import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	circularProgressContainer: {
		position: "fixed",
		width: "100%",
		height: "100%",
		zIndex: 2,
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
	},
	circularProgress: {
		position: "relative",
		top: "calc(50% - 50px)",
		left: "calc(50% - 50px)",
	},
}));

const LoadingOverlay = () => {
	const classes = useStyles();
	const theme = useTheme();
	return (
		<Box className={classes.circularProgressContainer}>
			<Box className={classes.circularProgress}>
				<LoadingAnimation loading={true} color={theme.palette.primary.main} progressBarTime={0} />
			</Box>
		</Box>
	);
};

export default LoadingOverlay;
