import React from "react";
import { Box } from "@material-ui/core";
import LogoInput from "./LogoInput/LogoInput";
import ColorInput from "./ColorPallete/ColorInput/ColorInput";
import NameInput from "./NameInput/NameInput";
import ColorPallete, {
	SELECTED_PREDEFINED_MOOD,
	XMP_FILE,
} from "./ColorPallete/ColorPallete";
import SingleMood from "./Moods/Mood";
import Mood from "../Mood/Mood";
import Gardian from "./Gardian/Gardian";
import Review from "./Review/Review";
import { removeEmptyValues } from "../../../Helpers/mapper";
import FineTuning from "./FineTuning/FineTuning";
import BriaButton, { BUTTONTYPES } from "../../UI/BriaButton";
import BrandReady from "./BrandReady/BrandReady";
import SubmitReview from "./Review/SubmitReview/SubmitReview";
import ProjectType from "./ProjectType/ProjectType";
import { moodsList } from "./Moods/moodList";
import Results from "./Results/Results";
import XMPUpload from "./Moods/XMPUpload/XMPUpload";
import RouterConstants from "../../../Constants/RouterConstants";

const renderXMPUpload = ({ next }: any) => <XMPUpload />;

export const brandSteps = (passedState?: any) => [
	{
		Render: ProjectType,
		resultKey: "projectType",
		mandatory: true,
	},
	{
		Render: NameInput,
		hideAtReview: true,
		resultKey: "name",
		mandatory: true,
	},
	{
		Render: LogoInput,
		title: "Logo",
		renderReview: (logoSrc: string) => (
			<>
				{!logoSrc && <>No Logo</>}

				{!!logoSrc && (
					<img
						src={(logoSrc as any)?.preview}
						alt="logo"
						className={passedState?.classes?.reviewImg}
					/>
				)}
			</>
		),
		resultKey: "logo",
		isImage: true,
		// completedOn: (generalState: any) => generalState?.logoColorPalette,
	},
	{
		Render: ColorPallete,
		title: "Color Palette",
		resultKey: "colorPallet",
		renderReview: (colors: string[]) => (
			<Box className={passedState?.classes.reviewColorsWrapper}>
				{!removeEmptyValues(colors).length && <>No Colors Selected</>}
				{removeEmptyValues(colors).map((color) => (
					<>
						<ColorInput
							circleWidth={40}
							reviewMood
							colorInputClass={
								passedState?.classes.reviewColorInput
							}
							color={color}
							disabled
						/>
					</>
				))}
			</Box>
		),
		isImage: true,
	},
	{
		Render: Gardian,
		title: "Brand Gardian",
		resultKey: "gardian",
		renderReview: (gardianText: string) => (
			<p className={passedState?.classes?.reviewGardian}>{gardianText}</p>
		),
	},
	{
		Render: <SingleMood mood={moodsList[0]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[1]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[2]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[3]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[4]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[5]} />,
		RenderWithProps: true,
		title: "Mood",
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: <SingleMood mood={moodsList[6]} />,
		RenderWithProps: true,
		title: "Mood",
		hidden: true,
		renderBottomNav: renderXMPUpload,
	},
	{
		Render: Results,
		title: "Results",
		renderSideNav: ({ next }: any) => (
			<BriaButton
				onClick={() => next(true)}
				buttonType={BUTTONTYPES.TEXTBUTTON}
			>
				Fine Tuning
			</BriaButton>
		),
	},
	{
		Render: FineTuning,
		hideAtReview: true,
		resultKey: "moodSliders",
		title: "Fine Tuning",
		hidden: false,
		nextText: "Continue",
	},
	{
		Render: Review,
		resultKey: "review",
		renderNext: () => <SubmitReview />,
	},
	{
		Render: BrandReady,
		resultKey: "final",
		renderSideNav: ({ next, push }: any) => (
			<BriaButton
				style={{ marginLeft: "auto" }}
				buttonType={BUTTONTYPES.PRIMARY}
				onClick={() => push(RouterConstants.GALLERY.path)}
			>
				View Brand Style in Gallery
			</BriaButton>
		),
		hidden: true,
		hideNav: true,
	},
];
