import { useEffect, useState } from "react";

function useIsSafariBrowser() {
	const [isSafari, setIsSafari] = useState<boolean>(false);

	useEffect(() => {
		const userAgent = window.navigator.userAgent;

		// Check if the user agent contains "Safari" but not "Chrome" (as Chrome also includes "Safari" in its user agent)
		if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
			setIsSafari(true);
		} else {
			setIsSafari(false);
		}
	}, []);

	return {
		isSafari,
	};
}

export default useIsSafariBrowser;
