import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ImageIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M12 5H12.01M1 12L5 8.00001C5.45606 7.56117 5.97339 7.33014 6.5 7.33014C7.02661 7.33014 7.54394 7.56117 8 8.00001L13 13M11 11L12 10C12.4561 9.56117 12.9734 9.33014 13.5 9.33014C14.0266 9.33014 14.5439 9.56117 15 10L17 12M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1Z"
				stroke={props.stroke || "#1A0638"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default ImageIcon;
