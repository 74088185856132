import urlExist from "url-exist";
import { checkLink } from "../Components/Tools/utilities";
import { isProd } from "../Config/Env";
import { BriaWebAxios } from "../sdk/briaAxios";

var FileSaver = require("file-saver");

export async function downloadImage(imageSrc: string, imageVH: string) {
	FileSaver.saveAs(imageSrc, imageVH);
}

export async function startDownload(
	fileUrl: string,
	feature: string,
	passedFileName: string,
	blobInput?: string,
	disablePoll?: boolean,
	forceExt?: string
) {
	try {
		let url = blobInput ? blobInput : fileUrl;
		if (!blobInput && !disablePoll) {
			await pullUntilFileIsAvailableFromBackend(fileUrl, feature);
			const response = await fetch(fileUrl);
			const blob = await response.blob();
			url = window.URL.createObjectURL(new Blob([blob]));
		}
		const link = document.createElement("a");
		link.href = url;
		const filenameFromUrl = new URL(fileUrl).pathname.split("/").pop();
		const ext = forceExt ?? filenameFromUrl?.split(".").pop();
		const filename = `bria_${passedFileName}.${ext}`;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

const downloadSourceUrl = async (
	fileUrl: string,
	feature: string,
	passedFileName: string,
	blobInput?: string,
	disablePoll?: boolean,
	forceExt?: string
) => {
	let url = blobInput ? blobInput : fileUrl;
	if (!blobInput && !disablePoll) {
		await pullUntilFileIsAvailableFromBackend(fileUrl, feature);
		const response = await fetch(fileUrl);
		const blob = await response.blob();
		url = window.URL.createObjectURL(new Blob([blob]));
	}
	const filenameFromUrl = new URL(fileUrl).pathname.split("/").pop();
	const ext = forceExt ?? filenameFromUrl?.split(".").pop();
	const filename = `bria_${passedFileName}.${ext}`;
	await downloadImage(fileUrl, filename);
};

export async function startDownloadGalleryImage(
	fileUrl: string,
	feature: string,
	passedFileName: string,
	blobInput?: string,
	disablePoll?: boolean,
	forceExt?: string,
	sourceUrl?: string
) {
	try {
		let finalFileUrl = fileUrl.replace("https://bria-temp.s3.amazonaws.com/", "https://temp.bria.ai/");
		const exists = await urlExist(finalFileUrl);
		if (exists) {
			await startDownload(finalFileUrl, feature, passedFileName, blobInput, disablePoll, forceExt);
		} else {
			if (sourceUrl) {
				await startDownload(sourceUrl, feature, passedFileName, blobInput, disablePoll, forceExt);
				// await downloadSourceUrl(sourceUrl, feature, passedFileName, blobInput, disablePoll, forceExt);
			} else {
				throw Error;
			}
		}
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export async function getFileStats(imageUrl: string) {
	let fileBlob;
	const fileSize = fetch(imageUrl)
		.then((res) => {
			fileBlob = res.blob();
			return fileBlob;
		})
		.then((fileBlob) => {
			return fileBlob.size;
		});
	return fileSize;
}

export async function pullUntilFileIsAvailableFromBackend(
	fileUrl: string,
	feature: string,
	abortController?: AbortController,
	maxAttempts: number = 60,
	sleepSecs: number = 1
): Promise<void> {
	try {
		if (!fileUrl) {
			return Promise.reject();
		}
		const isBase64 = fileUrl.includes("data:image/");
		if (isBase64) {
			return Promise.resolve();
		}
		const resp = await (
			await BriaWebAxios()
		).post(
			`/pull_file_until_available/`,
			{
				file_url: fileUrl,
				max_attempts: maxAttempts,
				sleep_secs: sleepSecs,
				feature,
			},
			{ signal: abortController?.signal }
		);
		const resData = resp.data as any;
		if (resData.available === true) {
			return Promise.resolve();
		} else {
			return Promise.reject();
		}
	} catch (e) {
		return Promise.reject();
	}
}

export async function pullUntilFileIsAvailable(
	fileUrl: string,
	feature: string,
	abortController?: AbortController,
	maxAttempts: number = 60,
	sleepSecs: number = 1
): Promise<void> {
	const start = new Date().getTime();
	try {
		if (!fileUrl) {
			return Promise.reject();
		}
		const isBase64 = fileUrl.includes("data:image/");
		if (isBase64) {
			return Promise.resolve();
		}
		let triesCounter = 0;
		while (triesCounter < maxAttempts) {
			if (abortController?.signal.aborted) {
				return Promise.reject();
			}
			const available = await checkLink(fileUrl);
			if (available) {
				return Promise.resolve();
			}
			triesCounter++;
			await new Promise((r) => setTimeout(r, sleepSecs * 1000));
		}
		return Promise.reject();
	} catch (e) {
		return Promise.reject();
	} finally {
		if (!isProd) {
			console.log(`Pull time: ${new Date().getTime() - start}`);
		}
	}
}
