import React, { useContext } from "react";
import AppContext from "../../Context/Context";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useMobileSize from "../../hooks/useMobileSize";
import LinksConst from "../../Constants/LinksConst";
import { ROLES } from "../../Routes";
import LoadingOverlay from "./LoadingOverlay";
import useIsSafariBrowser from "../../hooks/useIsSafariBrowser";
import { Box, Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalRedirect from "./ExternalRedirect";

const PublicRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
	const { user } = useContext(AppContext);
	const classes = useStyles();

	const { isMobile, isRedirecting } = useMobileSize();
	const { isSafari } = useIsSafariBrowser();

	return (
		<Route
			{...rest}
			render={() => {
				return isMobile && isRedirecting ? (
					<LoadingOverlay /> // until we redirect inside useMobileSize
				) : user?.role === ROLES.NON ? (
					<ExternalRedirect to={LinksConst.CONTACT_US_FORM_LINK} />
				) : (
					<>
						{isSafari && (
							<Dialog
								classes={{
									paper: classes.paper,
								}}
								open={isSafari}
								maxWidth={false}
								BackdropProps={{
									classes: {
										root: classes.backDrop,
									},
								}}
							>
								<Box className={classes.promtWrapper}>
									<Typography className={classes.beforeYouGoText} variant="h5">
										Browser not supported
									</Typography>
									<Typography className={classes.messageText}>
										This page isn’t supported on this browser. Please switch to Chrome on your
										desktop.
									</Typography>
								</Box>
							</Dialog>
						)}
						{children}
					</>
				);
			}}
		></Route>
	);
};

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: "10px !important",
		height: 200,
		width: 625,
	},
	promtWrapper: {
		padding: "1.5rem",
		paddingTop: "2rem",
	},
	beforeYouGoText: {
		color: "black",
		fontWeight: 600,
	},
	messageText: {
		color: "rgba(91, 91, 91, 1)",
		fontSize: "0.9rem",
		margin: "1rem 0",
	},
	backDrop: {
		backdropFilter: "blur(3px)",
		backgroundColor: "rgba(0,0,30,0.4)",
	},
}));

export default PublicRoute;
