import i18n from "i18next";

const calculateReszie = (w: number, h: number) => {
	const minWidth = 96;
	const minHeight = 48;

	if (h == w) return { w: minHeight, h: minHeight };
	if (h > w) {
		let result = (w * minHeight) / h;
		return { w: result, h: minHeight };
	}
	let result = (h * minWidth) / w;
	return { w: minWidth, h: result };
};

export const CropPlatformConstants = (arr: any[]) => {
	// 0 => soical media
	// 1 => ecommerce
	// 2 => advertizing

	const createPresetObj = (res: any[], obj: any, index: number) => {
		let cropObj = {
			name: i18n.t(obj.placement),
			w: obj.image_width,
			h: obj.image_height,
			aspectRatioWidth: obj.aspect_ratio_width,
			aspectRatioHeight: obj.aspect_ratio_height,
			resize: calculateReszie(obj.image_width, obj.image_height),
			index: 0,
		};
		if (!res[index]) {
			res[index] = new Map();
		}
		const destName = i18n.t(obj.destination_name);
		if (res[index].has(destName)) {
			let indexOfObj = res[index].get(destName).length;
			cropObj.index = indexOfObj;
			let arr = res[index].get(destName);
			arr.push(cropObj);
			res[index].set(destName, arr);
		} else res[index].set(destName, [cropObj]);
	};

	const res = new Array(3);
	arr.forEach((val) => {
		if (val.channel === "Social Media") {
			createPresetObj(res, val, 0);
		} else if (val.channel === "eCommerce") {
			createPresetObj(res, val, 1);
		} else {
			createPresetObj(res, val, 2);
		}
	});
	return res;
};
