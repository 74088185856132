import { makeStyles, Theme } from "@material-ui/core/styles";
import { useContext } from "react";
import Context from "../../Context/Context";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as ChatIcon } from "../../assets/svgs/chat-icon.svg";
import { IsIframe } from "../../Helpers/iframe";

export function FeedbackChat() {
	const classes = useStyles();
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	return !isIframe() ? (
		<Box
			className={classes.chatContainer}
			onClick={() => {
				context.setDisplayFeedbackDialogState(true);
			}}
		>
			<Box className={classes.chatCirculeContainer}>
				<ChatIcon className={classes.chatIconSize} />
			</Box>
			<Typography className={classes.chatTextStyle}>
				Give FeedBack
			</Typography>
		</Box>
	) : (
		<></>
	);
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	chatIconSize: {
		width: 26,
		height: 23,
	},
	chatCirculeContainer: {
		backgroundColor: "#FFFFFF",
		borderRadius: 100,
		width: 48,
		height: 48,
		boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08);",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#E7E7E7",
			boxShadow: "0px 2px 16px rgba(36, 0, 88, 0.08)",
		},
		"&:focus": {
			backgroundColor: "#F1EDF7",
			boxShadow: "0px 2px 16px rgba(36, 0, 88, 0.08)",
		},
	},
	chatContainer: {
		position: "absolute",
		bottom: 8,
		right: "1.7vw",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		zIndex: 10,
		cursor: "pointer",
		"@media (min-width:1920px)": {
			right: 32,
		},
	},
	chatTextStyle: {
		width: 62,
		marginTop: 8,
		color: "#1A0638",
		textAlign: "center",
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "16px",
	},
}));
