import React from "react";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { isDevelopment } from "../Config/Env";
import en from "../translations/en.json";
import ja from "../translations/ja.json";

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: en,
			},
			ja: {
				translation: ja,
			},
		},
		lng: "en", // Set the default language
		fallbackLng: "en",
		supportedLngs: ["en", "ja"],
		interpolation: {
			escapeValue: false, // Allows using HTML in translations
		},
		react: {
			useSuspense: process.env.NODE_ENV !== "test",
		},
		debug: isDevelopment,
	});

function I18nProvider({ children }: React.PropsWithChildren<React.ReactNode>) {
	i18n.on("languageChanged", function (lng) {
		document.body.dir = i18n.dir();
	});

	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default I18nProvider;
