import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as EdiIcon } from "../../../../../assets/svgs/edit-icon.svg";

interface IColorInput {
	color: string;
	onColorChange?: (color: string, remove?: boolean) => void;
	disabled?: boolean;
	reviewMood?: boolean;
	colorInputClass?: string;
	circleWidth?: number | string;
	circledashed?: boolean;
	disbaleDefaultPicker?: boolean;
	setOpenColorDialog?: any;
}

export default function ColorInput({
	color,
	onColorChange,
	disabled,
	colorInputClass,
	reviewMood,
	circleWidth,
	circledashed = false,
	disbaleDefaultPicker = false,
	setOpenColorDialog,
}: IColorInput) {
	const classes = useStyles({ circleWidth });
	const [localColor, setLocalColor] = useState(color);

	const handleChange = (e: any) => {
		const {
			target: { value: color },
		} = e;
		onColorChange?.(color);
		setLocalColor(color);
	};

	const handleDelete = () => {
		onColorChange?.(color, true);
		setLocalColor("");
	};

	useEffect(() => {
		setLocalColor(color);
	}, [color]);

	return (
		<Box className={classes.colorInputWrapper}>
			<Box className={clsx(classes.roundedWrapper, colorInputClass)}>
				{!disbaleDefaultPicker && (
					<input
						type="color"
						value={localColor}
						className={classes.colorInput}
						onChange={handleChange}
						disabled={reviewMood}
					/>
				)}
				{localColor ? (
					<Box bgcolor={localColor} className={clsx(classes.filledColor, classes.roundedWrapper)}>
						<EdiIcon
							onClick={() => disbaleDefaultPicker && setOpenColorDialog(true)}
							className={clsx(classes.editIcon, "hover")}
						/>
						<Box onClick={handleDelete} className={clsx(classes.deleteIcon, "hover")}>
							+
						</Box>
					</Box>
				) : (
					<>
						{!reviewMood && (
							<Box
								onClick={() => disbaleDefaultPicker && setOpenColorDialog(true)}
								className={clsx(
									classes.roundedWrapper,
									classes.placeholder,
									circledashed && classes.dashedColor
								)}
							>
								<span>+</span>
							</Box>
						)}
					</>
				)}
			</Box>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	colorInputWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: "0.5rem",
	},
	roundedWrapper: {
		fontFamily: "Montserrat",
		width: ({ circleWidth }: any) => circleWidth || 40,
		aspectRatio: "1/1",
		borderRadius: "50%",
		position: "relative",
		marginBottom: "auto",
		"& .hover": {
			opacity: 0,
			pointerEvents: "none",
			transition: "all 150ms ease-in-out",
		},
		boxShadow: "0px 1px 4px 0 rgb(0 0 0 / 10%)",
		"&:hover": {
			boxShadow: "1px 1px 7px 0 rgb(0 0 0 / 25%), -7px -7px 5px 0 rgb(255 255 255)",
			"& .hover": {
				opacity: 1,
				pointerEvents: "unset",
			},
		},
	},
	colorInput: {
		position: "absolute",
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: 2,
		opacity: 0,
		cursor: "pointer",
	},
	placeholder: {
		border: "1px solid #A4A4A4",
		color: "#A4A4A4",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& span": {
			fontSize: 32,
			lineHeight: 1,
		},
	},
	filledColor: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	colorText: {
		color: "#A4A4A4",
		textAlign: "center",
	},
	editIcon: {
		"& path": {
			fill: "#fff",
		},
	},
	deleteIcon: {
		color: "#A4A4A4",
		fontSize: "19px",
		position: "absolute",
		top: "-16px",
		right: "-6px",
		transform: "rotate(45deg)",
		cursor: "pointer",
		"&:hover": {
			opacity: "50%",
		},
	},
	dashedColor: {
		border: "1px dashed #A4A4A4",
	},
}));
