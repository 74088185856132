import { Box, Checkbox, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Context from "../../../../../Context/Context";
import Flex, { FlexGaps } from "../../../../Common/Wrappers/Flex";
import { BrandContext } from "../../BrandPage/BrandPage";

export const CURRENT_MOOD_IS_DEFAULTED = "CURRENT_MOOD_IS_DEFAULTED";

export default function MarkAsDefault() {
	const [isChecked, setIsChecked] = useState(true);
	const classes = useStyles({ isChecked });
	const { updateGeneralState } = useContext(BrandContext);

	const onCheck = () => setIsChecked(!isChecked);

	useEffect(() => {
		updateGeneralState(CURRENT_MOOD_IS_DEFAULTED, isChecked);
	}, [isChecked]);

	const defaultText =
		clsx({ "Default Style": isChecked }) || "Mark As Default";

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.checkboxTextWrapper}>
				<Flex gap={FlexGaps.sm}>
					<Checkbox
						className={classes.checkbox}
						onChange={onCheck}
						checked={isChecked}
						color="primary"
					/>
					<Typography className={classes.text}>
						{defaultText}
					</Typography>
				</Flex>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		position: "relative",
	},
	checkbox: {
		fill: "blue",
	},
	checkboxTextWrapper: {
		position: "absolute",
		left: -9,
		whiteSpace: "pre",
		top: "50%",
		transform: "translateY(-50%)",
		fontSize: 10,
		color: ({ isChecked }: any) =>
			(isChecked && theme.palette.primary.main) ||
			theme.palette.common.gray,
	},
	text: {
		fontSize: 14,
	},
}));
