import React, { useContext } from "react";
import AppContext from "../../Context/Context";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { ROLES } from "../../Routes";
import RouterConstants from "../../Constants/RouterConstants";
import useMobileSize from "../../hooks/useMobileSize";
import LinksConst from "../../Constants/LinksConst";
import LoadingOverlay from "./LoadingOverlay";
import { IsGuestUser } from "../../Helpers/isGuest";
import ExternalRedirect from "./ExternalRedirect";
import User from "../../Models/User";

type ConditionFunction = (user: User) => boolean;

interface IProps extends RouteProps {
	roles: ROLES[];
	conditions?: ConditionFunction[];
	guest?: boolean;
}

const PrivateRoute: React.FC<IProps> = ({ guest, children, roles, conditions, ...rest }) => {
	const { user, systemView, iframeId } = useContext(AppContext);
	const history = useHistory();
	const { isGuestUser } = IsGuestUser();
	let path = history.location.pathname;
	if (path === "/") {
		path = new URLSearchParams(window.location.search).get("redirect") ?? "/";
	}

	const { isMobile, isRedirecting } = useMobileSize();

	return (
		<Route
			{...rest}
			render={() => {
				if (isMobile && isRedirecting) {
					return <LoadingOverlay />; //until re redirect inside useMobileSize
				} else if (isGuestUser()) {
					return <Redirect to={RouterConstants.HOME.path} />;
				} else if (user) {
					const roleMatch = roles.includes(user.role as ROLES);
					const conditionsPassed = conditions
						? conditions.every((condition: ConditionFunction) => condition(user))
						: true;
					if (user.role === ROLES.NON) {
						return <ExternalRedirect to={LinksConst.CONTACT_US_FORM_LINK} />;
					} else if (roleMatch || conditionsPassed) {
						return children;
					} else {
						return <Redirect to={RouterConstants.HOME.path} />;
					}
				} else {
					return <Redirect to={RouterConstants.HOME.path} />;
				}
			}}
		></Route>
	);
};

export default PrivateRoute;
