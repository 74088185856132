import { Box, CircularProgress, Dialog, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputLayout from "../../Components/Common/InputLayout";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
import CloseIcon from "../../assets/icons/CloseIcon";
import { createHubspotContact, sendEmail, SendEmailInput } from "../../GraphQL/mutations";
import { getSelectedOrganizationFromLocalStorage } from "../../Helpers/localStorage";
import { ApiSubscriptionTypes } from "../../Constants/SubscriptionConstants";
import clsx from "clsx";

type Props = {
	open: boolean;
	plan: ApiSubscriptionTypes;
	onClose: (submitted: boolean) => void;
};

type PricingQuoteForm = {
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	phone?: string;
	message?: string;
};

const EmailPlanNames: Partial<{ [plan in ApiSubscriptionTypes]: string }> = {
	[ApiSubscriptionTypes.pro]: "Pro Plan",
	[ApiSubscriptionTypes.ultimate]: "Enterprise Plan",
};

const PricingQuote = ({ open, plan, onClose }: Props) => {
	const [isOpen, setIsOpen] = useState(open);
	const [form, setForm] = useState<PricingQuoteForm>({
		firstName: "",
		lastName: "",
		email: "",
		company: "",
		phone: undefined,
		message: undefined,
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pricingQuote" });

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const handleFormChange = <K extends keyof PricingQuoteForm>(key: K, value: PricingQuoteForm[K]) => {
		setForm((prevForm) => ({ ...prevForm, [key]: value }));
	};

	const sendQuote = async () => {
		try {
			setError(false);
			const emailInput: SendEmailInput = {
				subject: `[Internal] ${form.firstName} ${form.lastName} requests a quote for ${EmailPlanNames[plan]}`,
				body: `The following user requests an a quote for ${EmailPlanNames[plan]}.\n- Name: ${form.firstName} ${
					form.lastName
				}\n- Email: ${form.email}\n- Organization: ${
					getSelectedOrganizationFromLocalStorage()?.name
				}\n- Message: ${form.message}\n- Phone: ${form.phone}`,
				toList: ["admin@bria.ai"],
				bccList: ["lev@bria.ai"],
			};
			setLoading(true);
			await Promise.all([
				sendEmail(emailInput),
				createHubspotContact(
					{ request_plan_quote: EmailPlanNames[plan], phone: form.phone, request_plan_note: form.message },
					true,
					form.email,
					form.firstName,
					form.lastName,
					form.company
				),
			]);
			onClose(true);
		} catch (e) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	const renderInput = (fieldKey: keyof PricingQuoteForm) => (
		<InputLayout
			label={t(`${fieldKey}.label`)}
			optional={["phone", "message"].includes(fieldKey)}
			className={clsx({
				[classes.nameField]: ["firstName", "lastName"].includes(fieldKey),
			})}
		>
			<TextField
				value={form[fieldKey]}
				onChange={(e) => handleFormChange(fieldKey, e.target.value)}
				placeholder={t(`${fieldKey}.placeholder`)}
				variant="outlined"
				fullWidth
				className={fieldKey === "message" ? classes.bigTextField : classes.textField}
				multiline={fieldKey === "message"}
				minRows={fieldKey === "message" ? 3 : undefined}
			/>
		</InputLayout>
	);

	return (
		<Dialog open={isOpen} onClose={() => onClose(false)} maxWidth="sm" fullWidth={true} className={classes.dialog}>
			<CloseIcon className={classes.closeIcon} onClick={() => onClose(false)} />
			<Box className={classes.headerWrapper}>
				<Typography className={classes.header}>{t(`${plan}.header`)}</Typography>
				<Typography className={classes.subHeader}>{t(`${plan}.subHeader`)}</Typography>
			</Box>
			<Box className={classes.form}>
				<Box className={classes.nameForm}>
					{renderInput("firstName")}
					{renderInput("lastName")}
				</Box>
				{(Object.keys(form) as (keyof PricingQuoteForm)[])
					.filter((key) => !["firstName", "lastName"].includes(key))
					.map(renderInput)}
				<BriaButton
					onClick={sendQuote}
					buttonType={BUTTONTYPES.PRIMARYPURPLE}
					className={classes.button}
					disabled={!form.firstName || !form.lastName || !form.email}
				>
					{t("button")} {loading && <CircularProgress className={classes.loader} />}
				</BriaButton>
				{error && <Typography className={classes.error}>{t("error")}</Typography>}
			</Box>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	closeIcon: {
		cursor: "pointer",
		position: "absolute",
		top: "18px",
		right: "18px",
		width: "16px",
		height: "16px",
	},
	dialog: {
		"& .MuiDialog-paper": {
			maxWidth: 520,
			minHeight: 730,
			padding: "40px 40px",
			borderRadius: "8px !important",
		},
	},
	headerWrapper: {
		paddingBottom: "28px",
	},
	header: {
		fontSize: 24,
		fontWeight: 600,
		lineHeight: "29px",
		letterSpacing: "0em",
		textAlign: "left",
		paddingBottom: "12px",
		color: "rgba(26, 6, 56, 1)",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
	},
	subHeader: {
		fontSize: 16,
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0em",
		textAlign: "left",
		color: "rgba(91, 91, 91, 1)",
	},
	textField: {
		"& .MuiOutlinedInput-root": {
			height: "4.5vh",
			fontSize: "min(1.2vw, 12px)",
		},
	},
	bigTextField: {
		"& .MuiOutlinedInput-root": {
			fontSize: "min(1.2vw, 12px)",
		},
	},
	button: {
		width: 160,
		alignSelf: "end",
	},
	loader: {
		width: "20px !important",
		height: "20px !important",
		marginLeft: "15px",
	},
	error: {
		color: "red",
		height: 0,
		fontSize: "12px",
		textAlign: "center",
	},
	nameForm: {
		display: "flex",
		width: "100%",
		gap: 24,
	},
	nameField: {
		flexGrow: 1,
	},
}));

export default PricingQuote;
