import {
	Box,
	Button,
	CircularProgress,
	InputBase,
	makeStyles,
	TextField,
	Theme,
	Tooltip,
	Typography,
	useTheme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ChangeEvent, useContext, useRef, useState } from "react";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import cloudUpload from "../assets/svgs/cloudUpload.svg";
import ColorInput from "../Components/Tools/Brand/ColorPallete/ColorInput/ColorInput";
import DropDown, { IOption } from "./Common/DropDown/DropDown";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import closeImage from "../assets/svgs/x-brand.svg";
import CustomSingleSelect, { IGroup } from "./Common/DropDown/ٍSingleSelectGroupedDropDown";
import { useTranslation } from "react-i18next";
import Context from "../Context/Context";
import { getBase64 } from "./Tools/Testing/FileUpload";
import { InsertBrandPresetToDB } from "../GraphQL/mutations";
import {
	getOrganizationBrandPresets,
	getOrganizationBrandPresetsVariables,
} from "../GraphQL/types/getOrganizationBrandPresets";
import { GET_ORGANIZATION_BRAND_PRESETS } from "../GraphQL/queries";
import client from "../GraphQL/client";
import { toast } from "react-toastify";
import { IsIframe } from "../Helpers/iframe";
import { BRIA_ORG_ID } from "../Constants";

interface IProps {
	shouldOpen: boolean;
	onClose: any;
}

function CreateNewBrandDialog(props: IProps) {
	const theme = useTheme();
	const classes = useStyles({});
	const [brandNameTextBoxValue, setBrandNameTextBoxValue] = useState("");
	const [brandDescription, setBrandDescription] = useState<string>("");
	const context = useContext(Context);
	const fontOptions = context.orgFonts;
	const sizesList = [
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"20",
		"24",
		"32",
		"36",
		"40",
		"48",
		"64",
		"96",
		"120",
		"128",
	];
	const newArray: IOption[] = sizesList.map((value: string, index: number) => ({
		id: String(index),
		title: value,
	}));
	const [sizeOptions, setSizeOptions] = useState<IOption[]>(newArray);
	const [selectedFontPrimary, setSelectedFontPrimary] = useState<any>();
	const [selectedFontSecondary, setSelectedFontSecondary] = useState<any>();
	const [selectedSizePrimary, setSelectedSizePrimary] = useState<any>();
	const [selectedSizeSecondary, setSelectedSizeSecondary] = useState<any>();
	const [primarySelectedColor, setPrimarySelectedColor] = useState<any>();
	const [secondarySelectedColor, setSecondarySelectedColor] = useState<any>();
	const [colors, setColors] = useState(["", "", "", "", ""]);
	const [renewKeyOnDelete, setRenewKeyOnDelete] = useState(0);
	const logoInputRef = useRef<HTMLInputElement | null>(null);
	const [logoFile, setLogoFile] = useState("");
	const [logoToInsert, setLogoToInsert] = useState<any>();
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const [selectedFontPrimaryName, setSelectedFontPrimaryName] = useState("");
	const [selectedFontSecondaryName, setSelectedFontSecondaryName] = useState("");
	const [insertBrandLoading, setInsertBrandLoading] = useState(false);
	const [errorInInsert, setErrorInInsert] = useState(false);
	const [isValidCampaignBrandname, setIsValidCampaignBrandname] = useState(true);
	const { isIframe } = IsIframe();
	let orgId: string;
	if (isIframe()) {
		orgId = context.iframeConfig?.organization ?? BRIA_ORG_ID;
	} else {
		orgId = context.selectedOrganization?.uid ?? BRIA_ORG_ID;
	}

	const handlePrimaryFontChnage = (event: any) => {
		const selectedOption = context?.orgFonts
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option.id === event.target.value);
		setSelectedFontPrimary(event.target.value);
		setSelectedFontPrimaryName(selectedOption.value);
	};

	const handleSecondaryFontChnage = (event: any) => {
		const selectedOption = context.orgFonts
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option.id === event.target.value);
		setSelectedFontSecondary(event.target.value);
		setSelectedFontSecondaryName(selectedOption.value);
	};

	const handleColorChange = (color: string, index: number, remove?: boolean) => {
		const updatedColors = [...colors];
		if (remove) {
			updatedColors.splice(index, 1);
		} else {
			updatedColors[index] = color;
		}

		const showAddIcon: boolean = Boolean(updatedColors[updatedColors.length - 1] && updatedColors.length < 5);
		if (showAddIcon) updatedColors.push("");

		setColors(updatedColors);
	};

	const handlePrimaryColorChange = (color: string, remove?: boolean) => {
		var showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			setPrimarySelectedColor(color);
		}

		if (showAddIcon) setPrimarySelectedColor("");
	};

	const handleSecondaryColorChange = (color: string, remove?: boolean) => {
		var showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			setSecondarySelectedColor(color);
		}

		if (showAddIcon) setSecondarySelectedColor("");
	};

	const getOrgBrands = async () => {
		const responseData = await client.query<getOrganizationBrandPresets, getOrganizationBrandPresetsVariables>({
			query: GET_ORGANIZATION_BRAND_PRESETS,
			variables: {
				orgId: orgId || "",
			},
		});
		if (responseData && responseData.data && responseData.data.getOrganizationBrandPresets) {
			const updatedBrandOptions = responseData.data.getOrganizationBrandPresets.map((brand: any) => ({
				...brand,
				id: brand.id,
				title: brand.name,
				brandDropDown: true,
			}));
			return updatedBrandOptions;
		}
		return [];
	};

	const createNewBrand = async () => {
		if (!brandNameTextBoxValue) {
			setIsValidCampaignBrandname(false);
			return;
		}
		setInsertBrandLoading(true);
		try {
			const createBrandRes = await InsertBrandPresetToDB(
				brandNameTextBoxValue,
				brandDescription,
				orgId,
				selectedFontPrimary,
				selectedSizePrimary?.title,
				primarySelectedColor,
				selectedFontSecondary,
				selectedSizeSecondary?.title,
				secondarySelectedColor,
				logoToInsert,
				JSON.stringify(colors)
			);
			if (createBrandRes?.ok) {
				const brand_preset_id = createBrandRes?.brandPresetId;
				context.setloadingBrands(true);
				const updatedBrandOptions: any = await getOrgBrands();
				context.setOrgBrands(updatedBrandOptions);
				const newBrandAdded = updatedBrandOptions.find((option: any) => option.id === brand_preset_id);
				context.setSelectedDropDownValue(newBrandAdded);
				context.setloadingBrands(false);
				props.onClose();
				setInsertBrandLoading(false);
				toast.success(<Typography>{t("brandSavedSuccessfully")}</Typography>);
			} else {
				setErrorInInsert(true);
				setInsertBrandLoading(false);
				toast.error(<Typography> {t("errorMessage")}</Typography>);
			}
		} catch (error: any) {
			setErrorInInsert(true);
			setInsertBrandLoading(false);
			toast.error(<Typography> {t("errorMessage")}</Typography>);
		}
	};

	const handleLogoUpload = async (fileKey: any, e: ChangeEvent<HTMLInputElement>) => {
		const maxSize = 5 * 1024 * 1024;
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		if (file.size > maxSize) {
			console.error("File size exceeds the limit (5 MB).");
			return;
		}
		let fileUrl = URL.createObjectURL(file);
		setLogoFile(fileUrl);
		const base64 = await getBase64(file);
		setLogoToInsert(`{ "fileName": "${file.name}", "base64": "${base64}" }`);
	};

	function deleteLogo() {
		if (logoFile) {
			URL.revokeObjectURL(logoFile);
		}
		setRenewKeyOnDelete(renewKeyOnDelete + 1);
		setLogoFile("");
		if (logoInputRef.current) {
			logoInputRef.current.value = "";
		}
	}

	const handleDescriptionChange = ({ target: { value } }: any) => {
		setBrandDescription(value ?? "");
	};

	const { t } = useTranslation("translation", { keyPrefix: "createNewBrandDialog" });
	const inputProps = {
		disableUnderline: true,
		style: { fontSize: 14, fontWeight: 400, lineHeight: "21px" },
	};

	return (
		<Dialog
			open={props.shouldOpen}
			maxWidth={false}
			PaperProps={{ style: { display: "flex", justifyContent: "center" } }}
			classes={{
				paper: classes.dialog,
			}}
		>
			<Box className={classes.mainContainer}>
				<Box className={classes.closeBtnContainer}>
					<IconButton
						className={classes.iconButton}
						color="inherit"
						aria-label="close"
						onClick={props.onClose}
					>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</Box>
				<Typography className={classes.brandTitle}>{t("title")}</Typography>
				<Box className={classes.displayFlex}>
					<Box>
						<Box className={classes.textBoxContainter}>
							<Typography className={classes.Label}>{t("brandName")}</Typography>
							<InputBase
								id="inputId"
								placeholder={""}
								className={`${classes.textField} ${!isValidCampaignBrandname && classes.errorBorder}`}
								onChange={(e) => setBrandNameTextBoxValue(e.target.value)}
								value={brandNameTextBoxValue}
							/>
						</Box>
						<Box className={classes.textBoxContainter}>
							<Typography className={classes.Label}>{t("brandDescription")}</Typography>
							<TextField
								value={brandDescription}
								fullWidth
								onChange={handleDescriptionChange}
								placeholder={t("brandDescription")}
								multiline
								className={classes.textAreaField}
								rows={3}
								maxRows={4}
								InputProps={inputProps}
							/>
						</Box>

						<input
							key={renewKeyOnDelete}
							ref={logoInputRef}
							onChange={(e) => handleLogoUpload("logo", e)}
							accept="image/*"
							type="file"
							style={{ display: "none" }}
						/>

						<Box className={classes.uploadContainer}>
							<Typography className={`${classes.Label} ${classes.uploadLogo}`}>
								{t("uploadLogo")}
							</Typography>
							<Button
								className={classes.imguploadButton}
								onClick={() => !isTooltipHovered && logoInputRef.current?.click()}
							>
								<Box className={classes.imgUploadContainer}>
									{logoFile ? (
										<>
											<Tooltip
												title={"delete"}
												className={classes.tooltipStyle}
												placement="top"
												onClick={() => {
													deleteLogo();
													setIsTooltipHovered(false);
												}}
												onOpen={() => setIsTooltipHovered(true)}
												onClose={() => setIsTooltipHovered(false)}
											>
												<img src={closeImage} />
											</Tooltip>

											<img src={logoFile} className={classes.imgLogo} alt="" />
										</>
									) : (
										<>
											<img className={classes.img} src={cloudUpload} alt="Cloud Upload" />
											<Typography className={classes.uploadFiles}>{t("uploadFile")}</Typography>
										</>
									)}
									<Typography className={classes.supportedFiles}>{t("supportedFiles")}</Typography>
								</Box>
							</Button>
						</Box>
					</Box>
					<Box className={classes.brandsAndFontsSections}>
						<Typography className={classes.brandNameLabel}>{t("brandColors")}</Typography>
						<Box className={classes.colorsLables}>
							{colors.map((color: string, index: number) => (
								<Box>
									{index < 2 && (
										<Typography className={classes.colorHeader}>
											{index === 0 && <>{t("primary")}</>}
											{index === 1 && <>{t("secondary")}</>}
										</Typography>
									)}
								</Box>
							))}
						</Box>

						<Box className={classes.colorOptions}>
							{colors.map((color: string, index: number) => (
								<Box key={index}>
									<ColorInput
										circleWidth="40px"
										color={color}
										onColorChange={(color: string, remove?: boolean) =>
											handleColorChange(color, index, remove)
										}
										circledashed={true}
									/>
								</Box>
							))}
						</Box>
						{/* primary text */}
						<Box>
							<Typography className={clsx(classes.textsMargin, classes.Label)}>
								{t("primaryText")}
							</Typography>
							<Box className={classes.textSection}>
								<CustomSingleSelect
									selectedValue={selectedFontPrimary}
									handleChange={handlePrimaryFontChnage}
									groups={(fontOptions as IGroup[]) || []}
									className="custom-single-select"
									selectRootClass={classes.rootFirstSelect}
									placeholder={t("Font")}
									selectStyle={classes.selectStyle}
									menuMaxHeight="150px"
									selectedValueName={selectedFontPrimaryName}
									fontOptions={true}
								/>

								<DropDown
									width="90px"
									height="40px"
									options={sizeOptions}
									defaultSelected=""
									onChange={(selectedOption) => {
										setSelectedSizePrimary(selectedOption);
									}}
									selectedOption={selectedSizePrimary}
									placeholder={t("Size")}
									color="#5B5B5B"
									border={"1px solid #B3B3B9"}
									borderRadius="8px"
									isFullWidth={true}
									paddingInline={"12px 8px"}
									optionsScroll={true}
									fontSize={"14px"}
									CampainDropdownElemStyle={true}
									fontWeight={400}
								></DropDown>
								<ColorInput
									circleWidth="40px"
									color={primarySelectedColor}
									onColorChange={(color: string, remove?: boolean) =>
										handlePrimaryColorChange(color, remove)
									}
									circledashed={true}
								/>
							</Box>
						</Box>
						{/* Secondary text */}
						<Box>
							<Typography className={clsx(classes.textsMargin, classes.Label)}>
								{t("secondaryText")}
							</Typography>
							<Box className={classes.textSection}>
								<CustomSingleSelect
									selectedValue={selectedFontSecondary}
									handleChange={handleSecondaryFontChnage}
									groups={(fontOptions as IGroup[]) || []}
									className="custom-single-select"
									selectRootClass={classes.rootFirstSelect}
									placeholder={t("Font")}
									selectStyle={classes.selectStyle}
									menuMaxHeight="150px"
									selectedValueName={selectedFontSecondaryName}
									fontOptions={true}
								/>

								<DropDown
									width="90px"
									height="40px"
									options={sizeOptions}
									defaultSelected=""
									onChange={(selectedOption) => {
										setSelectedSizeSecondary(selectedOption);
									}}
									placeholder={t("Size")}
									color="#5B5B5B"
									border={"1px solid #B3B3B9"}
									borderRadius="8px"
									isFullWidth={true}
									paddingInline={"12px 8px"}
									optionsScroll={true}
									fontSize={"14px"}
									CampainDropdownElemStyle={true}
									fontWeight={400}
								></DropDown>
								<ColorInput
									circleWidth="40px"
									color={secondarySelectedColor}
									onColorChange={(color: string, remove?: boolean) =>
										handleSecondaryColorChange(color, remove)
									}
									circledashed={true}
								/>
							</Box>
						</Box>
						<Box className={classes.buttonContainer}>
							<BriaButton
								className={classes.saveBrandButton}
								buttonType={BUTTONTYPES.PRIMARY}
								onClick={createNewBrand}
							>
								{t("saveNewBrand")}
								{insertBrandLoading && (
									<CircularProgress
										style={{
											width: 12,
											height: 12,
											fontSize: 16,
											position: "relative",
											right: -5,
											color: theme.palette.primary.main,
										}}
									></CircularProgress>
								)}
							</BriaButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
}

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	dialog: (props) => ({
		borderRadius: "10px !important",
		width: "712px",
	}),
	mainContainer: {
		display: "flex",
		flexDirection: "column",
	},
	iconButton: {
		padding: 0,
		color: "#000000",
	},
	closeIcon: {
		fontSize: "1.5rem",
	},
	displayFlex: {
		display: "flex",
		"@media (max-width:768px)": {
			flexDirection: "column",
		},
	},
	brandTitle: {
		color: " #1A0638",
		fontFamily: "Montserrat",
		fontSize: "24px",
		fontWeight: 600,
		marginLeft: "32px",
		marginTop: "32px",
		lineHeight: "normal",
	},
	Label: {
		color: "#1A0638",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 600,
		marginTop: "28px",
		marginBottom: "12px",
	},
	brandNameLabel: {
		color: "#1A0638",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 600,
		marginTop: "28px",
		marginBottom: "9px",
		lineHeight: "17px",
	},
	textField: {
		width: "300px",
		borderRadius: "8px",
		height: "40px",
		border: "1px solid #B3B3B9",
		color: "#5B5B5B",
		padding: "12px 8px",
		"& input": {
			fontSize: 14,
			textOverflow: "ellipsis",
			"&::placeholder": {
				color: "#5B5B5B",
				fontSize: 14,
				fontWeight: 400,
			},
		},
	},
	textBoxContainter: {
		marginLeft: "32px",
	},
	img: {
		alignItems: "center",
		paddingTop: " 22px",
		paddingBottom: "6px",
	},
	imgUploadContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderRadius: "8px",
		border: "1.5px dashed #B3B3B9",
		backgroundColor: "#FAFAFA",
	},
	imguploadButton: {
		padding: "0px",
		minWidth: "300px",
		display: "block",
		textTransform: "none",
	},
	uploadContainer: {
		marginLeft: "32px",
		width: "300px",
	},
	supportedFiles: {
		paddingBottom: "21px",
		color: "#1A0638",
		fontFamily: "Montserrat",
		fontSize: "12px",
		fontWeight: 400,
		lineHeight: "16.32px",
		paddingTop: "8px",
	},
	uploadFiles: {
		color: "#1A0638",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 500,
		lineHeight: "19.04px",
	},
	colorOptions: {
		display: "flex",
		gap: "12px",
		marginLeft: "0.25vh",
		"& > div": {
			alignSelf: "end",
			justifyContent: "end",
		},
	},
	colorHeader: {
		marginBottom: "12px",
		fontWeight: 400,
		fontSize: "12px",
		color: "#000",
	},
	buttonContainer: {
		display: "flex",
		flexFlow: " row-reverse",
		marginTop: "132px",
		marginBottom: "32px",
		"@media (max-width:768px)": {
			flexFlow: "wrap",
		},
	},
	brandsAndFontsSections: {
		marginLeft: "47px",
	},
	textsMargin: {
		marginTop: "24px !important",
	},
	textSection: {
		display: "flex",
		gap: "10px",
	},
	closeBtnContainer: {
		position: "absolute",
		top: 10,
		right: 16,
	},
	colorsLables: {
		display: "flex",
		gap: "8px",
	},
	imgLogo: {
		backgroundImage: `url(/transparent.jpg)`,
		backgroundSize: "cover",
		width: "160px",
		height: "64px",
		borderRadius: "10px",
		marginTop: "38px",
		marginBottom: "12px",
	},
	tooltipStyle: {
		position: "absolute",
		right: "6px",
		top: "6px",
		fontSize: "14px",
		borderRadius: "4px",
		color: "initial",
	},
	rootFirstSelect: {
		padding: "10px 12px",
	},
	selectStyle: {
		width: "116px",
		borderRadius: "10px !important",
		backgroundColor: "white",
		"&:focus": {
			backgroundColor: "white",
		},
		overflowY: "auto",
	},
	uploadLogo: {
		marginTop: "24px",
	},
	saveBrandButton: {
		fontWeight: 600,
		fontSize: "16px",
		borderRadius: "8px",
		"&:focus, &:active": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	errorMessage: {
		display: "flex",
		justifyContent: "end",
		color: "red",
	},
	errorBorder: {
		border: "1px solid red",
	},
	textAreaField: {
		width: "300px",
		height: "80px",
		"& .MuiInput-underline, ": {
			"&::placeholder": {
				opacity: 1,
			},
		},

		"& .MuiInputBase-root": {
			borderRadius: 8,
			height: "80px",
			boxShadow: "0 0 0 1px rgba(91, 91, 91, 0.5)",

			"&:hover": {
				boxShadow: "0 0 0 1px #1A0638",
			},
			"&.Mui-focused": {
				boxShadow: `0 0 0 2px ${theme.palette.primary.main} !important`,
			},
		},
		"& .MuiInput-underline:before ": {
			border: "none !important",
		},
		"& .MuiInputBase-input": {
			color: "#5B5B5B !important",
			paddingLeft: "12px",
		},
	},
}));

export default CreateNewBrandDialog;
