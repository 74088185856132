import { useTheme } from "@material-ui/core";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PlusIcon = (props: SvgIconProps) => {
	const theme = useTheme();
	return (
		<SvgIcon width="10" height="10" viewBox="0 0 12 12" fill="none" {...props}>
			<path
				d="M9.66536 5.66683H5.66536V9.66683H4.33203V5.66683H0.332031V4.3335H4.33203V0.333496H5.66536V4.3335H9.66536V5.66683Z"
				fill={theme.palette.primary.dark}
			/>
		</SvgIcon>
	);
};

export default PlusIcon;
