import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import RouterConstants from "../Constants/RouterConstants";
import Context from "../Context/Context";
import { getExportUploadedVhashesToCsv, getStartImageOnboarding } from "../GraphQL/queries";
import { startDownload } from "../Helpers/images";
import Analytics, { ANALYTICS_EVENTS, HUBSPOT_EVENTS } from "../Models/Analytics";
import { IsIframe, sendPostMessage } from "../Helpers/iframe";
import { getCurrentRoute, uploadImageViaApi } from "../Components/Tools/utilities";
import { PagesEnum } from "../Models/ToolsConfig";
import { IframePostMessageTypes } from "../Constants/IframeConstants";
import { IframeDestinationEnum } from "../types/graphql-global-types";

export interface FileWithPreview extends File {
	preview?: string;
}

interface ProgrssInfo {
	percentage: number;
	fileName: string;
}

function useUploadFile() {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const fromPlatformHub = searchParams.get("from_platform_hub") == "true";

	const history = useHistory();
	const currentRoute = getCurrentRoute();
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	const [allowMultipleFiles, setAllowMultipleFiles] = React.useState(currentRoute?.allowMultipleUpload ?? false);
	const [files, setFiles] = React.useState<FileWithPreview[]>([]);
	const [uploading, setUploading] = React.useState(false);
	const [succesfullyUploadedVhashes, setSuccesfullyUploadedVhashes] = React.useState<string[]>([]);
	const [uploadingDone, setUploadingDone] = React.useState(false);
	const [uploadingError, setUploadingError] = React.useState(false);
	const [uploadProgress, setUploadProgress] = React.useState(0);
	const [progressInfos, setProgressInfos] = React.useState<ProgrssInfo[]>([]);
	const [isImageOpened, setIsImageOpened] = React.useState(false);
	const [succesfullyUploadedImages, setSuccesfullyUploadedImages] = React.useState(0);
	const [failedUploadedFiles, setFailedUploadedFiles] = useState<any>([]);
	const [failedToUploadImages, setFailedToUploadImagesCounter] = React.useState(0);
	const [cancelRequests, setCancelRequests] = useState(false);
	const [imagesNamesUploadError, setImagesNamesUploadError] = React.useState<string[]>([]);
	const cancelButtonRef = useRef();
	const [showUploadProgressPopup, setShowUploadProgressPopup] = useState<boolean>(false);
	const [organizationChecked, setOrganizationChecked] = useState(
		isIframe() && context.iframeConfig?.uploadDestination === IframeDestinationEnum.ORGANIZATION
	);
	const [assetsChecked, setAssetsChecked] = useState(
		!isIframe() || (isIframe() && context.iframeConfig?.uploadDestination === IframeDestinationEnum.USER)
	);
	const selectedOrganization = context.selectedOrganization;
	const displayUploadForm = context.displayUpload;
	const setDisplayUploadForm = (value: boolean, history: any) => {
		context.setUploadState(value, history);
	};
	const closeUploadFormAndStatusPopup = () => {
		context.setUploadDialogExpandFlag(true);
		setShowUploadProgressPopup(false);
		handleCloseUploadForm();
	};
	const handleCloseUploadForm = () => {
		if (!uploading) {
			reset();
		}
	};
	useEffect(() => {
		if (!uploadingDone && files.length > 0) {
			context.setUploadDialogExpandFlag(true);
			setShowUploadProgressPopup(true);
		}
	}, [displayUploadForm]);
	const onDrop = (acceptedFiles: any) => {
		setFiles(
			acceptedFiles.map((file: any) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);
	};

	const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
		onDrop,
		maxFiles: 1000,
		multiple: allowMultipleFiles,
		accept: "image/jpeg, image/png, image/webp",
	});

	const error: boolean = fileRejections.length > 0;

	const handleErrorUploadingSingleImage = async () => {
		setUploading(false);
		setUploadingDone(true);
		setUploadingError(true);
	};

	const handleUploadFinish = () => {
		setUploading(false);
		setUploadingDone(true);
		setUploadProgress(0);
	};

	const fetchCsvFile = async (vhashList: string[]) => {
		const csvFile = await getExportUploadedVhashesToCsv(vhashList);
		if (csvFile) {
			await startDownload(csvFile?.csvFile, "uploadCsvFile", "images_report.csv");
		}
	};

	useEffect(() => {
		if (uploadingDone) {
			if (files.length === succesfullyUploadedImages) {
				Analytics.logEvent(ANALYTICS_EVENTS.SUCCESSFUL_BULK_UPLOAD);
			} else {
				Analytics.logEvent(ANALYTICS_EVENTS.AT_LEAST_ONE_FAILED_BULK_UPLOAD);
			}
			Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_UPLOAD);
			if (files.length === 1 && succesfullyUploadedImages === 1) {
				const pathname = RouterConstants.GALLERY.path;
				sendPostMessage(
					IframePostMessageTypes.GalleryImageOpen,
					{},
					succesfullyUploadedVhashes[0],
					context?.iframeConfig
				);
				const path: string = `${pathname}${succesfullyUploadedVhashes[0]}${
					fromPlatformHub ? `?from_platform_hub=${fromPlatformHub}` : ""
				}`;
				if (isIframe()) {
					if (
						context.iframeConfig?.enabledPages?.[PagesEnum.Playground] &&
						!context.iframeConfig.disableNav
					) {
						history.push(path);
					}
					setShowUploadProgressPopup(false);
					setIsImageOpened(true);
					reset();
				} else {
					// WAI-1774
					window.location.assign(path);
				}
			} else if (files.length > 1 && succesfullyUploadedImages > 0) {
				sendPostMessage(IframePostMessageTypes.AssetsOpen, {}, "", context?.iframeConfig);
				if (context.user?.isAdmin()) {
					fetchCsvFile(succesfullyUploadedVhashes).then(() => {
						closeUploadFormAndStatusPopup();
						if (window.location.pathname === RouterConstants.ASSETS_PAGE.path) {
							window.location.reload();
						} else {
							history.push(RouterConstants.ASSETS_PAGE.path, {
								assetsToggle: assetsChecked && !organizationChecked,
							});
						}
					});
				} else {
					closeUploadFormAndStatusPopup();
					if (!isIframe() || (isIframe() && !context.iframeConfig?.disableNav)) {
						if (window.location.pathname === RouterConstants.ASSETS_PAGE.path) {
							window.location.reload();
						} else {
							history.push(RouterConstants.ASSETS_PAGE.path, {
								assetsToggle: assetsChecked && !organizationChecked,
							});
						}
					}
				}
			}
		}
	}, [uploadingDone]);

	const handleMultipleImageUploadError = (imageName: string | undefined) => {
		setImagesNamesUploadError((imagesNamesUploadError) => [...imagesNamesUploadError, imageName ?? ""]);
	};

	const reset = () => {
		setDisplayUploadForm(false, history);
		setImagesNamesUploadError([]);
		setFiles([]);
		setUploadingDone(false);
		setUploading(false);
		setUploadingError(false);
		setSuccesfullyUploadedVhashes([]);
		setSuccesfullyUploadedImages(0);
		setFailedUploadedFiles(0);
		setFailedToUploadImagesCounter(0);
		setSuccesfullyUploadedImages(0);
		setOrganizationChecked(
			isIframe() && context.iframeConfig?.uploadDestination === IframeDestinationEnum.ORGANIZATION
		);
		setAssetsChecked(
			!isIframe() || (isIframe() && context.iframeConfig?.uploadDestination === IframeDestinationEnum.USER)
		);
		setProgressInfos([]);
		setCancelRequests(false);
	};
	useEffect(() => {
		if (cancelRequests) cancelButtonRef.current = "stop";
	}, [cancelRequests]);
	const handleCancelRequests = async () => {
		await setCancelRequests(true);
	};
	const uploadImageApi = async (
		file: any,
		_progressInfos: any,
		countsTraking: {
			numOfValidAttemps: number;
			succesfullyUploadedImagesCounter: number;
			succesfullyUploadedImages: Array<any>;
		},
		clientEmailAdress: any,
		generated: any,
		orgId?: string,
		uploadToUserAssets?: boolean
	) => {
		const resp = await uploadImageViaApi(file, orgId, uploadToUserAssets);
		const res = resp.data as any;
		_progressInfos[files.indexOf(file)].percentage = 100;
		setProgressInfos([..._progressInfos]);

		let success: boolean = false;
		if (res && res.visualId && res.status) {
			countsTraking.numOfValidAttemps--;

			if (res.status === 200 || res.status === 208) {
				await getStartImageOnboarding(res.visualId, clientEmailAdress, generated);
				Analytics.logEvent(ANALYTICS_EVENTS.IMAGE_UPLOAD_COMPLETE);
				setSuccesfullyUploadedVhashes((successfullyUploadedVhashes) => [
					...successfullyUploadedVhashes,
					res.visualId ?? "",
				]);
			}
			countsTraking.succesfullyUploadedImagesCounter++;
			countsTraking.succesfullyUploadedImages.push(file.name);
			success = true;
		}
	};

	const onUploadImage = async (generated: boolean = false) => {
		cancelButtonRef.current = "";
		setUploading(true);
		const clientEmailAdress = getAuth().currentUser?.email ?? "";
		let localUploadProgress: number = 0;
		let counter: number = 0;
		let failedToUploadImagesCounter: number = 0;
		const selectedFiles = files;
		let countsTraking = {
			succesfullyUploadedImagesCounter: 0,
			numOfValidAttemps: files.length,
			succesfullyUploadedImages: [],
		};
		let _progressInfos: ProgrssInfo[] = [];
		let faildUploadedFiles: any = [];
		for (let i = 0; i < selectedFiles.length; i++) {
			_progressInfos.push({
				percentage: 0,
				fileName: selectedFiles[i].name,
			});
		}
		await setProgressInfos(_progressInfos);
		await setFailedUploadedFiles(faildUploadedFiles);
		const queue: Promise<void>[] = [];
		let orgId: string;
		if (organizationChecked) {
			orgId =
				selectedOrganization && selectedOrganization.uid.length > 0
					? selectedOrganization.uid
					: isIframe()
					? context.iframeConfig?.organization ?? ""
					: context.user?.organizations[0]?.org_uid ?? "";
		}

		const requestsToMake = files.map((file: any, index) => async () => {
			try {
				await uploadImageApi(
					file,
					_progressInfos,
					countsTraking,
					clientEmailAdress,
					generated,
					orgId,
					assetsChecked
				);
			} catch (error: any) {
				Analytics.logEvent(ANALYTICS_EVENTS.IMAGE_UPLOAD_FAILED, {
					reason: "error",
				});

				countsTraking.numOfValidAttemps--;
				handleMultipleImageUploadError(`${file.name}: ${error}`);
				failedToUploadImagesCounter++;
				faildUploadedFiles.push(file.name);
				if (files.length === 1) {
					setFailedUploadedFiles(faildUploadedFiles);
					faildUploadedFiles = files
						.filter((file: any) => {
							return !countsTraking.succesfullyUploadedImages.includes(file.name);
						})
						.map((file) => {
							_progressInfos[files.indexOf(file)].percentage = 100;
							return file.name;
						});
					setProgressInfos([..._progressInfos]);
					setSuccesfullyUploadedImages(countsTraking.succesfullyUploadedImagesCounter);
					setFailedToUploadImagesCounter(failedToUploadImagesCounter);
					handleErrorUploadingSingleImage();
					return Promise.reject(error);
				}
			}
		});
		counter++;
		localUploadProgress = 100 * (counter / files.length);
		setUploadProgress(localUploadProgress);
		let cancelClicked = false;
		for (let requestToMake of requestsToMake) {
			// fire the async function, add its promise to the queue,
			// and remove it from queue when complete
			if (cancelButtonRef.current === "stop") {
				faildUploadedFiles = files
					.filter((file: any) => {
						return !countsTraking.succesfullyUploadedImages.includes(file.name);
					})
					.map((file) => {
						_progressInfos[files.indexOf(file)].percentage = 100;
						return file.name;
					});
				setProgressInfos([..._progressInfos]);

				setFailedUploadedFiles(faildUploadedFiles);
				setSuccesfullyUploadedImages(countsTraking.succesfullyUploadedImagesCounter);
				setFailedToUploadImagesCounter(failedToUploadImagesCounter + faildUploadedFiles.length);
				handleUploadFinish();
				cancelClicked = true;
				break;
			}
			const promise = requestToMake().then((res) => {
				queue.splice(queue.indexOf(promise), 1);
				if (queue.length === 0 && !cancelClicked) {
					faildUploadedFiles = files
						.filter((file: any) => {
							return !countsTraking.succesfullyUploadedImages.includes(file.name);
						})
						.map((file) => {
							_progressInfos[files.indexOf(file)].percentage = 100;
							return file.name;
						});
					setProgressInfos([..._progressInfos]);
					setFailedUploadedFiles(faildUploadedFiles);
					setSuccesfullyUploadedImages(countsTraking.succesfullyUploadedImagesCounter);
					setFailedToUploadImagesCounter(failedToUploadImagesCounter);
					handleUploadFinish();
				}
				return res;
			});
			queue.push(promise);
			// if the number of queued requests matches our limit then
			// wait for one to finish before enqueueing more
			if (queue.length >= 5) {
				await Promise.race(queue);
			}
		}
	};

	return {
		showUploadProgressPopup,
		setShowUploadProgressPopup,
		handleCancelRequests,
		getRootProps,
		getInputProps,
		isDragActive,
		setAssetsChecked,
		assetsChecked,
		files,
		isImageOpened,
		setFiles,
		error,

		setOrganizationChecked,
		progressInfos,
		displayUploadForm,
		setDisplayUploadForm,
		failedUploadedFiles,
		onUploadImage,
		uploadingDone,
		uploading,
		organizationChecked,
		succesfullyUploadedImages,
		failedToUploadImages,
		closeUploadFormAndStatusPopup,
		handleCloseUploadForm,
		allowMultipleFiles,
	};
}

export default useUploadFile;
