import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import AccordionExpandIcon from "../../assets/icons/AccordionExpandIcon";
import ConsumptionFeeInfoTable from "./ConsumptionFeeInfoTable";
import { ApiSubscriptionTypes } from "../../Constants/SubscriptionConstants";

const QAList = () => {
	const { t } = useTranslation("translation", { keyPrefix: "qaList" });
	const classes = useStyles();
	const QAlist: {
		question: string;
		answer: string;
		showFeeInfoTableBelowForPlan?: string;
	}[] = Object.values(t("list", { returnObjects: true }));
	return (
		<Box className={classes.container}>
			<Typography className={classes.title}>{t("title")}</Typography>
			<Box className={classes.list}>
				{QAlist.map(({ question, answer, showFeeInfoTableBelowForPlan }) =>
					question && answer ? (
						<Accordion key={question} className={classes.question} elevation={0}>
							<AccordionSummary expandIcon={<AccordionExpandIcon />}>
								<Typography className={classes.summary}>{question}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box className={classes.answerContainer}>
									<Typography className={classes.answer}>{answer}</Typography>
									{showFeeInfoTableBelowForPlan && (
										<ConsumptionFeeInfoTable
											plan={showFeeInfoTableBelowForPlan as ApiSubscriptionTypes}
										/>
									)}
								</Box>
							</AccordionDetails>
						</Accordion>
					) : (
						<></>
					)
				)}
			</Box>
		</Box>
	);
};
const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		gap: "71px",
		padding: "120px 80px 100px 80px",
	},
	title: {
		maxWidth: 412,
		fontSize: 40,
		fontWeight: 700,
		lineHeight: "49px",
		textAlign: "left",
		color: "rgba(26, 6, 56, 1)",
	},
	list: {
		display: "flex",
		flexDirection: "column",
		gap: "28px",
		width: "fit-content",
	},
	question: {
		background: "transparent",
		"& .MuiAccordionSummary-root": {
			flexDirection: "row-reverse",
			gap: "14px",
		},
		"&.MuiAccordion-root:before": {
			display: "none",
		},
		"& .MuiAccordionDetails-root": {
			padding: "0 16px",
		},
		"&.MuiPaper-elevation1": {
			boxShadow: "none !important",
		},
		"&.MuiPaper-root:before": {
			display: "none !important",
		},
		"& .MuiAccordionSummary-root.Mui-expanded": {
			minHeight: 0,
		},
		"&.Mui-expanded": {
			minHeight: 0,
			margin: 0,
		},
		"& .MuiAccordionSummary-content.Mui-expanded": {
			margin: "12px 0",
		},
		"& .MuiAccordionSummary-expandIcon": {
			transition: "none",
			"&.Mui-expanded": {
				transform: "none",
			},
		},
	},
	summary: {
		fontSize: 20,
		fontWeight: 700,
		lineHeight: "24px",
		letterSpacing: "0em",
		textAlign: "left",
		color: "rgba(26, 6, 56, 1)",
	},
	answer: {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "24px",
		letterSpacing: "0px",
		textAlign: "left",
		color: "rgba(91, 91, 91, 1)",
		paddingLeft: "46px",
	},
	answerContainer: {
		"& .MuiTable-root": {
			width: "auto",
			margin: "10px 0 0 46px",
		},
	},
}));

export default QAList;
