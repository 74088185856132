import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function Brush(props: SvgIconProps) {
	return (
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.54785 10C6.39593 7.75416 7.83463 5.77895 9.71206 4.28287C11.5895 2.78679 13.836 1.82533 16.2145 1.5C15.8892 3.87849 14.9274 6.12502 13.4313 8.00245C11.9352 9.87989 9.96003 11.3186 7.71419 12.1667M7.54777 6.5C9.16707 7.24739 10.467 8.54736 11.2144 10.1667M1.21436 16.5V13.1667C1.21436 12.5074 1.40985 11.8629 1.77612 11.3148C2.14239 10.7666 2.66299 10.3394 3.27208 10.0871C3.88116 9.83478 4.55139 9.76877 5.19799 9.89738C5.84459 10.026 6.43854 10.3435 6.90471 10.8096C7.37089 11.2758 7.68836 11.8698 7.81697 12.5164C7.94559 13.163 7.87958 13.8332 7.62729 14.4423C7.375 15.0514 6.94775 15.572 6.39959 15.9382C5.85143 16.3045 5.20696 16.5 4.54769 16.5H1.21436Z"
				stroke={props.color ? props.color : "#1A0638"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default Brush;
