import { allOrganizations_allOrganizations } from "../GraphQL/types/allOrganizations";

const safeJsonParse = (value: string) => {
	let result = "";
	try {
		result = JSON.parse(value);
	} catch (error) {
		result = "";
	}
	return result;
};

export const retriveFromLS = (key: string): any => {
	// LS for localStorage
	const result: string = localStorage.getItem(key) ?? "";
	const isObject = typeof safeJsonParse(result) === "object";
	const objectParsed = safeJsonParse(result);
	return isObject ? objectParsed : result;
};

export const storeAtLS = (key: string, value: any) => {
	const isObject = typeof value === "object";
	const stringifiedValue = isObject ? JSON.stringify(value) : value;
	localStorage.setItem(key, stringifiedValue);
};

export const getSelectedOrganizationFromLocalStorage = () => {
	const selectedOrg = localStorage.getItem("selected_org");
	return selectedOrg ? (JSON.parse(selectedOrg) as allOrganizations_allOrganizations) : undefined;
};
