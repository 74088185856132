import { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Context from "../../Context/Context";
import { Divider } from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import clsx from "clsx";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import { ToolsSlider, ToolsThumbnails } from "../../Models/ToolsConfig";
import ThumbnailCardNew from "../UI/ThumbnailCardNew";
import { SelectedPointInterface, ToolsViewerMode } from "./utilities";

import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import { getValueFromNormalizedRangeBaseTen } from "../../Helpers/math";
import ToolsEmptyState from "../UI/ToolsEmptyState";
import { ToolsActionContext } from "./ToolsAction";

interface IProps {
	selectedPoint: SelectedPointInterface | any | null;
	visualHash: string;
	disabled?: boolean;
	oracle?: any;
	selectedEthnicity: string;
	ethnicityValue?: number;
	onChangeEthnicity: (ethnicty: string, value: number) => void;
	thumbnails: ToolsThumbnails[];
	onAddSemanticCombination: (ethnicity: any, ethnicityValue: any, controller: any) => Promise<string>;
	drawerAnchor: DrawerProps["anchor"];
	sliders: ToolsSlider[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		thumbnailsContainer: {
			height: "100%",
			paddingLeft: 0,
			paddingRight: 0,
		},
		bottomThumbnailsView: {
			height: "100%",
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
		},
	})
);

export default function Localizer({
	visualHash,
	selectedPoint,
	disabled,
	oracle,
	ethnicityValue = 0,
	selectedEthnicity,
	onChangeEthnicity,
	thumbnails,
	drawerAnchor,
	sliders,
	onAddSemanticCombination,
}: IProps) {
	const cachedThumbsKey = "MODELS"; // could also be passed from ThumbnailList props if u wanna use it with other tabs

	const { cachedThumbnails, setCachedThumbnails } = useContext(ToolsActionContext);

	const classes = useStyles();
	const { preferences, user, systemView } = useContext(Context);
	const [count, setCount] = useState(6);
	const { t } = useTranslation();
	const currentStyle = preferences?.style;
	const [selectedGridImage, setSelectedGridImage] = useState<number>(-1);
	const appearanceCachedThumbs = (cachedThumbnails as any)?.[cachedThumbsKey] || [];

	const mapSliders = (value: number, el: ToolsSlider) => {
		return (
			<Box maxWidth={"100%"} width="100%" mb={4.2} mr={2.5} key={el.id}>
				<ThumbnailSlider
					key={el.id}
					id={el.id}
					disabled={disabled || el.disabled}
					defaultValue={el.defaultInitialValue}
					maxValue={el.maxValue}
					minValue={el.minValue}
					leftSubtitle={el.minName}
					rightSubtitle={el.maxName}
					value={value}
					reversed={el.reversed}
					title={el.displayName}
					warrning={el.warning}
					error={el.error}
					minNameRiskArea={el.minNameRiskArea}
					maxNameRiskArea={el.maxNameRiskArea}
					sliderDefaultValue={el.defaultInitialValue}
					handleValueChange={(value) => {
						onChangeEthnicity(el.id, value);
					}}
				/>
			</Box>
		);
	};

	const updateThumbnailInfo = (key: string, url: string) => {
		const newThumbs = thumbnails.map((thumb: any) => {
			if (thumb.key === key) {
				thumb.thumbUrl = url;
				return thumb;
			}
			return thumb;
		});
		if (!newThumbs.length) return;
		setCachedThumbnails(cachedThumbsKey, newThumbs);
	};

	const deletedSavedThumbUrl = () => {
		const afterDelete = appearanceCachedThumbs.map((thumb: any) => {
			thumb.thumbUrl = "";
			return thumb;
		});
		setCachedThumbnails(cachedThumbsKey, afterDelete);
	};

	const findThumbUrl = (el: any) => {
		const foundInAppearanceThumbnails: any = appearanceCachedThumbs.find((thumb: any) => thumb.key === el.key);
		return foundInAppearanceThumbnails?.thumbUrl;
	};

	const thumbnailsList = (
		<Box
			flex={1}
			className={clsx({
				[classes.thumbnailsContainer]: drawerAnchor !== "bottom",
				[classes.bottomThumbnailsView]: drawerAnchor === "bottom",
			})}
		>
			{sliders && sliders.length > 0 ? (
				<Box display="flex" flexWrap="wrap">
					{thumbnails.slice(0, count < thumbnails.length ? count : thumbnails.length).map((el, index) => {
						return (
							<ThumbnailCardNew
								uniqueKey={`${visualHash}-${selectedPoint?.id}-${el.key}`}
								key={el.key}
								index={index}
								onAddSemanticCombination={(controller) => {
									return onAddSemanticCombination(
										el.sliders[0].id,
										getValueFromNormalizedRangeBaseTen(
											el.sliders[0].minValue,
											el.sliders[0].maxValue,
											el.sliders[0].value
										),
										controller
									);
								}}
								onUrlFetched={(url) => updateThumbnailInfo(el.key, url)}
								thumbnail={el}
								selectedGridImage={selectedGridImage}
								setSelectedGridImage={setSelectedGridImage}
								onClick={() => {
									if (!disabled) {
										setSelectedGridImage(index);

										onChangeEthnicity(
											el.sliders[0].id,
											getValueFromNormalizedRangeBaseTen(
												el.sliders[0].minValue,
												el.sliders[0].maxValue,
												el.sliders[0].value
											)
										);
									}
								}}
								thumbUrl={findThumbUrl(el)}
								warrning={el.sliders.some((el) => el.warning)}
								error={el.sliders.some((el) => el.disabled ?? el.error)}
							>
								<>
									{el.sliders.map((slider) => {
										const value = getValueFromNormalizedRangeBaseTen(
											slider.minValue,
											slider.maxValue,
											el.sliders[0].value
										);
										return mapSliders(value, slider);
									})}
								</>
							</ThumbnailCardNew>
						);
					})}
					{count < thumbnails.length && (
						<Box width="100%" display="flex" justifyContent="center">
							<BriaButton
								buttonType={BUTTONTYPES.TEXTSMALL}
								onClick={() => {
									setCount(count + 6);
								}}
							>
								{t("loadMore")}
							</BriaButton>
						</Box>
					)}
				</Box>
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	let validSliders = sliders?.filter((el) => !el.hideSlider);

	const ethnicityRadioList = (
		<Box flex={1} overflow="auto">
			{validSliders && validSliders.length > 0 ? (
				validSliders.map((slider) => {
					const value = slider.id === selectedEthnicity ? ethnicityValue : slider.defaultInitialValue;
					return mapSliders(value, slider);
				})
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	return (
		<>
			<Box
				className={classes.root}
				display={currentStyle === ToolsViewerMode.sliders ? "unset" : "none !important"}
				maxHeight={currentStyle === ToolsViewerMode.sliders ? "inherit" : 0}
			>
				{ethnicityRadioList}
			</Box>
			{currentStyle === ToolsViewerMode.both && <Divider />}
			<Box
				className={classes.root}
				display={currentStyle === ToolsViewerMode.thumbnails ? "unset" : "none !important"}
			>
				{thumbnailsList}
			</Box>
		</>
	);
}
