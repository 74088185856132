import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { GoogleAuthProvider } from "firebase/auth";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import ErrorIcon from "../../../assets/icons/ErrorIcon";
import Google from "../../../assets/icons/Google";
import Agreement from "../../../Components/Login/Agreement";
import BriaInput from "../../../Components/UI/BriaInput";
import Context from "../../../Context/Context";
import { useLoginService } from "../../../hooks/useLoginService";
import { getCustomAuthProviderButton } from "../../../Helpers/AuthHelpers";
import Github from "../../../assets/icons/Github";
import clsx from "clsx";
import firebase from "firebase/compat";
import GithubAuthProvider = firebase.auth.GithubAuthProvider;

interface IProps {}

const RegisterForm: React.FC<IProps> = () => {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();
	const { sendEmailVerificationFunction } = useLoginService();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [getUpdated, setGetUpdated] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<boolean | string>(false);
	const [email, setEmail] = React.useState<string>("");

	const initialValues = {
		username: "",
		email: "",
		password: "",
	};

	const handleSubmit = (values: any) => {
		setIsLoading(true);
		context
			.registerWithEmail(
				values.username,
				values.email ? values.email : email,
				values.password,
				undefined,
				undefined,
				undefined,
				true
			)
			.then(() => {
				setIsLoading(false);
				context.setAuthDialogStep("AfterRegistrationContent");
				context.user!.updated = !context.user!.updated;
			})
			.catch((error) => {
				setErrorMessage(error.code);
				setIsLoading(false);
			});
	};

	const handleContinue = (values: any) => {
		setEmail(values.email);
		setAuthDialogStep("RegisterInfo");
	};

	const setAuthDialogStep = (value: string) => {
		context.setAuthDialogStep(value);
	};

	return (
		<Container component="main" maxWidth={false}>
			<Box className={classes.credentials_content}>
				<Box px={1.25} display="flex" flexDirection="column" alignItems="center" className={classes.content}>
					{!email ? (
						<>
							<Typography className={classes.typo1}>{t("getStartedWithBria")}</Typography>
							<Box className={classes.signInWithContainer}>
								<Button
									variant="outlined"
									startIcon={<Google className={classes.icon} />}
									onClick={async () => {
										if (!isLoading) {
											let userInfo = await context.registerViaAuthProvider(
												new GoogleAuthProvider(),
												true
											);
											if (!userInfo!) {
												context.setAuthDialogStep("UserInfo");
												context.user!.updated = !context.user!.updated;
											} else {
												context.setAuthDialogStep("Login");
												context.setShowLoginForm(false);
											}
										}
									}}
									className={classes.button}
								>
									{t("signUpWithGoogle")}
								</Button>
								{!context.customAuthProvider ? (
									<Button
										variant="outlined"
										startIcon={<Github className={classes.icon} />}
										onClick={async () => {
											if (!isLoading) {
												let userInfo = await context.registerViaAuthProvider(
													new GithubAuthProvider(),
													true
												);
												if (!userInfo!) {
													context.setAuthDialogStep("UserInfo");
													context.user!.updated = !context.user!.updated;
												} else {
													context.setAuthDialogStep("Login");
													context.setShowLoginForm(false);
												}
											}
										}}
										className={classes.button}
									>
										{t("signUpWithGithub")}
									</Button>
								) : (
									getCustomAuthProviderButton(
										context,
										isLoading,
										clsx(classes.button),
										t("signUpWith") + context.customAuthProvider.name
									)
								)}
							</Box>
							<Typography className={classes.typo}>{t("or")}</Typography>
							<Formik
								initialValues={initialValues}
								validateOnMount={false}
								validateOnChange={false}
								validateOnBlur={false}
								validationSchema={Yup.object().shape({
									email: Yup.string().email(t("invalidEmail")).required(t("emailIsRequired")),
								})}
								onSubmit={(values) => {
									handleContinue(values);
								}}
							>
								{({ values, errors, touched, handleChange, handleSubmit, isValid }) => (
									<Form
										className={classes.form}
										onKeyDown={(event) => {
											if (event.key === "Enter" && isValid) {
												handleContinue(values);
											}
										}}
										noValidate
									>
										<Box className={classes.formElements}>
											<BriaInput
												id="email"
												label={t("email")}
												onChange={handleChange}
												value={values.email}
												error={!!errors.email}
												helperText={errors.email}
												className={classes.textField}
												buttonSource="popup"
											/>

											{errorMessage && (
												<Typography className={classes.error} color="secondary">
													<ErrorIcon className={classes.errorIcon} />
													{t(`${errorMessage}`)}
												</Typography>
											)}

											<Button
												onClick={() => handleSubmit(this)}
												variant="contained"
												color="secondary"
												className={clsx(classes.button, classes.ctaButton)}
											>
												{t("signUp")}
											</Button>
										</Box>
									</Form>
								)}
							</Formik>
						</>
					) : (
						<>
							<Typography className={classes.typo1}>{t("howDidYouHearAboutUs")}</Typography>
							<Formik
								initialValues={initialValues}
								validateOnMount={false}
								validateOnChange={false}
								validateOnBlur={false}
								validationSchema={Yup.object().shape({
									username: Yup.string().required(t("fullNameIsRequired")),
									password: Yup.string()
										.min(6, t("mustBe6OrMoreCharacters"))
										.required(t("passwordIsRequired")),
								})}
								onSubmit={handleSubmit}
							>
								{({ values, errors, touched, handleChange, handleBlur, isValid }) => (
									<Form className={classes.form} noValidate>
										<Box className={classes.formElements}>
											<BriaInput
												id="username"
												label={t("fullName")}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.username}
												error={!!touched.username && !!errors.username}
												helperText={touched.username ? errors.username : ""}
												className={classes.textField}
												buttonSource="popup"
											/>

											<BriaInput
												id="password"
												label={t("password")}
												type="password"
												autoComplete="current-password"
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
												error={!!touched.password && !!errors.password}
												helperText={touched.password ? errors.password : ""}
												className={classes.textField}
												buttonSource="popup"
											/>

											<Box width="100%">
												<Agreement getUpdated={getUpdated} setGetUpdated={setGetUpdated} />
											</Box>

											<Button
												type="submit"
												variant="contained"
												color="secondary"
												className={clsx(classes.button, classes.ctaButton)}
											>
												{t("getStarted")}
												<Box display="flex" position="absolute" right={"10px"}>
													{isLoading && <CircularProgress color="inherit" size={25} />}
												</Box>
											</Button>

											{errorMessage && (
												<Typography className={classes.error} color="secondary">
													<ErrorIcon className={classes.errorIcon} />
													{t(`${errorMessage}`)}
												</Typography>
											)}
										</Box>
									</Form>
								)}
							</Formik>
						</>
					)}
					{!email && (
						<Typography className={classes.typo2}>
							{t("alreadyHaveAnAccount?")}
							<RouterLink
								onClick={() => {
									setAuthDialogStep("Login");
								}}
								to={{
									pathname: "",
									search: window.location.search,
								}}
								className={classes.signin}
							>
								{t("logIn")}
							</RouterLink>
						</Typography>
					)}
				</Box>
			</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme) => {
	return {
		form: {
			width: "100%",
			// height: 280,
			"& .MuiSelect-selectMenu": {
				marginTop: "15px",
			},
		},
		formElements: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			rowGap: theme.spacing(3),
		},
		button: {
			borderRadius: 8,
			width: "100%",
			height: 56,
			position: "relative",
			textTransform: "capitalize",
			// whiteSpace: "nowrap",
		},
		ctaButton: {
			marginTop: theme.spacing(0),
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: theme.palette.primary.dark,
			"&:hover, &:active, &:focus, &:visited": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
		button1: {
			borderRadius: 20,
			width: "55%",
			maxWidth: 440,
			height: 40,
			textTransform: "capitalize",
		},
		inputLabel: {
			color: "#495057",
		},
		icon: {
			fontSize: 20,
		},
		signin: {
			color: theme.palette.primary.dark,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},
		typo: {
			position: "relative",
			width: "100%",
			textAlign: "center",
			fontSize: 16,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			opacity: 0.5,
			margin: theme.spacing(3, 0),
			"&:before": {
				left: 0,
				width: "45%",
				bottom: "calc(50% - 1px)",
				height: 1,
				content: "''",
				display: "inline-block",
				position: "absolute",
				backgroundColor: "#B3B3B980",
			},
			"&:after": {
				right: 0,
				width: "45%",
				bottom: "calc(50% - 1px)",
				height: 1,
				content: "''",
				display: "inline-block",
				position: "absolute",
				backgroundColor: "#B3B3B980",
			},
		},
		typo1: {
			fontSize: 32,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(6),
			whiteSpace: "nowrap",
		},
		signInWithContainer: {
			display: "flex",
			columnGap: 10,
			width: "100%",
		},
		typo2: {
			fontSize: 16,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
			marginTop: theme.spacing(6),
		},
		typo3: {
			fontSize: 16,
			fontWeight: theme.typography.fontWeightRegular,
			color: "#979797",
			display: "inline-block",
		},
		link: {
			fontSize: 16,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
			textDecoration: "none",
			cursor: "pointer",

			"&:hover": {
				textDecoration: "none",
			},
		},
		textField: {
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				minWidth: "unset",
			},
		},
		dialogTitle: { padding: 0 },
		dialogContent: { padding: "0 40px 40px 40px" },
		title: {
			fontSize: 24,
			fontWeight: theme.typography.fontWeightBold,
		},
		close: {
			fontSize: 16,
			cursor: "pointer",
		},
		error: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightBold,
			maxWidth: 440,
		},
		errorIcon: {
			fontSize: 16,
			marginRight: 4,
			verticalAlign: "middle",
		},
		divider: {
			width: "33%",
			margin: 10,
		},
		content: {
			background: "white",
			width: "100%",
		},
		logo: {
			width: 141.09,
			height: 90.97,
		},
		credentials_content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-evenly",
			// width: 600,
			// minWidth: 600,
			maxWidth: 600,
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			background: "white",
			padding: "20px 60px",
			boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				padding: 0,
			},
		},
	};
});

export default RegisterForm;
