import { Box } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import Icon from "../../../../../Common/Icon/Icon";
import { TooltipedContent } from "../../../../../Common/Wrappers/TooltipedContent";
import ToggleViewBtn from "../ToggleViewBtn/ToggleViewBtn";
import CompareButton from "../../../../../UI/CompareButton";

interface _ImgTools {
	classes: ClassNameMap<any>;
	toggleCompareMode: (v: boolean) => void;
	isGrid: boolean;
	toggleGridMode: () => void;
	hideGrid?: boolean;
	compareMode?: boolean;
}

export default function ImgTools({
	classes,
	toggleCompareMode,
	isGrid,
	toggleGridMode,
	compareMode,
	hideGrid = false,
}: _ImgTools) {
	return (
		<Box className={classes.gridToggleIcon}>
			{!isGrid && (
				<CompareButton
					selectedValue={compareMode ? 1 : 2}
					tooltipPosition="bottom"
					hideLabel={true}
					onChange={(value) => toggleCompareMode(value === 1)}
				/>
			)}

			{!hideGrid && (
				<TooltipedContent
					tooltipText={clsx({
						"Focus View": isGrid,
						"Grid View": !isGrid,
					})}
					bottom
				>
					<Box onClick={toggleGridMode} className={classes.flex}>
						{isGrid ? <ToggleViewBtn /> : <Icon name="grid-icon" />}
					</Box>
				</TooltipedContent>
			)}
		</Box>
	);
}
