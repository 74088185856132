import { Box } from "@material-ui/core";
import CameraFocusImage from "../../assets/cameraFocusDemo.svg";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

interface IProps {
	focusArea: FocusArea;
	setFocusArea: (area: FocusArea) => void;
	disabled?: boolean;
	alwaysActiveArea?: string;
}

export type FocusArea =
	| "top_left"
	| "top_middle"
	| "top_right"
	| "center_left"
	| "center_middle"
	| "center_right"
	| "bottom_left"
	| "bottom_middle"
	| "bottom_right"
	| null;

const ImageFocusPoint: React.FC<IProps> = ({
	focusArea,
	setFocusArea,
	disabled,
	alwaysActiveArea,
}) => {
	const rows = 3;
	const columns = 3;
	const classes = useStyles({ rows, columns });
	const focusAreasOptions: FocusArea[] = [
		"top_left",
		"top_middle",
		"top_right",
		"center_left",
		"center_middle",
		"center_right",
		"bottom_left",
		"bottom_middle",
		"bottom_right",
	];

	return (
		<Box className={classes.root}>
			<img
				id="root_image"
				className={classes.img}
				src={CameraFocusImage}
				alt="Lets Bria it"
			/>
			<Box className={classes.gridContainer}>
				{focusAreasOptions.map((area, i) => (
					<Box
						className={clsx(
							classes.cell,
							!disabled && focusArea === area && classes.cellActive,
							disabled && classes.disabled,
							!disabled && alwaysActiveArea === area &&
								classes.cellAlwaysActive
						)}
						key={i}
						onClick={() => setFocusArea(area)}
					/>
				))}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles({
	root: {
		display: "grid",
		gridTemplateRows: "repeat(1, min-content)",
		gridTemplateColumns: "repeat(2, min-content)",
		minHeight: 0,
		minWidth: 0,
		marginBottom: 16,
		flex: "none",
	},
	img: {
		gridRow: "1 / 2",
		gridColumn: "1",
	},
	gridContainer: {
		gridRow: "1 / 2",
		gridColumn: "1",
		display: "grid",
		gridTemplateRows: (props: any) => `repeat(${props.rows}, 1fr)`,
		gridTemplateColumns: (props: any) => `repeat(${props.columns}, 1fr)`,
	},
	cell: {
		background: "rgba(41, 41, 41, 0.8)",
		border: "solid #fff",
		borderWidth: "0 1px 1px 0",
		position: "relative",
		cursor: "pointer",
		"&:after": {
			borderRadius: "50%",
			border: "2px solid #fff",
			width: "60%",
			height: "60%",
			margin: "auto",
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			boxShadow: "0px 2px 4px rgb(0 0 0 / 25%)",
		},
		"&:hover": {
			transition:
				"background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			background: "rgba(196, 196, 196, 0.57)",
			"&:after": {
				content: "''",
				borderColor: "#495057",
			},
		},
	},
	disabled: {
		pointerEvents: "none"
	},
	cellActive: {
		background: "none",
		"&:after": {
			content: "''",
			display: "block",
		},
	},
	cellAlwaysActive: {
		pointerEvents: "none",
		background: "none",
	},
});

export default ImageFocusPoint;
