import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";

export const useSegmentation = (
	context: any,
	objectsTabCanvasRef: any,
	setShowObjectsFollowUpTooltip: (value: boolean) => void,
	setObjectsTooltipPosition: (value: { x: number; y: number } | undefined) => void,
	objectsPanopticImage: ImageData | undefined,
	objectsMasks: HTMLImageElement[],
	onSegmentClick?: (e: any, mask: HTMLImageElement | undefined) => void,
	onMouseClick?: (
		e: any,
		mouseLocation: { x: number; y: number } | undefined,
		width: number | undefined,
		height: number | undefined
	) => void,
	imageWidth?: number,
	imageHeight?: number,
	imageUrl?: any
) => {
	const [mask, setMask] = useState<HTMLImageElement>();

	const getMask = (e: any) => {
		if (!objectsTabCanvasRef.current || !objectsPanopticImage || !objectsMasks || objectsMasks.length === 0) {
			return undefined;
		}
		const widthRatio = objectsPanopticImage.width / objectsTabCanvasRef.current.clientWidth;
		const heightRatio = objectsPanopticImage.height / objectsTabCanvasRef.current.clientHeight;
		const mousePosX = Math.round(e.offsetX * widthRatio);
		const mousePosY = Math.round(e.offsetY * heightRatio);
		const pixelIndex = (mousePosY * objectsPanopticImage.width + mousePosX) * 4;
		const maskIndex = objectsPanopticImage.data[pixelIndex] - 1;
		return objectsMasks[maskIndex];
	};

	const getClickedMouseLocation = (e: any) => {
		if (!objectsTabCanvasRef.current || !imageWidth || !imageHeight) {
			return undefined;
		}
		const widthRatio = imageWidth / objectsTabCanvasRef.current.clientWidth;
		const heightRatio = imageHeight / objectsTabCanvasRef.current.clientHeight;
		const mousePosX = Math.round(e.offsetX * widthRatio);
		const mousePosY = Math.round(e.offsetY * heightRatio);
		return { x: mousePosX, y: mousePosY };
	};

	const clickHandler = (e: any) => {
		const clickedMask = getMask(e);
		if (clickedMask) {
			onSegmentClick && onSegmentClick(e, clickedMask);
		} else {
			const clickedMouseLocation = getClickedMouseLocation(e);
			onMouseClick && onMouseClick(e, clickedMouseLocation, imageWidth, imageHeight);
		}
	};

	const drawSegment = useCallback(
		debounce((e) => {
			setObjectsTooltipPosition({ x: e.offsetX, y: e.offsetY });
			if (!objectsTabCanvasRef.current || !objectsPanopticImage || !objectsMasks || objectsMasks.length === 0) {
				return;
			}
			setMask(getMask(e));
		}, 0),
		[objectsTabCanvasRef, objectsMasks, objectsPanopticImage]
	);

	const handleMouseOut = useCallback(
		(e) => {
			if (!objectsTabCanvasRef.current) {
				return;
			}
			setShowObjectsFollowUpTooltip(false);
			setMask(undefined);
			setObjectsTooltipPosition({ x: 0, y: 0 });
		},
		[objectsTabCanvasRef]
	);

	const handleMouseEnter = useCallback(
		(e) => {
			if (!objectsTabCanvasRef.current) {
				return;
			}
			setShowObjectsFollowUpTooltip(!objectsPanopticImage || !objectsMasks || objectsMasks.length === 0);
		},
		[objectsTabCanvasRef, objectsMasks, objectsPanopticImage]
	);

	useEffect(() => {
		if (objectsTabCanvasRef.current) {
			objectsTabCanvasRef.current.addEventListener("mousemove", drawSegment);
			objectsTabCanvasRef.current.addEventListener("mouseup", clickHandler);
			objectsTabCanvasRef.current.addEventListener("mouseout", handleMouseOut);
			objectsTabCanvasRef.current.addEventListener("mouseenter", handleMouseEnter);
		}

		return () => {
			if (objectsTabCanvasRef.current) {
				objectsTabCanvasRef.current.removeEventListener("mouseup", clickHandler);
				objectsTabCanvasRef.current.removeEventListener("mousemove", drawSegment);
				objectsTabCanvasRef.current.removeEventListener("mouseout", handleMouseOut);
				objectsTabCanvasRef.current.removeEventListener("mouseenter", handleMouseEnter);
			}
		};
	}, [objectsTabCanvasRef, objectsMasks, objectsPanopticImage, imageUrl]);

	return { mask };
};
