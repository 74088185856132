import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

interface IProps {
	label: any;
	mainMenuOpen: boolean;
	expandIcon: any;
	highlightColor: string;
	left: boolean;
	MenuItemProps: any;
	onOpen?: () => void;
	onClose?: () => void;
}

class NestedMenuItem extends React.Component<IProps, any> {
	constructor(props: IProps) {
		super(props);
		const expandIconSize = this.props.MenuItemProps?.dense ? "small" : "default";
		this.state = {
			subMenuOpen: false,
			expandIconSize: expandIconSize,
			expandIcon: this.props.expandIcon ? (
				this.props.expandIcon
			) : props.left ? (
				<ArrowLeft fontSize={expandIconSize} />
			) : (
				<ArrowRight fontSize={expandIconSize} />
			),
			highlightColor: this.props.highlightColor ? this.props.highlightColor : "#dddddd",
			subMenuRef: React.createRef(),
			nestedMenuRef: React.createRef(),
		};
	}

	isSubmenuFocused = () => {
		const active = this.state.nestedMenuRef.current?.ownerDocument?.activeElement;
		return this.state.subMenuRef.current === active || !!this.state.subMenuRef.current?.contains(active as Node);
	};

	handleMouseEnter = (e: any) => {
		e.stopPropagation();
		this.setState({ subMenuOpen: true });
		this.state.nestedMenuRef.current.style.backgroundColor = this.state.highlightColor;
		this.props.onOpen && this.props.onOpen();
	};
	handleMouseLeave = () => {
		this.props.onClose && this.props.onClose();
		this.setState({ subMenuOpen: false });
		this.state.nestedMenuRef.current.style.backgroundColor = "white";
	};
	handleClick = (e: any) => {
		e.stopPropagation();
		if (this.state.subMenuOpen) {
			if (!this.isSubmenuFocused()) {
				this.props.onClose && this.props.onClose();
				this.setState({ subMenuOpen: false });
			}
		} else {
			this.props.onOpen && this.props.onOpen();
			this.setState({ subMenuOpen: true });
		}
	};
	handleFocus = (evt: any) => {
		if (evt.target === this.state.nestedMenuRef.current) {
			this.setState({ subMenuOpen: true });
			this.state.nestedMenuRef.current.style.backgroundColor = this.state.highlightColor;
			this.props.onOpen && this.props.onOpen();
		}
	};
	handleKeyDown = (evt: any) => {
		const arrowRight = this.props.left ? "ArrowLeft" : "ArrowRight";
		const arrowLeft = this.props.left ? "ArrowRight" : "ArrowLeft";
		const length = this.state.subMenuRef.current?.children.length;
		if (length && length > 0) {
			// When keyboard nav goes out of bounds, wrap around the current menu
			// and prevent parent menu from receiving the key input
			if (evt.target === this.state.subMenuRef.current?.children[length - 1] && evt.key === "ArrowDown") {
				evt.stopPropagation();
				this.state.subMenuRef.current?.children[0]?.focus();
			} else if (evt.target === this.state.subMenuRef.current?.children[0] && evt.key === "ArrowUp") {
				evt.stopPropagation();
				this.state.subMenuRef.current?.children[length - 1]?.focus();
			} else if (this.isSubmenuFocused()) {
				evt.stopPropagation();
			}
		}
		// Handle arrow key directions behaviour
		if (evt.key === arrowRight && !this.isSubmenuFocused()) {
			if (!this.state.subMenuOpen) {
				this.setState({ subMenuOpen: true });
				this.props.onOpen && this.props.onOpen();
			}
			this.state.subMenuRef.current?.children[0]?.focus();
			evt.stopPropagation();
		} else if (
			(evt.key === "ArrowDown" || evt.key === "ArrowUp") &&
			evt.target === this.state.nestedMenuRef.current
		) {
			this.setState({ subMenuOpen: false });
			this.state.nestedMenuRef.current.style.backgroundColor = "white";
			this.props.onClose && this.props.onClose();
		} else if (evt.key === arrowLeft) {
			this.state.nestedMenuRef.current?.focus();
			this.setState({ subMenuOpen: false });
			this.props.onClose && this.props.onClose();
		}
	};

	render() {
		return (
			<MenuItem
				ref={this.state.nestedMenuRef}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={this.handleClick}
				onFocus={this.handleFocus}
				// Root element must have a `tabIndex` attribute for keyboard navigation
				tabIndex={-1}
				onKeyDown={this.handleKeyDown}
				style={{ outline: "none", overflow: "hidden" }}
				{...this.props.MenuItemProps}
			>
				{this.props.label}
				{this.state.expandIcon}
				<Menu
					// set to pointerEvents to none to prevent menu from capturing
					// events meant for child elements
					style={{ pointerEvents: "none", overflow: "none" }}
					onMouseLeave={(evt) => {}}
					anchorEl={this.state.nestedMenuRef.current}
					anchorOrigin={{
						vertical: "top",
						horizontal: this.props.left ? "left" : "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: this.props.left ? "right" : "left",
					}}
					open={this.state.subMenuOpen && this.props.mainMenuOpen}
					onClose={() => {
						this.props.onClose && this.props.onClose();
						this.setState({ subMenuOpen: false });
					}}
					disableAutoFocus
					disableEnforceFocus
					disableRestoreFocus
				>
					<div ref={this.state.subMenuRef} style={{ pointerEvents: "auto" }}>
						{this.props.children}
					</div>
				</Menu>
			</MenuItem>
		);
	}
}

export default NestedMenuItem;
