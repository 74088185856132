import React from "react";
import { Box, makeStyles } from "@material-ui/core";

interface IColor {
	color: string;
	isSelected: boolean;
	prevSelected?: boolean;
	onClick: (color: string) => void;
	style?: React.CSSProperties;
	width?: number;
	isBrandPage?: boolean;
}

export const Color = ({
	color,
	isSelected,
	onClick,
	style,
	width = 20,
	prevSelected,
	isBrandPage = false,
}: IColor) => {
	const classes = useStyles({ width });
	return (
		<Box
			className={classes.circle}
			style={{
				borderColor: color,
				backgroundColor: isSelected ? color : "",
				...style,
			}}
			onClick={() => onClick(color)}
		>
			{isBrandPage?<div className={classes.point}></div>:""}
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	circle: {
		position: "relative",
		marginTop: "0.5rem",
		border: "2px solid",
		width: ({ width = 20 }: { width: number }) => width,
		aspectRatio: "1/1",
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	point: {
	    width: "4px",
		height: "4px",
		borderRadius: "50%",
		backgroundColor: "#F8F8F8",
	},
	innerCircle: {
		position: "absolute",
		width: 4,
		aspectRatio: "1/1",
		borderRadius: "50%",
		left: "50%",
		top: "50%",
		transform: "translate(-50%,-50%)",
		backgroundColor: "white",
	},
	prevSelectedMarker: {
		aspectRatio: "1/1",
		minWidth: 4,
		borderRadius: "50%",
		backgroundColor: "#495057",
		position: "absolute",
		bottom: "-50%",
		left: "50%",
		transform: "translatex(-50%)",
	},
}));
