import i18next from "i18next";
import { matchPath } from "react-router-dom";
import RouterConstants from "../../Constants/RouterConstants";
import client from "../../GraphQL/client";
import { GET_ALL_ORGANIZATIONS, getOrganization, getTailoredModels } from "../../GraphQL/queries";
import {
	allOrganizations,
	allOrganizations_allOrganizations,
	allOrganizationsVariables,
} from "../../GraphQL/types/allOrganizations";
import { ImageEditorData_vdrObject_vdrAwsFaceDetection_faces } from "../../GraphQL/types/imageEditorData";

import { Rect } from "../../types/graphql-global-types";
import { BriaWebAxios } from "../../sdk/briaAxios";
import AppContextInterface from "../../Context/AppContextInterface";
import { getTailoredModels_getTailoredModels } from "../../GraphQL/types/getTailoredModels";

export enum AnalysisStage {
	NONE,
	A,
	B,
	C,
}

export function getTitleForAnalysisStage(analysisStage: AnalysisStage): string {
	switch (analysisStage) {
		case AnalysisStage.NONE:
			return i18next.t("toolsAnalysisStageNone");
		case AnalysisStage.A:
			return i18next.t("toolsAnalysisStageA");
		case AnalysisStage.B:
			return i18next.t("toolsAnalysisStageB");
		case AnalysisStage.C:
			return i18next.t("toolsAnalysisStageC");
	}
}

export enum GenModels {
	bria_2_3_fast = "bria_2_3_fast",
	bria_2_2_hd = "bria_2_2_hd",
	bria_2_3 = "bria_2_3",
}

export enum IframeGenModelKeys {
	Bria2_3_fast = "bria2_3_fast",
	bria2_2_hd = "bria2_2_hd",
	bria2_3 = "bria2_3",
}

export const IframeModelType = {
	[IframeGenModelKeys.Bria2_3_fast]: GenModels.bria_2_3_fast,
	[IframeGenModelKeys.bria2_2_hd]: GenModels.bria_2_2_hd,
	[IframeGenModelKeys.bria2_3]: GenModels.bria_2_3,
};

export enum BriaObjectType {
	objects = "objects",
	car = "car",
	human = "human",
	horse = "horse",
	church = "church",
	background = "background",
	camera = "camera",
	brand = "brand",
	newBrand = "new_brand",
	nothuman = "not human",
	imageBackground = "image_background",
	graphics = "graphics",
	crop = "Crop",
	imageStyle = "image_style",
}

export enum EditorTabIframeMap {
	scene = "imageBackground",
	presenters = "human",
	size = "crop",
	objects = "objects",
	brand = "newBrand",
}

export enum ToolsViewerMode {
	both = "both",
	sliders = "sliders",
	thumbnails = "thumbnails",
}

export enum ToolsBackGroundMode {
	noBg = "noBg",
	withBg = "withBg",
}

export enum ToolsBackGroundBlurMode {
	noBlur = "noBlur",
	Blur = "Blur",
}

export interface SelectedPointInterface {
	id: string;
	index: number;
	objectType: string;
	rect: Rect;
	oracle?: any;
}

export const getFaceData = (face: ImageEditorData_vdrObject_vdrAwsFaceDetection_faces) => {
	let highAge = face.ageRange.high || Infinity;
	let lowAge = face.ageRange.low || 0;
	let age = (highAge + lowAge) / 2;
	let smile = face.faceAttributes.find((s: any) => s.name.toLowerCase() === "smile")?.value ?? false;
	let glasses = face.faceAttributes.find((a: any) => a.name.toLowerCase() === "eyeglasses")?.value ?? false;

	return {
		gender: face.gender.value,
		age,
		smile,
		glasses,
	};
};

export function titleCase(s: string) {
	return s
		.replace("_", " ")
		.replace("-", " ")
		.replace(/\w\S*/g, function (t) {
			return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
		});
}

export const getCurrentRoute = () => {
	const currentRoute = Object.entries(RouterConstants).find((route) =>
		matchPath(window.location.pathname, {
			path: route[1].path,
			exact: route[1].exact,
			strict: route[1].strict,
		})
	);
	return currentRoute?.[1];
};

export const isLightThemeRoute = () => {
	const currentRoute = getCurrentRoute();
	return currentRoute?.forceLightTheme ?? false;
};

export const isDisableLoaderRoute = () => {
	const currentRoute = getCurrentRoute();
	return currentRoute?.disableLoader ?? false;
};

export const getImagePixelsData = (image: HTMLImageElement, width: number, height: number) => {
	let imagePixelsData = undefined;
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext("2d");
	if (ctx) {
		ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
		imagePixelsData = ctx.getImageData(0, 0, width, height);
	}

	return { imagePixelsData, ctx };
};

export const getImageMeta = (url: string) =>
	new Promise<HTMLImageElement>((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous");
		image.src = url;
	});

export const fetchOrganizations = async (context: any) => {
	let allOrgs: allOrganizations_allOrganizations[] = [];
	if (context.allOrganizations && context.allOrganizations.length >= 1) {
		allOrgs = context.allOrganizations;
	} else {
		try {
			context.setIsOrganizationsLoading(true);
			const res = await client.query<allOrganizations, allOrganizationsVariables>({
				query: GET_ALL_ORGANIZATIONS,
			});
			allOrgs = res.data?.allOrganizations ?? [];
			context.setAllOrganizations(allOrgs);
		} catch (error) {
			console.log(error);
		} finally {
			context.setIsOrganizationsLoading(false);
		}
	}
	return allOrgs;
};

export const hexToRgb = (hex: string) => {
	let c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
	}
	throw new Error("Bad Hex");
};

export const getOrganizationGalleryId = async (context: AppContextInterface, orgId: string | undefined | null) => {
	let galleryId;
	if (orgId && orgId?.length > 0) {
		const foundOrg = context.allOrganizations.find((org) => org.uid === orgId);
		if (foundOrg) {
			galleryId = foundOrg.galleryId;
		} else if (Object.keys(context.organizationGalleryMap).includes(orgId)) {
			galleryId = context.organizationGalleryMap[orgId];
		} else {
			const orgRes = await getOrganization(orgId, window.iframeId ?? "");
			galleryId = orgRes?.galleryId;
			context.setOrganizationGalleryMap({
				...context.organizationGalleryMap,
				[orgId]: galleryId,
			});
		}
	}
	return galleryId;
};

// WAI-2145
export const uploadImageViaApi = async (file: File | string, orgId?: string, uploadToUserAssets?: boolean) => {
	const formData = new FormData();
	formData.append(typeof file === "string" ? "image_url" : "file", file);
	formData.append("org_id", orgId ?? "");
	formData.append("add_to_user_assets", `${uploadToUserAssets ?? "false"}`);

	const resp = await (
		await BriaWebAxios()
	).post(`/upload_image_via_api/`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

	return resp;
};

export const scrollToPositionAndWaitUntilDone = async (container: any, position: number) => {
	position = Math.round(position);

	if (container.scrollTop === position) {
		return;
	}

	let resolveFn: any;
	let scrollListener: any;
	let timeoutId: any;

	const promise = new Promise((resolve) => {
		resolveFn = resolve;
	});

	const finished = () => {
		container.removeEventListener("scroll", scrollListener);
		resolveFn();
	};

	scrollListener = () => {
		clearTimeout(timeoutId);

		// scroll is finished when either the position has been reached, or 100ms have elapsed since the last scroll event
		if (container.scrollTop === position) {
			finished();
		} else {
			timeoutId = setTimeout(finished, 50);
		}
	};

	container.addEventListener("scroll", scrollListener);

	container.scrollTo({
		top: position,
		behavior: "smooth",
	});

	return promise;
};

export const getFirstActiveApiToken = (org: any) => {
	const activeApiKeys =
		org.apiKeys?.filter(
			(key: any) => key.status === "active" && key.keyType !== "internal" && key.keyType !== "iframe"
		) ?? [];
	return activeApiKeys.length > 0 ? activeApiKeys[0].apiToken : "";
};

export const croppingAreaHasTransparency = (croppedArea: any, draftCroppedAreaPixels: any, naturalSize: any) => {
	const ratio = Math.max(
		croppedArea.width / draftCroppedAreaPixels.width,
		croppedArea.height / draftCroppedAreaPixels.height
	);
	const scaledLocationX = Math.round(draftCroppedAreaPixels.x * ratio);
	const scaledLocationY = Math.round(draftCroppedAreaPixels.y * ratio);
	const scaledImageWidth = Math.round(naturalSize.width * ratio);
	const scaledImageHeight = Math.round(naturalSize.height * ratio);
	return (
		scaledLocationX < 0 ||
		scaledLocationY < 0 ||
		Math.abs(scaledImageWidth - scaledLocationX) < croppedArea.width ||
		Math.abs(scaledImageHeight - scaledLocationY) < croppedArea.height
	);
};

export const checkLink = async (url: string) => {
	try {
		const res = await fetch(url);
		return res.ok;
	} catch (e) {
		return false;
	}
};

export const fetchTailoredModels = async (context: any) => {
	let models: getTailoredModels_getTailoredModels[] = [];
	if (context.tailoredModels) {
		models = context.tailoredModels;
	} else {
		try {
			models = (await getTailoredModels(context.selectedOrganization?.uid ?? "")) ?? [];
			context.setTailoredModels(models);
		} catch (error) {
			console.log(error);
		}
	}
	return models;
};
