import React, { useEffect, useRef, useState } from "react";
import {
	FormControl,
	MenuItem,
	Select,
	Checkbox,
	Typography,
	makeStyles,
	Divider,
	Box,
	Theme,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CircularLoadingTooltip from "../../UI/LoadingTooltip";
import { useTranslation } from "react-i18next";

const CustomMultiSelect = ({
	selectedValues,
	handleChange,
	groups,
	paddingClass,
	selectStyle,
	placeholder,
	selectedPlaceHolder,
	hasError,
	loading = false,
	selectedValueName,
}: any) => {
	const { i18n, t } = useTranslation();
	const classes = useStyles();
	const selectRef = useRef();
	const [menuWidth, setMenuWidth] = useState(null);
	const updateMenuWidth = () => {
		const selectWidth = selectRef?.current?.offsetWidth;
		setMenuWidth(selectWidth);
	};
	const sortOptionsAlphabetically = (options: any) => {
		return options.sort((a: any, b: any) => a.label.localeCompare(b.label));
	};
	useEffect(() => {
		updateMenuWidth();
	});
	useEffect(() => {
		updateMenuWidth();
		window.addEventListener("resize", updateMenuWidth);
		return () => {
			window.removeEventListener("resize", updateMenuWidth);
		};
	}, []);
	return (
		<FormControl fullWidth variant="outlined" className={classes.formControl}>
			<Select
				ref={selectRef}
				renderValue={() => {
					if (selectedValues.length === 0) {
						return <Typography className={classes.placeHolder}>{placeholder} </Typography>;
					} else if (selectedValues.length === 1) {
						return <Typography className={classes.placeHolder}>{selectedValueName}</Typography>;
					} else {
						return (
							<Typography className={classes.placeHolder}>
								{String(selectedValues.length) + " " + selectedPlaceHolder}
							</Typography>
						);
					}
				}}
				multiple
				value={selectedValues}
				onChange={handleChange}
				displayEmpty
				classes={{ root: paddingClass, select: `${selectStyle} ${hasError ? classes.errorBorder : ""}` }}
				IconComponent={(props) =>
					loading ? (
						<Box className={classes.loader}>
							<CircularLoadingTooltip key="" tab="" />
						</Box>
					) : (
						<KeyboardArrowDownIcon {...props} style={{ color: "#1A0638" }} />
					)
				}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
					PaperProps: {
						style: {
							backgroundColor: "white",
							minWidth: menuWidth || "auto",
							maxHeight: "306px",
							overflowY: "auto",
						},
					},
					classes: { paper: selectStyle },
					MenuListProps: {
						style: {
							maxHeight: "inherit",
						},
					},
				}}
			>
				{groups?.map((group: any, groupIndex: any) => [
					<MenuItem
						className={`${classes.disabledOptions} ${classes.hoverEffect} ${
							selectedValues.includes(group.name) ? classes.selectedItem : ""
						}`}
						key={`group_${groupIndex}`}
						value={t(group.name)}
						disabled
					>
						{t(group.name)}
					</MenuItem>,
					...sortOptionsAlphabetically(group.options).map((option: any, optionIndex: any) => (
						<MenuItem
							key={option.id}
							value={option.id}
							className={`${classes.hoverEffect} ${
								selectedValues.includes(option.id) ? classes.selectedItem : ""
							}`}
						>
							<Checkbox
								classes={{
									root: classes.root,
									checked: classes.checked,
								}}
								checked={selectedValues.includes(option.id)}
							/>
							<Typography className={classes.optionsLable}>{option.label} </Typography>
						</MenuItem>
					)),
					groupIndex === 0 && groups.length > 1 && (
						<Divider key={`divider_${groupIndex}`} className={classes.divider} />
					),
				])}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	root: {
		color: theme.palette.primary.dark,
		paddingLeft: "0px",
		paddingRight: "8px",
		"&$checked": {
			color: theme.palette.primary.dark,
		},
	},
	checked: {}, // Necessary to override default styles
	formControl: {
		paddingTop: "12px",
		paddingBottom: "20px",
	},
	placeHolder: {
		color: "#5B5B5B",
		fontSize: "14px",
	},
	optionsLable: {
		fontWeight: 500,
		fontSize: "14px",
		color: "#5B5B5B",
		lineHeight: "16px",
	},
	divider: {
		backgroundColor: "#E7E7E7",
		marginTop: "6px",
	},
	disabledOptions: {
		color: " rgba(91, 91, 91, 0.50)",
		paddingTop: "12px",
		paddingBottom: "4px",
		fontSize: "10px",
		fontWeight: 700,
		opacity: "1 !important",
		lineHeight: "normal",
	},
	errorBorder: {
		border: "1px solid red",
		borderRadius: "10px !important",
	},

	hoverEffect: {
		"&:hover, &:active ": {
			backgroundColor: "#F5F5F5 !important",
		},
	},
	selectedItem: {
		backgroundColor: "white",
		"&:hover": {
			backgroundColor: "white",
		},
		"&.Mui-selected": {
			backgroundColor: "white",
		},
		"&.Mui-selected:hover": {
			backgroundColor: "white",
		},
	},
	loader: {
		position: "relative",
		right: "15px",
	},
}));

export default CustomMultiSelect;
