import Box, { BoxProps } from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

interface IProps extends BoxProps {
	objectId: string;
	objectOrder: number;
	objectOnHover: string;
	active: boolean;
	disableTooltip?: boolean;
}

interface StyleProps {
	active: boolean;
	objectOnListHover: boolean;
	Object_Zindex: number;
}

function ObjectRect({ objectId, objectOrder, objectOnHover, active, ...props }: IProps) {
	const classes = useStyles({
		active: active,
		objectOnListHover: objectId === objectOnHover,
		Object_Zindex: objectOrder + 1,
	});

	return (
		<Box className={classes.root}>
			<Box className={clsx(classes.container, "hover-container")} position="absolute" {...props}></Box>
		</Box>
	);
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	container: {
		borderRadius: theme.shape.borderRadius,
		mixBlendMode: "normal",
		cursor: "inherit",
		border: (props) => (props.objectOnListHover ? "1px dashed #FFFFFF" : "none"),
		zIndex: (props) => props.Object_Zindex,
		boxShadow: (props) => (props.objectOnListHover ? "0 0 0 9999em rgb(0 0 0 / 50%)" : "none"),
	},
}));
export default ObjectRect;
