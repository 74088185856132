import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Context from "../../Context/Context";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";

interface IProps {
	onButtonClick: () => void;
}

const AfterRegistrationContent: React.FC<IProps> = ({
	onButtonClick,
}: IProps) => {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();

	return (
		<Box>
			<Box className={classes.imgContainer}>
				<img src="https://labs-assets.bria.ai/welcomeImage.jpg" />
			</Box>
			<Box className={classes.contentContainer}>
				<Typography className={clsx(classes.title, classes.bold)}>
					Welcome to Bria
				</Typography>
				<Typography className={classes.description}>
					Your free trial subscription has now been activated, with
					full access to our cutting-edge AI-powered visual creation
					tool..
				</Typography>
				<Box className={classes.buttonContainer}>
					<BriaButton
						buttonType={BUTTONTYPES.PRIMARY}
						style={{ width: 150 }}
						onClick={onButtonClick}
					>
						Got It
					</BriaButton>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	imgContainer: {
		marginTop: 50,
	},
	contentContainer: {
		padding: "30px 30px",
	},
	bold: {
		fontWeight: "bold",
	},
	title: {
		fontSize: "1.6rem",
		color: "rgba(73, 80, 87, 1)",
		marginBottom: 24,
	},
	description: {
		fontSize: "0.9rem",
		color: "rgba(73, 80, 87, 1)",
	},
	buttonContainer: {
		marginTop: 60,
		textAlign: "right",
	},
}));

export default AfterRegistrationContent;
