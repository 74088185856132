import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function LampIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fillOpacity={0}
			fill="none"
			{...props}
		>
			<path
				d="M1 10H2M10 1V2M18 10H19M3.6 3.6L4.3 4.3M16.4 3.6L15.7 4.3M7.69995 15H12.3M7 14C6.16047 13.3704 5.54033 12.4925 5.22743 11.4908C4.91453 10.4892 4.92473 9.41442 5.25658 8.41886C5.58844 7.4233 6.22512 6.55739 7.07645 5.94379C7.92778 5.33019 8.95059 5 10 5C11.0494 5 12.0722 5.33019 12.9236 5.94379C13.7749 6.55739 14.4116 7.4233 14.7434 8.41886C15.0753 9.41442 15.0855 10.4892 14.7726 11.4908C14.4597 12.4925 13.8395 13.3704 13 14C12.6096 14.3865 12.3156 14.8594 12.1419 15.3806C11.9681 15.9018 11.9195 16.4566 12 17C12 17.5304 11.7893 18.0391 11.4142 18.4142C11.0391 18.7893 10.5304 19 10 19C9.46957 19 8.96086 18.7893 8.58579 18.4142C8.21071 18.0391 8 17.5304 8 17C8.08046 16.4566 8.03185 15.9018 7.85813 15.3806C7.6844 14.8594 7.39043 14.3865 7 14Z"
				stroke={props.color ? props.color : "#008AD2"}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</SvgIcon>
	);
}

export default LampIcon;
