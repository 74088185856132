export enum SubscriptionCreditsTypes {
	PEOPLE_CUSTOMIZATION = "people_customizations_credits",
	VIDEO = "videos_credits",
	UPLOAD = "upload",
	DOWNLOAD = "download_credits",
	INCREASE_RESOLUTION = "increase_resolution",
	REMOVE_BG = "remove_bg",
	CREATE_BRAND_STYLE = "brands",
	MY_ASSETS = "my_assets",
	DOWNLOAD_PSD = "download_psd_credits",
}

export enum SubscriptionTypes {
	free = "free",
	basic = "basic",
	pro = "pro",
	ultimate = "ultimate",
	none = "none",
}

export enum ApiSubscriptionTypes {
	starter = "starter",
	basic = "basic",
	pro = "pro",
	ultimate = "ultimate",
	none = "none",
}

export enum SubscriptionPeriods {
	monthly = "monthly",
	yearly = "yearly",
	default = "",
}

export enum ApiSubscriptionStatuses {
	active = "active",
	not_active = "not_active",
}

export const SUBSCRIPTION_TYPES_ORDER = [
	SubscriptionTypes.free,
	SubscriptionTypes.pro,
	SubscriptionTypes.ultimate,
	SubscriptionTypes.none,
];

export const API_SUBSCRIPTION_TYPES_ORDER = [
	ApiSubscriptionTypes.starter,
	ApiSubscriptionTypes.basic,
	ApiSubscriptionTypes.pro,
	ApiSubscriptionTypes.ultimate,
	ApiSubscriptionTypes.none,
];
