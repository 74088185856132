import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BriaLogo from "../assets/icons/BriaLogo";
import BriaButton, { BUTTONTYPES } from "../Components/UI/BriaButton";
import Footer from "../Components/UI/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import answerClosedImg from "../assets/landing/ACComponent.png";
import answerOpenedImg from "../assets/landing/AOComponent.png";
import questionImg from "../assets/landing/QComponent.png";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";
import { useEffect, useState } from "react";
import InView from "react-intersection-observer";
import ContentWrapper from "../Components/Common/ContentWrapper";
import Flex from "../Components/Common/Wrappers/Flex";
import BriaWhiteIcon from "../assets/icons/BriaWhiteIcon";
import parse from "html-react-parser";
import LinksConst from "../Constants/LinksConst";

function LandingPage2(props: {
	constants: {
		firstFoldVideoUrl: string;
		firstFoldVideoPosterUrl: string;
		firstFoldHeader: string;
		firstFoldSubHeader: string;
	};
}) {
	const classes = useStyles();
	const isMobile = window.innerWidth <= 768;
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000,
	};

	const [syncedVideos, setSyncedVideos] = useState<
		{ src: string; alt: string; loaded: boolean }[]
	>([
		{
			src: "https://labs-assets.bria.ai/LP2_animations.m4v",
			alt: "A woman with brown hair becoming a woman with blonde hair in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/search_2.m4v",
			alt: "Help your users always find the image they need - first time, every time - thanks to Bria’s label-free, contextual Smart Search.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/People.mp4",
			alt: "A photo of two men whose age and facial hair changes in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/expretions.mp4",
			alt: "A woman displaying a variety of different facial expressions in one click with Bria's creative platform",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/Age.mp4",
			alt: "An elderly man becoming a young man in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/Logo.mp4",
			alt: "A logo inserted onto a wall of a building in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/Mood.mp4",
			alt: "An image changing its lighting and contrast to fit a brand's guidelines in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/LP_animations_motion.m4v",
			alt: "A still image of a boat on a lake at sunset becoming a video with moving clouds and rippling water in one click with Bria's creative platform.",
			loaded: false,
		},
		{
			src: "https://labs-assets.bria.ai/Remove_object.mp4",
			alt: "An image of three hot air ballons where one ins removed in one click with Bria's creative platform.",
			loaded: false,
		},
	]);
	const [videosInView, setVideosInView] = useState<boolean>(false);

	function openSignUpPage() {
		Analytics.logEvent(ANALYTICS_EVENTS.LANDING_PAGE_CTA_BUTTON_CLICK);
		// window.open(
		// 	"https://hubspot-cta-redirect-eu1-prod.s3.amazonaws.com/cta/redirect/25399012/edfc8056-2c80-4905-8acd-c3c7ca66c5a3",
		// 	"_blank"
		// );
		window.open("https://pages.bria.ai/book-bria-demo", "_blank");
	}

	function toggleAnswer(e: any, answerId: string) {
		const thisAnswer = document.querySelector<HTMLElement>(`#${answerId}`);
		if (e.target.getAttribute("src") === answerClosedImg) {
			e.target.setAttribute("src", answerOpenedImg);
			if (thisAnswer) thisAnswer.style.display = "block";
		} else {
			e.target.setAttribute("src", answerClosedImg);
			if (thisAnswer) thisAnswer.style.display = "none";
		}
	}

	function getGifs() {
		return (
			<>
				<Box className={"imgBlock"}>
					{getVideoElement(0)}
					<Typography className={"imgDescription"}>
						Supercharge your imagination by generating inspirational
						creative visuals.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(1)}
					<Typography className={"imgDescription"}>
						Find the right asset every time with contextual,
						label-free image search.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(2)}
					<Typography className={"imgDescription"}>
						Refresh old assets by recasting the presenters for a new
						look.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(3)}
					<Typography className={"imgDescription"}>
						Create new emotions from old images by changing facial
						expressions.
					</Typography>
				</Box>
			</>
		);
	}
	function getProGifs() {
		return (
			<>
				<Box className={"imgBlock"}>
					{getVideoElement(4)}
					<Typography className={"imgDescription"}>
						Personalize and A/B test images for different target
						audiences.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(5)}
					<Typography className={"imgDescription"}>
						Embed a logo seamlessly in an image for instant
						branding.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(6)}
					<Typography className={"imgDescription"}>
						Apply brand book guidelines to any visuals in moments.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(7)}
					<Typography className={"imgDescription"}>
						Add attention-grabbing 3D camera motion to any still
						photograph.
					</Typography>
				</Box>
				<Box className={"imgBlock"}>
					{getVideoElement(8)}
					<Typography className={"imgDescription"}>
						Add and remove objects to focus your message.
					</Typography>
				</Box>
			</>
		);
	}

	useEffect(() => {
		if (!isMobile) {
			let allVideosLoaded = true;
			for (let syncedVideo of syncedVideos) {
				if (syncedVideo.loaded === false) {
					allVideosLoaded = false;
					break;
				}
			}
			if (videosInView && allVideosLoaded) {
				const syncedVideosElements: any =
					document.getElementsByClassName("synced-video");
				for (const videoElement of syncedVideosElements) {
					videoElement.play();
				}
			}
		}
	}, [videosInView, syncedVideos]);

	// useEffect(() => {
	// 	const script = document.createElement("script");
	// 	script.src = "https://js-eu1.hscta.net/cta/current.js";
	// 	document.body.appendChild(script);

	// 	script.addEventListener("load", () => {
	// 		if ((window as any).hbspt) {
	// 			(window as any).hbspt.cta.load(
	// 				"25399012",
	// 				"edfc8056-2c80-4905-8acd-c3c7ca66c5a3",
	// 				{ useNewLoader: "true", region: "eu1" }
	// 			);
	// 		}
	// 	});
	// }, []);

	function getVideoElement(index: number) {
		return (
			<Box className={classes.videoElementContainer}>
				<video
					className="synced-video"
					width="100%"
					autoPlay={isMobile}
					loop={true}
					muted={true}
					preload={"auto"}
					playsInline={true}
					onLoadedData={() => {
						syncedVideos[index].loaded = true;
						setSyncedVideos([...syncedVideos]);
					}}
				>
					<source
						src={syncedVideos[index].src}
						type="video/mp4"
					></source>
					<img src="" alt={syncedVideos[index].alt}></img>
					Your browser does not support the video tag.
				</video>
				<BriaWhiteIcon className={classes.briaIcon}></BriaWhiteIcon>
				{/* <img
					src="https://labs-assets.bria.ai/bria_icon_wh.png"
					alt="Bria icon"
				></img> */}
			</Box>
		);
	}

	return (
		<Box className={classes.rootContainer}>
			{!isMobile && (
				<Box
					className={classes.betaSignUpsticky}
					onClick={openSignUpPage}
				>
					<Typography>Book a Demo</Typography>
				</Box>
			)}
			<Box
				className={`${classes.firstContentBlock} ${classes.contentBlock} ${classes.columnFlex}`}
			>
				<ContentWrapper style={{ flex: 1, flexWrap: "wrap" }}>
					<Box className={classes.logoContainer}>
						<BriaLogo className={classes.logo} />
					</Box>
					<Box
						className={`${classes.fullHeightContentBlock} ${classes.firstFullHeightContentBlock}`}
					>
						<Box className={classes.firstContentTitleContainer}>
							<Typography
								className={`${classes.title} ${classes.firstContentTitle}`}
							>
								{parse(props.constants.firstFoldHeader)}
							</Typography>
							<Typography className={classes.description}>
								{parse(props.constants.firstFoldSubHeader)}
							</Typography>
							{!isMobile && (
								<>
									<BriaButton
										buttonType={BUTTONTYPES.PRIMARY}
										className={classes.button}
										onClick={openSignUpPage}
									>
										Book a Demo
									</BriaButton>
									<Box>
										<img
											className={classes.scrollImg}
											src="https://labs-assets.bria.ai/scroll.gif"
										></img>
									</Box>
								</>
							)}
						</Box>
						<Box>
							<video
								className={classes.yellowImg}
								autoPlay={true}
								loop={true}
								preload={"none"}
								muted={true}
								playsInline={true}
								controls={true}
								poster={props.constants.firstFoldVideoPosterUrl}
							>
								<source
									src={props.constants.firstFoldVideoUrl}
									type="video/mp4"
								></source>
								<img
									src={props.constants.firstFoldVideoUrl}
									alt="A video showing what you can do with Bria - remove and add objects, add weather and video effects, customize people’s appearance and expressions, add your branding."
								></img>
								Your browser does not support the video tag.
							</video>
							<Box className={classes.shadow}></Box>
						</Box>
						{isMobile && (
							<Flex>
								<BriaButton
									buttonType={BUTTONTYPES.PRIMARY}
									className={classes.button}
									onClick={openSignUpPage}
								>
									Book a Demo
								</BriaButton>
							</Flex>
						)}
					</Box>
				</ContentWrapper>
			</Box>
			<Box className={`${classes.contentBlock} ${classes.columnFlex}`}>
				<ContentWrapper classes={classes.paddingHorizantal}>
					<Box
						className={`${classes.secondBlockTitle} ${classes.blockTitle}`}
					>
						<Typography>
							Give your users scalable creative superpowers
						</Typography>
					</Box>
					<InView
						as="div"
						onChange={setVideosInView}
						className={classes.secondBlockContent}
					>
						{isMobile ? (
							<>
								<Box className={classes.secondBlockContent}>
									<Slider {...sliderSettings}>
										{getGifs().props.children}
									</Slider>
								</Box>
								<Box className={classes.secondBlockContent}>
									<Slider {...sliderSettings}>
										{getProGifs().props.children}
									</Slider>
								</Box>
							</>
						) : (
							<>
								{getGifs().props.children}
								{getProGifs().props.children}
							</>
						)}
					</InView>
					{!isMobile && (
						<Flex>
							<Box className={classes.secondBlockButtonContainer}>
								<BriaButton
									className={classes.button}
									buttonType={BUTTONTYPES.PRIMARY}
									onClick={openSignUpPage}
								>
									Book a Demo
								</BriaButton>
							</Box>
						</Flex>
					)}
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.fullHeightContentBlock} ${classes.contentBlock} ${classes.columnFlex}`}
			>
				<ContentWrapper>
					<Box
						className={`${classes.thirdBlockTitle} ${classes.blockTitle}`}
					>
						<Flex justifyContent="center">
							<Typography>
								Your users will become creators
							</Typography>
						</Flex>
					</Box>
					<Box className={classes.thirdBlockContent}>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3060.png"
									alt="An icon representing a video conversatoin"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Predictable
								</Typography>
								Have confidence that results will match
								requests.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3051.png"
									alt="An icon representing a jigsaw puzzle"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Controllable
								</Typography>
								Make precise changes to images with full control
								over the results.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3049.png"
									alt="An icon representing images"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Scalable
								</Typography>
								Apply changes across infinite numbers of visuals
								with an API call.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3058.png"
									alt="An icon representing documents and images"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									High quality
								</Typography>
								Create photo-realistic, high definition images
								and video.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/Group_3098.png"
									alt="An icon representing responsibility"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Responsible
								</Typography>
								Use technology with Responsible AI principles
								built in from day one.
							</Typography>
						</Box>
					</Box>
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.fullHeightContentBlock} ${classes.contentBlock} ${classes.fourthBlockContent}`}
			>
				<ContentWrapper>
					<Flex passedClasses={[classes.pricesAreLimitedSection]}>
						<Box
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
							<Typography className={classes.title}>
								Integrate Bria’s<br></br>creativity API<br></br>
								today
							</Typography>
							<Box className={classes.fourthBlockButtonContainer}>
								<BriaButton
									className={classes.button}
									buttonType={BUTTONTYPES.PRIMARY}
									onClick={openSignUpPage}
								>
									Book a Demo
								</BriaButton>
							</Box>
						</Box>
						<Box>
							<img
								src="https://labs-assets.bria.ai/mac2.png"
								alt="The Bria platform being used on a desktop computer"
							/>
						</Box>
					</Flex>
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.contentBlock} ${classes.columnFlex} ${classes.fifthContentBlock}`}
			>
				<ContentWrapper>
					<Box
						className={`${classes.fifthBlockTitle} ${classes.blockTitle}`}
					>
						<Typography>
							Got questions? We’ve got answers.
						</Typography>
					</Box>
					<Box className={classes.fifthBlockContent}>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group51.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What kind of platform can benefit from Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_1");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_1"
									className={classes.answerText}
								>
									You can integrate Bria’s creativity API into
									any platform. It’s already popular with
									stock photography sites, digital asset
									management systems (DAMs), creative
									platforms, eCommerce sites, creative
									agencies and performance marketing
									companies. You can read the API
									documentation{" "}
									<a
										href={`${LinksConst.BRIA_WEBSITE}bria-api/`}
									>
										here
									</a>
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can creative platforms use Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_2");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_2"
									className={classes.answerText}
								>
									Graphic design platforms, website builders
									and video creation platforms can integrate
									Bria’s API to help their users be even more
									creative. They can redirect scenes by
									changing backgrounds and removing objects,
									recast presenters (or create new, unique
									presenters from scratch), apply brandbook
									guidelines and turn any still image into an
									engaging video.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can eCommerce platforms use Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_3");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_3"
									className={classes.answerText}
								>
									eCommerce platforms can harness the power of
									Bria to create and modify visuals at scale.
									Make products stand out by turning still
									product photos into engaging videos,
									redirect scenes by changing background and
									removing objects, recast models (or create
									new, unique models from scratch) and apply
									brandbook guidelines.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group51.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can creative agencies use Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_4");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_4"
									className={classes.answerText}
								>
									Creative agencies can use Bria to
									supercharge their creativity. By generating
									creative visuals forms scratch you can
									explore creative directions and get
									inspiration. Refresh existing assets by
									changing backgrounds, removing objects and
									recasting new models. Turn photos into
									engaging videos and apply brandbook
									guidelines across images. The best thing?
									You can do this all at scale - freeing up
									creative capacity to take on more clients.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can performance marketing companies use
									Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_5");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_5"
									className={classes.answerText}
								>
									Bria lets you take an image and create
									unlimited variations at scale for A/B
									testing. Recast an actor to fit your target
									audience and then run tests to optimize your
									ads for maximum conversion. Apply brandbook
									guidelines across multiple images and
									transform still photographs into dynamic
									videos to drive engagement.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group54.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can digital asset management systems
									(DAMs) use Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_6");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_6"
									className={classes.answerText}
								>
									DAMs can give their users creativity at
									scale by integrating Bria’s API. They’ll be
									able to apply brandbook guidelines across
									all their assets; recast models to create
									campaigns that are targeted to different
									audiences and redirect scenes to give images
									a new look and feel by changing backgrounds
									or removing objects. Adding Bria’s Smart
									Search will mean that users can always find
									exactly the asset they’re looking for, no
									matter the size of their library, with no
									need for manual tagging.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group55.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can stock photography platforms use Bria
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_7");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_7"
									className={classes.answerText}
								>
									Stock photography sites can give their users
									exactly the image they need - first time,
									every time - by integrating Bria’s Smart
									Search. The search has a deep understand of
									images with no need for tagging and can
									return accurate results for complex
									concepts. Your users can take your visuals
									and redirect them by removing backgrounds or
									objects, or recasting models, so they
									exactly meet their needs. And they can turn
									still photos into engaging videos.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What kind of people use Bria’s capabilities?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_8");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_8"
									className={classes.answerText}
								>
									Bria gives anyone who needs images or video
									the power to create visuals that meet their
									needs. Many users marketing managers, social
									media managers, content creators and
									performance marketers who are looking to
									customize or localize visuals at scale.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can I edit images with Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_9");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_9"
									className={classes.answerText}
								>
									Bria offers you a range of options to
									customize images to meet your needs. You can
									recast models and change their facial
									expressions to resonate with your target
									audience. Add and remove objects to make
									sure that the important elements of your
									visual are the focus. And you can turn still
									images into video - animating people and
									scenery or adding camera movement effects to
									grab users’ attention.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group55.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can I create videos with Bria?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_10");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_10"
									className={classes.answerText}
								>
									Bria gives you the power to transform any
									still image into an attention-grabbing
									video. Take people on a journey into your
									image with a range of 3D camera motions. And
									bring people to life by animating their
									faces with a variety of different movements.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group51.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>What is generative AI?</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_11");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_11"
									className={classes.answerText}
								>
									Generative AI is the next generation of
									artificial intelligence. Until recently, AI
									could only be trained to recognize
									patterns/objects/faces and then take actions
									based on that understanding.
									<br></br>
									Bria’s generative AI takes that
									understanding a step further. First, the AI
									learns how to recognize objects, faces and
									attributes (such as facial expressions) and
									then is trained to create these objects
									entirely from scratch in the form of a
									visual.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What quality are the images?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_12");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_12"
									className={classes.answerText}
								>
									Bria allows you to create images and video
									in high definition.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How do I try Bria out for myself?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_13");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_13"
									className={classes.answerText}
								>
									Just{" "}
									<a onClick={openSignUpPage} href="#">
										sign in to the Bria beta
									</a>{" "}
									to start creating your own images and video
									for free. To see pro features and to discuss
									integrating Bria’s capabilities into your
									platform via API,{" "}
									<a onClick={openSignUpPage} href="#">
										schedule a demo
									</a>
									.
								</span>
							</Typography>
						</Box>
					</Box>
					<Box className={classes.fifthBlockButtonContainer}>
						<Flex justifyContent="center">
							<BriaButton
								className={classes.button}
								buttonType={BUTTONTYPES.PRIMARY}
								onClick={openSignUpPage}
							>
								Book a Demo
							</BriaButton>
						</Flex>
					</Box>
				</ContentWrapper>
			</Box>
			<Box className={classes.footerContainer}>
				<Footer />
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		height: "100%",
	},
	paddingHorizantal: {},
	contentBlock: {
		display: "flex",
		alignItems: "center",
		margin: "0 auto",
		backgroundColor: "white",
		[theme.breakpoints.down(768)]: {
			paddingLeft: 24,
			paddingRight: 24,
		},
		[theme.breakpoints.between(768, 1920)]: {
			// paddingLeft: 130,
			// paddingRight: 130,
		},
	},
	columnFlex: {
		flexDirection: "column",
	},
	firstContentBlock: {
		backgroundImage: "linear-gradient(#EEEEEE, #FFFFFF)",
		height: 1080,
		maxHeight: "100%",
		display: "flex",
		[theme.breakpoints.down(768)]: {
			height: "auto",
			paddingBottom: 139,
			maxHeight: "none",
		},
	},
	pricesAreLimitedSection: {
		justifyContent: "center",
		flexWrap: "wrap",
		gap: theme.spacing(15),
		[theme.breakpoints.down(1140)]: {
			justifyContent: "center",
			gap: theme.spacing(2),
		},
	},
	firstFullHeightContentBlock: {
		[theme.breakpoints.down(768)]: {
			flexWrap: "wrap",
			paddingTop: 57,
		},
	},
	firstContentTitleContainer: {
		[theme.breakpoints.down(768)]: {
			paddingBottom: 85,
		},
	},
	firstContentTitle: {
		[theme.breakpoints.between(768, 1920)]: {
			minWidth: 376,
		},
	},
	fullHeightContentBlock: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		justifyContent: "center",
		gap: "4rem",
		[theme.breakpoints.down(1140)]: {
			gap: "0rem",
		},
		[theme.breakpoints.down(768)]: {
			gap: "2rem",
		},
	},
	logoContainer: {
		width: "100%",
	},
	footerContainer: {
		height: 372,
		"& > .MuiBox-root > .MuiGrid-root": {
			paddingTop: 83,
		},
	},
	logo: {
		height: "100%",
		width: 132,
		marginTop: 17,
		[theme.breakpoints.down(768)]: {
			width: 68,
		},
	},
	title: {
		color: "#5300C9",
		lineHeight: 1.2,
		fontSize: "2.9rem",
		[theme.breakpoints.down(768)]: {
			fontSize: "2rem",
		},
	},
	blockTitle: {
		"& p": {
			fontSize: "2.2rem",
			[theme.breakpoints.down(768)]: {
				textAlign: "left",
				fontSize: "2rem",
			},
		},
	},
	secondBlockTitle: {
		width: "100%",
		color: "#505050",
		textAlign: "center",
		[theme.breakpoints.down(768)]: {
			marginTop: 0,
		},
	},
	secondBlockContent: {
		display: "flex",
		marginTop: 100,
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "center",
		columnGap: "3vw",
		rowGap: 103,
		[theme.breakpoints.down(768)]: {
			marginTop: 58,
			rowGap: 58,
			display: "block",
			textAlign: "center",
		},
		"& .imgBlock": {
			flex: "1 1 29%",
			"& img": {
				width: "100%",
			},
		},
		"& .imgDescription": {
			marginTop: 22,
			color: "#505050",
			[theme.breakpoints.down(768)]: {
				textAlign: "center",
			},
			fontSize: "1.4rem",
			"& .pro": {
				color: "#D80167",
				fontWeight: "bold",
				display: "inline-block",
				fontSize: "1.4rem",
				"&:before": {
					content: '""',
					width: 20,
					height: 20,
					marginRight: 10,
					display: "inline-block",
					background: `url("https://labs-assets.bria.ai/pro_star.png") no-repeat`,
				},
			},
		},
		"& .slick-dots li": {
			margin: "0",
		},
		"& .slick-dots li button": {
			padding: 3,
		},
		"& .slick-dots li.slick-active button:before": {
			color: "#D80067",
			content: '"•"',
			fontSize: 10,
			top: 2,
		},
		"& .slick-dots li button:before": {
			fontSize: 30,
			content: '"◦"',
		},
	},
	secondBlockButtonContainer: {
		marginTop: 112,
		marginBottom: 142,
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.between(768, 1920)]: {
			marginBottom: 70,
		},
	},
	thirdBlockTitle: {
		marginTop: 100,
		color: "#505050",
		[theme.breakpoints.down(768)]: {
			marginTop: 84,
		},
	},
	thirdBlockContent: {
		display: "flex",
		paddingTop: 125,
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "center",
		columnGap: 85,
		rowGap: 99,
		[theme.breakpoints.down(768)]: {
			paddingTop: 86,
			rowGap: 37,
		},
		[theme.breakpoints.between(768, 1920)]: {
			columnGap: 25,
			rowGap: 60,
			paddingTop: 70,
		},
		"& .imgBlock": {
			"& .imgBlockContainer": {
				minHeight: 106,
			},
			textAlign: "center",
			flex: "0 1 29%",
			"& img": {
				maxHeight: 106,
			},
			[theme.breakpoints.down(768)]: {
				textAlign: "left",
				display: "flex",
				flex: "0 1 100%",
				columnGap: 20,
				"& img": {
					width: 53.5,
					height: 44.5,
				},
			},
		},
		"& .imgDescription": {
			"& .imgTitle": {
				fontSize: "1.4rem",
				fontWeight: "bold",
			},
			fontSize: "1.4rem",
			marginTop: 42,
			color: "#505050",
			[theme.breakpoints.down(768)]: {
				marginTop: 0,
			},
		},
	},
	fourthBlockContent: {
		paddingTop: 260,
		paddingBottom: 175,
		justifyContent: "center",
		"& img": {
			marginRight: "-18vw",
			width: "55vw",
			maxWidth: "1000px",
			[theme.breakpoints.down(1140)]: {
				width: "100%",
				marginRight: 0,
				marginLeft: "13vw",
			},
		},
		[theme.breakpoints.down(768)]: {
			paddingTop: 103.5,
			flexWrap: "wrap",
			paddingBottom: 137,
			"& p": {
				textAlign: "center",
			},
			"& img": {
				width: "100%",
			},
		},
		[theme.breakpoints.between(768, 1920)]: {
			paddingTop: 180,
			paddingBottom: 80,
		},
	},
	fourthBlockButtonContainer: {
		width: "100%",
		"& button": {
			width: "70%",
			paddingLeft: 0,
			paddingRight: 0,
		},
		margin: "3rem auto",
		[theme.breakpoints.down(768)]: {
			textAlign: "center",
			margin: "2rem auto",
		},
	},
	fifthContentBlock: {
		[theme.breakpoints.between(768, 1920)]: {
			// paddingLeft: 275,
			// paddingRight: 275,
		},
	},
	fifthBlockTitle: {
		color: "#505050",
		width: "100%",
	},
	fifthContentBlockRow: {
		display: "flex",
		columnGap: 97,
		width: "100%",
		paddingTop: 77,
		[theme.breakpoints.down(768)]: {
			paddingTop: 69,
		},
		[theme.breakpoints.between(768, 1920)]: {
			columnGap: 60,
			paddingTop: 40,
		},
	},
	questionIcon: {
		minWidth: 114,
	},
	fifthBlockContent: {
		"& img": {
			height: "100%",
			[theme.breakpoints.down(768)]: {
				height: "auto",
				display: "block",
			},
		},
		"& .imgDescription": {
			color: "#505050",
			"& p": {
				fontSize: "1.4rem",
				fontWeight: "bold",
			},
			"& span": {
				fontSize: "1.3rem",
			},
		},
	},
	questionImg: {
		paddingBottom: 45,
	},
	answerImg: {
		paddingTop: 15.8,
		paddingBottom: 50.2,
	},
	answerText: {
		[theme.breakpoints.down(768)]: {
			display: "none",
		},
	},
	fifthBlockButtonContainer: {
		marginTop: 98,
		marginBottom: 222,
		[theme.breakpoints.down(768)]: {
			marginTop: 91.5,
			marginBottom: 111,
		},
	},
	description: {
		maxWidth: "21vw",
		color: "#505050",
		fontSize: "1.4rem",
		paddingTop: 28,
		paddingBottom: 40,
		[theme.breakpoints.down(768)]: {
			paddingBottom: 0,
			paddingTop: 42,
			maxWidth: "none",
		},
	},
	button: {
		fontSize: "1.3rem",
		borderRadius: 50,
		padding: "6px 5vw",
		boxShadow: "10px 10px 10px 0px #c7d0e0",
		[theme.breakpoints.down(768)]: {
			minWidth: 215,
			margin: "0 auto",
		},
	},
	heroImgContainer: {
		marginTop: "5%",
		"& img": {
			marginLeft: "33.5%",
		},
	},
	signUpLink: {
		color: "#D80167",
		textDecoration: "underline",
		cursor: "pointer",
	},
	betaSignUpsticky: {
		position: "fixed",
		fontSize: 18,
		backgroundColor: "#D80067",
		color: "white",
		padding: "19px 5px",
		right: 0,
		top: 204,
		transform: "scaleX(-1) scaleY(-1)",
		writingMode: "vertical-rl",
		cursor: "pointer",
	},
	yellowImg: {
		width: "45vw",
		maxWidth: "1000px",
		marginBottom: 31,
		border: "white 19px solid",
		borderTop: "white 22px solid",
		borderBottom: "white 77px solid",
		[theme.breakpoints.down(768)]: {
			width: "100%",
			marginBottom: 0,
		},
	},
	shadow: {
		height: 50,
		backgroundImage: "radial-gradient(#CECECE, #FCFCFC 70%)",
		[theme.breakpoints.down(768)]: {
			marginBottom: 26,
			marginTop: 12,
		},
	},
	scrollImg: {
		width: 45,
		paddingTop: 65,
	},
	videoElementContainer: {
		position: "relative",
	},
	briaIcon: {
		position: "absolute",
		bottom: 15,
		right: 18,
		width: 39,
	},
}));

export default LandingPage2;
