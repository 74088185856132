import { Button } from "@material-ui/core";
import AppContextInterface from "../Context/AppContextInterface";
import { SAMLAuthProvider } from "firebase/auth";
import RouterConstants from "../Constants/RouterConstants";

export const getCustomAuthProviderButton = (
	context: AppContextInterface,
	isLoading: boolean,
	buttonClass: any,
	buttonText: any,
	redirect: boolean = false,
	history: any = undefined
) => {
	return context.customAuthProvider ? (
		<Button
			variant="outlined"
			startIcon={context.customAuthProvider.icon}
			onClick={async () =>
				isLoading
					? null
					: context
							.registerViaAuthProvider(
								new SAMLAuthProvider(context.customAuthProvider?.providerId ?? ""),
								true
							)
							.then(() => {
								if (redirect && history) {
									history.push(RouterConstants.HOME.path);
								} else {
									context.setAuthDialogState(false);
								}
							})
			}
			className={buttonClass}
		>
			{buttonText}
		</Button>
	) : (
		<></>
	);
};
