import { API_SUBSCRIPTION_TYPES_ORDER, ApiSubscriptionTypes } from "../Constants/SubscriptionConstants";
import AppContextInterface from "../Context/AppContextInterface";
import { SystemViewTypes } from "../Models/SystemView";

// export async function redirectToWebPlansPage(history: any) {
// 	history.push(RouterConstants.WEB_PLANS.path);
// }

// export async function checkIfUserHasCredits(
// 	history: any,
// 	creditType: SubscriptionCreditsTypes,
// 	isIframe: boolean,
// 	context: any,
// 	redirectToPricing = true
// ) {
// 	const hasCredits = isIframe || context.user?.isAdmin() ? true : await userHasCredits(creditType);
// 	redirectToPricing && !hasCredits && redirectToWebPlansPage(history);
// 	return hasCredits;
// }

export function checkIfStandardApiSubscriptionType(
	context: AppContextInterface,
	ignoreEmpty: boolean = false
): boolean {
	const selectedOrganization = context.selectedOrganization;
	const hasOrgs = selectedOrganization !== null && selectedOrganization !== undefined;
	if (
		context.user?.isAdmin() &&
		(context.systemView === SystemViewTypes.Admin || context.systemView === SystemViewTypes.Testing)
	) {
		return true;
	} else if (ignoreEmpty && !hasOrgs) {
		return true;
	} else if (
		hasOrgs &&
		selectedOrganization?.apiSubscription?.apiSubscriptionType &&
		["none"]
			.concat(Object.values(ApiSubscriptionTypes))
			.includes(selectedOrganization?.apiSubscription?.apiSubscriptionType as ApiSubscriptionTypes)
	) {
		return true;
	} else return false;
}

// export function isApiSubscriptionActive(context: AppContextInterface): boolean {
// 	const selectedOrganization = context.selectedOrganization;
// 	return !!(
// 		selectedOrganization &&
// 		selectedOrganization?.apiSubscription?.apiSubscriptionStatus === ApiSubscriptionStatuses.active
// 	);
// }

export function getNextApiRecommendedSubscription(apiSubscriptionType: ApiSubscriptionTypes) {
	return API_SUBSCRIPTION_TYPES_ORDER[API_SUBSCRIPTION_TYPES_ORDER.indexOf(apiSubscriptionType) + 1];
}

// export const IsProWebSubscription = () => {
// 	const context = useContext(Context);
// 	const { isGuestUser } = IsGuestUser();
// 	const isProWebSubscription = () => {
// 		return (
// 			!isGuestUser() &&
// 			context.user?.subscription.web_subscription_type &&
// 			[SubscriptionTypes.pro, SubscriptionTypes.ultimate].includes(
// 				context.user?.subscription.web_subscription_type
// 			)
// 		);
// 	};
//
// 	return { isProWebSubscription };
// };
