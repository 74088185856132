import React from "react";

function CropIcon({ color }: any) {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 1.5V11.5C4 11.7652 4.10536 12.0196 4.29289 12.2071C4.48043 12.3946 4.73478 12.5 5 12.5H15M1 4.5H11C11.2652 4.5 11.5196 4.60536 11.7071 4.79289C11.8946 4.98043 12 5.23478 12 5.5V15.5"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default CropIcon;
