export const clothColors = {
	personlizerUpperClothHex: [
		"#ff0000",
		"#ff9900",
		"#feff00",
		"#03ff00",
		"#03ffff",
		"#4887e8",
		"#1100ff",
		"#9900ff",
		"#ff00ff",
	],
	personlizerUpperClothRgp: [
		[255, 0, 0],
		[255, 153, 0],
		[254, 255, 0],
		[3, 255, 0],
		[3, 255, 255],
		[72, 135, 232],
		[17, 0, 255],
		[153, 0, 255],
		[255, 0, 255],
	],
};
