import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function SquareIcon(props: SvgIconProps) {
	return (
		<svg
			{...props}
			width="20"
			height="17"
			viewBox="0 0 20 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.75"
				y="0.75"
				width="18.5"
				height="15.5"
				rx="1.25"
				stroke-width="1.5"
			/>
		</svg>
	);
}

export default SquareIcon;
