import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function BriaWhiteIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			id="Group_3088"
			data-name="Group 3088"
			xmlns="http://www.w3.org/2000/svg"
			width="38.135"
			height="24.434"
			viewBox="0 0 38.135 24.434"
			{...props}
		>
			<circle
				id="Ellipse_104"
				data-name="Ellipse 104"
				cx="12.217"
				cy="12.217"
				r="12.217"
				transform="translate(0 0)"
				fill="#fff"
			/>
			<circle
				id="Ellipse_105"
				data-name="Ellipse 105"
				cx="6.664"
				cy="6.664"
				r="6.664"
				transform="translate(24.807 4.067)"
				fill="#fff"
			/>
			<circle
				id="Ellipse_106"
				data-name="Ellipse 106"
				cx="2.777"
				cy="2.777"
				r="2.777"
				transform="translate(25.544 18.511)"
				fill="#fff"
			/>
		</SvgIcon>
	);
}

export default BriaWhiteIcon;
