import LinksConst from "../Constants/LinksConst";

export const isDevelopment =
	!process.env.NODE_ENV || process.env.NODE_ENV === "development" || !!process.env.REACT_APP_IS_INT;

export const isProd =
	window.location.hostname === LinksConst.PROD_HOSTNAME ||
	window.location.hostname === LinksConst.PROD_IFRAME_HOSTNAME;

export const isInt =
	window.location.hostname === LinksConst.INT_HOSTNAME || window.location.hostname === LinksConst.INT_IFRAME_HOSTNAME;

export function getPlatformHost() {
	return isProd ? "https://platform.bria.ai/" : "https://int.platform.bria.ai/";
}
