import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const PremiumIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<svg
				width="16"
				height="11"
				viewBox="0 0 17 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.8432 2.95916C15.6542 2.79782 15.3874 2.76382 15.1639 2.87263L11.7438 4.53763L8.53466 0.83757C8.41598 0.700695 8.24369 0.62207 8.06251 0.62207C7.88132 0.62207 7.70907 0.700695 7.59035 0.83757L4.38123 4.5376L0.961101 2.8726C0.737569 2.76382 0.470882 2.79779 0.281819 2.95913C0.0927569 3.12048 0.0172569 3.37854 0.0895381 3.61635L2.46454 11.4289C2.54457 11.6921 2.78735 11.8721 3.06251 11.8721H13.0625C13.3376 11.8721 13.5804 11.6921 13.6604 11.4289L16.0354 3.61638C16.1078 3.37857 16.0323 3.12051 15.8432 2.95916ZM12.5992 10.6221H3.52573L1.70226 4.6237L4.26544 5.87154C4.51994 5.99541 4.82576 5.93285 5.01116 5.7191L8.06251 2.20098L11.1139 5.7191C11.2993 5.93288 11.6052 5.99538 11.8596 5.87154L14.4228 4.6237L12.5992 10.6221Z"
					fill={props.color ? props.color : "#D80067"}
				/>
			</svg>
		</SvgIcon>
	);
};

export default PremiumIcon;
