import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { APPEND_ASK_ALAN_RECORD } from "../GraphQL/mutations";
import { GET_ASK_ALAN_SCORES } from "../GraphQL/queries";
import {
	AppendAskAlanRecord,
	AppendAskAlanRecordVariables,
} from "../GraphQL/types/AppendAskAlanRecord";
import {
	GetAskAlanScores,
	GetAskAlanScoresVariables,
	GetAskAlanScores_getAskAlanScores,
} from "../GraphQL/types/GetAskAlanScores";
import { SystemViewTypes } from "../Models/SystemView";
import { Rect } from "../types/graphql-global-types";
import { useSystemViewAt } from "./useSystemView";

interface getAskAlanScoresParams {
	ethnicity: string;
	objectType: string | undefined;
	visualHash: string;
	rect: Rect[] | undefined;
}
const useAskAlan = () => {
	const [isCrowdScoring, setIsCrowdScoring] = useState<boolean>(false);
	const [applyAt, dontApplyAt] = useSystemViewAt([SystemViewTypes.Testing]); // so this function callback will only be called  at any systemType other than testing

	const [isAutomaticScoring, setIsAutomaticScoring] =
		useState<boolean>(false);

	const [getAskAlanScoresQuery, { data, loading, error }] =
		useLazyQuery<GetAskAlanScores, GetAskAlanScoresVariables>(
			GET_ASK_ALAN_SCORES
		);
	const [setAskAlanScoreMutation] = useMutation<
		AppendAskAlanRecord,
		AppendAskAlanRecordVariables
	>(APPEND_ASK_ALAN_RECORD);

	const [askAlanList, setaskAlanList] =
		useState<(GetAskAlanScores_getAskAlanScores[] | null)[] | null>(null);

	useEffect(() => {
		if (data) {
			setaskAlanList(data?.getAskAlanScores ?? []);
		}
	}, [data]);

	useEffect(() => {
		if (error) setaskAlanList([]);
	}, [error]);

	const getAskAlanScores = useCallback(
		({
			ethnicity,
			objectType,
			visualHash,
			rect,
		}: getAskAlanScoresParams) => {
			dontApplyAt(() => {
				if (objectType && rect) {
					getAskAlanScoresQuery({
						variables: {
							automaticScoring: isAutomaticScoring,
							crowdScoring: isCrowdScoring,
							ethnicity: ethnicity,
							objectType: objectType,
							visualHash: visualHash,
							rects: rect,
						},
					});
				}
			});
		},
		[getAskAlanScoresQuery, isAutomaticScoring, isCrowdScoring]
	);

	return {
		askAlanScoresList: askAlanList,
		setAskAlanScoreMutation,
		getAskAlanScores,
		setIsCrowdScoring,
		setIsAutomaticScoring,
	};
};

export default useAskAlan;
