import { Box } from "@material-ui/core";
import React from "react";
import { ChangeEventHandler } from "react";
import { useState } from "react";
import { ChangeEvent } from "react";
import { useContext } from "react";
import { BrandContext } from "../BrandPage/BrandPage";
import TitleTexts from "../TitleTexts/TitleTexts";
import TextArea from "./TextArea/TextArea";
import { makeStyles } from "@material-ui/styles";
import { brandSteps } from "../steps";

export default function Gardian() {
	const {
		next,
		currentIndex,
		brandState,
		updateBrandState,
		defaultStepValue,
	} = useContext(BrandContext);
	const currentStep = brandSteps()?.[currentIndex];
	const { mandatory } = currentStep;
	const disableNext = !defaultStepValue && mandatory;
	const classes = useStyles();
	const [gardianText, setGardianText] = useState<string>(
		defaultStepValue || ""
	);

	const handleChange = ({
		target: { value },
	}: ChangeEvent<HTMLInputElement>) => {
		updateBrandState(value);
		setGardianText(value);
	};

	return (
		<Box className={classes.guardianRoot}>
			<TitleTexts
				passedClassName={classes.brandName}
				subText={(brandState as any)?.name}
			/>
			<TitleTexts
				passedClassName={classes.titleTexts}
				text="Let’s dive deeper"
				subText="Describe your mood in your own words so AI could incorporate mood / sentiment in photographic style"
			/>
			<TextArea
				value={gardianText}
				onChange={handleChange}
				placeholder="Eg. Bria inspires creators to unleash their imaginations by using A.I. based cutting edge technology to understands complex creative visions and visually execute them. "
				autoFocus
				onKeyPress={(event: KeyboardEvent) => {
					if (event.key === "Enter" && !disableNext) {
						next();
					}
				}}
			/>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	brandName: {
		color: "#A4A4A4",
		padding: "5vh 0 5vh",
		"& p": {
			fontWeight: 400,
			fontSize: "24px",
		},
	},
	titleTexts: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	guardianRoot: {
		maxWidth: 800,
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
		"& textarea": {
			fontFamily: "Montserrat",
			marginTop: "3rem",
			background: "none",
			border: "unset",
			color: "#495057",
			fontSize: "24px",
			fontWeight: 400,
			textAlign: "center",
			"&::placeholder": {
				color: "#A4A4A480",
				opacity: "0.5",
			},
		},
	},
}));
