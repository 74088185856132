import { createStyles, FormControlLabel, makeStyles, Theme, withStyles } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import React from "react";

const CustomizedRadio = withStyles({
	root: {
		color: "transparent",
		"&:hover": {
			backgroundColor: "transparent !important",
			color: "transparent",
		},
	},
})(Radio);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			borderRadius: "50%",
			width: 16,
			height: 16,
			margin: "2px 5px 2px 2px",
			boxShadow:
				"inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
			backgroundColor: theme.palette.background.paper,
			"input:hover ~ &": {
				boxShadow: "inset 0 0 0 1px rgba(73, 80, 87, 1)",
				borderRadius: "50%",
				display: "block",
				width: 16,
				height: 16,
				backgroundImage: `radial-gradient(#A4A4A480,#A4A4A480 47%,transparent 47%)`,
				content: '""'
			},
		},
		checkedIcon: {
			backgroundColor: theme.palette.background.paper,
			backgroundImage:
				"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
			"&:before": {
				borderRadius: "50%",
				display: "block",
				width: 16,
				height: 16,
				backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 47%,transparent 47%)`,
				content: '""',
			},
		},
		formControlLabel: {
			fontSize: (props: any) => props.fontSize ?? 14,
		},
		formControlLabelDisabled: {
			pointerEvents: "none"
		},
		formControlLabelRoot: {
			marginRight: 9,
			marginLeft: -9,
			flex: 1,
			whiteSpace: "pre",
		},
	})
);

const BriaRadio = ({ ...props }) => {
	const classes = useStyles(props);
	return (
		<CustomizedRadio
			color="primary"
			checkedIcon={
				<span className={clsx(classes.icon, classes.checkedIcon)} />
			}
			icon={<span className={classes.icon} />}
			inputProps={{ "aria-label": "primary radio button" }}
			disableRipple
			{...props}
		/>
	);
};

export const BriaRadioControlLabel = (props: any) => {
	const { label, value, fontSize, color } = props;
	const classes = useStyles(props);

	return (
		<FormControlLabel
			classes={{
				label: classes.formControlLabel,
				root: classes.formControlLabelRoot,
				disabled: classes.formControlLabelDisabled
			}}
			control={<BriaRadio />}
			style={{fontSize: fontSize, color: color}}
			{...props}
		/>
	);
};

export default BriaRadio;
