/* eslint-disable react-hooks/exhaustive-deps */
import {Box, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useEffect, useState} from "react";
import {getRandomText} from "../../../../Constants/RandomTextGenerator";
import ColorInput from "../../../Tools/Brand/ColorPallete/ColorInput/ColorInput";
import BriaButton, {BUTTONTYPES} from "../../BriaButton";
import {BriaRadioControlLabel} from "../../BriaRadio";
import {bgPrompts, Campaign} from "./utils";
import {useTranslation} from "react-i18next";

type Props = {
	campaign: Campaign;
	incompleteFormError: boolean;
	handleCampaignChange: (key: keyof Campaign, value: any) => void;
};

const CampaignBackground = ({ campaign, incompleteFormError, handleCampaignChange }: Props) => {
	const { t } = useTranslation();
	const [originalBackgroundRadio, setOriginalBackgroundRadio] = useState(false);
	const [newBackgroundRadio, setNewBackgroundRadio] = useState(true);
	const [solidColorRadio, setSolidColorRadio] = useState(false);
	const [bgDescription, setBgDescription] = useState("");
	const [solidColor, setSolidColor] = useState("");
	const classes = useStyles();

	useEffect(() => {
		setSolidColor(campaign.colors[0]);
		if (solidColorRadio) {
			handleCampaignChange("bgDescription", { ...campaign.bgDescription, text: campaign.colors[0] });
		}
	}, [campaign.colors]);

	const getRandomPrompt = () => {
		let prompt = getRandomText(bgPrompts);
		setBgDescription(prompt);
		if (newBackgroundRadio) {
			handleCampaignChange("bgDescription", { ...campaign.bgDescription, text: prompt });
		}
	};

	const handleOriginalBackgroundRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewBackgroundRadio(false);
		setSolidColorRadio(false);
		setOriginalBackgroundRadio(event.target.checked);
		handleCampaignChange("keepOriginalBg", event.target.checked);
	};

	const handleNewBackgroundRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOriginalBackgroundRadio(false);
		handleCampaignChange("keepOriginalBg", false);
		setSolidColorRadio(false);
		setNewBackgroundRadio(event.target.checked);
		handleCampaignChange("bgDescription", { text: bgDescription });
	};

	const handleSolidRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOriginalBackgroundRadio(false);
		handleCampaignChange("keepOriginalBg", false);
		setNewBackgroundRadio(false);
		setSolidColorRadio(event.target.checked);
		handleCampaignChange("bgDescription", { ...campaign.bgDescription, text: solidColor });
	};

	const handleBgDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setBgDescription(e.target.value);
		if (newBackgroundRadio) {
			handleCampaignChange("bgDescription", { ...campaign.bgDescription, text: e.target.value });
		}
	};

	const handleColorChange = (color: string, remove?: boolean) => {
		setSolidColor(color);
		handleCampaignChange("bgDescription", { text: color });
	};

	return (
		<Box>
			<Typography className={classes.inputLable}>{t("backgroundStar")}</Typography>
			<Box className={classes.solidColor}>
				<BriaRadioControlLabel
					className={classes.radioBtn}
					checked={originalBackgroundRadio}
					onChange={handleOriginalBackgroundRadio}
				/>
				<Typography className={classes.subBackgroundText}>{t("keepOriginalBackground")}</Typography>
			</Box>
			<Box>
				<Box className={classes.subBackground} style={{ justifyContent: "space-between" }}>
					<Box className={classes.subBackground}>
						<BriaRadioControlLabel
							className={classes.radioBtn}
							checked={newBackgroundRadio}
							onChange={handleNewBackgroundRadio}
						/>
						<Typography className={classes.subBackgroundText}>{t("describeNewBackground")}</Typography>
					</Box>
					<BriaButton
						className={classes.surpriseBtn}
						buttonType={BUTTONTYPES.TEXTBUTTOPURPLE}
						onClick={getRandomPrompt}
					>
						{t("surpriseMe")}
					</BriaButton>
				</Box>
				<TextField
					placeholder={t("mountainRiverWithFlowingWater")}
					variant="outlined"
					fullWidth
					multiline
					minRows={3}
					onChange={handleBgDescriptionChange}
					value={bgDescription}
					className={classes.bigTextField}
					error={incompleteFormError && newBackgroundRadio && !campaign.bgDescription.text}
				/>
			</Box>
			<Box className={classes.solidColor}>
				<BriaRadioControlLabel
					className={classes.radioBtn}
					checked={solidColorRadio}
					onChange={handleSolidRadio}
				/>
				<Typography className={classes.subBackgroundText}> {t("applySolidColor")}</Typography>
				<Box style={{ marginLeft: "15px" }}>
					<ColorInput circleWidth="33px" color={campaign.colors[0]} onColorChange={handleColorChange} />
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	inputLable: {
		marginLeft: "4px",
		marginBottom: "8px",
		fontWeight: 600,
		fontSize: "min(1.3vw, 13px)",
		color: "black",
	},
	subBackground: {
		display: "flex",
		alignItems: "center",
	},
	bigTextField: {
		width: "90%",
		marginLeft: "30px",
		"& .MuiOutlinedInput-root": {
			fontSize: "min(1.2vw, 12px)",
		},
	},
	surpriseBtn: {
		marginRight: "1vh",
		marginBottom: "-8px",
		fontSize: "min(1.2vw, 12px)",
		"&:hover": {
			background: "unset",
			opacity: "0.75",
		},
	},
	checkbox: {},
	radioBtn: {
		marginRight: 0,
		flex: 0,
	},
	subBackgroundText: {
		color: "#171717",
		fontFamily: "Montserrat",
		fontSize: "min(1.3vw, 14px)",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
	},
	solidColor: {
		display: "flex",
		alignItems: "center",
		marginTop: "1vh",
	},
}));
export default CampaignBackground;
