import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.66096 6.44922L11.8509 2.25933C12.0497 2.06082 12.1616 1.79146 12.1618 1.51049C12.1621 1.22952 12.0507 0.959959 11.8522 0.761107C11.6537 0.562256 11.3843 0.450403 11.1033 0.450155C10.8224 0.449906 10.5528 0.561283 10.354 0.759784L6.16406 4.94968L1.97417 0.759784C1.77532 0.560932 1.50562 0.449219 1.2244 0.449219C0.94318 0.449219 0.673479 0.560932 0.474628 0.759784C0.275776 0.958636 0.164062 1.22834 0.164062 1.50955C0.164062 1.79077 0.275776 2.06047 0.474628 2.25933L4.66452 6.44922L0.474628 10.6391C0.275776 10.838 0.164062 11.1077 0.164062 11.3889C0.164062 11.6701 0.275776 11.9398 0.474628 12.1387C0.673479 12.3375 0.94318 12.4492 1.2244 12.4492C1.50562 12.4492 1.77532 12.3375 1.97417 12.1387L6.16406 7.94876L10.354 12.1387C10.5528 12.3375 10.8225 12.4492 11.1037 12.4492C11.3849 12.4492 11.6546 12.3375 11.8535 12.1387C12.0523 11.9398 12.1641 11.6701 12.1641 11.3889C12.1641 11.1077 12.0523 10.838 11.8535 10.6391L7.66096 6.44922Z"
				fill="#495057"
			/>
		</SvgIcon>
	);
};

export default CloseIcon;
