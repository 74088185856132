import { Box, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { ChangeEvent, useContext, useState } from "react";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
import Context from "../../Context/Context";
import Analytics, { ANALYTICS_EVENTS, HUBSPOT_EVENTS } from "../../Models/Analytics";
import { IsGuestUser } from "../../Helpers/isGuest";

function FairDiffusionPage() {
	const { isGuestUser } = IsGuestUser();
	const context = useContext(Context);
	const [selectedStep, setSelectedStep] = useState<number>(1);
	const [emailAddress, setEmailAddress] = useState<any>(null);
	const classes = useStyles({ selectedStep });

	const handleSignUpSubmission = async () => {
		if (emailAddress) {
			setSelectedStep(2);
			if (isGuestUser()) {
				Analytics.pushAnonymousHubspotEvent(HUBSPOT_EVENTS.FAIR_DIFFUSION_REQUEST, true, emailAddress);
			} else {
				Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.FAIR_DIFFUSION_REQUEST);
			}
			Analytics.logEvent(ANALYTICS_EVENTS.FAIR_DIFFUSION_SIGN_UP);
		}
	};

	return (
		<Box className={classes.fullWidth}>
			<Box className={classes.rootContainer}>
				<Box className={classes.bgVideoContainer}>
					<video controls={false} preload="true" autoPlay={true} loop={true} muted={true} playsInline={true}>
						<source src="https://labs-assets.bria.ai/Untitled%205.m4v" type="video/mp4" />
						<img src="https://labs-assets.bria.ai/PHOTO-2023-03-16-16-19-31%201.jpg" alt=""></img>
					</video>
				</Box>
				<Box className={classes.root}>
					<Box className={classes.container}>
						<Box className={classes.titleBox}>
							<Typography className={classes.mainTitle}>Fair Diffusion</Typography>
						</Box>
						<Box className={classes.descriptionContainer}>
							<Typography className={classes.titleStyle}>Generative AI done right</Typography>
							<Typography className={classes.descriptionTextStyle}>
								The wheels are in motion. Generative AI is rapidly transforming the creative industry,
								not stopping to recognize artists' creative contributions. We started Fair Diffusion to
								offer a practical alternative where payment is made to the artist whose work is being
								used. More so, this will demonstrate to lawmakers and courts that art and innovation can
								coexist and thrive. Join us to define the future of the arts, on our terms.
							</Typography>
							{selectedStep === 1 ? (
								<Box
									style={{
										display: "flex",
										marginTop: "3.125vw",
									}}
								>
									<TextField
										placeholder={"Enter your email to define artists’ terms"}
										size="small"
										variant="outlined"
										fullWidth
										className={classes.emailTextfield}
										value={emailAddress}
										onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
											setEmailAddress(event?.target.value)
										}
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												handleSignUpSubmission();
											}
										}}
									/>
									<BriaButton
										className={classes.submit}
										buttonType={BUTTONTYPES.PRIMARY}
										onClick={handleSignUpSubmission}
									>
										Sign up
									</BriaButton>
								</Box>
							) : (
								<Typography className={classes.messageTextStyle}>
									Thank you for joining us, we will keep you updated.
								</Typography>
							)}
						</Box>
					</Box>
					<Box className={classes.copyrightContainer}>
						<Typography>Art by Tomer Hanuka / Animated by Yoni Goodman</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	fullWidth: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	bgVideoContainer: {
		position: "fixed",
		zIndex: -1,
		top: 0,
		left: 0,
		minWidth: "100%",
		minHeight: "100%",
	},
	copyrightContainer: {
		color: "white",
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: "5%",
		paddingBottom: "2%",
		alignItems: "flex-end",
		fontSize: "20px",
		flexGrow: 1,
	},
	rootContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
	},
	root: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.46)",
	},
	container: {
		marginLeft: "13vw",
		marginTop: "2.6vw",
	},
	titleBox: {
		width: "100%",
	},
	mainTitle: {
		color: "#FFF",
		fontWeight: 700,
		fontSize: "1.6vw",
		display: "flex",
		alignItems: "center",
	},
	descriptionContainer: {
		marginTop: "5.2vw",
		maxWidth: "36.5vw",
	},
	titleStyle: {
		fontSize: "4.6875vw",
		fontFamily: "Montserrat",
		color: "#FFFF",
		lineHeight: 1,
		fontWeight: 700,
	},
	description: {
		textAlign: "center",
		fontSize: 20,
		marginTop: "4vh",
	},
	descriptionTextStyle: {
		fontSize: 20,
		fontWeight: 400,
		lineHeight: 1.2,
		fontFamily: "Montserrat",
		color: "#FFFF",
		marginTop: "3.64vw",
	},
	emailTextfield: {
		"& .MuiOutlinedInput-root": {
			fontSize: "1.1vw",
			borderRadius: "1vh",
			background: "#FFFFFF",
			height: "3vw",
		},
	},
	messageTextStyle: {
		fontSize: "1.25vw",
		fontWeight: 700,
		marginTop: "4.427vw",
		color: "#FFFF",
	},
	submit: {
		width: 146,
		height: "3vw",
		backgroundColro: "#D70067",
		borderRadius: 8,
		marginLeft: 20,
		fontSize: "1.1vw",
		fontFamily: "Montserrat",
		letterSpacing: ".02em",
		lineHeight: 1.25,
		fontWeight: 600,
	},
}));

export default FairDiffusionPage;
