import { Box, InputBase, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import TitleTexts from "../TitleTexts/TitleTexts";
import { useContext } from "react";
import { ChangeEvent } from "react";
import { BrandContext } from "../BrandPage/BrandPage";
import { brandSteps } from "../steps";

export default function NameInput() {
	const classes = useStyles();
	const {
		next,
		currentIndex,
		brandState,
		updateBrandState,
		defaultStepValue,
	} = useContext(BrandContext);
	const currentStep = brandSteps()?.[currentIndex];
	const { mandatory } = currentStep;
	const disableNext = !defaultStepValue && mandatory;

	const [name, setName] = useState<string | null>(defaultStepValue);
	const projectTypesSubtext: { [typeValue: number]: string } = {
		0: "Create a name for your new brand",
		1: "Create a name for your additional brand style",
		2: "Create a name for your new campaign",
	};

	const handleChange = ({ target: { value } }: any) => {
		setName(value ?? "");
		updateBrandState(value);
	};

	return (
		<Box className={classes.nameInputRoot}>
			<TitleTexts
				passedClassName={classes.title}
				text="Let’s create a new brand style"
				subText={
					projectTypesSubtext[(brandState as any)?.projectType ?? 1]
				}
			/>
			<TextField
				InputProps={{
					classes: { root: classes.input },
				}}
				value={name}
				onChange={handleChange}
				className={classes.inputWrapper}
				variant="standard"
				fullWidth
				placeholder="Eg. Bria’s summer vibes"
				onKeyPress={(event) => {
					if (event.key === "Enter" && !disableNext) {
						next();
					}
				}}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	nameInputRoot: {
		maxWidth: 800,
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
		"& > :first-child": {
			margin: "24% auto 0",
		},
	},
	title: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	inputWrapper: {
		marginTop: "7vh",
		maxWidth: "50%",
		"& input": {
			fontSize: "24px",
			paddingBottom: "7px",
			fontWeight: 400,
			"&::placeholder": {
				textAlign: "center",
			},
			"&.MuiInput-input": {
				textAlign: "center !important",
			},
		},
	},
	grayFont: {
		color: "#A4A4A4",
	},
}));
