import { useState } from "react";

export const useMultiPromise = () => {
	const [hookState, setHookState] = useState<any>({
		loading: false,
		error: "",
		response: [],
	});
	const updateHookState = (newState = {}) => {
		setHookState({ ...hookState, ...newState });
	};
	const executePromise: executePromiseType = (promises = []) => {
		return new Promise((resolve, reject) => {
			updateHookState({ loading: true });
			Promise.allSettled(promises)
				.then((reposnses = []) => {
					const values = reposnses
						.filter((res) => res.status)
						.map((promise: any) => promise.value);
					updateHookState({ response: values });
					resolve(values);
				})
				.catch((err: any) => {
					reject(err);
				})
				.finally(() => {
					updateHookState({ loading: false });
				});
		});
	};
	const { response, loading, error } = hookState;
	return [executePromise, response, loading, error];
};

export type executePromiseType = (promises: Promise<any>[]) => Promise<any>;
