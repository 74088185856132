import { gql } from "@apollo/client";
import BriaAPI from "../../../../sdk/resources/briaAPI";

export type CampaignAsset = {
	mainObject: string;
	logo: string;
	cta: string;
};

export type HeaderSettings = {
	color: [number, number, number];
	font: string;
	size: string;
	style: string;
};

export interface Header extends HeaderSettings {
	text: string;
}

export type BgDescription = {
	text: string;
	image?: string;
};

export type Brand = {
	id: string;
	header1: HeaderSettings;
	header2: HeaderSettings;
	logo: string;
	colorPallet: string[];
	name: string;
	title: string;
};

export type Campaign = {
	header1: Header;
	header2: Header;
	layouts: string[];
	mainObject: { filename: string; base_64_string: string };
	productVisualHash: string;
	sid?: string;
	logo: { filename: string; base_64_string: string } | string;
	cta: { filename: string; base_64_string: string };
	colors: string[];
	bgDescription: BgDescription;
	seed?: number;
	requestPsd: boolean;
	keepOriginalBg: boolean;
};

export const CREATE_CAMPAIGN_QUERY = gql`
	mutation createCampaign($campaign: Campaign!) {
		createCampaign(campaignData: $campaign) {
			images
			seed
			psds
		}
	}
`;

export const defaultCampaign: Campaign = {
	header1: {
		color: [0, 0, 0],
		font: "",
		size: "",
		style: "",
		text: "",
	},
	header2: {
		color: [0, 0, 0],
		font: "",
		size: "",
		style: "",
		text: "",
	},
	layouts: [],
	mainObject: { filename: "", base_64_string: "" },
	productVisualHash: "",
	sid: undefined,
	logo: { filename: "", base_64_string: "" },
	cta: { filename: "", base_64_string: "" },
	colors: [""],
	bgDescription: { text: "" },
	seed: NaN,
	requestPsd: false,
	keepOriginalBg: false,
};

export function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export async function getImageInfo(imageURL: string, campaignName: string) {
	const response = await fetch(imageURL);
	const blob = await response.blob();

	const base64 = (await getBase64(blob as File)) as string;
	const imageResolution = await getImageResolution(imageURL);
	const fileExtension = getFileExtension(blob);

	const campaignNameLowercase = campaignName.toLowerCase().replace(/\s/g, "_");
	const filename = `${campaignNameLowercase}_${`${imageResolution.width}x${imageResolution.height}`}.${fileExtension}`;

	return { filename, base64 };
}

export async function getPsdInfo(psdUrl: string, index: number, campaignName: string) {
	const response = await fetch(psdUrl);
	const blob = await response.blob();

	const base64 = (await getBase64(blob as File)) as string;
	const campaignNameLowercase = campaignName.toLowerCase().replace(/\s/g, "_");
	const filename = `${campaignNameLowercase}_${index}.psd`;
	return { filename, base64 };
}

export async function getImageResolution(imageURL: string): Promise<{ width: number; height: number }> {
	const image = new Image();
	image.src = imageURL;

	return new Promise((resolve) => {
		image.onload = () => {
			const resolution = { width: image.width, height: image.height };
			resolve(resolution);
		};
	});
}

function getFileExtension(blob: Blob): string {
	const parts = blob.type.split("/");
	return parts[parts.length - 1];
}

export const isS3Uri = (uri: string) => {
	return uri.startsWith("s3://");
};

export const convertS3UriToHttpsUrl = (s3Uri: string) => {
	const parts = s3Uri.split("/");
	const bucketName = parts[2];
	const filePath = parts.slice(3).join("/");
	const region = "us-east-1";

	return `https://${bucketName}.s3.${region}.amazonaws.com/${filePath}`;
};

export const bgPrompts = [
	"On a table, office interior, blurry background, soft light, green hue",
	"On a hard wood floor, home interior, living room potted plant, soft light, warm filter",
	"On a concrete floor, hanger interior, octane render, utopia, ethereal, dust, graffiti, cinematic light",
	"Outdoors, concrete, urban basketball court, junk, graffiti, octane render, ethereal, cinematic light",
	"On a dirt road, Texas, outdoors, sunny, desert, blue sky, clouds, cactus, rocks",
	"On a rock, outdoors, Ireland, moss, grey sky, rain",
	"On a road, Montana, snow, winter, blue sky, soft light, cool photo filter",
	"A stormy monsoon rainstorm in the Arizona desert, the Sonoran desert landscape is full of life, water puddles, lightning bolt in the distance, beautiful cloudy deep blue sky",
	"On a dirt road, in a Mexican town, colorful walls, concrete, dramatic light",
	"Outdoors, Europe, Normandy, gray sky, rain, orange photo filter",
];

export const headersPrompts = [
	"Experience Seamless Communication with Orange",
	"Stay Connected, Informed, and Inspired",
	"Empowering Connectivity, One Orange Network at a Time",
	"Orange: Your Bridge to a Vibrant Digital Future",
	"Elevate Your Communication Experience with Orange: Where Innovation Meets Connection",
	"Innovation for Everyone: Discover Limitless Possibilities with Orange",
];
