import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import clsx from "clsx";
import Tooltip from "../Tooltip";
import AllFacesButton from "../../../assets/icons/AllFacesButton";

const useStyles = makeStyles((theme) => ({
	root: {
		// background: theme.palette.common.white,
		width: 105,
		height: 30,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(4),
		borderRadius: 25,
		overflow: "hidden",
	},
	toggleGroup: {
		height: "100%",
		width: "100%",
		padding: 1,
	},
	toggleButton: {
		height: "100%",
		width: "35px",
		stroke: "#A4A4A4",
		borderRadius: 15,
	},
	bothIcon: {
		marginLeft: 0,
		fontSize: 22,
		marginTop: 8,
	},
	noMsl: {
		marginRight: 0,
		fontSize: 8,
		color: theme.palette.grey[400],
	},
	msl: {
		marginRight: 0,
		fontSize: 12,
		color: theme.palette.grey[400],
	},
	activeToggleButton: {
		backgroundColor: `${theme.palette.primary.dark} !important`,
		stroke: "white",
		"&:disabled": {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
	},
}));

interface IProps {
	selectedOption: MslOptions;
	onOptionChange: (style: MslOptions) => void;
}

export enum MslOptions {
	both = "Both",
	onlyWithMSL = "onlyWithMSL",
	onlyWithoutMSL = "onlyWithoutMSL",
}

const MslSwitch: React.FC<IProps> = ({ selectedOption, onOptionChange }) => {
	const classes = useStyles();

	const handleOption = (
		event: React.MouseEvent<HTMLElement>,
		newOption: MslOptions
	) => {
		if (newOption !== null) {
			onOptionChange(newOption);
		}
	};

	return (
		<Box className={classes.root}>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={selectedOption}
				exclusive
				onChange={handleOption}
			>
				<Tooltip title="Images with and without MSL" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption === MslOptions.both,
						})}
						disabled={false}
						value={MslOptions.both}
					>
						<AllFacesButton className={classes.bothIcon} />
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Only images with MSL" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption === MslOptions.onlyWithMSL,
						})}
						value={MslOptions.onlyWithMSL}
						disabled={false}
					>
						<Typography className={classes.msl}>MSL</Typography>
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Only images without MSL" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption === MslOptions.onlyWithoutMSL,
						})}
						value={MslOptions.onlyWithoutMSL}
						disabled={false}
					>
						<Typography className={classes.noMsl}>
							No MSL
						</Typography>
					</ToggleButton>
				</Tooltip>
			</ToggleButtonGroup>
		</Box>
	);
};

export default MslSwitch;
