import React from "react";
import { useMemo } from "react";
import { ChevronRight } from "@material-ui/icons";
import { NavLink, useLocation } from "react-router-dom";
import Flex from "../Wrappers/Flex";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const breadsSrc: any = {
	"/": {
		title: "Home",
	},
	"/create-brand": {
		title: "Brands",
	},
};

export default function LocalBreadcrumbs() {
	const { pathname } = useLocation();
	const classes = useStyles();

	const mergeWithName = (path: string) => ({
		title: breadsSrc?.[path]?.title || "",
		path,
	});

	const removeEmptyPaths = (path: string) => !!path;

	const appendPath = (path: string, index: number, allElems: any[]) => {
		if (index < 1) return path;
		const processedPath = index
			? allElems
					.slice(1, index + 1)
					.reduce((prev, curr) => [prev, curr].join("/"), "")
			: path;
		return processedPath;
	};

	const addSlash = (elem: string, index: number) =>
		index ? ["/", elem].join("") : elem;

	const processPathname = () => {
		const unprocessedPaths = [
			"/",
			pathname.split("/").filter(removeEmptyPaths),
		].flat();

		return unprocessedPaths
			.map(addSlash)
			.map((el, index) => appendPath(el, index, unprocessedPaths))
			.map(mergeWithName);
	};

	const breads = useMemo(() => processPathname(), [pathname]);

	return (
		<Flex passedClasses={[classes.inlineMargin]}>
			{breads.map((bread, index) => (
				<Flex passedClasses={[classes.grayFont]}>
					<NavLink className={classes.navLink} to={bread.path}>
						{bread.title}
					</NavLink>
					{breads.length - 1 !== index && (
						<ChevronRight className={classes.grayFont} />
					)}
				</Flex>
			))}
		</Flex>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	grayFont: {
		color: theme.palette.text.secondary,
	},
	navLink: {
		textDecoration: "none",
		color: "inherit",
	},
	inlineMargin: {
		margin: "0 2rem",
	},
}));
