export const moodsList = [
	[
		{
			name: "Nostalgic",
			imageSource: "https://labs-assets.bria.ai/brands/Nostalgic.JPEG",
			moodParameters: {
				color: {
					vibrance: 10,
					saturation: -10,
				},
				light: {
					brightness: -10,
					contrast: 0,
				},
				color_mixer: [
					{ color: "orange", hue: 30 },
					{ color: "yellow", hue: -20 },
					{ color: "purple", hue: -20 },
					{ color: "blue", hue: -10 },
				],
			},
		},
		{
			name: "Youthful",
			imageSource: "https://labs-assets.bria.ai/brands/Youthful.JPEG",
			moodParameters: {
				color: {
					vibrance: -20,
					saturation: 40,
				},
				light: {
					brightness: -10,
					contrast: 40,
				},
				color_mixer: [
					{ color: "red", hue: 30 },
					{ color: "orange", hue: 20 },
					{ color: "purple", hue: -30 },
				],
			},
		},
	],
	[
		{
			name: "Serious",
			imageSource: "https://labs-assets.bria.ai/brands/Serious.JPEG",
			moodParameters: {
				color: {
					vibrance: 20,
					saturation: 0,
				},
				light: {
					brightness: 10,
					contrast: -20,
				},
				color_mixer: [
					{ color: "red", hue: 10 },
					{ color: "orange", hue: 10 },
					{ color: "green", hue: 40 },
					{ color: "magenta", hue: -50 },
				],
			},
		},
		{
			name: "Playful",
			imageSource: "https://labs-assets.bria.ai/brands/Playful.JPEG",
			moodParameters: {
				color: {
					vibrance: -50,
					saturation: 40,
				},
				light: {
					brightness: 0,
					contrast: 10,
				},
				color_mixer: [
					{ color: "red", hue: -20 },
					{ color: "yellow", hue: 50 },
					{ color: "blue", hue: -20 },
				],
			},
		},
	],
	[
		{
			name: "Casual",
			imageSource: "https://labs-assets.bria.ai/brands/Casual.JPEG",
			moodParameters: {
				color: {
					vibrance: -20,
					saturation: 0,
				},
				light: {
					brightness: -10,
					contrast: -10,
				},
			},
		},
		{
			name: "Elegant",
			imageSource: "https://labs-assets.bria.ai/brands/Elegant.JPEG",
			moodParameters: {
				color: {
					vibrance: 10,
					saturation: 0,
				},
				light: {
					brightness: -20,
					contrast: 20,
				},
				color_mixer: [
					{ color: "orange", hue: 60 },
					{ color: "green", hue: -40 },
				],
			},
		},
	],
	[
		{
			name: "Refined",
			imageSource: "https://labs-assets.bria.ai/brands/Refined.JPEG",
			moodParameters: {
				color: {
					vibrance: -60,
					saturation: -30,
				},
				light: {
					brightness: -20,
					contrast: -70,
				},
				color_mixer: [{ color: "red", hue: -80 }],
			},
		},
		{
			name: "Strong",
			imageSource: "https://labs-assets.bria.ai/brands/Strong.JPEG",
			moodParameters: {
				color: {
					vibrance: -10,
					saturation: 40,
				},
				light: {
					brightness: 20,
					contrast: 40,
				},
				color_mixer: [
					{ color: "yellow", hue: 50 },
					{ color: "green", hue: 70 },
					{ color: "purple", hue: -70 },
				],
			},
		},
	],
	[
		{
			name: "Simple",
			imageSource: "https://labs-assets.bria.ai/brands/Simple.JPEG",
			moodParameters: {
				color: {
					vibrance: 0,
					saturation: 0,
				},
				light: {
					brightness: 0,
					contrast: 0,
				},
				color_mixer: [
					{ color: "orange", hue: -40 },
					{ color: "green", hue: 40 },
					{ color: "purple", hue: 40 },
				],
			},
		},
		{
			name: "Complex",
			imageSource: "https://labs-assets.bria.ai/brands/Complex.JPEG",
			moodParameters: {
				color: {
					vibrance: 0,
					saturation: 10,
				},
				light: {
					brightness: -10,
					contrast: 30,
				},
				color_mixer: [
					{ color: "yellow", hue: -90 },
					{ color: "green", hue: 70 },
					{ color: "blue", hue: 60 },
					{ color: "magenta", hue: -80 },
				],
			},
		},
	],
	[
		{
			name: "Natural",
			imageSource: "https://labs-assets.bria.ai/brands/Natural.JPEG",
			moodParameters: {
				color: {
					vibrance: 0,
					saturation: -40,
				},
				light: {
					brightness: 0,
					contrast: 0,
				},
			},
			color_mixer: [
				{ color: "red", hue: -90 },
				{ color: "yellow", hue: 100 },
				{ color: "blue", hue: 10 },
			],
		},
		{
			name: "Industrial",
			imageSource: "https://labs-assets.bria.ai/brands/Industrial.JPEG",
			moodParameters: {
				color: {
					vibrance: -20,
					saturation: -10,
				},
				light: {
					brightness: 20,
					contrast: 30,
				},
				color_mixer: [
					{ color: "red", hue: -100 },
					{ color: "green", hue: 100 },
					{ color: "magenta", hue: -100 },
					{ color: "purple", hue: -100 },
				],
			},
		},
	],
	[
		{
			name: "Traditional",
			imageSource: undefined,
			moodParameters: {
				color: {
					vibrance: -10,
					saturation: 10,
				},
				light: {
					brightness: -10,
					contrast: -20,
				},
				color_mixer: [
					{ color: "red", hue: -60 },
					{ color: "orange", hue: 10 },
					{ color: "yellow", hue: -30 },
					{ color: "green", hue: -40 },
				],
			},
		},
		{
			name: "Innovative",
			imageSource: undefined,
			moodParameters: {
				color: {
					vibrance: 0,
					saturation: -60,
				},
				light: {
					brightness: 10,
					contrast: 60,
				},
				color_mixer: [
					{ color: "blue", hue: 60 },
					{ color: "orange", hue: 40 },
					{ color: "magenta", hue: 30 },
				],
			},
		},
	],
];

export const MOOD_SLIDER_MAX_VALUE = 100;
export const MOOD_SLIDER_MIN_VALUE = -100;
export const MOOD_SLIDER_DEFAULT_VALUE = 0;
