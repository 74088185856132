import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const UploadIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 48 48" width="48px" height="48px" {...props}>
			<defs>
				<filter
					id="filter0_d_21627_106866"
					x="0"
					y="0"
					width="113"
					height="113"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="20" dy="10" />
					<feGaussianBlur stdDeviation="16" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_21627_106866"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_21627_106866"
						result="shape"
					/>
				</filter>
			</defs>
			<g
				filter="url(#filter0_d_21627_106866)"
				transform="matrix(1, 0, 0, 1, -12.675643, -22.357319)"
			>
				<circle
					cx="36.8182"
					cy="46.4492"
					r="23"
					stroke="#A4A4A4"
					stroke-width="2"
					shape-rendering="crispEdges"
					fill="none"
					fillOpacity={0.9}
				/>
			</g>
			<path
				d="M 13.799 27.258 C 13.973 27.258 14.14 27.33 14.263 27.458 C 14.386 27.586 14.455 27.76 14.455 27.941 L 14.455 31.358 C 14.455 31.72 14.594 32.068 14.84 32.324 C 15.086 32.581 15.42 32.725 15.768 32.725 L 31.518 32.725 C 31.866 32.725 32.2 32.581 32.446 32.324 C 32.692 32.068 32.83 31.72 32.83 31.358 L 32.83 27.941 C 32.83 27.76 32.9 27.586 33.023 27.458 C 33.146 27.33 33.313 27.258 33.487 27.258 C 33.661 27.258 33.828 27.33 33.951 27.458 C 34.074 27.586 34.143 27.76 34.143 27.941 L 34.143 31.358 C 34.143 32.083 33.866 32.778 33.374 33.291 C 32.882 33.804 32.214 34.092 31.518 34.092 L 15.768 34.092 C 15.072 34.092 14.404 33.804 13.912 33.291 C 13.419 32.778 13.143 32.083 13.143 31.358 L 13.143 27.941 C 13.143 27.76 13.212 27.586 13.335 27.458 C 13.458 27.33 13.625 27.258 13.799 27.258 Z"
				fill="#A4A4A4"
			/>
			<path
				d="M 23.179 15.292 C 23.24 15.229 23.313 15.178 23.392 15.144 C 23.472 15.109 23.557 15.092 23.644 15.092 C 23.73 15.092 23.816 15.109 23.895 15.144 C 23.975 15.178 24.047 15.229 24.108 15.292 L 28.046 19.393 C 28.169 19.521 28.238 19.695 28.238 19.877 C 28.238 20.058 28.169 20.232 28.046 20.36 C 27.923 20.489 27.756 20.561 27.581 20.561 C 27.407 20.561 27.24 20.489 27.117 20.36 L 24.3 17.426 L 24.3 29.444 C 24.3 29.625 24.231 29.799 24.108 29.927 C 23.985 30.056 23.818 30.128 23.644 30.128 C 23.47 30.128 23.303 30.056 23.18 29.927 C 23.057 29.799 22.988 29.625 22.988 29.444 L 22.988 17.426 L 20.171 20.36 C 20.11 20.424 20.037 20.474 19.958 20.509 C 19.878 20.543 19.793 20.561 19.706 20.561 C 19.62 20.561 19.535 20.543 19.455 20.509 C 19.375 20.474 19.303 20.424 19.242 20.36 C 19.181 20.297 19.132 20.221 19.099 20.138 C 19.066 20.055 19.049 19.966 19.049 19.877 C 19.049 19.787 19.066 19.698 19.099 19.615 C 19.132 19.532 19.181 19.456 19.242 19.393 L 23.179 15.292 Z"
				fill="#A4A4A4"
			/>
			<path
				d="M 13.799 27.258 C 13.973 27.258 14.14 27.33 14.263 27.458 C 14.386 27.586 14.455 27.76 14.455 27.941 L 14.455 31.358 C 14.455 31.72 14.594 32.068 14.84 32.324 C 15.086 32.581 15.42 32.725 15.768 32.725 L 31.518 32.725 C 31.866 32.725 32.2 32.581 32.446 32.324 C 32.692 32.068 32.83 31.72 32.83 31.358 L 32.83 27.941 C 32.83 27.76 32.9 27.586 33.023 27.458 C 33.146 27.33 33.313 27.258 33.487 27.258 C 33.661 27.258 33.828 27.33 33.951 27.458 C 34.074 27.586 34.143 27.76 34.143 27.941 L 34.143 31.358 C 34.143 32.083 33.866 32.778 33.374 33.291 C 32.882 33.804 32.214 34.092 31.518 34.092 L 15.768 34.092 C 15.072 34.092 14.404 33.804 13.912 33.291 C 13.419 32.778 13.143 32.083 13.143 31.358 L 13.143 27.941 C 13.143 27.76 13.212 27.586 13.335 27.458 C 13.458 27.33 13.625 27.258 13.799 27.258 Z"
				stroke="#A4A4A4"
				stroke-width="0.75"
				fill="rgb(164, 164, 164)"
			/>
			<path
				d="M 23.179 15.292 C 23.24 15.229 23.313 15.178 23.392 15.144 C 23.472 15.109 23.557 15.092 23.644 15.092 C 23.73 15.092 23.816 15.109 23.895 15.144 C 23.975 15.178 24.047 15.229 24.108 15.292 L 28.046 19.393 C 28.169 19.521 28.238 19.695 28.238 19.877 C 28.238 20.058 28.169 20.232 28.046 20.36 C 27.923 20.489 27.756 20.561 27.581 20.561 C 27.407 20.561 27.24 20.489 27.117 20.36 L 24.3 17.426 L 24.3 29.444 C 24.3 29.625 24.231 29.799 24.108 29.927 C 23.985 30.056 23.818 30.128 23.644 30.128 C 23.47 30.128 23.303 30.056 23.18 29.927 C 23.057 29.799 22.988 29.625 22.988 29.444 L 22.988 17.426 L 20.171 20.36 C 20.11 20.424 20.037 20.474 19.958 20.509 C 19.878 20.543 19.793 20.561 19.706 20.561 C 19.62 20.561 19.535 20.543 19.455 20.509 C 19.375 20.474 19.303 20.424 19.242 20.36 C 19.181 20.297 19.132 20.221 19.099 20.138 C 19.066 20.055 19.049 19.966 19.049 19.877 C 19.049 19.787 19.066 19.698 19.099 19.615 C 19.132 19.532 19.181 19.456 19.242 19.393 L 23.179 15.292 Z"
				stroke="#A4A4A4"
				stroke-width="0.75"
				fill="rgb(164, 164, 164)"
			/>
		</SvgIcon>
	);
};

export default UploadIcon;
