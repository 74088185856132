import { useTheme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const ExclamationIcon = (props: SvgIconProps) => {
	const theme = useTheme();
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 -3.91654e-07 10 -8.74228e-07C4.48 -1.3568e-06 1.3568e-06 4.48 8.74228e-07 10C3.91654e-07 15.52 4.48 20 10 20ZM9 5L11 5L11 7L9 7L9 5ZM9 9L11 9L11 15L9 15L9 9Z"
				fill={theme.palette.primary.main}
			/>
		</svg>
	);
};

export default ExclamationIcon;
