import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LinksConst from "../Constants/LinksConst";
import RouterConstants from "../Constants/RouterConstants";
import { isMobile as isMobileBrowser } from "react-device-detect";
import { IsIframe } from "../Helpers/iframe";

function useMobileSize() {
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [isRedirecting, setisRedirecting] = useState<boolean>(false);
	const history = useHistory();
	const queryParams = new URLSearchParams(window ? window.location.search : {});
	const isFromCommercialSite = queryParams.get("fromCommercial");
	const { isIframe } = IsIframe();

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			if (isMobileBrowser && window.innerWidth < 768 && !isIframe()) {
				setIsMobile(true);
				if (isFromCommercialSite) {
					history.replace(RouterConstants.MOBILE_PAGE.path);
				} else {
					setisRedirecting(true);
					history.replace(RouterConstants.MOBILE_PAGE.path);
					window.open(LinksConst.BRIA_WEBSITE, "_self");
				}
			}
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return {
		isMobile,
		isRedirecting,
	};
}

export default useMobileSize;
