import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import ErrorIcon from "../../../assets/icons/ErrorIcon";
import Agreement from "../../../Components/Login/Agreement";
import BriaInput from "../../../Components/UI/BriaInput";
import Context from "../../../Context/Context";

interface IProps {}

const UserInfoForm: React.FC<IProps> = () => {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();
	const history = useHistory();
	const [getUpdated, setGetUpdated] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<boolean | string>(false);

	const initialValues = {
		username: "",
	};

	const handleSubmit = (values: any) => {
		context
			.updateUserInfo(values.username, undefined, undefined)
			.then(() => {
				context.setAuthDialogStep("AfterRegistrationContent");
				context.user!.updated = !context.user!.updated;
			})
			.catch((error) => {
				setErrorMessage(error.code);
			});
	};

	return (
		<Container component="main" maxWidth={false}>
			<Box className={classes.credentials_content}>
				<Box px={1.25} display="flex" flexDirection="column" alignItems="center">
					<Typography className={classes.typo1}>{t("welcomeToBria")}</Typography>

					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							username: Yup.string().required(t("fullNameIsRequired")),
						})}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isValid }) => (
							<Form className={classes.form} noValidate>
								<Box className={classes.formElements}>
									<BriaInput
										id="username"
										label={t("fullName")}
										onChange={handleChange}
										value={values.username}
										error={!!touched.username}
										helperText={errors.username}
										className={classes.textField}
										buttonSource={"popup"}
									/>

									{errorMessage && (
										<Typography className={classes.error} color="secondary">
											<ErrorIcon className={classes.errorIcon} />
											{t(`${errorMessage}`)}
										</Typography>
									)}
									<Box width="100%">
										<Agreement getUpdated={getUpdated} setGetUpdated={setGetUpdated} />
									</Box>
									<Button
										type="submit"
										variant="contained"
										color="secondary"
										className={classes.button}
									>
										{t("getStarted")}
									</Button>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme) => {
	return {
		form: {
			width: "100%",
			// height: 280,
			"& .MuiSelect-selectMenu": {
				marginTop: "15px",
			},
		},
		inputLabel: {
			color: "#495057",
		},
		formElements: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			rowGap: theme.spacing(3),
		},
		button: {
			borderRadius: 8,
			width: "100%",
			maxWidth: 440,
			height: 56,
			backgroundColor: theme.palette.primary.dark,
			"&:hover, &:active, &:focus, &:visited": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
		button1: {
			borderRadius: 20,
			// width: "100%",
			maxWidth: 440,
			height: 40,
			margin: 3,
		},
		icon: {
			fontSize: 20,
		},
		signin: {
			color: theme.palette.primary.light,
			textDecoration: "none",
		},
		typo: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			opacity: 0.5,
			marginTop: 26,
			marginBottom: 22,
		},
		typo1: {
			fontSize: 32,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(6),
			whiteSpace: "nowrap",
		},
		typo2: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
		},
		typo3: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightRegular,
			color: "#979797",
			display: "inline-block",
		},
		typo4: {
			fontWeight: theme.typography.fontWeightBold,
			color: "#979797",
			marginBottom: 53,
			fontSize: 14,
		},
		link: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
			textDecoration: "none",
			cursor: "pointer",

			"&:hover": {
				textDecoration: "none",
			},
		},
		textField: {
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				minWidth: "unset",
			},
		},
		dialogTitle: { padding: 0 },
		dialogContent: { padding: "0 40px 40px 40px" },
		title: {
			fontSize: 24,
			fontWeight: theme.typography.fontWeightBold,
		},
		close: {
			fontSize: 16,
			cursor: "pointer",
		},
		error: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightBold,
			maxWidth: 440,
			marginBottom: 15,
			marginLeft: 12,
		},
		errorIcon: {
			fontSize: 16,
			marginRight: 4,
			verticalAlign: "middle",
		},
		credentials_content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-evenly",
			// width: 600,
			// minWidth: 600,
			maxWidth: 600,
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			background: "white",
			padding: "20px 60px",
			boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				padding: 0,
			},
		},
	};
});

export default UserInfoForm;
