import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePromise } from "../../../../../hooks/usePromise";
import Analytics, { ANALYTICS_EVENTS } from "../../../../../Models/Analytics";
import { ImageEditorContext } from "../../../../../Pages/Tool/ImageEditor";
import { ApiActions } from "../../../../../sdk/resources/briaAPIConstants";
import { applyMoodChanges } from "../../../../../services/mood";
import SkeletonWrapper from "../../../../Common/SkeketonWrapper/SkeketonWrapper";
import { ToolsViewerMode } from "../../../utilities";
import { fromColorToHex } from "../../data/colors";
import { MoodContext } from "../../Mood";

interface ColorInfo {
	color: string;
	hue: string;
}
interface SuggestionParams {
	color: any;
	colorMixer: ColorInfo[];
	light: any;
}

export interface ISuggestion {
	moodId: string;
	moodParameters: any;
	imageRes: string;
	sid: string;
}
export default function Suggestion({
	imageRes,
	sid,
	moodId,
	moodParameters,
	...rest
}: ISuggestion) {
	const { visual_hash } = useParams<any>();
	const { setSelectedImageUrl, setSelectedColor, slidersGeneralState } =
		useContext(MoodContext);

	const classes = useStyles();
	const { directChangespdate } = useContext(MoodContext);

	const parseSelectedColor = (newParams: SuggestionParams) => {
		const { colorMixer = [] } = newParams;
		const [firstColorInfo] = colorMixer;
		const { color = "red" } = firstColorInfo || {};
		setSelectedColor(fromColorToHex(color));
	};

	const onImgClick = async () => {
		parseSelectedColor(moodParameters);
		directChangespdate(moodParameters, { imageRes, sid });
		await Analytics.logToolsEvent(
			ANALYTICS_EVENTS.BRAND_MOOD_THUMBNAIL_CLICK,
			{
				source: "",
				changeName: "Brand_mood_thumbnail_click",
				value: 0,
			}
		);
	};

	return (
		<Box py={3}>
			<Box className={classes.imgWrapper} onClick={onImgClick}>
				<img src={imageRes} alt={moodId} />
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		imgWrapper: {
			borderRadius: 5,
			overflow: "hidden",
			cursor: "pointer",
			"& img": {
				width: "100%",
			},
		},
	})
);
