import { colorMixerColors, fromHexToColor } from "./colors";
import { invertObj, isEmptyObj } from "./../../../../Helpers/mapper";
import { cleanObj } from "../../../../Helpers/string";
interface ISlider {
	title: string;
	minValue: number;
	maxValue: number;
	stepSize: number;
	sliderDefaultValue: number;
	value: number;
	name: string;
	mainKey?: string;
}

interface IinfoSrc {
	keys: string[];
	id: string;
	onChange?: (key: string, newValue: any, prevState: any) => void;
	resetAt?: string;
	constructDefaultState?: any;
}

const defaultStepInfo = {
	title: "",
	name: "",
	minValue: -100,
	maxValue: 100,
	stepSize: 1,
	sliderDefaultValue: 0,
	value: 0,
};

export const moodSlidersValueRange = {
	brightness: {
		minValue: -5,
		maxValue: 5,
	},
	contrast: {
		minValue: defaultStepInfo.minValue,
		maxValue: defaultStepInfo.maxValue,
	},
	saturation: {
		minValue: defaultStepInfo.minValue,
		maxValue: defaultStepInfo.maxValue,
	},
	vibrance: {
		minValue: defaultStepInfo.minValue,
		maxValue: defaultStepInfo.maxValue,
	},
	hue: {
		minValue: defaultStepInfo.minValue,
		maxValue: defaultStepInfo.maxValue,
	},
};

export const createStepInfo = (info?: any) => ({ ...defaultStepInfo, ...info });

export const moodSliders: ISlider[] = [
	{
		...createStepInfo({ name: "brightness" }),
		title: "Exposure",
		minValue: moodSlidersValueRange.brightness.minValue,
		maxValue: moodSlidersValueRange.brightness.maxValue,
	},
	{
		...createStepInfo({ name: "contrast" }),
		title: "Contrast",
	},
	{
		...createStepInfo({ name: "saturation" }),
		title: "Saturation",
		mainKey: "color",
	},
	{
		...createStepInfo({ name: "vibrance" }),
		title: "Vibrance",
	},
];

export const colorSettingsSliders = (state?: any) => [
	{
		...createStepInfo({ name: "hue" }),
		title: "Hue",
		trackGrdient: `linear-gradient(84deg, ${state.hueColorRange[0]} 0%, ${state.hueColorRange[1]} 50%, ${state.hueColorRange[2]} 100%);`,
	},
	// {
	// 	...createStepInfo({ name: "saturation" }),
	// 	title: "Saturation",
	// 	trackGrdient: `linear-gradient(84deg, #fff 0%, ${state.hueColorRange[1]} 100%);`,
	// },
	// {
	// 	...createStepInfo({ name: "luminance" }),
	// 	title: "Luminance",
	// 	trackGrdient: `linear-gradient(84deg, #000 0%, ${state.hueColorRange[1]} 50%, #fff 100%);`,
	// },
];

export const defaultSelectedColor = colorMixerColors[0];

const colorInfo = { keys: ["vibrance", "saturation"], id: "color" };
const lightInfo = { keys: ["brightness", "contrast"], id: "light" };
const colorMixerInfo = {
	keys: ["color", "hue", "luminance", "saturation"],
	id: "color_mixer",
	onChange: (subKey: string, newValue: any, prevState: any = []) => [
		{ ...prevState[0], [subKey]: newValue },
	],
	resetAt: "color",
	constructDefaultState: (newValue: string) => [{ color: newValue }],
};

export const mergedInfoSrc: IinfoSrc[] = [colorInfo, lightInfo, colorMixerInfo];

export const getStateUpdate = (
	key: string,
	value: any = 0,
	prevSrc: any = {},
	passedMainKey?: string // this prop is added to handle similar sliders keys from different mainKey
) => {
	const filterByMainKey = mergedInfoSrc.filter(
		(src) => src.id === passedMainKey || !passedMainKey
	);
	const foundSrc: IinfoSrc = filterByMainKey.find((elem) =>
		elem.keys.includes(key)
	) || {
		id: "",
		onChange: () => {},
		keys: [],
	};
	const { id: mainKey, onChange, resetAt, constructDefaultState } = foundSrc;

	const highLevelKey = passedMainKey || mainKey;

	const prevStateForSliderCategory = prevSrc?.[highLevelKey];
	const prevSliderValue =
		prevStateForSliderCategory?.[key] ||
		prevStateForSliderCategory?.[0]?.[key] ||
		0;

	if (prevSliderValue === value) throw new Error("No Change");

	const constructedNewValue = (foundSrc as any)?.onChange
		? onChange?.(key, value, prevStateForSliderCategory)
		: {
				...prevStateForSliderCategory,
				[key]: value,
		  };

	let createdNewInfo = {
		[highLevelKey]: constructedNewValue,
	};

	if (key === resetAt) {
		createdNewInfo = {
			[highLevelKey]: constructDefaultState(value),
		};
	}
	return createdNewInfo;
};

export const getSliderValue = (
	sliderName: string,
	slidersState = {},
	passedMainKey?: string
) => {
	const { id } = mergedInfoSrc.find((elem) =>
		elem.keys.includes(sliderName)
	) || { id: "" };
	const sliderParentState = (slidersState as any)?.[passedMainKey || id];
	const val = sliderParentState?.[sliderName];
	return val ?? 0;
};

export const apiMoodSchema = {
	colorMixer: "color_mixer",
};

export const localStateMoodSchema = {
	color_mixer: "colorMixer",
};

const deleteUnwantedKeys = (obj = {}, keys: string[]) => {
	let newObj = obj;
	keys.forEach((key) => {
		delete (newObj as any)?.[key];
	});
	return newObj;
};

// this mapper version keeps current object keys, only update keys found in schma
export const mapObjectBySchema = (obj: any = {}, schema: any) => {
	const newObjAccordingToSchema = Object.keys(schema).reduce(
		(prev, currentKey) => ({
			...prev,
			[currentKey]: obj?.[schema?.[currentKey]] || obj?.[currentKey],
		}),
		{}
	);
	const afterRemoveUnecessaryKeys = deleteUnwantedKeys(
		{ ...obj, ...newObjAccordingToSchema },
		["colorMixer"]
	);
	return afterRemoveUnecessaryKeys;
};

export const directStateUpdate = (newState: any) =>
	mapObjectBySchema(newState, invertObj(apiMoodSchema));

export const defaultSlidersState = {
	color: {
		vibrance: 0,
		saturation: 0,
	},
	light: {
		brightness: 0,
		contrast: 0,
	},
	color_mixer: [],
};

const defaultValueRef: any = {
	color: fromHexToColor(defaultSelectedColor),
};

export const createDefaultState = () => {
	const allKeys = [colorInfo, lightInfo, colorMixerInfo]
		.map((info) => info.keys)
		.flat();

	return allKeys.reduce(
		(pre, key) => ({
			...pre,
			...getStateUpdate(key, defaultValueRef?.[key], pre),
		}),
		{}
	);
};

export const removeZeroes = (sliderState = {}) => {
	const result = Object.entries(sliderState).reduce(
		(prev: any, [currKey, currValue]) => ({
			...prev,
			[currKey]: cleanObj(currValue),
		}),
		{}
	);
	return result as object;
};

export const isEmptySliderStateObj = (obj: any): boolean => {
	const zeroesRemoved = removeZeroes(obj);
	const haveValues = Object.values(zeroesRemoved).find((value: object) => {
		if (!Array.isArray(value)) return Object.keys(value).length;
		const isValidObj = !isEmptyObj(cleanObj(value?.[0]));
		return isValidObj; // handle for color Mixer case
	});
	return !haveValues;
};
(window as any).isEmptySliderStateObj = isEmptySliderStateObj;
