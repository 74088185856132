const CryptoJS = require("crypto-js");

class AESCipher {
	private bs: number;
	private key: Buffer;

	constructor(secret: string) {
		this.bs = 16; // AES block size in bytes
		this.key = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(secret));
	}

	encrypt(raw: string): string {
		const iv = CryptoJS.lib.WordArray.random(this.bs);
		const cipher = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this._pad(raw)), this.key, {
			iv: iv,
			padding: CryptoJS.pad.NoPadding,
			mode: CryptoJS.mode.CBC,
		});
		let finalCipher = iv.concat(cipher.ciphertext);
		return finalCipher.toString(CryptoJS.enc.Base64);
	}

	private _pad(s: string): string {
		let pad = this.bs - (s.length % this.bs);
		return s + String.fromCharCode(pad).repeat(pad);
	}

	private _unpad(s: string): string {
		const padChar = s.charCodeAt(s.length - 1);
		return s.slice(0, -padChar);
	}

	decrypt(enc: string): string {
		const encBytes = CryptoJS.enc.Base64.parse(enc);
		const iv = CryptoJS.lib.WordArray.create(encBytes.words.slice(0, 4), 16);
		const encryptedText = CryptoJS.lib.WordArray.create(encBytes.words.slice(4), encBytes.sigBytes - 16);

		const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedText }, this.key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.NoPadding,
		});

		const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
		return this._unpad(decryptedText);
	}
}

export { AESCipher };
