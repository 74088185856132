import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import clsx from "clsx";
import { Theme } from "@material-ui/core/styles";
import CustomizedTooltip from "../../UI/Tooltip";
import Icon from "../../Common/Icon/Icon";
import BriaButton, { BUTTONTYPES } from "../../UI/BriaButton";

export function getBase64(file: File) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

interface IFileUpload {
	isLogoTab?: boolean;
	renderFile?: (file: any) => JSX.Element;
	dragText: string;
	onDrop?: (fileInfo: any, file?: File) => void;
	file?: any;
	fileType?: string;
	keepPlaceholder?: boolean;
	hideFileOnDrop?: boolean;
	loading?: boolean;
}

export default function FileUpload({
	isLogoTab = false,
	renderFile,
	dragText,
	onDrop,
	file: passedFile,
	fileType = "PNG or JPG",
	keepPlaceholder,
	hideFileOnDrop,
	loading,
}: IFileUpload) {
	const [file, setFile] = useState<any>(passedFile);
	const classes = useStyles({ isLogoTab });

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			if (!acceptedFiles.length) return;
			const [firstFile] = acceptedFiles;
			const { name, type } = firstFile;
			const withUrl = {
				...firstFile,
				preview: URL.createObjectURL(firstFile),
				base64: await getBase64(firstFile),
				name,
				type,
			};
			onDrop?.(withUrl, firstFile);
			!hideFileOnDrop && setFile(withUrl);
		},
	});

	useEffect(() => {
		if (!passedFile) return;
		setFile(passedFile);
	}, [passedFile]);
	return (
		<Box
			{...getRootProps()}
			className={clsx(
				!isLogoTab && classes.dropzone,
				file && "dropZoneFull",
				isLogoTab && classes.customDropZone
			)}
		>
			<input {...getInputProps()} />
			<Box>
				{(!file || keepPlaceholder) && (
					<BriaButton
						buttonType={BUTTONTYPES.OUTLINED}
						className={clsx(classes.uploadButton)}
						onClick={async () => {}}
					>
						{loading ? <Box>Uploading ...</Box> : "Upload Logo"}
					</BriaButton>
				)}
				{file && renderFile && (
					<Box
						style={{
							display: "inline-block",
							position: "relative",
							width: 160,
							height: 160,
						}}
					>
						<CustomizedTooltip title={<>Replace</>} placement="top">
							<Box className={classes.editIcon}>
								<Icon name="edit-icon" />
							</Box>
						</CustomizedTooltip>
						{renderFile(file)}
					</Box>
				)}
			</Box>
		</Box>
	);
}

interface StyleProps {
	isLogoTab: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	dropzone: {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		marginTop: (props) => (props.isLogoTab ? 0 : "2.5rem"),
		outlineOffset: "14px",
		flexDirection: "column",
		gap: "1rem",
		aspectRatio: "3/1",
		height: "24vh",
		"&:not(.dropZoneFull)": {
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DDDDDDFF' stroke-width='3' stroke-dasharray='4%2c 11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
		},
	},
	orUploadTextWrapper: {
		display: "flex",
		gap: 3,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "0.5rem",
	},
	uploadButton: {
		width: 160,
	},
	dropzoneText: {
		fontSize: "12px",
		color: "#A4A4A4",
		textAlign: "center",
		"&.small": {
			fontSize: 10,
		},
		"&.bold": {
			fontWeight: 600,
			marginBottom: "1.3rem",
		},
		"&.upload": {
			color: "#D80067",
		},
	},
	bold: {
		fontWeight: "bold",
	},
	logoImg: {
		width: "95%",
		height: "95%",
		objectFit: "cover",
	},
	bottomText: {
		position: "relative",
		bottom: "-23%",
	},
	customBottomText: {
		bottom: 0,
	},
	editIcon: {
		position: "absolute",
		top: "4%",
		right: "4%",
	},
}));
