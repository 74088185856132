import { gql } from "@apollo/client";

export type CampaignAsset = {
	mainAsset: string;
	logo: string;
	cta: string;
};

export type HeaderSettings = {
	color: string;
	font: string;
	size: string;
};

export interface Header extends HeaderSettings {
	text: string;
}

export type Camp = {
	header1: Header;
	header2: Header;
	placementsIds: string[];
	channelIds: string[];
	mainAsset: { filename: string; base_64_string: string };
	sid?: string;
	logo: { filename: string; base_64_string: string } | string;
	cta: { filename: string; base_64_string: string };
	colors: string[];
	seed?: number;
	backgroundType: string;
	bgColor: string;
	backGroundDescription: string;
	bgPrompt: string;
	productVisualHash: string;
	templateId: string;
	bgSeed: string;
};

export const defaultCampaign: Camp = {
	header1: {
		color: "",
		font: "",
		size: "",
		text: "",
	},
	header2: {
		color: "",
		font: "",
		size: "",
		text: "",
	},
	placementsIds: [],
	channelIds: [],
	mainAsset: { filename: "", base_64_string: "" },
	sid: undefined,
	logo: { filename: "", base_64_string: "" },
	cta: { filename: "", base_64_string: "" },
	colors: ["", "", "", "", ""],
	seed: NaN,
	backgroundType: "",
	bgColor: "",
	backGroundDescription: "",
	bgPrompt: "",
	productVisualHash: "",
	templateId: "",
	bgSeed: "",
};

export function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export async function getImageInfo(image: any, campaignName: string, index: any) {
	const response = await fetch(image.src);
	const blob = await response.blob();

	const base64 = (await getBase64(blob as File)) as string;
	const fileExtension = getFileExtension(blob);
	const filename = `${image.name}_${index + 1}.${fileExtension}`;

	return { filename, base64 };
}

export async function getPsdInfo(psdUrl: string, index: number, campaignName: string) {
	const response = await fetch(psdUrl);
	const blob = await response.blob();

	const base64 = (await getBase64(blob as File)) as string;
	const campaignNameLowercase = campaignName.toLowerCase().replace(/\s/g, "_");
	const filename = `${campaignNameLowercase}_${index}.psd`;
	return { filename, base64 };
}

export async function getImageResolution(imageURL: string): Promise<{ width: number; height: number }> {
	const image = new Image();
	image.src = imageURL;

	return new Promise((resolve) => {
		image.onload = () => {
			const resolution = { width: image.width, height: image.height };
			resolve(resolution);
		};
	});
}

function getFileExtension(blob: Blob): string {
	const parts = blob.type.split("/");
	return parts[parts.length - 1];
}

export const isS3Uri = (uri: string) => {
	return uri.startsWith("s3://");
};

export const convertS3UriToHttpsUrl = (s3Uri: string) => {
	const parts = s3Uri.split("/");
	const bucketName = parts[2];
	const filePath = parts.slice(3).join("/");
	const region = "us-east-1";

	return `https://${bucketName}.s3.${region}.amazonaws.com/${filePath}`;
};
