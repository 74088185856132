export interface ToolsStructure {
	input1: string;
	level1DisplayName?: string;
	level1Id: string;
	level1Location: number;
	level2DisplayName: string;
	level2Id: string;
	level2Location: number;
	soon: boolean;
	icon?: any;
	new?: boolean;
}

export interface ToolsSlider {
	value: number;
	defaultInitialValue: number;
	displayName: string;
	id: string;
	name: string;
	level1Id: string;
	level2Id: string;
	maxLayer: number;
	maxName: string;
	maxValue: number;
	minLayer: number;
	minName: string;
	minValue: number;
	normallizedValue: number;
	order: number;
	pca: number;
	reversed: number;
	hideSlider: boolean;
	validateFromDevInfo: boolean;
	validation: ToolsValidatoin;
	minNameRiskArea: boolean;
	maxNameRiskArea: boolean;
	disabled?: boolean;
	warning?: boolean;
	error?: boolean;
}

export interface ToolsValidatoin {
	gender?: string;
}

export interface ToolsThumbnails {
	key: string;
	title: string;
	level1_id: string;
	level2_id: string;
	thumbnail_index?: number;
	thumbnails_number?: number;
	sliders: ToolsSlider[];
	validation: ToolsValidatoin;
}

export interface ToolsSuggestions {
	id: number;
	thumbnails: ToolsSlider[];
}

export interface UncropValues {
	canvas_size: [number, number];
	original_image_size: [number, number];
	original_image_location: [number, number];
}

export interface ThumbnailImage {
	imageUrl: string;
	key: string;
	seed?: number;
	sid: string | null;
}

export interface ThumbnailSlider {
	key: string;
	value: number;
}

export enum PagesEnum {
	Gallery = "gallery",
	Playground = "playground",
	Assets = "assets",
	Campaign = "campaign",
}

export enum LanguagesEnum {
	English = "english",
	Japanese = "japanese",
}

export const LanguageCodesEnum = {
	[LanguagesEnum.English]: "en",
	[LanguagesEnum.Japanese]: "ja",
};

export enum ToolConfigTabsTypes {
	Personalizer = "personalizer",
	Style = "style_tab",
	ImageStyle = "image_style",
	createCampaign = "campaign_create",
	Suggestions = "suggestion",
	Appearance = "appearance",
	Diversity = "diversity",
	Localizer = "localizer",
	Emotions = "emotions",
	Expression = "expression",
	Accessories = "accessories",
	Objects = "remove_object",
	CameraMovement = "camera_movement_video",
	FashionMen = "fashion_men",
	Mood = "mood",
	FineTuning = "fine_tuning",
	Logo = "logo",
	Face = "face",
	Blur = "blur",
	Replace = "replace",
	Paint = "paint",
	Remove = "remove",
	UPPER_CLOTHES = "upper_clothes",
	TextEditor = "text_editor",
	background = "background",
	crop = "crop",
	MediaSizesCrop = "Media Sizes",
	CustomCrop = "Custom",
	PsdDownload = "psd_download",
	SuperResolution = "super_resolution",
	AutoObjIdentification = "auto_obj_identification",
	ManualObjBrush = "manual_obj_brush",
	ExpandBackground = "expand_background",
}
