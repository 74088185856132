enum SelectedPage {
	IMAGEREPOSITORY = "VDR",
	HOME = "Home",
	BRIATOR_V2 = "Tool",
	INSTANCE_MANAGEMENT = "Instance Management",
	DATASET = "Datasets",
	RESEARCH = "Research",
	USERMAMAGEMENT = "User Management",
	ORGANIZATIONS = "Organizations",
	// SETTINGS = "Settings",
	APIBILLINGDASHBOARD = "API Billing Dashboard",
	IFRAME = "Iframe",
}

export default SelectedPage;
