import React, { useEffect } from "react";
import { Box, Divider, FormControl, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { convertS3UriToHttpsUrl } from "../../UI/CreateCampaign/utils";
import CircularLoadingTooltip from "../../UI/LoadingTooltip";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Theme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Icon from "../Icon/Icon";

export interface IGroupOption {
	id: string;
	value?: string;
	label: string;
	description?: string;
	extraData?: any;
	icon?: any;
}

export interface IGroup {
	name: string;
	options: IGroupOption[];
}

interface IProps {
	selectedValue: any;
	handleChange: any;
	maxOptionLabelLength?: number;
	groups: IGroup[];
	className?: string;
	selectRootClass?: string;
	manuItemRootClass?: string;
	dividerRootClass?: string;
	placeholder: string;
	selectStyle?: string;
	menuPaperStyle?: any;
	menuMaxHeight: string;
	selectedValueName?: string | null;
	loading?: boolean;
	fontOptions?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	sortAlphabetically?: boolean;
	selectOption?: string;
	noOptions?: string;
	selectIconName?: string;
}

interface IStyleProps {
	disabled?: boolean;
}

const CustomSingleSelect = ({
	selectedValue,
	disabled,
	maxOptionLabelLength,
	handleChange,
	groups,
	className,
	selectRootClass,
	manuItemRootClass,
	dividerRootClass,
	placeholder,
	selectStyle,
	menuMaxHeight,
	menuPaperStyle = {},
	selectedValueName,
	sortAlphabetically = true,
	loading = false,
	fontOptions = false,
	hasError,
	selectOption,
	noOptions,
	selectIconName,
}: IProps) => {
	const { t } = useTranslation();
	const classes = useStyles({ disabled });
	const sortOptionsAlphabetically = (options: any) => {
		return options.sort((a: any, b: any) => a.label.localeCompare(b.label));
	};
	const loadFont = (fontSrc: any) => {
		const style = document.createElement("style");
		const httpUrl = convertS3UriToHttpsUrl(fontSrc.src);
		style.appendChild(
			document.createTextNode(`
		  @font-face {
			font-family: "${fontSrc.value}";
			src: url("${httpUrl}") format("truetype");
		  }
		`)
		);
		document.head.appendChild(style);
	};

	useEffect(() => {
		// Load fonts when component mounts
		if (fontOptions) {
			groups.forEach((group: any) => {
				group.options.forEach((option: any) => {
					loadFont(option);
				});
			});
		}
	}, [groups]);

	const getValueText = (text: string | undefined | null) => {
		if (!text) return text;
		return maxOptionLabelLength && text.length > maxOptionLabelLength
			? text.substring(0, maxOptionLabelLength) + "..."
			: text;
	};

	return (
		<FormControl variant="outlined" className={clsx(className, classes.formControl)} disabled={disabled}>
			<Select
				disabled={disabled}
				renderValue={() =>
					noOptions ? (
						groups.length > 0 || loading ? (
							<Typography className={classes.placeHolder}>
								{getValueText(selectedValueName) || placeholder}
							</Typography>
						) : (
							<Typography className={classes.placeHolder}>{noOptions}</Typography>
						)
					) : (
						<Typography className={classes.placeHolder}>
							{getValueText(selectedValueName) || placeholder}
						</Typography>
					)
				}
				value={getValueText(selectedValue)}
				onChange={handleChange}
				displayEmpty
				classes={{ root: selectRootClass, select: `${selectStyle} ${hasError ? classes.errorBorder : ""}` }}
				IconComponent={(props) =>
					loading ? (
						<Box className={classes.loader}>
							<CircularLoadingTooltip key="" tab="" />
						</Box>
					) : selectIconName ? (
						<Icon color="#1A0638" name={selectIconName} {...props} style={{ top: "auto" }} />
					) : (
						<KeyboardArrowDownIcon {...props} style={{ color: "#1A0638" }} />
					)
				}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
					PaperProps: {
						style: {
							backgroundColor: "white",
							maxHeight: menuMaxHeight,
							overflowY: "auto",
							...menuPaperStyle,
						},
					},
				}}
			>
				{selectOption && (groups.length > 0 || loading) && (
					<MenuItem classes={{ root: manuItemRootClass }} className={classes.menuItem} value="">
						<Typography className={classes.optionsLable}>{selectOption} </Typography>
					</MenuItem>
				)}
				{groups.map((group: any, groupIndex: any) => [
					<MenuItem
						className={`${classes.disabledOptions}`}
						key={`group_${groupIndex}`}
						value={t(group.name)}
						disabled
					>
						{t(group.name)}
					</MenuItem>,
					...(sortAlphabetically ? sortOptionsAlphabetically(group.options) : group.options).map(
						(option: any, optionIndex: any) => (
							<MenuItem
								classes={{ root: manuItemRootClass }}
								className={clsx(classes.menuItem, classes.hoverEffect)}
								style={{ fontFamily: fontOptions && option.value }}
								key={option.id}
								value={option.id}
							>
								{fontOptions ? (
									option.label
								) : (
									<Box className={classes.optionContainer}>
										<Box className={`${option.icon && classes.iconLabelContiner}`}>
											{option.icon && <img src={option.icon} alt="" />}
											<Typography
												className={clsx(classes.optionsLable, {
													[classes.bold]: option.description !== undefined,
												})}
											>
												{option.label}
											</Typography>
										</Box>
										{option.description && (
											<Typography className={classes.optionsDescription}>
												{option.description}
											</Typography>
										)}
									</Box>
								)}
							</MenuItem>
						)
					),
					groupIndex === 0 && groups.length > 1 && (
						<Divider
							key={`divider_${groupIndex}`}
							className={classes.divider}
							classes={{ root: dividerRootClass }}
						/>
					),
				])}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => ({
	placeHolder: {
		color: "#5B5B5B",
		fontSize: "14px",
	},
	formControl: (props) => ({
		opacity: props.disabled ? 0.38 : 1,
	}),
	divider: {
		backgroundColor: "#E7E7E7",
		marginTop: "10px",
	},
	disabledOptions: {
		color: " rgba(91, 91, 91, 0.50)",
		paddingTop: "16px",
		paddingBottom: "8px",
		fontSize: "10px",
		fontWeight: 700,
		opacity: "1 !important",
		lineHeight: "normal",
	},
	hoverEffect: {
		"&:hover, &:active, &.Mui-selected ": {
			backgroundColor: "#5300C90D !important",
		},
	},
	loader: {
		position: "relative",
		right: "15px",
	},
	errorBorder: {
		border: "1px solid red",
		borderRadius: "10px !important",
	},
	optionsLable: {
		fontWeight: 500,
		fontSize: "14px",
		color: "#iA0638",
		lineHeight: "16px",
		textWrap: "wrap",
	},
	optionsDescription: {
		fontWeight: 500,
		fontSize: "14px",
		color: "#5B5B5B",
		textWrap: "wrap",
		marginTop: 6,
	},
	optionContainer: {},
	bold: {
		fontWeight: 600,
	},
	menuItem: {
		padding: "10px 12px",
	},
	iconLabelContiner: {
		display: "flex",
		alignItems: "center",
		gap: "4px",
	},
}));
export default CustomSingleSelect;
