import React from "react";

function ObjectsIcon({ color }: any) {
	return (
		<svg
			width="16"
			height="19"
			viewBox="0 0 16 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 14.5C15 12.843 11.866 11.5 8 11.5C4.134 11.5 1 12.843 1 14.5M15 14.5C15 16.157 11.866 17.5 8 17.5C4.134 17.5 1 16.157 1 14.5M15 14.5V14L8 1.5L1 14V14.5"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default ObjectsIcon;
