import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { ToolsViewerMode } from "../Tools/utilities";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as GridIcon } from "../../assets/svgs/grid-view-icon.svg";
import { ReactComponent as ListIcon } from "../../assets/svgs/list-view-icon.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
		paddingRight: 20,
		marginBottom: 20,
		marginTop: -8,
	},
	toggleButton: {
		stroke: "#A4A4A4",
		// border: "1px solid #A4A4A4",
		// borderRadius: 4,
		textTransform: "unset",
		padding: "0px !important",
		fontWeight: "bold",
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		width: 30,
		height: 30,
		cursor: "pointer",
		boxSizing: "content-box",
		border: "2px solid transparent !important",
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#E7E7E7 !important",
		},
	},
	grid: {
		padding: "0px !important",
		"&:hover": {
			background: "#E8E8E8",
		},
	},
	list: {
		padding: "0px !important",
		marginRight: 8,
		"&:hover": {
			background: "#A4A4A4",
		},
	},
	buttonTitle: {
		marginLeft: theme.spacing(0.5),
		fontSize: 14,
	},
	selected: {
		backgroundColor: "rgba(83, 0, 201, 0.05) !important",
		border: " 2px solid rgba(83, 0, 201, 0.05) !important",
		boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02) !important",
	},
}));

interface IProps {
	defaultStyle: ToolsViewerMode;
	onStyleChange: (style: ToolsViewerMode) => void;
	disabled?: boolean;
}

const ThumbnailSliderSwitch: React.FC<IProps> = ({
	defaultStyle,
	onStyleChange,
}) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const [selectedStyle, setSelectedStyle] =
		React.useState<ToolsViewerMode>(defaultStyle);

	useEffect(() => {
		onStyleChange(defaultStyle);
	}, [defaultStyle]);

	const handleStyle = (newStyle: ToolsViewerMode) => {
		if (newStyle !== null) {
			onStyleChange(newStyle);
			setSelectedStyle(newStyle);
		}
	};

	return (
		<Box className={classes.root}>
			<Box
				className={clsx({
					[classes.list]: true,
					[classes.toggleButton]: true,
					[classes.selected]:
						selectedStyle === ToolsViewerMode.sliders,
				})}
				onClick={() => {
					handleStyle(ToolsViewerMode.sliders);
				}}
			>
				<ListIcon />
			</Box>
			<Box
				className={clsx({
					[classes.grid]: true,
					[classes.toggleButton]: true,
					[classes.selected]:
						selectedStyle === ToolsViewerMode.thumbnails,
				})}
				onClick={() => {
					handleStyle(ToolsViewerMode.thumbnails);
				}}
			>
				<GridIcon />
			</Box>
		</Box>
	);
};

export default ThumbnailSliderSwitch;
