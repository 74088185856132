import {
	SubscriptionTypes,
	SUBSCRIPTION_TYPES_ORDER,
} from "../Constants/SubscriptionConstants";

class Subscription {
	web_subscription_type: SubscriptionTypes;
	web_subscription_period: string;
	people_customizations_credits: number;
	download_psd_credits: number;
	download_credits: number;
	object_customizations_credits: number;
	videos_credits: number;
	bs_web_shopper_id: string;

	constructor(
		web_subscription_type: SubscriptionTypes,
		web_subscription_period: string,
		people_customizations_credits: number,
		download_psd_credits: number,
		download_credits: number,
		object_customizations_credits: number,
		videos_credits: number,
		bs_web_shopper_id: string
	) {
		this.web_subscription_type = web_subscription_type;
		this.web_subscription_period = web_subscription_period;
		this.people_customizations_credits = people_customizations_credits;
		this.download_psd_credits = download_psd_credits;
		this.download_credits = download_credits;
		this.object_customizations_credits = object_customizations_credits;
		this.videos_credits = videos_credits;
		this.bs_web_shopper_id = bs_web_shopper_id;
	}

	getNextRecommendedSubscription() {
		return SUBSCRIPTION_TYPES_ORDER[
			SUBSCRIPTION_TYPES_ORDER.indexOf(this.web_subscription_type) + 1
		];
	}
}

export default Subscription;
