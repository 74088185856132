import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

export default function ToggleViewBtn({
	onClick = () => {},
}: {
	onClick?: () => void;
}) {
	const classes = useStyles();
	return (
		<>
			<Box onClick={onClick} className={classes.focusViewBtn}>
				<Box className={classes.focusViewBtnInner}></Box>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	focusViewBtn: {
		width: 20,
		aspectRatio: "1/1",
		padding: 2,
		border: `1px solid ${theme.palette.common.black}`,
		borderRadius: 3,
	},
	focusViewBtnInner: {
		backgroundColor: `${theme.palette.common.black}`,
		width: "100%",
		height: "100%",
		borderRadius: 3,
	},
}));
