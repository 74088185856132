export const proportions = [
	{
		aspectRatioHeight: 9,
		aspectRatioWidth: 16,
		h: 674,
		index: 0,
		name: "Cover",
		resize: { w: 86, h: 48 },
		w: 1200,
	},
	{
		aspectRatioHeight: 16,
		aspectRatioWidth: 9,
		h: 1920,
		index: 1,
		name: "Cover",
		resize: { w: 33.16, h: 48 },
		w: 1080,
	},
	{ aspectRatioHeight: 1, aspectRatioWidth: 1, h: 400, index: 2, name: "Profile", resize: { w: 48, h: 48 }, w: 400 },

	{
		aspectRatioHeight: 3,
		aspectRatioWidth: 2,
		h: 640,
		index: 3,
		name: "Cover",
		resize: { w: 32, h: 48 },
		w: 480,
	},
	{
		aspectRatioHeight: 2,
		aspectRatioWidth: 3,
		h: 640,
		index: 4,
		name: "Cover",
		resize: { w: 72, h: 48 },
		w: 480,
	},
	{
		aspectRatioHeight: 4,
		aspectRatioWidth: 3,
		h: 640,
		index: 5,
		name: "Cover",
		resize: { w: 36, h: 48 },
		w: 480,
	},
	{
		aspectRatioHeight: 3,
		aspectRatioWidth: 4,
		h: 640,
		index: 6,
		name: "Cover",
		resize: { w: 64, h: 48 },
		w: 480,
	},
];
