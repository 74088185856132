import { InputBase, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
	allOrganizations,
	allOrganizations_allOrganizations,
	allOrganizationsVariables,
} from "../GraphQL/types/allOrganizations";
import client from "../GraphQL/client";
import { GET_ALL_ORGANIZATIONS } from "../GraphQL/queries";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Context from "../Context/Context";
import debounce from "lodash.debounce";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";

interface StyleProps {
	loading: boolean;
}

interface IProps {
	loading?: boolean;
	onChange?: (org: allOrganizations_allOrganizations) => void;
	selectedOrganization?: allOrganizations_allOrganizations;
	allOrganizations?: allOrganizations_allOrganizations[];
}

export default function OrganizationsDropdown({
	onChange,
	selectedOrganization: passedSelectedOrg,
	allOrganizations,
	loading: passedLoading,
}: IProps) {
	const context = useContext(Context);
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(passedLoading ?? context.isOrganizationsLoading);
	const [selectedOrg, setSelectedOrg] = useState<allOrganizations_allOrganizations | undefined>(
		passedSelectedOrg ?? context.selectedOrganization
	);
	const controller = useRef<any>();
	const [filteredOrgs, setFilteredOrgs] = useState<allOrganizations_allOrganizations[]>(
		allOrganizations ?? context.allOrganizations
	);
	const classes = useStyles({ loading });

	const onSearchChange = (searchText: string) => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		if (searchText && searchText.length > 0) {
			setLoading(true);
			client
				.query<allOrganizations, allOrganizationsVariables>({
					query: GET_ALL_ORGANIZATIONS,
					variables: {
						query: searchText,
					},
					context: {
						fetchOptions: {
							signal: controller.current.signal,
						},
					},
				})
				.then(function (response) {
					setFilteredOrgs(response.data.allOrganizations ?? []);
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setFilteredOrgs(allOrganizations ?? context.allOrganizations);
		}
	};

	const onSearchChangeDebounced = useMemo(
		() =>
			debounce((text: string) => {
				onSearchChange(text);
			}, 500),
		[onSearchChange]
	);

	const handleOrgClick = (org: allOrganizations_allOrganizations) => {
		if (onChange) {
			onChange(org);
		} else {
			context.setSelectedOrganization(org);
			window.location.reload();
		}
	};

	const OrgComponent = ({ org }: { org: allOrganizations_allOrganizations }) => {
		return (
			<Box className={clsx(classes.orgContainer, { selected: selectedOrg?.uid === org.uid })}>
				<Typography
					onClick={() => {
						handleOrgClick(org);
					}}
				>
					{org.name}
				</Typography>
			</Box>
		);
	};

	return (
		<Box className={clsx(classes.container)}>
			{context.user?.isAdmin() && (
				<InputBase
					placeholder={t("search")}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					inputProps={{ "aria-label": "search" }}
					onChange={(e: any) => {
						onSearchChangeDebounced(e.target.value);
					}}
					startAdornment={<SearchIcon className={classes.searchIcon} />}
				/>
			)}
			<Box className={clsx(classes.orgsContainer)}>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						{selectedOrg && !filteredOrgs.find((org) => org.uid === selectedOrg.uid) && (
							<OrgComponent org={selectedOrg} />
						)}
						{filteredOrgs.map((org) => (
							<OrgComponent org={org} />
						))}
					</>
				)}
			</Box>
		</Box>
	);
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	container: {
		margin: "20px 0 20px 0",
	},
	inputRoot: {
		border: "1px #B3B3B9 solid",
		margin: "0 24px 20px 24px",
		padding: "8px 6px",
		borderRadius: 8,
	},
	inputInput: {
		position: "relative",
		padding: 0,
		fontSize: 14,
	},
	searchIcon: {
		marginRight: 2,
		width: "17px",
		height: "17px",
	},
	orgContainer: {
		marginBottom: 6,
		padding: "6px 24px",
		cursor: "pointer",
		"&.selected, &:hover": {
			backgroundColor: `${theme.palette.primary.dark}0D`,
		},
		"& p": {
			fontSize: 14,
			textTransform: "capitalize",
		},
	},
	orgsContainer: (props) => ({
		textAlign: props.loading ? "center" : "inherit",
	}),
}));
