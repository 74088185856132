import { Divider, Link } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import LinksConst from "../Constants/LinksConst";
import RouterConstants from "../Constants/RouterConstants";
import { ApiSubscriptionTypes } from "../Constants/SubscriptionConstants";
import Context from "../Context/Context";
import { IsGuestUser } from "../Helpers/isGuest";
import { SystemViewTypes } from "../Models/SystemView";
import AccountSettings from "./AccountSettings";
import Settings from "./Settings";
import Menu from "./UI/Menu";
import { IsIframe } from "../Helpers/iframe";
import OrganizationsDropdown from "./OrganizationsDropdown";
import ShowAt from "./Common/Wrappers/ShowAt";
import NestedMenuItem from "./UI/NestedMenuItem";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
		},
		textSize: {
			fontSize: 14,
		},
		menuHeader: {
			color: theme.palette.primary.main,
			fontWeight: 600,
			fontSize: 16,
		},
		menuItem: {
			paddingLeft: 24,
			paddingRight: 24,
			color: theme.palette.text.primary,
			"&:hover": {
				backgroundColor: "#4950570D",
			},
			"&:active": {
				backgroundColor: "#4950570D",
				opacity: 1,
				color: theme.palette.common.white,
			},
			"&.Mui-disabled": {
				opacity: 1,
			},
		},
		backDrop: {
			backdropFilter: "blur(3px)",
			backgroundColor: "rgba(0,0,30,0.4)",
			filter: "blur(3px)",
		},
		active: {
			backgroundColor: "#5300C90D",
		},
	})
);

interface IProps {
	pipelineSettings?: any[]; //ImageEditorData_metaData_settings[];
	handleChangePipelineSettings?: (index: number, isOn: boolean, value: any) => void;
	showSettings?: boolean;
	avatarStyle?: any;
}

const UserMenu: React.FC<IProps> = ({ pipelineSettings, handleChangePipelineSettings, showSettings, avatarStyle }) => {
	const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
	const classes = useStyles();
	const { t } = useTranslation();
	const context = useContext(Context);
	const history = useHistory();
	const { isGuestUser } = IsGuestUser();
	const selectedOrganization = context.selectedOrganization;
	const currentPlan = selectedOrganization?.apiSubscription?.apiSubscriptionType as ApiSubscriptionTypes;
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const { isIframe } = IsIframe();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const fromPlatformHub = searchParams.get("from_platform_hub") == "true";
	useEffect(() => {
		if (!isGuestUser()) {
			switch (context.authDialogStep) {
				case "Login":
				case "RegisterInfo":
					if (context.authDialogSource === "User Menu") {
						context.setAuthDialogSource("");
						context.setAuthDialogState(false);
					}
			}
		}
	}, [context.user, context.user?.updated]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseSettings = () => {
		setSettingsOpen(false);
	};

	const handleSystemViewChange = (value: SystemViewTypes) => {
		context?.setSystemView(value);
	};

	return (
		<Box>
			<Button
				onClick={(event) => {
					if (context.user) {
						if (isGuestUser()) {
							context.setAuthDialogStep("Login");
							context.setAuthDialogState(true);
						} else {
							handleClick(event);
						}
					}
				}}
				aria-label="account of current user"
			>
				<Avatar src={context.user?.profilePicture} style={{ ...avatarStyle }} />
			</Button>

			{!isGuestUser() && (
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					getContentAnchorEl={null}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<MenuItem className={classes.menuItem} disabled>
						<Typography
							variant="inherit"
							style={{ paddingTop: 18 }}
							className={clsx(classes.textSize, classes.menuHeader)}
						>
							{t("Profile")}
						</Typography>
					</MenuItem>
					<ShowAt showAlways condition={context.allOrganizations && context.allOrganizations.length > 1}>
						<NestedMenuItem
							left
							MenuItemProps={{ className: clsx(classes.menuItem, classes.textSize) }}
							expandIcon={<ArrowRightIcon />}
							highlightColor={""}
							label={
								<Box style={{ textTransform: "capitalize" }}>
									{selectedOrganization?.name ?? t("selectOrganization")}
								</Box>
							}
							mainMenuOpen={Boolean(anchorEl)}
						>
							<OrganizationsDropdown />
						</NestedMenuItem>
					</ShowAt>
					{/*{!isGuestUser() && (*/}
					{/*	<MenuItem*/}
					{/*		className={clsx(classes.menuItem, {*/}
					{/*			[classes.active]: window.location.pathname === RouterConstants.ACCOUNT_MANAGEMENT.path,*/}
					{/*		})}*/}
					{/*		onClick={() => {*/}
					{/*			history.push(`${RouterConstants.ACCOUNT_MANAGEMENT.path}`);*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Typography variant="inherit" className={classes.textSize}>*/}
					{/*			{t("Account")}*/}
					{/*		</Typography>*/}
					{/*	</MenuItem>*/}
					{/*)}*/}

					{/*{!isGuestUser() && isApiSubscriptionActive(context) && (*/}
					{/*	<MenuItem*/}
					{/*		className={clsx(classes.menuItem, {*/}
					{/*			[classes.active]:*/}
					{/*				window.location.pathname === RouterConstants.ORGANIZATION_MANAGEMENT.path,*/}
					{/*		})}*/}
					{/*		onClick={() => {*/}
					{/*			history.push(`${RouterConstants.ORGANIZATION_MANAGEMENT.path}`);*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Typography variant="inherit" className={classes.textSize}>*/}
					{/*			{t("organization")}*/}
					{/*		</Typography>*/}
					{/*	</MenuItem>*/}
					{/*)}*/}
					{!fromPlatformHub && (
						<MenuItem
							className={clsx(classes.menuItem, {
								[classes.active]: window.location.pathname === RouterConstants.ASSETS_PAGE.path,
							})}
							onClick={() => {
								history.push(`${RouterConstants.ASSETS_PAGE.path}`);
							}}
						>
							<Typography variant="inherit" className={classes.textSize}>
								My Assets
							</Typography>
						</MenuItem>
					)}
					{/*{!isIframe() && (*/}
					{/*	<MenuItem*/}
					{/*		className={clsx(classes.menuItem, {*/}
					{/*			[classes.active]: window.location.pathname === RouterConstants.WEB_PLANS.path,*/}
					{/*		})}*/}
					{/*		onClick={async () => {*/}
					{/*			await Analytics.logEvent(ANALYTICS_EVENTS.HOME_HEADER_PRICING);*/}
					{/*			history.push(RouterConstants.WEB_PLANS.path);*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Typography variant="inherit" className={classes.textSize}>*/}
					{/*			Pricing*/}
					{/*		</Typography>*/}
					{/*	</MenuItem>*/}
					{/*)}*/}

					{isGuestUser() && (
						<MenuItem
							className={classes.menuItem}
							onClick={() => {
								context.setAuthDialogSource("User Menu");
								context.setAuthDialogState(true);
							}}
						>
							<Typography variant="inherit" className={classes.textSize}>
								Login
							</Typography>
						</MenuItem>
					)}
					{!isGuestUser() && (
						<MenuItem
							className={classes.menuItem}
							onClick={() => {
								context.logOut();
							}}
						>
							<Typography variant="inherit" className={classes.textSize}>
								{t("logout")}
							</Typography>
						</MenuItem>
					)}
					{showSettings && context?.user?.isAdmin() && (
						<MenuItem className={classes.menuItem} onClick={() => setOpenSettingsDialog(true)}>
							<Typography variant="inherit" className={classes.textSize}>
								{t("Settings")}
							</Typography>
						</MenuItem>
					)}

					<Settings
						pipelineSettings={pipelineSettings}
						openSettingsDialog={openSettingsDialog}
						setOpenSettingsDialog={setOpenSettingsDialog}
						handleChangePipelineSettings={handleChangePipelineSettings}
					/>

					<Box>
						<Divider
							style={{
								width: "80%",
								margin: "auto",
								marginTop: 10,
								marginBottom: 10,
							}}
						/>

						<MenuItem className={classes.menuItem} disabled>
							<Typography
								color="textSecondary"
								variant="inherit"
								className={clsx(classes.textSize, classes.menuHeader)}
							>
								<Box component="span" fontWeight="fontWeightBold">
									{t("developerVersion")}
								</Box>
							</Typography>
						</MenuItem>
						{context?.user?.isAdmin() && (
							<MenuItem className={classes.menuItem}>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("sdk")}
								</Typography>
							</MenuItem>
						)}

						<MenuItem
							component={Link}
							href={LinksConst.BRIA_API}
							target="_blank"
							className={classes.menuItem}
						>
							<Typography variant="inherit" color="textPrimary" className={clsx(classes.textSize)}>
								{t("api")}
							</Typography>
						</MenuItem>
						<MenuItem
							className={classes.menuItem}
							component={Link}
							onClick={() => {
								history.push(RouterConstants.API_PLANS.path);
							}}
						>
							<Typography variant="inherit" color="textPrimary" className={clsx(classes.textSize)}>
								{currentPlan ? t("upgradeAPISubscription") : t("getApiAccess")}
							</Typography>
						</MenuItem>
					</Box>

					{context?.user?.isAdmin() && (
						<Box>
							<Divider
								style={{
									width: "80%",
									margin: "auto",
									marginTop: 10,
									marginBottom: 10,
								}}
							/>
							<Box display="flex">
								<MenuItem style={{ paddingLeft: 24 }}>
									<Typography
										variant="inherit"
										className={clsx(classes.textSize, {
											[classes.active]:
												window.location.href.includes(LinksConst.PROD_LINK) ||
												window.location.href.includes(LinksConst.PROD_LINK),
										})}
										onClick={() => {
											window.location.replace(LinksConst.PROD_LINK + history.location.pathname);
										}}
									>
										{t("production")}
									</Typography>
								</MenuItem>
								<MenuItem style={{ paddingRight: 24 }}>
									<Typography
										variant="inherit"
										className={clsx(classes.textSize, {
											[classes.active]: window.location.href.includes(LinksConst.INT_LINK),
										})}
										onClick={() => {
											window.location.replace(LinksConst.INT_LINK + history.location.pathname);
										}}
									>
										{t("integration")}
									</Typography>
								</MenuItem>
							</Box>

							<MenuItem
								className={clsx(classes.menuItem, {
									[classes.active]: context.systemView === SystemViewTypes.Admin,
								})}
								onClick={() => handleSystemViewChange(SystemViewTypes.Admin)}
							>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("admin")}
								</Typography>
							</MenuItem>

							<MenuItem
								className={clsx(classes.menuItem, {
									[classes.active]:
										context.user?.isAdmin() && context.systemView === SystemViewTypes.Viewer,
								})}
								onClick={() => handleSystemViewChange(SystemViewTypes.Viewer)}
							>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("viewer")}
								</Typography>
							</MenuItem>

							<MenuItem
								className={clsx(classes.menuItem, {
									[classes.active]: context.systemView === SystemViewTypes.External,
								})}
								onClick={() => handleSystemViewChange(SystemViewTypes.External)}
							>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("external")}
								</Typography>
							</MenuItem>

							<MenuItem
								style={{ paddingBottom: 18 }}
								className={clsx(classes.menuItem, {
									[classes.active]: context.systemView === SystemViewTypes.Testing,
								})}
								onClick={() => handleSystemViewChange(SystemViewTypes.Testing)}
							>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("testing")}
								</Typography>
							</MenuItem>
						</Box>
					)}
					{context?.user?.isAdmin() && (
						<Box>
							<Divider
								style={{
									width: "80%",
									margin: "auto",
									marginTop: 10,
									marginBottom: 10,
								}}
							/>

							<MenuItem className={classes.menuItem} disabled>
								<Typography
									color="textSecondary"
									variant="inherit"
									className={clsx(classes.textSize, classes.menuHeader)}
								>
									<Box component="span" fontWeight="fontWeightBold">
										{t("searchConfiguration")}
									</Box>
								</Typography>
							</MenuItem>

							<MenuItem
								className={clsx(classes.menuItem, {
									[classes.active]: context.diverseSearch,
								})}
								onClick={() => context.setDiverseSearch(true)}
							>
								<Typography variant="inherit" className={clsx(classes.textSize)}>
									{t("diverseResults")}
								</Typography>
							</MenuItem>

							<MenuItem
								className={clsx(classes.menuItem, {
									[classes.active]: !context.diverseSearch,
								})}
								onClick={() => context.setDiverseSearch(false)}
							>
								<Typography variant="inherit" color="textPrimary" className={clsx(classes.textSize)}>
									{t("originalResults")}
								</Typography>
							</MenuItem>
						</Box>
					)}
				</Menu>
			)}

			<Dialog
				open={settingsOpen}
				onClose={handleCloseSettings}
				maxWidth="sm"
				fullWidth
				BackdropProps={{
					classes: {
						root: classes.backDrop,
					},
				}}
			>
				<AccountSettings
					handleClose={() => {
						setSettingsOpen(false);
					}}
				/>
			</Dialog>
		</Box>
	);
};

export default UserMenu;
