import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Context from "../../Context/Context";
import AfterRegistrationContent from "./AfterRegistrationContent";
import ForgotPasswordForm from "./Login/ForgotPasswordForm";
import LoginForm from "./Login/LoginForm";
import RegisterForm from "./Login/RegisterForm";
import UserInfoForm from "./Login/userInfoForm";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import { saveAtWindow } from "../../Helpers/window";
import RouterConstants from "../../Constants/RouterConstants";
import { getRandomText, SearchRandomTexts } from "../../Constants/RandomTextGenerator";

type LoginProps = {};

export default function AuthDialog({}: LoginProps) {
	const classes = useStyles();
	const context = useContext(Context);
	const displayAuthDialog = context.displayAuthDialog;
	const history = useHistory();
	const setDisplayAuthDialog = (value: boolean) => {
		context.setAuthDialogState(value);
	};

	const generateRandomText = (openGallery?: boolean) => {
		let enteredText = getRandomText(SearchRandomTexts);
		if (openGallery) {
			Analytics.logEvent(ANALYTICS_EVENTS.PRICING_GENERATE);
			saveAtWindow("STORED_SEARCH_INFO_KEY", {
				STORED_SEARCH_KEY: enteredText,
				STORED_VHASH_KEY: "",
				STORED_GENRATE_KEY: true,
			});
			history.push(RouterConstants.GALLERY.path, {});
		}
	};

	return (
		<Dialog
			onClose={(event, reason) => {
				if (
					context.authDialogSource === "Editor Login" &&
					(reason === "backdropClick" || reason === "escapeKeyDown")
				) {
					return;
				}
				context.setAuthDialogStep("Register");
				setDisplayAuthDialog(false);
				context.setAuthDialogSource("");
			}}
			aria-labelledby="simple-dialog-title"
			open={displayAuthDialog}
			maxWidth={false}
			style={{
				display:
					!context.showLoginForm ||
					(context.authDialogStep === "AfterRegistrationContent" &&
						context.authDialogSource !== "Select Plan-free")
						? "none"
						: "unset",
			}}
			classes={{
				paper:
					context.authDialogStep === "AfterRegistrationContent"
						? clsx(classes.wideDialog)
						: context.authDialogStep === "RegisterInfo"
						? clsx(classes.longDialog)
						: clsx(classes.dialog),
			}}
		>
			{" "}
			{context.authDialogStep === "Register" || context.authDialogStep === "RegisterInfo" ? (
				<RegisterForm />
			) : context.authDialogStep === "AfterRegistrationContent" ? (
				<AfterRegistrationContent
					onButtonClick={() => {
						context.setShowLoginForm(false);
						context.setAuthDialogStep("Login");
						if (context.user) {
							context.user.updated = !context.user.updated;
						}
						generateRandomText(true);
					}}
				/>
			) : context.authDialogStep === "Login" ? (
				<LoginForm />
			) : context.authDialogStep === "UserInfo" ? (
				<UserInfoForm />
			) : (
				<ForgotPasswordForm />
			)}
		</Dialog>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			borderRadius: "10px !important",
			width: "36.313rem !important",
			height: "43.75rem !important",
		},
		longDialog: {
			borderRadius: "10px !important",
			width: "37rem !important",
			height: "45.438rem !important",
		},
		wideDialog: {
			borderRadius: "10px !important",
			width: "39.313rem !important",
			height: "30.75rem !important",
		},
	})
);
