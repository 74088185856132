import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useContext } from "react";
import Flex, { FlexGaps } from "../../../../Common/Wrappers/Flex";
import { BrandContext } from "../../BrandPage/BrandPage";
import { brandSteps } from "../../steps";
import { INavigator } from "../Navigator";

export const filterHidden = (steps: any[] = []) =>
	steps.filter((step: any) => !step?.hidden);

export default function CurrentStepIndicator({
	currentIndex,
	next,
	prev,
}: INavigator) {
	const classes = useStyles();
	const steps: any[] = brandSteps();
	const { navigate } = useContext(BrandContext);
	const moodsIndices = [5, 6, 7, 8, 9, 10]

	const stepIndicatorClass = (index: number) =>
		clsx({[classes.active]: currentIndex === index || (moodsIndices.includes(index) && moodsIndices.includes(currentIndex))}, classes.pointer);

	return (
		<Flex gap={FlexGaps.md}>
			{steps.map((step, index) => (
				<>
					{!step.hidden && (
						<Box
							className={stepIndicatorClass(index)}
							onClick={() => navigate(index)}
						/>
					)}
				</>
			))}
		</Flex>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		position: "absolute",
		left: "2rem",
		bottom: "5%",
	},
	pointer: {
		width: 10,
		aspectRatio: "1/1",
		borderRadius: "50%",
		backgroundColor: "#A4A4A4",
	},
	active: {
		backgroundColor: "#D80067",
	},
}));
