import React from "react";

interface IconProps {
	name: string;
	className?: string;
}

const Icon = ({ name, ...rest }: any) => {
	const [path, setPath] = React.useState("");

	React.useEffect(() => {
		const importIcon = async () => {
			try {
				const { default: _path } = await import(
					`../../../assets/svgs/${name}.svg`
				);
				setPath(_path);
			} catch (err) {
				console.error(err);
			}
		};
		importIcon();
	}, [name]);

	return <>{path && <img src={path} alt=" " {...rest} />}</>;
};

export default Icon;
