import { Box, createStyles, InputBase, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import image from "../assets/CampaignInfoPage.png";
import imageJapanese from "../assets/CampaignInfoPageJapanese.png";
import DropDown from "../Components/Common/DropDown/DropDown";
import React, { useContext, useState } from "react";
import CustomMultiSelect from "../Components/Common/DropDown/MultiSelectGroupedDropDown";
import BriaButton, { BUTTONTYPES } from "../Components/UI/BriaButton";
import arrowRight from "../assets/chevron-right.svg";
import CreateNewBrandDialog from "../Components/CreateNewBrandDialog";
import { useTranslation } from "react-i18next";
import Context from "../Context/Context";
import DropDownButton from "../Components/Campaign/dropDownButton";
import CustomSingleSelect, { IGroup } from "../Components/Common/DropDown/ٍSingleSelectGroupedDropDown";
import { IsIframe } from "../Helpers/iframe";
import i18n from "i18next";
import { LanguageCodesEnum, LanguagesEnum } from "../Models/ToolsConfig";

interface IProps {
	handleGetStartedButton: () => void;
}

const CampainInfo = ({ handleGetStartedButton }: IProps) => {
	const context = useContext(Context);
	const [shouldOpenCreateBrandDialog, setShouldOpenCreateBrandDialog] = useState(false);
	const [isTextBoxValueValid, setIsTextBoxValueValid] = useState(true);
	const [isMediaSelectedValueValid, setIsMediaSelectedValueValid] = useState(true);
	const [isMediaSizeSelectedValueValid, setIsMediaSizeSelectedValueValid] = useState(true);
	const [isTemplateSelectedValueValid, setIsTemplateSelectedValueValid] = useState(true);
	const { isIframe } = IsIframe();

	const handleMultiSelectChange = (event: any) => {
		context.setSelectedChannelValues(event.target.value);
		// context.setSelectedPlacementsValues([]);
	};

	const handleSizeMultiSelectChange = (event: any) => {
		context.setSelectedPlacementsValues(event.target.value);
	};

	const handleGetStartedClick = (event: any) => {
		if (
			!context.campaignName ||
			context.selectedChannelValues.length == 0 ||
			!context?.selectedTemplate
			// context.selectedPlacementsValues.length == 0
		) {
			if (!context.campaignName) {
				setIsTextBoxValueValid(false);
			}
			if (context.selectedChannelValues.length == 0) {
				setIsMediaSelectedValueValid(false);
			}
			if (!context?.selectedTemplate) {
				setIsTemplateSelectedValueValid(false);
			}
			// if (context.selectedPlacementsValues.length == 0) {
			// 	setIsMediaSizeSelectedValueValid(false);
			// }
		} else {
			handleGetStartedButton();
		}
	};
	const handleTemplateChnage = (event: any) => {
		const selectedOption = context.orgTemplates
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option?.id === event.target.value);
		context.setSelectedTemplate(selectedOption?.id);
	};

	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "CampainInfo" });

	const getOrgChannelNameById = (id: string) => {
		for (const orgChannel of context?.orgChannels) {
			for (const option of orgChannel.options) {
				if (option.id === id) {
					return option.value;
				}
			}
		}
	};

	const getOrgTemplateNameById = (id: string) => {
		if (context && context?.orgTemplates) {
			for (const orgTemplate of context?.orgTemplates) {
				for (const option of orgTemplate?.options) {
					if (option.id === id) {
						return option.value;
					}
				}
			}
		}
	};
	// const getOrgPlacementNameById = (id: string) => {
	// 	if (context.orgPlacements && context.orgPlacements.length > 0) {
	// 		for (const orgPlacement of context.orgPlacements) {
	// 			for (const option of orgPlacement?.options) {
	// 				if (option.id === id) {
	// 					return option.label;
	// 				}
	// 			}
	// 		}
	// 	}
	// };

	return (
		<>
			<Box
				className={classes.mainContainer}
				style={{
					height:
						isIframe() && context.iframeConfig?.embeddedIframe
							? "calc(100vh - 70px)"
							: "calc(100vh - 155px)",
				}}
			>
				<Box className={classes.form}>
					<Typography className={classes.formTitle}>{t("campaignGeneration")}</Typography>
					<Box className={classes.inputsComponents}>
						<Typography className={classes.inputsLabel}>{t("campaignName")}</Typography>
						<InputBase
							id="inputId"
							placeholder={t("addCampaignName")}
							className={`${classes.textField} ${!isTextBoxValueValid && classes.errorBorder}`}
							onChange={(e) => context.setCampaignName(e.target.value)}
							value={context.campaignName}
						/>
						<Box style={{ marginBottom: "20px" }}>
							<Box className={classes.chooseBrandLables}>
								<Typography className={classes.inputsLabel}>{t("chooseBrand")}</Typography>
								<Typography className={classes.inputsLabelOptional}>{t("Optional")}</Typography>
							</Box>
							<DropDown
								options={context.orgBrands || []}
								extraOptions={[{ id: "", title: t("selectPreset"), brandDropDown: true }]}
								defaultSelected=""
								onChange={() => {}}
								selectedOption={context.selectedDropdownValue}
								placeholder={t("selectPreset")}
								color="#5B5B5B !important"
								border={"1px solid rgb(164, 164, 164)"}
								borderRadius="8px"
								width="100%"
								height="4.5vh"
								isFullWidth={true}
								paddingInline={"12px 8px"}
								fontSize="14px"
								CampainDropdownElemStyle={true}
								fontWeight={500}
								loading={context.loadingBrands}
								additionalElement={
									<DropDownButton
										lastOptionHandler={() => setShouldOpenCreateBrandDialog(true)}
										options={context.orgBrands || []}
									/>
								}
								optionsScroll={true}
								maxHeight="40vh"
							></DropDown>
						</Box>
						<Typography className={classes.inputsLabel}>{t("template")}</Typography>
						<CustomSingleSelect
							selectedValue={context.selectedTemplate}
							handleChange={handleTemplateChnage}
							groups={(context.orgTemplates as IGroup[]) || []}
							className={classes.formControl}
							selectRootClass={classes.rootFirstSelect}
							placeholder={t("selectTemplate")}
							selectStyle={classes.selectStyleSingleselect}
							menuMaxHeight="50vh"
							loading={context.loadingTemplates}
							selectedValueName={getOrgTemplateNameById(context?.selectedTemplate ?? "")}
							hasError={!isTemplateSelectedValueValid}
							selectOption={t("selectTemplate")}
							noOptions={t("noResult")}
						/>
						<Typography className={classes.inputsLabel}>{t("Media")}</Typography>
						<CustomMultiSelect
							selectedValues={context.selectedChannelValues}
							handleChange={handleMultiSelectChange}
							groups={context.orgChannels}
							paddingClass={classes.rootFirstSelect}
							selectStyle={classes.selectStyle}
							placeholder={t("SelectChannels")}
							selectedPlaceHolder={t("channelsSelected")}
							hasError={!isMediaSelectedValueValid}
							loading={context.loadingChannels}
							selectedValueName={
								context.selectedChannelValues.length == 1 &&
								getOrgChannelNameById(context.selectedChannelValues[0])
							}
						/>

						{/* <Typography className={classes.inputsLabel}>{t("MediaSize")}</Typography>
						<CustomMultiSelect
							selectedValues={context.selectedPlacementsValues}
							handleChange={handleSizeMultiSelectChange}
							groups={context.orgPlacements || []}
							paddingClass={classes.rootFirstSelect}
							selectStyle={classes.selectStyle}
							placeholder="Select placements"
							selectedPlaceHolder="Placements were selected"
							hasError={!isMediaSizeSelectedValueValid}
							selectedValueName={
								context.selectedPlacementsValues.length == 1 &&
								getOrgPlacementNameById(context.selectedPlacementsValues[0])
							}
							loading={context.loadingPlacements}
						/> */}

						<Box className={classes.buttonContainer}>
							<BriaButton
								className={classes.getStartedButton}
								buttonType={BUTTONTYPES.PRIMARY}
								onClick={handleGetStartedClick}
							>
								{t("getStarted")}
								<img className={classes.arrowRight} src={arrowRight} alt="" />
							</BriaButton>
						</Box>
					</Box>
				</Box>

				<Box className={classes.imgContainer}>
					<img
						className={classes.rightImage}
						src={i18n.language === LanguageCodesEnum[LanguagesEnum.Japanese] ? imageJapanese : image}
					></img>
				</Box>
			</Box>

			<CreateNewBrandDialog
				shouldOpen={shouldOpenCreateBrandDialog}
				onClose={() => setShouldOpenCreateBrandDialog(false)}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rootFirstSelect: {
			padding: "10px 12px",
		},
		buttonContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "end",
			marginTop: "4px",
		},
		imgContainer: {
			"@media (max-width:850px)": {
				minWidth: "450px",
			},
		},
		mainContainer: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			backgroundColor: "white",
			boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.08)",
			borderRadius: "8px",
			"@media (max-width:850px)": {
				minWidth: "850px",
			},
			"@media (max-height:650px)": {
				height: "auto !important",
			},
		},
		form: {
			maxWidth: "404px",
			marginLeft: "auto",
			marginRight: "auto",
			paddingInline: "20px",
			justifyContent: "center",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			height: "100%",
			"@media (max-height:650px)": {
				marginTop: "auto !important",
				marginBottom: "auto !important",
			},
			"& > div .dropdownWrapper > div": {
				width: "100%",
			},
			"& > div .dropdownWrapper": {
				height: "40px",
			},
			"@media (max-width:768px)": {
				marginLeft: "24px",
				marginRight: "24px",
			},
		},
		inputsComponents: {
			marginTop: "48px",
			width: "100%",
		},
		formTitle: {
			color: "#1A0638",
			fontSize: "28px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		inputsLabel: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		inputsLabelOptional: {
			color: "#5B5B5B",
			fontSize: "14px",
			fontWeight: 400,
		},
		textField: {
			width: "100%",
			borderRadius: "8px",
			height: "40px",
			marginTop: "12px",
			border: "1px solid #B3B3B9",
			color: "#5B5B5B",
			paddingRight: "6px",
			paddingLeft: "12px",
			"& input": {
				fontSize: 14,
				textOverflow: "ellipsis",
				"&::placeholder": {
					color: "#5B5B5B",
					fontSize: 14,
					fontWeight: 400,
					lineHeight: "21px",
					opacity: 1,
				},
			},
			marginBottom: "20px",
		},
		multiSelect: {
			padding: "2px 12px",
			border: "1px solid #B3B3B9",
			borderRadius: "8px",
			"&::before": {
				borderBottom: "none",
			},
			"&::after": {
				borderBottom: "none",
			},
			"&:hover:not(.Mui-disabled):before": {
				borderBottom: "none",
			},
		},
		legend: {
			display: "none",
		},
		chooseBrandLables: {
			display: "flex",
			justifyContent: "space-between",
			marginBottom: "12px",
		},
		selectStyle: {
			boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.08) !important",
			borderRadius: "10px !important",
			backgroundColor: "white",
			"&:focus": {
				backgroundColor: "white",
			},
		},
		getStartedButton: {
			borderRadius: "8px",
			fontWeight: 600,
			fontSize: "16px",
			padding: "8px 16px",
			"&:focus, &:active": {
				backgroundColor: theme.palette.primary.dark,
			},
			"@media (max-height:650px)": {
				marginBottom: "5px !important",
			},
		},
		arrowRight: {
			paddingLeft: "8px",
		},
		rightImage: {
			maxWidth: "100%",
			width: "100%",
			height: "100%",
			borderRadius: "8px",
		},
		errorBorder: {
			border: "1px solid red",
		},
		selectStyleSingleselect: {
			width: "320px !important",
			backgroundColor: "white",
			"&:focus": {
				backgroundColor: "white",
			},
		},
		formControl: {
			paddingTop: "12px",
			marginBottom: "20px",
		},
	})
);

export default CampainInfo;
