import React from "react";
import { Box, TextField, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useMemo } from "react";
import clsx from "clsx";

interface ITitleTexts {
	text?: string;
	subText?: string;
	passedClassName?: string;
	style?: any;
}

export default function TitleTexts({
	text,
	subText,
	passedClassName = "",
	style
}: ITitleTexts) {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.textWrapper, passedClassName)} {...style}>
			{text && <Typography className={classes.title}>{text}</Typography>}
			{subText && (
				<Typography className={classes.grayFont}>{subText}</Typography>
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	textWrapper: {
	},
	grayFont: {
		marginTop: 25,
		color: "#A4A4A4",
		fontSize: "1.25vw",
		lineHeight: "1.5rem",
		whiteSpace: "pre-wrap"
	},
	title: {
		color: theme.palette.text.primary,
		fontWeight: 600,
		fontSize: "2.39vw"
	},
}));
