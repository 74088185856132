import { useState } from "react";
import { Box, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
import { Bria2SearchRandomTexts } from "../../Constants/RandomTextGenerator";
import SurpriseMeButton from "../../Components/shared/SurpriseButton";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import { saveAtWindow } from "../../Helpers/window";
import { useHistory } from "react-router-dom";
import RouterConstants from "../../Constants/RouterConstants";
import { useTranslation } from "react-i18next";

interface IProps {
	styleType?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "400px",
			fontFamily: "Space Grotesk",
			"& *": {
				fontFamily: "inherit",
			},
		},
		generativeAIText: {
			color: "#4642FF",
			fontSize: "24px",
			fontWeight: 400,
			marginBottom: "18px",
			letterSpacing: "-0.12px",
			"& span": {
				fontWeight: 700,
				display: "block",
				fontSize: "24px",
				letterSpacing: "-0.15px",
			},
			fontFamily: "Space Grotesk",
		},

		textField: {
			"& textarea": {
				height: "100% !important",
			},
			"& .MuiInputBase-root": {
				borderRadius: 8,
				height: 152,
				background: "white",
				padding: "12px 14px 12px 14px !important",
				boxShadow:
					"0px 1px 1px 0px #0000000F,0px 3px 3px 0px #0000000D,0px 6px 4px 0px #00000008,0px 11px 4px 0px #00000003,0px 16px 5px 0px #00000000",

				border: "1px solid #ECECEC !important",
				"&:hover": {
					boxShadow: "0 0 0 1px #1A0638",
				},
				"&.Mui-focused": {
					boxShadow: "0 0 0 3px #4642FF !important",
				},
			},
			"& .MuiInput-underline:before ": {
				border: "none !important",
			},

			"& .MuiInputBase-input": {
				fontSize: "14px",
				fontWeight: 500,
				color: "#1A0638",
				letterSpacing: "0.02rem",
				lineHeight: "16px",
			},
		},
		generateButton: {
			width: 149,
			height: 40,
			backgroundColor: "#4642FF",
			fontSize: 14,
			fontWeight: 700,
			textWrap: "nowrap",
			"&:hover": {
				backgroundColor: "#3835CC",
			},
		},
		surpriseButton: {
			textTransform: "none",
			borderRadius: 8,
			"&:hover": {
				backgroundColor: "#0000001A",
			},
		},
		buttonsContainer: {
			marginTop: "10px",
			display: "flex",
			alignItems: "center",
			justifyContent: "end",
		},
	})
);

const GenerativeAISection = (props: IProps) => {
	const classes = useStyles(props);
	const history = useHistory();
	const { t } = useTranslation();

	const [currentSearch, setCurrentSearch] = useState<string>("");
	const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newText: string = event.target.value;
		setCurrentSearch(newText);
	};
	const handleGenerateImgs = async () => {
		let enteredText = currentSearch.trim();
		if (enteredText !== "") {
			Analytics.logEvent(ANALYTICS_EVENTS.HOME_GENERATE);
			saveAtWindow("STORED_SEARCH_INFO_KEY", {
				STORED_SEARCH_KEY: enteredText,
				STORED_VHASH_KEY: "",
				STORED_GENRATE_KEY: true,
			});

			history.push(RouterConstants.GALLERY_DEMO.path, {});
		} else {
			document.getElementById("inputId")?.focus();
		}
	};

	return (
		<Box className={classes?.container}>
			<Typography variant="h1" className={classes?.generativeAIText}>
				<span>{t("briaModels")}</span>
				{t("modelSubTitle")}
			</Typography>

			<TextField
				autoFocus
				fullWidth
				multiline
				placeholder="Generate images using the text-to-image model"
				value={currentSearch}
				className={classes.textField}
				onChange={handleSearchTextChange}
				InputProps={{ disableUnderline: true }}
			/>
			<Box className={classes?.buttonsContainer}>
				<SurpriseMeButton
					list={Bria2SearchRandomTexts}
					onClick={(text: any) => setCurrentSearch(text)}
					className={classes.surpriseButton}
					style={{
						height: 40,
						width: 120,
						marginRight: 5,
						color: "#4642FF",
					}}
				/>
				<BriaButton
					className={classes.generateButton}
					id="GenrateBtn"
					buttonType={BUTTONTYPES.PRIMARYSMALL}
					onClick={() => {
						handleGenerateImgs();
					}}
				>
					Start generating
				</BriaButton>
			</Box>
		</Box>
	);
};
export default GenerativeAISection;
