import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from "@sentry/react";
import smartlookClient from "smartlook-client";
import client from "./GraphQL/client";
import { ApolloProvider } from "@apollo/client/react";
import { isInt, isProd } from "./Config/Env";
import { createBrowserHistory } from "history";

if (isProd || isInt) {
	smartlookClient.init("ec245a49a6bf8b418a2b34e773128025ec739027");
	smartlookClient.record({
		forms: true,
		ips: true,
		numbers: true,
		emails: true,
		api: true,
	});

	const history = createBrowserHistory();
	Sentry.init({
		dsn: "https://507ec78025934aa9bc3e5e94b85cc8ad@o417868.ingest.sentry.io/4504241568350208",
		integrations: [
			new Sentry.BrowserProfilingIntegration(), // for profiling, "Document-Policy" response header should be set to "js-profiling"
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
			new Sentry.Replay({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [
					window.location.origin,
					process.env.REACT_APP_WEB_APP_URL ?? "",
					process.env.REACT_APP_BRIA_API_URL ?? "",
				],
			}),
		],
		debug: isInt,
		environment: isProd ? "production" : isInt ? "integration" : "local",

		tracesSampleRate: 0,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Suspense
				fallback={
					<Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
						<CircularProgress />
					</Box>
				}
			>
				<App />
			</Suspense>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
