import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Flex from "../../../../Common/Wrappers/Flex";
import ColorList from "../../../Mood/ColorMixer/ColorPicker/ColorList/ColorList";
import {
	createStepInfo,
	defaultSlidersState,
	getSliderValue,
	getStateUpdate,
} from "../../../Mood/data/sliders";
import { BrandContext } from "../../BrandPage/BrandPage";
import { GridImgProps } from "../../BrandPage/ImgViewer/ImgViewer";
import TitleTexts from "../../TitleTexts/TitleTexts";
import { CustomMoodSlider } from "../../Moods/MoodSlider/MoodSlider";

interface Slider {
	title: string;
	hint: string;
	key: string;
}

const slidersArray: Slider[] = [
	{
		title: "Brightness",
		hint: "or dark?",
		key: "brightness",
		minValue: -5,
		maxValue: 5,
		step: 1,
	},
	{
		title: "Contrast",
		hint: "or dramatic?",
		key: "contrast",
		minValue: -100,
		maxValue: 100,
		step: 10,
	},
	{
		title: "Vibrance",
		hint: "or faded?",
		key: "vibrance",
		minValue: -100,
		maxValue: 100,
		step: 10,
	},
	{
		title: "Saturation",
		hint: "or saturated?",
		key: "saturation",
		minValue: -100,
		maxValue: 100,
		step: 10,
	},
].map((slider) => createStepInfo(slider));

export default function FineTuningSliders() {
	const classes = useStyles();
	const {
		updateBrandState,
		brandState,
		updateGeneralState,
		changeId,
		setChangeId,
		imgsState,
		generalState,
		silentImgUpdate,
		updateFineTunningInitialSlidersState,
		fineTunningInitialSlidersState,
	} = useContext(BrandContext);

	const [selectedImage, setSelectedImage] = useState(
		(brandState as any)?.selectedResultImage ?? "center"
	);

	const { moodSliders } = (brandState as any) || {};

	function getSelectedMoodSliders() {
		return selectedImage === "left"
			? (generalState as any)?.moodSlidersSuggestion1
			: selectedImage === "right"
			? (generalState as any)?.moodSlidersSuggestion2
			: (brandState as any)?.moodSliders;
	}
	const [slidersState, setSliderState] = useState(
		getSelectedMoodSliders() || {}
	);
	const [slidersChanged, setSlidersChanged] = useState<boolean>(false);
	const [totalImgsDefault, setTotalImgsDefault] = useState<GridImgProps[]>([
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
		{ src: "", original: "", vhash: "" },
	]);

	useEffect(() => {
		updateFineTunningInitialSlidersState(
			getSelectedMoodSliders() || defaultSlidersState
		);
		const localTotalImgsDefault = totalImgsDefault;
		imgsState.totalImgs.forEach((img: GridImgProps, index: number) => {
			localTotalImgsDefault[index].original = img.original;
			localTotalImgsDefault[index].src = img.src;
			localTotalImgsDefault[index].vhash = img.vhash;
		});
		setTotalImgsDefault(localTotalImgsDefault);
	}, []);

	const updateState = (newState: any) =>
		setSliderState({ ...slidersState, ...newState });

	const onChange = (key: string, newValue: any) => {
		try {
			setSlidersChanged(true);
			const updatedState = getStateUpdate(key, newValue, slidersState);
			updateState(updatedState);
			setTimeout(() => {
				setChangeId((c: any) => c + 1);
			}, 200);
		} catch (error) {}
	};
	const onUpdate = () => {
		setSlidersChanged(true);
		setTimeout(() => {
			setChangeId((c: number) => c + 1);
		}, 200);
	};

	useEffect(() => {
		if (!Object.values(slidersState).length) return;
		if (selectedImage === "left") {
			updateGeneralState("", "", {
				moodSlidersSuggestion1: slidersState,
			});
		} else if (selectedImage === "right") {
			updateGeneralState("", "", {
				moodSlidersSuggestion2: slidersState,
			});
		} else {
			updateBrandState(slidersState);
		}
	}, [slidersState]);

	// TODO: Implement Reset functionality
	const onReset = () => {
		// if (slidersChanged) {
		// 	imgsState.setTotalImgs(totalImgsDefault);
		// 	setSliderState(defaultSlidersState);
		// 	setSlidersChanged(false);
		// }
	};

	return (
		<>
			<Box mt="1rem" className={classes.relaive}>
				<Flex style={{ flexDirection: "column" }}>
					{(slidersArray as any[]).map((slider) => (
						<>
							<Box
								display="flex"
								width="100%"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography
									style={{
										color:
											getSliderValue(
												slider.key,
												getSelectedMoodSliders()
											) || 0 == 0
												? "#A4A4A4"
												: "#495057",
										width: "8vw",
										textAlign: "left",
										fontSize: "14px",
										marginBottom: "10px",
									}}
								>
									{slider.title}
								</Typography>
								<Box width="420px">
									<CustomMoodSlider
										onChange={(e: any, value: number) => {
											onChange(slider.key, value);
										}}
										min={slider.minValue}
										max={slider.maxValue}
										step={slider.step}
										showTrack={true}
										value={
											getSliderValue(
												slider.key,
												getSelectedMoodSliders()
											) || 0
										}
									/>
								</Box>
							</Box>
						</>
					))}
				</Flex>
			</Box>
			<Box mt="0.5rem" mb="-3rem">
				<TitleTexts
					passedClassName={classes.colorMixerTitle}
					style={{ textAlign: "left" }}
					text="Color Mixer"
					subText="Select a color and tweak it "
				/>
			</Box>
			<Box textAlign="left">
				<ColorList
					showedSlidersLength={1}
					passedColorState={(slidersState as any)?.["color_mixer"]}
					updateState={updateState}
					onUpdate={onUpdate}
					moodSliders={(brandState as any)?.[moodSliders]}
					isBrandPage={true}
					onReset={onReset}
				/>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	hint: {
		color: "#495057",
	},
	relaive: {
		position: "relative",
	},
	resetAll: {
		position: "absolute",
		right: 0,
		top: "-10%",
		color: theme.palette.common.black,
		"& p": {
			fontSize: "12px !important",
			cursor: "pointer",
			fontWeight: "bold",
		},
	},
	colorMixerTitle: {
		"& p": {
			fontSize: "1.25vw !important",
			"&:last-child": {
				marginTop: 0,
			},
		},
	},
}));
