import { useContext, useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import GenerativeAISection from "./GenerativeAISection";
import { ReactComponent as Logo } from "../../assets/bria_logo.svg";
import BackgroundImg from "../../assets/preLoginBackground.png";
import image1 from "../../assets/Lines1.png";
import image2 from "../../assets/Lines2.png";
import image3 from "../../assets/Lines3.png";
import { IsIframe } from "../../Helpers/iframe";
import Context from "../../Context/Context";
import { useHistory } from "react-router-dom";
import RouterConstants from "../../Constants/RouterConstants";
import { SystemViewTypes } from "../../Models/SystemView";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "100vh",
		backgroundImage: `url(${BackgroundImg})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		fontFamily: "Space Grotesk, sans-serif",
	},
	genarativeAISection: (props) => ({
		display: "flex",
		alignItems: "center",
		height: "100%",
		justifyContent: "center",
		width: "52%",
	}),
	briaLogo: {
		width: 43,
		height: 29,
		marginTop: "11px",
		marginLeft: "17px",
		position: "absolute",
	},
	imgContainer: {
		height: "100vh",
		display: "flex",
	},
	mainImage: {
		height: "0",
		width: "0",
		opacity: 0,
		visibility: "hidden",
	},
	active: {
		visibility: "visible",
		height: "100%",
		width: "100%",
		opacity: 1,
		transition: "opacity 0.7s ease-in-out",
	},
}));
const GaiaDemo = () => {
	const images = [image1, image2, image3];
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const { isIframe } = IsIframe();
	const context = useContext(Context);
	const history = useHistory();

	const classes = useStyles();
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 3000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		document.getElementById(`image-0`).classList.remove(classes.active);
		document.getElementById(`image-1`).classList.remove(classes.active);
		document.getElementById(`image-2`).classList.remove(classes.active);
		document.getElementById(`image-${currentImageIndex}`).classList.add(classes.active);
	}, [currentImageIndex]);
	return (
		<Box className={classes?.container}>
			<Logo
				onClick={() => {
					!isIframe() &&
						(context.user?.isAdmin() && context.systemView === SystemViewTypes.Admin
							? history.push(RouterConstants.VDR.path)
							: history.push(RouterConstants.HOME.path));
				}}
				className={classes?.briaLogo}
			/>
			<Box className={classes?.genarativeAISection}>
				<GenerativeAISection />
			</Box>
			<Box className={classes.imgContainer}>
				<img id="image-0" src={images[0]} className={classes.mainImage}></img>
				<img id="image-1" src={images[1]} className={classes.mainImage}></img>
				<img id="image-2" src={images[2]} className={classes.mainImage}></img>
			</Box>
		</Box>
	);
};
export default GaiaDemo;
