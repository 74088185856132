import { getImageMeta } from "../utilities";
import Resizer from "react-image-file-resizer";

export function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
	const rotRad = getRadianAngle(rotation);

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) +
			Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) +
			Math.abs(Math.cos(rotRad) * height),
	};
}

export default async function getCroppedImg(
	imageSrc,
	pixelCrop,
	rotation = 0,
	flip = { horizontal: false, vertical: false }
) {
	const image = await getImageMeta(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d", { willReadFrequently: true });

	if (!ctx) {
		return null;
	}

	const rotRad = getRadianAngle(rotation);

	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);
	ctx.fillStyle = "rgba(0, 0, 0, 0)";
	ctx.fillRect(0, 0, bBoxWidth, bBoxHeight);
	ctx.drawImage(image, 0, 0);

	const data = ctx.getImageData(
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height
	);

	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx.putImageData(data, 0, 0);
	if (hasAlpha(ctx, canvas)) {
		return new Promise((resolve, reject) => {
			canvas.toBlob((file) => {
				resolve(URL.createObjectURL(file));
			}, "image/png");
		});
	} else
		return new Promise((resolve, reject) => {
			canvas.toBlob((file) => {
				resolve(URL.createObjectURL(file));
			}, "image/jpeg");
		});
}
function hasAlpha(context, canvas) {
	var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
		hasAlphaPixels = false;
	for (var i = 3, n = data.length; i < n; i += 4) {
		if (data[i] < 255) {
			hasAlphaPixels = true;
			break;
		}
	}
	return hasAlphaPixels;
}

export async function resize(
	imageUrl,
	croppedAreaPixels,
	rotation,
	width,
	height
) {
	const isBase64 = imageUrl.includes("data:image/");
	const tmpImageUrl = isBase64
		? imageUrl
		: imageUrl +
		  (imageUrl.includes("?") ? "&" : "?") +
		  "dummy=" +
		  Date.now();

	const file = await getCroppedImg(tmpImageUrl, croppedAreaPixels, rotation);
	const response = await fetch(file);
	// here image is url/location of image
	const blob = await response.blob();
	const url = new File([blob], "image.png", { type: blob.type });
	//const url = new File([file], "name");
	const res = new Promise((resolve) => {
		Resizer.imageFileResizer(
			url,
			width,
			height,
			"PNG",
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64",
			width,
			height
		);
	});
	return res;
}
