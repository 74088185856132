import React, { useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "../../UI/BriaButton";
import Flex from "../Wrappers/Flex";
import { useContext } from "react";
import { BrandContext } from "../../Tools/Brand/BrandPage/BrandPage";

interface ICustomPrompt {
	when: boolean;
	message: string;
	allowedPaths?: string[]; // TODO: if there's a case that navigation is allowed only for specific routes => apply this
}

export default function CustomPrompt({ when, message }: ICustomPrompt) {
	const classes = useStyles();
	const { push } = useHistory();
	const { changeId } = useContext(BrandContext);
	const hasUnSavedChanged = changeId !== -1;

	const [modalShown, setModalShown] = useState(false);
	const [savedPathname, setSavedPathname] = useState(""); // dep for allowing navigating

	const onPromptShow = (location: any, action: any) => {
		setSavedPathname(location?.pathname);
		setModalShown(true);
		if (savedPathname) return true;
		return false;
	};

	const onCloseModal = () => {
		setModalShown(false);
	};

	const onSave = () => {
		push(savedPathname);
		onCloseModal();
	};

	const onCancel = () => {
		setSavedPathname("");
		onCloseModal();
	};

	return (
		<>
			<Prompt when={hasUnSavedChanged} message={onPromptShow} />
			<Dialog
				classes={{
					paper: classes.paper,
				}}
				onClose={onCloseModal}
				open={modalShown}
				maxWidth={false}
			>
				<Box className={classes.promtWrapper}>
					<Typography
						className={classes.beforeYouGoText}
						variant="h5"
					>
						Before you go...
					</Typography>
					<Typography className={classes.messageText}>
						{message}
					</Typography>
					<Box className={classes.buttonsWrapper}>
						<Box>
							{/* <BriaButton
								className={classes.btnStyle}
								buttonType={BUTTONTYPES.TEXTBUTTON}
								onClick={onCancel}
							>
								Cancel
							</BriaButton> */}
						</Box>
						<Flex passedClasses={[classes.rightBtns]}>
							<BriaButton
								className={classes.btnStyle}
								buttonType={BUTTONTYPES.PRIMARY}
								onClick={onCancel}
							>
								Cancel
							</BriaButton>
							<BriaButton
								className={classes.btnStyle}
								buttonType={BUTTONTYPES.OUTLINED}
								onClick={onSave}
							>
								Yes
							</BriaButton>
						</Flex>
					</Box>
				</Box>
			</Dialog>
		</>
	);
}

const useStyles = makeStyles(() => ({
	promtWrapper: {
		padding: "1.5rem",
		maxWidth: 650,
	},
	beforeYouGoText: {
		fontWeight: 700,
	},
	messageText: {
		fontSize: "0.9rem",
		margin: "1rem 0",
	},
	paper: {
		borderRadius: "10px !important",
	},
	buttonsWrapper: {
		marginTop: "1.5rem",
		display: "flex",
		gap: "2rem",
		justifyContent: "space-between",
		marginLeft: "auto",
	},
	rightBtns: {
		flex: 0.7,
	},
	btnStyle: {
		flex: 1,
	},
}));
