import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GENERAL_ORG_ID } from "../../../Constants";
import { IframeApplyChangePostMessageTypes, IframePostMessageTypes } from "../../../Constants/IframeConstants";
import Context from "../../../Context/Context";
import { sendPostMessage } from "../../../Helpers/iframe";
import { useGeneratedMoodImgs } from "../../../hooks/useGeneratedMoodImgs";
import Analytics, { ANALYTICS_EVENTS } from "../../../Models/Analytics";
import { ImageEditorContext } from "../../../Pages/Tool/ImageEditor";
import BriaAPI, { ApiCall } from "../../../sdk/resources/briaAPI";
import { ApiActions } from "../../../sdk/resources/briaAPIConstants";
import { fromHexToColor } from "./data/colors";
import { defaultSelectedColor, defaultSlidersState, directStateUpdate, getStateUpdate } from "./data/sliders";
import Sliders from "./Sliders/Sliders";
import Suggestions from "./Suggestions/Suggestions";
import { ShouldClearWatermark } from "../../../Helpers/clearWatermark";

interface ParamTypes {
	visual_hash: string;
}

export const MoodContext = createContext({
	suggestions: [],
	suggestionsLoading: false,
	setSuggestions: (suggestions: any[]) => {},
	handleSliderStateChange: (key: string, value: any, passedMainKey?: string, passedState?: any) => {},
	setSelectedImageUrl: (newUrl: string) => {},
	slidersGeneralState: {},
	directChangespdate: (newChanges: any, passedState: any = {}) => {},
	setSelectedColor: (color: string) => {},
	selectedColor: "",
	setSlidersGeneralState: (state: any) => {},
});

interface IProps {
	isFineTuningTab: boolean;
}

export default function Mood(props: IProps) {
	const { visual_hash } = useParams<ParamTypes>();
	const classes = useStyles();

	const { state } = useLocation();
	const { apiCallsMap: passedApiCallsMap } =
		(state as {
			apiCallsMap: Map<string, ApiCall>;
		}) || {};

	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [applyLoading, setApplyLoading] = useState<boolean>(false);

	const [hasChange, setHasChange] = useState(false);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const {
		setGeneralLoading,
		setSelectedImageUrl,
		selectedImageUrl: imgUrl,
		moodState,
		undoRedoSetter,
		selectedImageUrl,
		updateResetActions,
		setOperationPlayGroundError,
	} = useContext(ImageEditorContext);
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [processedMoods, loading] = useGeneratedMoodImgs(visual_hash, selectedImageUrl);

	const { slidersGeneralState, setSlidersGeneralState, selectedColor, setSelectedColor, moodInstance } = moodState;

	const clientInstance = moodInstance as any;

	const isInstanceReseted = useMemo(() => !(moodInstance as any)?.apiCallsMap?.size, [moodInstance]);

	useEffect(() => {
		updateResetActions(() => {
			setSlidersGeneralState(defaultSlidersState);
		});
	}, []);

	useEffect(() => {
		(async () => {
			if (passedApiCallsMap) {
				if (passedApiCallsMap.has(ApiActions.APPLY_MOOD)) {
					const appliedMoodValue = passedApiCallsMap.get(ApiActions.APPLY_MOOD) as ApiCall;

					const {
						input: { changes: moodParameters },
					} = appliedMoodValue;
					setSlidersGeneralState(moodParameters);
				}
			}
		})();
	}, [passedApiCallsMap]);

	useEffect(() => {
		if (!isInstanceReseted) return;
		setSlidersGeneralState(defaultSlidersState);
		setSelectedColor(defaultSelectedColor);
	}, [isInstanceReseted]);

	const handleColorChange = (newState: any) => {
		undoRedoSetter({
			undo: () => {
				setSlidersGeneralState(slidersGeneralState);
			},
			redo: () => {
				setSlidersGeneralState(newState);
			},
		});
		setSlidersGeneralState(newState);
	};

	const handleSliderStateChange = async (key: string, value: any, passedMainKey?: string, passedState?: any) => {
		try {
			setHasChange(true);
			const stateUpdate: any = passedState || getStateUpdate(key, value, slidersGeneralState, passedMainKey);
			if (!props.isFineTuningTab) return;

			const prevInstance = clientInstance.clone();
			const newState = { ...slidersGeneralState, ...stateUpdate };
			if (key === "color") {
				handleColorChange(newState);
				return;
			}

			const newImageUrl: string = (await handleApplyCall(newState)) || "";
			const newInstance = clientInstance.clone();

			undoRedoSetter({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(imgUrl);
					setSlidersGeneralState(slidersGeneralState);
				},
				redo: () => {
					BriaAPI.setInstance(newInstance);
					setSelectedImageUrl(newImageUrl);
					setSlidersGeneralState(newState);
				},
			});

			setSelectedImageUrl(newImageUrl);
			setSlidersGeneralState(newState);
		} catch (error: any) {
		} finally {
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.BRAND_MOOD_FINE_TUNING, {
				source: "",
				changeName: "Brand_mood_fine_tuning",
				value: 0,
			});
		}
	};

	const directChangespdate = (newChanges = {}, passedState: any = {}) => {
		// on thumbnail click
		const { imageRes: newImage, sid } = passedState;
		const prevInstance = clientInstance.clone();
		fakeUpdateCall(newImage, sid, newChanges);
		setHasChange(true);
		const newState = directStateUpdate(newChanges);
		const newInstance = clientInstance.clone();
		undoRedoSetter({
			undo: () => {
				BriaAPI.setInstance(prevInstance);
				setSelectedImageUrl(imgUrl);
				setSlidersGeneralState(slidersGeneralState);
			},
			redo: () => {
				BriaAPI.setInstance(newInstance);
				setSelectedImageUrl(newImage);
				setSlidersGeneralState(newState);
			},
		});
		setSelectedImageUrl(newImage);
		setSlidersGeneralState(newState);
	};

	const contextState = {
		selectedColor,
		setSelectedColor,
		handleSliderStateChange,
		slidersGeneralState,
		suggestions: processedMoods,
		suggestionsLoading: loading,
		setSuggestions,
		directChangespdate,
		setSelectedImageUrl,
		setSlidersGeneralState,
	};

	useEffect(() => {
		setGeneralLoading(applyLoading);
	}, [applyLoading]);

	const fakeUpdateCall = (newImage: string, sid: string, passedState?: any) => {
		(moodInstance as any).callApi(
			ApiActions.APPLY_MOOD,
			{
				changes: passedState || slidersGeneralState,
				selectedImageUrl: newImage,
				sid,
			},
			true,
			organizationUid,
			true,
			true
		);
	};

	const getCurrentAPIcalls = () => clientInstance.getApiCallsMap();

	const handleApplyCall = async (passedState = {}): Promise<string> => {
		setApplyLoading(true);
		return new Promise((resolve, rej) => {
			(moodInstance as any)
				.callApi(
					ApiActions.APPLY_MOOD,
					{
						changes: passedState,
					},
					shouldClearWatermark(),
					organizationUid
				)
				.then((res: any = {}) => {
					const {
						data: { image_res },
					} = res || { data: {} };
					sendPostMessage(
						IframePostMessageTypes.ApplyChange,
						{
							type: IframeApplyChangePostMessageTypes.Mood,
							action: passedState,
						},
						visual_hash,
						context.iframeConfig
					);
					resolve(image_res);
				})
				.catch((err: any) => {
					setOperationPlayGroundError(true);
					rej(err);
				})
				.finally(() => {
					setApplyLoading(false);
				});
		});
	};

	useEffect(() => {
		handleSliderStateChange("color", fromHexToColor(selectedColor));
	}, [selectedColor]);

	return (
		<MoodContext.Provider value={contextState as any}>
			{props.isFineTuningTab ? (
				<Sliders />
			) : (
				<Box className={classes.suggestionsWrapper}>
					<Suggestions passedSuggestion={processedMoods as any[]} passedLoading={loading as boolean} />
				</Box>
			)}
		</MoodContext.Provider>
	);
}

const useStyles = makeStyles(() => ({
	suggestionsWrapper: {
		maxHeight: "70vh",
		overflow: "auto",
	},
}));
