import React, { useState, useEffect, useContext } from "react";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import BrandImg from "./BrandImg/BrandImg";
import ImgTools from "./ImgTools/ImgTools";
import { useMultiPromise } from "../../../../../hooks/useMultiPromises";
import { Theme } from "@material-ui/core/styles";

const createImgUrl = (hash: string) =>
	`https://images.bria.ai/images_resized/${hash}.jpeg`;

// TODO: delete
export const allImgsHashes = [
	"d3699c2b7c98079c",
	"e4e1c6419e15e93e",
	"927b0de6ec38e198",
	"8497cd92cca152ef",
	"d69555c5073ee309",
	"84875b6864a6787f",
	"e0a794d81966676d",
	"aab136f6c5d61498",
	"f2a74c9ece8819cc",
];

export interface GridImgProps {
	src: string;
	original?: string;
	vhash: string;
}

// TODO: delete
export const imgArray: GridImgProps[] = allImgsHashes.map((hash) => ({
	src: createImgUrl(hash),
	original: createImgUrl(hash),
	vhash: hash,
}));

export const mainImgInfo: GridImgProps = {
	src: createImgUrl("d3699c2b7c98079c"),
	vhash: "d3699c2b7c98079c",
	original: createImgUrl("d3699c2b7c98079c"),
};

export const SELECTED_IMG_INFO = "SELECTED_IMG_INFO";

export default function ImgViewer(props: any) {
	const {
		imageSrc,
		hideImgTools,
		parentLoading,
		onClick,
		enableCompareMode,
		isBrandPage = false,
		isHomePage = false,
	} = props;
	const classes = useStyles({ isBrandPage, isHomePage });

	const [compareMode, setCompareMode] = useState(
		enableCompareMode ? enableCompareMode : false
	);
	const [isGrid, setIsGrid] = useState(false);

	const [applyMoodToImgs, imgsAfterMoodApply = [], loading] =
		useMultiPromise();

	const imgWrapperClass = clsx({
		imgGridWrapper: isGrid,
		singleImgWrapper: !isGrid,
	});

	const toggleGridMode = () => setIsGrid(!isGrid);
	const toggleCompareMode = () => setCompareMode(!compareMode);

	return (
		<Box className={classes.wrapper}>
			<Box className={(classes as any)?.[imgWrapperClass]}>
				<BrandImg
					isGrid={isGrid}
					isCompareMode={compareMode && !isGrid}
					onClick={onClick}
					className={classes.singleImgClass}
					src={imageSrc}
					original={mainImgInfo.src}
					hash={""}
					loading={loading || parentLoading}
					isBrandPage={isBrandPage}
				/>
				{!hideImgTools && (
					<ImgTools
						isGrid={isGrid}
						toggleCompareMode={toggleCompareMode}
						toggleGridMode={toggleGridMode}
						classes={classes}
						hideGrid={true}
						compareMode={compareMode}
					/>
				)}
			</Box>
		</Box>
	);
}

interface StyleProps {
	isBrandPage: boolean;
	isHomePage: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	wrapper: {
		width: "100%",
		display: "flex",
		position: "relative",
	},
	flex: {
		display: "flex",
	},
	gridToggleIcon: (props) => ({
		position: "absolute",
		right: 0,
		bottom: props.isBrandPage ? "-30px" : "-40px",
		cursor: "pointer",
		display: "flex",
		gap: 20,
		alignItems: "center",
	}),
	imgGridWrapper: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr ",
		gap: 5,
		position: "relative",
		width: "100%",
		margin: "auto",
	},
	singleImgWrapper: (props) => ({
		width: props.isHomePage ? "100%" : "90%",
		margin: "auto",
		position: "relative",
	}),
	wholeWrapper: {
		display: "flex",
		flex: 1,
	},
	stepperContainer: {
		flex: 1,
		backgroundColor: "#fff",
	},
	imgViwerSide: {
		flex: 3,
		backgroundColor: "#f8f8f9",
	},
	singleImgClass: {
		width: "100%",
		height: "100%",
		borderRadius: 5,
		cursor: "pointer",
		display: "flex",
		margin: "auto",
		objectFit: "cover",
	},
}));
