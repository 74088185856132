import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import SkeletonWrapper from "../../../Common/SkeketonWrapper/SkeketonWrapper";
import { MoodContext } from "../Mood";
import Suggestion, { ISuggestion } from "./Suggestion/Suggestion";

interface renderProps {
	children?: ({
		suggestions,
		loading,
	}: {
		suggestions: any[];
		loading: boolean;
	}) => JSX.Element;
	passedImg?: string;
	passedLoading?: boolean;
	passedHash?: string;
	passedSuggestion?: ISuggestion[];
	onSuggestionFetched?: (suggestions: ISuggestion[]) => void;
}

export default function Suggestions({
	children,
	passedImg,
	passedLoading = false,
	passedHash,
	passedSuggestion,
	onSuggestionFetched,
}: renderProps) {
	const { setSuggestions, suggestions } = useContext(MoodContext);
	const [localSuggestions, setLocalSuggestions] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!passedSuggestion?.length) return;
		setLocalSuggestions(passedSuggestion);
	}, [passedSuggestion?.length]);

	return (
		<>
			{!children ? (
				<SkeletonWrapper
					loading={loading || passedLoading}
					length={3}
					skHeight={220}
				>
					<>
						{localSuggestions.map((suggestion: ISuggestion) => (
							<Suggestion
								key={suggestion.moodId}
								{...suggestion}
							/>
						))}
					</>
				</SkeletonWrapper>
			) : (
				<>{children({ suggestions: localSuggestions, loading })}</>
			)}
		</>
	);
}
