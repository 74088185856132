import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { RefObject, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../../assets/bria-logo-white.svg";
import { ReactComponent as Logo } from "../../assets/bria-logo.svg";

import RouterConstants from "../../Constants/RouterConstants";
import Context from "../../Context/Context";
import { IsIframe } from "../../Helpers/iframe";
import { IsGuestUser } from "../../Helpers/isGuest";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import { SystemViewTypes } from "../../Models/SystemView";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import LinksConst from "../../Constants/LinksConst";
import Link from "@material-ui/core/Link";
import { PagesEnum } from "../../Models/ToolsConfig";

interface StyleProps {
	isGallery: boolean;
	isImageEditor: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	actionsContainer: {
		flexGrow: 1,
	},
	appBar: (props) => ({
		background: theme.palette.background.paper,
		display: "flex",
		alignItems: "center",
		height: 100,
		justifyContent: "center",
		borderBottom: `1px solid #E9ECEF`,
		paddingInline: "2.1vw",
		"@media (min-width:1920px)": {
			paddingInline: "100px",
		},
		"&.transparent": {
			background: "transparent",
			height: 100,
		},
		"& .transparentButton": {
			color: "#fff !important",
			borderColor: "#fff",
			backgroundColor: "transparent !important",
			"& path": {
				fill: "#fff",
			},
		},
	}),
	toolbar: {
		paddingInline: 0,
		width: "100%",
		maxWidth: 1872,
		display: "flex",
		justifyContent: "space-between",
		"@media (min-width:1920px)": {
			maxWidth: "100%",
		},
	},
	breadcrumbs: {
		marginLeft: theme.spacing(4.4),
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
	},
	gallery: (props) => ({
		cursor: "pointer",
		marginLeft: theme.spacing(4.4),
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
		},
	}),
	breadcrumbItem: (props) => ({
		color: " #1A0638",
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 600,
		lineHeight: "20px",
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
		},
	}),
	breadcrumbSeparator: {
		marginInline: 7,
	},
	icon: {
		fontSize: 18,
		paddingRight: 4,
		paddingTop: 6,
	},
	iconButton: {
		"&:hover": {
			background: "none",
		},
	},
	videoStatus: {
		color: theme.palette.primary.light,
		fontSize: 14,
		paddingLeft: 6,
	},
	downloadPSDButton: (props) => ({
		width: 156,
		marginLeft: 12,

		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "10vw !important" : "",
			minWidth: 105,
			height: props.isImageEditor ? "2.6vw !important" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1vw !important" : "",
		},
	}),
	downloadButton: (props) => ({
		marginLeft: 16,
		width: 118,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
			width: props.isImageEditor ? "8vw" : "",
			height: props.isImageEditor ? "2.6vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1vw" : "",
		},
	}),
	shareFeedbackButton: (props) => ({
		border: 0,
		margin: "0 2px",
		padding: "9px 16px",
		color: "#1A0638",
		fontWeight: 600,
		borderRadius: 8,
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		lineHeight: "16px",
		height: 45,
		fontSize: theme.typography.pxToRem(16),
		"&:hover": {
			color: "#1A0638",
			background: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			color: "#1A0638",
			background: "#E4DCF0",
			opacity: 1,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
	}),
	headerNavigationContainer: {
		display: "flex",
		marginLeft: 14,
		color: "#1A0638",
		fontSize: 16,
		fontWeight: 600,
		letterSpacing: "0.02em",
		lineHeight: "16px",
	},
	assetsContainer: (props) => ({
		marginLeft: 12,
		cursor: "pointer",
		width: 145,
		height: 40,
		fontSize: 16,
		fontWeight: 600,

		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "8.8vw" : "",
			minWidth: 98,
			height: props.isImageEditor ? "2.6vw" : "",
			marginLeft: props.isImageEditor ? "0.8vw" : "",
		},
	}),
	iframelogo: {
		height: 50,
	},
	iconsStyle: (props) => ({
		"@media (max-width:1360px)": {
			width: !props.isGallery ? "1.5vw !important" : "",
			height: !props.isGallery ? "1.5vw !important" : "",
		},
	}),
	homeContainer: (props) => ({
		display: "flex",
		cursor: "pointer",
		width: 117,
		height: 40,
		paddingLeft: 18,
		paddingRight: 14,
		fontSize: 16,
		fontWeight: 600,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "10vw" : "",
			height: props.isImageEditor ? "2.6vw" : "",
		},
	}),
	upgradeButton: (props) => ({
		textTransform: "unset",
		color: "#1A0638",
		fontWeight: 600,
		padding: "12px 19px 12px 16px",
		marginRight: 12,
		borderRadius: 8,
		fontSize: theme.typography.pxToRem(16),
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		"&:hover": {
			color: "#1A0638",
			background: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			border: 0,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
			marginRight: props.isImageEditor ? "0.625vw" : "",
			padding: props.isImageEditor ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
	}),
	purple: {
		padding: "9px 16px",
		margin: "0 10px",
		fontSize: theme.typography.pxToRem(16),
		height: 45,
		color: theme.palette.primary.dark,
		border: `3px solid ${theme.palette.primary.dark}`,
		filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
		"&:hover, &:focus": {
			color: theme.palette.primary.dark,
			backgroundColor: "#E5E5E5",
			border: `3px solid ${theme.palette.primary.dark}`,
		},
	},
	contactSales: {
		width: 158,
		height: 40,
		fontWeight: 600,
	},
	apiButton: {
		color: "#1A0638 !important",
	},
	uploadButton: (props) => ({
		padding: "10px 12px",
		marginRight: 12,
		color: theme.palette.primary.dark,
		fontWeight: 600,
		borderRadius: 8,
		fontSize: theme.typography.pxToRem(16),
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		"&:hover": {
			color: theme.palette.primary.dark,
			background: "#E7E7E7",
			opacity: 1,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
			marginRight: props.isImageEditor ? "0.625vw" : "",
			padding: props.isImageEditor ? "0.52vw 0.625vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.52vw 0.625vw" : "",
		},
	}),
	plusIcon: {
		paddingTop: theme.spacing(0.25),
	},
}));

interface IProps {
	imageName?: string;
	isImageEditor?: boolean;
	children?: JSX.Element;
	pipelineSettings?: any[]; //ImageEditorData_metaData_settings[];
	showSettings?: boolean;
	handleChangePipelineSettings?: (index: number, isOn: boolean, value: any) => void;
	cameraVideoStatus?: "Progress" | "Ready" | null;
	transparentStyle?: boolean;
	isGallery?: boolean;
	isPricing?: boolean;
	isAccountManagement?: boolean | null;
	isOrganizationManagement?: boolean | null;
	clickedIncreaseResButton?: number | null;
	setIsImageLoading?: (flag: boolean) => void;
	imageUrl?: string;
	isImageLoading?: boolean;
	imageCropped?: any;
	imageRef?: RefObject<HTMLImageElement>;
	isUserHasIncreaseResolutionCredits?: boolean;
	setShowIncreaseResolutionPopUp?: (value: any) => void;
	imageOnLoadError?: boolean;
	downloadSaveLoading?: boolean;
	setDownloadSaveLoading?: (value: any) => void;
	isUncropApplied?: boolean;
}

interface stateProps {
	pageNumber: number;
}

function ToolHeaderMarketing({
	children,
	imageName,
	isImageEditor = false,
	pipelineSettings,
	handleChangePipelineSettings,
	showSettings,
	cameraVideoStatus,
	isGallery = false,
	isPricing = false,
	transparentStyle,
	isAccountManagement = false,
	isOrganizationManagement = false,
	clickedIncreaseResButton,
	imageUrl,
	isImageLoading,
	setIsImageLoading,
	imageCropped,
	imageRef,
	isUserHasIncreaseResolutionCredits,
	setShowIncreaseResolutionPopUp,
	imageOnLoadError,
	downloadSaveLoading,
	setDownloadSaveLoading,
	isUncropApplied,
}: IProps) {
	const history = useHistory();
	const { t } = useTranslation();
	const classes = useStyles({
		isGallery: isGallery || isPricing,
		isImageEditor,
	});
	const { isIframe } = IsIframe();
	const context = useContext(Context);
	const { user, iframeConfig } = context;
	const { state } = useLocation<stateProps>();

	const { isGuestUser } = IsGuestUser();

	const showLogo =
		!context.iframeConfig ||
		(context.iframeConfig &&
			(!context.iframeConfig.whiteLabel ||
				(context.iframeConfig.whiteLabel && context.iframeConfig.customStyle?.logo)));
	const isGalleryAllowed =
		!context.iframeConfig || (context.iframeConfig && context.iframeConfig?.enabledPages?.[PagesEnum.Gallery]);

	return (
		<>
			<AppBar position="sticky" elevation={0} className={clsx(classes.appBar, transparentStyle && "transparent")}>
				<Toolbar className={clsx(classes?.toolbar)}>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<>
							{showLogo && (
								<IconButton
									edge="start"
									aria-label="home page"
									className={classes.iconButton}
									onClick={() => {
										!isIframe() &&
											(context.user?.isAdmin() && context.systemView === SystemViewTypes.Admin
												? history.push(RouterConstants.VDR.path)
												: window.open(`${LinksConst.BRIA_WEBSITE}`, "_self"));
									}}
									style={{
										paddingLeft: 0,
										marginLeft: transparentStyle ? -20 : 0,
									}}
								>
									{isIframe() &&
									context.iframeConfig?.whiteLabel &&
									context.iframeConfig?.customStyle?.logo ? (
										<img
											className={classes.iframelogo}
											src={context.iframeConfig?.customStyle?.logo}
										/>
									) : transparentStyle ? (
										<LogoWhite />
									) : (
										<Logo height={65} />
									)}
								</IconButton>
							)}
							{!isIframe() && (
								<Link
									onClick={() => history.push(RouterConstants.HOME.path)}
									className={classes.gallery}
								>
									{t("home")}
								</Link>
							)}
						</>
					</Box>
					<Box style={{ display: "flex", alignItems: "center", gap: 8 }}>
						{!isIframe() && (
							<>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_ABOUTUS);
										window.open(`${LinksConst.BRIA_WEBSITE}about-bria/`, "_self");
									}}
								>
									About Us
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_TECHNOLOGY);
										window.open(`${LinksConst.BRIA_WEBSITE}technology/`, "_self");
									}}
								>
									Technology
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_MODELS);
										window.open(`${LinksConst.BRIA_WEBSITE}models/`, "_self");
									}}
								>
									Models
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_API);
										window.open(`${LinksConst.BRIA_WEBSITE}api/`, "_self");
									}}
								>
									APIs
								</BriaButton>

								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_FAIR_DIFFUSION);
										window.open(`${LinksConst.BRIA_WEBSITE}fair-diffusion/`, "_self");
									}}
								>
									For Artists
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_TERMS);
										window.open(`${LinksConst.BRIA_WEBSITE}terms-and-conditions/`, "_self");
									}}
								>
									Legal
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									style={{
										backgroundColor: "#e4dcf0",
									}}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_PRICING);
										history.push(RouterConstants.HOME.path);
									}}
								>
									Pricing
								</BriaButton>
								<BriaButton
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_PLAYGROUND);
										history.push(RouterConstants.HOME.path);
									}}
								>
									Playground
								</BriaButton>
								<BriaButton
									className={clsx({
										[classes.purple]: true,
										transparentButton: transparentStyle,
									})}
									buttonType={BUTTONTYPES.TEXTSMALL}
									onClick={() => {
										Analytics.logEvent(ANALYTICS_EVENTS.MARKETING_CONTACT_US);
										window.open(`${LinksConst.BRIA_WEBSITE}contact-us`, "_self");
									}}
								>
									Contact Us
								</BriaButton>
								{/*{isGuestUser() ? (*/}
								{/*	<ShowAt showAlways>*/}
								{/*		<BriaButton*/}
								{/*			className={clsx({*/}
								{/*				[classes.upgradeButton]: true,*/}
								{/*				[classes.purple]: isGuestUser(),*/}

								{/*				transparentButton: transparentStyle,*/}
								{/*			})}*/}
								{/*			buttonType={BUTTONTYPES.TEXTSMALL}*/}
								{/*			onClick={(e) => {*/}
								{/*				context.setAuthDialogState(true);*/}
								{/*				e.currentTarget.blur();*/}
								{/*			}}*/}
								{/*			style={{ height: 40, marginLeft: 16 }}*/}
								{/*		>*/}
								{/*			Contact Us*/}
								{/*		</BriaButton>*/}
								{/*	</ShowAt>*/}
								{/*) : (*/}
								{/*	<UserMenu*/}
								{/*		pipelineSettings={pipelineSettings}*/}
								{/*		handleChangePipelineSettings={handleChangePipelineSettings}*/}
								{/*		showSettings={showSettings}*/}
								{/*	/>*/}
								{/*)}*/}
							</>
						)}
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}

export default ToolHeaderMarketing;
