import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import app from "../Config/Firebase";
import { sendProxyRequest } from "../GraphQL/mutations";
import { ProxyRequest } from "../types/graphql-global-types";
import { WEB_API_URL } from "../GraphQL/client";
import { DefaultContext } from "@apollo/client";
import { getSelectedOrganizationFromLocalStorage } from "../Helpers/localStorage";

export async function BriaWebAxios() {
	const iframeId = window.iframeId;
	const iframeAuth = window.iframeAuth;
	const currentUser = getAuth(app)?.currentUser;
	const authorization: string =
		iframeId && iframeAuth ? iframeAuth : currentUser ? await getIdToken(currentUser) : "";
	const client_uid = currentUser ? currentUser?.uid : localStorage.getItem("client_uid") ?? "";
	const headers: any = {
		Authorization: authorization,
		client_uid: client_uid,
	};
	if (iframeId) {
		headers["Iframe-Id"] = iframeId;
	}
	return axios.create({
		baseURL: WEB_API_URL,
		headers,
	});
}

export async function BriaAxios(is_internal: boolean = false) {
	const currentUser = getAuth(app)?.currentUser;
	const authorization: string = currentUser ? await getIdToken(currentUser) : "";
	const client_uid = currentUser ? currentUser?.uid : localStorage.getItem("client_uid") ?? "";
	let url = is_internal ? process.env.REACT_APP_BRIA_API_INTERNAL : process.env.REACT_APP_BRIA_API_URL;
	return axios.create({
		baseURL: url,
		headers: {
			jwt: authorization,
			client_uid: client_uid,
		},
	});
}

/*
 in case of a get request, pass the params in data field
*/
export async function BackendEngineRequest(
	requestInformation: any,
	feature: string,
	context?: DefaultContext,
	is_internal: boolean = false
) {
	const iframeId = window.iframeId;
	const currentUser = getAuth(app)?.currentUser;
	const authorization: string = currentUser ? await getIdToken(currentUser) : "";
	const client_uid = currentUser ? currentUser?.uid : localStorage.getItem("client_uid") ?? "";
	let url = is_internal ? process.env.REACT_APP_BRIA_API_INTERNAL : process.env.REACT_APP_BRIA_API_URL;
	url && !url.endsWith("/") && (url += "/");
	const headers: any = {
		jwt: authorization,
		client_uid: client_uid,
		org_id: getSelectedOrganizationFromLocalStorage()?.uid,
	};
	if (iframeId) {
		headers.iframe_id = iframeId;
	}

	let proxy_response = await sendProxyRequest(
		{
			id: requestInformation.id ?? Math.floor(Math.random() * 100) + 1,
			method: requestInformation.method,
			url: `${url}${requestInformation.url}`,
			data: requestInformation.data,
			headers: JSON.stringify(headers),
		} as ProxyRequest,
		feature,
		context
	);

	if (proxy_response) {
		proxy_response.data = JSON.parse(proxy_response.data);
		return proxy_response;
	} else {
		return Promise.reject("error");
	}
}

export default BriaAxios;
