import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export const addPropsToChildren = (children: JSX.Element, extraProps = {}) => {
	const childrenWithProps = React.Children.map(children, (child, index) => {
		if (!child) return;
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				...extraProps,
			});
		}
		return child;
	});
	return childrenWithProps;
};

interface IStepper {
	currentStepIndex: number;
	children: any;
}

export default function Stepper({
	currentStepIndex: passedIndex,
	children,
	...rest
}: IStepper) {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	useEffect(() => {
		setCurrentStepIndex(passedIndex);
	}, [passedIndex]);
	const passPropsToChildren = useMemo(() => {
		let _index = 0;
		const childrenWithProps = React.Children.map(
			children,
			(child, index) => {
				if (!child) return;
				if (React.isValidElement(child)) {
					_index += 1;
					return React.cloneElement<any>(child, {
						order: index,
						currentStepIndex,
					});
				}
				return child;
			}
		);
		return childrenWithProps;
	}, [children, currentStepIndex]);

	return <Box width="100%" className="stepper">{passPropsToChildren}</Box>;
}

Stepper.propTypes = {
	currentStepIndex: PropTypes.number,
	children: PropTypes.node.isRequired,
};

Stepper.Step = ({
	children,
	currentStepIndex,
	order: parentOrder,
	passedOrder,
}: any) => {
	const order = passedOrder || parentOrder;
	const show = currentStepIndex === order;
	return <div>{show && children}</div>;
};
