import React, { useState, RefObject, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BriaRadio from "../UI/BriaRadio";
import { Divider, FormControlLabel, RadioGroup } from "@material-ui/core";
import BriaSwitch from "../UI/BriaSwitch";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import ImageFocusPoint, { FocusArea } from "./ImageFocusPoint";
import { CameraVideoActions } from "../../Pages/Tool/useImageEditor";
import clsx from "clsx";

const CAMERA_ACTIONS_CONFIG: { [key: string]: any } = {
	zoom_in: {
		label: "3D Close up in",
		disabledControl: [],
		action: "zoom",
		type: "zoom_in",
	},
	zoom_out: {
		label: "3D Pan out",
		disabledControl: [],
		action: "zoom",
		type: "zoom_out",
	},
	smart_zoom: {
		label: "Smart Zoom",
		disabledControl: ["depth", "focusArea"],
		action: "smart_zoom",
	},
	hover: { label: "Hover", disabledControl: ["depth"], action: "hover" },
};

const videoWidth = 180;
const videoHeight = 134;
interface IProps {
	disabled?: boolean;
	fetchCameraVideo: (actions: CameraVideoActions) => void;
	imageUrl: string;
	imageRef: RefObject<HTMLImageElement>;
	preCameraMovementActionsConfig: { [s: string]: any } | null;
	setPreCameraMovementActionsConfig: React.Dispatch<
		React.SetStateAction<{ [s: string]: any } | null>
	>;
	cameraVideoStatus?: "Progress" | "Ready" | null;
}

const CustomRadioControl = (props: any) => {
	const { label, value } = props;
	const classes = useStyles();

	return (
		<FormControlLabel
			value={value}
			classes={{
				label: classes.formControlLabel,
				root: classes.formControlLabelRoot,
			}}
			control={<BriaRadio />}
			label={label}
		/>
	);
};

const CameraMovementTab: React.FC<IProps> = ({
	disabled,
	fetchCameraVideo,
	imageUrl,
	imageRef,
	preCameraMovementActionsConfig,
	setPreCameraMovementActionsConfig,
	cameraVideoStatus
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const defaultFocusArea = "center_middle";
	let [focusArea, setFocusArea] = useState<FocusArea>(defaultFocusArea);
	let [selectedActionKey, setSelectedActionKey] = useState("zoom_in");
	let [pase, setPase] = useState("medium");
	let [depth, setDepth] = useState("medium");
	let [boomerang_loop, setBoomerangLoop] = useState(false);
	let [disabledControl, setDisabledControl] = useState([] as string[]);
	let [cameraMovementActions, setCameraMovementActions] =
		useState<{ [s: string]: any } | null>(null);

	const handleAnimationChange = (event: any) => {
		if (!event.target.value) {
			return;
		}

		if (event.target.value === "hover" && focusArea === defaultFocusArea) {
			setFocusArea("center_right");
		}

		setSelectedActionKey(event.target.value);
		setDisabledControl(
			CAMERA_ACTIONS_CONFIG[event.target.value].disabledControl
		);
	};

	useEffect(() => {
		/* Restore previously saved camera movement configuration */
		if (!!preCameraMovementActionsConfig && !cameraMovementActions) {
			const [actionKey, actionValue] = Object.entries(
				preCameraMovementActionsConfig
			).filter(([key, value]) => !!value)[0];
			setFocusArea(
				actionValue?.focus_area ??
					actionValue?.end_area ??
					focusArea ??
					defaultFocusArea
			);
			handleAnimationChange({
				target: { value: actionValue?.type ?? actionKey },
			});
			setDepth(actionValue?.video_depth ?? depth);
			setPase(actionValue?.pase ?? pase);
			setBoomerangLoop(actionValue?.boomerang_loop ?? boomerang_loop);
		}
	}, [preCameraMovementActionsConfig]);

	useEffect(() => {
		setCameraMovementActions({
			zoom:
				CAMERA_ACTIONS_CONFIG[selectedActionKey].action === "zoom"
					? {
							type: CAMERA_ACTIONS_CONFIG[selectedActionKey].type,
							focus_area: focusArea,
							video_depth: depth,
							boomerang_loop,
							pase,
					  }
					: undefined,
			hover:
				CAMERA_ACTIONS_CONFIG[selectedActionKey].action === "hover"
					? {
							start_area: "center_middle",
							end_area: focusArea,
							video_depth: depth,
							boomerang_loop,
							pase,
					  }
					: undefined,
			smart_zoom:
				CAMERA_ACTIONS_CONFIG[selectedActionKey].action === "smart_zoom"
					? {
							boomerang_loop,
							pase,
					  }
					: undefined,
		});
	}, [focusArea, depth, pase, boomerang_loop, selectedActionKey]);

	useEffect(() => {
		if (!!cameraMovementActions) {
			setPreCameraMovementActionsConfig(cameraMovementActions);
		}
	}, [cameraMovementActions]);

	return (
		<Box display="flex" flexDirection="column" className={classes.root}>
			<Box className={classes.scrollContainer}>
				<Typography
					variant="subtitle2"
					className={clsx(classes.title, classes.titleMedium)}
					style={{ flex: "none" }}
				>
					{t("selectFocusPoint")}
				</Typography>
				<ImageFocusPoint
					focusArea={focusArea}
					setFocusArea={setFocusArea}
					disabled={disabledControl.includes("focusArea")}
					alwaysActiveArea={
						CAMERA_ACTIONS_CONFIG[selectedActionKey].action ===
						"hover"
							? "center_middle"
							: undefined
					}
				/>
				<Box className={classes.divider}>
					<Typography variant="subtitle2" className={classes.title}>
						{t("cameraMotion")}
					</Typography>
					<RadioGroup
						row
						className={classes.cameraMotionControl}
						value={selectedActionKey}
						onChange={handleAnimationChange}
					>
						{Object.entries(CAMERA_ACTIONS_CONFIG).map(
							([key, value]) => (
								<CustomRadioControl
									value={key}
									label={value.label}
									key={key}
								/>
							)
						)}
					</RadioGroup>
					<FormControlLabel
						value={false}
						classes={{
							label: classes.formControlLabel,
							root: classes.formControlLabelRoot,
						}}
						control={
							<BriaSwitch
								checked={boomerang_loop}
								onChange={(e, v) => setBoomerangLoop(v)}
							/>
						}
						label="Boomerang"
					/>
				</Box>
				<Divider className={classes.divider} />
				<Box
					className={clsx(
						classes.divider,
						disabledControl.includes("pase") &&
							classes.controlDisabled
					)}
				>
					<Typography variant="subtitle2" className={classes.title}>
						{t("pase")}
					</Typography>
					<RadioGroup
						row
						value={pase}
						onChange={(e, v) => setPase(v)}
					>
						<CustomRadioControl value="slow" label="Slow" />
						<CustomRadioControl value="medium" label="Medium" />
						<CustomRadioControl value="fast" label="Fast" />
					</RadioGroup>
				</Box>
				<Divider className={classes.divider} />
				<Box
					className={clsx(
						classes.divider,
						disabledControl.includes("depth") &&
							classes.controlDisabled
					)}
				>
					<Typography variant="subtitle2" className={classes.title}>
						{t("depth")}
					</Typography>
					<RadioGroup
						row
						value={depth}
						onChange={(e, v) => setDepth(v)}
					>
						<CustomRadioControl value="shallow" label="Shallow" />
						<CustomRadioControl value="medium" label="Medium" />
						<CustomRadioControl value="deep" label="Deep" />
					</RadioGroup>
				</Box>
			</Box>
			<BriaButton
				className={classes.button}
				buttonType={BUTTONTYPES.SECONDARY}
				onClick={() =>
					fetchCameraVideo(
						cameraMovementActions as { [s: string]: any }
					)
				}
				disabled={disabled || cameraVideoStatus === "Progress"}
				fullWidth
			>
				{t("createVideo")}
			</BriaButton>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: "calc(100% + 13px)",
		maxHeight: 910,
		overflow: "auto",
		padding: "0 30px 0 12px",
		marginRight: -20,
	},
	scrollContainer: {
		flex: 1,
		minHeight: 0,
		overflow: "auto",
		paddingTop: 10,
	},
	button: {
		marginTop: "calc((70 / 907) * 100%)",
		marginBottom: "calc((70 / 907) * 100%)",
	},
	typo: {
		color: theme.palette.cardTextSecondary.main,
		fontSize: 10,
	},
	titleContainer: {
		justifyContent: "flex-start",
	},
	title: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: "calc((16 / 907) * 100%)",
		position: "relative",
		top: 0,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		color: "#495057",
	},
	titleMedium: {
		fontSize: 16,
		marginBottom: "calc((36 / 907) * 100%)",
	},
	videoContainer: {
		height: videoHeight,
		width: videoWidth,
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		margin: 0,
	},
	imgContainer: {
		// height: videoHeight,
		// width: videoWidth,
		objectFit: "contain",
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		margin: 0,
	},
	formControlLabel: {
		fontSize: 14,
	},
	formControlLabelRoot: {
		marginRight: 9,
		marginLeft: -9,
		flex: 1,
		whiteSpace: "pre",
	},
	divider: {
		marginTop: "calc((50 / 907) * 100%)",
	},
	cameraMotionControl: {
		marginBottom: "calc((20 / 907) * 100%)",
	},
	controlDisabled: {
		transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		opacity: 0.2,
		pointerEvents: "none",
	},
}));

export default CameraMovementTab;
