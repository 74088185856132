import React from "react";

function uploadIcon(width: number, height: number) {
	return (
		<svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="photo">
				<path
					id="Vector"
					d="M18.6247 10.2708H18.6368M5.33301 18.7292L10.1663 13.8958C10.7174 13.3656 11.3425 13.0864 11.9788 13.0864C12.6152 13.0864 13.2403 13.3656 13.7913 13.8958L19.833 19.9375M17.4163 17.5208L18.6247 16.3125C19.1757 15.7822 19.8009 15.5031 20.4372 15.5031C21.0735 15.5031 21.6986 15.7822 22.2497 16.3125L24.6663 18.7292M8.95801 5.4375H21.0413C23.0434 5.4375 24.6663 7.06047 24.6663 9.0625V21.1458C24.6663 23.1479 23.0434 24.7708 21.0413 24.7708H8.95801C6.95598 24.7708 5.33301 23.1479 5.33301 21.1458V9.0625C5.33301 7.06047 6.95598 5.4375 8.95801 5.4375Z"
					stroke="#5B5B5B"
					stroke-opacity="0.5"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
}

export default uploadIcon;
