import { Box, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	getValueByMultiProps,
	upperCaseAtFirstChar,
} from "../../../../../../Helpers/mapper";
import RerenderAt from "../../../../../Common/RerenderAt/RerenderAt";
import Flex from "../../../../../Common/Wrappers/Flex";
import ThumbnailSlider from "../../../../../UI/ThumbnailSlider";
import { BrandContext } from "../../../../Brand/BrandPage/BrandPage";
import {
	colorMixerColors,
	fromColorToHex,
	fromHexToColor,
} from "../../../data/colors";
import { colorSettingsSliders, getSliderValue } from "../../../data/sliders";
import { Color } from "../Color/Color";
import TitleTexts from "../../../../Brand/TitleTexts/TitleTexts";
import { Theme } from "@material-ui/core/styles";
import { CustomMoodSlider } from "../../../../Brand/Moods/MoodSlider/MoodSlider";

interface IColorList {
	selectedColor?: string;
	onColorChange?: (color: string) => void;
	updateState: (value: any) => void;
	passedColorState?: any[];
	onUpdate?: () => void;
	onReset?: () => void;
	moodSliders: any;
	showedSlidersLength: any;
	isBrandPage?: boolean;
}

export default function ColorList({
	onColorChange,
	selectedColor: defaultSelectedColor,
	updateState,
	passedColorState,
	onUpdate,
	moodSliders,
	showedSlidersLength = 1,
	isBrandPage = false,
	onReset,
}: IColorList) {
	const [selectedColorIndex, setSelectedColorIndex] = useState(0);
	const [prevSelectedColors, setPrevSelectedColors] = useState<number[]>([]);
	const [changeCounter, setChangeCounter] = useState(0);

	const reversePassedColorState = () => {
		let result = {};
		if (!passedColorState?.length) return null;
		passedColorState?.forEach(
			({
				color,
				hue = 0,
				luminance = 0,
				saturation = 0,
			}: {
				color: string;
				hue: number;
				luminance: number;
				saturation: number;
			}) => {
				result = {
					...result,
					[fromColorToHex(color?.toLowerCase())]: {
						hue,
						luminance,
						saturation,
					},
				};
			}
		);
		return result;
	};

	const reveresdPassedColorState = reversePassedColorState();

	const [mergedColorsState, setMergedColorsState] = useState(
		reveresdPassedColorState ?? {}
	);
	const classes = useStyles();

	const updateMergedColorState = (
		color: string,
		value: number,
		name: string
	) => {
		const foundByColor = (mergedColorsState as any)?.[color];
		const newValue = { [color]: { ...foundByColor, [name]: value } };
		setMergedColorsState({
			...mergedColorsState,
			[color]: value,
			...newValue,
		});
		onUpdate?.();
		setChangeCounter((c) => c + 1);
	};

	const generatedObjIdFromValues = Object.values(
		Object.values(mergedColorsState)
	);

	const formatMergedColorsState = () => {
		const result: any[] = [];
		Object.entries(mergedColorsState).forEach(([key, value]) => {
			const color = upperCaseAtFirstChar(
				fromHexToColor(key)
			)?.toLowerCase();
			const colorObj = { color, ...(value as {}) };
			result.push(colorObj);
		});
		return result;
	};

	const handleCycleIndex = (colorIndex: number): number => {
		if (colorIndex <= 0) return colorMixerColors.length - 1;
		if (colorIndex > colorMixerColors.length - 1) return 0;
		return colorIndex;
	};

	const getColorMixerSliderValue = (sliderName: string) => {
		const colorMixedSrc =
			getValueByMultiProps(moodSliders as any, [
				"color_mixer",
				"colorMixer",
			]) || [];
		const [colorsSliderInfo] = colorMixedSrc;
		return colorsSliderInfo?.[sliderName] || 0;
	};

	const updatePrevSelectedColor = (colorIndex: number) => {
		if (prevSelectedColors.includes(colorIndex)) return;
		setPrevSelectedColors([...prevSelectedColors, colorIndex]);
	};

	const formattedColorState = formatMergedColorsState();

	useEffect(() => {
		if (formattedColorState.length) {
			updateState({ color_mixer: formattedColorState });
		}
	}, [changeCounter]);

	const getSelectedColor = () => {
		const colorValue = getColorMixerSliderValue("color");
		const colorIndex = colorMixerColors.findIndex(
			(color) => fromHexToColor(color) === colorValue
		);
		return colorIndex > -1 ? colorIndex : 0;
	};

	// Derived Color gredinet, using colorIndex
	const hueColorRange = [
		colorMixerColors[handleCycleIndex(selectedColorIndex - 1)],
		colorMixerColors[selectedColorIndex],
		colorMixerColors[handleCycleIndex(selectedColorIndex + 1)],
	];

	const handleColorChnage = (colorIndex: number) => {
		// updatePrevSelectedColor(colorIndex);
		setSelectedColorIndex(colorIndex);

		// parentStateUpdater(
		// 	"color",
		// 	fromHexToColor(colorMixerColors[colorIndex]),
		// 	"color_mixer"
		// );
	};
	const onHueChange = (value: number, name: string) => {
		// parentStateUpdater("hue", value);
		updateMergedColorState(
			colorMixerColors[selectedColorIndex],
			value,
			name
		);
	};

	const getSliderMultiValue = (name: string) => {
		return getColorMixerSliderValue(name);
	};

	const _mergedColorsState = mergedColorsState as any;

	return (
		<>
			<Box mb="1rem">
				<Box mt={isBrandPage ? "3.5rem" : "5rem"}>
					{!isBrandPage && (
						<TitleTexts
							passedClassName={classes.colorMixerTitle}
							text="Color Mixer"
						/>
					)}
					<Flex style={{ gap: "0.75rem" }}>
						{colorMixerColors.map((color, index) => (
							<Color
								prevSelected={color in mergedColorsState}
								width={20}
								onClick={() => handleColorChnage(index)}
								color={color}
								isSelected={selectedColorIndex === index}
								isBrandPage={isBrandPage}
							/>
						))}
					</Flex>
				</Box>
				<Box mt={!isBrandPage && "2.5rem"}>
					<RerenderAt dep={selectedColorIndex}>
						<>
							{colorSettingsSliders({ hueColorRange })
								.slice(0, showedSlidersLength)
								.map((slider: any) => (
									<Box mt={!isBrandPage ? 4 : 1} width="95%">
										{isBrandPage && (
											<Box
												width="100%"
												display="flex"
												alignItems="left"
												flexDirection="column"
												mb="-10px"
											>
												<Typography
													style={{
														cursor: "pointer",
														width: "fit-content",
														fontSize: "14px",
														fontWeight: 400,
														color: "#2C2738",
														marginTop: "1vh",
													}}
													onClick={onReset}
												>
													{/*Reset*/}
												</Typography>
											</Box>
										)}
										{isBrandPage ? (
											<CustomMoodSlider
												onChange={(
													e: any,
													value: number
												) =>
													onHueChange(
														value,
														slider.name
													)
												}
												min={slider.minValue}
												max={slider.maxValue}
												step={slider.step}
												railBackground={
													slider.trackGrdient
												}
												value={
													_mergedColorsState?.[
														colorMixerColors?.[
															selectedColorIndex
														]
													]?.[slider.name] || 0
												}
												style={{ marginLeft: 0 }}
											/>
										) : (
											<ThumbnailSlider
												noMarks={true}
												renderTopRight={() => <></>}
												handleValueChange={(value) =>
													onHueChange(
														value,
														slider.name
													)
												}
												{...slider}
												value={
													_mergedColorsState?.[
														colorMixerColors?.[
															selectedColorIndex
														]
													]?.[slider.name] || 0
												}
											/>
										)}
									</Box>
								))}
						</>
					</RerenderAt>
				</Box>
			</Box>
		</>
	);
}
const useStyles = makeStyles((theme: Theme) => ({
	customModeSlider: {
		marginTop: 0,
	},
	colorMixerTitle: {
		"& p": {
			textAlign: "left",
			fontSize: "14px",
			fontWeight: 400,
			"&:last-child": {
				marginTop: 10,
			},
		},
	},
}));
