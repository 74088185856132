import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import { ToolConfigTabsTypes } from "../../Models/ToolsConfig";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import Context from "../../Context/Context";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import Mood from "../Tools/Mood/Mood";
import ShowAt from "./Wrappers/ShowAt";
import MarketingMessagingTemplate from "./marketing-message-template";
import EmbeddedLogoTab from "../Tools/Testing/EmbeddedLogoTab";
import { IsIframe } from "../../Helpers/iframe";

const getS3Url = (mp4Name: string) =>
	`https://labs-assets.bria.ai/${mp4Name}.mp4`;

const EMPTY_CONTENT: { [key: string]: EmptyContentProps } = {
	// [ToolConfigTabsTypes.Logo]: {
	// 	paragraph: "Logo is not available for the current image.",
	// 	image: warningIcon,
	// },
};

type EmptyContentProps = {
	paragraph: string;
	image: string;
};

export type MessageProps = {
	smallTitle: string;
	paragraph: string;
	smallParagraph: string;
	image?: string;
	buttonText: string;
	render?: () => JSX.Element;
};

export type MarketingMessagingProps = {
	personalizerKey: string;
};

function MarketingMessaging(props: MarketingMessagingProps) {
	const context = useContext(Context);
	const classes = useStyles();
	const { t } = useTranslation();
	const { isIframe } = IsIframe();

	const PERSONALIZED_MESSAGES: { [key: string]: MessageProps } = {
		[ToolConfigTabsTypes.Mood]: {
			smallTitle: "Mood",
			paragraph:
				"Make any visual fit your brand book guidelines in an instant.",
			smallParagraph: "",
			image: getS3Url("Mood"),
			buttonText: "comingSoon",
			render: () => (
				<ShowAt
					showAlways
					condition={
						!isIframe() ||
						(isIframe() &&
							context.iframeConfig?.enabledFeatures.some(
								(feature: string) =>
									ToolConfigTabsTypes.Mood.toString() ===
									feature
							))
					}
					unAuthedContent={
						<MarketingMessagingTemplate
							{...PERSONALIZED_MESSAGES[ToolConfigTabsTypes.Mood]}
						/>
					}
				>
					<Mood isFineTuningTab={false} />
				</ShowAt>
			),
		},
		[ToolConfigTabsTypes.FineTuning]: {
			smallTitle: "Fine-tuning",
			paragraph:
				"Make any visual fit your brand book guidelines in an instant.",
			smallParagraph: "",
			image: getS3Url("Mood"),
			buttonText: "comingSoon",
			render: () => (
				<ShowAt
					showAlways
					condition={
						!isIframe() ||
						(isIframe() &&
							context.iframeConfig?.enabledFeatures.some(
								(feature: string) =>
									ToolConfigTabsTypes.FineTuning.toString() ===
									feature
							))
					}
					unAuthedContent={
						<MarketingMessagingTemplate
							{...PERSONALIZED_MESSAGES[
								ToolConfigTabsTypes.FineTuning
							]}
						/>
					}
				>
					<Mood isFineTuningTab={true} />
				</ShowAt>
			),
		},
		[ToolConfigTabsTypes.Logo]: {
			smallTitle: "Logo",
			paragraph:
				"Make any visual fit your brand book guidelines in an instant.",
			smallParagraph: "",
			image: getS3Url("Mood"),
			buttonText: "comingSoon",
			render: () => (
				<ShowAt
					showAlways
					unAuthedContent={
						<MarketingMessagingTemplate
							{...PERSONALIZED_MESSAGES[ToolConfigTabsTypes.Logo]}
						/>
					}
				>
					<EmbeddedLogoTab />
				</ShowAt>
			),
		},
		[ToolConfigTabsTypes.CameraMovement]: {
			smallTitle: "Camera",
			paragraph: "Apply camera motion to the still.",
			smallParagraph:
				"Make the still image come to life in our Pro version.",
			image: getS3Url("Camera_motion"),
			buttonText: "interested",
		},
		[ToolConfigTabsTypes.Face]: {
			smallTitle: "Face",
			paragraph: "Apply motion to still faces.",
			smallParagraph:
				"Make the still image come to life in our Pro version.",
			image: getS3Url("Head_motion"),
			buttonText: "interested",
		},
		// [ToolConfigTabsTypes.Blur]: {
		// 	smallTitle: "Blur",
		// 	paragraph: "Modify the background of any image to fit your needs.",
		// 	smallParagraph: "",
		// 	image: getS3Url("BG_change"),
		// 	buttonText: "comingSoon",
		// },
		// [ToolConfigTabsTypes.Paint]: {
		// 	smallTitle: "Paint",
		// 	paragraph: "Paint your background to match your brand’s colors.",
		// 	smallParagraph: "",
		// 	image: getS3Url("bg_color"),
		// 	buttonText: "comingSoon",
		// },
	};

	const messageBasedOnKey = PERSONALIZED_MESSAGES[props.personalizerKey];
	// messageBasedOnKey ? EMPTY_CONTENT[props.personalizerKey] :

	return (
		<>
			{messageBasedOnKey ? (
				<>
					{messageBasedOnKey.render ? (
						messageBasedOnKey.render()
					) : (
						<>
							<Box className={classes.root} alignItems="center">
								<Box height="264px">
									<video
										width="100%"
										height="auto"
										autoPlay={true}
										loop={true}
										preload={"none"}
										muted={true}
										playsInline={true}
									>
										<source
											src={
												PERSONALIZED_MESSAGES[
													props.personalizerKey
												].image
											}
											type="video/mp4"
										></source>
										Your browser does not support the video
										tag.
									</video>
								</Box>
								<Typography
									className={classes.smallTitle}
									variant="body2"
									color="textPrimary"
								>
									{
										PERSONALIZED_MESSAGES[
											props.personalizerKey
										].smallTitle
									}{" "}
								</Typography>

								<Box width="100%" mr={1}>
									<Typography
										className={classes.paragraph}
										variant="body1"
										color="textPrimary"
									>
										{
											PERSONALIZED_MESSAGES[
												props.personalizerKey
											].paragraph
										}{" "}
									</Typography>
								</Box>
								<Box minWidth={35}>
									<Typography
										className={classes.smallParagraph}
										variant="body2"
										color="textPrimary"
									>
										{
											PERSONALIZED_MESSAGES[
												props.personalizerKey
											].smallParagraph
										}{" "}
									</Typography>
								</Box>

								<BriaButton
									buttonType={BUTTONTYPES.SECONDARY}
									className={
										messageBasedOnKey.buttonText ==
										"comingSoon"
											? classes.noCursor
											: ""
									}
									onClick={() => {
										if (
											messageBasedOnKey.buttonText !==
											"comingSoon"
										) {
											context.setDisplayDemoApplicationDialogState(
												true
											);
											Analytics.logEvent(
												ANALYTICS_EVENTS.DEMO_APPLICATION_DIALOG_OPENED,
												{
													eventSource: `${props.personalizerKey} - interested btn click`,
												}
											);
										}
									}}
								>
									{" "}
									{t(messageBasedOnKey.buttonText)}
								</BriaButton>
							</Box>
						</>
					)}
				</>
			) : (
				<>
					{EMPTY_CONTENT[props.personalizerKey] && (
						<Box
							className={classes.root}
							alignItems="center"
							style={{ textAlign: "center" }}
						>
							<img
								src={EMPTY_CONTENT[props.personalizerKey].image}
							/>
							<Box width="100%" mt={9}>
								<Typography
									className={classes.smallParagraph}
									variant="body1"
									color="textPrimary"
								>
									{
										EMPTY_CONTENT[props.personalizerKey]
											.paragraph
									}{" "}
								</Typography>
							</Box>
						</Box>
					)}
				</>
			)}
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: theme.spacing(4.4),
		paddingTop: theme.spacing(1.9),
	},
	smallTitle: {
		padding: theme.spacing(3.75, 0),
		fontWeight: 600,
	},
	paragraph: {
		paddingBottom: theme.spacing(7.5),
		fontSize: 18,
	},
	smallParagraph: {
		paddingBottom: theme.spacing(3.5),
	},
	noCursor: {
		cursor: "inherit",
	},
}));

export default MarketingMessaging;
