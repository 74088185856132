import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import footer from "../../assets/footer.png";
import linkedin from "../../assets/linkedin.svg";
import youtube from "../../assets/youtube.svg";
import LinksConst from "../../Constants/LinksConst";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		backgroundImage: `url(${footer})`,
		backgroundSize: "cover",
	},
	rootGrid: {
		justifyContent: "center",
		color: "white",
		paddingTop: 30,
		fontSize: 20,
		[theme.breakpoints.down("sm")]: {
			padding: "5%",
			fontSize: 16,
			rowGap: 28,
		},
		[theme.breakpoints.up(1921)]: {
			fontSize: 24,
		},
		[theme.breakpoints.up("sm")]: {
			textAlign: "center",
		},
	},
	link: {
		color: "white",
		fontStyle: "normal",
		textDecoration: "none",
	},
	gridLink: {
		width: 52,
		[theme.breakpoints.down("sm")]: {
			width: 30,
		},
	},
	LinkedinGridLink: {
		marginRight: 25,
		[theme.breakpoints.down("sm")]: {
			marginRight: 20,
		},
	},
	linkImg: {
		width: "100%",
	},
}));

const isMobile = window.innerWidth <= 768;

function Footer() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Grid
				container
				className={classes.rootGrid}
				alignItems={isMobile ? "flex-start" : "center"}
				direction={isMobile ? "column-reverse" : "row"}
			>
				<Grid item xs={isMobile ? 12 : 6}>
					@ 2023 Bria Artificial Intelligence. All Rights Reserved.
				</Grid>
				<Grid item xs={isMobile ? 12 : 3}>
					<a
						className={classes.link}
						href={`${LinksConst.BRIA_WEBSITE}terms-and-conditions/`}
						target="_blank"
					>
						Terms of Service
					</a>{" "}
					|{" "}
					<a
						className={classes.link}
						href={`${LinksConst.BRIA_WEBSITE}privacy-policy/`}
						target="_blank"
					>
						Privacy Policy
					</a>
				</Grid>
				<Grid item xs={isMobile ? 12 : 3}>
					<Grid
						container
						alignItems="baseline"
						justifyContent="center"
					>
						<Grid
							item
							className={`${classes.gridLink} ${classes.LinkedinGridLink}`}
						>
							<a
								href="https://www.linkedin.com/company/briaai/about/"
								target="_blank"
							>
								<img
									src={linkedin}
									className={classes.linkImg}
								/>
							</a>
						</Grid>
						<Grid item className={classes.gridLink}>
							<a
								href="https://www.youtube.com/watch?v=blKESFn__WI"
								target="_blank"
							>
								<img
									src={youtube}
									className={classes.linkImg}
								/>
							</a>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Footer;
