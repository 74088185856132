/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AWSInstanceAction {
  START = "START",
  STOP = "STOP",
}

export enum DBName {
  DATASETS = "DATASETS",
  RESEARCH = "RESEARCH",
  VDR = "VDR",
}

export enum GCPInstanceAction {
  LIST = "LIST",
  START = "START",
  STOP = "STOP",
}

export enum IframeDestinationEnum {
  ORGANIZATION = "ORGANIZATION",
  USER = "USER",
}

export enum IframeStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum PlatformName {
  AWS = "AWS",
  GCP = "GCP",
}

export interface Camp {
  header1?: HeaderObj | null;
  header2?: HeaderObj | null;
  placementsIds?: (string | null)[] | null;
  channelIds?: (string | null)[] | null;
  mainAsset?: any | null;
  sid?: string | null;
  logo?: any | null;
  cta?: any | null;
  colors?: (string | null)[] | null;
  backgroundType?: string | null;
  bgColor?: string | null;
  backGroundDescription?: string | null;
  bgPrompt?: string | null;
  productVisualHash?: string | null;
  templateId?: string | null;
  seed?: string | null;
  bgSeed?: string | null;
}

export interface ColorMixerParametersInput {
  color?: string | null;
  hue?: number | null;
  saturation?: number | null;
  luminance?: number | null;
}

export interface ColorParametersInput {
  vibrance?: number | null;
  saturation?: number | null;
}

export interface EmailInput {
  subject: string;
  body: string;
  toList: (string | null)[];
  sender?: string | null;
  bccList?: (string | null)[] | null;
  attachment?: string | null;
  filename?: string | null;
  isHtml?: boolean | null;
}

export interface HeaderObj {
  text?: string | null;
  color?: string | null;
  font?: string | null;
  size?: string | null;
}

export interface ImageSemantic {
  name: string;
  value: number;
  minName?: string | null;
  maxName?: string | null;
  minValue?: number | null;
  maxValue?: number | null;
  minLayer?: number | null;
  maxLayer?: number | null;
  pca?: number | null;
  reversed?: number | null;
}

export interface LightParametersInput {
  brightness?: number | null;
  contrast?: number | null;
}

export interface MoodInput {
  parameters?: MoodParametersInput | null;
}

export interface MoodParametersInput {
  light?: LightParametersInput | null;
  color?: ColorParametersInput | null;
  colorMixer?: ColorMixerParametersInput[] | null;
}

export interface PipelineSetting {
  name: string;
  description: string;
  isOn: boolean;
  value?: string | null;
}

export interface ProxyRequest {
  id: string;
  url: string;
  method: string;
  headers?: any | null;
  data?: any | null;
}

export interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface WebOperations {
  duration: number;
  operationName: string;
  tags?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
