import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ExclamationIcon from "../../assets/icons/ExclamationIcon";

export type IProps = {
	usageText: string;
};

function IframeUsageMessage(props: IProps) {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box display="flex" flexDirection="row">
				<Box className={clsx(classes.icon)}>
					<ExclamationIcon />
				</Box>
				<Typography className={classes.message}>{props.usageText}</Typography>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	icon: {
		marginRight: "0.25vw",
		marginTop: "0.1vw",
	},
	message: {
		color: "#000",
		backgroundColor: "white",
		width: "fit-content",
		margin: "0 auto",
		padding: "0 10px",
		fontWeight: "bold",
	},
}));

export default IframeUsageMessage;
