import { Typography } from "@material-ui/core";
import { useContext } from "react";
import Context from "../Context/Context";
import BriaConfirmationDialog from "./BriaConfirmationDialog";

export default function HighLoadDialog() {
	const context = useContext(Context);

	return (
		<BriaConfirmationDialog
			title={"We're experiencing exceptionally high demand"}
			description={<Typography style={{ marginBottom: 100 }}>Please try later in few minutes.</Typography>}
			width="600px"
			buttonExtraStyle={{ width: 340, bottom: 30 }}
			buttonText={"Try Later"}
			showCancelButton={false}
			// cancelButtonText={"Try Later"}
			onButtonClick={() => {
				// Analytics.logEvent(
				// 	ANALYTICS_EVENTS.HIGH_LOAD_UPGRADE_BTN_CLICK
				// );
				// window.open(`${RouterConstants.WEB_PLANS.path}`, "_blank");
				context.setdisplayHighLoadDialog(false);
			}}
			onCloseModal={() => {
				context.setdisplayHighLoadDialog(false);
			}}
			shouldOpen={context.displayHighLoadDialog}
		/>
	);
}
