import { createStyles, Theme, withStyles } from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import React from "react";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

interface Props extends SwitchProps {
	classes: Styles;
}

const BriaSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 33,
			height: 16,
			padding: 0,
			margin: 9,
			boxShadow: "0px 12px 10px -12px rgb(0 0 0 / 100%)",
			backgroundColor: "transparent",
			position: "relative",
			borderRadius: 20,
		},
		switchBase: {
			background: "transparent",
			padding: 1,
			"&$checked": {
				transform: "translateY(-50%)",
				left: "unset",
				right: 2,
				color: theme.palette.common.white,
				"& + $track": {
					backgroundColor: theme.palette.primary.main,
					opacity: 1,
					border: "none",
				},
			},
			"&$focusVisible $thumb": {
				color: "#52d869",
				border: "6px solid #fff",
			},
			top: "50%",
			transform: "translateY(-50%)",
			left: 2,
		},
		thumb: {
			width: 11,
			height: 11,
		},
		track: {
			borderRadius: 20,
			backgroundColor: "#C4C4C4",
			opacity: 1,
			transition: theme.transitions.create(["background-color", "border"]),
		},
		checked: {},
		focusVisible: {},
	})
)(({ classes, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

export default BriaSwitch;
