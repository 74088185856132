class LandingPageConstants {
	public static readonly OLD_DEFAULT = {
		firstFoldVideoUrl: "https://labs-assets.bria.ai/QnD.m4v",
		firstFoldVideoPosterUrl: "https://labs-assets.bria.ai/QnD.jpeg",
		firstFoldHeader: "Generate creative<br>images for<br>immediate use",
		firstFoldSubHeader:
			"Integrate Bria’s creativity API and empower your users to create high-quality visuals at scale—today.",
	};
	public static readonly SMART_SEARCH = {
		firstFoldVideoUrl: "https://labs-assets.bria.ai/search_video.m4v",
		firstFoldVideoPosterUrl:
			"https://labs-assets.bria.ai/search_poster.png",
		firstFoldHeader:
			"Integrate a search<br> that thinks like<br> your users",
		firstFoldSubHeader:
			"Help your users always find the image they need - first time, every time - thanks to Bria’s label-free, contextual Smart Search.",
	};
	public static readonly REMOVE_BG = {
		firstFoldVideoUrl: "https://labs-assets.bria.ai/remove%20bg.m4v",
		firstFoldVideoPosterUrl:
			"https://labs-assets.bria.ai/remove_bg_poster.png",
		firstFoldHeader: "Best-in-class image<br> background removal",
		firstFoldSubHeader:
			"Give your users flawless background removal at scale by integrating the Bria API.",
	};
	public static readonly ADD_LOGO = {
		firstFoldVideoUrl: "https://labs-assets.bria.ai/Logo.mp4",
		firstFoldVideoPosterUrl: "https://labs-assets.bria.ai/Logo_poster.png",
		firstFoldHeader: "Seamless logo<br> embedding at scale",
		firstFoldSubHeader:
			"Embed logos naturally onto furniture, walls, clothing and more. Integrate Bria’s API to help your users brand single or multiple visuals in moments.",
	};
	public static readonly DEFAULT = {
		firstFoldVideoUrl: "https://labs-assets.bria.ai/QnD.m4v",
		firstFoldVideoPosterUrl: "https://labs-assets.bria.ai/QnD.jpeg",
		firstFoldHeader:
			"Build your product using our API-integrated Visual Generative AI platform",
		firstFoldSubHeader:
			"BRIA’s API is designed to be predictable and controllable for commercial use, providing users with the ability to generate, control and remove specific elements of their content.",
	};
}

export default LandingPageConstants;
