import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const MaxWhiteIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_20545_95923)">
				<rect x="4" width="28" height="28" rx="14" fill="#F1F1F1" />
			</g>
			<path
				d="M20.6682 8.42188H24.4016M24.4016 8.42188V11.7402M24.4016 8.42188L20.1349 12.2143M11.6016 16.4808V19.7992M11.6016 19.7992H15.3349M11.6016 19.7992L15.8682 16.0067"
				stroke="#495057"
			/>
			<defs>
				<filter
					id="filter0_d_20545_95923"
					x="0"
					y="0"
					width="36"
					height="36"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_20545_95923"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_20545_95923"
						result="shape"
					/>
				</filter>
			</defs>
		</SvgIcon>
	);
};

export default MaxWhiteIcon;
