import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import "firebase/database";
import "firebase/analytics";
import "firebase/performance";
import "firebase/remote-config";
import { isDevelopment } from "./Env";

export const config = {
	apiKey: "AIzaSyAaFpGrDQWREIYlqPG06gn4gg5oB0E58M8",
	authDomain: "auth.bria.ai",
	databaseURL: "https://image-viewer-120cb.firebaseio.com",
	projectId: "image-viewer-120cb",
	storageBucket: "image-viewer-120cb.appspot.com",
	messagingSenderId: "573857372557",
	appId: "1:573857372557:web:3f8218f4d1ef37903a85c4",
	measurementId: "G-S63HD9ZS4V",
};

const app = initializeApp(config);
if (!isDevelopment) getPerformance(app);

export default app;
