import { Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import React from "react";
import TitleTexts from "../../TitleTexts/TitleTexts";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import {
	MOOD_SLIDER_DEFAULT_VALUE,
	MOOD_SLIDER_MAX_VALUE,
	MOOD_SLIDER_MIN_VALUE,
} from "../moodList";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";

export const CustomMoodSlider = withStyles((theme) => ({
	root: {
		margin: "10px 4.3%",
	},
	thumb: {
		height: 18,
		width: 18,
		backgroundColor: "#fff",
		transform: (props: any) =>
			`translateX( ${props.value > 0 ? "-" : ""}50%)`,
		marginTop: -9,
		marginLeft: -9,
		"&:hover": {
			boxShadow:
				"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			"@media (hover: none)": {
				boxShadow:
					"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
			},
		},
		boxShadow:
			"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
		zIndex: 2,
	},
	active: {},
	track: {
		display: (props: any) =>
			props.showTrack && props.value !== MOOD_SLIDER_DEFAULT_VALUE
				? ""
				: "none",
	},
	rail: {
		height: 2,
		borderRadius: 2,
		opacity: 1,
		background: (props: any) =>
			props.railBackground
				? props.railBackground
				: props.value > MOOD_SLIDER_DEFAULT_VALUE
				? "linear-gradient(-90deg, rgba(136, 0, 255, 0.3) 0%, rgba(174, 93, 244, 0.3) 40.48%, rgba(229, 229, 229, 0.3) 100%)"
				: props.value < MOOD_SLIDER_DEFAULT_VALUE
				? "linear-gradient(90deg, rgba(136, 0, 255, 0.3) 0%, rgba(174, 93, 244, 0.3) 40.48%, rgba(229, 229, 229, 0.3) 100%)"
				: "radial-gradient(50% 2205000.04% at 50% 0%, rgba(136, 0, 255, 0.3) 0%, rgba(174, 93, 244, 0.3) 40.48%, rgba(229, 229, 229, 0.3) 100%)",
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
}))((props: SliderProps) => <Slider {...props} />);

export default function MoodSlider(props: any) {
	const { moods, onChange, value, moodsImages, defaultMoodImg, loading } =
		props;
	const classes = useStyles();

	return (
		<Box className={classes.moodRoot}>
			<TitleTexts subText={`${moods[0].name} or ${moods[1].name}?`} />
			<Box display="flex" width="100%" height="11.05vw">
				<Box position="relative" className={classes.imageWrapper}>
					<Box className={classes.animationPosition}>
						<LoadingAnimation loading={loading} />
					</Box>
					<img src={moods[0].imageSource || defaultMoodImg} />
				</Box>
				<Box
					display="flex"
					flex="auto"
					alignItems="center"
					padding="0 6%"
				>
					<Typography
						className={clsx(
							classes.moodName,
							value < MOOD_SLIDER_DEFAULT_VALUE && "selected"
						)}
					>
						{moods[0].name}
					</Typography>
					<CustomMoodSlider
						value={value}
						min={MOOD_SLIDER_MIN_VALUE}
						max={MOOD_SLIDER_MAX_VALUE}
						onChange={onChange}
						step={10}
					/>
					<Typography
						className={clsx(
							classes.moodName,
							value > MOOD_SLIDER_DEFAULT_VALUE && "selected"
						)}
					>
						{moods[1].name}
					</Typography>
				</Box>

				<Box position="relative" className={classes.imageWrapper}>
					<Box className={classes.animationPosition}>
						<LoadingAnimation loading={loading} />
					</Box>
					<img src={moods[1].imageSource || defaultMoodImg} />
				</Box>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	moodRoot: {
		width: "100%",
		textAlign: "center",
		fontSize: 24,
		"& img": {
			width: "19.6vw",
		},
	},
	moodName: {
		color: "#A4A4A4",
		fontSize: 14,
		"&.selected": {
			color: "#8800FF",
			fontWeight: "bold",
		},
	},
	animationPosition: {
		position: "absolute",
		left: "50%",
		top: "50%",
		zIndex: 5,
		transform: "translate(-50%,-50%) scale(0.5)",
	},
	imageWrapper: {
		width: "27%",
		"&:after": {
			content: "''",
			display: "block",
			background:
				"radial-gradient(50% 50% at 50.07% 50%, rgb(162 162 162 / 61%) -20%, rgba(245, 245, 245, 0) 100%)",
			height: "25px",
			width: "66%",
			position: "absolute",
			left: "50%",
			transform: "translate(-50%)",
			bottom: "-4%",
			zIndex: "-1",
		},
	},
}));
