import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
	contentWrapper: {
		width: "85%",
		maxWidth: 1920,
		display: "flex",
		flexDirection: "column",
		margin: "0 auto",
	},
}));

interface IContentWrapper {
	children: React.ReactNode[] | React.ReactNode;
	classes?: any;
	style?: React.CSSProperties;
}

export default function ContentWrapper({
	children,
	classes,
	...rest
}: IContentWrapper) {
	const defaultClasses = useStyles();
	const mergedClasses = [defaultClasses.contentWrapper, classes].join(" ");

	return (
		<Box className={mergedClasses} {...rest}>
			{children}
		</Box>
	);
}
