import { Box, makeStyles, Theme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { ShapeEditor, ImageLayer, wrapShape } from "react-shape-editor";
import Context from "../../../Context/Context";

type IProps = {
	srcImg: any;
};

function ShapeEditingImg({ srcImg }: IProps) {
	const classes = useStyles();
	const context = useContext(Context);

	const [{ vectorHeight, vectorWidth }, setVectorDimensions] = useState({
		vectorHeight: 0,
		vectorWidth: 0,
	});

	const RectShape = wrapShape(({ width, height }) => (
		<rect width={width} height={height} fill="rgba(0,0,255,0.5)" />
	));
	const constrainResize = ({
		originalMovingCorner: { x: originalX, y: originalY },
		movingCorner: { x: newX, y: newY },
		vectorHeight,
		vectorWidth,
	}) => {
		return {
			x: Math.round(Math.min(vectorWidth, Math.max(0, newX))),
			y: Math.round(Math.min(vectorHeight, Math.max(0, newY))),
		};
	};
	const shapes = context.shapeEditingItems.map((rect, index) => {
		return (
			<RectShape
				key={rect.id}
				shapeId={rect.id}
				height={rect.height}
				width={rect.width}
				x={rect.x}
				y={rect.y}
				onChange={(newRect) => {
					const shape = validateShape(
						newRect.x,
						newRect.y,
						newRect.width,
						newRect.height
					);
					context.setShapeEditingItems(shape);
				}}
				onDelete={() => {
					context.setShapeEditingItems([]);
				}}
				constrainResize={constrainResize}
			/>
		);
	});
	const validateShape = (
		x: number,
		y: number,
		width: number,
		height: number
	) => {
		if (x < 0) {
			let negativeX = x;
			x = 0;
			width = width + negativeX;
		}
		if (y < 0) {
			let negativeY = y;
			y = 0;
			height = height + negativeY;
		}
		const rectEndX = x + width;
		const rectEndY = y + height;
		const rectWidth = rectEndX > vectorWidth ? vectorWidth - x : width;
		const rectHeight = rectEndY > vectorHeight ? vectorHeight - y : height;

		return [
			{
				id: `id1`,
				x: x,
				y: y,
				width: rectWidth,
				height: rectHeight,
			},
		];
	};

	useEffect(() => {
		context.setShapeResultedImage(srcImg);
		context.setShapeEditingItems([
			{
				id: `id1`,
				x: 20,
				y: 20,
				width: 300,
				height: 300 / context.embeddedLogoAspectRatio,
			},
		]);
	}, [context.embeddedLogoAspectRatio]);

	return (
		<Box className={classes.root}>
			<ShapeEditor vectorWidth={vectorWidth} vectorHeight={vectorHeight}>
				<ImageLayer
					src={context.shapeResultedImage}
					onLoad={(imageDimensions) => {
						setVectorDimensions({
							vectorWidth: imageDimensions.naturalWidth,
							vectorHeight: imageDimensions.naturalHeight,
						});
					}}
				/>
				{shapes}
			</ShapeEditor>
		</Box>
	);
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	maxDimentionsSide: {
		width: "100%",
		height: "100%",
	},
	root: {
		display: "flex",
		justifyContent: "center",
		margin: "auto",
		width: "100%",
		height: "100%",
		"& .rse-plane-container": {
			width: "100%",
			height: "100%",
			"& image": {
				width: "100%",
				height: "100%",
			},
		},
	},
}));

export default ShapeEditingImg;
