import { CircularProgress, useTheme } from "@material-ui/core";
import CustomizedTooltip from "./Tooltip";

interface IProps {
	key: string;
	tab: string;
}

const CircularLoadingTooltip = (props: IProps) => {
	const theme = useTheme();
	return (
		<CustomizedTooltip
			arrow
			multiLine={true}
			title={
				<>
					<div>Loading {props.tab.toLowerCase()}...</div>
				</>
			}
			placement="right"
		>
			<CircularProgress
				style={{
					width: 12,
					height: 12,
					fontSize: 16,
					position: "relative",
					right: 5,
					color: theme.palette.primary.main,
					marginRight: -12,
				}}
			></CircularProgress>
		</CustomizedTooltip>
	);
};

export default CircularLoadingTooltip;
