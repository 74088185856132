import { Link, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BriaLogo from "../assets/icons/BriaLogo";
import BriaButton, { BUTTONTYPES } from "../Components/UI/BriaButton";
import Footer from "../Components/UI/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import answerClosedImg from "../assets/landing/ACComponent.png";
import answerOpenedImg from "../assets/landing/AOComponent.png";
import questionImg from "../assets/landing/QComponent.png";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";
import { useEffect, useState } from "react";
import InView from "react-intersection-observer";
import ContentWrapper from "../Components/Common/ContentWrapper";
import Flex from "../Components/Common/Wrappers/Flex";
import BriaWhiteIcon from "../assets/icons/BriaWhiteIcon";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import RouterConstants from "../Constants/RouterConstants";

function LandingPage3(props: {
	constants: {
		firstFoldVideoUrl: string;
		firstFoldVideoPosterUrl: string;
		firstFoldHeader: string;
		firstFoldSubHeader: string;
	};
}) {
	const classes = useStyles();
	const isMobile = window.innerWidth <= 768;
	const history = useHistory();
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000,
	};

	const [syncedVideos, setSyncedVideos] = useState<
		{
			src: string;
			alt: string;
			loaded: boolean;
			title: string;
			text: string;
		}[]
	>([
		{
			src: "https://labs-assets.bria.ai/LandingPage/1. Background generator.gif",
			alt: "Background generator",
			loaded: false,
			title: "Background generator:",
			text: "Create the perfect desired background. ",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/2. Presenter.gif",
			alt: "Presenter",
			loaded: false,
			title: "Presenter:",
			text: "Adjust the presenters facial expression, customize appearance and even recast presenters. ",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/3. Object re-generator (Beta).gif",
			alt: "Object re-generator",
			loaded: false,
			title: "Object re-generator (Beta):",
			text: "Generate or remove objects in your images. ",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/4. Brand guardian (Beta).gif",
			alt: "Brand guardian",
			loaded: false,
			title: "Brand guardian (Beta):",
			text: "Follow brand guidelines for consistency at scale. ",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/5. Integrate our webapp into your product. .gif",
			alt: "Integrate our webapp into your product",
			loaded: false,
			title: "Integrate our webapp into your product:",
			text: "Experience all of our capabilities with our pre-made UX. One line integration.",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/6. Generate.gif",
			alt: "Generate",
			loaded: false,
			title: "Generate:",
			text: "Generating creative visuals. No prompt engineering is required. 2048x2048.",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/7 search.gif",
			alt: "search",
			loaded: false,
			title: "Generative search:",
			text: "Find  images quickly with contextual, label-free visual search.",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/8psd.gif",
			alt: "psd",
			loaded: false,
			title: "Delayer and download to PSD: ",
			text: "Delayer your images and download them in PSD format. ",
		},
		{
			src: "https://labs-assets.bria.ai/LandingPage/9 image to text.gif",
			alt: " image to text",
			loaded: false,
			title: "Image to text:",
			text: "Extract the text from an image.",
		},
	]);
	const [videosInView, setVideosInView] = useState<boolean>(false);

	function openSignUpPage() {
		Analytics.logEvent(ANALYTICS_EVENTS.API_LANDING_PAGE_CTA_BUTTON_CLICK);
		window.open("https://meetings-eu1.hubspot.com/jordan-mishel", "_blank");
	}

	function openApiPricingPage() {
		Analytics.logEvent(ANALYTICS_EVENTS.API_LANDING_PAGE_CTA_BUTTON_CLICK);
		window.open(RouterConstants.API_PLANS.path, "_blank");
	}

	function toggleAnswer(e: any, answerId: string) {
		const thisAnswer = document.querySelector<HTMLElement>(`#${answerId}`);
		if (e.target.getAttribute("src") === answerClosedImg) {
			e.target.setAttribute("src", answerOpenedImg);
			if (thisAnswer) thisAnswer.style.display = "block";
		} else {
			e.target.setAttribute("src", answerClosedImg);
			if (thisAnswer) thisAnswer.style.display = "none";
		}
	}

	useEffect(() => {
		if (!isMobile) {
			let allVideosLoaded = true;
			for (let syncedVideo of syncedVideos) {
				if (syncedVideo.loaded === false) {
					allVideosLoaded = false;
					break;
				}
			}
			if (videosInView && allVideosLoaded) {
				const syncedVideosElements: any =
					document.getElementsByClassName("synced-video");
				for (const videoElement of syncedVideosElements) {
					videoElement.play();
				}
			}
		}
	}, [videosInView, syncedVideos]);

	function getVideoElement(index: number) {
		return (
			<Box className={classes.videoElementContainer}>
				<video
					className="synced-video"
					width="100%"
					autoPlay={isMobile}
					loop={true}
					muted={true}
					preload={"auto"}
					playsInline={true}
					onLoadedData={() => {
						syncedVideos[index].loaded = true;
						setSyncedVideos([...syncedVideos]);
					}}
				>
					<source
						src={syncedVideos[index].src}
						type="video/mp4"
					></source>
					<img src="" alt={syncedVideos[index].alt}></img>
					Your browser does not support the video tag.
				</video>
				<BriaWhiteIcon className={classes.briaIcon}></BriaWhiteIcon>
				{/* <img
					src="https://labs-assets.bria.ai/bria_icon_wh.png"
					alt="Bria icon"
				></img> */}
			</Box>
		);
	}

	return (
		<Box className={classes.rootContainer}>
			{!isMobile && (
				<Box
					className={classes.betaSignUpsticky}
					onClick={openSignUpPage}
				>
					<Typography>Book a Demo</Typography>
				</Box>
			)}
			<Box
				className={`${classes.firstContentBlock} ${classes.contentBlock} ${classes.columnFlex}`}
			>
				<ContentWrapper style={{ flex: 1, flexWrap: "wrap" }}>
					<Box className={classes.logoContainer}>
						<BriaLogo className={classes.logo} />
					</Box>
					<Box
						className={`${classes.fullHeightContentBlock} ${classes.firstFullHeightContentBlock}`}
					>
						<Box className={classes.firstContentTitleContainer}>
							<Typography
								className={`${classes.title} ${classes.firstContentTitle}`}
							>
								{parse(props.constants.firstFoldHeader)}
							</Typography>
							<Typography className={classes.description}>
								{parse(props.constants.firstFoldSubHeader)}
							</Typography>
							{!isMobile && (
								<>
									<BriaButton
										buttonType={BUTTONTYPES.PRIMARY}
										className={classes.button}
										onClick={openApiPricingPage}
									>
										Get Free Token Now
									</BriaButton>
									<Box>
										<img
											className={classes.scrollImg}
											src="https://labs-assets.bria.ai/scroll.gif"
										></img>
									</Box>
								</>
							)}
						</Box>
						<Box>
							<video
								className={classes.yellowImg}
								autoPlay={true}
								loop={true}
								preload={"none"}
								muted={true}
								playsInline={true}
								controls={true}
								poster={props.constants.firstFoldVideoPosterUrl}
							>
								<source
									src={props.constants.firstFoldVideoUrl}
									type="video/mp4"
								></source>
								<img
									src={props.constants.firstFoldVideoUrl}
									alt="A video showing what you can do with Bria - remove and add objects, add weather and video effects, customize people’s appearance and expressions, add your branding."
								></img>
								Your browser does not support the video tag.
							</video>
							<Box className={classes.shadow}></Box>
						</Box>
						{isMobile && (
							<Flex>
								<BriaButton
									buttonType={BUTTONTYPES.PRIMARY}
									className={classes.button}
									onClick={openApiPricingPage}
								>
									Get Free Token Now
								</BriaButton>
							</Flex>
						)}
					</Box>
				</ContentWrapper>
			</Box>
			<Box className={`${classes.contentBlock} ${classes.columnFlex}`}>
				<ContentWrapper classes={classes.paddingHorizantal}>
					<Box
						className={`${classes.secondBlockTitle} ${classes.blockTitle}`}
					>
						<Typography>
							Give your users scalable creative superpowers
						</Typography>
					</Box>
					<InView
						as="div"
						onChange={setVideosInView}
						className={classes.secondBlockContent}
					>
						{isMobile ? (
							<>
								<Box className={classes.secondBlockContent}>
									<Slider {...sliderSettings}>
										{syncedVideos.map((gif) => (
											<Box className={"imgBlock"}>
												<Box
													className={
														classes.videoElementContainer
													}
												>
													<img src={gif.src} />
												</Box>

												<Typography
													className={"imgDescription"}
												>
													<Box
														fontWeight="bold"
														display="inline"
													>
														{gif.title}{" "}
													</Box>
													{gif.text}
												</Typography>
											</Box>
										))}
									</Slider>
								</Box>
							</>
						) : (
							syncedVideos.map((gif) => (
								<Box className={"imgBlock"}>
									<Box
										className={
											classes.videoElementContainer
										}
									>
										<img src={gif.src} />
									</Box>

									<Typography className={"imgDescription"}>
										<Box fontWeight="bold" display="inline">
											{gif.title}{" "}
										</Box>
										{gif.text}
									</Typography>
								</Box>
							))
						)}
					</InView>
					{!isMobile && (
						<Flex>
							<Box className={classes.secondBlockButtonContainer}>
								<BriaButton
									className={classes.button}
									buttonType={BUTTONTYPES.PRIMARY}
									onClick={openApiPricingPage}
								>
									Get Free Token Now{" "}
								</BriaButton>
							</Box>
						</Flex>
					)}
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.fullHeightContentBlock} ${classes.contentBlock} ${classes.columnFlex}`}
			>
				<ContentWrapper>
					<Box
						className={`${classes.thirdBlockTitle} ${classes.blockTitle}`}
					>
						<Flex justifyContent="center">
							<Typography>
								Your users will become creators
							</Typography>
						</Flex>
					</Box>
					<Box className={classes.thirdBlockContent}>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3060.png"
									alt="An icon representing a video conversatoin"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Predictable
								</Typography>
								Have confidence that results will match
								requests.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3051.png"
									alt="An icon representing a jigsaw puzzle"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Controllable
								</Typography>
								Make precise changes to images with full control
								over the results.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3049.png"
									alt="An icon representing images"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Scalable
								</Typography>
								Apply changes across infinite numbers of visuals
								with an API call.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/group3058.png"
									alt="An icon representing documents and images"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									High quality
								</Typography>
								Create photo-realistic, high definition images
								and video.
							</Typography>
						</Box>
						<Box className={"imgBlock"}>
							<Box className={"imgBlockContainer"}>
								<img
									src="https://labs-assets.bria.ai/Group_3098.png"
									alt="An icon representing responsibility"
								/>
							</Box>
							<Typography className={"imgDescription"}>
								<Typography className={"imgTitle"}>
									Responsible
								</Typography>
								Use technology with Responsible AI principles
								built in from day one.
							</Typography>
						</Box>
					</Box>
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.fullHeightContentBlock} ${classes.contentBlock} ${classes.fourthBlockContent}`}
			>
				<ContentWrapper>
					<Flex passedClasses={[classes.pricesAreLimitedSection]}>
						<Box
							justifyContent="center"
							alignItems="center"
							display="flex"
							flexDirection="column"
						>
							<Typography className={classes.title}>
								Integrate Bria’s<br></br>creativity API<br></br>
								today
							</Typography>
							<Box className={classes.fourthBlockButtonContainer}>
								<BriaButton
									className={classes.button}
									buttonType={BUTTONTYPES.PRIMARY}
									onClick={openApiPricingPage}
								>
									Get Free Token Now
								</BriaButton>
							</Box>
						</Box>
						<Box>
							<img
								src="https://labs-assets.bria.ai/mac2.png"
								alt="The Bria platform being used on a desktop computer"
							/>
						</Box>
					</Flex>
				</ContentWrapper>
			</Box>
			<Box
				className={`${classes.contentBlock} ${classes.columnFlex} ${classes.fifthContentBlock}`}
			>
				<ContentWrapper>
					<Box
						className={`${classes.fifthBlockTitle} ${classes.blockTitle}`}
					>
						<Typography>
							Got questions? We’ve got answers.
						</Typography>
					</Box>
					<Box className={classes.fifthBlockContent}>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group51.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How do I start using BRIA’s API?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_1");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_1"
									className={classes.answerText}
								>
									You can create your API token on this{" "}
									<a
										onClick={openApiPricingPage}
										href={RouterConstants.API_PLANS.path}
									>
										page
									</a>{" "}
									and continue to the documentation.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What sets BRIA's API apart?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_2");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_2"
									className={classes.answerText}
								>
									We aim for commercial use, which means that
									we take brand elements and controllability
									into consideration. We believe that an image
									is a collection of five elements, and we
									allow you to generate, control, and remove
									each element separately, setting us apart
									from other APIs.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What are the five elements?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_3");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_3"
									className={classes.answerText}
								>
									The five elements are Background, Presenter,
									Objects, Brand, and Copy.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group51.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									I noticed that some features are labeled as
									BETA. How can I access and use them?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_4");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_4"
									className={classes.answerText}
								>
									For inquiries regarding BETA features,
									please contact us.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									Is Bria's API scalable for larger projects?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_5");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_5"
									className={classes.answerText}
								>
									Yes, BRIA's API is designed to be scalable
									for larger projects and can handle high
									volumes of requests and data processing. We
									have partnered with Nvidia for scale and are
									trusted by Getty Images.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group54.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									Can integrate BRIA’s webapp generator with
									my website?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_6");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_6"
									className={classes.answerText}
								>
									Yes, you can integrate it using an iframe
									integration.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group55.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									Can I use it for commercial use?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_7");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_7"
									className={classes.answerText}
								>
									Yes, BRIA's API is designed for commercial
									use, and encourages businesses to use its
									technology to create content.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group52.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									Where has the data come from?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_8");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_8"
									className={classes.answerText}
								>
									BRIA's services are based on numerous
									models. All the training conducted by Bria
									respects copyrights. For specific services,
									Bria uses models trained by others on public
									data. Bria is in the process of replacing
									these models with responsibly sourced models
									it trains in partnership with creative data
									contributors. As a result, Bria will be able
									to offer fully licensed generative AI
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									Is it safe from a legal perspective/
									copyright?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_9");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_9"
									className={classes.answerText}
								>
									BRIA's API is designed to comply with
									copyright laws and other legal requirements,
									and the company takes measures to ensure
									that its users can use the API safely and
									legally.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									What are some potential applications of BRIA
									and how can its Shopify app help enhance
									e-commerce products?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_10");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_10"
									className={classes.answerText}
								>
									With BRIA's background remove and replace{" "}
									<a href="https://apps.shopify.com/bria">
										Shopify app
									</a>
									, Shopify shoowners can make their product
									shine.
								</span>
							</Typography>
						</Box>
						<Box className={classes.fifthContentBlockRow}>
							{!isMobile && (
								<img
									src="https://labs-assets.bria.ai/group53.png"
									className={classes.questionIcon}
									alt="A collection of circles in the Bria brand colors"
								></img>
							)}
							<Typography className={"imgDescription"}>
								{isMobile && (
									<img
										className={classes.questionImg}
										src={questionImg}
									></img>
								)}
								<Typography>
									How can BRIA help enhance e-commerce
									products on platforms such as Shopify, Ebay
									and Amazon?
								</Typography>
								{isMobile && (
									<img
										className={classes.answerImg}
										onClick={(e) => {
											toggleAnswer(e, "answer_9");
										}}
										src={answerClosedImg}
									></img>
								)}
								<span
									id="answer_9"
									className={classes.answerText}
								>
									With BRIA's background remove and replace{" "}
									<Link
										href="https://apps.shopify.com/bria"
										target="_blank"
									>
										Shopify app
									</Link>
									, e-commerce shop owners on platforms such
									as Ebay, Shopify and Amazon can make their
									product shine.
								</span>
							</Typography>
						</Box>
					</Box>
					<Box className={classes.fifthBlockButtonContainer}>
						<Flex justifyContent="center">
							<BriaButton
								className={classes.button}
								buttonType={BUTTONTYPES.PRIMARY}
								onClick={openApiPricingPage}
							>
								Get Free Token Now
							</BriaButton>
						</Flex>
					</Box>
				</ContentWrapper>
			</Box>
			<Box className={classes.footerContainer}>
				<Footer />
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		height: "100%",
	},
	paddingHorizantal: {},
	contentBlock: {
		display: "flex",
		alignItems: "center",
		margin: "0 auto",
		backgroundColor: "white",
		[theme.breakpoints.down(768)]: {
			paddingLeft: 24,
			paddingRight: 24,
		},
		[theme.breakpoints.between(768, 1920)]: {
			// paddingLeft: 130,
			// paddingRight: 130,
		},
	},
	columnFlex: {
		flexDirection: "column",
	},
	firstContentBlock: {
		backgroundImage: "linear-gradient(#EEEEEE, #FFFFFF)",
		height: 1080,
		maxHeight: "100%",
		display: "flex",
		[theme.breakpoints.down(768)]: {
			height: "auto",
			paddingBottom: 139,
			maxHeight: "none",
		},
	},
	pricesAreLimitedSection: {
		justifyContent: "center",
		flexWrap: "wrap",
		gap: theme.spacing(15),
		[theme.breakpoints.down(1140)]: {
			justifyContent: "center",
			gap: theme.spacing(2),
		},
	},
	firstFullHeightContentBlock: {
		[theme.breakpoints.down(768)]: {
			flexWrap: "wrap",
			paddingTop: 57,
		},
	},
	firstContentTitleContainer: {
		[theme.breakpoints.down(768)]: {
			paddingBottom: 85,
		},
	},
	firstContentTitle: {
		[theme.breakpoints.between(768, 1920)]: {
			minWidth: 376,
		},
	},
	fullHeightContentBlock: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		justifyContent: "center",
		gap: "4rem",
		[theme.breakpoints.down(1140)]: {
			gap: "0rem",
		},
		[theme.breakpoints.down(768)]: {
			gap: "2rem",
		},
	},
	logoContainer: {
		width: "100%",
	},
	footerContainer: {
		height: 372,
		"& > .MuiBox-root > .MuiGrid-root": {
			paddingTop: 83,
		},
	},
	logo: {
		height: "100%",
		width: 132,
		marginTop: 17,
		[theme.breakpoints.down(768)]: {
			width: 68,
		},
	},
	title: {
		color: "#5300C9",
		lineHeight: 1.2,
		fontSize: "2.8vw",
		paddingTop: "10%",
		[theme.breakpoints.down(768)]: {
			fontSize: "2rem",
		},
	},
	blockTitle: {
		"& p": {
			fontSize: "2.2rem",
			[theme.breakpoints.down(768)]: {
				textAlign: "left",
				fontSize: "2rem",
			},
		},
	},
	secondBlockTitle: {
		width: "100%",
		color: "#505050",
		textAlign: "center",
		[theme.breakpoints.down(768)]: {
			marginTop: 0,
		},
	},
	secondBlockContent: {
		display: "flex",
		marginTop: 100,
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "center",
		columnGap: "3vw",
		rowGap: 103,
		[theme.breakpoints.down(768)]: {
			marginTop: 58,
			rowGap: 58,
			display: "block",
			textAlign: "center",
		},
		"& .imgBlock": {
			flex: "1 1 29%",
			"& img": {
				width: "100%",
			},
		},
		"& .imgDescription": {
			marginTop: 22,
			color: "#505050",
			[theme.breakpoints.down(768)]: {
				textAlign: "center",
			},
			fontSize: "1.4rem",
			"& .pro": {
				color: "#D80167",
				fontWeight: "bold",
				display: "inline-block",
				fontSize: "1.4rem",
				"&:before": {
					content: '""',
					width: 20,
					height: 20,
					marginRight: 10,
					display: "inline-block",
					background: `url("https://labs-assets.bria.ai/pro_star.png") no-repeat`,
				},
			},
		},
		"& .slick-dots li": {
			margin: "0",
		},
		"& .slick-dots li button": {
			padding: 3,
		},
		"& .slick-dots li.slick-active button:before": {
			color: "#D80067",
			content: '"•"',
			fontSize: 10,
			top: 2,
		},
		"& .slick-dots li button:before": {
			fontSize: 30,
			content: '"◦"',
		},
	},
	secondBlockButtonContainer: {
		marginTop: 112,
		marginBottom: 142,
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.between(768, 1920)]: {
			marginBottom: 70,
		},
	},
	thirdBlockTitle: {
		marginTop: 100,
		color: "#505050",
		[theme.breakpoints.down(768)]: {
			marginTop: 84,
		},
	},
	thirdBlockContent: {
		display: "flex",
		paddingTop: 125,
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "center",
		columnGap: 85,
		rowGap: 99,
		[theme.breakpoints.down(768)]: {
			paddingTop: 86,
			rowGap: 37,
		},
		[theme.breakpoints.between(768, 1920)]: {
			columnGap: 25,
			rowGap: 60,
			paddingTop: 70,
		},
		"& .imgBlock": {
			"& .imgBlockContainer": {
				minHeight: 106,
			},
			textAlign: "center",
			flex: "0 1 29%",
			"& img": {
				maxHeight: 106,
			},
			[theme.breakpoints.down(768)]: {
				textAlign: "left",
				display: "flex",
				flex: "0 1 100%",
				columnGap: 20,
				"& img": {
					width: 53.5,
					height: 44.5,
				},
			},
		},
		"& .imgDescription": {
			"& .imgTitle": {
				fontSize: "1.4rem",
				fontWeight: "bold",
			},
			fontSize: "1.4rem",
			marginTop: 42,
			color: "#505050",
			[theme.breakpoints.down(768)]: {
				marginTop: 0,
			},
		},
	},
	fourthBlockContent: {
		paddingTop: 260,
		paddingBottom: 175,
		justifyContent: "center",
		"& img": {
			marginRight: "-18vw",
			width: "55vw",
			maxWidth: "1000px",
			[theme.breakpoints.down(1140)]: {
				width: "100%",
				marginRight: 0,
				marginLeft: "13vw",
			},
		},
		[theme.breakpoints.down(768)]: {
			paddingTop: 103.5,
			flexWrap: "wrap",
			paddingBottom: 137,
			"& p": {
				textAlign: "center",
			},
			"& img": {
				width: "100%",
			},
		},
		[theme.breakpoints.between(768, 1920)]: {
			paddingTop: 180,
			paddingBottom: 80,
		},
	},
	fourthBlockButtonContainer: {
		width: "100%",
		"& button": {
			width: "100%",
		},
		margin: "3rem auto",
		[theme.breakpoints.down(768)]: {
			textAlign: "center",
			margin: "2rem auto",
		},
	},
	fifthContentBlock: {
		[theme.breakpoints.between(768, 1920)]: {
			// paddingLeft: 275,
			// paddingRight: 275,
		},
	},
	fifthBlockTitle: {
		color: "#505050",
		width: "100%",
	},
	fifthContentBlockRow: {
		display: "flex",
		columnGap: 97,
		width: "100%",
		paddingTop: 77,
		[theme.breakpoints.down(768)]: {
			paddingTop: 69,
		},
		[theme.breakpoints.between(768, 1920)]: {
			columnGap: 60,
			paddingTop: 40,
		},
	},
	questionIcon: {
		minWidth: 114,
	},
	fifthBlockContent: {
		"& img": {
			height: "100%",
			[theme.breakpoints.down(768)]: {
				height: "auto",
				display: "block",
			},
		},
		"& .imgDescription": {
			color: "#505050",
			"& p": {
				fontSize: "1.4rem",
				fontWeight: "bold",
			},
			"& span": {
				fontSize: "1.3rem",
			},
		},
	},
	questionImg: {
		paddingBottom: 45,
	},
	answerImg: {
		paddingTop: 15.8,
		paddingBottom: 50.2,
	},
	answerText: {
		[theme.breakpoints.down(768)]: {
			display: "none",
		},
	},
	fifthBlockButtonContainer: {
		marginTop: 98,
		marginBottom: 222,
		[theme.breakpoints.down(768)]: {
			marginTop: 91.5,
			marginBottom: 111,
		},
	},
	description: {
		maxWidth: "43vw",
		color: "#505050",
		fontSize: "1.1rem",
		paddingTop: 28,
		paddingBottom: 30,
		[theme.breakpoints.down(768)]: {
			paddingTop: 42,
			maxWidth: "none",
			paddingBottom: "3%",
		},
	},
	button: {
		fontSize: "1.3rem",
		borderRadius: 50,
		padding: "6px 5vw",
		boxShadow: "10px 10px 10px 0px #c7d0e0",
		[theme.breakpoints.down(768)]: {
			minWidth: 215,
			margin: "0 auto",
		},
	},
	heroImgContainer: {
		marginTop: "5%",
		"& img": {
			marginLeft: "33.5%",
		},
	},
	signUpLink: {
		color: "#D80167",
		textDecoration: "underline",
		cursor: "pointer",
	},
	betaSignUpsticky: {
		position: "fixed",
		fontSize: 18,
		backgroundColor: "#D80067",
		color: "white",
		padding: "19px 5px",
		right: 0,
		top: 204,
		transform: "scaleX(-1) scaleY(-1)",
		writingMode: "vertical-rl",
		cursor: "pointer",
	},
	yellowImg: {
		width: "45vw",
		maxWidth: "1000px",
		marginBottom: 31,
		border: "white 19px solid",
		borderTop: "white 22px solid",
		borderBottom: "white 77px solid",
		[theme.breakpoints.down(768)]: {
			width: "100%",
			marginBottom: 0,
		},
	},
	shadow: {
		height: 50,
		backgroundImage: "radial-gradient(#CECECE, #FCFCFC 70%)",
		[theme.breakpoints.down(768)]: {
			marginBottom: 26,
			marginTop: 12,
		},
	},
	scrollImg: {
		width: 45,
		paddingTop: 65,
	},
	videoElementContainer: {
		position: "relative",
	},
	briaIcon: {
		position: "absolute",
		bottom: 15,
		right: 18,
		width: 39,
	},
}));

export default LandingPage3;
