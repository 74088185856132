import { makeStyles } from "@material-ui/styles";
import { Box, Button, IconButton, Theme, Tooltip, Typography } from "@material-ui/core";
import ImageIcon from "../../../../assets/icons/ImageIcon";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Campaign, CampaignAsset, convertS3UriToHttpsUrl, getBase64, isS3Uri } from "./utils";
import CircularLoadingTooltip from "../../LoadingTooltip";
import ReplayIcon from "@material-ui/icons/Replay";
import useErrorPopup from "../../../../hooks/useErrorPopup";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { uploadImageViaApi } from "../../../Tools/utilities";
import { useTranslation } from "react-i18next";

type Props = {
	productImage: string;
	selectedBrand: any;
	isUploading: boolean;
	incompleteFormError: boolean;
	handleCampaignChange: (key: keyof Campaign, value: any) => void;
	setIsUploading: (isLoading: boolean) => void;
};

const CampaignAssets = ({
	productImage,
	selectedBrand,
	isUploading,
	incompleteFormError,
	handleCampaignChange,
	setIsUploading,
}: Props) => {
	const { t } = useTranslation();
	const mainObjectInputRef = useRef<HTMLInputElement | null>(null);
	const logoInputRef = useRef<HTMLInputElement | null>(null);
	const ctaInputRef = useRef<HTMLInputElement | null>(null);
	const [campaignAssets, setCampaignAssets] = useState<CampaignAsset>({
		mainObject: productImage,
		logo: "",
		cta: "",
	});
	const [mainObjectFile, setMainObjectFile] = useState<{ file: File; base64_string: string }>();
	const [showRetryButton, setShowRetryButton] = useState(false);
	const isMainObjectIncomplete = showRetryButton || (incompleteFormError && !campaignAssets.mainObject);
	const isLogoIncomplete = incompleteFormError && !campaignAssets.logo;
	const [renewKeyOnDelete, setRenewKeyOnDelete] = useState(0);
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const errorPopup = useErrorPopup();
	const classes = useStyles({
		isMainObjectIncomplete: isMainObjectIncomplete,
		isLogoIncomplete: isLogoIncomplete,
		isTooltipHover: isTooltipHovered,
	});

	useEffect(() => {
		if (
			selectedBrand &&
			selectedBrand.logo &&
			typeof selectedBrand.logo === "string" &&
			isS3Uri(selectedBrand.logo)
		) {
			const httpsUrl = convertS3UriToHttpsUrl(selectedBrand.logo);
			setCampaignAssets((prevAssets) => ({ ...prevAssets, logo: httpsUrl }));
		}
	}, [selectedBrand]);

	const handleAssetUpload = async (fileKey: keyof CampaignAsset, e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		setCampaignAssets((prevAssets) => ({
			...prevAssets,
			[fileKey]: URL.createObjectURL(file),
		}));
		const base64_string = (await getBase64(file)) as string;
		handleCampaignChange(fileKey, { filename: file.name, base64_string });
		if (fileKey === "mainObject") {
			setMainObjectFile({ file, base64_string });
			await updateVisualHash(file);
		}
	};

	async function updateVisualHash(file: File) {
		try {
			setIsUploading(true);
			setShowRetryButton(false);
			const resp = await uploadImageViaApi(file, undefined, true);
			const res = resp.data as any;
			if (res && res.visualId && res.status) {
				handleCampaignChange("productVisualHash", res.visualId);
			}
			setIsUploading(false);
		} catch (err) {
			setIsUploading(false);
			setShowRetryButton(true);
			errorPopup.showErrorPopup(t("failedUploadingTryAgain"));
		}
	}

	function deleteAsset(fileKey: keyof CampaignAsset) {
		setRenewKeyOnDelete(renewKeyOnDelete + 1);
		setCampaignAssets((prevAssets) => ({
			...prevAssets,
			[fileKey]: "",
		}));
		handleCampaignChange(fileKey, { filename: "", base_64_string: "" });
	}

	return (
		<Box className={classes.assetsButtons}>
			{showRetryButton && mainObjectFile && (
				<IconButton className={classes.retryIcon} onClick={() => updateVisualHash(mainObjectFile.file)}>
					<ReplayIcon style={{ width: "15px", height: "15px" }} />
				</IconButton>
			)}
			<input
				key={renewKeyOnDelete}
				ref={mainObjectInputRef}
				onChange={(e) => handleAssetUpload("mainObject", e)}
				accept="image/*"
				type="file"
				style={{ display: "none" }}
			/>

			<Button
				onClick={() => !isTooltipHovered && mainObjectInputRef.current?.click()}
				className={clsx(classes.button, classes.mainObjectButton)}
			>
				{campaignAssets.mainObject ? (
					<>
						<Tooltip
							title={"delete"}
							className={classes.tooltipStyle}
							placement="top"
							onClick={() => {
								if (!isUploading) {
									deleteAsset("mainObject");
									handleCampaignChange("productVisualHash", "");
									setIsTooltipHovered(false);
								}
							}}
							onOpen={() => setIsTooltipHovered(true)}
							onClose={() => setIsTooltipHovered(false)}
						>
							<DeleteIcon />
						</Tooltip>
						<img
							src={campaignAssets.mainObject}
							style={{ filter: isUploading ? "blur(0.5px)" : "none" }}
							className={classes.img}
							alt=""
						/>
						{isUploading && (
							<Box className={classes.loading}>
								<CircularLoadingTooltip key="" tab="" />
							</Box>
						)}
					</>
				) : (
					<>
						<Typography className={classes.requiredLable}>*</Typography>
						<ImageIcon />
					</>
				)}
				<Box className={classes.textButton}>{t("plusBaseImage")}</Box>
			</Button>
			<input
				key={renewKeyOnDelete}
				ref={logoInputRef}
				onChange={(e) => handleAssetUpload("logo", e)}
				accept="image/*"
				type="file"
				style={{ display: "none" }}
			/>
			<Button
				onClick={() => !isTooltipHovered && logoInputRef.current?.click()}
				className={clsx(classes.button, classes.logoButton)}
			>
				<Typography className={classes.requiredLable}>*</Typography>
				{campaignAssets.logo ? (
					<>
						<Tooltip
							title={"delete"}
							className={classes.tooltipStyle}
							placement="top"
							onClick={() => {
								deleteAsset("logo");
								setIsTooltipHovered(false);
							}}
							onOpen={() => setIsTooltipHovered(true)}
							onClose={() => setIsTooltipHovered(false)}
						>
							<DeleteIcon />
						</Tooltip>
						<img src={campaignAssets.logo} className={classes.img} alt="" />
					</>
				) : (
					<ImageIcon />
				)}
				<Box className={classes.textButton}>{t("plusLogo")}</Box>
			</Button>
			<input
				key={renewKeyOnDelete}
				ref={ctaInputRef}
				onChange={(e) => handleAssetUpload("cta", e)}
				accept="image/*"
				type="file"
				style={{ display: "none" }}
			/>
			<Button onClick={() => !isTooltipHovered && ctaInputRef.current?.click()} className={classes.button}>
				{campaignAssets.cta ? (
					<>
						<Tooltip
							title={"delete"}
							className={classes.tooltipStyle}
							placement="top"
							onClick={() => {
								deleteAsset("cta");
								setIsTooltipHovered(false);
							}}
							onOpen={() => setIsTooltipHovered(true)}
							onClose={() => setIsTooltipHovered(false)}
						>
							<DeleteIcon />
						</Tooltip>
						<img src={campaignAssets.cta} className={classes.img} alt="" />
					</>
				) : (
					<ImageIcon />
				)}
				<Box className={classes.textButton}>{t("plusCtaButton")}</Box>
			</Button>
		</Box>
	);
};

interface StyleProps {
	isMainObjectIncomplete: boolean;
	isLogoIncomplete: boolean;
	isTooltipHover: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	assetsButtons: {
		width: "96%",
		display: "flex",
		justifyContent: "space-between",
	},
	button: (props) => ({
		fontWeight: "bold",
		color: theme.palette.primary.main,
		"&:hover": {
			opacity: props.isTooltipHover ? "auto" : 0.5,
		},
		height: "10vh",
		width: "14vh",
		display: "grid",
		border: "2px dashed",
		"& span": {
			height: "100%",
			alignContent: "end",
			justifyItems: "center",
		},
	}),
	mainObjectButton: (props) => ({
		borderColor: props.isMainObjectIncomplete ? theme.palette.error.main : theme.palette.primary.main,
	}),
	logoButton: (props) => ({
		borderColor: props.isLogoIncomplete ? theme.palette.error.main : theme.palette.primary.main,
	}),
	tooltipStyle: {
		position: "absolute",
		right: "6px",
		top: "6px",
		fontSize: "14px",
		borderRadius: "4px",
		color: "initial",
	},
	textButton: {
		textTransform: "initial",
		fontSize: "min(1.5vh, 1.4vw, 14px)",
		marginTop: "5px",
	},
	requiredLable: {
		position: "absolute",
		fontSize: "18px",
		color: "black",
		top: 2,
		right: 9,
	},
	img: {
		backgroundImage: `url(/transparent.jpg)`,
		backgroundSize: "cover",
		maxHeight: "5vh",
		maxWidth: "10vh",
		borderRadius: "10px",
	},
	loading: {
		position: "absolute",
		display: "flex",
		justifyContect: "center",
		alignItems: "center",
	},
	retryIcon: {
		position: "absolute",
		zIndex: 99999,
		left: "110px",
		top: "155px",
		"&:hover": {
			background: "none",
		},
	},
}));
export default CampaignAssets;
