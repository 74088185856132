import { createStyles, TooltipProps } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export interface ICustomizedTooltip extends TooltipProps {
	multiLine?: any;
	place?: string;
	clickFocus?: boolean;
	disable?: boolean;
	arrowColor?: string;
	backgroundColor?: string;
	toolTipStyle?: string;
}

let CUSTOM_TOOLTIP_RANDOM_ID = 0;

const CustomizedTooltip = (props: ICustomizedTooltip) => {
	CUSTOM_TOOLTIP_RANDOM_ID += 1;
	const classes = useStyles(props);
	let tooltipRef = useRef();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.disable && open) ReactTooltip.hide(tooltipRef.current);
	}, [props.disable]);

	return (
		<>
			<>
				{React.cloneElement(props.children, {
					"data-tip": "",
					"data-for": `tooltip-${CUSTOM_TOOLTIP_RANDOM_ID}`,
					"data-event": props.clickFocus ? "click" : undefined,
					onClick: () => {
						if (open) ReactTooltip.hide(tooltipRef.current);
						setOpen(!open);
					},
				})}
			</>
			<ReactTooltip
				id={`tooltip-${CUSTOM_TOOLTIP_RANDOM_ID}`}
				place={props.placement as any}
				className={clsx(props.toolTipStyle === "secondary" ? classes.secondary : classes.primary)}
				arrowColor={props.arrowColor ?? "#495057"}
				backgroundColor={props.backgroundColor ? `${props.backgroundColor} !important` : ""}
				effect="solid"
				globalEventOff={"click"}
				isCapture={true}
				disable={props.disable}
				ref={(ref) => (tooltipRef = ref as any)}
			>
				{props.title}
			</ReactTooltip>
		</>
	);
};

export default CustomizedTooltip;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		primary: {
			backgroundColor: "#495057 !important",
			opacity: "1 !important",
			maxWidth: 240,
			fontWeight: "normal",
			textTransform: "none !important" as any,
			zIndex: "1101 !important" as any,
			fontSize: (props: ICustomizedTooltip) => `${props.multiLine ? 12 : 14}px !important `,
			padding: (props: ICustomizedTooltip) => (props.multiLine ? "12px 18px 20px !important" : ""),
			lineHeight: 1.25,
			"& .title + *": {
				marginTop: 7,
			},
		},
		secondary: {
			backgroundColor: "#FFF !important",
			color: "#5B5B5B !important",
			opacity: "1 !important",
			maxWidth: 240,
			fontWeight: "normal",
			textTransform: "none !important" as any,
			zIndex: "1101 !important" as any,
			fontSize: (props: ICustomizedTooltip) => `${props.multiLine ? 12 : 14}px !important `,
			padding: (props: ICustomizedTooltip) => (props.multiLine ? "12px 18px 20px !important" : ""),
			lineHeight: 1.25,
			"& .title + *": {
				marginTop: 7,
			},
			borderRadius: 8,
		},
	})
);
