import React from "react";
import TitleTexts from "../TitleTexts/TitleTexts";

export default function BrandReady() {
	return (
		<>
			<TitleTexts
				text="You are done"
				subText="Your brand is now ready to apply to images at scale"
			/>
		</>
	);
}
