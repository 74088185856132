import { useContext } from "react";
import Context from "../Context/Context";
import { getAuth } from "firebase/auth";

export const IsGuestUser = () => {
	const context = useContext(Context);

	const isGuestUser = () => {
		return !!!context.user || !!!context.user?.subscription || getAuth().currentUser?.isAnonymous;
	};

	return { isGuestUser };
};
