import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { useEffect } from "react";
import RerenderAt from "../../../../Common/RerenderAt/RerenderAt";
import ThumbnailSlider from "../../../../UI/ThumbnailSlider";
import ColorList from "../../ColorMixer/ColorPicker/ColorList/ColorList";
import { colorMixerColors, fromHexToColor } from "../../data/colors";
import { colorSettingsSliders, getSliderValue } from "../../data/sliders";
import { MoodContext } from "../../Mood";
import { SliderSettingsContext } from "../Sliders";

export default function ColorSettings() {
	const { selectedColor, handleSliderStateChange, slidersGeneralState } =
		useContext(MoodContext);

	const { localSelectedColor } = useContext(SliderSettingsContext);

	const selectedColorIndex: number = colorMixerColors.findIndex(
		(color: string) => color === localSelectedColor
	);

	const handleCycleIndex = (colorIndex: number): number => {
		if (colorIndex <= 0) return colorMixerColors.length - 1;
		if (colorIndex > colorMixerColors.length - 1) return 0;
		return colorIndex;
	};

	const hueColorRange = [
		colorMixerColors[handleCycleIndex(selectedColorIndex - 1)],
		localSelectedColor,
		colorMixerColors[handleCycleIndex(selectedColorIndex + 1)],
	];

	const slidersState = {
		hueColorRange,
		localSelectedColor,
	};

	// color mixer slider value is bit special since it's should be sent to API as color_mixer : [{ ...colorSliders }]
	const getColorSliderValue = (sliderName: string) => {
		const colorMixedSrc = (slidersGeneralState as any)?.color_mixer || [];
		const [colorsSliderInfo] = colorMixedSrc;
		return colorsSliderInfo?.[sliderName] || 0;
	};

	const getSliderMultiValue = (slider: any) => {
		return getColorSliderValue(slider.name);
	};

	return (
		<ColorList
			showedSlidersLength={3}
			passedColorState={(slidersGeneralState as any)?.color_mixer}
			updateState={({ color_mixer }) => {
				setTimeout(() => {
					handleSliderStateChange("", "", "", { color_mixer });
				}, 300);
			}}
			moodSliders={slidersGeneralState}
			onUpdate={() => {}}
		/>
	);
}

const useStyles = makeStyles((theme) => ({
	hueSlider: {
		"&:before": {
			content: "",
			display: "block",
			background:
				"linear-gradient(84deg, rgba(195,57,34,1) 0%, rgba(120,134,190,1) 50%, rgba(253,45,250,1) 100%);",
		},
	},
}));
