import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import Context from "../../Context/Context";
import { getAuth } from "firebase/auth";

export default function useFeedback(feedbackSource: string) {
	const [ratingValue, setRatingValue] = React.useState<number | null>(null);
	const [value, setValue] = React.useState<string>("");
	const context = useContext(Context);

	const dsn =
		feedbackSource === "react-web"
			? "https://84b28882ee0d47dea87581329c5c0bc2@o417868.ingest.sentry.io/5884174"
			: feedbackSource === "web-api"
			? "https://a59754f3c09b44a68e9b7ba7cdecf7de@o417868.ingest.sentry.io/5319533"
			: "";

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.target.value);
	};

	const handleSubmit = async () => {
		const eventId =
			Sentry.lastEventId() ?? Sentry.captureMessage("User feedback");

		const body = new FormData();
		Object.entries({
			name: context.user?.userName ?? "bria",
			email: getAuth().currentUser?.email ?? "bria@bria.com",
			comments: JSON.stringify(
				{
					feedback: value,
					rating:
						ratingValue?.toString() ??
						"No rating for this feedback",
				},
				null,
				"\t"
			),
			event_id: eventId,
		}).map(([k, v]) => body.append(k, v));

		const query = Object.entries({ dsn, eventId })
			.map(([k, v]) => `${k}=${v}`)
			.join("&");

		await fetch(`https://sentry.io/api/embed/error-page/?${query}`, {
			method: "POST",
			body,
		});

		setValue("");
	};

	return {
		ratingValue,
		setRatingValue,
		value,
		setValue,
		handleChange,
		handleSubmit,
	};
}
