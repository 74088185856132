export const DEFAULT_BRUSH = Object.freeze({
	lineJoin: "round",
	lineCap: "round",
	strokeStyle: "#8800FF",
	lineWidth: 60,
	globalCompositeOperation: "source-over",
	globalAlpha: 1,
	brushColorOpacity: 0.5, // here we can change brush color opacity
});
export * from "./defaultBrush";
