import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

export interface IFollowCursorTooltip {
	imageRef: any;
	showObjectsFollowUpTooltip?: boolean;
	position: any;
	place?: string;
	title: string;
}

interface StyleProps {
	showObjectsFollowUpTooltip: boolean | undefined;
}

const FollowCursorTooltip = (props: IFollowCursorTooltip) => {
	const followUpTooltipRef = useRef(null);
	const classes = useStyles({
		showObjectsFollowUpTooltip: props.showObjectsFollowUpTooltip,
	});

	useEffect(() => {
		const tooltipElement = followUpTooltipRef.current;
		if (tooltipElement) {
			tooltipElement.style.left = `${
				props?.position.x - followUpTooltipRef?.current?.getBoundingClientRect().width / 2
			}px`;
			tooltipElement.style.top = `${props?.position.y - 35}px`;
		}
	}, [props.position]);

	return (
		<div className={classes.tooltip} ref={followUpTooltipRef}>
			{props.title}
		</div>
	);
};

export default FollowCursorTooltip;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	tooltip: (props) => ({
		position: "absolute",
		padding: "5px",
		display: props.showObjectsFollowUpTooltip ? "inline-block" : "none",
		pointerEvents: "none",
		backgroundColor: "#FFF !important",
		color: "#5B5B5B !important",
		opacity: "1 !important",
		maxWidth: 240,
		fontWeight: "normal",
		textTransform: "none !important" as any,
		zIndex: "1101 !important" as any,
		lineHeight: 1.25,
		"& .title + *": {
			marginTop: 7,
		},
		borderRadius: 8,
		whiteSpace: "nowrap",
	}),
}));
