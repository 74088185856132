import React from "react";

type ExternalRedirectProps = {
	to: string;
};

class ExternalRedirect extends React.Component<ExternalRedirectProps> {
	componentDidMount() {
		const { to } = this.props;
		window.location.href = to;
	}

	render() {
		return null;
	}
}

export default ExternalRedirect;
