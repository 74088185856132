import React from "react";
import { MslOptions } from "../Components/UI/Header_Elements/MslSwitch";
import { ValidFacesOptions } from "../Components/UI/Header_Elements/ValidFacesSwitch";
import { SystemViewTypes } from "../Models/SystemView";
import AppContextInterface from "./AppContextInterface";
import { SelectedPointInterface } from "../Components/Tools/utilities";
import { getTailoredModels_getTailoredModels } from "../GraphQL/types/getTailoredModels";

interface IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;
}

export class Folder implements IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;

	constructor(path: string, name: string, children: IFolder[], parent: IFolder | null) {
		this.path = path;
		this.name = name;
		this.children = children;
		this.parent = parent;
		this.isExpanded = false;
		this.isChecked = false;
	}
}

const defaultContext: AppContextInterface = {
	displayBriaConfirmationDialog: false,
	setDisplayBriaConfirmationDialogState: (value: boolean) => {},
	displayCancelationPopup: false,
	setDisplayCancelationPopupState: (newDisplayCancelationPopUpState: boolean) => {},
	iframeId: "",
	iframeConfig: null,
	subscriptionConfig: null,
	selectedClientEmails: [],
	orgFonts: [],
	orgTemplates: [],
	campaignName: "",
	currentCampainVhash: "",
	selectedTemplate: "",
	selectedDropdownValue: {},
	selectedChannelValues: [],
	selectedPlacementsValues: [],
	orgChannels: [],
	orgBrands: [],
	orgPlacements: [],
	updateSelectedClientEmails: (newSelectedClientEmails: (string | null)[]) => {},
	initialUploadDate: 0,
	generatedImagesState: [],
	setGeneratedImagesState: () => {},
	updateInitialUploadDate: (newInitialUploadDate: number) => {},
	finalUploadDate: 0,
	updateFinalUploadDate: (newFinalUploadDate: number) => {},
	uploadImagePurpose: "",
	updateUploadImagePurpose: (newUploadImagePurpose: string) => {},
	objectsToRemoveArray: [],
	updateObjectsToRemoveArray: (newObjectsToRemoveArray: string[]) => {},
	updateOrgFonts: (orgFonts: string[]) => {},
	updateOrgTemplates: (orgTemplates: string[]) => {},
	setCampaignName: (campaignName: string) => {},
	setCurrentCampainVhash: (currentCampainVhash: string) => {},
	setSelectedTemplate: (selectedTemplate: string) => {},
	setSelectedDropDownValue: (selectedDropDownValue: {}) => {},
	setSelectedChannelValues: (selectedChannelValues: string[]) => {},
	setSelectedPlacementsValues: (selectedPlacementsValues: string[]) => {},
	setOrgChannels: (orgChannels: []) => {},
	setOrgPlacements: (orgPlacements: []) => {},
	setOrgBrands: (orgBrands: []) => {},
	showBugReportForm: false,
	updateShowBugReportForm: () => {},
	showShowroomScoring: false,
	updateShowShowroomScoring: () => {},
	showLoginForm: true,
	setShowLoginForm: () => {},
	randomize: false,
	updateRandomizeToggle: (newRandomizeToggle: boolean) => {},
	validFacesString: ValidFacesOptions.both,
	updateValidFacesString: (newValidFacesString: ValidFacesOptions) => {},
	mslString: MslOptions.both,
	updateMslString: (newMslString: MslOptions) => {},
	mslToggle: false,
	updateMslToggle: (newMslToggle: boolean) => {},
	curFolder: new Folder("", "Root", [], null),
	updateCurFolder: (newCurFolder: Folder) => {},
	preferences: null,
	changeallConfigPersonalizerSwitchCheck: (switchState: boolean) => {},
	allConfigPersonalizerSwitchCheck: false,
	authenticationWasVerified: false,
	heartbeatRefs: null,
	user: null,
	presentedImagesArray: [],
	updatePresentedImagesArray: (presentedImagesMongoIdArray: string[]) => {},
	updateSelectedText: (newText: string) => {},
	updateCurrExpandedFolders: (newExpandedFolders: string[] | []) => {},
	currExpandedFolders: [],
	selectedText: "",
	imageHeight: 150,
	updateImageHeight: (newImageHeight: number) => {},
	imagesPerPage: 50,
	updateImagesPerPage: (newImagesPerPage: number) => {},
	previouslySelectedLabels: [],
	updateSelectedLabels: (newSelectedLables: string[]) => {},
	previouslySelectedPage: 1,
	updateSelectedPage: (newSelectedPage: number) => {},
	registerViaAuthProvider: (provider: any, viewer?: boolean) => Promise.resolve(false),
	updateUserInfo: (username: string, company?: string, role?: string) => Promise.resolve(),
	setSimilarSearchFlag: () => {},
	similarSearchFlag: false,
	setLoadingBrands: () => {},
	loadingBrands: true,
	setLoadingChannels: () => {},
	loadingChannels: true,
	setLoadingTemplates: () => {},
	loadingTemplates: true,
	setLoadingPlacements: () => {},
	loadingPlacements: true,

	registerWithEmail: (
		username: string,
		email: string,
		password: string,
		company?: string,
		role?: string,
		feedback?: string,
		viewer?: boolean
	) => Promise.resolve(),
	logOut: () => {},
	pageMenuWidth: 72,
	selectedTab: {} as SelectedPointInterface,
	setSelectedTab: (value: SelectedPointInterface) => {},
	displayUpload: false,
	authDialogStep: "Register",
	authDialogSource: "",
	shapeEditingItems: [],
	shapeResultedImage: "",
	displayAuthDialog: false,
	displayFeedbackDialog: false,
	displayDemoApplicationDialog: false,
	setUploadState: () => {},
	setAuthDialogState: () => {},
	setDisplayFeedbackDialogState: () => {},
	setDisplayDemoApplicationDialogState: () => {},
	isTesting: false,
	setIsTesting: () => {},
	systemView: SystemViewTypes.Admin,
	setSystemView: () => {},
	cropConfig: [],
	setCropConfig: () => {},
	allOrganizations: [],
	setAllOrganizations: () => {},
	diverseSearch: false,
	setDiverseSearch: () => {},
	tailoredModels: undefined,
	setTailoredModels: (models: getTailoredModels_getTailoredModels[]) => {},
};

export default React.createContext<AppContextInterface>(defaultContext);
