import { useContext } from "react";
import AppContext from "../../../Context/Context";
import { SystemViewTypes } from "../../../Models/SystemView";

interface IShowAt {
	admin?: boolean;
	viewer?: boolean;
	external?: boolean;
	testing?: boolean;
	showAlways?: boolean;
	condition?: boolean;
	children: JSX.Element;
	unAuthedContent?: JSX.Element;
}

export default function ShowAt({
	admin,
	viewer,
	external,
	testing,
	showAlways,
	children,
	condition = true,
	unAuthedContent,
}: IShowAt) {
	const context = useContext(AppContext);
	const { systemView, user } = context;
	let show = false;
	if (admin && systemView === SystemViewTypes.Admin && user?.isAdmin()) {
		show = true;
	} else if (
		viewer &&
		((user?.isAdmin() && systemView === SystemViewTypes.Viewer) ||
			user?.isViewer())
	) {
		show = true;
	} else if (
		external &&
		((user?.isAdmin() && systemView === SystemViewTypes.External) ||
			user?.isExternal())
	) {
		show = true;
	} else if (
		testing &&
		user?.isAdmin() &&
		systemView === SystemViewTypes.Testing
	) {
		show = true;
	}
	let showElement = showAlways || show; // Based on viewTypes
	showElement = condition && showElement; // Based on extra show conditions

	return <>{showElement ? children : <> {unAuthedContent}</>}</>;
}
