import { CircularProgress } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import BriaLogo from "../assets/icons/BriaLogo";
import Copyright from "../Components/Copyright";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "black",
	},
	title: {
		padding: theme.spacing(1),
	},
	logo: {
		height: 55,
		width: 63,
	},
}));

interface IProps {
	title: string;
	src: string;
	maxWidth?: string;
	addAppBar?: boolean;
	increaseScale?: boolean;
}

function BriaPolicy(props: IProps) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				width="100%"
				height="100%"
				flexDirection="column"
				style={{ backgroundColor: "white" }}
			>
				{(props.addAppBar ?? true) && (
					<AppBar position="fixed" className={classes.root}>
						<Toolbar>
							<BriaLogo className={classes.logo} />
							<Typography className={classes.title}>
								{props.title}
							</Typography>
						</Toolbar>
					</AppBar>
				)}
				<Box
					style={{ display: isLoading ? "flex" : "none" }}
					height="50vh"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
				<iframe
					onLoad={() => {
						setIsLoading(false);
					}}
					style={{
						marginTop: 64,
						display: isLoading ? "none" : "block",
						width: "100%",
						height: "90vh",
						maxWidth: props.maxWidth ? props.maxWidth : "540pt",
						border: 0,
						transform: props.increaseScale
							? "scale(1.35)"
							: "scale(1.0)",
						transformOrigin: props.increaseScale
							? "50% 0%"
							: "center",
					}}
					title={props.title}
					src={props.src}
				></iframe>
				<Box
					padding={2}
					style={{ display: isLoading ? "none" : "block" }}
				>
					<Copyright />
				</Box>
			</Box>
		</>
	);
}

export default BriaPolicy;
