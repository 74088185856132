import { useState } from "react";
import { Rnd } from "react-rnd";

interface IProps {
	text: string;
}

export const TextOnImage = (props: IProps) => {
	const text = props.text;
	const [isFocused, setIsFocused] = useState(false);
	const defaultStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
	const dynamicStyle = (isFocused && { border: "solid 1px black" }) || {};
	return (
		<Rnd
			default={{
				x: 150,
				y: 205,
			}}
			minWidth={0}
			minHeight={0}
			bounds="parent"
			onDrag={(e) => {
				e.stopImmediatePropagation();
			}}
			style={{ ...defaultStyle, ...dynamicStyle }}
			onBlur={() => setIsFocused(false)}
			onFocus={() => setIsFocused(true)}
		>
			{/* <TextField InputProps={{ disableUnderline: true }} id="text-on-image" fullWidth multiline style={{ "overflowWrap": "anywhere" }} /> */}
			<div
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			/>
		</Rnd>
	);
};
