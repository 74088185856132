import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import startupPorgarmImg from "../../assets/startup-program.png";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
const StartupProgramTeaser = () => {
	const { t } = useTranslation("translation", { keyPrefix: "startupProgramTeaser" });
	const classes = useStyles();

	const learnMore = () => {
		window.open("https://pages.bria.ai/the-visual-generative-ai-platform-for-builders-startups-plan", "_blank");
	};
	return (
		<>
			<Box className={classes.banner}>
				<Box className={classes.texts}>
					<Typography className={classes.header}>{t("header")}</Typography>
					<Typography className={classes.subHeader}>{t("subHeader")}</Typography>
					<BriaButton onClick={learnMore} buttonType={BUTTONTYPES.PRIMARYPURPLE}>
						{t("button")}
					</BriaButton>
				</Box>
				<Box className={classes.image} />
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	banner: {
		background: "linear-gradient(275.92deg, #5300C9 -9.78%, #200349 37.68%)",
		height: 500,
		display: "flex",
	},
	texts: {
		color: "white",
		position: "absolute",
		alignSelf: "center",
		maxWidth: 650,
		paddingLeft: "111px",
	},
	header: {
		fontSize: 40,
		fontWeight: 700,
		lineHeight: "49px",
		paddingBottom: "12px",
	},
	subHeader: {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "24px",
		paddingBottom: "28px",
	},
	button: {},
	image: {
		backgroundImage: `url(${startupPorgarmImg})`,
		backgroundSize: "cover",
		width: "100%",
		height: "100%",
	},
}));

export default StartupProgramTeaser;
