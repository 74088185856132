import React, { useContext, useEffect, useState } from "react";
import {
	caluclateThubmnailValue,
	getValueFromNormalizedRangeBaseTen,
	normalizeSliderValueToBaseTen,
} from "../../Helpers/math";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import { ToolsSlider } from "../../Models/ToolsConfig";
import FakeHiddenContent from "../Common/Wrappers/FakeHiddenContent";
import { Box, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { ToolsActionContext } from "./ToolsAction";
import { SelectedPointInterface } from "./utilities";

interface IThumbnail {
	sliders: any[];
	thumbnails_number: any;
	thumbnail_index: any;
	key: string;
}

interface IThumbnailList {
	visualHash: string;
	selectedPoint: SelectedPointInterface | any | null;
	thumbnails: any[];
	isSuggestion: boolean;
	disabled?: boolean;
	personalizerValues: { [key: string]: number };
	ProperThumbnailCard: React.FC<any>;
	originalImage: string;
	thumbnailsWidth: number;
	thumbnailsHeight: number;
	selectedThumbnail: string;
	setSelectedThumbnail: (thumbnail: string) => void;
	onChangePersonalizer: (sliders: ToolsSlider[], key?: string) => void;
	onAddSemanticCombination: any;
	handleResetActions?: () => void;
	mapSliders: (value: number, el: ToolsSlider) => void;
	thumbnailsCount?: number;
	onErrorLoad?: boolean;
}

export default function ThumbnailList({
	visualHash,
	selectedPoint,
	thumbnails,
	isSuggestion,
	personalizerValues,
	ProperThumbnailCard,
	originalImage,
	thumbnailsWidth,
	thumbnailsHeight,
	selectedThumbnail,
	setSelectedThumbnail,
	onChangePersonalizer,
	onAddSemanticCombination,
	handleResetActions,
	disabled,
	mapSliders,
	thumbnailsCount,
	onErrorLoad = false,
}: IThumbnailList) {
	const cachedThumbsKey = "APPEARANCE"; // could also be passed from ThumbnailList props if u wanna use it with other tabs

	const { cachedThumbnails, setCachedThumbnails } = useContext(ToolsActionContext);

	const classes = useStyles();
	const { t } = useTranslation();

	const [fetchedThumbs, setFetchedThumbs] = useState<number>(thumbnailsCount || 8); // fetched does not mean showed to user
	const [showedThumbs, setShowedThumbs] = useState(thumbnailsCount || 4);
	const [selectedGridImage, setSelectedGridImage] = useState<number>(-1);
	const appearanceCachedThumbs = (cachedThumbnails as any)?.[cachedThumbsKey] || [];

	const updateThumbnailInfo = (key: string, url: string) => {
		const newThumbs = thumbnails.map((thumb: any) => {
			if (thumb.key === key) {
				thumb.thumbUrl = url;
				return thumb;
			}
			return thumb;
		});
		if (!newThumbs.length) return;
		setCachedThumbnails(cachedThumbsKey, newThumbs);
	};

	const handleScroll = (e: any) => {
		if (!(showedThumbs >= thumbnails.length - 1)) {
			const scrollAmount = e.target.scrollHeight - e.target.scrollTop;
			const bottom = scrollAmount >= e.target.clientHeight && scrollAmount <= e.target.clientHeight + 100;
			if (bottom) {
				setShowedThumbs((c) => c + 3);
				setFetchedThumbs((c) => c + 3);
			}
		}
	};

	const deletedSavedThumbUrl = () => {
		const afterDelete = appearanceCachedThumbs.map((thumb: any) => {
			thumb.thumbUrl = "";
			return thumb;
		});
		setCachedThumbnails(cachedThumbsKey, afterDelete);
	};

	useEffect(() => {
		if (thumbnailsCount !== undefined) {
			setShowedThumbs(thumbnailsCount);
		}
	}, [thumbnailsCount]);

	const findThumbUrl = (el: any) => {
		const foundInAppearanceThumbnails: any = appearanceCachedThumbs.find((thumb: any) => thumb.key === el.key);
		return foundInAppearanceThumbnails?.thumbUrl;
	};

	return (
		<>
			<Box
				className={classes.root}
				onScroll={handleScroll}
				// style={{ maxHeight: thumbnailsCount ? "65vh" : "" }}
			>
				<Box className={classes.thumbnailsWrapper}>
					{thumbnails
						.slice(0, fetchedThumbs < thumbnails.length ? fetchedThumbs : thumbnails.length)
						.map((el, idx) => {
							let sliders = el.sliders;
							if (!isSuggestion) {
								sliders = sliders.map((slider: any) => {
									return {
										...slider,
										value:
											caluclateThubmnailValue(
												normalizeSliderValueToBaseTen(
													slider.minValue,
													slider.maxValue,
													personalizerValues[slider.id]
												) * slider.reversed,
												el.thumbnails_number ?? 0,
												el.thumbnail_index ?? 0
											) * slider.reversed,
									};
								});
							}
							return (
								<FakeHiddenContent showAt={idx <= showedThumbs}>
									<ProperThumbnailCard
										index={idx}
										uniqueKey={`${visualHash}-${selectedPoint?.id}-${el.key}`}
										thumbnailsWidth={thumbnailsWidth}
										thumbnailsHeight={thumbnailsHeight}
										onErrorLoad={onErrorLoad}
										selectedGridImage={selectedGridImage}
										setSelectedGridImage={setSelectedGridImage}
										key={el.key}
										isSelected={selectedThumbnail === el.key}
										thumbnailImage={
											el.key === "suggestions-original" && originalImage
												? originalImage
												: undefined
										}
										thumbUrl={findThumbUrl(el)}
										personalizerValues={personalizerValues}
										onAddSemanticCombination={async (operationVariables: any) => {
											const validSliders = sliders
												.map((el: any) => {
													const slider: ToolsSlider = el as ToolsSlider;
													return {
														...slider,
														value: getValueFromNormalizedRangeBaseTen(
															slider.minValue || 0,
															slider.maxValue || 0,
															slider.value
														),
													};
												})
												.filter((el: any) => el.id);
											if (!validSliders.length) return "";

											const onAddResult = await onAddSemanticCombination(
												validSliders,
												operationVariables,
												el.key
											);
											updateThumbnailInfo(el.key, onAddResult);

											return onAddResult;
										}}
										thumbnail={el}
										onClick={async () => {
											if (!disabled) {
												setSelectedGridImage(idx);
												await Analytics.logEvent(ANALYTICS_EVENTS.SUGGESTION_CLICKED);
												setSelectedThumbnail(el.key);
												onChangePersonalizer(
													sliders.map((el: any) => {
														const slider: ToolsSlider = el as ToolsSlider;
														return {
															...slider,
															value: getValueFromNormalizedRangeBaseTen(
																slider.minValue,
																slider.maxValue,
																slider.value
															),
														};
													}),
													el.key
												);
											}
										}}
										warrning={sliders.some((el: any) => el.warning)}
										error={sliders.some((el: any) => el.disabled || el.error)}
									>
										<>
											{sliders.map((slider: any) => {
												const value = getValueFromNormalizedRangeBaseTen(
													slider.minValue,
													slider.maxValue,
													slider.value
												);
												return mapSliders(value, slider);
											})}
										</>
									</ProperThumbnailCard>
								</FakeHiddenContent>
							);
						})}
				</Box>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flex: 1,
		flexWrap: "wrap",
		flexDirection: "column",
		// overflow: "auto",
	},
	thumbnailsWrapper: {
		display: "flex",
		flex: 1,
		flexWrap: "wrap",
	},
	loadMore: {},
}));
