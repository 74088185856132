import React, { useEffect, useRef, useState } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { pullUntilFileIsAvailableFromBackend } from "../../Helpers/images";
import ImageError from "../../Pages/Tool/SearchForImage/Image/ImgeError";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: (props) => ({
		width: props.width,
		margin: `auto ${theme.spacing(0.5)}px`,
		background: "transparent",
		position: "relative",
		marginBottom: theme.spacing(1),
		"&:hover $moreIconWrapper": {
			display: "flex",
		},
	}),
	container: (props) => ({
		width: props.width,
		height: props.height,
		position: "relative",
		overflow: "hidden",
		borderRadius: 4,
		objectFit: "cover",
	}),
	img: (props) => ({
		margin: 0,
		padding: 0,
		cursor: "pointer",
		borderRadius: 4,
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	}),
	originalLabel: {
		color: "white",
		fontSize: "1rem",
		marginTop: "auto",
		marginRight: 8,
	},
	originalLabelContainer: {
		pointerEvents: "none",
		height: "160%",
		position: "absolute",
		display: "flex",
		right: 0,
		top: "-60%",
		placeContent: "flex-end",
		width: 90,
		borderRadius: 4,
		background:
			"linear-gradient(to bottom right, rgb(0,0,0,0) 0%, rgb(0,0,0,0) 50%, rgba(65, 65, 65, 0.4) 50%, rgba(65, 65, 65, 0.4) 100%);",
	},
	selected: {
		outline: `3px solid ${theme.palette.primary.dark}`,
	},

	hidden: {
		visibility: "hidden",
		width: "0 !important",
		height: "0 !important",
	},
}));

interface StyleProps {
	width: string;
	height: string;
	boxStyle?: boolean;
}

interface IProps {
	url: string;
	imageKey: string;
	feature: string;
	alt?: string;
	width: string;
	height: string;
	onClick?: () => void;
	boxStyle?: boolean;
	isSelected?: boolean;
	passedImageErrorOnLoad?: boolean;
	loadedCallback?: (success: boolean) => void;
	abortController?: AbortController;
	retry?: boolean;
	sleepSecs?: number;
	maxAttempts?: number;
}

const RetryingImage: React.FC<IProps> = ({
	url: passedImageUrl,
	loadedCallback,
	imageKey,
	alt,
	width,
	height,
	onClick,
	boxStyle,
	isSelected,
	feature,
	abortController,
	retry = true,
	passedImageErrorOnLoad = false,
	sleepSecs = 1,
	maxAttempts = 60,
}) => {
	const { t } = useTranslation();
	const containerRef = useRef(null);
	const [imageUrl, setImageUrl] = useState<string>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [ImageErrorOnLoad, setImgaeErrorOnload] = useState<boolean>(!loaded && passedImageErrorOnLoad);

	useEffect(() => {
		if (!loaded && passedImageErrorOnLoad) {
			abortController && abortController.abort();
			setImgaeErrorOnload(passedImageErrorOnLoad);
		}
	}, [passedImageErrorOnLoad]);

	const [isZeroBytesImage, setIsZeroBytesImage] = useState<boolean>(false);
	useEffect(() => {
		if (!retry) {
			setImageUrl(passedImageUrl);
		} else if (passedImageUrl && passedImageUrl.length > 0) {
			pullUntilFileIsAvailableFromBackend(passedImageUrl, feature, abortController, maxAttempts, sleepSecs)
				.then(async () => {
					const response = await fetch(passedImageUrl);
					const fileSize = (await response.blob()).size;
					if (fileSize !== undefined && fileSize === 0) {
						setIsZeroBytesImage(true);
					} else {
						setImageUrl(passedImageUrl);
					}
				})
				.catch((e) => {
					console.error(e);
					setImgaeErrorOnload(true);
				});
		}
	}, [passedImageUrl]);

	const onImageLoad = () => {
		loadedCallback && loadedCallback(true);
		setLoaded(true);
	};
	const onImageErrorFun = () => {
		setImageUrl("");
		setImgaeErrorOnload(true);
		loadedCallback && loadedCallback(false);
		setLoaded(false);
	};
	const classes = useStyles({
		width,
		height,
		boxStyle,
	});

	return (
		<>
			{isZeroBytesImage && (
				<Box display="flex" justifyContent="center" my={2} className={classes.root}>
					<ImageError
						imageHeight={height}
						imageWidth={width}
						massageText={"Offensive terms identified and need to be removed or rephrased."}
						titleText={"Image unavailable"}
						titleFontSize="12px"
						massageTextSize="12px"
					/>
				</Box>
			)}
			{ImageErrorOnLoad && (
				<Box display="flex" justifyContent="center" my={2} className={classes.root}>
					<ImageError
						imageHeight={height}
						imageWidth={width}
						massageText={"Oops! Something went wrong. Please try again."}
						titleText={"Image unavailable"}
						titleFontSize="12px"
						massageTextSize="12px"
					/>
				</Box>
			)}
			{!imageUrl && !ImageErrorOnLoad && !isZeroBytesImage && (
				<Box display="flex" justifyContent="center" my={2} className={classes.root}>
					<Skeleton
						variant="rect"
						animation="wave"
						width={width}
						height={height}
						style={{ borderRadius: 4 }}
					/>
				</Box>
			)}
			{imageUrl && (
				<Paper
					className={clsx(classes.root, {
						[classes.hidden]: !imageUrl,
					})}
					elevation={0}
					ref={containerRef}
				>
					<Box className={clsx(classes.container, isSelected ? classes.selected : "")}>
						<img
							onContextMenu={(e) => e.preventDefault()}
							key={`image-${imageKey}`}
							src={imageUrl}
							className={classes.img}
							style={{
								height: "inherit",
							}}
							onClick={() => {
								onClick && onClick();
							}}
							alt={alt || ""}
							onLoad={onImageLoad}
							onError={onImageErrorFun}
						/>
						{imageKey === "original-image" && (
							<Box className={classes.originalLabelContainer}>
								<Typography className={classes.originalLabel}>{t("original")}</Typography>
							</Box>
						)}
					</Box>
				</Paper>
			)}
		</>
	);
};

export default RetryingImage;
