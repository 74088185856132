import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
	open: boolean;
	src: string | string[];
	currentIndex: number;
	onClose: () => void;
};

const FullScreenViewer = ({ open, src, currentIndex, onClose }: Props) => {
	const [isOpen, setIsOpen] = useState(open);
	const [viewerLoaded, setViewerLoaded] = useState(false);
	const [currentImage, setCurrentImage] = useState(currentIndex);
	const classes = useStyles();

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		setCurrentImage(currentIndex);
	}, [currentIndex]);

	useEffect(() => {
		setViewerLoaded(true);
	}, []);

	const onPrevImage = useCallback(
		(e?) => {
			e?.stopPropagation();
			const prevIndex = (currentImage - 1 + src.length) % src.length;
			setCurrentImage(prevIndex);
		},
		[currentImage, src.length]
	);

	const onNextImage = useCallback(
		(e?) => {
			e?.stopPropagation();
			const nextIndex = (currentImage + 1) % src.length;
			setCurrentImage(nextIndex);
		},
		[currentImage, src.length]
	);

	const handleKeyPress = useCallback(
		(event) => {
			if (event.key === "Escape") {
				onClose();
			} else if (event.key === "ArrowRight") {
				onPrevImage();
			} else if (event.key === "ArrowLeft") {
				onNextImage();
			}
		},
		[onClose, onPrevImage, onNextImage]
	);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);
		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, [handleKeyPress]);

	const handleClose = () => {
		setIsOpen(false);
		onClose();
	};

	return (
		<>
			{isOpen && (
				<div
					className={clsx(classes.overlay, viewerLoaded && classes.fadeIn)}
					onKeyDown={handleKeyPress}
					onClick={onClose}
				>
					<CloseIcon className={classes.closeButton} onClick={handleClose} />
					{src.length > 1 && (
						<>
							<ChevronLeftIcon
								className={`${classes.arrowIcon} ${classes.leftArrowIcon}`}
								onClick={onPrevImage}
							/>
							<ChevronRightIcon
								className={`${classes.arrowIcon} ${classes.rightArrowIcon}`}
								onClick={onNextImage}
							/>
						</>
					)}
					<div className={classes.viewer}>
						<LazyLoadImage
							className={classes.image}
							key={src[currentImage]}
							alt={src[currentImage]}
							placeholderSrc="https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8184.jpg?size=626&ext=jpg"
							effect="blur"
							src={src[currentImage]}
							threshold={100}
							onClick={(e) => e.stopPropagation()}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default FullScreenViewer;

const useStyles = makeStyles((theme) => ({
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0, 0, 0, 0.9)",
		zIndex: 9999,
		opacity: 0,
		transition: "opacity 0.3s",
	},
	fadeIn: {
		opacity: 1,
	},
	viewer: {
		maxWidth: "65%",
		maxHeight: "98%",
		display: "flex",
		justifyContent: "center",
		overflow: "hidden",
		position: "relative",
		"& > span": {
			display: "flex !important",
			justifyContent: "center",
		},
	},
	image: {
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
		transition: "max-width 0.3s, max-height 0.3s",
	},
	closeButton: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(2),
		color: theme.palette.common.gray,
		cursor: "pointer",
	},
	arrowIcon: {
		position: "absolute",
		top: "50%",
		height: "5vh",
		width: "10vh",
		transform: "translateY(-50%)",
		color: theme.palette.common.gray,
		cursor: "pointer",
		zIndex: 1,
	},
	leftArrowIcon: {
		left: theme.spacing(2),
	},
	rightArrowIcon: {
		right: theme.spacing(2),
	},
}));
