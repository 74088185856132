import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import clsx from "clsx";
import Tooltip from "../Tooltip";
import AllFacesButton from "../../../assets/icons/AllFacesButton";
import OnlyValidFacesButton from "../../../assets/icons/OnlyValidFacesButton";
import OnlyNotValidFacesButton from "../../../assets/icons/OnlyNotValidFacesButton";

const useStyles = makeStyles((theme) => ({
	root: {
		// background: theme.palette.common.white,
		width: 105,
		height: 30,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(4),
		borderRadius: 25,
		overflow: "hidden",
	},
	toggleGroup: {
		height: "100%",
		width: "100%",
		padding: 1,
	},
	toggleButton: {
		height: "100%",
		width: "35px",
		stroke: "#A4A4A4",
		borderRadius: 15,
	},
	bothIcon: {
		marginLeft: 0,
		fontSize: 22,
		marginTop: 8,
	},
	notValidFacesIcon: {
		marginRight: 0,
		fontSize: 22,
	},
	validFacesIcon: {
		marginRight: 0,
		fontSize: 22,
	},
	activeToggleButton: {
		backgroundColor: `${theme.palette.primary.dark} !important`,
		stroke: "white",
		"&:disabled": {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
	},
}));

interface IProps {
	selectedOption: ValidFacesOptions;
	onOptionChange: (style: ValidFacesOptions) => void;
}

export enum ValidFacesOptions {
	both = "Both",
	onlyValid = "onlyValid",
	onlyNotValid = "onlyNotValid",
}

const ValidFacesSwitch: React.FC<IProps> = ({
	selectedOption,
	onOptionChange,
}) => {
	const classes = useStyles();

	const handleOption = (
		event: React.MouseEvent<HTMLElement>,
		newOption: ValidFacesOptions
	) => {
		if (newOption !== null) {
			onOptionChange(newOption);
		}
	};

	return (
		<Box className={classes.root}>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={selectedOption}
				exclusive
				onChange={handleOption}
			>
				<Tooltip title="Valid and Invalid Faces" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption === ValidFacesOptions.both,
						})}
						disabled={false}
						value={ValidFacesOptions.both}
					>
						<AllFacesButton className={classes.bothIcon} />
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Only valid faces" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption === ValidFacesOptions.onlyValid,
						})}
						value={ValidFacesOptions.onlyValid}
						disabled={false}
					>
						<OnlyValidFacesButton
							className={classes.validFacesIcon}
						/>
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Only not valid faces" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedOption ===
								ValidFacesOptions.onlyNotValid,
						})}
						value={ValidFacesOptions.onlyNotValid}
						disabled={false}
					>
						<OnlyNotValidFacesButton
							className={classes.notValidFacesIcon}
						/>
					</ToggleButton>
				</Tooltip>
			</ToggleButtonGroup>
		</Box>
	);
};

export default ValidFacesSwitch;
