/* eslint-disable react-hooks/exhaustive-deps */
import {Box, IconButton, Theme, Typography} from "@material-ui/core";
import FullscreenIcon from "../../../../assets/icons/FullscreenIcon";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import BriaButton, {BUTTONTYPES} from "../../BriaButton";
import FullScreenViewer from "../FullScreenViewer";
import {makeStyles} from "@material-ui/styles";
import {useContext, useEffect, useState} from "react";
import {Campaign, getImageInfo, getImageResolution, getPsdInfo} from "./utils";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import campaignDefault from "../../../../assets/campaignDefault.png";
import CircularLoadingTooltip from "../../LoadingTooltip";
import Context from "../../../../Context/Context";
import {useTranslation} from "react-i18next";

interface StyleProps {
	hasResults: boolean;
}

type Props = {
	visualHash: string;
	lastCampaignForm: Campaign;
	images: string[];
	seed: number;
	psds: string[];
	campaignName: string;
	loadingResults: boolean;
	isViewerOpen: boolean;
	isFormCompleted: boolean;
	errorGenerating: any;
	handleGenerate: (requestPsd?: boolean) => Promise<void>;
	setIsViewerOpen: (isViewerOpen: boolean) => void;
};

const CampaignPreview = ({
	visualHash,
	lastCampaignForm,
	images,
	seed,
	psds,
	campaignName,
	loadingResults,
	isViewerOpen,
	errorGenerating,
	isFormCompleted,
	handleGenerate,
	setIsViewerOpen,
}: Props) => {
	const { t } = useTranslation();
	const context = useContext(Context);
	const [currentImage, setCurrentImage] = useState(0);
	const [downloadingPsd, setDownloadingPsd] = useState(false);
	const [downloadingImgs, setDownloadingImgs] = useState(false);
	const [lastImages, setLastImages] = useState(images);

	const classes = useStyles({ hasResults: images.length > 0 });

	// To handle the returned psds from the second generation on downloadPsd
	useEffect(() => {
		if (psds.length > 0 && seed) {
			downloadPsd();
		}
	}, [psds]);

	useEffect(() => {
		// To not update the displayed images from the second generation on downloadPsd
		if (images.length > 0 && (!downloadingPsd || psds.length === 0)) {
			setDownloadingPsd(false);
			setLastImages(images);
		}
	}, [images]);

	useEffect(() => {
		if (errorGenerating) {
			setDownloadingPsd(false);
		}
	}, [errorGenerating]);

	const handleImageClick = (index: number) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	};

	async function downloadPsd() {
		if (psds.length > 0) {
			const currentDate = new Date();
			const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
			const campaignNameLowercase = campaignName.toLowerCase().replace(/\s/g, "_");
			const zipFileName = `${campaignNameLowercase}_${formattedDate}.zip`;
			const psdPromises = psds.map(async (psdURL, index) => {
				const { filename, base64 } = await getPsdInfo(psdURL, index, campaignName);
				const psdData = base64.split(",")[1];
				return { filename, psdData };
			});

			try {
				const psdInfos = await Promise.all(psdPromises);
				const zip = new JSZip();
				psdInfos.forEach(({ filename, psdData }) => {
					zip.file(filename, psdData, { base64: true });
				});

				zip.generateAsync({ type: "blob" }).then((content) => {
					saveAs(content, zipFileName);
					setDownloadingPsd(false);
				});
			} catch (error) {
				console.error("Error fetching PSDs:", error);
				setDownloadingPsd(false);
			}
		}
	}

	async function downloadCampaign() {
		if (lastImages.length > 0) {
			const zip = new JSZip();
			const currentDate = new Date();
			const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;

			for (const imageURL of lastImages) {
				const { filename, base64 } = await getImageInfo(imageURL, campaignName);
				const imageData = base64.split(",")[1];

				zip.file(filename, imageData, { base64: true });
			}

			const campaignNameLowercase = campaignName.toLowerCase().replace(/\s/g, "_");
			const zipFileName = `${campaignNameLowercase}_${formattedDate}.zip`;

			zip.generateAsync({ type: "blob" }).then((content) => {
				saveAs(content, zipFileName);
				setDownloadingImgs(false);
				setDownloadingPsd(false);
			});
		}
	}

	async function getClosestImageToSquare(): Promise<string> {
		let closestImage: string = "";
		let closestRatioDifference = Infinity;

		for (const image of images) {
			const resolution = await getImageResolution(image);
			const ratio = resolution.width / resolution.height;
			const ratioDifference = Math.abs(1 - ratio);

			if (ratioDifference < closestRatioDifference) {
				closestRatioDifference = ratioDifference;
				closestImage = image;
			}
		}

		return closestImage ? closestImage : images[Math.floor(Math.random() * images.length)];
	}

	return (
		<Box className={classes.previewWrapper}>
			<Box className={classes.preview}>
				<Typography variant="h2" component="header">
					{campaignName}
				</Typography>
				<IconButton
					onClick={() => {
						handleImageClick(0);
					}}
					style={{
						position: "absolute",
						top: -5,
						right: -5,
					}}
				>
					<FullscreenIcon stroke="black" style={{ height: "20px", width: "20px" }} />
				</IconButton>
				<Box className={classes.gallery}>
					<LoadingAnimation
						color="#ededed"
						progressBarTime={25}
						loading={loadingResults && !downloadingPsd}
						showLoadingStrings={true}
						loadingStringsTimeout={5000}
					/>
					{(lastImages.length > 0 && !loadingResults) || downloadingPsd
						? lastImages.map((imageUrl, i) => (
								<img
									onContextMenu={(e) => e.preventDefault()}
									key={i}
									src={imageUrl}
									className={classes.campaignImage}
									alt=""
									onClick={() => handleImageClick(i)}
								/>
						  ))
						: lastImages.length === 0 &&
						  !loadingResults && (
								<Box className={classes.campaignDefault}>
									<img
										onContextMenu={(e) => e.preventDefault()}
										src={campaignDefault}
										alt="campaign-default"
									/>
									<Box>
										<Typography>{t("instantlyGenerate")}</Typography>
										<Typography color="primary">{t("multipleCampaignAssets")}</Typography>
									</Box>
								</Box>
						  )}
				</Box>
				{(lastImages.length > 0 || loadingResults) && (
					<Box className={classes.downloadButtons}>
						<BriaButton
							buttonType={BUTTONTYPES.PRIMARYPURPLE}
							onClick={() => {
								if (lastImages.length > 0) {
									setDownloadingImgs(true);
									downloadCampaign();
								}
							}}
							style={{ width: "auto" }}
							endIcon={
								downloadingImgs && (
									<Box className={classes.campaignLoader}>
										<CircularLoadingTooltip key="" tab="" />
									</Box>
								)
							}
						>
							{t("downloadCampaign")}
						</BriaButton>
						<BriaButton
							buttonType={BUTTONTYPES.SECONDARYPURPLE}
							onClick={() => {
								if (!downloadingPsd) {
									if (lastCampaignForm.keepOriginalBg) {
										setDownloadingPsd(true);
										downloadCampaign();
									} else {
										if (psds.length > 0 && seed) {
											if (isFormCompleted) {
												setDownloadingPsd(true);
											}
											downloadPsd();
										} else if (!loadingResults) {
											if (isFormCompleted) {
												setDownloadingPsd(true);
											}
											handleGenerate(true);
										}
									}
								}
							}}
							style={{ width: "auto" }}
							endIcon={
								downloadingPsd && (
									<Box marginLeft="5px" marginRight="4px">
										<CircularLoadingTooltip key="" tab="" />
									</Box>
								)
							}
						>
							{t("downloadPSD")}
						</BriaButton>
					</Box>
				)}
			</Box>
			<FullScreenViewer
				open={!!(isViewerOpen && lastImages)}
				src={lastImages}
				currentIndex={currentImage}
				onClose={() => setIsViewerOpen(false)}
			/>
		</Box>
	);
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	previewWrapper: (props) => ({
		"& .MuiButton-iconSizeMedium > *:first-child": {
			display: "flex",
			fontSize: "unset",
		},

		height: "93%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: props.hasResults ? "#FAFAFA" : "",
		borderRadius: "16px",
	}),
	preview: {
		position: "relative",
		height: "95%",
		width: "95%",
		display: "grid",
		justifyItems: "center",
		gap: "2vh",
		"& header": {
			fontWeight: 600,
			color: "black",
			fontSize: "min(2.7vh, 2vw, 23px)",
		},
	},
	gallery: {
		display: "flex",
		justifyContent: "center",
		gap: "1vh",
		width: "100%",
		flexFlow: "wrap",
		overflow: "auto",
		alignItems: "center",
	},
	campaignImage: {
		maxWidth: "50%",
		maxHeight: "50%",
		borderRadius: "8px",
		transition: "transform 0.3s, cursor 0.3s, box-shadow 0.3s",
		"&:hover": {
			transform: "scale(1.02)",
			cursor: "pointer",
			boxShadow: "0 0 11px rgba(33,33,33,.2)",
		},
	},
	campaignDefault: (props) => ({
		position: "absolute",
		textAlign: "center",
		"& img": {
			width: "min(75vh, 50vw)",
			marginTop: "-50%",
		},
		"& > div": {
			position: "absolute",
			bottom: "15vh",
			left: "17vh",
			top: "26.5vh",
		},
		"& p": {
			fontFamily: "Montserrat",
			fontSize: "min(2vw, 40px)",
			fontWeight: "500",
			lineHeight: "56px",
			letterSpacing: "-0.5px",
			textAlign: "center",
		},
	}),
	downloadButtons: {
		display: "flex",
		gap: "2vh",
		"& button": {
			fontWeight: 600,
			fontSize: "min(1.5vh, 1.1vw, 12px)",
			borderRadius: "1vh",
			justifySelf: "center",
		},
		alignItems: "end",
	},
	campaignLoader: {
		"& > div": {
			color: "white !important",
		},
		marginLeft: "5px",
		marginRight: "4px",
	},
	infoIconWrapper: {
		position: "absolute",
		left: 5,
		top: 5,
		display: "flex",
		gap: "10px",
		textAlign: "initial",
		zIndex: 999,
	},
	infoIcon: {
		color: "rgb(241, 241, 241)",
		background: "rgb(73, 80, 87)",
		"& .MuiSvgIcon-root ": {
			width: 32,
			height: 32,
		},
		"&:hover": {
			background: "rgb(73, 80, 87) !important",
		},
		width: 20,
		height: 20,
	},
	metadataBox: {
		color: theme.palette.primary.main,
		borderRadius: "8px",
		padding: "15px",
		background: "rgba(0,0,0,0.7)",
	},
	textField: {
		"& .MuiInputBase-root": {
			fontSize: "0.875rem !important",
		},
		"& .MuiOutlinedInput-inputMarginDense": {
			paddingTop: "5.5px",
			paddingBottom: "5.5px",
		},
	},
}));
export default CampaignPreview;
