export const objectMapper = (obj: any, mapSchema: any) => {
	let mappedObj = {};
	Object.entries(obj).forEach(([key, value]) => {
		const matchedKey = mapSchema?.[key] || key;
		mappedObj = { ...mappedObj, [matchedKey]: value };
	});
	mappedObj = { ...mappedObj, ...obj };
	const keysToDelete = Object.keys(mapSchema);
	keysToDelete.forEach((key) => delete (mappedObj as any)?.[key]);
	return mappedObj;
};

export const invertObj = (obj: any = {}) => {
	let newObj = {};
	Object.keys(obj).forEach((key: string) => {
		const objValue = (obj as any)?.[key];
		Object.assign(newObj, { [objValue]: key });
	});
	return newObj;
};

export const mergedCondition = (
	conditions: boolean[] // one condition is enough to return true
) => conditions.some((bool) => bool);

export const isEmptyObj = (obj: object) => !Object.keys(obj).length;

export const stringShort = (text: string, max: number = 30) =>
	[text.slice(0, max), "..."].join("");

export const removeEmptyValues = (arr: any[] = []) =>
	arr.filter((elem) => !!elem);

export const getValueByMultiProps = (obj: any, possibleKeys: string[] = []) => {
	const validKey = possibleKeys.find((key) => obj?.[key]) || "";
	return (obj as any)?.[validKey];
};

export const upperCaseAtFirstChar = (text: string = "") => {
	return text.split("")
		.map((elem: string, index: number) =>
			index ? elem : elem.toUpperCase()
		)
		.join("");
};
(window as any).upperCaseAtFirstChar = upperCaseAtFirstChar;
