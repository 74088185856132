import { useTheme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const BulbIcon = (props: SvgIconProps) => {
	const theme = useTheme();
	return (
		<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="11" cy="11" r="11" fill={theme.palette.primary.dark} />
			<path
				d="M8.95934 16.9238H13.048C13.2762 16.9238 13.4614 16.7356 13.4614 16.5036V14.9729C13.4614 14.741 13.2765 14.5527 13.048 14.5527H8.95907C8.73088 14.5527 8.54569 14.7407 8.54569 14.9729V16.5036C8.54596 16.7356 8.73115 16.9238 8.95934 16.9238ZM12.6349 16.0837H9.37273V15.3931H12.6349V16.0837Z"
				fill="white"
			/>
			<path
				d="M8.95934 18.728H13.048C13.2762 18.728 13.4614 18.5398 13.4614 18.3078V16.5037C13.4614 16.2717 13.2765 16.0835 13.048 16.0835H8.95907C8.73088 16.0835 8.54569 16.2717 8.54569 16.5037V18.3078C8.54596 18.5398 8.73115 18.728 8.95934 18.728ZM12.6349 17.8879H9.37273V16.9238H12.6349V17.8879Z"
				fill="white"
			/>
			<path
				d="M9.58311 15.3956C9.72503 15.3956 9.86318 15.3213 9.94005 15.1877C10.0556 14.9877 9.98951 14.7301 9.79249 14.6129C8.25668 13.6979 5.98413 11.4949 5.98413 9.67269C5.98413 6.98937 8.04703 5.11525 11.0009 5.11525C13.9602 5.11525 16.0231 6.98937 16.0231 9.67269C16.0231 11.4952 13.7506 13.6979 12.2147 14.6129C12.0177 14.7301 11.9516 14.9877 12.0672 15.1877C12.1825 15.3879 12.4357 15.4549 12.6327 15.3377C14.3838 14.2946 16.8496 11.8473 16.8496 9.67269C16.8496 6.54488 14.3921 4.2749 11.0063 4.2749C7.6151 4.2749 5.15763 6.54488 5.15763 9.67269C5.15763 11.8473 7.62343 14.2946 9.37454 15.3377C9.44012 15.3767 9.51215 15.3956 9.58311 15.3956Z"
				fill="white"
			/>
			<path
				d="M11.7572 15.3932C11.9854 15.3932 12.1706 15.2049 12.1706 14.973V11.9211L13.5556 10.5134C13.7171 10.3494 13.7171 10.0833 13.5556 9.91943C13.3941 9.75524 13.1325 9.75551 12.9713 9.91943L11.465 11.4504C11.3876 11.5291 11.3438 11.6359 11.3438 11.7474V14.973C11.3438 15.2049 11.529 15.3932 11.7572 15.3932Z"
				fill="white"
			/>
			<path
				d="M10.2502 15.3932C10.4784 15.3932 10.6636 15.2049 10.6636 14.973V11.7474C10.6636 11.6359 10.62 11.5291 10.5426 11.4504L9.03637 9.91943C8.87484 9.75524 8.61331 9.75551 8.45204 9.91943C8.29051 10.0833 8.29051 10.3494 8.45204 10.5134L9.83707 11.9211V14.973C9.8368 15.2049 10.0217 15.3932 10.2502 15.3932Z"
				fill="white"
			/>
		</svg>
	);
};

export default BulbIcon;
