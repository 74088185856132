import { Box, createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import LampIcon from "../../assets/icons/LampIcon";
import { String } from "aws-sdk/clients/appstream";
import clsx from "clsx";
import Icon from "../Common/Icon/Icon";
import React from "react";
import ExclamationIcon from "../../assets/icons/ExclamationIcon";

interface IProps {
	styleType?: string;
	showCloseIcon?: boolean;
	text: string;
	condition: boolean;
	rootStyle?: any;
	onButtonClick: () => void;
	position?: string;
	iconColor?: String;
}

function BriaInfoBox(props: IProps) {
	const classes = useStyles(props);

	return props.condition ? (
		<Box
			position={props.position ? props.position : "absolute"}
			style={props.rootStyle ?? {}}
			className={classes.infoBox}
		>
			<Box className={classes.infoBoxInnerContainer}>
				{props.styleType === "info" ? (
					<Box className={clsx(classes.infoBoxIcon)}>
						<ExclamationIcon />
					</Box>
				) : (
					<Box className={classes.infoBoxIcon}>
						<LampIcon color={props.iconColor} />
					</Box>
				)}
				<Typography className={classes.infoBoxText}>{props.text}</Typography>
				<Link href="#" className={classes.infoBoxLink} onClick={props.onButtonClick}>
					{props.showCloseIcon ? (
						// <CloseIcon style={{ fontSize: "1rem" }} />
						<></>
					) : (
						<>Got It</>
					)}
				</Link>
			</Box>
		</Box>
	) : (
		<></>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		infoBox: (props: any) => ({
			width: props.styleType === "info" ? "95%" : "100%",
			justifyContent: props.styleType === "info" ? "flex-start" : "center",
			alignItems: "center",
			display: "flex",
			zIndex: 999,
		}),
		infoBoxInnerContainer: (props: any) => ({
			justifyContent: "center",
			alignItems: "center",
			display: "flex",
			borderRadius: 8,
			border: "1px solid #E9ECEF",
			backgroundColor: "#ffffff",
			boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08)",
			padding: "8px 8px 8px 5px",
			width: "100%",
		}),
		infoBoxIcon: (props: any) => ({
			display: props.styleType === "info" ? "flex" : "inherit",
		}),
		infoBoxText: (props: any) => ({
			fontSize: 14,
			color: "#5B5B5B",
			fontWeight: 500,
			marginLeft: 11,
			marginRight: 7,
			paddingTop: props.styleType === "info" ? 3 : 0,
		}),
		infoBoxLink: (props: any) => ({
			fontSize: 14,
			color: "#1A0638",
			fontWeight: 600,
			minWidth: props.styleType === "info" ? 0 : 52,
			display: props.styleType === "info" ? "flex" : "inherit",
		}),
	})
);

export default BriaInfoBox;
