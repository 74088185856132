import { useCallback, useContext, useEffect, useState } from "react";
import { BriaObjectType, getFaceData, SelectedPointInterface } from "../Components/Tools/utilities";
import Context from "../Context/Context";
import { ImageEditorData_vdrObject_vdrAwsFaceDetection_faces } from "../GraphQL/types/imageEditorData";
import OracleResponse from "../Models/OracleResponse";
import { SystemViewTypes } from "../Models/SystemView";
import {
	ToolConfigTabsTypes,
	ToolsSlider,
	ToolsStructure,
	ToolsSuggestions,
	ToolsThumbnails,
	ToolsValidatoin,
} from "../Models/ToolsConfig";
import ManualBrush from "../assets/icons/Brush";
import MagicalRemovel from "../assets/icons/MagicalRemovel";
import { useTranslation } from "react-i18next";

let displayNameCounter: { [key: string]: number } = {};

export const useToolConfig = (
	systemView: SystemViewTypes,
	selectedPoint: SelectedPointInterface | any | null,
	selectedFace: ImageEditorData_vdrObject_vdrAwsFaceDetection_faces | undefined
) => {
	const { t } = useTranslation();
	const { objectType, index: selectedFaceIndex = 0 } = selectedPoint || {};
	const [loading, setloading] = useState<boolean>(true);
	const { user, iframeConfig } = useContext(Context);
	const [askAlanConfigurations, setaskAlanConfigurations] = useState<any>({});
	const [toolsConfiguration, settoolsConfiguration] = useState<any>({});
	const [toolsConfigTabs, settoolsConfigTabs] = useState<ToolsStructure[]>([]);
	const [toolsConfigSliders, settoolsConfigSliders] = useState<ToolsSlider[]>([]);
	const [toolsConfigThumbnails, settoolsConfigThumbnails] = useState<ToolsThumbnails[]>([]);
	const [toolsConfigSuggestions, settoolsConfigSuggestions] = useState<ToolsSuggestions[]>([]);
	let faceData: any = {};
	if (selectedFace) {
		faceData = getFaceData(selectedFace);
	}

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		const configUrl = user?.isAdmin()
			? systemView === SystemViewTypes.Viewer
				? "https://images.bria.ai/web-assets/ui-config-viewer.json"
				: systemView === SystemViewTypes.External
				? "https://images.bria.ai/web-assets/ui-config-external.json"
				: systemView === SystemViewTypes.Testing
				? "https://images.bria.ai/web-assets/ui-testing-config.json"
				: "https://images.bria.ai/web-assets/ui-config.json"
			: user?.isViewer()
			? "https://images.bria.ai/web-assets/ui-config-viewer.json"
			: user?.isExternal()
			? "https://images.bria.ai/web-assets/ui-config-external.json"
			: "https://images.bria.ai/web-assets/ui-config.json";

		fetch(configUrl, {
			signal: signal,
		})
			.then((res) => res.json())
			.then((res) => {
				setloading(false);
				settoolsConfiguration(res);
				setaskAlanConfigurations(res.askAlanConfig);
			});

		return () => {
			controller.abort();
		};
	}, [systemView]);

	const filterSliders = useCallback(
		(el: ToolsValidatoin) => {
			return el?.gender === undefined || el.gender === faceData?.gender;
		},
		[faceData?.gender]
	);

	const errorBasedOnOracle = (el: any) => {
		let shouldShowError = false;
		const oracleScore: string =
			selectedPoint?.oracle?.filter((oracleObject: any) => {
				return oracleObject.id === el.id;
			})[0]?.score ?? "";

		// if (oracleScore === OracleResponse.LOW) {
		// 	shouldShowError = true;
		// }

		return shouldShowError;
	};

	const warningBasedOnOracle = (el: any) => {
		let shouldShowWarning = false;
		const oracleScore: string =
			selectedPoint?.oracle?.filter((oracleObject: any) => {
				return oracleObject.id === el.id;
			})[0]?.score ?? "";

		if (oracleScore === OracleResponse.MEDIUM || oracleScore === OracleResponse.LOW) {
			shouldShowWarning = true;
		}

		return shouldShowWarning;
	};

	const duplicateDisplayNameHandler = (displayName: string) => {
		if (!displayNameCounter[displayName]) {
			displayNameCounter[displayName] = 0;
		}
		displayNameCounter[displayName]++;
		return `${displayName} ${displayNameCounter[displayName]}`;
	};

	const translateConfigSliders = (sliders: ToolsSlider[]) => {
		sliders.forEach((slider) => {
			if (slider.displayName.includes("Model ")) {
				const chunks = slider.displayName.split(" ");
				slider.displayName = t(chunks[0].trim()) + chunks[1].trim();
			} else {
				slider.displayName = t(slider.displayName.trim());
			}
			slider.name = t(slider.name.trim());
			slider.maxName = t(slider.maxName.trim());
			slider.minName = t(slider.minName.trim());
		});
	};
	const translateConfigTabs = (tabs: ToolsStructure[]) => {
		tabs.forEach((tab) => {
			if (tab.level1DisplayName) {
				tab.level1DisplayName = t(tab.level1DisplayName.trim());
			}
			tab.level2DisplayName = t(tab.level2DisplayName.trim());
		});
	};

	const translateConfigThumbs = (thumbs: ToolsThumbnails[]) => {
		thumbs.forEach((thumb) => {
			if (thumb.title.includes("Model ")) {
				const chunks = thumb.title.split("  ");
				thumb.title = t(chunks[0].trim()) + chunks[1].trim();
			} else {
				thumb.title = t(thumb.title.trim());
			}
			translateConfigSliders(thumb.sliders);
		});
	};

	useEffect(() => {
		let tmpObjectType = objectType;
		if (tmpObjectType === BriaObjectType.objects) {
			tmpObjectType = BriaObjectType.objects;
		}
		if (
			toolsConfiguration?.structure &&
			(toolsConfiguration?.structure[tmpObjectType] || toolsConfiguration?.structure["camera"])
		) {
			displayNameCounter = {};

			let toolsConfigTabs: ToolsStructure[] = JSON.parse(
				JSON.stringify(
					toolsConfiguration?.structure[tmpObjectType] ?? toolsConfiguration?.structure["camera"] ?? []
				)
			);
			let toolsConfigSliders: ToolsSlider[] = JSON.parse(
				JSON.stringify(toolsConfiguration?.sliders[tmpObjectType] ?? [])
			);
			let displayNameList: { [key: string]: number } = {};
			toolsConfigSliders.forEach(({ displayName }: ToolsSlider) => {
				if (!displayNameList[displayName]) {
					displayNameList[displayName] = 0;
				}
				displayNameList[displayName]++;
			});
			toolsConfigSliders = toolsConfigSliders.map((el: ToolsSlider) => {
				return {
					...el,
					displayName:
						displayNameList[el.displayName] > 1
							? duplicateDisplayNameHandler(el.displayName)
							: el.displayName,
					disabled: !filterSliders(el.validation) || errorBasedOnOracle(el),
					error: errorBasedOnOracle(el),
					warning: warningBasedOnOracle(el),
				};
			});

			let toolsConfigThumbnails: ToolsThumbnails[] = (toolsConfiguration?.thumbnails[tmpObjectType] ?? []).filter(
				(el: ToolsThumbnails) => filterSliders(el.validation)
			);
			displayNameList = {};
			toolsConfigThumbnails.forEach(({ title }: ToolsThumbnails) => {
				if (!displayNameList[title]) {
					displayNameList[title] = 0;
				}
				displayNameList[title]++;
			});
			toolsConfigThumbnails = toolsConfigThumbnails.map((el: ToolsThumbnails) => {
				return {
					...el,
					title: displayNameList[el.title] > 1 ? duplicateDisplayNameHandler(el.title) : el.title,
				};
			});

			let toolsConfigSuggestions: ToolsSuggestions[] = (toolsConfiguration?.suggestions ?? []).filter(
				(el: ToolsSuggestions) => el?.thumbnails?.every((el) => filterSliders(el.validation))
			);
			const defaultInitialValues = toolsConfiguration?.initialValues[tmpObjectType] ?? {};

			Object.keys(defaultInitialValues).forEach((key) => {
				const conditions: any[] = defaultInitialValues[key] ?? [];
				conditions?.forEach((element) => {
					switch (key) {
						case "age":
							if (faceData.age >= element.min && faceData.age <= element.max) {
								const slider = toolsConfigSliders.find((el) => el.id === element?.sliderId);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}
							break;
						case "gender":
							if (faceData?.gender?.toLowerCase?.() === element?.gender?.toLowerCase?.()) {
								const slider = toolsConfigSliders.find((el) => el.id === element?.sliderId);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}

							break;
						case "glasses":
							if (faceData.glasses === element.isOn) {
								const slider = toolsConfigSliders.find((el) => el.id === element?.sliderId);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}
							break;

						default:
							break;
					}
				});
			});

			let toolsConfigTabsFiltered = [...toolsConfigTabs];
			if (iframeConfig) {
				toolsConfigTabsFiltered = toolsConfigTabsFiltered.filter(
					(tab) =>
						tab.input1 === BriaObjectType.crop || iframeConfig.enabledFeatures.indexOf(tab.level2Id) !== -1
				);
			}

			if (toolsConfigTabsFiltered && toolsConfigTabsFiltered[0] && toolsConfigTabsFiltered[0].input1 === "Crop") {
				toolsConfigTabsFiltered = toolsConfigTabsFiltered.filter(
					(tab) => tab.level2Id === "Custom" || tab.level2Id === "eCommerce"
				);
				const newTab = toolsConfigTabsFiltered[0];
				const customTab = toolsConfigTabsFiltered[1];
				newTab.level2Id = "Media Sizes";
				newTab.level2DisplayName = "mediaSizes";
				newTab.level2Location = 1;
				customTab.level1DisplayName = "customSize";
				customTab.level2DisplayName = "customSize";
				customTab.level2Location = 2;
			}
			if (
				toolsConfigTabsFiltered &&
				toolsConfigTabsFiltered[0] &&
				toolsConfigTabsFiltered[0].input1 === "objects"
			) {
				const newTab: any = toolsConfigTabsFiltered.slice(1);
				const ObjectsTab = toolsConfigTabsFiltered[0];
				ObjectsTab.level2DisplayName = "magicRemoval";
				ObjectsTab.level2Location = 1;
				ObjectsTab.level2Id = ToolConfigTabsTypes.AutoObjIdentification;
				ObjectsTab.icon = <MagicalRemovel />;
				newTab.level2Id = ToolConfigTabsTypes.ManualObjBrush;
				newTab.level2DisplayName = "manualBrush";
				newTab.level2Location = 2;
				newTab.input1 = "objects";
				newTab.icon = <ManualBrush />;
				toolsConfigTabsFiltered.push(newTab);
			}

			translateConfigTabs(toolsConfigTabsFiltered);
			translateConfigSliders(toolsConfigSliders);
			translateConfigThumbs(toolsConfigThumbnails);

			settoolsConfigSliders(toolsConfigSliders);
			settoolsConfigTabs([...toolsConfigTabsFiltered]);
			settoolsConfigThumbnails(toolsConfigThumbnails);
			settoolsConfigSuggestions(toolsConfigSuggestions);
		}
	}, [
		toolsConfiguration,
		objectType,
		selectedFace,
		filterSliders,
		faceData.age,
		faceData.gender,
		faceData.glasses,
		askAlanConfigurations,
		selectedFaceIndex,
		systemView,
	]);

	return {
		toolsLoading: loading,
		toolsConfigTabs,
		toolsConfigSliders,
		toolsConfigThumbnails,
		toolsConfigSuggestions,
	};
};
