import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import { BrandContext } from "../BrandPage/BrandPage";
import { brandSteps } from "../steps";
import TitleTexts from "../TitleTexts/TitleTexts";
import ImgViewer, { mainImgInfo } from "../BrandPage/ImgViewer/ImgViewer";
import { handleMoodApplyCall } from "../BrandPage/ImgViewer/BrandImg/BrandImg";
import Context from "../../../../Context/Context";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import MarkAsDefault from "./MarkAsDefault/MarkAsDefault";
import { GENERAL_ORG_ID } from "../../../../Constants";

export default function Review() {
	const classes = useStyles();
	const styleObj = {
		classes,
	};
	const stepsToReview = brandSteps(styleObj)?.filter((step) => !step.hideAtReview);

	const stepRenderReview = (stepKey) => stepsToReview.find((step) => step.resultKey === stepKey)?.renderReview;

	const { brandState, navigate, generalState } = useContext(BrandContext);
	const getValueByStepKey = (key: string) => (brandState as any)?.[key];
	const [selectedMoodImgSrc, setSelectedMoodImgSrc] = useState<any>(mainImgInfo.src);
	const [loading, setLoading] = useState(false);
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const instance = BriaAPI.getInstance(mainImgInfo.vhash, context.iframeConfig);
	const brandName = (brandState as any)?.name;

	const [selectedImage, setSelectedImage] = useState((brandState as any)?.selectedResultImage ?? "center");

	function getSelectedMoodSliders() {
		return selectedImage === "left"
			? (generalState as any)?.moodSlidersSuggestion1
			: selectedImage === "right"
			? (generalState as any)?.moodSlidersSuggestion2
			: (brandState as any)?.moodSliders;
	}

	useEffect(() => {
		// generate selected mood image
		if (getSelectedMoodSliders()) {
			setLoading(true);
			handleMoodApplyCall(context, instance, getSelectedMoodSliders(), organizationUid)
				.then((img) => setSelectedMoodImgSrc(img))
				.finally(() => setLoading(false));
		}
	}, [
		(brandState as any)?.moodSliders,
		(generalState as any)?.moodSlidersSuggestion1,
		(generalState as any)?.moodSlidersSuggestion2,
	]);

	return (
		<Box className={classes.reviewRoot}>
			<TitleTexts passedClassName={classes.brandName} text={`Summary of ${brandName}`} />
			<Box display="flex">
				<Box flex="40%" maxWidth="40%" textAlign="left" className={classes.reviewValues}>
					{getValueByStepKey("logo") ? (
						<img src={getValueByStepKey("logo")} alt="file" className={classes.logoImg} />
					) : (
						"No Logo Selected"
					)}
					{stepRenderReview("colorPallet")?.(getValueByStepKey("colorPallet"))}
					{stepRenderReview("gardian")?.(getValueByStepKey("gardian"))}
					<MarkAsDefault />
				</Box>
				<Box width="31vw" margin="auto">
					<ImgViewer imageSrc={selectedMoodImgSrc} parentLoading={loading} isBrandPage={true} />
				</Box>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	reviewRoot: {
		maxWidth: "calc(100% - 12.5%)",
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
	},
	brandName: {
		padding: "13.8vh 0 4.8vh",
	},
	pointer: {
		cursor: "pointer",
	},
	reviewBox: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		marginTop: "3rem",
	},
	stepTitlesWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		gap: "0.5rem",
	},
	reviewedStep: {
		// border: "1px dashed #ddd",
		backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DDDDDDFF' stroke-width='3' stroke-dasharray='4%2c 11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
		maxHeight: 120,
		minWidth: 180,
		borderRadius: 5,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "1rem",
		position: "relative",
	},
	editIcon: {
		position: "absolute",
		top: "14px",
		right: "14px",
	},
	currentReviewPointer: {
		width: 5,
		aspectRatio: "1/1",
		backgroundColor: "#D80067",
		borderRadius: "50%",
	},
	reviewColorsWrapper: {
		display: "flex",
		flexWrap: "wrap",
		gap: "2.18vw",
	},
	reviewColorInput: {
		width: 30,
	},
	reviewImg: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	reviewGardian: {
		margin: 0,
		fontSize: "24px",
		lineHeight: 1.2,
		maxHeight: "100%",
		overflow: "auto",
		color: "#000",
		fontWeight: 400,
		textAlign: "left",
		flex: 1,
	},
	reviewValues: {
		gap: "2.18vw",
		display: "flex",
		flexDirection: "column",
	},
	logoImg: {
		maxWidth: "10VW",
		objectFit: "contain",
	},
}));
