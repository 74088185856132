import { invertObj } from "./../../../../Helpers/mapper";
export const colorMixerColors: string[] = [
	"#FF0000",
	"#FF7A00",
	"#FFC20A",
	"#8BCD51",
	"#2F9D92",
	"#0061E0",
	"#5F39C4",
	"#CC00AA",
];

const colorMapper = {
	red: "#FF0000",
	orange: "#FF7A00",
	yellow: "#FFC20A",
	green: "#8BCD51",
	aqua: "#2F9D92",
	blue: "#0061E0",
	purple: "#5F39C4",
	magenta: "#CC00AA",
};

export const allowedColors = Object.keys(colorMapper).map((color: string) =>
	color.toUpperCase()
);

export const filterAllowedColor = (color: string) =>
	allowedColors.includes(color.toUpperCase());

export const getValueByKey = (obj: any = {}, key: string) =>
	(obj as any)?.[key];

export const fromColorToHex = (color: string): string =>
	(colorMapper as any)?.[color];

export const fromHexToColor = (hex: string): string =>
	getValueByKey(invertObj(colorMapper), hex);

export const lowerCaseProp = (obj: any = {}, propName: string) => ({
	...obj,
	[propName]: obj?.[propName]?.toLowerCase(),
});
