import { Box, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import ShowAt from "../Wrappers/ShowAt";
import "./LoadingAnimation.css";
import { useTranslation } from "react-i18next";

interface IProps {
	loading: boolean;
	loadingStringsPosition?: string;
	progressBarTime?: number;
	color?: string;
	loadingStringsTimeout?: number;
	loadingStrings?: string[];
	showLoadingStrings?: boolean;
}

export default function LoadingAnimation(props: IProps) {
	const { t } = useTranslation();
	const {
		loading,
		loadingStringsPosition = "bottom",
		progressBarTime = 6,
		color,
		loadingStringsTimeout = 2000,
		loadingStrings = [`${t("loading")}...`, t("pleaseWait"), t("almostThere")],
		showLoadingStrings = false,
	} = props;
	const classes = useStyles();
	const [progressBarValue, setProgressBarValue] = useState<number>(0);
	const [progressBarIntervalId, setProgressBarIntervalId] = useState<any>();
	const [loadingStringIntervalId, setLoadingStringIntervalId] = useState<any>();
	const [loadingStringIdx, setLoadingStringIdx] = useState<number>(0);

	const stopProgressBar = () => {
		setProgressBarValue(0);
		clearInterval(progressBarIntervalId);
		setProgressBarIntervalId(null);
	};

	const stopLoadingStringsShuffle = () => {
		setLoadingStringIdx(0);
		clearInterval(loadingStringIntervalId);
		setLoadingStringIntervalId(null);
	};

	const runProgressBar = () => {
		if (!progressBarIntervalId) {
			const id = setInterval(() => {
				setProgressBarValue((prevCounterValue) => {
					let newValue = prevCounterValue + 100 / progressBarTime;
					return newValue > 100 ? 100 : newValue;
				});
			}, 1000);
			setProgressBarIntervalId(id);
		}
	};

	const shuffleLoadingStrings = () => {
		if (showLoadingStrings && loadingStrings.length > 0 && !loadingStringIntervalId) {
			const id = setInterval(() => {
				setLoadingStringIdx((prevIdxValue) => {
					let newValue = prevIdxValue + 1;
					return newValue >= loadingStrings.length ? 0 : newValue;
				});
			}, loadingStringsTimeout);
			setProgressBarIntervalId(id);
		}
	};

	useEffect(() => {
		if (progressBarTime > 0) {
			if (!progressBarIntervalId && loading) {
				runProgressBar();
			} else {
				stopProgressBar();
			}
		}
		if (showLoadingStrings && loadingStrings.length > 0) {
			if (!loadingStringIntervalId && loading) {
				shuffleLoadingStrings();
			} else {
				stopLoadingStringsShuffle();
			}
		}
	}, [loading]);

	return (
		<ShowAt showAlways condition={loading}>
			<Box
				flexDirection="column"
				className={clsx({
					[classes.flexCenter]: loadingStringsPosition === "top",
				})}
			>
				<ShowAt
					showAlways
					condition={loadingStringsPosition === "top" && showLoadingStrings && loadingStrings.length > 0}
				>
					<>
						<br></br>
						<br></br>
						<span className="loading-string">
							<Typography>{loadingStrings[loadingStringIdx]}</Typography>
						</span>
						<br></br>
						<br></br>
					</>
				</ShowAt>

				<div className="loading">
					<div className="circle circle-a" style={color ? { backgroundColor: color } : {}}></div>
					<div className="circle circle-b" style={color ? { backgroundColor: color } : {}}></div>
					<div className="circle circle-c" style={color ? { backgroundColor: color } : {}}></div>
					<ShowAt showAlways condition={progressBarTime > 0}>
						<span className="progress-bar">
							<LinearProgress value={progressBarValue} variant="determinate" color="primary" />
						</span>
					</ShowAt>
				</div>

				<ShowAt
					showAlways
					condition={loadingStringsPosition === "bottom" && showLoadingStrings && loadingStrings.length > 0}
				>
					<>
						<br></br>
						<br></br>
						<span className="loading-string">
							<Typography>{loadingStrings[loadingStringIdx]}</Typography>
						</span>
					</>
				</ShowAt>
			</Box>
		</ShowAt>
	);
}

const useStyles = makeStyles((theme) => ({
	flexCenter: {
		display: "flex",
		alignItems: "center",
		paddingInline: 30,
	},
}));
