import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function StyleIcon(props: SvgIconProps) {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 21.5C9.61305 21.5 7.32387 20.5518 5.63604 18.864C3.94821 17.1761 3 14.8869 3 12.5C3 10.1131 3.94821 7.82387 5.63604 6.13604C7.32387 4.44821 9.61305 3.5 12 3.5C16.97 3.5 21 7.082 21 11.5C21 12.56 20.526 13.578 19.682 14.328C18.838 15.078 17.693 15.5 16.5 15.5H14C13.5539 15.4928 13.1181 15.635 12.7621 15.904C12.4061 16.173 12.1503 16.5533 12.0353 16.9844C11.9203 17.4155 11.9528 17.8727 12.1276 18.2833C12.3025 18.6938 12.6095 19.0341 13 19.25C13.1997 19.4342 13.3366 19.6764 13.3915 19.9425C13.4465 20.2085 13.4167 20.4851 13.3064 20.7334C13.196 20.9816 13.0107 21.1891 12.7764 21.3266C12.5421 21.4641 12.2705 21.5247 12 21.5Z"
				stroke={props.stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.5 12C9.05228 12 9.5 11.5523 9.5 11C9.5 10.4477 9.05228 10 8.5 10C7.94772 10 7.5 10.4477 7.5 11C7.5 11.5523 7.94772 12 8.5 12Z"
				stroke={props.stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5 9C13.0523 9 13.5 8.55228 13.5 8C13.5 7.44772 13.0523 7 12.5 7C11.9477 7 11.5 7.44772 11.5 8C11.5 8.55228 11.9477 9 12.5 9Z"
				stroke={props.stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.5 12C17.0523 12 17.5 11.5523 17.5 11C17.5 10.4477 17.0523 10 16.5 10C15.9477 10 15.5 10.4477 15.5 11C15.5 11.5523 15.9477 12 16.5 12Z"
				stroke={props.stroke}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default StyleIcon;
