import { ApolloClient, ApolloLink, createHttpLink, DefaultOptions, from } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { captureException, captureMessage } from "@sentry/react";
import { getAuth, getIdToken } from "firebase/auth";
import app from "../Config/Firebase";
import { getSelectedOrganizationFromLocalStorage } from "../Helpers/localStorage";

export const WEB_API_URL = process.env.REACT_APP_WEB_APP_URL ?? "https://web.int.bria-api.com/graphql/";

const httpLink = createHttpLink({
	uri: WEB_API_URL,
});
const authLink = setContext(async (_, { headers }) => {
	const currentUser = getAuth(app)?.currentUser;

	const iframeId = window.iframeId;
	const client_uid = currentUser ? currentUser?.uid : localStorage.getItem("client_uid");
	const authorization: string = currentUser
		? await getIdToken(currentUser)
		: localStorage.getItem("authorization") ?? "";

	return {
		headers: {
			...headers,
			client_uid: client_uid,
			Authorization: authorization,
			"Org-Id": getSelectedOrganizationFromLocalStorage()?.uid,
			...(iframeId && { "Iframe-Id": iframeId }),
		},
	};
});
const iframeAuthLink = setContext(async (_, { headers }) => {
	const currentUser = getAuth(app)?.currentUser;

	const iframeId = window.iframeId;
	const iframeAuth = window.iframeAuth;
	const client_uid = currentUser ? currentUser?.uid : localStorage.getItem("client_uid");
	const authorization: string =
		iframeId && iframeAuth
			? iframeAuth
			: currentUser
			? await getIdToken(currentUser)
			: localStorage.getItem("authorization") ?? "";

	return {
		headers: {
			...headers,
			client_uid: client_uid,
			Authorization: authorization,
			"Org-Id": getSelectedOrganizationFromLocalStorage()?.uid,
			...(iframeId && { "Iframe-Id": iframeId }),
		},
	};
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			captureMessage(err.message);
		}
	}
	if (networkError) {
		captureException(networkError);
	}
});
const graphQlErrorLink = new ApolloLink((operation, forward) => {
	return forward(operation).map((data) => {
		if (data && data.errors) {
			for (let err of data.errors) {
				if (err.message.startsWith("401 Unauthorized")) {
					throw new Error("AUTH_ERROR");
				}
			}
		}
		return data;
	});
});
const retryLink = new RetryLink({
	attempts: {
		max: 5,
		retryIf: (error, _operation) => {
			if (error.statusCode === 401) {
				return false;
			}
			return !!error;
		},
	},
	delay: (count, operation, error) => {
		const delayTime: number = Math.pow(2, count - 1) * 100;
		return delayTime;
	},
});
const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
		context: { timeout: 120 },
	},
	mutate: {
		context: { timeout: 120 },
	},
};
export const apolloClientWithoutRetry = new ApolloClient({
	link: from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions: defaultOptions,
});
export default new ApolloClient({
	link: from([errorLink, retryLink, graphQlErrorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions: defaultOptions,
});
const UploadImageRetryLink = new RetryLink({
	attempts: {
		max: 3,
		retryIf: (error, _operation) => {
			if (error.statusCode === 401) {
				return false;
			}
			return !!error;
		},
	},
	delay: (count, operation, error) => {
		const delayTime: number = Math.pow(2, count - 1) * 100;
		return delayTime;
	},
});
const uploadDefaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
		context: { timeout: 300 },
	},
	mutate: {
		context: { timeout: 300 },
	},
};
export const UploadImageClient = new ApolloClient({
	link: from([errorLink, UploadImageRetryLink, graphQlErrorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions: uploadDefaultOptions,
});

export const IframeClient = new ApolloClient({
	link: from([errorLink, UploadImageRetryLink, graphQlErrorLink, iframeAuthLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions: uploadDefaultOptions,
});
