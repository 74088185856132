import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import React from "react";

const CustomizedButton = withStyles({
	root: {
		borderRadius: 4,
	},
	label: {
		textTransform: "capitalize",
	},
	disabled: {
		background: "rgba(18, 18, 50, 0.5)",
		color: "#FFFFFF",
		opacity: 0.3,
	},
})(Button);

export enum BUTTONTYPES {
	PRIMARY = "primary",
	TEXTSMALL = "textSmall",
	PRIMARYMEDIUM = "primaryMedium",
	SECONDARYMEDIUM = "secondaryMedium",
	PRIMARYSMALL = "primarySmall",
	PRIMARYPURPLE = "primaryPurple",
	PRIMARYROUNDED = "primaryRounded",
	SECONDARY = "secondary",
	SECONDARYPURPLE = "secondaryPurple",
	SECONDARYROUNDED = "secondaryRounded",
	OUTLINED = "outlined",
	PURPLE_OUTLINED = "purpleOutlined",
	GRAY = "gray",
	TEXTBUTTON = "textButton",
	TEXTBUTTOPURPLE = "textButtonPurple",
	OUTLINED_TRANSPARENT = "outlinedTransparent",
	PRIMARYPURPLEROUNDED = "primaryPurpleRounded",
	PRIMARYRound = "primaryRound",
	WHITEROUNDED = "whiteRounded",
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textSmall: {
			borderRadius: 8,
			width: 147,
			height: 34,
			color: "#1A0638",
			fontSize: 14,
			fontWeight: 600,
			letterSpacing: "0.02em",
			"&:hover": {
				background: "#E7E7E7",
				boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.03);",
			},
			"&:focus": {
				background: "rgba(83, 0, 201, 0.05);",
				border: " 2px solid rgba(83, 0, 201, 0.05);",
				boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02);",
			},
			// "&:focus-visible": {
			// 	background: "#37057D",
			// 	color: "#FFFFFF",
			// },
			"&:disabled": {
				color: "#1A0638",
				opacity: 0.25,
			},
		},
		primaryMedium: {
			borderRadius: 8,
			backgroundColor: theme.palette.primary.dark,
			boxShadow: " 0px 1px 4px rgba(0, 0, 0, 0.12)",
			// width: 182,
			height: 40,
			color: theme.palette.common.white,
			fontSize: 16,
			fontWeight: 600,
			letterSpacing: "0.02em",
			lineHeight: "16px",
			padding: "12px 16px",
			"&:hover": {
				background: `  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
			},
			"&:focus": {
				background: `  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
			},
			// "&:focus-visible": {
			// 	background: "#37057D",
			// 	color: "#FFFFFF",
			// },
			"&:disabled": {
				color: theme.palette.common.white,
				opacity: 0.25,
			},
		},
		secondaryMedium: {
			borderRadius: 8,
			border: `3px solid ${theme.palette.primary.dark}`,
			color: theme.palette.primary.dark,
			fontSize: 16,
			fontWeight: 600,
			letterSpacing: "0.02em",
			width: 182,
			cursor: "pointer",
			height: 40,
			"&:hover": {
				background: "#E7E7E7",
			},
			"&:focus": {
				background: "rgba(83, 0, 201, 0.05);",
			},
			// "&:focus-visible": {
			// 	background: "#37057D",
			// 	color: "#FFFFFF",
			// },
			"&:disabled": {
				color: theme.palette.primary.dark,
				opacity: 0.25,
			},
		},
		primarySmall: {
			borderRadius: 8,
			backgroundColor: theme.palette.primary.dark,
			boxShadow: " 0px 1px 4px rgba(0, 0, 0, 0.12)",
			width: 147,
			height: 34,
			color: theme.palette.common.white,
			fontSize: 14,
			fontWeight: 600,
			letterSpacing: "0.02em",
			"&:hover": {
				background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
			},
			"&:focus": {
				background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),  ${theme.palette.primary.dark};`,
			},
			// "&:focus-visible": {
			// 	background: "#37057D",
			// 	color: "#FFFFFF",
			// },
			"&:disabled": {
				color: theme.palette.common.white,
				opacity: 0.25,
			},
		},
		primary: {
			borderRadius: 4,
			color: "#FFFFFF",
			fontWeight: "bold",
			background: theme.palette.primary.dark,
			padding: "8px 16px",
			"&:hover": {
				background: theme.palette.primary.dark,
				color: "#FFFFFF",
				opacity: 0.5,
			},
			"&:focus": {
				background: "#96054A",
				color: "#FFFFFF",
			},
			"&:disabled": {
				background: "rgba(18, 18, 50, 0.5) !important",
				color: "#FFFFFF",
				opacity: 0.3,
			},
			"&:active": {
				backgroundColor: "#96054A",
			},
		},
		primaryPurple: {
			borderRadius: 8,
			color: "#FFFFFF",
			fontWeight: "bold",
			background: theme.palette.primary.dark,
			padding: "8px 16px",
			height: "37px",
			width: "130px",

			"&:hover": {
				background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
				color: theme.palette.common.white,
			},
			"&:focus": {
				background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
				color: theme.palette.common.white,
			},
			"&:focus-visible": {
				background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.dark};`,
				color: theme.palette.common.white,
			},
			"&:disabled": {
				color: theme.palette.common.white,
				opacity: 0.25,
			},
			"&:active": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
		primaryPurpleRounded: {
			borderRadius: 40,
			color: "#FFFFFF",
			fontWeight: "bold",
			background: theme.palette.primary.dark,
			padding: "8px 16px",
			height: "56px",
			width: "140px",
			boxShadow: "20px 20px 40px rgba(37, 3, 85, 0.1)",

			"&:hover": {
				background: " rgba(83, 0, 201, 0.5)",
				color: "#FFFFFF",
				opacity: 0.5,
			},
			"&:focus": {
				background: "#37057D",
				color: "#FFFFFF",
			},
			"&:focus-visible": {
				background: "#37057D",
				color: "#FFFFFF",
			},
			"&:disabled": {
				background: "rgba(18, 18, 50, 0.5) !important",
				color: "#FFFFFF",
				opacity: 0.3,
			},
			"&:active": {
				backgroundColor: "#37057D",
			},
		},
		primaryRound: {
			borderRadius: 40,
			color: "#FFFFFF",
			fontWeight: "bold",
			background: theme.palette.primary.dark,
			padding: "8px 16px",
			height: "56px",
			width: "140px",
			boxShadow: "20px 20px 40px rgba(37, 3, 85, 0.1)",
			"&:hover": {
				background: theme.palette.primary.dark,
				color: "#FFFFFF",
				opacity: 0.5,
			},
			"&:focus": {
				background: "#96054A",
				color: "#FFFFFF",
			},
			"&:disabled": {
				background: "rgba(18, 18, 50, 0.5) !important",
				color: "#FFFFFF",
				opacity: 0.3,
			},
			"&:active": {
				backgroundColor: "#96054A",
			},
		},
		noUpperCase: {
			textTransform: "none",
		},
		upperCase: {
			textTransform: "uppercase",
		},
		textButton: {
			color: theme.palette.primary.dark,
			border: "none",
			"&:hover": {
				opacity: 0.5,
			},
			"&:focus": {
				color: "#96054A",
			},
			"&:disabled": {
				color: "#121232 !important",
				opacity: 0.3,
			},
		},
		textButtonPurple: {
			fontWeight: "bold",
			color: theme.palette.primary.dark,
			border: "none",
			"&:hover": {
				opacity: 0.5,
			},
			"&:disabled": {
				opacity: 0.3,
			},
		},
		secondary: {
			textTransform: "capitalize",
			borderRadius: 4,
			// background: "#FAFAFC",
			color: theme.palette.primary.dark,
			fontWeight: "bold",
			padding: "8px 16px",
			border: `1px solid ${theme.palette.primary.dark}`,
			"&:hover": {
				opacity: 0.5,
			},
			"&:focus": {
				color: "#96054A",
				border: "1px solid #96054A",
			},
			"&:disabled": {
				color: "#121232 !important",
				border: "1px solid #121232",
				opacity: 0.3,
			},
		},
		secondaryPurple: {
			color: theme.palette.primary.dark,
			border: `1px solid ${theme.palette.primary.dark}`,
			borderRadius: 4,
			fontWeight: "bold",
			padding: "8px 16px",
			"&:hover": {
				opacity: 0.5,
			},
			"&:disabled": {
				opacity: 0.3,
			},
		},
		primaryRounded: {
			minHeight: "48px",
			minWidth: "48px",
			textTransform: "capitalize",
			borderRadius: "50%",
			color: "#FFFFFF",
			fontWeight: "bold",
			background: theme.palette.primary.dark,
			"&:hover": {
				background: theme.palette.primary.dark,
				color: "#FFFFFF",
				opacity: 0.5,
			},
			"&:focus": {
				background: "#96054A",
				color: "#FFFFFF",
			},
			"&:disabled": {
				background: "rgba(18, 18, 50, 0.5) !important",
				color: "#FFFFFF",
				opacity: 0.3,
			},
		},
		secondaryRounded: {
			minHeight: "48px",
			minWidth: "48px",
			textTransform: "capitalize",
			borderRadius: "50%",
			background: "#FAFAFC",
			color: theme.palette.primary.dark,
			fontWeight: "bold",
			border: `1px solid ${theme.palette.primary.dark}`,
			"&:hover": {
				opacity: 0.5,
			},
			"&:focus": {
				color: "#96054A",
				border: "1px solid #96054A",
			},
			"&:disabled": {
				color: "#121232 !important",
				border: "1px solid #121232",
				opacity: 0.3,
			},
		},
		outlined: {
			border: `1px solid ${theme.palette.primary.dark}`,
			backgroundColor: "#FAFAFC !important",
			color: "#96054A !important",
			borderRadius: "4px !important",
			"&:hover": {
				opacity: 0.5,
			},
			"&:focus": {
				color: "#96054A",
				borderColor: "#96054A",
			},
			"&:disabled": {
				color: "#121232 !important",
				borderColor: "#121232",
			},
		},
		purpleOutlined: {
			border: `1px solid ${theme.palette.primary.dark}`,
			backgroundColor: "#FAFAFC !important",
			color: `${theme.palette.primary.dark} !important`,
			borderRadius: "4px !important",
			"&:hover": {
				opacity: 0.5,
			},
			"&:focus": {
				color: theme.palette.primary.dark,
				borderColor: theme.palette.primary.dark,
			},
			"&:disabled": {
				color: "#00000042 !important",
				borderColor: "#0000001f",
			},
		},
		outlinedTransparent: {
			border: "1px solid #fff",
			backgroundColor: "transparent",
			color: "#fff",
			borderRadius: "4px",
			fontWeight: "bold",
			"&:focus, &:hover": {
				color: theme.palette.primary.dark,
				borderColor: theme.palette.primary.dark,
				backgroundColor: "#fff",
			},
			"&:disabled": {
				borderColor: "#121232",
			},
		},
		gray: {
			borderRadius: 4,
			color: "black",
			fontWeight: "bold",
			background: "rgba(196, 196, 196, 0.2)",
			padding: "8px 16px",
			"&:hover": {
				background: "rgba(196, 196, 196, 0.6)",
				color: "black",
			},
			"&:focus": {
				background: "rgba(196, 196, 196, 0.6)",
				color: "black",
			},
			"&:disabled": {
				background: "rgba(196, 196, 196, 0.2)",
				color: "black",
			},
			"&:active": {
				backgroundColor: "rgba(196, 196, 196, 0.6)",
			},
		},
		whiteRounded: {
			width: " 11.42vw",
			height: "4vh",
			background: " #FFFFFF",
			boxShadow: "0px 4px 20px rgb(0 0 0 / 10%)",
			borderRadius: "100px",
			textAlign: "left",
			color: "black",
			fontWeight: 600,
			padding: "8px 16px",
			minWidth: "fit-content",
			"& p": {
				paddingLeft: "5px",
				fontWeight: 600,
			},
			"&:hover": {
				boxShadow: "20px 10px 32px rgba(22, 1, 53, 0.2)",
				color: theme.palette.primary.dark,
				background: " #FFFFFF",
			},
			"&:active": {
				color: "#96054A",
				backgroundColor: "#F1F1F1",
				boxShadow: "20px 10px 32px rgba(22, 1, 53, 0.2)",
			},
		},
	})
);

interface IProps {
	buttonType: BUTTONTYPES;
	upperCase?: boolean;
	style?: React.CSSProperties;
	target?: string;
}

const BriaButton: React.FC<IProps & ButtonProps> = ({ buttonType, children, upperCase = false, style, ...rest }) => {
	const classes = useStyles();
	const mergedClassName = clsx(classes[buttonType], {
		[classes.noUpperCase]: !upperCase,
		[classes.upperCase]: upperCase,
	});
	return (
		<Button classes={{ root: mergedClassName }} {...rest} style={style}>
			{children}
		</Button>
	);
};

export default BriaButton;
