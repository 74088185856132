import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { toast, ToastPosition } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IParams {
	toastId?: React.ReactText;
}

const useErrorPopup = (params: IParams = {}) => {
	const { t } = useTranslation();
	const defaultStyle: React.CSSProperties = {
		width: "fit-content",
		background: "white",
		borderTop: "5px #8800ff solid",
		boxShadow: "3px 3px 15px lightgrey",
		color: "#495057",
		textAlign: "left",
	};
	const [toastId, setToastId] = useState<React.ReactText | undefined>(params.toastId);

	function showErrorPopup(
		errorMessage: string,
		autoClose: number | false = 10000,
		position: ToastPosition = "bottom-left",
		style: React.CSSProperties = defaultStyle
	) {
		const taostId = toast.error(
			<Box>
				<Typography style={{ fontWeight: "bold", fontSize: "1.0rem" }}>{t("somethingWentWrong")}</Typography>
				<Typography style={{ fontSize: "0.8rem" }}>{errorMessage}</Typography>
			</Box>,
			{
				toastId,
				type: toast.TYPE.ERROR,
				autoClose: autoClose,
				position,
				style,
				closeButton: (
					<button
						className="Toastify__close-button Toastify__close-button--error"
						style={{ color: "#495057" }}
						type="button"
						aria-label="close"
					>
						<svg aria-hidden="true" viewBox="0 0 14 16">
							<path
								fillRule="evenodd"
								d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
							></path>
						</svg>
					</button>
				),
			}
		);
		setToastId(taostId);
	}

	return {
		showErrorPopup,
		toastId,
	};
};

export default useErrorPopup;
