import React, { Dispatch, SetStateAction } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import RouterConstants from "../../Constants/RouterConstants";
import BriaCheckbox from "../UI/BriaCheckbox";
import LinksConst from "../../Constants/LinksConst";

interface IProps {
	showGetUpdated: boolean;
	getUpdated: boolean;
	setGetUpdated: Dispatch<SetStateAction<boolean>>;
}

const Agreement: React.FC<IProps> = (showGetUpdated, getUpdated, setGetUpdated) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Box>
				<Typography className={classes.typo3}>
					{t("bySubmitting")}

					<Link
						onClick={(e: React.MouseEvent<any>) => {
							window.open(RouterConstants.PRIVACY_POLICY.path, "_blank");
						}}
						className={classes.link}
					>
						{t("privacyPolicy")}
					</Link>

					{t("and")}

					<Link
						onClick={(e: React.MouseEvent<any>) => {
							window.open(`${LinksConst.BRIA_WEBSITE}terms-and-conditions/`, "_blank");
						}}
						className={classes.link}
					>
						{t("termsOfService")}
					</Link>
				</Typography>
			</Box>

			{showGetUpdated === true && (
				<Box ml={-2} width="100%" display="flex" alignItems="center">
					<BriaCheckbox
						style={{
							display: "inline-block",
						}}
						value={getUpdated}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setGetUpdated(event.target.checked as boolean);
						}}
					/>
					<Typography className={classes.typo3}>{t("keepMeUpdated")}</Typography>
				</Box>
			)}
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	typo3: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
		color: "#979797",
		display: "inline-block",
	},
	link: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
		textDecoration: "none",
		cursor: "pointer",

		"&:hover": {
			textDecoration: "none",
			color: theme.palette.primary.dark,
		},
	},
}));

export default Agreement;
