import React, { useContext, useEffect, useState } from "react";
import { BrandContext } from "../BrandPage/BrandPage";
import { Box, RadioGroup, TextField } from "@material-ui/core";
import TitleTexts from "../TitleTexts/TitleTexts";
import { makeStyles } from "@material-ui/styles";
import BriaRadio, { BriaRadioControlLabel } from "../../../UI/BriaRadio";
import CustomizedTooltip from "../../../UI/Tooltip";

export default function ProjectType() {
	const classes = useStyles();
	const { brandState, updateBrandState, defaultStepValue } =
		useContext(BrandContext);
	const projectTypes = [
		{
			label: "New Brand",
			value: "0",
			disabled: false,
			description: "Create a new brand style for a new company",
		},
		{
			label: "Brand Extension",
			value: "1",
			disabled: false,
			description: "Create an additional brand style for your company",
		},
		{
			label: "Campaign",
			value: "2",
			disabled: false,
			description: "Create a new brand style for an upcoming campaign",
		},
	];

	const [type, setType] = useState<any>(undefined);

	const handleChange = ({ target: { value } }: any) => {
		setType(value ?? undefined);
		updateBrandState(value);
	};

	if (type === undefined) {
		handleChange({
			target: {
				value: brandState.projectType
					? brandState.projectType
					: projectTypes[0].value,
			},
		});
	}

	return (
		<Box className={classes.projectTypeRoot}>
			<TitleTexts
				passedClassName={classes.title}
				text="What are you creating today?"
				subText="Select the type of project you are working on"
			/>

			<RadioGroup
				row
				value={type}
				onChange={handleChange}
				className={classes.inputWrapper}
			>
				{projectTypes.map((control, index) => (
					<CustomizedTooltip
						multiLine={true}
						placement="right"
						title={
							<>
								<b className="title">{control.label}</b>
								<div>{control.description}</div>
							</>
						}
					>
						<BriaRadioControlLabel
							value={control.value}
							label={control.label}
							key={index}
							fontSize={24}
							disabled={control.disabled}
							checked={index === parseInt(brandState.projectType)}
						/>
					</CustomizedTooltip>
				))}
			</RadioGroup>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	projectTypeRoot: {
		maxWidth: 800,
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
		"& > :first-child": {
			margin: "24% auto 0",
		},
	},
	title: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	inputWrapper: {
		marginTop: "6.5%",
		justifyContent: "space-around",
		"& .MuiFormControlLabel-root": {
			flex: "unset !important",
		},
	},
	input: {
		fontSize: "20px",
	},
	grayFont: {
		color: "#A4A4A4",
	},
}));
