import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Input, Typography } from "@material-ui/core";

interface IProps {
	iframeId?: string;
	vhash?: string;
	enableDownload?: boolean;
	usageText?: string;
	enabledFeatures?: string;
	sessionId?: string;
	userId?: string;
	embeddedIframe?: boolean;
	disableDownloadPsd?: boolean;
	disableSave?: boolean;
	disableSavePsd?: boolean;
	saveLabel?: string;
	selectedTab?: string;
	disableDownloadImage?: boolean;
}

class Iframe extends React.Component<IProps, any> {
	public render() {
		const {
			classes,
			iframeId = "1",
			vhash = "b08ca996a3ea6abc",
			enableDownload = true,
			usageText = "You have 12 tokens left out of 100",
			enabledFeatures = "all",
			userId = "1",
			sessionId = "10",
			embeddedIframe = false,
			disableDownloadPsd = false,
			disableSave = false,
			disableSavePsd = false,
			saveLabel = "Save",
			disableDownloadImage = false,
			selectedTab = "scene",
		}: any = this.props;
		const iframeHost = process.env.REACT_APP_IFRAME_HOSTNAME;
		const sourceDomain = `${window.location.protocol}//${window.location.host}`;
		return (
			<div className={classes.root}>
				<Typography variant="h5">Gallery</Typography>
				<Input
					type="text"
					className={classes.iframeUrlInput}
					value={`${window.location.origin}/iframe/?target=gallery&userId=${userId}&sessionId=${sessionId}&enabledFeatures=${enabledFeatures}&enableDownload=${enableDownload}&sourceDomain=${sourceDomain}&embeddedIframe=${embeddedIframe}&disableDownloadPsd=${disableDownloadPsd}&disableSave=${disableSave}&disableSavePsd=${disableSavePsd}&saveLabel=${saveLabel}&disableDownloadImage=${disableDownloadImage}&selectedTab=${selectedTab}&usageText=${usageText}`}
				/>
				<iframe
					className={classes.iframe}
					src={`${iframeHost}/?iframeId=${iframeId}&target=gallery&userId=${userId}&sessionId=${sessionId}&enabledFeatures=${enabledFeatures}&enableDownload=${enableDownload}&sourceDomain=${sourceDomain}&embeddedIframe=${embeddedIframe}&disableDownloadPsd=${disableDownloadPsd}&disableSave=${disableSave}&disableSavePsd=${disableSavePsd}&saveLabel=${saveLabel}&disableDownloadImage=${disableDownloadImage}&selectedTab=${selectedTab}&usageText=${usageText}`}
				></iframe>

				<Typography variant="h5">Playground</Typography>
				<Input
					type="text"
					className={classes.iframeUrlInput}
					value={`${window.location.origin}/iframe/?target=playground&userId=${userId}&sessionId=${sessionId}&vhash=${vhash}&enabledFeatures=${enabledFeatures}&enableDownload=${enableDownload}&sourceDomain=${sourceDomain}&embeddedIframe=${embeddedIframe}&disableDownloadPsd=${disableDownloadPsd}&disableSave=${disableSave}&disableSavePsd=${disableSavePsd}&saveLabel=${saveLabel}&disableDownloadImage=${disableDownloadImage}&selectedTab=${selectedTab}&usageText=${usageText}`}
				/>
				<iframe
					className={classes.iframe}
					src={`${iframeHost}/?iframeId=${iframeId}&target=playground&userId=${userId}&sessionId=${sessionId}&vhash=${vhash}&enabledFeatures=${enabledFeatures}&enableDownload=${enableDownload}&sourceDomain=${sourceDomain}&embeddedIframe=${embeddedIframe}&disableDownloadPsd=${disableDownloadPsd}&disableSave=${disableSave}&disableSavePsd=${disableSavePsd}&saveLabel=${saveLabel}&disableDownloadImage=${disableDownloadImage}&selectedTab=${selectedTab}&usageText=${usageText}`}
				></iframe>
			</div>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
			textAlign: "center",
		},
		iframe: {
			width: "100%",
			height: "100%",
			overflow: "scroll",
		},
		iframeUrlInput: {
			width: "100%",
		},
	});

export default withStyles(styles)(Iframe);
