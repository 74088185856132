import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

interface ITooltipedContent {
	children: JSX.Element;
	tooltipContent?: JSX.Element;
	tooltipText: string;
	bottom?: boolean;
}

export function TooltipedContent({
	children,
	tooltipText,
	tooltipContent,
	bottom = false,
}: ITooltipedContent) {
	const classes = useStyles({ bottom });
	const { t } = useTranslation();

	return (
		<Box width="100%" height="100%" className={classes.tooltipWrapper}>
			<div className={classes.tooltip} id="tooltip">
				<span className={classes.tooltipText}>
					{tooltipContent || t(tooltipText)}
				</span>
				<div className={classes.tringaleWrapper}>
					<div className={classes.tringale}></div>
				</div>
			</div>
			{children}
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tooltipWrapper: {
			cursor: "pointer",
			display: "flex",
			flex: 1,
			justifyContent: "center",
			alignItems: "center",
			position: "relative",
			width: "100%",
			height: "100%",
			"&:hover  #tooltip": {
				display: "flex",
			},
		},
		tooltipText: {
			transform: ({ bottom }: any) => (!bottom ? "" : "rotateX(180deg)"),
			whiteSpace: ({ bottom }: any) => bottom && "pre",
		},
		tooltip: {
			textAlign: "center",
			display: "none",
			backgroundColor: "#000",
			color: "#fff",
			position: "absolute",
			bottom: ({ bottom }: any) => (bottom ? "auto" : "150%"),
			top: ({ bottom }: any) => (!bottom ? "auto" : "170%"),
			left: ({ bottom }: any) => "50%",
			zIndex: 2000,
			justifyContent: "center",
			alignItems: "center",
			padding: "0.5rem 1rem",
			minHeight: 36,
			borderRadius: "4px",
			transform: ({ bottom }: any) =>
				!bottom
					? "translateX(-60%)"
					: "rotateX(-180deg) translateX(-80%)",
		},
		tringaleWrapper: {
			position: "absolute",
			bottom: "0",
			width: "100%",
		},
		tringale: {
			width: "0",
			height: "7px",
			borderLeft: "7px solid transparent",
			borderRight: "7px solid transparent",
			borderTop: "7px solid #000",
			position: "absolute",
			right: ({ bottom }: any) => (bottom ? "6%" : "50%"),
			transform: ({ bottom }: any) => bottom ? "translateX(-50%)" : "translateX(150%)",
		},
	})
);
