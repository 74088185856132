import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";
import React, { RefObject, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../../assets/bria-logo-white.svg";
import { ReactComponent as Logo } from "../../assets/bria-logo.svg";
import { ReactComponent as FileIcon } from "../../assets/svgs/assets-file-icon.svg";
import GalleryIcon from "../../assets/gallery-icon.png";
import playGroundIcon from "../../assets/artificial-intelligence.svg";
import { uuid4 } from "@sentry/utils";
import PlusIcon from "../../assets/icons/PlusIcon";
import RouterConstants from "../../Constants/RouterConstants";
import Context from "../../Context/Context";
// import { ImageEditorData_metaData_settings } from "../../GraphQL/types/imageEditorData";
import { IsIframe, sendPostMessage } from "../../Helpers/iframe";
import { IsGuestUser } from "../../Helpers/isGuest";
import Analytics, { ANALYTICS_EVENTS, HUBSPOT_EVENTS } from "../../Models/Analytics";
import { SystemViewTypes } from "../../Models/SystemView";
import LocalBreadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import ShowAt from "../Common/Wrappers/ShowAt";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import UserMenu from "../UserMenu";
import BriaAPI from "../../sdk/resources/briaAPI";
import { toast } from "react-toastify";
import { SubscriptionCreditsTypes } from "../../Constants/SubscriptionConstants";
import { GENERAL_ORG_ID } from "../../Constants";
import { addRecordToDownloads, consumeUserCredits } from "../../GraphQL/mutations";
import { startDownload } from "../../Helpers/images";
import { IframePostMessageTypes } from "../../Constants/IframeConstants";
import LinksConst from "../../Constants/LinksConst";
import BriaDropdownButton from "../UI/BriaDropdownButton";
import useErrorPopup from "../../hooks/useErrorPopup";
import { captureException } from "@sentry/react";
import { PagesEnum, ToolConfigTabsTypes } from "../../Models/ToolsConfig";
import IframeUsageMessage from "../Common/IframeUsageMessage";
import { uploadImageViaApi } from "./utilities";
import WandIcon from "../../assets/svgs/wand.svg";
import { CloseButton } from "../Common/CloseButton";
import { prepareImageForExport } from "../../GraphQL/queries";
import { resize } from "./CropImgeTool/Crop";
import { IframeDestinationEnum } from "../../types/graphql-global-types";

interface StyleProps {
	isGallery: boolean;
	isImageEditor: boolean;
	isMyAssets: boolean;
	translationEnabled: boolean;
	isPageNotFound: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	actionsContainer: {
		flexGrow: 1,
	},
	appBar: (props) => ({
		position: props.isMyAssets ? "unset" : "initial",
		background: theme.palette.background.paper,
		display: "flex",
		alignItems: "center",
		height: 80,
		justifyContent: "center",
		borderBottom: !props.isPageNotFound ? `1px solid #E9ECEF` : "",
		paddingInline: props?.isImageEditor ? "1.7vw" : "2.1vw",
		"@media (min-width:1920px)": {
			paddingInline: 32,
		},
		"&.transparent": {
			background: "transparent",
			height: 100,
		},
		"& .transparentButton": {
			color: "#fff !important",
			borderColor: "#fff",
			backgroundColor: "transparent !important",
			"& path": {
				fill: "#fff",
			},
		},
		"@media (max-width:1040px)": {
			height: "auto",
			paddingBottom: "16px",
		},
	}),
	toolbar: {
		paddingInline: 0,
		width: "100%",
		maxWidth: 1872,
		display: "flex",
		justifyContent: "space-between",
		"@media (min-width:1920px)": {
			maxWidth: "100%",
		},
	},
	breadcrumbs: {
		marginLeft: theme.spacing(4.4),
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
	},
	gallery: (props) => ({
		cursor: "pointer",
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
		},
	}),
	breadcrumbItem: (props) => ({
		color: " #1A0638",
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 600,
		lineHeight: "20px",
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
		},
	}),
	breadcrumbSeparator: {
		marginInline: 7,
	},
	icon: {
		fontSize: 18,
		paddingRight: 4,
		paddingTop: 6,
	},
	iconButton: {
		"&:hover": {
			background: "none",
		},
	},
	videoStatus: {
		color: theme.palette.primary.light,
		fontSize: 14,
		paddingLeft: 6,
	},
	downloadPSDButton: (props) => ({
		width: props.translationEnabled ? 190 : 156,
		marginLeft: 12,

		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? (props.translationEnabled ? "15vw !important" : "10vw !important") : "",
			minWidth: 105,
			height: props.isImageEditor ? "2.6vw !important" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1vw !important" : "",
		},
	}),
	downloadButton: (props) => ({
		marginLeft: 16,
		width: 118,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
			width: props.isImageEditor ? "8vw" : "",
			height: props.isImageEditor ? "2.6vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1vw" : "",
		},
	}),
	shareFeedbackButton: (props) => ({
		border: 0,
		lineHeight: "normal",
		margin: "0 2px",
		padding: "12px 12px",
		color: "#1A0638",
		fontWeight: 600,
		borderRadius: 8,
		fontSize: theme.typography.pxToRem(16),
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		"&:hover": {
			color: "#1A0638",
			background: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			color: "#1A0638",
			background: "#E4DCF0",
			opacity: 1,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
	}),
	headerNavigationContainer: {
		display: "flex",
		marginLeft: 14,
		color: "#1A0638",
		fontSize: 16,
		fontWeight: 600,
		letterSpacing: "0.02em",
		lineHeight: "16px",
	},
	assetsContainer: (props) => ({
		marginLeft: 12,
		cursor: "pointer",
		width: 145,
		height: 40,
		fontSize: 16,
		fontWeight: 600,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "8.8vw" : "",
			minWidth: 98,
			height: props.isImageEditor ? "2.6vw" : "",
			marginLeft: props.isImageEditor ? "0.8vw" : "",
		},
	}),

	playGroundIconContainer: (props) => ({
		marginRight: "8px",
		cursor: "pointer",
		width: "139px",
		height: 40,
		fontSize: 16,
		fontWeight: 600,
		padding: "8px 16px !important",

		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "8.8vw" : "",
			minWidth: 98,
			height: props.isImageEditor ? "2.6vw" : "",
			marginLeft: props.isImageEditor ? "0.8vw" : "",
		},
	}),
	iframelogo: {
		height: 50,
	},
	iconsStyle: (props) => ({
		"@media (max-width:1360px)": {
			width: !props.isGallery ? "1.5vw !important" : "",
			height: !props.isGallery ? "1.5vw !important" : "",
		},
	}),
	homeContainer: (props) => ({
		display: "flex",
		cursor: "pointer",
		width: 117,
		height: 40,
		paddingLeft: 18,
		paddingRight: 14,
		fontSize: 16,
		fontWeight: 600,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "10vw" : "",
			height: props.isImageEditor ? "2.6vw" : "",
		},
	}),

	galleryContainer: (props) => ({
		display: "flex",
		cursor: "pointer",
		height: 40,
		padding: "8px 16px !important",
		fontSize: 16,
		fontWeight: 600,
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw !important" : "",
			width: props.isImageEditor ? "10vw" : "",
			height: props.isImageEditor ? "2.6vw" : "",
		},
	}),
	upgradeButton: (props) => ({
		textTransform: "unset",
		color: "#1A0638",
		fontWeight: 600,
		marginRight: 12,
		borderRadius: 8,
		fontSize: theme.typography.pxToRem(16),
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		"&:hover": {
			color: "#1A0638",
			background: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			border: 0,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "1vw" : "",
			marginRight: props.isImageEditor ? "0.625vw" : "",
			padding: props.isImageEditor ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.625vw 1vw 0.625vw 0.83vw" : "",
		},
	}),
	purple: {
		padding: "9px 12px",
		margin: "0 10px",
		color: theme.palette.primary.dark,
		border: `3px solid ${theme.palette.primary.dark}`,
		filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
		"&:hover, &:focus": {
			color: theme.palette.primary.dark,
			backgroundColor: "#E5E5E5",
			border: `3px solid ${theme.palette.primary.dark}`,
		},
	},
	contactSales: {
		width: 158,
		height: 40,
		fontWeight: 600,
	},
	apiButton: {
		color: "#1A0638 !important",
	},
	uploadButton: (props) => ({
		marginRight: 12,
		color: theme.palette.primary.dark,
		fontWeight: 600,
		borderRadius: 8,
		fontSize: theme.typography.pxToRem(16),
		fontFamily: "Montserrat",
		letterSpacing: "0.02em",
		"&:hover": {
			color: theme.palette.primary.dark,
			background: "#E7E7E7",
			opacity: 1,
		},
		"@media (max-width:1360px)": {
			fontSize: props.isImageEditor ? "min(16px, 1.4vw)" : "",
			marginRight: props.isImageEditor ? "0.625vw" : "",
			padding: props.isImageEditor ? "8px 16px 8px 16px" : "",
		},
		"@media (max-width:1200px)": {
			fontSize: props.isGallery ? "1.3vw" : "",
			marginRight: props.isGallery ? "0.625vw" : "",
			padding: props.isGallery ? "0.52vw 0.625vw" : "",
		},
	}),
	plusIcon: {
		paddingTop: theme.spacing(0.25),
	},
	lngMenuItem: {
		textTransform: "capitalize",
	},
	headersButtonsContainer: {
		"@media (max-width:1040px)": {
			flexWrap: "wrap",
		},
	},
}));

interface IProps {
	imageName?: string;
	isImageEditor?: boolean;
	isMyAssets?: boolean;
	children?: JSX.Element;
	pipelineSettings?: any[]; //ImageEditorData_metaData_settings[];
	showSettings?: boolean;
	handleChangePipelineSettings?: (index: number, isOn: boolean, value: any) => void;
	cameraVideoStatus?: "Progress" | "Ready" | null;
	transparentStyle?: boolean;
	isGallery?: boolean;
	isPricing?: boolean;
	isPageNotFound?: boolean;
	isCampaign?: boolean;
	isApiPricing?: boolean;
	isAccountManagement?: boolean | null;
	isOrganizationManagement?: boolean | null;
	clickedIncreaseResButton?: number | null;
	setIsImageLoading?: (flag: boolean) => void;
	imageUrl?: string;
	isImageLoading?: boolean;
	imageCropped?: any;
	imageRef?: RefObject<HTMLImageElement>;
	isUserHasIncreaseResolutionCredits?: boolean;
	setShowIncreaseResolutionPopUp?: (value: any) => void;
	imageOnLoadError?: boolean;
	downloadSaveLoading?: boolean;
	setDownloadSaveLoading?: (value: any) => void;
	isUncropApplied?: boolean;
	croppedAreaPixels?: any;
	croppedArea?: any;
	rotation?: any;
}

interface stateProps {
	pageNumber: number;
}

function ToolHeader({
	children,
	imageName,
	isImageEditor = false,
	isMyAssets = false,
	pipelineSettings,
	handleChangePipelineSettings,
	showSettings,
	cameraVideoStatus,
	isGallery = false,
	isPricing = false,
	isPageNotFound = false,
	isCampaign = false,
	isApiPricing = false,
	transparentStyle,
	isAccountManagement = false,
	isOrganizationManagement = false,
	clickedIncreaseResButton,
	imageUrl,
	isImageLoading,
	setIsImageLoading,
	imageCropped,
	imageRef,
	isUserHasIncreaseResolutionCredits,
	setShowIncreaseResolutionPopUp,
	imageOnLoadError,
	downloadSaveLoading,
	setDownloadSaveLoading,
	isUncropApplied,
	croppedAreaPixels,
	croppedArea,
	rotation,
}: IProps) {
	const history = useHistory();
	const { t } = useTranslation();
	const { isIframe } = IsIframe();
	const context = useContext(Context);
	const classes = useStyles({
		isGallery: isGallery || isPricing,
		isImageEditor,
		isMyAssets,
		translationEnabled: isIframe() && !!context.iframeConfig && context.iframeConfig.enableTranslation,
		isPageNotFound,
	});

	const { user, iframeConfig } = context;
	const { state } = useLocation<stateProps>();

	const { isGuestUser } = IsGuestUser();
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [isPsdLoading, setIsPsdLoading] = useState(false);
	const useErrorPopupVar: any = useErrorPopup();
	const showLogo =
		!context.iframeConfig ||
		(context.iframeConfig &&
			(!context.iframeConfig.whiteLabel ||
				(context.iframeConfig.whiteLabel && context.iframeConfig.customStyle?.logo)));
	const isGalleryAllowed =
		!context.iframeConfig || (context.iframeConfig && context.iframeConfig?.enabledPages?.[PagesEnum.Gallery]);
	const isPlayGroundAllowed =
		!context.iframeConfig || (context.iframeConfig && context.iframeConfig?.enabledPages?.[PagesEnum.Playground]);

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const fromPlatformHub = searchParams.get("from_platform_hub") == "true";

	useEffect(() => {
		const triggerLoginAction = async () => {
			if (!isGuestUser()) {
				if (context.authDialogSource === "Upload image") {
					context.setAuthDialogSource("");
					context.setAuthDialogState(false);
					context.setUploadState(true, history);
				} else if (context.authDialogSource === "Download image") {
					context.setAuthDialogState(false);
					context.setAuthDialogSource("");
					if (imageUrl) {
						await doDownloadImage();
					}
				}
			}
		};
		triggerLoginAction().catch(console.error);
	}, [context.user, context.user?.updated]);

	const doDownloadImage = async () => {
		try {
			setDownloadSaveLoading && setDownloadSaveLoading(true);
			setIsImageLoading && setIsImageLoading(true);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.DOWNLOAD,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
			// if (!hasCredits) {
			// 	setDownloadSaveLoading && setDownloadSaveLoading(false);
			// 	setIsImageLoading && setIsImageLoading(false);
			// 	window.open(RouterConstants.WEB_PLANS.path, "_blank");
			// 	return;
			// }

			toast.info(<Typography>{t("preparingImageForDownload")}</Typography>, {
				toastId: "my_id",
				autoClose: false,
			});

			Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_IMAGE, {
				image_vh: imageName,
			});
			await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);

			const instance = BriaAPI.getInstance(imageName, context.iframeConfig);
			let imgSrc = imageUrl ?? "";
			let imgSrcWithWatermark;
			let imageCroppedWithoutWatermark = imageCropped;
			if (isIframe() && !!iframeConfig?.watermarkPath) {
				imgSrcWithWatermark = imgSrc;
				imgSrc = (await prepareImageForExport(imageUrl ?? "", iframeConfig.organization)) ?? "";
				if (imageCropped && !isUncropApplied) {
					imageCroppedWithoutWatermark = await resize(
						imgSrc,
						croppedAreaPixels,
						rotation,
						croppedArea.width,
						croppedArea.height
					);
				}
			}
			sendPostMessage(
				IframePostMessageTypes.ImageDownload,
				{
					imgSrc,
					imgSrcWithWatermark,
					changes: BriaAPI.getInstance(imageName, context.iframeConfig).getChangesInputsMap(),
				},
				imageName ? imageName : "",
				iframeConfig
			);
			await consumeUserCredits(SubscriptionCreditsTypes.DOWNLOAD);
			await startDownload(
				imgSrc ?? imageUrl ?? "",
				"download-img",
				imageName ? imageName : "",
				isUncropApplied ? undefined : imageCroppedWithoutWatermark,
				undefined,
				!isUncropApplied && imageCroppedWithoutWatermark ? "png" : undefined
			);
			toast.update("my_id", {
				render: (
					<Typography>
						{t("imageWas")}
						<strong>{t("downloaded")}</strong>
					</Typography>
				),
				type: toast.TYPE.SUCCESS,
				autoClose: 3000,
			});
			const modified: boolean = instance.getApiCallsMap().size > 0;
			((imageRef && imageRef?.current?.naturalWidth) ?? 0) <= 512 &&
				!isUserHasIncreaseResolutionCredits &&
				!context.user?.settings?.dontShowIncreaseResPopUp &&
				setShowIncreaseResolutionPopUp &&
				setShowIncreaseResolutionPopUp(true);
			await addRecordToDownloads(imageName ? imageName : "", modified);
			setDownloadSaveLoading && setDownloadSaveLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		} catch (e) {
			setDownloadSaveLoading && setDownloadSaveLoading(false);
			setIsImageLoading && setIsImageLoading(false);
			captureException(e);
		}
	};
	const doSaveAsPSDImage = async () => {
		try {
			setIsPsdLoading(true);
			setIsImageLoading && setIsImageLoading(true);
			Analytics.logEvent(ANALYTICS_EVENTS.SAVE_IMAGE_AS_PSD, {
				image_vh: imageName,
			});
			toast.info(<Typography>Preparing PSD for save</Typography>, {
				toastId: "psd_save_id",
				autoClose: false,
			});
			const client = BriaAPI.getInstance(imageName, context.iframeConfig);
			const modified: boolean = client.getApiCallsMap().size > 0;

			const res = await client.callNonSidCalls(
				client.getLastApiCallSid(),
				organizationUid,
				true,
				undefined,
				true
			);

			const response = await client.imageToPSD(
				res?.data?.sid ?? client.getLastApiCallSid(),
				clickedIncreaseResButton
			);
			let resPath = response.data.res_path;
			sendPostMessage(
				IframePostMessageTypes.ImageSavePSD,
				{
					resPath,
					changes: BriaAPI.getInstance(imageName, context.iframeConfig).getChangesInputsMap(),
				},
				imageName ? imageName : "",
				iframeConfig
			);
			toast.update("psd_save_id", {
				render: (
					<Typography>
						Image was <strong>saved</strong> as PSD
					</Typography>
				),
				type: toast.TYPE.SUCCESS,
				autoClose: 3000,
			});
			await addRecordToDownloads(imageName ? imageName : "", modified, "save", "psd");
			setIsPsdLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		} catch (e) {
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		}
	};
	const doSaveImage = async () => {
		setDownloadSaveLoading && setDownloadSaveLoading(true);
		setIsImageLoading && setIsImageLoading(true);

		toast.info(<Typography>{t("preparingImage")}</Typography>, {
			toastId: "my_id",
			autoClose: false,
		});

		const client = BriaAPI.getInstance(imageName, context.iframeConfig);
		const modified: boolean = client.getApiCallsMap().size > 0;
		Analytics.logEvent(ANALYTICS_EVENTS.SAVE_IMAGE, {
			image_vh: imageName,
		});

		let imgSrc = imageUrl ?? "";
		let imgSrcWithWatermark;
		if (isIframe() && !!iframeConfig?.watermarkPath) {
			imgSrcWithWatermark = imgSrc;
			imgSrc = (await prepareImageForExport(imageUrl ?? "", iframeConfig.organization)) ?? "";
		}

		let vhash = imageName ?? "";
		if (isIframe() && context.iframeConfig?.saveToAssets && imgSrc) {
			const fileData = await fetch(imgSrc);
			const fileBlob = await fileData.blob();
			const file = new File([fileBlob], `${uuid4()}.png`, {
				type: "image/png",
			});
			const orgId =
				context.iframeConfig?.saveDestination === IframeDestinationEnum.ORGANIZATION
					? context.iframeConfig.organization
					: undefined;
			const addToUserAssets = context.iframeConfig?.saveDestination === IframeDestinationEnum.USER;
			await uploadImageViaApi(file, orgId, addToUserAssets);
			await addRecordToDownloads(vhash, modified, "save");
		}

		sendPostMessage(
			IframePostMessageTypes.ImageSave,
			{
				imageUrl: imgSrc,
				imgSrcWithWatermark,
				changes: BriaAPI.getInstance(vhash, context.iframeConfig).getChangesInputsMap(),
			},
			vhash,
			iframeConfig
		);

		await addRecordToDownloads(vhash, modified, "save");

		toast.update("my_id", {
			render: (
				<Typography>
					{t("imageWas")}
					<strong>{t("saved")}</strong>
				</Typography>
			),
			type: toast.TYPE.SUCCESS,
			autoClose: 3000,
		});

		setDownloadSaveLoading && setDownloadSaveLoading(false);
		setIsImageLoading && setIsImageLoading(false);
	};
	const handleDownloadButton = async (isPsd: boolean) => {
		if (isPsd) {
			if (imageUrl) {
				try {
					await doDownloadPSD();
				} catch (err) {
					console.log(err);
					useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
					setIsImageLoading && setIsImageLoading(false);
				}
			}
		} else {
			if (!isGuestUser()) {
				if (imageUrl) {
					try {
						await doDownloadImage();
					} catch (err) {
						console.log(err);
						useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
						setIsImageLoading && setIsImageLoading(false);
					}
				}
			} else {
				context.setAuthDialogSource("Download image");
				context.setAuthDialogState(true);
			}
		}
	};

	const handleUseInCampaign = async (e: any) => {
		if (imageUrl) {
			try {
				history.push(RouterConstants.campaignGeneration.path, {
					image: { finalUrl: imageUrl, visualHash: imageName },
				});
			} catch (err) {
				console.log(err);
				useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
				setIsImageLoading && setIsImageLoading(false);
			}
		}
	};
	const doDownloadPSD = async () => {
		try {
			// if (isGuestUser()) {
			// 	context.setDisplayUpgradeWebSubscriptionPopup(true);
			// 	return;
			// }
			setIsPsdLoading(true);
			setIsImageLoading && setIsImageLoading(true);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.DOWNLOAD_PSD,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
			// if (!hasCredits) {
			// 	setIsImageLoading && setIsImageLoading(false);
			// 	setIsPsdLoading(false);
			// 	context.setDisplayUpgradeWebSubscriptionPopup(true);
			// 	return;
			// }
			Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_PSD, {
				image_vh: imageName,
			});
			await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);
			toast.info(<Typography>Preparing PSD for download</Typography>, {
				toastId: "psd_id",
				autoClose: false,
			});

			const client = BriaAPI.getInstance(imageName, context.iframeConfig);
			const res = await client.callNonSidCalls(
				client.getLastApiCallSid(),
				organizationUid,
				true,
				undefined,
				true
			);

			const response = await client.imageToPSD(
				res?.data?.sid ?? client.getLastApiCallSid(),
				clickedIncreaseResButton
			);

			await consumeUserCredits(SubscriptionCreditsTypes.DOWNLOAD_PSD);
			await startDownload(response.data.res_path, "download-psd", imageName ? imageName : "", undefined, true);
			toast.update("psd_id", {
				render: (
					<Typography>
						PSD file is
						<strong> downloaded</strong>
					</Typography>
				),
				type: toast.TYPE.SUCCESS,
				autoClose: 3000,
			});
			const modified: boolean = client.getApiCallsMap().size > 0;
			await addRecordToDownloads(imageName ? imageName : "", modified, "download", "psd");
		} catch (err) {
			captureException(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		} finally {
			setIsPsdLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		}
	};

	const getDownloadButton = () => {
		return !iframeConfig?.disableDownloadImage &&
			iframeConfig?.enabledFeatures.includes(ToolConfigTabsTypes.PsdDownload.toString()) &&
			!iframeConfig?.disableDownloadPsd ? (
			<BriaDropdownButton
				btnClass={classes.downloadPSDButton}
				text={t("downloadAs")}
				menu={[
					t("image"),
					t("PSD"),
					...(context?.user?.isAdmin() && context.systemView === SystemViewTypes.Admin
						? [t("useInCampaign")]
						: []),
				]}
				handleCloseMenu={(e: any) =>
					e == "PSD" || e == "Image" ? handleDownloadButton(e === "PSD") : handleUseInCampaign(e)
				}
			/>
		) : iframeConfig?.disableDownloadImage &&
		  iframeConfig?.enabledFeatures.includes(ToolConfigTabsTypes.PsdDownload.toString()) &&
		  !iframeConfig?.disableDownloadPsd ? (
			<BriaButton
				className={classes.downloadPSDButton}
				buttonType={BUTTONTYPES.SECONDARYMEDIUM}
				target="_blank"
				onClick={async (e) => {
					handleDownloadButton(true);
				}}
			>
				{t("downloadPsd")}
			</BriaButton>
		) : !iframeConfig?.disableDownloadImage &&
		  (!iframeConfig?.enabledFeatures.includes(ToolConfigTabsTypes.PsdDownload.toString()) ||
				iframeConfig?.disableDownloadPsd) ? (
			<BriaButton
				className={classes.downloadPSDButton}
				buttonType={BUTTONTYPES.SECONDARYMEDIUM}
				target="_blank"
				onClick={async (e) => {
					handleDownloadButton(false);
				}}
			>
				{t("download")}
			</BriaButton>
		) : (
			<></>
		);
	};

	return (
		<>
			<AppBar position="sticky" elevation={0} className={clsx(classes.appBar, transparentStyle && "transparent")}>
				<Toolbar className={clsx(classes?.toolbar)}>
					<Box style={{ display: "flex", alignItems: "center" }}>
						{showLogo && (
							<IconButton
								edge="start"
								aria-label="home page"
								className={classes.iconButton}
								onClick={() => {
									!isIframe() &&
										(context.user?.isAdmin() && context.systemView === SystemViewTypes.Admin
											? history.push(RouterConstants.VDR.path)
											: history.push(RouterConstants.HOME.path));
								}}
								// disabled={
								// 	!context.user?.isAdmin() ||
								// 	(context.systemView !== SystemViewTypes.Admin &&
								// 		context.systemView !== SystemViewTypes.Testing)
								// }
								style={{
									paddingLeft: 0,
									marginLeft: transparentStyle ? -20 : 0,
								}}
							>
								{isIframe() &&
								context.iframeConfig?.whiteLabel &&
								context.iframeConfig?.customStyle?.logo ? (
									<img className={classes.iframelogo} src={context.iframeConfig?.customStyle?.logo} />
								) : transparentStyle ? (
									<LogoWhite />
								) : (
									<Logo height={50} />
								)}
							</IconButton>
						)}
						<Route path="/create-brand/">
							<LocalBreadcrumbs />
						</Route>

						{((context.user?.isAdmin() &&
							(context.systemView === SystemViewTypes.Admin ||
								context.systemView === SystemViewTypes.Viewer)) ||
							context.user?.isViewer() ||
							context.systemView === SystemViewTypes.Testing) &&
							(isGallery || imageName || isPricing || isApiPricing) &&
							(!(isImageEditor && imageName) && !isGallery && !isCampaign ? (
								<Breadcrumbs
									separator={<NavigateNextIcon fontSize="small" color="action" />}
									aria-label="breadcrumb"
									className={classes.breadcrumbs}
									classes={{
										separator: classes.breadcrumbSeparator,
									}}
								>
									{!isIframe() && (
										<Link
											onClick={() => history.push(RouterConstants.HOME.path)}
											className={classes.gallery}
										>
											{t("home")}
										</Link>
									)}

									{/* {isPricing && (
											<Typography className={classes.breadcrumbItem}>{t("pricing")}</Typography>
										)} */}
									{isGalleryAllowed && !isGallery && !isPricing && !isApiPricing ? (
										<Link
											onClick={() =>
												context.user?.isViewer() ||
												(context.user?.isAdmin() &&
													context.systemView === SystemViewTypes.Viewer)
													? history.push(
															`${RouterConstants.GALLERY.path}?page=${
																state?.pageNumber || 1
															}`
													  )
													: history.push(
															`${RouterConstants.GALLERY.path}?page=${
																state?.pageNumber || 1
															}`
													  )
											}
											className={classes.gallery}
										>
											{t("gallery")}
										</Link>
									) : isGalleryAllowed &&
									  !isAccountManagement &&
									  !isOrganizationManagement &&
									  !isPricing &&
									  !isApiPricing ? (
										<Typography className={classes.breadcrumbItem}>{t("gallery")}</Typography>
									) : null}
									{(isGalleryAllowed || !context.iframeConfig?.whiteLabel) &&
										imageName &&
										isImageEditor && (
											<Typography className={classes.breadcrumbItem}>
												{t("playground")}
											</Typography>
										)}
									{isAccountManagement && (
										<Typography className={classes.breadcrumbItem}>{t("account")}</Typography>
									)}
									{isOrganizationManagement && (
										<Typography className={classes.breadcrumbItem}>{t("organization")}</Typography>
									)}
								</Breadcrumbs>
							) : (
								// <Breadcrumbs
								// 	separator={<NavigateNextIcon fontSize="small" color="action" />}
								// 	aria-label="breadcrumb"
								// 	className={classes.breadcrumbs}
								// 	classes={{
								// 		separator: classes.breadcrumbSeparator,
								// 	}}
								// >
								// 	{!isIframe() && (
								// 		<Link
								// 			onClick={() => history.push(RouterConstants.HOME.path)}
								// 			className={classes.gallery}
								// 		>
								// 			{t("home")}
								// 		</Link>
								// 	)}
								// 	{isPricing && (
								// 		<Typography className={classes.breadcrumbItem}>{t("pricing")}</Typography>
								// 	)}
								// 	{isGalleryAllowed && !isGallery && !isPricing ? (
								// 		<Link
								// 			onClick={() =>
								// 				context.user?.isViewer() ||
								// 				(context.user?.isAdmin() &&
								// 					context.systemView === SystemViewTypes.Viewer)
								// 					? history.push(
								// 							`${RouterConstants.GALLERY.path}?page=${
								// 								state?.pageNumber || 1
								// 							}`
								// 					  )
								// 					: history.push(
								// 							`${RouterConstants.GALLERY.path}?page=${
								// 								state?.pageNumber || 1
								// 							}`
								// 					  )
								// 			}
								// 			className={classes.gallery}
								// 		>
								// 			{t("gallery")}
								// 		</Link>
								// 	) : isGalleryAllowed &&
								// 	  !isAccountManagement &&
								// 	  !isOrganizationManagement &&
								// 	  !isPricing ? (
								// 		<Typography className={classes.breadcrumbItem}>{t("gallery")}</Typography>
								// 	) : null}
								// 	{(isGalleryAllowed || !context.iframeConfig?.whiteLabel) &&
								// 		imageName &&
								// 		isImageEditor && (
								// 			<Typography className={classes.breadcrumbItem}>
								// 				{t("playground")}
								// 			</Typography>
								// 		)}
								// 	{isAccountManagement && (
								// 		<Typography className={classes.breadcrumbItem}>{t("account")}</Typography>
								// 	)}
								// 	{isOrganizationManagement && (
								// 		<Typography className={classes.breadcrumbItem}>
								// 			{t("organization")}
								// 		</Typography>
								// 	)}
								// </Breadcrumbs>
								<Box className={classes.headerNavigationContainer}>
									{isGalleryAllowed && !isGallery && !isCampaign && !fromPlatformHub && (
										<BriaButton
											onClick={() => {
												sendPostMessage(
													IframePostMessageTypes.GalleryOpen,
													{},
													"",
													context?.iframeConfig
												);
												if (!iframeConfig?.disableNav) {
													context.user?.isViewer() ||
													(context.user?.isAdmin() &&
														context.systemView === SystemViewTypes.Viewer)
														? history.push(
																`${RouterConstants.GALLERY.path}?page=${
																	state?.pageNumber || 1
																}`
														  )
														: history.push(
																`${RouterConstants.GALLERY.path}?page=${
																	state?.pageNumber || 1
																}`
														  );
												}
											}}
											buttonType={BUTTONTYPES.TEXTSMALL}
											className={classes.homeContainer}
											style={{
												width: isIframe() ? 195 : 117,
											}}
										>
											<img
												src={GalleryIcon}
												style={{
													marginLeft: -3,
													marginRight: 7,
												}}
												className={classes.iconsStyle}
											/>
											{/* <GallerySvg /> */}
											{/* <Typography
											style={{
												fontWeight: 600,
												marginLeft: 7,
											}}
										> */}
											{isIframe() ? t("exploreImages") : "Gallery"}
											{/* </Typography> */}
										</BriaButton>
									)}
									{iframeConfig && iframeConfig.usageText && (
										<Box
											style={{
												display: "flex",
												alignItems: "center",
												marginLeft: "1vw",
											}}
										>
											<IframeUsageMessage usageText={iframeConfig.usageText} />
										</Box>
									)}
									{/* <BriaButton
										onClick={() =>
											history.push(
												RouterConstants.HOME.path
											)
										}
										buttonType={BUTTONTYPES.TEXTSMALL}
										className={classes.homeContainer}
									>
										<HomeIcon />
										<Typography
											style={{
												fontWeight: 600,
												marginLeft: 7,
											}}
										>
											Home
										</Typography>
									</BriaButton> */}
									{!isIframe() && !isGuestUser() && !isCampaign && !fromPlatformHub && (
										<BriaButton
											onClick={() => {
												history.push(RouterConstants.ASSETS_PAGE.path);
											}}
											buttonType={BUTTONTYPES.TEXTSMALL}
											className={classes.assetsContainer}
										>
											<FileIcon
												style={{
													marginLeft: -3,
													marginRight: 7,
												}}
												className={classes.iconsStyle}
											/>
											{/* <Typography
												style={{
													fontWeight: 600,
													marginLeft: 7,
												}}
											> */}
											My Assets
											{/* </Typography> */}
										</BriaButton>
									)}
								</Box>
							))}
						{isCampaign && !isIframe() && (
							<>
								{isGalleryAllowed && (
									<BriaButton
										onClick={() => {
											sendPostMessage(
												IframePostMessageTypes.GalleryOpen,
												{},
												"",
												context?.iframeConfig
											);
											if (!iframeConfig?.disableNav) {
												context.user?.isViewer() ||
												(context.user?.isAdmin() &&
													context.systemView === SystemViewTypes.Viewer)
													? history.push(
															`${RouterConstants.GALLERY.path}?page=${
																state?.pageNumber || 1
															}`
													  )
													: history.push(
															`${RouterConstants.GALLERY.path}?page=${
																state?.pageNumber || 1
															}`
													  );
											}
										}}
										buttonType={BUTTONTYPES.TEXTSMALL}
										className={classes.galleryContainer}
										style={{
											width: isIframe() ? "194px" : "124px",
											marginRight: "8px",
										}}
									>
										<img
											src={GalleryIcon}
											style={{
												marginRight: 8,
											}}
										/>
										{isIframe() ? t("exploreImages") : "Gallery"}
									</BriaButton>
								)}

								{isPlayGroundAllowed && (
									<BriaButton
										onClick={() => {
											history.push(
												`${RouterConstants.GALLERY.path}${
													imageName ? imageName : "8679a552aa79cc96"
												}`
											);
										}}
										buttonType={BUTTONTYPES.TEXTSMALL}
										className={classes.playGroundIconContainer}
									>
										<img
											src={playGroundIcon}
											style={{
												marginLeft: -3,
												marginRight: 7,
											}}
										/>
										AI Editor
									</BriaButton>
								)}
								{
									<BriaButton
										onClick={() => {
											history.push(RouterConstants.ASSETS_PAGE.path);
										}}
										buttonType={BUTTONTYPES.TEXTSMALL}
										className={classes.assetsContainer}
										style={{ padding: "8px 16px", marginLeft: "0px", marginRight: "8px" }}
									>
										<FileIcon
											style={{
												marginLeft: -3,
												marginRight: 7,
											}}
											className={classes.iconsStyle}
										/>
										My Assets
									</BriaButton>
								}
								<BriaButton
									style={{
										marginLeft: "14px",
										padding: "7.5px 16px",
										margin: "0px",
										backgroundColor: isCampaign ? "#E7E7E7" : "",
										opacity: isCampaign && 1,
										lineHeight: "normal",
									}}
									className={clsx(classes.upgradeButton, transparentStyle && "transparentButton")}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										history.push(RouterConstants.campaignGeneration.path);
									}}
								>
									<img style={{ paddingRight: "8px" }} src={WandIcon} alt="" />
									{t("campaignGeneration")}
								</BriaButton>
							</>
						)}
					</Box>

					{/* <Box className={classes.actionsContainer}>{children}</Box> */}
					<Box className={classes.headersButtonsContainer} style={{ display: "flex", alignItems: "center" }}>
						{isPricing && !isIframe() && (
							<BriaButton
								className={clsx(
									classes.uploadButton,
									classes.apiButton,
									transparentStyle && "transparentButton"
								)}
								buttonType={BUTTONTYPES.TEXTBUTTON}
								target="_blank"
								onClick={async (e) => {
									window.open(LinksConst.CONTACT_US_FORM_LINK, "_blank");
									Analytics.logEvent(ANALYTICS_EVENTS.HOME_HEADER_CONTACT_SALES);
								}}
							>
								Contact Sales
							</BriaButton>
						)}
						{/* <ShowAt
								admin
								condition={!isPricing && !isGallery && !isImageEditor && !isMyAssets && !isCampaign}
							>
								<BriaButton
									className={clsx(classes.upgradeButton, transparentStyle && "transparentButton")}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={() => {
										history.push(RouterConstants.campaignGeneration.path);
									}}
								>
									<img style={{ paddingRight: "8px" }} src={WandIcon} alt="" />
									{t("campaignGeneration")}
								</BriaButton>
							</ShowAt> */}
						{!isIframe() && !isImageEditor && !isGallery && !isPricing && (
							<>
								<BriaButton
									style={{
										marginRight: isCampaign ? "8px" : "",
										padding: isCampaign ? "8px 16px" : "",
									}}
									className={classes.shareFeedbackButton}
									buttonType={BUTTONTYPES.TEXTBUTTON}
									onClick={(e) => {
										context.setDisplayFeedbackDialogState(true);
										e.currentTarget.blur();
									}}
								>
									{t("giveFeedback")}
								</BriaButton>

								{!isGuestUser() && !isImageEditor && !isCampaign && (
									<BriaButton
										className={clsx(classes.upgradeButton, transparentStyle && "transparentButton")}
										buttonType={BUTTONTYPES.TEXTBUTTON}
										onClick={() => {
											history.push(RouterConstants.ASSETS_PAGE.path);
										}}
									>
										{t("myAssets")}
									</BriaButton>
								)}
							</>
						)}
						{/* {!isIframe() && !isGuestUser() && (
								<>
									<ShowAt showAlways>
										<BriaButton
											className={clsx({
												[classes.upgradeButton]: true,
												[classes.purple]: isGuestUser(),

												transparentButton: transparentStyle,
											})}
											buttonType={BUTTONTYPES.TEXTBUTTON}
											onClick={(e) => {
												history.push(RouterConstants.WEB_PLANS.path);
											}}
										>
											<Icon
												style={{
													marginRight: 7,
												}}
												name="crown-icon"
											/>
											{t("upgradeNow")}
										</BriaButton>
									</ShowAt>
								</>
							)} */}
						{!isCampaign && !isIframe() && (
							<BriaButton
								className={clsx(
									classes.uploadButton,
									classes.apiButton,
									transparentStyle && "transparentButton"
								)}
								buttonType={BUTTONTYPES.TEXTBUTTON}
								target="_blank"
								onClick={async () => {
									window.open(RouterConstants.API_PLANS.path, "_blank");
								}}
							>
								Pricing
							</BriaButton>
						)}
						{!isCampaign && !isIframe() && (
							<BriaButton
								href={`${LinksConst.BRIA_WEBSITE}api/`}
								className={clsx(
									classes.uploadButton,
									classes.apiButton,
									transparentStyle && "transparentButton"
								)}
								buttonType={BUTTONTYPES.TEXTBUTTON}
								target="_blank"
								onClick={async (e) => {
									e.currentTarget.blur();
									Analytics.logEvent(ANALYTICS_EVENTS.HOME_HEADER_API);
								}}
							>
								API
							</BriaButton>
						)}
						{!isCampaign && (!isIframe() || (isIframe() && !context.iframeConfig?.disableUpload)) && (
							<BriaButton
								className={clsx(classes.uploadButton, transparentStyle && "transparentButton")}
								buttonType={BUTTONTYPES.TEXTBUTTON}
								onClick={(e) => {
									if (!isGuestUser()) {
										context.setUploadState(true, history);
									} else {
										context.setAuthDialogSource("Upload image");
										context.setAuthDialogState(true);
									}
									e.currentTarget.blur();
								}}
							>
								<PlusIcon className={clsx(classes.icon, classes.plusIcon)} />
								{t("uploadImage")}
							</BriaButton>
						)}
						{(isGallery || isImageEditor || isCampaign) && !isIframe() && (
							<BriaButton
								style={{
									backgroundColor: isCampaign ? "#5300C9" : "",
									color: isCampaign ? "white" : "",
								}}
								className={clsx(classes.contactSales, transparentStyle && "transparentButton")}
								buttonType={isImageEditor ? BUTTONTYPES.PRIMARYMEDIUM : BUTTONTYPES.SECONDARYMEDIUM}
								onClick={(e) => {
									window.open(LinksConst.CONTACT_US_FORM_LINK, "_blank");
								}}
							>
								{t("contactSales")}
							</BriaButton>
						)}
						{isImageEditor &&
							isIframe() &&
							iframeConfig &&
							iframeConfig.enableDownload &&
							getDownloadButton()}

						{
							// isImageEditor && !isIframe() ? (
							// 	<BriaButton
							// 		disabled={isPsdLoading || !imageName || imageOnLoadError}
							// 		className={classes.downloadPSDButton}
							// 		buttonType={BUTTONTYPES.SECONDARYMEDIUM}
							// 		target="_blank"
							// 		onClick={async (e) => {
							// 			handleDownloadButton(true);
							// 		}}
							// 	>
							// 		Download PSD
							// 	</BriaButton>
							// ) :
							isImageEditor &&
								iframeConfig &&
								iframeConfig.enabledFeatures.includes(ToolConfigTabsTypes.PsdDownload.toString()) &&
								!iframeConfig.disableSavePsd && (
									<BriaButton
										disabled={
											isPsdLoading ||
											(!iframeConfig?.alwaysEnableSave &&
												!imageCropped &&
												BriaAPI.getInstance(imageName, context.iframeConfig).getApiCallsMap()
													.size === 0)
											//  ||disableDownloadButton
										}
										className={classes.downloadPSDButton}
										buttonType={BUTTONTYPES.SECONDARYMEDIUM}
										target="_blank"
										onClick={async () => {
											doSaveAsPSDImage();
										}}
										style={{
											width: isIframe() && context.iframeConfig?.enableTranslation ? 140 : 133,
										}}
									>
										{t("saveAsPsd")}
									</BriaButton>
								)
						}
						{
							// isImageEditor && !isIframe() ? (
							// 	<BriaButton
							// 		disabled={downloadSaveLoading || imageOnLoadError || !imageName}
							// 		className={classes.downloadButton}
							// 		buttonType={BUTTONTYPES.PRIMARYMEDIUM}
							// 		target="_blank"
							// 		onClick={async (e) => {
							// 			handleDownloadButton(false);
							// 		}}
							// 	>
							// 		Download
							// 	</BriaButton>
							// ) :
							isImageEditor && isIframe() && !iframeConfig?.disableSave && (
								<BriaButton
									disabled={
										downloadSaveLoading ||
										(!iframeConfig?.alwaysEnableSave &&
											!imageCropped &&
											BriaAPI.getInstance(imageName, context.iframeConfig).getApiCallsMap()
												.size === 0)
										//  ||disableDownloadButton
									}
									className={classes.downloadButton}
									buttonType={BUTTONTYPES.PRIMARYMEDIUM}
									target="_blank"
									onClick={async () => {
										doSaveImage();
									}}
									style={{
										width: "unset",
									}}
								>
									{iframeConfig?.saveLabel ? iframeConfig?.saveLabel : t("save")}
								</BriaButton>
							)
						}
						{isIframe() && context.iframeConfig?.enableCloseButton && (
							<Box style={{ marginLeft: 16 }}>
								<CloseButton />
							</Box>
						)}
						{!isIframe() && isGuestUser() && (
							<ShowAt showAlways>
								<BriaButton
									className={clsx({
										[classes.upgradeButton]: true,
										[classes.purple]: isGuestUser(),

										transparentButton: transparentStyle,
									})}
									buttonType={BUTTONTYPES.TEXTSMALL}
									onClick={(e) => {
										context.setAuthDialogState(true);
										e.currentTarget.blur();
									}}
									style={{ height: 40, marginLeft: 16 }}
								>
									{t("getStarted")}
								</BriaButton>
							</ShowAt>
						)}
						{/*{isIframe() && iframeConfig?.enableTranslation && (*/}
						{/*	<Select*/}
						{/*		value={i18n.language}*/}
						{/*		onChange={(event) => {*/}
						{/*			i18n.changeLanguage(event.target.value as string);*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		{Object.entries(LanguagesEnum)*/}
						{/*			.filter(([key, value]) => iframeConfig.enabledLanguages[value as LanguagesEnum])*/}
						{/*			.map(([key, value]) => (*/}
						{/*				<MenuItem*/}
						{/*					value={LanguageCodesEnum[value as LanguagesEnum]}*/}
						{/*					className={classes.lngMenuItem}*/}
						{/*				>*/}
						{/*					{key}*/}
						{/*				</MenuItem>*/}
						{/*			))}*/}
						{/*	</Select>*/}
						{/*)}*/}
						{!isIframe() && !isGuestUser() && (
							<UserMenu
								pipelineSettings={pipelineSettings}
								handleChangePipelineSettings={handleChangePipelineSettings}
								showSettings={showSettings}
							/>
						)}
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}

export default ToolHeader;
