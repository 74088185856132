import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

interface IProps {
	label?: string;
	labelClassName?: string;
	labelIcon?: any;
	className?: string;
	children: React.ReactNode;
	showLabel?: boolean;
	validateOn?: boolean;
	validationMessage?: string;
	optional?: boolean;
}

const InputLayout = ({
	label,
	labelClassName,
	labelIcon,
	showLabel = true,
	validateOn = false,
	validationMessage,
	className,
	children,
	optional = false,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "inputLayout" });
	const classes = useStyles();

	return (
		<Box className={className}>
			{showLabel && (
				<Box className={classes.labelWrapper}>
					<Box className={classes.textLabel}>
						<Typography className={`${classes.inputLabel} ${labelClassName}`}>
							{label || <span>&nbsp;</span>}
						</Typography>
						{labelIcon && <img className={classes.labelIcon} src={labelIcon} />}
					</Box>
					{optional && <Typography className={classes.optional}>{t("optional")}</Typography>}
				</Box>
			)}
			{children}
			{validateOn && <Typography className={`${classes.inputError}`}>{validationMessage}</Typography>}
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	labelWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	optional: {
		fontSize: "14px",
		color: "rgba(91, 91, 91, 1)",
	},
	textLabel: {
		display: "inline-flex",
		overflow: "visible",
		paddingBottom: "12px",
		whiteSpace: "nowrap",
	},
	inputLabel: {
		fontWeight: 600,
		fontSize: "min(1.3vw, 13px)",
		color: "black",
	},
	labelIcon: {
		marginLeft: "6px",
		marginTop: "2px",
		width: "16px",
		height: "16px",
	},
	inputError: {
		fontSize: "12px",
		margin: "5px 0",
		color: "red",
	},
}));

export default InputLayout;
