import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function AllFacesButton(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 20 20" {...props}>
			<g
				id="Icons"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="Rounded"
					transform="translate(-746.000000, -3926.000000)"
				>
					<g
						id="Places"
						transform="translate(100.000000, 3866.000000)"
					>
						<g
							id="-Round-/-Places-/-all_inclusive"
							transform="translate(646.000000, 54.000000)"
						>
							<g>
								<polygon
									id="Path"
									points="0 0 24 0 24 24 0 24"
								></polygon>
								<path
									d="M20.22,6.86 C18.22,6.26 16.16,6.82 14.83,8.15 L12,10.66 L10.48,12 L10.49,12 L7.8,14.39 C6.99,15.2 5.85,15.54 4.68,15.31 C3.43,15.06 2.4,14.06 2.11,12.82 C1.59,10.59 3.27,8.62 5.4,8.62 C6.31,8.62 7.16,8.97 7.84,9.65 L8.31,10.06 C8.69,10.4 9.26,10.4 9.64,10.06 C10.09,9.66 10.09,8.96 9.64,8.56 L9.22,8.2 C8.2,7.18 6.84,6.62 5.4,6.62 C2.42,6.62 0,9.04 0,12 C0,14.96 2.42,17.38 5.4,17.38 C6.84,17.38 8.2,16.82 9.17,15.85 L12,13.35 L12.01,13.36 L13.52,12 L13.51,12 L16.2,9.61 C17.01,8.8 18.15,8.46 19.32,8.69 C20.57,8.94 21.6,9.94 21.89,11.18 C22.41,13.41 20.73,15.38 18.6,15.38 C17.7,15.38 16.84,15.03 16.16,14.35 L15.68,13.93 C15.3,13.59 14.73,13.59 14.35,13.93 C13.9,14.33 13.9,15.03 14.35,15.43 L14.77,15.8 C15.79,16.81 17.14,17.37 18.59,17.37 C21.86,17.37 24.45,14.47 23.92,11.12 C23.62,9.13 22.15,7.43 20.22,6.86 Z"
									id="🔹-Icon-Color"
									fill="#979797"
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
}

export default AllFacesButton;
