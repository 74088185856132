import React from "react";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

/**
 * showAt: prop will show the content to the user.
 * children: Component content
 */
interface IFakeHiddenContent {
	showAt: boolean;
	children: JSX.Element;
}

/**
 * This component is used in case u want to execeute a logic but not showing the content to user
 * Example : <FakeHiddenContent show={flase}> This is mounted in dom but not shown </FakeHiddenContent>
 * Example : <FakeHiddenContent show={true}> This is mounted in dom and shown </FakeHiddenContent>
 */

export default function FakeHiddenContent({
	showAt,
	children,
}: IFakeHiddenContent) {
	const classes = useStyles();
	const className = clsx({ [classes.fakeHidden]: !showAt });
	return <Box className={className}>{children}</Box>;
}

const useStyles = makeStyles((theme: Theme) => ({
	fakeHidden: {
		position: "absolute",
		zIndex: -100,
		opacity: 0,
	},
}));
