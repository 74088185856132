import React, { useEffect } from "react";
import { Box, Button, Divider, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import ColorInput from "./Tools/Brand/ColorPallete/ColorInput/ColorInput";
import { useTranslation } from "react-i18next";
import { ChromePicker } from "react-color";
import alterTri from "../assets/alert-triangle.svg";
import Context from "../Context/Context";
interface IProps {
	shouldOpen: boolean;
	onClose: () => void;
	colors: string[];
	color: string;
	setColor: (arg: string) => void;
	isMatchBrandsColors: boolean;
	setIsMatchBrandsColors: (arg: boolean) => void;
}

function InputColorDialog(props: IProps) {
	const { t } = useTranslation("translation", { keyPrefix: "CampainGeneration" });
	const classes = useStyles();
	const handleChangeComplete = (e: any) => {
		props.setColor(e.hex);
	};
	const filtertedColors = props.colors
		?.map((color: string) => color.toUpperCase())
		?.filter((color: string) => color && /^#[0-9A-F]{6}$/i.test(color));

	useEffect(() => {
		if (props.color && filtertedColors.length > 0) {
			if (filtertedColors.includes(props.color?.toUpperCase())) {
				props.setIsMatchBrandsColors(true);
			} else {
				props.setIsMatchBrandsColors(false);
			}
		} else {
			props.setIsMatchBrandsColors(true);
		}
	}, [props.shouldOpen]);

	return (
		<Dialog
			BackdropProps={{ invisible: true }}
			open={props.shouldOpen}
			onClose={props.onClose}
			PaperProps={{ className: classes.dialog }}
		>
			<Box id="input-color-dialog" className={classes.mainContainer}>
				<Box className={classes.closeBtnContainer}>
					<IconButton className={classes.iconButton} onClick={props.onClose}>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</Box>
				{filtertedColors.length > 0 && (
					<>
						<Box>
							<Typography className={classes.mainTextsLabel}>{t("brandColors")}</Typography>
						</Box>
						<Box className={classes.colorsLables}>
							{filtertedColors.map((color: string, index: number) => (
								<Box key={index}>
									{index < 2 && (
										<Typography className={classes.colorHeader}>
											{index === 0 && <>{t("Primary")}</>}
											{index === 1 && <>{t("Secondary")}</>}
										</Typography>
									)}
								</Box>
							))}
						</Box>
						<Box className={classes.colorOptions}>
							{filtertedColors.map(
								(color: string, index: number) =>
									color && (
										<Box
											onClick={() => {
												props.setColor(color);
												props.onClose();
											}}
											style={{ backgroundColor: color }}
											className={classes.colorPreview}
											key={index}
										></Box>
									)
							)}
						</Box>
						<Divider className={classes.divider} />
					</>
				)}
				<Box>
					<Typography className={`${classes.mainTextsLabel} ${classes.allColorsLabel}`}>
						{t("allColors")}
					</Typography>
				</Box>
				<ChromePicker
					styles={{
						default: {
							picker: {
								width: "auto",
								boxShadow: "none",
							},
						},
					}}
					color={props.color}
					onChangeComplete={handleChangeComplete}
				/>
			</Box>
			{!props.isMatchBrandsColors && (
				<Box className={classes.errorMessage}>
					<img src={alterTri} alt="alter" />
					<Typography className={classes.alertMessage}>{t("brandAlert")}</Typography>
				</Box>
			)}
		</Dialog>
	);
}
const useStyles = makeStyles((theme: Theme) => ({
	dialog: {
		borderRadius: "10px",
		width: "272px",
		justifyContent: "center",

		position: "absolute",
		left: "418px",
		top: "31%",
		backgroundColor: "transparent",
		boxShadow: "none",
		border: "none",
	},
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "24px 16px 20px 16px",
		backgroundColor: "white",
		boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.05)",
		borderRadius: "8px",
		border: " 1px solid #E7E7E7",
	},
	closeBtnContainer: {
		alignSelf: "flex-end",
		display: "flex",
		top: "6px",
		position: "absolute",
		right: "5px",
		width: "12px",
		height: "12px",
	},
	mainTextsLabel: {
		marginBottom: "8px",
		color: "#1A0638",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "normal",
	},
	allColorsLabel: {
		marginTop: "12px !important",
	},
	colorsLables: {
		display: "flex",
		gap: "8px",
	},
	colorHeader: {
		marginBottom: "12px",
		fontWeight: 400,
		fontSize: "12px",
		color: "#000",
		lineHeight: "normal",
	},
	colorOptions: {
		display: "flex",
		gap: "12px",
		"& > div": {
			alignSelf: "end",
			justifyContent: "end",
		},
	},
	errorMessage: {
		borderRadius: "8px",
		border: " 1px solid #E7E7E7",
		backgroundColor: "white",
		marginTop: "4px",
		display: "flex",
		wordBreak: "break-word",
		boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.05)",
		padding: "8px 10px",
		alignItems: "flex-start",
	},
	alertMessage: {
		fontWeight: 500,
		lineHeight: "normal",
		paddingLeft: "2px",
		fontSize: "12px",
	},
	iconButton: {
		width: "12px",
		height: "12px",
		padding: "0px",
	},
	closeIcon: {
		width: "12px",
		height: "12px",
	},
	colorPreview: {
		width: "29px",
		height: "29px",
		borderRadius: "50%",
		border: "3px solid #E7E7E7",
	},
	divider: {
		backgroundColor: "#E7E7E7",
		marginTop: "12px",
	},
}));
export default InputColorDialog;
