import { Box, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useContext } from "react";
import { sendPostMessage } from "../../Helpers/iframe";
import { IframePostMessageTypes } from "../../Constants/IframeConstants";
import CloseIcon from "@material-ui/icons/Close";
import Context from "../../Context/Context";

export function CloseButton() {
	const classes = useStyles();
	const context = useContext(Context);

	return (
		<Box
			className={clsx(classes.closeButtonContainer)}
			onClick={(e) => {
				sendPostMessage(IframePostMessageTypes.CloseClicked, {}, "", context.iframeConfig);
			}}
		>
			<CloseIcon className={clsx(classes.closeButton)} />
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButtonContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: 8,
			boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
			width: 40,
			height: 40,
			padding: "20px 16px",
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#E7E7E7",
			},
		},
		closeButton: {
			color: "#5B5B5B",
		},
	})
);
