import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useContext } from "react";
import Flex from "../../../../Common/Wrappers/Flex";
import BriaButton, { BUTTONTYPES } from "../../../../UI/BriaButton";
import { BrandContext } from "../../BrandPage/BrandPage";
import { brandSteps } from "../../steps";
import { INavigator } from "../Navigator";
import { useHistory } from "react-router-dom";

export default function NavigationBtns({
	currentIndex,
	prev,
	next,
	atLast,
	onSave,
}: INavigator) {
	const { push } = useHistory();
	const classes = useStyles();
	const { defaultStepValue, brandState, generalState } =
		useContext(BrandContext);
	const currentStep = brandSteps()?.[currentIndex];
	const {
		mandatory,
		resultKey,
		renderSideNav = () => <></>,
		renderBottomNav = () => <></>,
		nextText,
		hideNav,
		renderNext,
		completedOn = () => false, // some steps completion state is not only dependent on brandState?.[resultKey], so we'll use general state also
	} = currentStep;
	const disableNext = !defaultStepValue && mandatory;
	const stepState =
		(brandState as any)?.[resultKey] || completedOn(generalState);

	const skipable = !stepState && !mandatory;
	const canContinue = !!stepState;

	const continueBtnProps = {
		onClick: atLast ? onSave : next,
		title: atLast ? "Save" : "Next",
	};

	const emptyCalback = () => {};

	const { onClick = emptyCalback, title: continueTitle } = continueBtnProps;

	const continueText = nextText || "Next";

	return (
		<Box textAlign="center" width="100%">
			<Box className={classes.wholeWrapper}>
				{renderSideNav({ next, push })}
				{!hideNav && (
					<>
						<Box className={classes.navigationBtnWrapper}>
							{!atLast ? (
								<>
									{renderNext ? (
										renderNext()
									) : (
										<BriaButton
											className={classes.btn}
											onClick={() => next()}
											buttonType={BUTTONTYPES.PRIMARY}
											disabled={disableNext}
										>
											{continueText}
										</BriaButton>
									)}
								</>
							) : (
								<BriaButton
									className={classes.btn}
									onClick={onSave}
									buttonType={BUTTONTYPES.PRIMARY}
									disabled={disableNext}
								>
									Finish
								</BriaButton>
							)}
						</Box>
					</>
				)}
			</Box>
			<Box>{renderBottomNav({ next, push })}</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	navigationBtnWrapper: {
		justifyContent: "center",
		gap: "0.5rem",
		display: "flex",
	},
	btn: {
		width: 110,
	},
	wholeWrapper: {
		display: "flex",
		justifyContent: "center",
		gap: "0.5rem",
		flex: 1,
		alignItems: "center",
	},
}));
