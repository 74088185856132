import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { getDatabase, ref, update } from "firebase/database";
import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useThemeContext } from "../Context/BriaThemeProvider";
import Context from "../Context/Context";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import BriaSwitch from "./UI/BriaSwitch";
import BriaSelect from "./UI/BriaSelect";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ToolsViewerMode } from "./Tools/utilities";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import Logout from "../assets/icons/Logout";
import { Dialog } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingLeft: 35,
		paddingRight: 22,
		width: "100%",
	},
	section: {
		marginTop: 27,
		marginBottom: theme.spacing(3),
		padding: 0,
	},
	label: {
		margin: 0,
		fontWeight: theme.typography.fontWeightBold,
	},
	field: {
		marginLeft: theme.spacing(3),
	},
	username: {
		fontSize: 14,
		marginBottom: theme.spacing(3),
	},
	button: {
		width: 119,
		height: 40,
		alignSelf: "flex-end",
	},
	title: {
		fontWeight: theme.typography.fontWeightBold,
	},
}));

interface IProps {
	handleClose: () => void;
}

const AccountSettings: React.FC<IProps> = ({ handleClose }) => {
	const classes = useStyles();
	const context = useContext(Context);
	const { isDarkMode, handleThemeChange } = useThemeContext();
	const { i18n, t } = useTranslation();

	const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);

	const initialValues = {
		withLabels: context.preferences?.withLabels,
		// style: context.preferences?.style,
	};

	const ValidationSchema = Yup.object().shape({
		withLabels: Yup.boolean(),
		// style: Yup.string(),
	});

	const handleSubmit = (values: any) => {
		Object.keys(values).forEach((key) =>
			values[key] === undefined ? delete values[key] : {}
		);
		const db = getDatabase();
		const settingsRef = ref(db, `users/${context.user?.uid}/settings`);
		update(settingsRef, values);
	};

	return (
		<>
			<Container className={classes.root}>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h4" className={classes.title}>
						{t("Account")}
					</Typography>
					<IconButton
						color="inherit"
						aria-label="close"
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Divider />

				{/* <Container className={classes.section}>
							<Grid container>
								<Grid item xs={4}>
									<Typography className={classes.label}>
										{t("interfaceLookAndFeel")}
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Grid container alignItems="center">
										<Grid item>
											<Typography
												style={{
													opacity: isDarkMode
														? "0.7"
														: "1",
												}}
											>
												{t("brightMode")}
											</Typography>
										</Grid>
										<Grid item>
											<Field
												as={BriaSwitch}
												disabled
												color="default"
												defaultChecked={isDarkMode}
												onChange={(e: any) => {
													handleThemeChange();
												}}
											/>
										</Grid>
										<Grid item>
											<Typography
												style={{
													opacity: !isDarkMode
														? "0.7"
														: "1",
												}}
											>
												{t("darkMode")}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container> 

						<Container className={classes.section} disableGutters>
							<FormControlLabel
								label={
									<Typography className={classes.label}>
										{t("language")}
									</Typography>
								}
								labelPlacement="start"
								control={
									<Field
										as={BriaSelect}
										variant="outlined"
										className={classes.field}
										value={i18n.language}
										onChange={(event: any) => {
											i18n.changeLanguage(
												event.target.value as string
											);
										}}
									>
										<MenuItem value="en">English</MenuItem>
										<MenuItem value="fr">French</MenuItem>
										<MenuItem value="ar">Arabic</MenuItem>
										<MenuItem value="he">Hebrew</MenuItem>
									</Field>
								}
							/>
						</Container>
						<Container className={classes.section}>
							<Grid container>
								<Grid item xs={3}>
									<Typography className={classes.label}>
										{t("style")}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<FormControl component="fieldset">
										<RadioGroup
											defaultValue={
												initialValues.style ??
												"thumbnails"
											}
											name="style"
											onChange={(e: any) => {
												handleSubmit({
													...values,
													style: e.target.value,
												});
												setFieldValue(
													"style",
													e.target.value
												);
											}}
										>
											<FormControlLabel
												value={
													ToolsViewerMode.thumbnails
												}
												control={
													<Radio color="primary" />
												}
												label={t("thumbnails")}
											/>
											<FormControlLabel
												value={ToolsViewerMode.sliders}
												control={
													<Radio color="primary" />
												}
												label={t("sliders")}
											/>
											<FormControlLabel
												value={ToolsViewerMode.both}
												control={
													<Radio color="primary" />
												}
												label={t("both")}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
							</Grid>
						</Container> */}
				{/* <Container className={classes.section}>
							<Grid container>
								<Grid item xs={3}>
									<Typography className={classes.label}>
										{t("labels")}
									</Typography>
								</Grid>
								<Grid item xs={7}>
									<Grid
										component="label"
										container
										alignItems="center"
										spacing={1}
									>
										<Grid item>{t("hide")}</Grid>
										<Grid item>
											<Field
												name="withLabels"
												as={BriaSwitch}
												color="default"
												defaultChecked={
													initialValues.withLabels
												}
												onChange={(e: any) => {
													handleSubmit({
														...values,
														withLabels:
															e.target.checked,
													});
													setFieldValue(
														"withLabels",
														e.target.checked
													);
												}}
											/>
										</Grid>
										<Grid item>{t("show")}</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container> */}
				<Container className={classes.section}>
					<Box display="flex" flexDirection="column">
						<Typography className={classes.username}>
							{context.user?.userName}
						</Typography>
						<BriaButton
							buttonType={BUTTONTYPES.SECONDARY}
							onClick={() => {
								setLogoutDialogOpen(true);
							}}
						>
							{t("Logout")}
						</BriaButton>
					</Box>
				</Container>
			</Container>
			<Dialog
				maxWidth="sm"
				fullWidth
				open={logoutDialogOpen}
				onClose={() => {
					setLogoutDialogOpen(false);
				}}
			>
				<>
					<Container className={classes.root}>
						<Box display="flex" justifyContent="space-between">
							<Typography variant="h4">{t("Logout")}</Typography>
							<IconButton
								color="inherit"
								aria-label="close"
								onClick={() => {
									setLogoutDialogOpen(false);
								}}
							>
								<CloseIcon />
							</IconButton>
						</Box>
						<Divider />

						<Container className={classes.section}>
							<Typography>{t("logoutConfirm")}</Typography>
						</Container>

						<Container className={classes.section}>
							<Box display="flex" justifyContent="space-between">
								<BriaButton
									buttonType={BUTTONTYPES.SECONDARY}
									onClick={() => {
										setLogoutDialogOpen(false);
									}}
								>
									{t("cancel")}
								</BriaButton>
								<BriaButton
									buttonType={BUTTONTYPES.SECONDARY}
									onClick={() => {
										context.logOut();
									}}
								>
									{t("imSure")}
								</BriaButton>
							</Box>
						</Container>
					</Container>
				</>
			</Dialog>
		</>
	);
};

export default AccountSettings;
