import { Box, CircularProgress, Link, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
	shouldOpen: boolean;
	onClose: any;
	mainAssetFile: string;
	mainAssetWithoutBacground: string;
	handleKeepBackGround: () => void;
	handleReplaceBackGround: () => void;
	imagesReady: boolean;
}

function CreateNewBrandDialog(props: IProps) {
	const theme = useTheme();
	const classes = useStyles({});
	const { t } = useTranslation("translation", { keyPrefix: "keepReolaceBackgroundDialog" });
	const [isMainImageHovered, setIsMainImageHovered] = useState(false);
	const [isImageWithoutBackgroundHovered, setIsImageWithoutBackgroundHovered] = useState(false);

	return (
		<Dialog
			open={props.shouldOpen}
			maxWidth={false}
			classes={{
				paper: classes.dialog,
			}}
		>
			<Box className={classes.mainContainer}>
				<Box className={classes.closeBtnContainer}>
					<IconButton
						className={classes.iconButton}
						color="inherit"
						aria-label="close"
						onClick={props.onClose}
					>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</Box>
				<Typography className={classes.keepBackground}>{t("title")}</Typography>
				<Typography className={classes.description}>{t("description")}</Typography>
				{props.imagesReady ? (
					<>
						<Box className={classes.imagesContainer}>
							<Box className={classes.imgContainer}>
								<img
									onClick={props.handleKeepBackGround}
									className={classes.img}
									src={props.mainAssetFile}
									alt=""
									onMouseEnter={() => setIsMainImageHovered(true)}
									onMouseLeave={() => setIsMainImageHovered(false)}
								/>
							</Box>
							<Box className={classes.imgContainer}>
								<img
									className={`${classes.img} ${classes.imgWithBackground}`}
									src={props.mainAssetWithoutBacground}
									alt=""
									onClick={props.handleReplaceBackGround}
									onMouseEnter={() => setIsImageWithoutBackgroundHovered(true)}
									onMouseLeave={() => setIsImageWithoutBackgroundHovered(false)}
								/>
							</Box>
						</Box>
						<Box className={classes.imagesButtons}>
							<Link
								onClick={props.handleKeepBackGround}
								className={`${classes.underlineButton} ${classes.buttonsText} ${
									isMainImageHovered && classes.hovered
								}`}
							>
								{t("keepBackground")}
							</Link>

							<Link
								onClick={props.handleReplaceBackGround}
								className={`${classes.underlineButton} ${classes.buttonsText} ${
									isImageWithoutBackgroundHovered && classes.hovered
								}`}
							>
								{t("replaceBackground")}
							</Link>
						</Box>
					</>
				) : (
					<Box className={classes.imageLoader}>
						<CircularProgress
							style={{
								width: 20,
								height: 20,
								fontSize: 24,
								color: theme.palette.primary.main,
							}}
						></CircularProgress>
					</Box>
				)}
			</Box>
		</Dialog>
	);
}

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	dialog: (props) => ({
		borderRadius: "10px !important",
		width: "539px",
		height: "462px",
	}),
	mainContainer: {
		padding: "32px",
		paddingBottom: "40px",
	},
	keepBackground: {
		color: "#1A0638",
		fontSize: "24px",
		fontWeight: 600,
		lineHeight: "normal",
	},
	description: {
		color: "#000",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "normal",
		marginTop: "8px",
	},
	imagesContainer: {
		marginTop: "40px",
		display: "flex",
		gap: "26px",
	},
	imagesButtons: {
		marginTop: "14px",
		display: "flex",
		gap: "26px",
		justifyContent: "space-evenly",
	},
	imgContainer: {
		width: "100%",
		height: "100%",
		backgroundColor: "#FAFAFA",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	img: {
		height: " 225px",
		maxWidth: " 225px",
		objectFit: "contain",
	},
	buttonsText: {
		color: "#5300C9",
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "16px",
		letterSpacing: " 0.32px",
	},
	underlineButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		fontWeight: 600,
		padding: "12px 16px",
		borderRadius: 8,
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "#E7E7E7",
			textDecoration: "none",
		},
		width: "50%",
		textAlign: "center",
	},
	hovered: {
		backgroundColor: "#E7E7E7",
		textDecoration: "none",
	},
	closeBtnContainer: {
		position: "absolute",
		top: "16px",
		right: "16px",
	},
	iconButton: {
		padding: "0px",
	},
	imgWithBackground: {
		backgroundImage: `url(/transparent.jpg)`,
		overflow: "hidden",
		maxWidth: "225px",
		height: "225px",
		objectFit: "contain",
		backgroundSize: "cover",
	},
	imageLoader: {
		display: "flex",
		justifyContent: "center",
		marginTop: "150px",
	},
}));

export default CreateNewBrandDialog;
