import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import Context from "../../Context/Context";
import LoadingAnimation from "../Common/LoadingAnimation/LoadingAnimation";
import Personalizer from "../Tools/Personalizer";
import BriaButton, { BUTTONTYPES } from "./BriaButton";
import CustomizedTooltip from "./Tooltip";
import { ThumbnailImage, UncropValues } from "../../Models/ToolsConfig";
import BriaAPI from "../../sdk/resources/briaAPI";
import BriaConfirmationDialog from "../BriaConfirmationDialog";
import { proportions } from "../../Constants/ImageProportions";
import { ImageEditorContext } from "../../Pages/Tool/ImageEditor";
import { useTranslation } from "react-i18next";
import { croppingAreaHasTransparency } from "../Tools/utilities";

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	tooltip: {
		padding: " 14px 8px !important",
		width: " 148px",
		alignItems: "center",
		height: " 82px",
		background: "#000000 !important",
		boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.15)",
		borderRadius: " 4px !important",
		textAlign: "center",
	},
	iconItem: {
		marginRight: 11,
		width: 13.33,
		height: 13.33,
	},
	activeText: { color: "#1A0638", fontWeight: 600 },
	separator: {
		borderBottom: "1px solid #E7E7E7",
		marginBottom: 40,
		width: "100%",
		marginTop: 4,
	},
	titleStyle: {
		fontSize: 14,
		lineHeight: "17px",
		fontWeight: 600,
		color: "#1A0638",
	},
	sizeBtn: {
		border: "1px solid #B3B3B9",
		borderRadius: "4px",
		background: "unset",
		fontWeight: 600,
		color: "#B3B3B9",
		padding: 0,
		"&:disabled": {
			border: "1px dashed rgba(73, 80, 87, 0.2)",
			color: "rgba(73, 80, 87, 0.2)",
		},
		"&:hover": {
			backgroundColor: "white !important",
		},
		"&.MuiButton-root": {
			transition: "none",
		},
	},

	sizeBtnActive: {
		color: "#5300C9",
		background: "rgba(231, 231, 231, 0.2)",
		border: "2.31px solid #5300C9",
		borderRadius: "4px",
		"&.MuiButton-root": {
			transition: "none",
		},
	},
	sizeBtnText: {
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "17px",
		textAlign: "center",
		color: "rgba(91, 91, 91, 0.85)",
	},
	activeNumber: { color: "#1A0638" },
	switchLable: {
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		textShadow: "0px 0px 0px #495057",
	},
	buttonsContainer: {
		marginTop: 18,
		marginBottom: "4px",
		display: "flex",
		gap: 7,
		justifyContent: "flex-end",
	},
	buttonStyle: {
		width: 170,
		height: 34,
	},
}));

interface IProps {
	imageTransparencyPercentage: number;
	activeCropSizebtn: number;
	setActiveCropSizeBtn: (value: number) => void;
	activeCropPreset: string;
	resetUncrop: () => void;
	croppedImage: any;
	resetCrop: () => void;
	setCropTabSeleced: (value: boolean) => void;
	setDraftCropName: (value: string) => void;
	cropImageFunction: (w: number, h: number, isCustom: boolean) => void;
	cropZoom: number;
	setCropZoom: Dispatch<SetStateAction<number>>;
	setActiveCropPreset: (value: string) => void;
	processing: boolean;
	uncropLoading: boolean;
	OnloadError: boolean;
	uncropThumbsLoading: boolean;
	uncropValues: UncropValues | undefined;
	uncropThumbnails: ThumbnailImage[];
	visual_hash: string;
	isEngineOverloaded?: boolean;
	setUncropThumbsLoading: (value: boolean) => void;
	imageRef: RefObject<HTMLImageElement>;
	originalImage: string;
	selectedPoint: any;
	onUncropThumbnailClick: (thumbnailImage: ThumbnailImage) => Promise<void>;
	applyCrop: () => void;
	callUncropApi: (number: number, append?: boolean, prompt?: string) => void;
	setIsEngineOverloaded: (value: boolean) => void;
	thumbnailConfig: any;
	isUncropApplied: boolean;
	setDraftCroppedArea: (value: any) => void;
	draftCropName: string;
	setCroppedAreaPixels: (value: any) => void;
	resetPointsOfInterests: () => void;
	setCroppedAreaPercentage: (value: any) => void;
	setDraftCroppedAreaPixels: (value: any) => void;
	setDraftCroppedAreaPercentage: (value: any) => void;
	setCropName: (value: string) => void;
	croppedArea: any;
	setRotation: (value: number) => void;
	draftCroppedAreaPercentage: any;
	draftCroppedAreaPixels: any;
	draftRotation: number;
	draftZoom: number;
	cropImageUrl: any;
	setCropImageUrl: (value: any) => void;
	originalImageDimensions: any;
	naturalSize: any;
}

const ExpandBackground: React.FC<IProps> = ({
	setDraftCroppedAreaPixels,
	setDraftCroppedAreaPercentage,
	imageTransparencyPercentage,
	activeCropSizebtn,
	setActiveCropSizeBtn,
	activeCropPreset,
	resetUncrop,
	croppedImage,
	resetCrop,
	setDraftCropName,
	cropImageFunction,
	cropZoom,
	setCropZoom,
	setActiveCropPreset,
	processing,
	uncropLoading,
	OnloadError,
	uncropThumbsLoading,
	uncropValues,
	uncropThumbnails,
	visual_hash,
	isEngineOverloaded,
	setUncropThumbsLoading,
	imageRef,
	originalImage,
	selectedPoint,
	onUncropThumbnailClick,
	applyCrop,
	callUncropApi,
	setIsEngineOverloaded,
	thumbnailConfig,
	isUncropApplied,
	setCropTabSeleced,
	setCroppedAreaPercentage,
	setCroppedAreaPixels,
	setDraftCroppedArea,
	draftCropName,
	resetPointsOfInterests,
	croppedArea,
	setCropName,
	setRotation,
	draftCroppedAreaPercentage,
	draftCroppedAreaPixels,
	draftRotation,
	draftZoom,
	cropImageUrl,
	setCropImageUrl,
	originalImageDimensions,
	naturalSize,
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const context = useContext(Context);
	const [selectedWidth, setSelectedWidth] = useState<number>(-1);
	const [selectedHeight, setSelectedHeigt] = useState<number>(-1);
	const uncropContainerRef = useRef<null | HTMLDivElement>(null);
	const [isGenerateClicked, setIsGenerateClicked] = useState(false);
	const [isLoadMoreClicked, setIsLoadMoreClicked] = useState<boolean>(false);
	const [retryingImageAbortController, setRetryingImageAbortController] = useState<AbortController>(
		new AbortController()
	);
	const { undoRedoSetter } = useContext(ImageEditorContext);

	const [showResetConfirmationDialog, setShowResetConfirmationDialog] = useState<boolean>(false);
	const [resetConfirmationDialogHandler, setResetConfirmationDialogHandler] = useState(() => async () => {});

	function setcropZoomCallBack(value: number) {
		if (cropZoom !== value) {
			setCropZoom(value);
			cropImageFunction(selectedWidth, selectedHeight, false);
		}
	}

	useEffect(() => {
		// 	setCropIimageValues(); // commented-out for wai-1966
		setActiveCropPreset(context.cropConfig[0].keys().next().value);
		// 	setActiveCropSizeBtn(-1);  // commented-out for wai-1966
		setCropTabSeleced(true);
	}, []);

	const croppingAreaHasTransparencyBool = croppingAreaHasTransparency(
		croppedArea,
		draftCroppedAreaPixels,
		naturalSize
	);
	const disableUncrop =
		(imageTransparencyPercentage === 0 && !croppingAreaHasTransparencyBool) ||
		!croppingAreaHasTransparencyBool ||
		processing ||
		uncropLoading ||
		OnloadError;

	const applyUncrop = async () => {
		setUncropThumbsLoading(true);

		await applyCrop();
		setIsGenerateClicked(true);
	};

	const callLocalUncropApi = async (num_results: number, append?: boolean) => {
		setIsLoadMoreClicked(append ?? false);
		setIsEngineOverloaded(false);
		callUncropApi(num_results, append);
	};
	const callUncropFn = async () => {
		setTimeout(() => {
			uncropContainerRef.current?.scrollIntoView({
				behavior: "smooth",
			});
		}, 200);
		retryingImageAbortController?.abort();
		setRetryingImageAbortController(new AbortController());
		await callLocalUncropApi(thumbnailConfig[3]);
		uncropContainerRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};
	useEffect(() => {
		if (isGenerateClicked) {
			setIsGenerateClicked(false);
			callUncropFn();
		}
	}, [isGenerateClicked]);

	return (
		<Box className={classes.root}>
			<BriaConfirmationDialog
				title={"Are you sure?"}
				description={<Typography style={{ marginBottom: 100 }}>This will reset the current result</Typography>}
				width="600px"
				buttonExtraStyle={{ width: 340, bottom: 30 }}
				buttonText={"Yes"}
				showCancelButton={true}
				cancelButtonText={"Cancel"}
				onButtonClick={async () => {
					await resetConfirmationDialogHandler();
					setShowResetConfirmationDialog(false);
				}}
				onCloseModal={() => {
					setShowResetConfirmationDialog(false);
				}}
				shouldOpen={showResetConfirmationDialog}
			/>
			<Box>
				<Typography className={classes.titleStyle}>{t("imageProportions")}</Typography>
				<Box>
					<Grid item container justifyContent="flex-start" alignItems="baseline" style={{ marginTop: 25 }}>
						{proportions.map((e: any) => (
							<CustomizedTooltip
								arrowColor="#000000"
								arrow
								classes={{
									tooltip: classes.tooltip,
								}}
								multiLine={true}
								title={
									// e.w > originalImgae.width ||
									// e.h > originalImgae.height ? (
									// 	<>
									// 		<div>
									// 			Image resolution is
									// 			too small to use for
									// 			this format.
									// 		</div>
									// 	</>
									// ) : (
									""
									// )
								}
								placement="top"
							>
								<Grid
									// xs={}
									item
									container
									justifyContent="flex-start"
									alignItems="center"
									direction="column"
									style={{
										marginBottom: 24,
										marginRight: e.index === 4 ? 0 : 30,
										width: e.resize.w ?? 0,
										height: e.resize.h ?? 0,
									}}
								>
									<Box
										style={{
											maxWidth: "fit-content",
											marginBottom: 10,
										}}
									>
										<Button
											// onMouseEnter={() => setHoveredBox(e.index)}
											// onMouseLeave={() => setHoveredBox(-1)}
											focusRipple={activeCropSizebtn === e.index}
											disableTouchRipple
											style={{
												minWidth: "fit-content",
												width: e.resize.w ?? 0,
												height: e.resize.h ?? 0,
											}}
											className={
												activeCropSizebtn === e.index ? classes.sizeBtnActive : classes.sizeBtn
											}
											// disabled={
											// 	e.w >
											// 		originalImgae.width ||
											// 	e.h >
											// 		originalImgae.height
											// }
											onClick={() => {
												const handler = async () => {
													resetUncrop();
													if (croppedImage) {
														resetCrop();
													}

													// if (
													// 	e.w <=
													// 		originalImgae.width &&
													// 	e.h <=
													// 		originalImgae.height
													// ) {
													setcropZoomCallBack(1);
													setDraftCroppedArea(croppedArea);
													setDraftCroppedAreaPixels({
														...draftCroppedAreaPixels,
														x: 0,
														y: 0,
													});
													setDraftCroppedAreaPercentage({
														...draftCroppedAreaPercentage,
														x: 0,
														y: 0,
													});
													// setRotation(draftRotation);
													// setCropZoom(draftZoom);
													// resetPointsOfInterests();
													let newWidth = originalImageDimensions.width;
													let newHeight = originalImageDimensions.height;
													if (e.aspectRatioHeight > e.aspectRatioWidth) {
														newHeight = Math.round(
															originalImageDimensions.width *
																(e.aspectRatioHeight / e.aspectRatioWidth)
														);
													} else {
														newWidth = Math.round(
															originalImageDimensions.height *
																(e.aspectRatioWidth / e.aspectRatioHeight)
														);
													}
													setSelectedWidth(newWidth);
													setSelectedHeigt(newHeight);
													cropImageFunction(newWidth, newHeight, false);
													setActiveCropSizeBtn(e.index);
												};
												setResetConfirmationDialogHandler(() => handler);
												if (!!croppedImage) {
													setShowResetConfirmationDialog(true);
												} else {
													handler();
												}
											}}
										>
											{Math.round(e.aspectRatioWidth) + ":" + Math.round(e.aspectRatioHeight)}
										</Button>
									</Box>

									{/* <Typography
											className={clsx({
												[classes.sizeBtnText]: true,
												[classes.activeText]: (e.index === activeCropSizebtn) === e.index,
												// [classes.DisabledText]:
												// 	e.w >
												// 		originalImgae.width ||
												// 	e.h >
												// 		originalImgae.height,
											})}
										>
											{e.name}
										</Typography>
										<Typography
											className={clsx({
												[classes.sizeBtnText]: true,
												[classes.activeNumber]: (e.index === activeCropSizebtn) === e.index,
												// [classes.DisabledText]:
												// 	e.w >
												// 		originalImgae.width ||
												// 	e.h >
												// 		originalImgae.height,
											})}
										>
											{e.w} x {e.h}
										</Typography> */}
								</Grid>
							</CustomizedTooltip>
						))}
					</Grid>
					<Box className={classes.separator} />
					<>
						<div style={{ marginRight: 20 }} ref={uncropContainerRef}>
							<span className={classes.switchLable}>{t("fillImage")}</span>
							<Box className={classes.buttonsContainer}>
								{/* <CustomizedTooltip
								arrow
								multiLine={true}
								title="Press Crop button before generating"
								placement="top"
								disable={!disableUncrop}
							> */}
								{/* <span  data-tip-disable={false}> */}
								<BriaButton
									id="GenrateBtn"
									disabled={disableUncrop}
									buttonType={BUTTONTYPES.PRIMARYSMALL}
									className={classes.buttonStyle}
									onClick={async (e) => {
										await applyUncrop();
									}}
								>
									<Box
										display={"flex"}
										style={{
											gap: 5,
											fontSize: 14,
										}}
									>
										{t("autofillBackground")}
									</Box>
								</BriaButton>
								{/* </span> */}
								{/* </CustomizedTooltip> */}
							</Box>
						</div>
						{(uncropThumbsLoading || uncropLoading) && !isLoadMoreClicked && (
							<Box
								mt={"25px"}
								height="20vh"
								width="100%"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<LoadingAnimation
									color="#ededed"
									loadingStringsPosition="top"
									progressBarTime={15}
									loading={true}
									showLoadingStrings={false}
									loadingStringsTimeout={5000}
									loadingStrings={[]}
								/>
							</Box>
						)}
						{(!uncropLoading || (uncropLoading && isLoadMoreClicked)) && (
							<Box
								style={{
									width: "100%",
									height: 0,
									minHeight:
										((!uncropThumbsLoading && !uncropLoading) || isLoadMoreClicked) &&
										uncropThumbnails.length > 0
											? 500
											: 0,
									display:
										((!uncropThumbsLoading && !uncropLoading) || isLoadMoreClicked) &&
										uncropThumbnails.length > 0
											? "unset"
											: "none",
								}}
								// className={classes.thumbnail}
							>
								<Personalizer
									selectedBackgroundReplaceSeed={BriaAPI.getInstance(
										visual_hash,
										context.iframeConfig
									).getSelectedUncropSeed()}
									isEngineOverloaded={isEngineOverloaded}
									onLoadMoreClick={async () => {
										await callLocalUncropApi(4, true);
									}}
									setReplaceBgThumbsLoading={setUncropThumbsLoading}
									retryingImageAbortController={retryingImageAbortController}
									isReplaceBackground={true}
									selectedPoint={selectedPoint}
									visualHash={visual_hash}
									originalImage={originalImage}
									imageRef={imageRef}
									key="uncrop"
									feature="uncrop"
									personalizerKey="suggestion"
									personalizerValues={{}}
									disabled={processing}
									isSuggestion={true}
									sliders={null}
									user={context.user}
									systemView={context.systemView}
									onChangePersonalizer={async (sliders, key) => {}}
									drawerAnchor={"left"}
									objectType={selectedPoint?.objectType}
									isLoadingExpression={false}
									thumbnailHeight={thumbnailConfig[1]}
									thumbnailWidth={thumbnailConfig[0]}
									thumbnailsPerCol={thumbnailConfig[2]}
									thumbnailsCount={12}
									thumbnailImages={uncropThumbnails}
									onAddSemanticCombination={async () => {
										return "";
									}}
									onReplaceBgThumbnailClick={async (thumbnailImage: ThumbnailImage) => {
										await onUncropThumbnailClick(thumbnailImage);
									}}
								/>
							</Box>
						)}
					</>
				</Box>
			</Box>
		</Box>
	);
};

export default ExpandBackground;
