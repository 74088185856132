import User from "../Models/User";
import { getDatabase, onValue, ref } from "firebase/database";
import { getAuth } from "firebase/auth";
import app from "../Config/Firebase";
import Subscription from "../Models/Subscription";
import { SubscriptionPeriods, SubscriptionTypes } from "../Constants/SubscriptionConstants";
import { GENERAL_ORG_ID } from "../Constants";

export async function getUserWithUID(uid: string): Promise<User | null> {
	return new Promise<User | null>((resolve, reject) => {
		const database = getDatabase(app);
		const databaseRef = ref(database, "users/" + uid);
		onValue(
			databaseRef,
			(snapshot) => {
				const firebaseUser = snapshot.val();
				if (firebaseUser) {
					const subscription = new Subscription(
						firebaseUser.subscription?.web_subscription_type ?? SubscriptionTypes.none,
						firebaseUser.subscription?.web_subscription_period ?? SubscriptionPeriods.default,
						firebaseUser.subscription?.people_customizations_credits ?? "",
						firebaseUser.subscription?.download_psd_credits ?? "",
						firebaseUser.subscription?.download_credits ?? "",
						firebaseUser.subscription?.object_customizations_credits ?? "",
						firebaseUser.subscription?.videos_credits ?? "",
						firebaseUser.subscription?.bs_web_shopper_id ?? ""
					);
					let userOrgs = firebaseUser.user_organizations;

					userOrgs = userOrgs
						? userOrgs.filter(
								(org: string) => org !== GENERAL_ORG_ID //General organization id
						  )
						: userOrgs;
					const user = new User(
						firebaseUser.user_name,
						firebaseUser.uid,
						firebaseUser.profile_picture,
						firebaseUser.role,
						getAuth()?.currentUser?.emailVerified ?? false,
						userOrgs,
						firebaseUser.user_organizations,
						firebaseUser.company,
						firebaseUser.userRole,
						firebaseUser.getInfo,
						firebaseUser.email,
						subscription,
						firebaseUser.settings
					);
					user.manuallyManaged = firebaseUser.manuallyManaged;
					resolve(user);
				} else {
					console.error("no_user found, Try again later or hard refresh the browser");
					resolve(null);
				}
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export function listenForOrgChanges(orgUid: string, callback: (record: any) => void) {
	const starCountRef = ref(getDatabase(app), "organizations/" + orgUid);
	return onValue(starCountRef, async function (snapshot) {
		if (snapshot.exists()) {
			const record = snapshot.val();
			callback(record);
		}
	});
}
