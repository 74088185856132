import { Typography, Paper, Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import arrowLeft from "../../assets/svgs/arrow-left.svg";
import Wand from "../../assets/svgs/wand-campain.svg";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../Common/LoadingAnimation/LoadingAnimation";
import FullScreenViewer from "../UI/BrandTab/FullScreenViewer";
interface IProps {
	images: any;
	currentImage: any;
	value: any;
	handlePrev: any;
	handleNext: any;
	containerRef: any;
	setIsViewerOpen: any;
	generatedCampaignErrorMessage: any;
	isViewerOpen: any;
	campaignImagesGenerated: boolean;
	firstImageLoadedOrError: boolean;
}

const CampaignSliderView = ({
	images,
	currentImage,
	value,
	handlePrev,
	handleNext,
	containerRef,
	setIsViewerOpen,
	generatedCampaignErrorMessage,
	isViewerOpen,
	campaignImagesGenerated,
	firstImageLoadedOrError,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "Slider" });
	const classes = useStyles();

	return (
		<Box className={classes.sliderMainContainer}>
			<Box style={{ flexGrow: 1 }}>
				{images.length > 0 && campaignImagesGenerated && firstImageLoadedOrError && (
					<Box className={classes.arrowsHeader}>
						<Box
							className={classes.RightArrowCircle}
							style={{ backgroundColor: value === 0 ? "#E9E8EBB2" : "#FFF" }}
						>
							<img
								src={arrowLeft}
								alt="Previous"
								onClick={handlePrev}
								className={classes.RightArrowImg}
								style={{
									opacity: value == 0 ? 0.5 : 1,
								}}
							/>
						</Box>
						{images.length > 0 && (
							<Typography className={classes.imageNumberCounter}>
								<span className={classes.currentimageNumber}>{`${value + 1}`}</span>/
								{`${images.length}`}
							</Typography>
						)}
						<Box
							className={classes.RightArrowCircle}
							style={{
								backgroundColor: value === images.length - 1 ? "#E9E8EBB2" : "#FFF",
							}}
						>
							<img
								src={arrowLeft}
								alt="Next"
								onClick={handleNext}
								className={classes.LeftArrowImg}
								style={{
									opacity: value === images.length - 1 ? 0.5 : 1,
								}}
							/>
						</Box>
					</Box>
				)}
				<Box className={classes.imgPlaceholder}>
					{campaignImagesGenerated && (
						<Box className={classes.imgContainer}>
							{currentImage?.loaded ? (
								<img
									ref={containerRef}
									src={currentImage?.src}
									alt={currentImage?.name}
									className={classes.img}
									onClick={() => {
										setIsViewerOpen(true);
									}}
								/>
							) : (
								<>
									{(firstImageLoadedOrError || generatedCampaignErrorMessage) && (
										<Paper className={classes.emptyState}>
											{currentImage?.src && !currentImage?.loaded && !currentImage?.error ? (
												<>
													<LoadingAnimation
														color="#ededed"
														progressBarTime={25}
														loading={currentImage?.src && !currentImage?.loaded}
														showLoadingStrings={true}
														loadingStringsTimeout={5000}
													/>
												</>
											) : (
												(currentImage?.error ||
													(images.length == 0 && generatedCampaignErrorMessage)) && (
													<>
														<Typography
															variant="body1"
															className={classes.emptyStateContent}
														>
															{t("somethingWentWrong")}
														</Typography>
													</>
												)
											)}
										</Paper>
									)}

									{((images.length == 0 && !generatedCampaignErrorMessage) ||
										(!currentImage?.src && !currentImage?.loaded && images.length > 0)) &&
										campaignImagesGenerated && (
											<Paper className={classes.emptyState}>
												<Box>
													<img src={Wand} alt="" />
													<Typography variant="body1" className={classes.emptyStateContent}>
														{t("emptyPlaceHolder")}
													</Typography>
												</Box>
											</Paper>
										)}
								</>
							)}
						</Box>
					)}
					<LoadingAnimation
						color="#ededed"
						progressBarTime={25}
						loading={
							!campaignImagesGenerated ||
							(!firstImageLoadedOrError && campaignImagesGenerated && images.length > 0)
						}
						showLoadingStrings={true}
						loadingStringsTimeout={5000}
					/>
					{campaignImagesGenerated && firstImageLoadedOrError && (
						<Box>
							<Typography className={classes.imageDescription}>
								{currentImage?.name}
								{currentImage?.zoomPercentage && (
									<span style={{ marginLeft: "3px" }}>({currentImage?.zoomPercentage}%)</span>
								)}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>

			<FullScreenViewer
				open={isViewerOpen}
				src={images.map((image: any, index: any) => images[index].src)}
				currentIndex={value}
				onClose={() => setIsViewerOpen(false)}
			/>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sliderMainContainer: {
			textAlign: "center",
			display: "flex",
			flexDirection: "row-reverse",
			width: "100%",
			height: "100%",
			justifyContent: "center",
		},

		RightArrowCircle: {
			width: "40px",
			height: "40px",
			border: "1px solid #E9E8EBB2",
			borderRadius: "50%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		RightArrowImg: {
			cursor: "pointer",
			transform: "rotate(180deg)",
		},
		imageNumberCounter: {
			fontWeight: 400,
			fontSize: "16px",
			lineHeight: "16px",
			marginLeft: "41px",
			marginRight: "41px",
		},
		currentimageNumber: {
			fontWeight: 600,
		},
		LeftArrowImg: {
			cursor: "pointer",
		},
		imageDescription: {
			marginTop: "28px",
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "16px",
		},
		imgContainer: {
			display: "flex",
			justifyContent: "center",
			margin: "0 auto",
			maxHeight: "64vh",
			"@media (max-width: 1150px)": {
				maxHeight: "60vh",
			},
		},
		img: {
			width: "100%",
			height: "100%",
			borderRadius: "8px",
		},
		emptyState: {
			width: "100vw",
			textAlign: "center",
			maxWidth: "416px",
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			justifyContent: "center",
			gap: "14px",
			borderRadius: "8px !important",
			height: "55vh",
			boxShadow:
				"0px 0px 0px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 8px 5px 0px rgba(0, 0, 0, 0.04), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 23px 6px 0px rgba(0, 0, 0, 0.00)",
		},
		emptyStateContent: {
			color: "#A6A6A6",
			fontWeight: 400,
			fontSize: "16px",
			lineHeight: "24px",
			maxWidth: "238px",
		},
		imgPlaceholder: {
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
		},
		arrowsHeader: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	})
);
export default CampaignSliderView;
