import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { pullUntilFileIsAvailableFromBackend } from "../../Helpers/images";
import JSZip from "jszip";
import { getImageInfo } from "../UI/CreateCampaign/utils";
import { saveAs } from "file-saver";
import Context from "../../Context/Context";
import { getImageMeta } from "../Tools/utilities";
import CampaignPreviewButtons from "./CampaignPreviewButtons";
import CampaignSliderView from "./CampaignSliderView";
import CampaignGalleryPreview from "./CampaignGalleryPreview";

interface IProps {
	images: any;
	generatedCampaignErrorMessage: any;
	campaignImagesGenerated: boolean;
}

const CampaignPreview = ({ images, generatedCampaignErrorMessage, campaignImagesGenerated }: IProps) => {
	const [loadedImages, setLoadedImages] = useState<any>([]);
	const containerRef = useRef<HTMLImageElement>(null);
	const context = useContext(Context);
	const [value, setValue] = useState(0);
	const [showMenu, setShowMenu] = useState(false);
	const [sliderImages, setSliderImages] = useState(images);
	var FileSaver = require("file-saver");
	const [currentImage, setCurrentImage] = useState<any>(images[0]);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [IsAllImagesReady, setisAllImagesReady] = useState(false);
	const [errorInAllImages, setErrorInAllImages] = useState(false);
	const [showSlider, setShowSlider] = useState(false);
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "Slider" });
	const [isAtleastOneImageReady, setIsAtleastOneImageReady] = useState(false);
	const [firstImageLoadedOrError, setFirstImageLoadedOrError] = useState(false);

	const handlePrev = () => {
		if (value > 0) {
			setValue((prevValue) => prevValue - 1);
		}
	};

	const handleNext = () => {
		if (value < images.length - 1) {
			setValue((prevValue) => prevValue + 1);
		}
	};

	function getFileExtension(blob: Blob): string {
		const parts = blob.type.split("/");
		return parts[parts.length - 1];
	}

	async function downloadImage(imageSrc: string, imageVH: string) {
		FileSaver.saveAs(imageSrc, imageVH);
	}

	async function downloadSingleCampaign() {
		if (images.length > 0) {
			let url = currentImage?.src;
			try {
				if (url) {
					await pullUntilFileIsAvailableFromBackend(url, "dowmload-campaign", undefined, 10, 1);
					const response = await fetch(url);
					const blob = await response.blob();
					const fileExtension = getFileExtension(blob);
					url = window.URL.createObjectURL(new Blob([blob]));
					const filename = `${context.campaignName}_${currentImage?.name}.${fileExtension}`;
					await downloadImage(url, filename);
				}
			} catch {
				console.log("Error in downloading the image");
			}
		}
	}

	async function downloadPSD() {
		if (images.length > 0) {
			let url = currentImage?.psdFile;
			try {
				if (url) {
					await pullUntilFileIsAvailableFromBackend(url, "dowmload-campaign", undefined, 10, 1);
					const response = await fetch(url);
					const blob = await response.blob();
					url = window.URL.createObjectURL(new Blob([blob]));
					const filename = `${context.campaignName}_${currentImage?.name}.psd`;
					await downloadImage(url, filename);
				}
			} catch {
				console.log("Error in downloading the PSD image");
			}
		}
	}

	async function downloadCampaigns() {
		try {
			let isImagesReady = true;
			for (const image of sliderImages) {
				if (image.loaded === false && image.error === false) {
					isImagesReady = false;
					break;
				}
			}
			if (sliderImages.length > 0 && isImagesReady) {
				const zip = new JSZip();
				const currentDate = new Date();
				const formattedDate = `${
					currentDate.getMonth() + 1
				}-${currentDate.getDate()}-${currentDate.getFullYear()}`;

				// Use Promise.all to wait for all getImageInfo promises
				await Promise.all(
					sliderImages.map(async (image: any, index: any) => {
						if (image.loaded) {
							const { filename, base64 } = await getImageInfo(image, context.campaignName, index);
							const imageData = base64.split(",")[1];

							zip.file(filename, imageData, { base64: true });
						}
					})
				);

				const campaignNameLowercase = context?.campaignName;
				const zipFileName = `${campaignNameLowercase}_${formattedDate}.zip`;

				zip.generateAsync({ type: "blob" }).then((content) => {
					saveAs(content, zipFileName);
				});
			}
		} catch (error) {
			console.error("Error in downloading all images", error);
		}
	}

	const downloadAllPSDs = async () => {
		const zip = new JSZip();
		const currentDate = new Date();
		const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
		try {
			let isImagesReady = true;
			for (const image of sliderImages) {
				if (image.loaded == false && image.error == false) {
					isImagesReady = false;
					break;
				}
			}
			if (sliderImages.length > 0 && isImagesReady) {
				await Promise.all(
					sliderImages.map(async (image: any, index: any) => {
						if (image.loaded) {
							const response = await fetch(image.psdFile);
							if (response.ok) {
								const blob = await response.blob();
								zip.file(`${image.name}_${index + 1}.psd`, blob);
							} else {
								console.error(`Failed to download image ${index + 1} from ${image.psdFile}`);
							}
						}
					})
				);
				const content = await zip.generateAsync({ type: "blob" });
				const zipFilename = context?.campaignName + "_" + formattedDate;
				saveAs(content, zipFilename);
				console.log(`Images downloaded and saved in ${zipFilename}`);
			}
		} catch (error) {
			console.error("Error downloading images:", error);
		}
	};

	useEffect(() => {
		setCurrentImage(sliderImages[value]);
	}, [value]);

	useEffect(() => {
		setSliderImages(images);
		setValue(0);
		setLoadedImages([]);
	}, [images]);

	useEffect(() => {
		setCurrentImage(sliderImages?.[value]);
		let isImagesReady = true;
		for (const image of sliderImages) {
			if ((image.loaded === false && image.error === false) || (image.loaded === false && !image.error)) {
				isImagesReady = false;
				break;
			}
		}
		sliderImages.length > 0 && setisAllImagesReady(isImagesReady);

		const allImagesHasErrors = sliderImages.length > 0 && sliderImages.every((image: any) => image.error);
		setErrorInAllImages(allImagesHasErrors);
		const atLeastOneLoaded = sliderImages?.some((image: any) => image?.loaded);
		const isFirstImageLoadedOrError = sliderImages?.[0]?.loaded === true || sliderImages?.[0]?.error === true;
		setFirstImageLoadedOrError(isFirstImageLoadedOrError);

		setIsAtleastOneImageReady(atLeastOneLoaded);
	}, [sliderImages]);

	useEffect(() => {
		const updateSliderImagesSequentially = async () => {
			const updatedImages = images.map(async (image: any) => {
				if (image.src && image.src.length > 0) {
					try {
						await pullUntilFileIsAvailableFromBackend(image.src, "campaign-generate", undefined, 60, 2);
						const response = await fetch(image.src);
						const fileSize = (await response.blob()).size;
						if (fileSize !== undefined && fileSize === 0) {
							console.log("to handle error case");
							image.error = true;
							setSliderImages((prevImages: any) =>
								prevImages.map((prevImage: any) =>
									prevImage.src === image.src ? { ...prevImage, error: true } : prevImage
								)
							);
						} else {
							image.loaded = true;
							setSliderImages((prevImages: any) =>
								prevImages.map((prevImage: any) =>
									prevImage.src === image.src ? { ...prevImage, loaded: true } : prevImage
								)
							);

							setLoadedImages((prevImages: any) => [...prevImages, { ...image, loaded: true }]);
						}
					} catch (e) {
						console.error(e);
						image.error = true;
						setSliderImages((prevImages: any) =>
							prevImages.map((prevImage: any) =>
								prevImage.src === image.src ? { ...prevImage, error: true } : prevImage
							)
						);
					}
				}
			});

			await Promise.all(updatedImages);
		};

		updateSliderImagesSequentially();
	}, [images]);

	useEffect(() => {
		const handleResize = () => {
			if (containerRef?.current && currentImage?.src && currentImage?.loaded) {
				getImageMeta(currentImage?.src).then((img) => {
					const imageHeight = img.height ?? 0;
					const imageWidth = img.width ?? 0;
					const containerHeight = containerRef?.current?.clientHeight ?? 0;
					const containerWidth = containerRef?.current?.clientWidth ?? 0;

					if (imageWidth <= containerWidth && imageHeight <= containerHeight) {
						setCurrentImage((prevImage: any) => ({
							...prevImage,
							zoomPercentage: 100,
						}));
					} else {
						let zoomPercentage = Math.trunc(
							Math.min(containerHeight / imageHeight, containerWidth / imageWidth) * 100
						);
						setCurrentImage((prevImage: any) => ({
							...prevImage,
							zoomPercentage: zoomPercentage,
						}));
					}
				});
			}
		};

		const resizeObserver = new ResizeObserver(handleResize);

		if (containerRef?.current) {
			resizeObserver.observe(containerRef.current);
		}

		return () => {
			if (containerRef?.current) {
				resizeObserver.unobserve(containerRef.current);
			}
			resizeObserver.disconnect();
		};
	}, [currentImage?.src, currentImage?.loaded, sliderImages]);

	useEffect(() => {
		const closeMenuOnClick = (event: any) => {
			if (showMenu && !event.target.closest(".downloadMenu")) {
				setShowMenu(false);
			}
		};
		document.addEventListener("click", closeMenuOnClick);
		return () => {
			document.removeEventListener("click", closeMenuOnClick);
		};
	}, [showMenu]);
	return (
		<>
			<CampaignPreviewButtons
				sliderImages={sliderImages}
				setShowMenu={setShowMenu}
				showMenu={showMenu}
				currentImage={currentImage}
				downloadSingleCampaign={downloadSingleCampaign}
				IsAllImagesReady={IsAllImagesReady}
				downloadCampaigns={downloadCampaigns}
				downloadPSD={downloadPSD}
				downloadAllPSDs={downloadAllPSDs}
				setShowSlider={setShowSlider}
				showSlider={showSlider}
			/>
			<Box className={classes.sliderMainContainer}>
				{showSlider ? (
					<CampaignSliderView
						images={sliderImages}
						currentImage={currentImage}
						value={value}
						handlePrev={handlePrev}
						handleNext={handleNext}
						containerRef={containerRef}
						setIsViewerOpen={setIsViewerOpen}
						generatedCampaignErrorMessage={generatedCampaignErrorMessage}
						isViewerOpen={isViewerOpen}
						campaignImagesGenerated={campaignImagesGenerated}
						firstImageLoadedOrError={firstImageLoadedOrError}
					/>
				) : (
					<CampaignGalleryPreview
						images={sliderImages}
						isViewerOpen={isViewerOpen}
						errorGenerating={generatedCampaignErrorMessage}
						setIsViewerOpen={setIsViewerOpen}
						errorInAllImages={errorInAllImages}
						isAtleastOneImageReady={isAtleastOneImageReady}
						loadedImages={loadedImages}
						campaignImagesGenerated={campaignImagesGenerated}
					/>
				)}
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sliderMainContainer: {
			textAlign: "center",
			display: "flex",
			flexDirection: "row-reverse",
			height: "100%",
		},
	})
);
export default CampaignPreview;
