import { Box, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import ToolHeader from "../../Components/Tools/ToolHeader";
import { useHistory } from "react-router-dom";
import React, { ChangeEvent, useContext, useState } from "react";
import Context from "../../Context/Context";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
import clsx from "clsx";
import { getSendEarlyAccessEmail } from "../../GraphQL/queries";
import RouterConstants from "../../Constants/RouterConstants";
import Analytics, { HUBSPOT_EVENTS } from "../../Models/Analytics";
import { IsGuestUser } from "../../Helpers/isGuest";

interface IProps {}

const EARLY_ACCESS: any = {
	backgroundImage: "https://labs-assets.bria.ai/Pricing/early-access.jpg",
	mainTitle: "Keeping your brand on brand",
	step1: {
		title: "Sign up for early access",
		subTitle: null,
		description: "Match your exact brand style and stand out among your competitors.",
		buttonLabel: "Sign up for early access",
	},
	step2: {
		title: "Thank you",
		subTitle: "for signing up to start your brand journey with BRIA!",
		description:
			"Yikes! Our early access program is currently full. We will make sure to add you to our waiting list.",
		buttonLabel: "Back to Home",
	},
	step3: {
		title: "Thanks for signing up to start your brand journey with BRIA!",
		subTitle: null,
		description:
			"Yikes! Our early access program is currently full. We will make sure to add you to our waiting list.",
		buttonLabel: "Back to Home",
	},
};

function EarlyAccess(props: IProps) {
	const history = useHistory();
	const { t } = useTranslation();
	const { isGuestUser } = IsGuestUser();
	const context = useContext(Context);
	const [selectedStep, setSelectedStep] = useState<string>("step1");
	const [firstName, setFirstName] = useState<any>(null);
	const [lastName, setLastName] = useState<any>(null);
	const [emailAddress, setEmailAddress] = useState<any>(null);
	const classes = useStyles({ EARLY_ACCESS, selectedStep });

	const handleSignUpSubmission = async () => {
		if (selectedStep === "step2" || selectedStep === "step3") {
			history.push(RouterConstants.HOME.path);
			return;
		}
		const user_id = context.user?.uid ?? "";
		const res = await getSendEarlyAccessEmail(user_id, emailAddress);
		let isSent = res.sendEarlyAccessEmail ?? false;
		if (isSent) {
			setSelectedStep("step2");
			if (isGuestUser()) {
				Analytics.pushAnonymousHubspotEvent(
					HUBSPOT_EVENTS.BRAND_EARLY_REGISTRATION,
					true,
					emailAddress,
					firstName,
					lastName
				);
			} else {
				Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.BRAND_EARLY_REGISTRATION);
			}
		}
	};

	return (
		<Box className={classes.fullWidth}>
			<ToolHeader isPricing={true} />
			<Box className={classes.rootContainer}>
				<Box className={classes.root}>
					<Box className={classes.leftContainer}>
						<Box className={classes.titleBox}>
							<Typography className={classes.mainTitle}>{EARLY_ACCESS.mainTitle}</Typography>
						</Box>
					</Box>
					<Box className={classes.rightContainer}>
						<Box className={classes.dialog}>
							<Typography
								className={clsx({
									[classes.title]: true,
									[classes.step3]: selectedStep === "step3",
								})}
							>
								{EARLY_ACCESS[selectedStep].title}
							</Typography>
							{EARLY_ACCESS[selectedStep].subTitle && (
								<Typography className={classes.subTitle}>
									{EARLY_ACCESS[selectedStep].subTitle}
								</Typography>
							)}
							<Typography className={classes.description}>
								{EARLY_ACCESS[selectedStep].description}
							</Typography>
							{selectedStep === "step1" && (
								<>
									<TextField
										placeholder={"*First Name"}
										size="small"
										variant="outlined"
										fullWidth
										multiline
										className={classes.emailTextfield}
										value={firstName}
										inputProps={{
											className: classes.input,
										}}
										onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
											setFirstName(event?.target.value)
										}
									/>
									<TextField
										placeholder={"*Last Name"}
										size="small"
										variant="outlined"
										fullWidth
										multiline
										className={classes.emailTextfield}
										value={lastName}
										inputProps={{
											className: classes.input,
										}}
										onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
											setLastName(event?.target.value)
										}
									/>
									<TextField
										placeholder={"*Business Email"}
										size="small"
										variant="outlined"
										fullWidth
										multiline
										className={classes.emailTextfield}
										value={emailAddress}
										inputProps={{
											className: classes.input,
										}}
										onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
											setEmailAddress(event?.target.value)
										}
									/>
								</>
							)}
							<BriaButton
								className={classes.submit}
								buttonType={BUTTONTYPES.PRIMARYMEDIUM}
								onClick={handleSignUpSubmission}
								disabled={!emailAddress || !firstName || !lastName}
							>
								{EARLY_ACCESS[selectedStep].buttonLabel}
							</BriaButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

interface StyleProps {
	EARLY_ACCESS: any;
	selectedStep: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	fullWidth: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	rootContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
	},
	root: (props) => ({
		display: "flex",
		flexDirection: "row",
		width: "100%",
		height: "100%",
		backgroundImage: `url(${props.EARLY_ACCESS.backgroundImage})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	}),
	leftContainer: {
		flexBasis: "65%",
		margin: "auto",
	},
	titleBox: {
		width: "100%",
		flexBasis: "8vw",
		paddingLeft: "10vw",
		fontFamily: "Montserrat",
		color: "#FFF",
	},
	mainTitle: {
		opacity: 0.8,
		fontWeight: 600,
		fontSize: "9vw",
		lineHeight: "18vh",
	},
	rightContainer: {
		flexBasis: "35%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	dialog: {
		width: 560,
		height: 666,
		backgroundColor: "#FFF",
		borderRadius: "0.5vw",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "0.1vh",
		color: "#495057",
		position: "relative",
		overflow: "hidden",
		padding: "72px 46px 58px 34px",
	},
	title: (props) => ({
		textAlign: "center",
		fontSize: props.selectedStep === "step1" ? 48 : "1.8vw",
		fontWeight: 600,
		width: "60%",
		color: "#1A0638",
		lineHeight: "59px",
	}),
	step3: {
		fontSize: "1.5vw",
		width: "100%",
	},
	subTitle: {
		marginTop: "2vh",
		textAlign: "center",
		fontWeight: 600,
	},
	description: {
		textAlign: "center",
		fontSize: 20,
		marginTop: 18,
		marginBottom: 20,
		color: "#5B5B5B",
		lineHeight: "24px",
	},
	input: {
		color: "#5B5B5B",
		opacity: 1,
		fontSize: 16,
		lineHeight: "16px",
		letterSpacing: "0.02em",
	},
	emailTextfield: {
		marginTop: 20,
		"& .MuiOutlinedInput-root": {
			borderRadius: "1vh",
			padding: "18px 16px",
		},
	},
	submit: {
		marginTop: 46,
		width: 292,
		height: 56,
		fontSize: 20,
		letterSpacing: "0.02em",
		lineHeight: "16px",
	},
}));

export default EarlyAccess;
