import Box, { BoxProps } from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import CustomizedTooltip from "./Tooltip";
import BulbIcon from "../../assets/bulb";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	container: {
		borderRadius: theme.shape.borderRadius,
		mixBlendMode: "normal",
		cursor: "pointer",
		border: "1px dashed #FFFFFF",
		zIndex: (props) => props.Object_Zindex,
		boxShadow: (props) => (props.objectOnListHover ? "0 0 0 9999em rgb(0 0 0 / 50%)" : "none"),
	},
	active: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		width: "calc(100% + 2px)",
		height: "calc(100% + 2px)",
		left: -1,
		top: -1,
		boxShadow: "0 0 0 9999em rgb(0 0 0 / 50%)",
	},
	activeListHover: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		width: "calc(100% + 2px)",
		height: "calc(100% + 2px)",
		left: -1,
		top: -1,
		boxShadow: "0 0 0 9999em rgb(0 0 0 / 50%)",
		border: "1px dashed #FFFFFF",
		zIndex: 3,
	},
	tip: {
		position: "absolute",
		top: 5,
		right: 8,
		fontSize: 23,
		zIndex: 999,
	},
}));

interface IProps extends BoxProps {
	objectId: string;
	objectOrder: number;
	objectOnHover: string;
	active: boolean;
	disableTooltip?: boolean;
}

interface StyleProps {
	active: boolean;
	objectOnListHover: boolean;
	Object_Zindex: number;
}

function FaceRect({ objectId, objectOrder, objectOnHover, active, ...props }: IProps) {
	const { t } = useTranslation();
	const classes = useStyles({
		active: active,
		objectOnListHover: objectId === objectOnHover,
		Object_Zindex: objectOrder + 1,
	});

	return (
		<Box className={classes.root}>
			<Box className={clsx(classes.container, "hover-container")} position="absolute" {...props}>
				<Box className={classes.tip}>
					<CustomizedTooltip
						disable={props.disableTooltip}
						title={
							<>
								<b className={"title"}>{t("selection")}</b>
								<div>{t("selectionOfObjects")}</div>
							</>
						}
						placement="right"
					>
						<BulbIcon
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
							}}
						/>
					</CustomizedTooltip>
				</Box>
				<Box className={clsx({ [classes.active]: active })}></Box>
			</Box>
		</Box>
	);
}

export default FaceRect;
