import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import React from "react";
import { getRandomText } from "../../Constants/RandomTextGenerator";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		supriseButtonStyle: {
			color: "#495057",
			fontSize: 14,
			marginRight: 20,
			fontWeight: 600,
			// width: 138,
			height: 40,
			textTransform: "none",
			"&.MuiButton-root:hover": {
				backgroundColor: "transparent !important",
			},
			"&.MuiButton-root:focus": {
				backgroundColor: "transparent !important",
			},
			"&.MuiButton-root:active": {
				backgroundColor: "transparent !important",
			},
		},
	})
);

interface IProps {
	onClick?: (text: any) => void;
	list: string[];
}

const SurpriseMeButton: React.FC<IProps & ButtonProps> = ({ onClick, list, ...rest }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Button
			className={classes.supriseButtonStyle}
			onClick={() => {
				let text = getRandomText(list);
				onClick && onClick(text);
			}}
			{...rest}
			disableRipple
		>
			{t("surpriseMe")}
		</Button>
	);
};

export default SurpriseMeButton;
