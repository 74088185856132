import React, { useContext, useEffect, useState } from "react";
import { DrawerProps } from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Context from "../../Context/Context";
import { BriaObjectType, SelectedPointInterface } from "./utilities";
import Typography from "@material-ui/core/Typography";
import { ToolConfigTabsTypes } from "../../Models/ToolsConfig";
import ErrorIcon from "@material-ui/icons/Error";
import CustomizedTooltip from "../UI/Tooltip";
import { Box } from "@material-ui/core";
import ShowAt from "../Common/Wrappers/ShowAt";
import LoadingTooltip from "../UI/LoadingTooltip";
import PresenterIcon from "../../assets/icons/PresenterIcon";
import SceneIcon from "../../assets/icons/Scene";
import ObjectsIcon from "../../assets/icons/ObjectsIcon";
import CropIcon from "../../assets/icons/CropIcon";
import { IsIframe } from "../../Helpers/iframe";
import WandIcon from "../../assets/icons/WandIcon";
import StyleIcon from "../../assets/icons/StyleIcons";

type ActionIconsProps = {
	imageDetailsLoading: boolean;
	disableObjectsTab?: boolean;
	isLoadingPersonInfo: boolean;
	isLoadingObjectsInfo: boolean;
	selectedImageUrl: string;
	canUndo: boolean;
	canRedo: boolean;
	disableToggle?: boolean;
	layersURL: string | null;
	selectedVideo: string | null | undefined;
	onUndo: () => void;
	onRedo: () => void;
	onResetActions: () => void;
	onOpenInfoDialog: () => void;
	selectedPoint: SelectedPointInterface | null;
	setSelectedPoint: (x: any) => void;
	setSelectedVideo: React.Dispatch<React.SetStateAction<string | null | undefined>>;
	drawerAnchor: DrawerProps["anchor"];
	setDrawerAnchor: (value: DrawerProps["anchor"]) => void;
	isCompareMode: boolean;
	setIsCompareMode: (value: boolean) => void;
	pointOfInterests: any;
	onLoadError: boolean;
	isLoadingCropConfig: number;
	setIsUncropApplied: (value: boolean) => void;
	setShowCropSaveFirstTime: (value: boolean) => void;
	croppedImage: any;
	showCropSaveFirstTime: boolean;
	setShowCropSavePopUp: (value: boolean) => void;
	cropImageUrl: any;
	originalImage: any;
};

// const DirectionMapper = {
// 	left: 1,
// 	right: 2,
// 	bottom: 0,
// 	top: -1,
// };

// const StylesMapper = {
// 	[ToolsViewerMode.both]: 0,
// 	[ToolsViewerMode.thumbnails]: 1,
// 	[ToolsViewerMode.sliders]: 2,
// };

function ActionIcons({
	isLoadingPersonInfo,
	isLoadingObjectsInfo,
	drawerAnchor,
	setDrawerAnchor,
	selectedImageUrl,
	onUndo,
	onRedo,
	canUndo,
	canRedo,
	layersURL,
	selectedVideo,
	setSelectedVideo,
	onResetActions,
	onOpenInfoDialog,
	selectedPoint,
	setSelectedPoint,
	disableToggle = false,
	isCompareMode,
	setIsCompareMode,
	pointOfInterests,
	onLoadError = false,
	disableObjectsTab,
	isLoadingCropConfig,
	imageDetailsLoading,
	setIsUncropApplied,
	croppedImage,
	showCropSaveFirstTime,
	setShowCropSaveFirstTime,
	setShowCropSavePopUp,
	cropImageUrl,
	originalImage,
}: ActionIconsProps) {
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	const { t } = useTranslation();
	const classes = useStyles({
		enableTranslation: isIframe() && !!context.iframeConfig && context.iframeConfig.enableTranslation,
	});

	const [hasHuman, setHasHuman] = useState(false);
	const [hasObjects, setHasObjects] = useState(false);
	const [hasHumanConfig, setHasHumanConfig] = useState(true);
	const [hasdStyleConfig, setHasStyleConfig] = useState(true);
	const [hasSceneConfig, setHasSceneConfig] = useState(true);
	const [hasCameraConfig, setHasCameraConfig] = useState(true);
	const [hasGraphicsConfig, setHasGraphicsConfig] = useState(true);
	const [hasObjectsConfig, setHasObjectsConfig] = useState(true);
	const [hasCropConfig, setHasCropConfig] = useState(true);
	const [hasBrandConfig, setHasBrandConfig] = useState(true);
	const [hasImageStyle, setHasImageStyle] = useState(true);

	const [humanPoint, setHumanPoint] = useState<any>();
	const [objectPoint, setObjectPoint] = useState<any>();
	const [graphicsPoint, setGraphicsPoint] = useState<any>();
	const [scenePoint, setScenePoint] = useState<any>();
	const theme = useTheme();
	useEffect(() => {
		setHasHuman(pointOfInterests.some((point: any) => point.objectType === BriaObjectType.human));
		setHasObjects(pointOfInterests.some((point: any) => point.objectType === BriaObjectType.objects));
		setHumanPoint(pointOfInterests.find((pt: any) => pt.objectType === BriaObjectType.human));
		setObjectPoint(pointOfInterests.find((pt: any) => pt.objectType === BriaObjectType.objects));
		setGraphicsPoint(pointOfInterests.find((pt: any) => pt.objectType === BriaObjectType.graphics));
		setScenePoint(pointOfInterests.find((pt: any) => pt.objectType === BriaObjectType.imageBackground));
	}, [pointOfInterests]);

	useEffect(() => {
		if (context.iframeConfig) {
			const hasHumanConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) =>
					[
						ToolConfigTabsTypes.Expression.toString(),
						ToolConfigTabsTypes.Appearance.toString(),
						ToolConfigTabsTypes.Diversity.toString(),
					].indexOf(feature) !== -1
			);
			const hasStyleConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) =>
					[ToolConfigTabsTypes.Mood.toString(), ToolConfigTabsTypes.FineTuning.toString()].indexOf(
						feature
					) !== -1
			);

			const hasSceneConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) =>
					[
						ToolConfigTabsTypes.background.toString(),
						ToolConfigTabsTypes.ExpandBackground.toString(),
					].indexOf(feature) !== -1
			);
			const hasCameraConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.CameraMovement.toString()].indexOf(feature) !== -1
			);
			const hasGraphicsConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.TextEditor.toString()].indexOf(feature) !== -1
			);
			const hasObjectsConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.Objects.toString()].indexOf(feature) !== -1
			);
			const hasCropConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.crop.toString()].indexOf(feature) !== -1
			);
			const hasBrandConfig = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.createCampaign.toString()].indexOf(feature) !== -1
			);
			const hasImageStyle = context.iframeConfig?.enabledFeatures.some(
				(feature: string) => [ToolConfigTabsTypes.ImageStyle.toString()].indexOf(feature) !== -1
			);

			setHasHumanConfig(hasHumanConfig);
			setHasStyleConfig(hasStyleConfig);
			setHasSceneConfig(hasSceneConfig);
			setHasCameraConfig(hasCameraConfig);
			setHasGraphicsConfig(hasGraphicsConfig);
			setHasObjectsConfig(hasObjectsConfig);
			setHasCropConfig(hasCropConfig);
			setHasBrandConfig(hasBrandConfig);
			setHasImageStyle(hasImageStyle);

			// TODO: if you noticed that
			if (!hasSceneConfig) {
				if (hasHumanConfig) {
					setSelectedPoint({
						...(humanPoint ? humanPoint : selectedPoint),
						objectType: BriaObjectType.human,
					});
				} else {
					if (hasObjectsConfig) {
						setSelectedPoint({
							...(objectPoint ? objectPoint : selectedPoint),
							objectType: BriaObjectType.objects,
						});
					} else {
						if (hasStyleConfig) {
							setSelectedPoint({
								...selectedPoint,
								objectType: BriaObjectType.brand,
							});
						} else {
							if (hasGraphicsConfig) {
								setSelectedPoint({
									...(graphicsPoint ? graphicsPoint : selectedPoint),
									objectType: BriaObjectType.graphics,
								});
							} else {
								if (hasCropConfig) {
									setSelectedPoint({
										...selectedPoint,
										objectType: BriaObjectType.crop,
									});
								}
							}
						}
					}
				}
			}
		}
	}, [context.iframeConfig, pointOfInterests]);
	const handleTabSelected = (tab: any) => {
		const isImageStyle = selectedPoint?.objectType === BriaObjectType.imageStyle;
		const imgUrl = selectedPoint?.objectType === BriaObjectType.imageStyle ? selectedImageUrl : cropImageUrl;
		if ((isImageStyle || croppedImage) && imgUrl && imgUrl !== originalImage && showCropSaveFirstTime) {
			setShowCropSavePopUp(true);
			context.setSelectedTab(tab);
		} else if (tab) {
			setSelectedPoint(tab);
		}
	};

	const ActionIconTip = ({ key, tab }: { key: string; tab: string }) => {
		const CropError = t("cropNotAvailable");
		const genralError = t("theSystemDidntFindThatCanBeGoodQuality", { tab: t(tab.toLowerCase()) });
		const theme = useTheme();
		return (
			<CustomizedTooltip
				arrow
				multiLine={true}
				title={
					<>
						<div>{tab === "crop" ? CropError : genralError}</div>
					</>
				}
				placement="right"
			>
				<ErrorIcon
					style={{
						fontSize: 16,
						position: "relative",
						right: 8,
						color: theme.palette.primary.main,
						marginRight: -38,
						cursor: "pointer",
					}}
				/>
			</CustomizedTooltip>
		);
	};

	return (
		<Grid className={classes.root}>
			<Grid
				className={clsx({
					[classes.tabContainer]: true,
					[classes.disabledtab]: onLoadError,
				})}
				container
				alignItems="center"
			>
				{hasSceneConfig && (
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.imageBackground,
						})}
						onClick={() => {
							handleTabSelected({
								...(scenePoint ? scenePoint : selectedPoint),
								objectType: BriaObjectType.imageBackground,
							});
						}}
					>
						<span className={classes.textStyle}>
							<SceneIcon
								color={
									selectedPoint?.objectType === BriaObjectType.imageBackground
										? theme.palette.primary.dark
										: "#5B5B5B"
								}
								className={classes.iconStyle}
							/>
							<Typography className={classes.titleStyle}>{t("scene")}</Typography>
						</span>
					</Grid>
				)}

				{hasHumanConfig && (
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.human,
							[classes.disabledTabButton]: !hasHuman,
						})}
						onClick={() => {
							handleTabSelected(
								hasHuman && {
									...humanPoint,
									objectType: BriaObjectType.human,
								}
							);
						}}
					>
						{!imageDetailsLoading && !isLoadingPersonInfo && !hasHuman && (
							<ActionIconTip key={"Presenters"} tab={"Presenters"} />
						)}
						<Box
							className={clsx({
								[classes.textStyle]: true,
								[classes.disabledIconItem]: !hasHuman,
							})}
						>
							<PresenterIcon
								color={
									selectedPoint?.objectType === BriaObjectType.human
										? theme.palette.primary.dark
										: !hasHuman
										? "rgba(26, 6, 56, 0.25)"
										: "#5B5B5B"
								}
								className={classes.iconStyle}
							/>
							<Typography className={classes.titleStyle}>{t("presenters")}</Typography>
						</Box>
						{(imageDetailsLoading || isLoadingPersonInfo) && (
							<LoadingTooltip key={"Presenters"} tab={"Presenters"} />
						)}
					</Grid>
				)}
				{(!isIframe() || (isIframe() && hasObjectsConfig)) && (
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.objects,
							[classes.disabledTabButton]: disableObjectsTab,
						})}
						onClick={() => {
							handleTabSelected(
								!disableObjectsTab && {
									...(objectPoint ? objectPoint : selectedPoint),
									objectType: BriaObjectType.objects,
								}
							);
						}}
					>
						{!imageDetailsLoading && disableObjectsTab && <ActionIconTip key={"Objects"} tab={"Objects"} />}
						<Box
							className={clsx({
								[classes.textStyle]: true,
								[classes.disabledIconItem]: disableObjectsTab,
							})}
						>
							<ObjectsIcon
								color={
									selectedPoint?.objectType === BriaObjectType.objects
										? theme.palette.primary.dark
										: "#5B5B5B"
								}
								className={classes.iconStyle}
							/>
							<Typography className={classes.titleStyle}>{t("objects")}</Typography>
						</Box>
						{(imageDetailsLoading || isLoadingObjectsInfo) && (
							<LoadingTooltip key={"Objects"} tab={"Objects"} />
						)}
					</Grid>
				)}
				<ShowAt testing condition={hasdStyleConfig}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.brand,
						})}
					>
						<span
							onClick={() =>
								handleTabSelected({
									...selectedPoint,
									objectType: BriaObjectType.brand,
								})
							}
						>
							Style
						</span>
					</Grid>
				</ShowAt>
				<ShowAt testing condition={hasGraphicsConfig}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.graphics,
						})}
						style={{
							marginRight: 0,
						}}
					>
						<span
							onClick={() => {
								setIsCompareMode(false);
								handleTabSelected({
									...(graphicsPoint ? graphicsPoint : selectedPoint),
									objectType: BriaObjectType.graphics,
								});
							}}
						>
							Graphics
						</span>
					</Grid>
				</ShowAt>
				<ShowAt testing condition={hasCameraConfig}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.camera,
						})}
					>
						<span
							onClick={() => {
								setIsCompareMode(false);
								handleTabSelected({
									...selectedPoint,
									objectType: BriaObjectType.camera,
								});
							}}
						>
							Camera
						</span>
					</Grid>
				</ShowAt>
				<ShowAt showAlways condition={hasCropConfig}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.crop,
							[classes.disabledTabButton]: [0, -1].includes(isLoadingCropConfig),
						})}
						onClick={() =>
							![0, -1].includes(isLoadingCropConfig) &&
							handleTabSelected({
								...(scenePoint ? scenePoint : selectedPoint),
								objectType: BriaObjectType.crop,
							})
						}
					>
						{isLoadingCropConfig === -1 && <ActionIconTip key={"crop"} tab={"crop"} />}
						<span
							className={clsx({
								[classes.textStyle]: true,
								[classes.disabledIconItem]: [0, -1].includes(isLoadingCropConfig),
							})}
						>
							<CropIcon
								color={
									selectedPoint?.objectType === BriaObjectType.crop
										? theme.palette.primary.dark
										: "#5B5B5B"
								}
								className={classes.iconStyle}
							/>
							<Typography className={classes.titleStyle}>{t("size")}</Typography>
						</span>
						{isLoadingCropConfig === 0 && <LoadingTooltip key={"crop"} tab={"crop"} />}
					</Grid>
				</ShowAt>
				<ShowAt showAlways condition={hasBrandConfig}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.newBrand,
						})}
						onClick={() =>
							handleTabSelected({
								...selectedPoint,
								objectType: BriaObjectType.newBrand,
							})
						}
					>
						<WandIcon
							style={{ width: "22px" }}
							stroke={
								selectedPoint?.objectType === BriaObjectType.newBrand
									? theme.palette.primary.dark
									: "#5B5B5B"
							}
						/>
						<span>{t("brand")}</span>
					</Grid>
				</ShowAt>
				<ShowAt admin testing condition={hasImageStyle}>
					<Grid
						item
						className={clsx({
							[classes.toggleItem]: true,
							[classes.selectedToggle]: selectedPoint?.objectType === BriaObjectType.imageStyle,
						})}
						onClick={() =>
							setSelectedPoint({
								...selectedPoint,
								objectType: BriaObjectType.imageStyle,
							})
						}
					>
						<StyleIcon
							style={{ width: "22px" }}
							stroke={
								selectedPoint?.objectType === BriaObjectType.imageStyle
									? theme.palette.primary.dark
									: "#5B5B5B"
							}
						/>
						<span>Style</span>
					</Grid>
				</ShowAt>
			</Grid>
			{/* {(!context.iframeConfig ||
				(context.iframeConfig?.enabledFeatures &&
					context.iframeConfig?.enabledFeatures.length) > 0) && (
				<Box className={classes.btnContainer}> */}
			{/* <Grid
						item
						className={clsx(
							classes.grid,
							selectedPoint?.objectType ===
								BriaObjectType.camera &&
								classes.disabledIconItem
						)}
					>
						<CompareButton
							selectedValue={isCompareMode ? 1 : 2}
							onChange={(value) => {
								if (
									selectedPoint?.objectType !==
									BriaObjectType.camera
								)
									setIsCompareMode(value === 1);
							}}
						/>
					</Grid>
					<Divider
						orientation="vertical"
						className={clsx(
							classes.dividerHeight,
							selectedPoint?.objectType ===
								BriaObjectType.camera && classes.hide
						)}
					/> */}
			{/* <Grid item className={classes.grid}>
						<IconButton
							disabled={!canUndo}
							onClick={() => {
								onUndo();
							}}
							className={classes.padding}
						>
							<UndoIcon
								className={clsx({
									[classes.iconItem]: true,
									[classes.disabledIconItem]: !canUndo,
								})}
							/>
						</IconButton>
						<Typography
							className={clsx({
								[classes.text]: true,
								[classes.disabledIconItem]: !canUndo,
							})}
						>
							{t<string>("undo")}
						</Typography>
					</Grid> */}
			{/* <Grid item className={classes.grid}>
						<IconButton
							disabled={!canRedo}
							onClick={() => {
								onRedo();
							}}
							className={classes.padding}
						>
							<RedoIcon
								className={clsx({
									[classes.iconItem]: true,
									[classes.disabledIconItem]: !canRedo,
								})}
							/>
						</IconButton>
						<Typography
							className={clsx({
								[classes.text]: true,
								[classes.disabledIconItem]: !canRedo,
							})}
						>
							{t<string>("redo")}
						</Typography>
					</Grid> */}

			{/* <Grid item className={classes.grid}>
						<IconButton
							onClick={onResetActions}
							className={classes.padding}
						>
							<ResetIcon className={classes.iconItem2} />
						</IconButton>
						<Typography className={classes.text}>
							{t<string>("reset")}
						</Typography>
					</Grid> */}
			{/* </Box> */}
			{/* )} */}
			{/* TODO: Improve styling */}
			{/* <Divider orientation="vertical" className={classes.dividerHeight} />

			<Grid item>
				<ToggleButtonGroup size="small" exclusive>
					<ToggleButton value="left">
						<ThumbnailsIcon
							// className={classes.icon}
							fontSize="small"
						/>
					</ToggleButton>
					<ToggleButton value="center">
						<SlidersIcon
							// className={classes.icon}
							fontSize="small"
						/>
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid> */}
			{/*
			<Grid item>
				<FormControl>
					<IconsDropdown
						value={selectedIndex}
						options={viewOptions}
						onChange={onDirectionChange}
						tooltipTitle="view"
					></IconsDropdown>
				</FormControl>
			</Grid>

			<Grid item>
				<FormControl>
					<IconsDropdown
						value={selectedStyleIndex}
						options={styleOptions}
						onChange={onStyleChange}
						tooltipTitle="style"
						disabled={drawerAnchor === "bottom"}
					></IconsDropdown>
				</FormControl>
			</Grid> */}

			{/* <Grid item>
				<Tooltip title={t<string>("addFileToPersonalGallery")} arrow>
					<Button
						variant="outlined"
						color="secondary"
						className={clsx(classes.button, classes.addButton)}
						startIcon={<AddIcon />}
					>
						{t("addToGallery")}
					</Button>
				</Tooltip>
			</Grid> */}

			{/* <Grid item>
				<Tooltip title={t<string>("downloadFileForPersonalUse")} arrow>
					<Button
						variant="contained"
						color="secondary"
						className={clsx(classes.button, classes.downloadButton)}
						endIcon={<ExpandMoreIcon />}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							setDownloadMenuAnchor(event.currentTarget);
						}}
					>
						{t("download")}
					</Button>
				</Tooltip>
			</Grid> */}

			{/* <Menu
				id="lock-menu"
				anchorEl={downloadMenuAnchor}
				keepMounted
				open={Boolean(downloadMenuAnchor)}
				onClose={handleDownloadMenuClose}
				style={{ top: 45 }}
			>
				<MenuItem disabled>
					<Typography
						style={{
							fontSize: 10,
							textAlign: "left",
						}}
					>
						{t("download")}
					</Typography>
				</MenuItem>
				<MenuItem
					// component={Link}
					// href={selectedImageUrl}
					// download
					// target="_blank"
					onClick={handleDownloadMenuClose}
					className={classes.downloadItem}
				>
					{t("downloadAsJPG")}
				</MenuItem>
				<MenuItem
					// onClick={handleDownloadMenuClose}
					className={classes.downloadItem}
				>
					{t("downloadAsPNG")}
				</MenuItem>
				<MenuItem
					component={Link}
					href={layersURL ?? ""}
					target="_blank"
					onClick={handleDownloadMenuClose}
					disabled={layersURL === "" || !layersURL}
					className={classes.downloadItem}
				>
					{t("downloadAsPSD")}
				</MenuItem>
				{selectedVideo && (
					<MenuItem
						component={Link}
						href={selectedVideo || ""}
						target="_blank"
						onClick={handleDownloadVideo}
						className={classes.downloadItem}
						download
						disabled={!selectedVideo}
					>
						{t("downloadAsMP4")}
					</MenuItem>
				)}
			</Menu> */}
		</Grid>
	);
}

interface StyleProps {
	enableTranslation: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: (props) => ({
		overflow: "auto",
		color: "#A1A1A1",
		width: props.enableTranslation ? 106 : 104,
		maxHeight: 650,
		minHeight: 390,
		background: theme.palette.background.paper,
		borderRadius: 8,
		border: "1px solid #E7E7E7",
		padding: 8,
	}),
	dividerHeight: {
		height: 20,
		margin: 8,
		marginLeft: 20,
	},
	button: {
		borderRadius: 20,
		textTransform: "capitalize",
		margin: 6,
	},
	addButton: {
		width: 155,
		height: 40,
		textTransform: "none",
	},
	downloadButton: {
		width: 125,
		height: 40,
	},

	downloadItem: {
		fontSize: 10,
		color: theme.palette.text.primary,
		"&:hover": {
			backgroundColor: theme.palette.menuItemBackground.main,
			opacity: 0.5,
			color: theme.palette.primary.main,
		},
		"&:active": {
			backgroundColor: theme.palette.primary.dark,
			opacity: 1,
			color: theme.palette.common.white,
		},
	},
	disabledButton: {
		"&:disabled": {
			opacity: 0.3,
		},
	},
	icon: {
		fill: "transparent",
		fontSize: 16,
		margin: `0px auto`,
	},
	iconItem: {
		// fill: theme.palette.text.primary,
		fill: theme.palette.cardTextSecondary.main,
		fontSize: 16,
	},
	iconItem2: {
		// stroke: theme.palette.text.primary,
		stroke: theme.palette.cardTextSecondary.main,
		fill: "transparent",
		fontSize: 16,
	},
	disabledTabButton: {
		cursor: "auto !important",
		pointerEvents: "auto",
	},
	disabledIconItem: {
		opacity: "0.3 !important",
	},
	disabledtab: {
		"& div": {
			cursor: "default",
			"& > span": {
				opacity: "0.3 !important",
				pointerEvents: "none",
			},
		},
	},
	selectedIcon: {
		opacity: 1,
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			opacity: 1,
			backgroundColor: theme.palette.primary.main,
		},
	},
	text: {
		fontSize: 10,
	},
	titleStyle: {
		marginTop: 8,
		fontWeight: 500,
		fontSize: 14,
	},
	textStyle: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	iconStyle: {
		alignSelf: "center",
	},
	toggleItem: {
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		height: 88,
		width: 88,
		justifyContent: "center",
		alignItems: "center",
		color: "#1A0638",
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#E7E7E7",
		},
	},
	selectedToggle: {
		color: theme.palette.primary.dark,
		backgroundColor: "rgba(83, 0, 201, 0.05) !important",
		border: "2px solid rgba(83, 0, 201, 0.05);",
		boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02)",
	},
	grid: { display: "grid", justifyItems: "center" },
	padding: {
		paddingBottom: 3,
		backgroundColor: "transparent !important",
	},
	hide: {
		opacity: 0,
		userActions: "none",
	},
	tabContainer: {
		display: "inline-flex",
		gap: 4,
		color: "#A1A1A1",
	},
	btnContainer: {
		marginTop: "12px",
		display: "inline-flex",
		color: "#A1A1A1",
		position: "relative",
		right: 10,
		alignItems: "flex-start",
	},
}));

export default ActionIcons;
