import OliverIcon from "../assets/icons/OliverIcon";
import { ReactElement } from "react";

export interface CustomAuthProvider {
	domains: string[];
	name: string;
	providerId: string;
	icon: ReactElement;
}

export const CustomAuthProviders: CustomAuthProvider[] = [
	{
		domains: ["oliver.bria.ai", "oliver-int.bria.ai"],
		name: "oliver",
		providerId: "saml.oliver-agency",
		icon: <OliverIcon />,
	},
];
