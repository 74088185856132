import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import Icon from "../Icon/Icon";
import InfoIcon from "./InfoIcon/InfoIcon";

const themeSrc = {
	info: {
		wrapperBg: "rgba(83, 0, 201, 0.1)",
		color: "#5300C9",
		iconName: "check-icon",
	},
	error: {
		color: "#D80067",
		wrapperBg: "rgba(216, 0, 103, 0.1);",
		iconName: "warning-icon",
	},
};

export default function InlineAlert({
	msg,
	type,
}: {
	msg: string;
	type: "error" | "info";
}) {
	const classes = useStyles({ type });

	return (
		<Box className={classes.alertWrapper}>
			<Box>
				<Icon name={(themeSrc as any)?.[type].iconName} />
			</Box>
			<Typography className={classes.text}>{msg}</Typography>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	alertWrapper: {
		display: "flex",
		gap: 10,
		alignItems: "center",
		padding: "0.5rem 0.8rem",
		background: ({ type }: { type: string }) =>
			(themeSrc as any)?.[type].wrapperBg,
		color: ({ type }: { type: string }) => (themeSrc as any)?.[type].color,
		width: "100%",
		marginTop: "1rem",
		borderRadius: 7,
	},
	// infoIconWrapper: {
	// 	aspectRatio: "1/1",
	// 	width: 23,
	// 	borderRadius: "50%",
	// 	backgroundColor: ({ type }: { type: string }) =>
	// 		(themeSrc as any)?.[type].color,
	// 	display: "flex",
	// 	justifyContent: "center",
	// 	alignItems: "center",
	// },
	text: {
		fontSize: 12,
		fontWeight: "bolder",
	},
}));
