import { GenModels } from "../../Components/Tools/utilities";

export const BriaAPIConstants = {
	UPLOAD_ROUTE: "upload",
	LEAN_IMAGE_INFO_ROUTE: "info",
	PERSON_INFO_ROUTE: "person/info",
	OBJECTS_INFO_ROUTE: "objects/info",
	BACKGROUND_INFO_ROUTE: "background/info",
	MASK_GENERATOR: "objects/mask_generator",
	DEV_INFO_ROUTE: "dev_info",
	FASHION_INFO_ROUTE: "fashion_info",
	CREATE_ROUTE: "create",
	CREATE_FASHION_ROUTE: "create_fashion",
	VIDEO_ROUTE: "video",
	REMOVE_OBJECT: "remove_object",
	SEARCH_ROUTE: "search",
	SEARCH_ON_IMAGE_ROUTE: "search_on_image",
	INCREASE_RESOLUTION: "increase_resolution",
	CAPTION_ROUTE: "caption",
	TEXT_TO_IMAGE: "text-to-image",
	BRIA_TAILORED: "text-to-image/tailored",
	SEARCH_SIMILAR_IMAGES: "search_similar_images",
	VISUAL_LOG_LEVELS: {
		OFF: 0,
		VERBOSE: 100,
		TRACE: 200,
		INFO: 300,
	},
};

export const BriaModelsParams = {
	[GenModels.bria_2_3_fast]: {
		model_type: "fast",
		model_version: "2.3",
	},
	[GenModels.bria_2_2_hd]: {
		model_type: "hd",
		model_version: "2.2",
	},
	[GenModels.bria_2_3]: {
		model_type: "base",
		model_version: "2.3",
	},
};

export enum ApiActions {
	REMOVE_OBJECT = "remove_object",
	FACE_MANIPULATIONS = "face_manipulations",
	FASHION_MEN = "fashion_men",
	REMOVE_BG = "remove_bg",
	INCREASE_RESOLUTION = "increase_resolution",
	APPLY_MOOD = "apply_mood",
	UPPER_CLOTHES = "change_clothing_color",
	REPLACE_BG = "replace_bg",
	IMAGE_STYLE = "apply_style",
	UNCROP = "image_expansion",
	BLUR_BG = "blur_bg ",
	PASTE_LOGO = "paste_logo",
	PLACE_EMBEDDED_LOGO = "paste_logo",
	RECAST_MODEL = "recast_model",
	REMOVE_BRUSH_OBJECT = "remove_brushed_object",
	REMOVE_AUTO_OBJECT = "remove_auto_object",
	PROMPT_GENERATE = "image/generate",
}
