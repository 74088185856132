import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
	flex: {
		display: "flex",
		alignItems: "center",
	},
	noGap: {
		gap: 0,
	},
	sm: {
		gap: 5,
	},
	md: {
		gap: 15,
	},
	lg: {
		gap: 24,
	},
	xlg: {
		gap: 30,
	},
}));

export enum FlexGaps {
	noGap = "noGap",
	sm = "sm",
	md = "md",
	lg = "lg",
	xlg = "xlg",
}
interface IFlex {
	children: React.ReactNode[] | React.ReactNode;
	justifyContent?: string;
	passedClasses?: string[];
	style?: React.CSSProperties;
	gap?: FlexGaps;
}

export default function Flex({
	children,
	passedClasses = [],
	gap = FlexGaps.sm,
	style,
	...rest
}: IFlex) {
	const classes: any = useStyles();
	const joinedClasses = [classes.flex, ...passedClasses, classes?.[gap]].join(
		" "
	);

	return (
		<Box className={joinedClasses} style={style} {...rest}>
			{children}
		</Box>
	);
}
