import { ApolloClient, DefaultContext } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import gql from "graphql-tag";
import { SubscriptionCreditsTypes } from "../Constants/SubscriptionConstants";
import {
	AWSInstanceAction,
	DBName,
	GCPInstanceAction,
	MoodInput,
	ProxyRequest,
	WebOperations,
} from "../types/graphql-global-types";
import client, { IframeClient } from "./client";
import { addLabels, addLabelsVariables } from "./types/addLabels";
import {
	addRecordToDownloads as addRecordToDownloadsRes,
	addRecordToDownloadsVariables,
} from "./types/addRecordToDownloads";
import {
	sendRequestFeatureEmail as sendRequestFeatureEmailRes,
	sendRequestFeatureEmailVariables,
} from "./types/sendRequestFeatureEmail";
import {
	cancelApiSubscription as cancelApiSubscriptionInterface,
	cancelApiSubscriptionVariables,
} from "./types/cancelApiSubscription";
import { cancelUserSubscription as cancelUserSubscriptionInterface } from "./types/cancelUserSubscription";
import {
	createAndAssociateUserOrganization as createAndAssociateUserOrganizationResp,
	createAndAssociateUserOrganization_createAndAssociateUserOrganization,
	createAndAssociateUserOrganizationVariables,
} from "./types/createAndAssociateUserOrganization";
import {
	createHubspotContact as createHubspotContactResp,
	createHubspotContactVariables,
} from "./types/createHubspotContact";
import {
	createOrganization as createOrganizationResponse,
	createOrganization_createOrganization,
	createOrganizationVariables,
} from "./types/createOrganization";
import { deleteByPath, deleteByPathVariables } from "./types/deleteByPath";
import { deleteUser, deleteUserVariables } from "./types/deleteUser";
import { disableUser, disableUserVariables } from "./types/disableUser";
import { enableUser, enableUserVariables } from "./types/enableUser";
import { moveImages, moveImagesVariables } from "./types/moveImages";
import {
	parseXmpFileContent as parseXmpFileContentResp,
	parseXmpFileContent_parseXmpFileContent_mood,
	parseXmpFileContentVariables,
} from "./types/parseXmpFileContent";
import { removeLabels, removeLabelsVariables } from "./types/removeLabels";
import { renameFolder, renameFolderVariables } from "./types/renameFolder";
import { runAwsInstanceCommand, runAwsInstanceCommandVariables } from "./types/runAwsInstanceCommand";
import { runGcpInstanceCommand, runGcpInstanceCommandVariables } from "./types/runGcpInstanceCommand";
import {
	saveBrandStyle as saveBrandStyleResp,
	saveBrandStyle_saveBrandStyle,
	saveBrandStyleVariables,
} from "./types/saveBrandStyle";
import { sendMetricsToGrafana_sendMetrics, sendMetricsToGrafanaVariables } from "./types/sendMetricsToGrafana";
import { updateImageScore, updateImageScoreVariables } from "./types/updateImageScore";
import {
	updateUserSubscription as updateUserSubscriptionInterface,
	updateUserSubscriptionVariables,
} from "./types/updateUserSubscription";
import { uploadImageToResearch, uploadImageToResearchVariables } from "./types/uploadImageToResearch";
import { uploadImageToS3, uploadImageToS3Variables } from "./types/uploadImageToS3";
import {
	uploadLogoToS3 as uploadLogoToS3Resp,
	uploadLogoToS3_uploadLogoToS3,
	uploadLogoToS3Variables,
} from "./types/uploadLogoToS3";
import {
	uploadBrushedImageToS3 as uploadBrushedImageToS3Resp,
	uploadBrushedImageToS3_uploadBrushedImageToS3,
	uploadBrushedImageToS3Variables,
} from "./types/uploadBrushedImageToS3";
import {
	addImageToUserGallery as addImageToUserGalleryInterface,
	addImageToUserGalleryVariables,
} from "./types/addImageToUserGallery";

import {
	deleteOrganizationAssetGallery as deleteOrganizationAssetGalleryInterface,
	deleteOrganizationAssetGalleryVariables,
} from "./types/deleteOrganizationAssetGallery";
import {
	sendProxyRequest as sendProxyRequestInterface,
	sendProxyRequest_sendProxyRequest_proxyResponse,
	sendProxyRequestVariables,
} from "./types/sendProxyRequest";
import { setPostRegistrationConfigs as setPostRegistrationConfigsInterface } from "./types/setPostRegistrationConfigs";
import { deleteMongoItem, deleteMongoItemVariables } from "./types/deleteMongoItem";
import {
	uploadTempImageToS3 as uploadTempImageToS3Result,
	uploadTempImageToS3_uploadTempImageToS3,
	uploadTempImageToS3Variables,
} from "./types/uploadTempImageToS3";
import { translateText as translateTextInterface, translateTextVariables } from "./types/translateText";
import {
	InsertBrandPresetToDb as InsertBrandPresetToDbInterface,
	InsertBrandPresetToDb_InsertBrandPresetToDb,
	InsertBrandPresetToDbVariables,
} from "./types/InsertBrandPresetToDb";
import { Camp } from "../Components/UI/CreateCampaign/utils";
import { sendEmail as sendEmailRes, sendEmailVariables } from "./types/SendEmail";

export async function deleteMongoImage(
	dbName: DBName,
	mongoId: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<deleteMongoItem, deleteMongoItemVariables>({
			query: gql`
				mutation deleteMongoItem($dbName: DBName!, $mongoId: String!) {
					deleteMongoItem(mongoId: $mongoId, dbName: $dbName) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoId: mongoId,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function deleteFolder(
	dbName: DBName,
	path: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<deleteByPath, deleteByPathVariables>({
			query: gql`
				mutation deleteByPath($dbName: DBName!, $path: String!) {
					deleteByPath(path: $path, dbName: $dbName) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				path: path,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function changeFoldername(
	dbName: DBName,
	curFolderPath: string,
	newFolderName: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		const res = await localClient.query<renameFolder, renameFolderVariables>({
			query: gql`
				mutation renameFolder($dbName: DBName!, $curFolderPath: String!, $newFolderName: String!) {
					renameFolder(curFolderPath: $curFolderPath, dbName: $dbName, newFolderName: $newFolderName) {
						newFolderPath
					}
				}
			`,
			variables: {
				dbName: dbName,
				curFolderPath: curFolderPath,
				newFolderName: newFolderName,
			},
		});
		if (res.data.renameFolder?.newFolderPath === undefined || res.data.renameFolder?.newFolderPath === null) {
			alert("Renaming folder has failed!");
			return Promise.reject();
		}
		return Promise.resolve(res.data.renameFolder?.newFolderPath);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function moveImagesToFolder(
	dbName: DBName,
	mongoIds: string[],
	newPath: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<moveImages, moveImagesVariables>({
			query: gql`
				mutation moveImages($dbName: DBName!, $mongoIds: [String!]!, $newPath: String!) {
					moveImages(mongoIds: $mongoIds, dbName: $dbName, newPath: $newPath) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
				newPath: newPath,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addLabelsToImages(
	dbName: DBName,
	mongoIds: string[],
	labels: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<addLabels, addLabelsVariables>({
			query: gql`
				mutation addLabels($dbName: DBName!, $mongoIds: [String!]!, $labels: [String!]!) {
					addLabels(mongoIds: $mongoIds, dbName: $dbName, labels: $labels) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
				labels: labels,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function createHubspotContact(
	extraProperties?: {
		[key: string]: any;
	},
	anonymous?: boolean,
	userEmail?: string,
	userFirstName?: string,
	userLastName?: string,
	userCompany?: string,
	userRole?: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<createHubspotContactResp, createHubspotContactVariables>({
			query: gql`
				mutation createHubspotContact(
					$extraPropertiesJson: String
					$anonymous: Boolean
					$userEmail: String
					$userFirstName: String
					$userLastName: String
					$userCompany: String
					$userRole: String
				) {
					createHubspotContact(
						extraPropertiesJson: $extraPropertiesJson
						anonymous: $anonymous
						userEmail: $userEmail
						userFirstName: $userFirstName
						userLastName: $userLastName
						userCompany: $userCompany
						userRole: $userRole
					) {
						ok
					}
				}
			`,
			variables: {
				extraPropertiesJson: extraProperties ? JSON.stringify(extraProperties) : extraProperties,
				anonymous: anonymous,
				userEmail: userEmail,
				userFirstName: userFirstName,
				userLastName: userLastName,
				userCompany: userCompany,
				userRole: userRole,
			},
		});
		return Promise.resolve(res.data.createHubspotContact?.ok ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function removeLabelsToImages(
	dbName: DBName,
	mongoIds: string[],
	labels: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<removeLabels, removeLabelsVariables>({
			query: gql`
				mutation removeLabels($dbName: DBName!, $mongoIds: [String!]!, $labels: [String!]!) {
					removeLabels(mongoIds: $mongoIds, dbName: $dbName, labels: $labels) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
				labels: labels,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function runGCPInstanceCommand(
	instanceNames: string[],
	action: GCPInstanceAction,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<runGcpInstanceCommand, runGcpInstanceCommandVariables>({
			query: gql`
				mutation runGcpInstanceCommand($instanceNames: [String!]!, $action: GCPInstanceAction!) {
					runGcpInstanceCommand(instanceNames: $instanceNames, action: $action) {
						ok
					}
				}
			`,
			variables: {
				instanceNames: instanceNames,
				action: action,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function runAWSInstanceCommand(
	instanceIds: string[],
	action: AWSInstanceAction,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<runAwsInstanceCommand, runAwsInstanceCommandVariables>({
			query: gql`
				mutation runAwsInstanceCommand($instanceIds: [String!]!, $action: AWSInstanceAction!) {
					runAwsInstanceCommand(instanceIds: $instanceIds, action: $action) {
						ok
					}
				}
			`,
			variables: {
				instanceIds: instanceIds,
				action: action,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addImageToResearch(
	binaryStr: string,
	folder: string,
	subfolder: string,
	project: string,
	source: string,
	labels: string[] | null,
	displayName: string | null,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<uploadImageToResearch, uploadImageToResearchVariables>({
			query: gql`
				mutation uploadImageToResearch(
					$file: Upload!
					$displayName: String
					$labels: [String!]
					$folder: String!
					$subfolder: String!
					$project: String!
					$source: String!
				) {
					uploadImageToResearch(
						file: $file
						displayName: $displayName
						labels: $labels
						folder: $folder
						subfolder: $subfolder
						project: $project
						source: $source
					) {
						ok
					}
				}
			`,
			variables: {
				file: { base_64_string: binaryStr },
				labels: labels,
				folder: folder,
				subfolder: subfolder,
				project: project,
				source: source,
				displayName: displayName,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function saveBrandStyle(
	name: string,
	logoUrl: string,
	colorPallet: string[],
	mood: MoodInput,
	gardian: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<saveBrandStyle_saveBrandStyle | null> {
	try {
		const res = await localClient.query<saveBrandStyleResp, saveBrandStyleVariables>({
			query: gql`
				mutation saveBrandStyle(
					$name: String!
					$logoUrl: String
					$colorPallet: [String!]
					$mood: MoodInput
					$gardian: String
				) {
					saveBrandStyle(
						name: $name
						logoUrl: $logoUrl
						colorPallet: $colorPallet
						mood: $mood
						gardian: $gardian
					) {
						ok
						brandId
					}
				}
			`,
			variables: {
				name,
				logoUrl,
				colorPallet,
				mood,
				gardian,
			},
		});
		return Promise.resolve(res.data.saveBrandStyle);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function uploadLogoToS3(
	filename: string,
	logoBinaryStr: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<uploadLogoToS3_uploadLogoToS3 | null> {
	try {
		const res = await localClient.query<uploadLogoToS3Resp, uploadLogoToS3Variables>({
			query: gql`
				mutation uploadLogoToS3($logo: Upload) {
					uploadLogoToS3(logo: $logo) {
						ok
						url
					}
				}
			`,
			variables: {
				logo: {
					filename,
					base_64_string: logoBinaryStr,
				},
			},
		});
		return Promise.resolve(res.data.uploadLogoToS3);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function uploadBrushedImageToS3(
	filename: string,
	binaryStr: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<uploadBrushedImageToS3_uploadBrushedImageToS3 | null> {
	try {
		const res = await localClient.query<uploadBrushedImageToS3Resp, uploadBrushedImageToS3Variables>({
			query: gql`
				mutation uploadBrushedImageToS3($brushedImage: Upload) {
					uploadBrushedImageToS3(brushedImage: $brushedImage) {
						ok
						url
					}
				}
			`,
			variables: {
				brushedImage: {
					filename,
					base_64_string: binaryStr,
				},
			},
		});
		return Promise.resolve(res.data.uploadBrushedImageToS3);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function uploadTempImageToS3(
	filename: string,
	binaryStr: string,
	folder: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<uploadTempImageToS3_uploadTempImageToS3 | null> {
	try {
		const res = await localClient.query<uploadTempImageToS3Result, uploadTempImageToS3Variables>({
			query: gql`
				mutation uploadTempImageToS3($image: Upload!, $folder: String!) {
					uploadTempImageToS3(image: $image, folder: $folder) {
						ok
						url
					}
				}
			`,
			variables: {
				image: {
					filename,
					base_64_string: binaryStr,
				},
				folder,
			},
		});
		return Promise.resolve(res.data.uploadTempImageToS3);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function createAndAssociateUserOrganization(
	orgName: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<createAndAssociateUserOrganization_createAndAssociateUserOrganization | null> {
	try {
		const res = await localClient.query<
			createAndAssociateUserOrganizationResp,
			createAndAssociateUserOrganizationVariables
		>({
			query: gql`
				mutation createAndAssociateUserOrganization($orgName: String!) {
					createAndAssociateUserOrganization(orgName: $orgName) {
						status
						orgId
					}
				}
			`,
			variables: {
				orgName,
			},
		});
		return Promise.resolve(res.data.createAndAssociateUserOrganization);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addImageToUserGallery(
	visualHash: string,
	remove?: boolean,
	localClient: ApolloClient<NormalizedCacheObject> = IframeClient
): Promise<boolean> {
	try {
		const res = await localClient.query<addImageToUserGalleryInterface, addImageToUserGalleryVariables>({
			query: gql`
				mutation addImageToUserGallery($visualHash: String!, $remove: Boolean) {
					addImageToUserGallery(visualHash: $visualHash, remove: $remove) {
						ok
					}
				}
			`,
			variables: {
				visualHash,
				remove,
			},
		});
		return Promise.resolve(res.data.addImageToUserGallery?.ok ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function createOrganization(
	orgName: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<createOrganization_createOrganization | null> {
	try {
		const res = await localClient.query<createOrganizationResponse, createOrganizationVariables>({
			query: gql`
				mutation createOrganization($orgName: String!) {
					createOrganization(orgName: $orgName) {
						status
						orgId
					}
				}
			`,
			variables: {
				orgName,
			},
		});
		return Promise.resolve(res.data.createOrganization);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addRecordToDownloads(
	value: string,
	modified: boolean,
	actionType?: string,
	downloadMethod?: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<any> {
	try {
		const res = await localClient.query<addRecordToDownloadsRes, addRecordToDownloadsVariables>({
			query: gql`
				mutation addRecordToDownloads(
					$value: String!
					$modified: Boolean!
					$actionType: String
					$downloadMethod: String
				) {
					addRecordToDownloads(
						value: $value
						modified: $modified
						actionType: $actionType
						downloadMethod: $downloadMethod
					) {
						ok
					}
				}
			`,
			variables: {
				value,
				modified,
				actionType,
				downloadMethod,
			},
		});
		return Promise.resolve(res.data.addRecordToDownloads?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function sendRequestFeatureEmail(
	feature: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<any> {
	try {
		const res = await localClient.query<sendRequestFeatureEmailRes, sendRequestFeatureEmailVariables>({
			query: gql`
				mutation sendRequestFeatureEmail($feature: String!) {
					sendRequestFeatureEmail(feature: $feature) {
						ok
					}
				}
			`,
			variables: {
				feature,
			},
		});
		return Promise.resolve(res.data.sendRequestFeatureEmail?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export type SendEmailInput = {
	subject: string;
	body: string;
	toList: string[];
	sender?: string;
	bccList?: string[];
	attachment?: string | null;
	filename?: string;
	isHtml?: boolean;
};

export async function sendEmail(
	emailInput: SendEmailInput,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<any> {
	try {
		const res = await localClient.query<sendEmailRes, sendEmailVariables>({
			query: gql`
				mutation sendEmail($input: EmailInput!) {
					sendEmail(input: $input) {
						success
					}
				}
			`,
			variables: { input: emailInput },
		});
		return Promise.resolve(res.data.sendEmail?.success);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function parseXmpFileContent(
	fileContent: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<parseXmpFileContent_parseXmpFileContent_mood | null | undefined> {
	try {
		const res = await localClient.query<parseXmpFileContentResp, parseXmpFileContentVariables>({
			query: gql`
				mutation parseXmpFileContent($fileContent: String!) {
					parseXmpFileContent(fileContent: $fileContent) {
						mood {
							moodParameters {
								light {
									brightness
									contrast
								}
								color {
									vibrance
									saturation
								}
								colorMixer {
									color
									hue
									saturation
									luminance
								}
							}
						}
					}
				}
			`,
			variables: {
				fileContent,
			},
		});
		return Promise.resolve(res.data.parseXmpFileContent?.mood);
	} catch (error) {
		return Promise.reject(error);
	}
}

export const GENERATE_TEXT_TO_IMAGE = gql`
	mutation generateTextToImage($selectedModel: String!, $prompt: String!, $numResults: Int!, $seed: String) {
		generateTextToImage(selectedModel: $selectedModel, prompt: $prompt, numResults: $numResults, seed: $seed) {
			images {
				url
				seed
			}
		}
	}
`;
export const ADD_SEMANTICS = gql`
	mutation AddSemantics(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
	) {
		addSemantics(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
		) {
			newImage {
				url
			}
			layersUrl
		}
	}
`;

export const ADD_OBJECT_TYPE_SEMANTICS = gql`
	mutation ChangeObjectTypeSemantics(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
		$objectType: String
	) {
		objectTypeSemantics(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
			objectType: $objectType
		) {
			newImage {
				url
			}
			layersUrl
		}
	}
`;

export const BILLING_REQUESTS_FOR_CREDITS_TOP_5 = gql`
	mutation BillingRequestsForCreditsTop5(
		$clientTokenList: [String]!
		$dbName: DBName!
		$initialTimestamp: Float!
		$finalTimestamp: Float!
	) {
		billingRequestsForCreditsTop5(
			clientTokenList: $clientTokenList
			dbName: $dbName
			initialTimestamp: $initialTimestamp
			finalTimestamp: $finalTimestamp
		) {
			data {
				requestDate
				top5Requests {
					requestAction
					creditsConsumed
				}
			}
		}
	}
`;

export const BILLING_REQUESTS_FOR_CREDITS_BREAKDOWN = gql`
	mutation BillingRequestsForCreditsBreakdown(
		$clientTokenList: [String]!
		$dbName: DBName!
		$initialTimestamp: Float!
		$finalTimestamp: Float!
	) {
		billingRequestsForCreditsBreakdown(
			clientTokenList: $clientTokenList
			dbName: $dbName
			initialTimestamp: $initialTimestamp
			finalTimestamp: $finalTimestamp
		) {
			data {
				requestAction
				creditsConsumed
			}
		}
	}
`;

export const BILLING_REQUESTS_FOR_CREDITS = gql`
	mutation BillingRequestsForCredits(
		$clientTokenList: [String]!
		$dbName: DBName!
		$initialTimestamp: Float!
		$finalTimestamp: Float!
	) {
		billingRequestsForCredits(
			clientTokenList: $clientTokenList
			dbName: $dbName
			initialTimestamp: $initialTimestamp
			finalTimestamp: $finalTimestamp
		) {
			data {
				date
				creditsConsumed
			}
		}
	}
`;

export const BILLING_REQUESTS_PER_ACTION = gql`
	mutation BillingRequestsPerAction(
		$clientTokenList: [String]!
		$action: String!
		$dbName: DBName!
		$initialTimestamp: Float!
		$finalTimestamp: Float!
	) {
		billingRequestsPerAction(
			clientTokenList: $clientTokenList
			action: $action
			dbName: $dbName
			initialTimestamp: $initialTimestamp
			finalTimestamp: $finalTimestamp
		) {
			numberOfRequests
		}
	}
`;

export const CHANGE_ETHNICITY = gql`
	mutation ChangeEthnicity(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
	) {
		changeEthnicity(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
		) {
			newImage {
				url
			}
			layersUrl
		}
	}
`;

export const sendMetricsToGrafana = async (metricList: WebOperations[]) => {
	try {
		await client.query<sendMetricsToGrafana_sendMetrics, sendMetricsToGrafanaVariables>({
			query: gql`
				mutation sendMetricsToGrafana($metricList: [WebOperations]) {
					sendMetrics(metricList: $metricList) {
						tracked
					}
				}
			`,
			variables: {
				metricList: metricList,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateImageScoreByvHash = async (vHash: string, score: number) => {
	try {
		await client.query<updateImageScore, updateImageScoreVariables>({
			query: gql`
				mutation updateImageScore($vHash: String!, $score: Int!) {
					updateImageScore(vHash: $vHash, score: $score) {
						ok
					}
				}
			`,
			variables: {
				vHash: vHash,
				score: score,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
};

export async function uploadImageTosS3(img: string): Promise<String> {
	try {
		const res = await client.query<uploadImageToS3, uploadImageToS3Variables>({
			query: gql`
				mutation uploadImageToS3($image: String!) {
					uploadImageToS3(image: $image) {
						imageUrl
					}
				}
			`,
			variables: {
				image: img,
			},
		});

		return Promise.resolve(res.data?.uploadImageToS3?.imageUrl || "");
	} catch (error) {
		return Promise.reject(error);
	}
}

export const APPEND_ASK_ALAN_RECORD = gql`
	mutation AppendAskAlanRecord($list: [String]) {
		appendAskAlanRecord(list: $list) {
			ok
		}
	}
`;

export async function sendProxyRequest(
	proxyRequest: ProxyRequest,
	feature: string,
	context?: DefaultContext
): Promise<sendProxyRequest_sendProxyRequest_proxyResponse> {
	try {
		const res = await client.query<sendProxyRequestInterface, sendProxyRequestVariables>({
			query: gql`
				mutation sendProxyRequest($proxyRequest: ProxyRequest!, $feature: String!) {
					sendProxyRequest(proxyRequest: $proxyRequest, feature: $feature) {
						proxyResponse {
							id
							data
						}
					}
				}
			`,
			variables: {
				proxyRequest: proxyRequest,
				feature: feature,
			},
			context,
		});
		return Promise.resolve(
			(res.data.sendProxyRequest?.proxyResponse ?? {}) as sendProxyRequest_sendProxyRequest_proxyResponse
		);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function deleteUserById(uid: string): Promise<boolean> {
	try {
		const res = await client.query<deleteUser, deleteUserVariables>({
			query: gql`
				mutation deleteUser($uid: String) {
					deleteUser(uid: $uid) {
						ok
					}
				}
			`,
			variables: {
				uid: uid,
			},
		});

		if (res.data.deleteUser?.ok === undefined || res.data.deleteUser?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.deleteUser?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function deleteGalleryAssetByOrganization(orgid: string, visualHash: string): Promise<boolean> {
	try {
		const res = await client.query<
			deleteOrganizationAssetGalleryInterface,
			deleteOrganizationAssetGalleryVariables
		>({
			query: gql`
				mutation deleteOrganizationAssetGallery($orgid: String, $visualHash: String!) {
					deleteOrganizationAssetGallery(orgid: $orgid, visualHash: $visualHash) {
						ok
					}
				}
			`,
			variables: {
				orgid: orgid,
				visualHash: visualHash,
			},
		});

		if (
			res.data.deleteOrganizationAssetGallery?.ok === undefined ||
			res.data.deleteOrganizationAssetGallery?.ok === null
		) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.deleteOrganizationAssetGallery?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function enableUserById(uid: string): Promise<boolean> {
	try {
		const res = await client.query<enableUser, enableUserVariables>({
			query: gql`
				mutation enableUser($uid: String!) {
					enableUser(uid: $uid) {
						ok
					}
				}
			`,
			variables: {
				uid: uid,
			},
		});

		if (res.data.enableUser?.ok === undefined || res.data.enableUser?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.enableUser?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function disableUserById(uid: string): Promise<boolean> {
	try {
		const res = await client.query<disableUser, disableUserVariables>({
			query: gql`
				mutation disableUser($uid: String!) {
					disableUser(uid: $uid) {
						ok
					}
				}
			`,
			variables: {
				uid: uid,
			},
		});

		if (res.data.disableUser?.ok === undefined || res.data.disableUser?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.disableUser?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function setPostRegistrationConfigs(): Promise<boolean> {
	try {
		const res = await client.query<setPostRegistrationConfigsInterface, any>({
			query: gql`
				mutation setPostRegistrationConfigs {
					setPostRegistrationConfigs {
						ok
					}
				}
			`,
			variables: {},
		});

		if (res.data.setPostRegistrationConfigs?.ok === undefined || res.data.setPostRegistrationConfigs?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.setPostRegistrationConfigs?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function updateUserSubscription(
	newWebSubscriptionType: string,
	newWebSubscriptionPeriod: string
): Promise<boolean> {
	try {
		const res = await client.query<updateUserSubscriptionInterface, updateUserSubscriptionVariables>({
			query: gql`
				mutation updateUserSubscription($newWebSubscriptionType: String!, $newWebSubscriptionPeriod: String!) {
					updateUserSubscription(
						newWebSubscriptionType: $newWebSubscriptionType
						newWebSubscriptionPeriod: $newWebSubscriptionPeriod
					) {
						ok
					}
				}
			`,
			variables: {
				newWebSubscriptionType,
				newWebSubscriptionPeriod,
			},
		});

		if (res.data.updateUserSubscription?.ok === undefined || res.data.updateUserSubscription?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.updateUserSubscription?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function cancelUserSubscription(): Promise<boolean> {
	try {
		const res = await client.query<cancelUserSubscriptionInterface, any>({
			query: gql`
				mutation cancelUserSubscription {
					cancelUserSubscription {
						ok
					}
				}
			`,
		});

		if (res.data.cancelUserSubscription?.ok === undefined || res.data.cancelUserSubscription?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.cancelUserSubscription?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function cancelApiSubscription(orgUid: string): Promise<boolean> {
	try {
		const res = await client.query<cancelApiSubscriptionInterface, cancelApiSubscriptionVariables>({
			query: gql`
				mutation cancelApiSubscription($orgUid: String!) {
					cancelApiSubscription(orgUid: $orgUid) {
						ok
					}
				}
			`,
			variables: {
				orgUid,
			},
		});

		if (res.data.cancelApiSubscription?.ok === undefined || res.data.cancelApiSubscription?.ok === null) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.cancelApiSubscription?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function consumeUserCredits(creditType: SubscriptionCreditsTypes, count?: number): Promise<boolean> {
	return Promise.resolve(true);
	// try {
	// 	const res = await client.query<consumeUserCreditsInterface, consumeUserCreditsVariables>({
	// 		query: gql`
	// 			mutation consumeUserCredits($creditType: String!, $count: Int) {
	// 				consumeUserCredits(creditType: $creditType, count: $count) {
	// 					ok
	// 				}
	// 			}
	// 		`,
	// 		variables: {
	// 			creditType,
	// 			count,
	// 		},
	// 	});
	//
	// 	if (res.data.consumeUserCredits?.ok === undefined || res.data.consumeUserCredits?.ok === null) {
	// 		return Promise.reject();
	// 	}
	//
	// 	return Promise.resolve(res.data.consumeUserCredits?.ok);
	// } catch (error) {
	// 	return Promise.reject(error);
	// }
}

export async function updateUserSubscriptionManually(
	uid: string,
	newWebSubscriptionType: string,
	newWebSubscriptionPeriod: string
): Promise<boolean> {
	try {
		const res = await client.query<any, any>({
			query: gql`
				mutation updateUserSubscriptionManually(
					$uid: String!
					$newWebSubscriptionType: String!
					$newWebSubscriptionPeriod: String
				) {
					updateUserSubscriptionManually(
						uid: $uid
						newWebSubscriptionType: $newWebSubscriptionType
						newWebSubscriptionPeriod: $newWebSubscriptionPeriod
					) {
						ok
					}
				}
			`,
			variables: {
				uid,
				newWebSubscriptionType,
				newWebSubscriptionPeriod,
			},
		});

		if (
			res.data.updateUserSubscriptionManually?.ok === undefined ||
			res.data.updateUserSubscriptionManually?.ok === null
		) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.updateUserSubscriptionManually?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function updateOrgUsersSubscriptionManually(
	orgId: string,
	newWebSubscriptionType: string
): Promise<boolean> {
	try {
		const res = await client.query<any, any>({
			query: gql`
				mutation updateOrgUsersSubscriptionManually($orgId: String!, $newWebSubscriptionType: String!) {
					updateOrgUsersSubscriptionManually(orgId: $orgId, newWebSubscriptionType: $newWebSubscriptionType) {
						ok
					}
				}
			`,
			variables: {
				orgId,
				newWebSubscriptionType,
			},
		});

		if (
			res.data.updateOrgUsersSubscriptionManually?.ok === undefined ||
			res.data.updateOrgUsersSubscriptionManually?.ok === null
		) {
			return Promise.reject();
		}

		return Promise.resolve(res.data.updateOrgUsersSubscriptionManually?.ok);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function translateText(
	sourceLangCode: string,
	targetLangCode: string,
	text: string
): Promise<string | null> {
	try {
		const res = await client.query<translateTextInterface, translateTextVariables>({
			query: gql`
				mutation translateText($sourceLangCode: String!, $targetLangCode: String!, $text: String!) {
					translateText(sourceLangCode: $sourceLangCode, targetLangCode: $targetLangCode, text: $text) {
						ok
						translatedText
					}
				}
			`,
			variables: {
				sourceLangCode,
				targetLangCode,
				text,
			},
		});
		if (res.data.translateText?.ok) {
			return Promise.resolve(res.data.translateText?.translatedText);
		} else {
			return Promise.reject(null);
		}
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function InsertBrandPresetToDB(
	name: string,
	description: string,
	orgId: string,
	header1Font?: string,
	header1Size?: string,
	header1Color?: string,
	header2Font?: string,
	header2Size?: string,
	header2Color?: string,
	logo?: string,
	colorPalette?: string
): Promise<InsertBrandPresetToDb_InsertBrandPresetToDb> {
	try {
		const res = await client.query<InsertBrandPresetToDbInterface, InsertBrandPresetToDbVariables>({
			query: gql`
				mutation InsertBrandPresetToDb(
					$name: String!
					$description: String
					$orgId: String!
					$header1Font: String
					$header1Size: String
					$header1Color: String
					$header2Font: String
					$header2Size: String
					$header2Color: String
					$logo: String
					$colorPalette: String
				) {
					InsertBrandPresetToDb(
						name: $name
						description: $description
						orgId: $orgId
						header1Font: $header1Font
						header1Size: $header1Size
						header1Color: $header1Color
						header2Font: $header2Font
						header2Size: $header2Size
						header2Color: $header2Color
						logo: $logo
						colorPalette: $colorPalette
					) {
						ok
						brandPresetId
					}
				}
			`,
			variables: {
				name,
				description,
				orgId,
				header1Font,
				header1Size,
				header1Color,
				header2Font,
				header2Size,
				header2Color,
				logo,
				colorPalette,
			},
		});
		if (res.data.InsertBrandPresetToDb?.ok) {
			return Promise.resolve(res.data.InsertBrandPresetToDb);
		} else {
			return Promise.reject(null);
		}
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function generateCamp(campaignRequest: Camp): Promise<any> {
	try {
		const res = await client.query<any, any>({
			query: gql`
				mutation generateCamp($campaignRequest: Camp!) {
					generateCamp(campaignRequest: $campaignRequest) {
						data {
							expansionSeed
							jpegFile
							pngFile
							layers
							layoutId
							psdFile
							channelId
							canvasHeight
							bgSeed
							placementId
							canvasWidth
							placementName
							templateId
						}
						errorMessage
					}
				}
			`,
			variables: {
				campaignRequest,
			},
		});

		const responseData = res.data.generateCamp?.data;
		const errorMessage = res.data.generateCamp?.errorMessage;

		if (errorMessage) {
			return Promise.reject(`Error: ${errorMessage}`);
		}

		if (responseData === undefined || responseData === null) {
			return Promise.reject("Error: Data not available.");
		}

		return Promise.resolve(responseData);
	} catch (error) {
		return Promise.reject(error);
	}
}
