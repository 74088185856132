import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import TitleTexts from "../TitleTexts/TitleTexts";
import FineTuningSliders from "./Sliders/Sliders";
import ImgViewer, { mainImgInfo } from "../BrandPage/ImgViewer/ImgViewer";
import { makeStyles } from "@material-ui/styles";
import { BrandContext } from "../BrandPage/BrandPage";
import { handleMoodApplyCall } from "../BrandPage/ImgViewer/BrandImg/BrandImg";
import Context from "../../../../Context/Context";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import { GENERAL_ORG_ID } from "../../../../Constants";

export default function FineTuning() {
	const classes = useStyles();
	const { brandState, generalState } = useContext(BrandContext);
	const [selectedMoodImgSrc, setSelectedMoodImgSrc] = useState<any>(mainImgInfo.src);
	const [selectedImage, setSelectedImage] = useState((brandState as any)?.selectedResultImage ?? "center");

	const [loading, setLoading] = useState(false);
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const instance = BriaAPI.getInstance(mainImgInfo.vhash, context.iframeConfig);
	const _window = window as any;

	function getSelectedMoodSliders() {
		return selectedImage === "left"
			? (generalState as any)?.moodSlidersSuggestion1
			: selectedImage === "right"
			? (generalState as any)?.moodSlidersSuggestion2
			: (brandState as any)?.moodSliders;
	}

	useEffect(() => {
		_window.atFineTuning = true;

		return () => {
			_window.atFineTuning = false;
		};
	}, []);
	useEffect(() => {
		// generate selected mood image
		if (getSelectedMoodSliders()) {
			setLoading(true);
			handleMoodApplyCall(context, instance, getSelectedMoodSliders(), organizationUid)
				.then((img) => setSelectedMoodImgSrc(img))
				.finally(() => setLoading(false));
		}
	}, [
		(brandState as any)?.moodSliders,
		(generalState as any)?.moodSlidersSuggestion1,
		(generalState as any)?.moodSlidersSuggestion2,
	]);

	return (
		<Box className={classes.fineTuneRoot}>
			<TitleTexts text="Let’s fine-tune" passedClassName={classes.mainTitle} />
			<Box display="flex">
				<Box flex="40%" maxWidth="40%">
					<FineTuningSliders />
				</Box>
				<Box width="31vw" margin="auto">
					<ImgViewer imageSrc={selectedMoodImgSrc} parentLoading={loading} enableCompareMode={true} />
				</Box>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	brandName: {
		color: "#A4A4A4",
		padding: "5vh 0 5vh",
	},
	mainTitle: {
		marginTop: "2vh",
	},
	fineTuneRoot: {
		maxWidth: "calc(100% - 20%)",
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
		marginBottom: "-3.5rem",
	},
}));
