import { MessageProps } from "./marketing-messaging";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Context from "../../Context/Context";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";

export default function MarketingMessagingTemplate({
	image,
	paragraph,
	smallParagraph,
	smallTitle,
	buttonText,
}: MessageProps) {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();

	return (
		<>
			<Box className={classes.root} alignItems="center">
				<Box height="264px">
					<video
						width="100%"
						height="auto"
						autoPlay={true}
						loop={true}
						preload={"none"}
						muted={true}
						playsInline={true}
					>
						<source src={image} type="video/mp4"></source>
						Your browser does not support the video tag.
					</video>
				</Box>
				<Typography
					className={classes.smallTitle}
					variant="body2"
					color="textPrimary"
				>
					{smallTitle}{" "}
				</Typography>

				<Box width="100%" mr={1}>
					<Typography
						className={classes.paragraph}
						variant="body1"
						color="textPrimary"
					>
						{paragraph}{" "}
					</Typography>
				</Box>
				<Box minWidth={35}>
					<Typography
						className={classes.smallParagraph}
						variant="body2"
						color="textPrimary"
					>
						{smallParagraph}{" "}
					</Typography>
				</Box>

				<BriaButton
					buttonType={BUTTONTYPES.SECONDARY}
					className={
						buttonText == "comingSoon" ? classes.noCursor : ""
					}
					onClick={() => {
						if (buttonText !== "comingSoon") {
							context.setDisplayDemoApplicationDialogState(true);
							Analytics.logEvent(
								ANALYTICS_EVENTS.DEMO_APPLICATION_DIALOG_OPENED
							);
						}
					}}
				>
					{" "}
					{t(buttonText)}
				</BriaButton>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: theme.spacing(4.4),
		paddingTop: theme.spacing(1.9),
	},
	smallTitle: {
		padding: theme.spacing(3.75, 0),
		fontWeight: 600,
	},
	paragraph: {
		paddingBottom: theme.spacing(7.5),
		fontSize: 18,
	},
	smallParagraph: {
		paddingBottom: theme.spacing(3.5),
	},
	noCursor: {
		cursor: "inherit",
	},
}));
