export const convertSnakeCaseToText = (text: string): string => {
	return text.replaceAll("_", " ").trim();
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const camelCaseToText = (str: string) => str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

export const capitalizeFirstLetter = (string: string) => {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

export const renameDuplicateItems = (arr: string[]) => {
	const count: { [key: string]: number } = {};
	arr.forEach(function (x, i) {
		if (arr.indexOf(x) !== i) {
			const c = x in count ? (count[x] = count[x] + 1) : (count[x] = 1);
			let j = c + 1;
			let k = `${x} ${j}`;

			while (arr.indexOf(k) !== -1) k = `${x} ${++j}`;
			arr[i] = k;
		}
	});
	return arr;
};

export const cleanObj = (obj: any = {}): any => {
	let cleanedObj = {};
	if (Array.isArray(obj)) {
		const arrAfterClean = (obj as []).map((elem: any) => cleanObj(elem));
		return arrAfterClean;
	}
	Object.entries(obj).forEach((entry) => {
		const [key, value] = entry;
		const isValidValue = typeof value === "string" ? false : !!value;
		const miniObj = isValidValue ? { [key]: value } : {};
		cleanedObj = { ...cleanedObj, ...miniObj };
	});
	return cleanedObj;
};

/* 
  conver objects to queryStrings (and reomving the empty props)
  input : {foo: "Value_1", bar: "Value_2", empty:""}
  output : ?foo=Value_1&bar=Value_2
*/
export const fromQueryStringsToUrl = (obj = {}) => {
	if (!obj) return "";
	return [
		"?",
		Object.entries(obj)
			.reduce((prev, [key, value]) => `${prev}&${key}=${value}`, "")
			.substring(1),
	].join("");
};
