import React, { useCallback, useEffect, useRef, useState } from "react";
import { getImageMeta } from "../../Components/Tools/utilities";

export const useCanvasActions = (objectsTabCanvasRef: any, context: any, brushOverlayCanvasRef: any) => {
	const [dataUrl, setDataUrl] = useState("#");
	const tempCanvasRef = useRef<any>(null);

	useEffect(() => {
		tempCanvasRef.current = document.createElement("canvas");
	}, []);

	const handleCanvasClear = useCallback(() => {
		if (!brushOverlayCanvasRef || !brushOverlayCanvasRef.current) {
			return;
		}
		brushOverlayCanvasRef.current.clearRect(0, 0, brushOverlayCanvasRef.current.width, brushOverlayCanvasRef.current.height);
	}, []);

	const createBase64ImageMask = useCallback((base64Image: string) => {
		return new Promise<string>((resolve) => {
			const tempCanvas: any = tempCanvasRef.current;
			if (tempCanvas) {
				const ctx = tempCanvas.getContext("2d");

				getImageMeta(base64Image).then((img: any) => {
					tempCanvas.width = img.width;
					tempCanvas.height = img.height;
					ctx.drawImage(img, 0, 0);
					const imageData = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
					const pixels = imageData.data;
					const originalImgPixels = brushOverlayCanvasRef?.current
						?.getContext("2d")
						?.getImageData(0, 0, tempCanvas.width, tempCanvas.height)?.data;
					for (let i = 0; i < originalImgPixels.length; i += 4) {
						const r = originalImgPixels[i];
						const g = originalImgPixels[i + 1];
						const b = originalImgPixels[i + 2];
						if (r > 0 || g > 0 || b > 0) {
							// Set pixel to white
							pixels[i] = 255;
							pixels[i + 1] = 255;
							pixels[i + 2] = 255;
						} else {
							// Set pixel to black
							pixels[i] = 0;
							pixels[i + 1] = 0;
							pixels[i + 2] = 0;
						}
					}

					ctx.putImageData(imageData, 0, 0);

					// Convert canvas to base64
					const processedBase64 = tempCanvas.toDataURL();
					resolve(processedBase64);
				});
			}
		});
	}, []);

	const handleCanvasDownload = useCallback(async () => {
		if (!objectsTabCanvasRef || !objectsTabCanvasRef.current) return;

		let base64Image = objectsTabCanvasRef.current.toDataURL("image/png");
		base64Image = await createBase64ImageMask(base64Image);

		return base64Image;
	}, [objectsTabCanvasRef, createBase64ImageMask]);

	return { handleCanvasClear, handleCanvasDownload, dataUrl };
};
