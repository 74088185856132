import React from "react";
import ThumbnailSlider from "../../../../UI/ThumbnailSlider";
import { getSliderValue, moodSliders } from "../../data/sliders";
import { Box } from "@material-ui/core";
import { MoodContext } from "../../Mood";
import { useContext } from "react";

export default function SettingsSliders() {
	const { handleSliderStateChange, slidersGeneralState } =
		useContext(MoodContext);

	return (
		<>
			{moodSliders.map((slider) => (
				<Box mt={4} width="95%">
					<ThumbnailSlider
						noMarks={true}
						renderTopRight={() => <></>}
						handleValueChange={(value) =>
							handleSliderStateChange(slider.name, value, slider.mainKey)
						}
						{...slider}
						value={getSliderValue(slider.name, slidersGeneralState,slider.mainKey)}
					/>
				</Box>
			))}
		</>
	);
}
