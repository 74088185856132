import { Box, Theme, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import arrow from "../../assets/svgs/chevron-down-download.svg";
import download from "../../assets/svgs/download.svg";
import sliderIcon from "../../assets/sliderIcon.svg";
import galleryViewIcon from "../../assets/galleryViewIcon.svg";

interface StyleProps {
	showSlider: boolean;
}

const CampaignPreviewButtons = ({
	sliderImages,
	setShowMenu,
	showMenu,
	currentImage,
	downloadSingleCampaign,
	IsAllImagesReady,
	downloadCampaigns,
	downloadPSD,
	downloadAllPSDs,
	setShowSlider,
	showSlider,
}: any) => {
	const { t } = useTranslation("translation", { keyPrefix: "Slider" });
	const classes = useStyles({ showSlider: showSlider });
	const createBrandOption = {
		id: "createBrandOption",
		title: t("createBrand"),
	};
	return (
		<Box className={classes.downloadMainContainer}>
			<Box className={classes.campaignPreviewIconsContainer}>
				<img
					className={`${!showSlider && classes.campaignPreviewIcon}`}
					onClick={() => setShowSlider(false)}
					src={galleryViewIcon}
					alt="galleryViewIcon"
				/>
				<img
					className={`${showSlider && classes.campaignPreviewIcon}`}
					onClick={() => setShowSlider(true)}
					src={sliderIcon}
					alt="sliderIcon"
				/>
			</Box>
			<Box className={`${classes.headerImageValue} downloadMenu`}>
				<Box>
					<BriaButton
						className={classes.downloadButton}
						disabled={sliderImages.length === 0}
						buttonType={BUTTONTYPES.PRIMARYPURPLE}
						onClick={() => {
							setShowMenu(!showMenu);
						}}
					>
						{t("Download")}

						<img src={arrow} alt="" />
					</BriaButton>
					{showMenu && (
						<Box className={classes.buttonsContainer}>
							<BriaButton
								style={{ color: !currentImage.loaded ? "#000" : undefined }}
								disabled={!currentImage.loaded}
								className={classes.buttonsStyle}
								buttonType={BUTTONTYPES.PRIMARYPURPLE}
								onClick={downloadSingleCampaign}
							>
								<img src={download} alt="" />
								<Typography> {t("downloadCurrentSize")} </Typography>
							</BriaButton>
							<BriaButton
								style={{ color: !IsAllImagesReady ? "#000" : undefined }}
								className={classes.buttonsStyle}
								buttonType={BUTTONTYPES.PRIMARYPURPLE}
								onClick={downloadCampaigns}
								disabled={!IsAllImagesReady}
							>
								<img src={download} alt="" />
								<Typography>{t("downloadAllSizes")} </Typography>
							</BriaButton>
							<BriaButton
								style={{ color: !currentImage.loaded ? "#000" : undefined }}
								disabled={!currentImage.loaded}
								className={classes.buttonsStyle}
								buttonType={BUTTONTYPES.PRIMARYPURPLE}
								onClick={downloadPSD}
							>
								<img src={download} alt="" />
								<Typography> {t("downloadAsPSD")} </Typography>
							</BriaButton>

							<BriaButton
								style={{ color: !IsAllImagesReady ? "#000" : undefined }}
								className={classes.buttonsStyle}
								buttonType={BUTTONTYPES.PRIMARYPURPLE}
								onClick={downloadAllPSDs}
								disabled={!IsAllImagesReady}
							>
								<img src={download} alt="" />
								<Typography> {t("downloadAllSizesAsPSD")} </Typography>
							</BriaButton>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	headerImageValue: {
		display: "flex",
		position: "relative",
		justifyContent: "end",
		height: "fit-content",
	},
	buttonsContainer: {
		zIndex: 1,
		position: "absolute",
		minWidth: "310px",
		right: 0,
		marginTop: "8px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "8px",
		border: "1px solid #E7E7E7",
		padding: "13px 13px 21px 13px",
		alignItems: "baseline",
		backgroundColor: "white",
		gap: "13px",
		boxShadow:
			"0px 0px 0px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 8px 5px 0px rgba(0, 0, 0, 0.04), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 23px 6px 0px rgba(0, 0, 0, 0.00)",
	},
	buttonsStyle: {
		backgroundColor: "white",
		borderRadius: "0px",
		color: "#000",
		fontWeight: 400,
		fontSize: "16px",
		width: "100%",
		"& span": {
			display: "flex",
			gap: "10px",
			alignItems: "center",
		},
		justifyContent: "start",
		"&:hover, &:active, &:focus ": {
			backgroundColor: `${theme.palette.primary.dark}0D`, //"#5300C90D !important",
			color: "#1A0638",
			background: "none",
		},
	},
	downloadMainContainer: (props) => ({
		display: "flex",
		justifyContent: "flex-end",
		position: props.showSlider ? "absolute" : "static",
		right: props.showSlider ? "32px" : "",
		"@media (max-width: 1150px)": {
			position: "static",
			right: "unset",
		},
	}),
	campaignPreviewIcon: {
		borderRadius: "8px",
		border: "2px solid  rgba(83, 0, 201, 0.05)",
		background: "rgba(83, 0, 201, 0.05)",
		boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.02) inset",
	},
	campaignPreviewIconsContainer: {
		marginRight: "20px",
		height: "fit-content",
		display: "flex",
		gap: "8px",
	},
	downloadButton: {
		width: "auto",
		height: "43px",
	},
}));
export default CampaignPreviewButtons;
