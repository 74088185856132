import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useLoginService } from "../../../hooks/useLoginService";
import BriaInput from "../../../Components/UI/BriaInput";
import ErrorIcon from "../../../assets/icons/ErrorIcon";
import { ReactComponent as Logo } from "../../../assets/bria_logo.svg";
import { Container } from "@material-ui/core";
import ResetPasswordConfirmationForm from "./ResetPasswordConfirmationForm";
import clsx from "clsx";

interface IProps {}

interface FormValues {
	email: string;
}

const ForgotPasswordForm: React.FC<IProps> = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { resetPassword, errorMessage } = useLoginService();
	const [sendResetEmail, setSendResetEmail] = React.useState<{
		isEmailSent: boolean;
		userEmail: string;
	}>({ isEmailSent: false, userEmail: "" });

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const initialValues = {
		email: "",
	};

	const handleSubmit = async (values: any) => {
		resetPassword(values.email)
			.then(() => {
				setSendResetEmail({
					isEmailSent: true,
					userEmail: values.email,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	if (sendResetEmail.isEmailSent && !errorMessage) {
		return <ResetPasswordConfirmationForm email={sendResetEmail.userEmail} resendEmail={handleSubmit} />;
	}

	return (
		<Container component="main" maxWidth={false}>
			<Box className={classes.credentials_content}>
				<Box position="relative">
					<Logo className={classes.logo} />
				</Box>
				<Box className={classes.content} display="flex" flexDirection="column" alignItems="center" px={1.25}>
					<Typography className={classes.typo1}>{t("forgotPassword")}</Typography>
					<Typography className={classes.typo2}>
						{t("pleaseEnterYourEmailToReceiveAPasswordResetLink")}
					</Typography>

					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validate={(values) => {
							const errors: FormikErrors<FormValues> = {};
							if (!values.email) {
								errors.email = "Required";
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = "Invalid email address";
							}
							return errors;
						}}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isValid }) => (
							<Form className={classes.form} noValidate>
								<Box display="flex" flexDirection="column" alignItems="center">
									<BriaInput
										id="email"
										label="Email"
										onChange={handleChange}
										error={(Boolean(errors.email) && touched.email) as boolean}
										helperText={errors.email}
										className={classes.textField}
										buttonSource="popup"
									/>

									{errorMessage && (
										<Typography className={classes.error} color="secondary">
											<ErrorIcon className={classes.errorIcon} />
											{errorMessage}
										</Typography>
									)}

									<Button
										type="submit"
										variant="contained"
										color="secondary"
										className={clsx(classes.button, classes.ctaButton)}
									>
										{t("sendLink")}
									</Button>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
				<Box />
			</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme) => {
	return {
		content: {
			background: "white",
		},
		form: {
			width: 440,
			height: 200,
			marginTop: 90,
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
		},
		button: {
			borderRadius: 8,
			width: 440,
			height: 56,
			marginTop: 16,
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
			textTransform: "capitalize",
		},
		ctaButton: {
			backgroundColor: theme.palette.primary.dark,
			"&:hover, &:active, &:focus, &:visited": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
		icon: {
			fontSize: 58,
		},
		signup: {
			color: theme.palette.primary.light,
		},
		typo1: {
			fontSize: 34,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			marginBottom: 125,
		},
		typo2: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
		},
		textField: {
			marginTop: 15,
			marginBottom: 15,
		},
		error: {
			fontSize: 14,
			fontWeight: theme.typography.fontWeightBold,
			marginLeft: 12,
		},
		errorIcon: {
			fontSize: 16,
			marginRight: 4,
			verticalAlign: "middle",
		},
		logo: {
			width: 141.09,
			height: 90.97,
		},
		credentials_content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-evenly",
			// width: 600,
			// minWidth: 600,
			maxWidth: 600,
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			background: "white",
			padding: "20px 60px",
			boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				padding: 0,
			},
		},
	};
});

export default ForgotPasswordForm;
