import React from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ShowPasswordIcon from "../../assets/icons/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/HidePasswordIcon";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordEye from "../../assets/icons/passwordEye";

const CustomizedInput = withStyles((theme: Theme) => ({
	root: {
		minWidth: (props) => (props.buttonSource === "platform" ? "unset" : 440),
		width: (props) => (props.buttonSource === "platform" || props.buttonSource === "popup" ? "100%" : "80%"),
		height: 56,

		"& input": {
			padding: "11px 19px",
			height: 34,
		},

		"& label": {
			color: theme.palette.text.primary,
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium,
			paddingTop: 9,
		},

		"& label.Mui-focused": {
			color: (props) =>
				props.buttonSource === "platform" || props.buttonSource === "popup"
					? theme.palette.primary.dark
					: theme.palette.primary.light,
		},
		"& label.MuiInputLabel-shrink": {
			paddingTop: 3,
		},

		"& .MuiOutlinedInput-root": {
			borderRadius: (props) => (props.buttonSource === "platform" || props.buttonSource === "popup" ? 8 : 20),

			"& fieldset": {
				borderColor: "#C4C4C4",
			},

			"&:hover fieldset": {
				borderColor: theme.palette.primary.dark,
			},

			"&:active fieldset": {
				borderColor: (props) =>
					props.buttonSource === "platform" || props.buttonSource === "popup"
						? theme.palette.primary.dark
						: theme.palette.primary.light,
				opacity: 0.5,
			},

			"&.Mui-focused fieldset": {
				borderColor: (props) =>
					props.buttonSource === "platform" || props.buttonSource === "popup"
						? theme.palette.primary.dark
						: theme.palette.primary.light,
				opacity: 1,
				borderWidth: 1,
			},
		},

		"& .MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error": {
			color: theme.palette.primary.light,
		},

		"& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: (props) =>
				props.buttonSource === "platform" || props.buttonSource === "popup"
					? theme.palette.primary.dark
					: theme.palette.primary.light,
		},
		[theme.breakpoints.down("xs")]: {
			minWidth: "unset",
			width: "100%",
		},
	},
}))(TextField);

const useStyles = makeStyles((theme) => ({
	icon: {
		cursor: "pointer",
	},
}));

interface IProps {
	type?: string;
	buttonSource?: string;
	optional?: boolean;
}

const BriaInput: React.FC<IProps & TextFieldProps> = ({ type, buttonSource, optional, ...rest }) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleClick = () => {
		setShowPassword(!showPassword);
	};

	return (
		<CustomizedInput
			variant="outlined"
			size="small"
			type={type === "password" ? (showPassword ? "text" : "password") : "text"}
			optional={optional}
			buttonSource={buttonSource}
			InputProps={{
				endAdornment:
					optional === true ? (
						<InputAdornment position="end">Optional</InputAdornment>
					) : (
						type === "password" && (
							<InputAdornment position="end">
								{buttonSource === "platform" || buttonSource === "popup" ? (
									<PasswordEye onClick={handleClick} className={classes.icon}></PasswordEye>
								) : showPassword ? (
									<ShowPasswordIcon onClick={handleClick} className={classes.icon} />
								) : (
									<HidePasswordIcon onClick={handleClick} className={classes.icon} />
								)}
							</InputAdornment>
						)
					),
			}}
			{...rest}
		/>
	);
};

export default BriaInput;
