import React from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "../Constants/LightTheme";
import { darkTheme } from "../Constants/DarkTheme";
import { create } from "jss";
import rtl from "jss-rtl";
import { createTheme, jssPreset, StylesProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IsIframe } from "../Helpers/iframe";

interface BriaThemeInterface {
	isDarkMode: boolean;
	handleThemeChange: () => void;
	context: any;
}

const defaultValues: BriaThemeInterface = {
	isDarkMode: false,
	handleThemeChange: () => {},
};

const BriaThemeContext = React.createContext<BriaThemeInterface>(defaultValues);

export const useThemeContext = () => {
	const { isDarkMode, handleThemeChange } = React.useContext(BriaThemeContext);
	if (isDarkMode === undefined || !handleThemeChange)
		throw new Error("useThemeContext should be used under BriaThemeContext");
	return { isDarkMode, handleThemeChange };
};

const BriaThemeProvider = ({
	children,
	forceLightMode = false,
	context,
}: React.PropsWithChildren<{ forceLightMode?: boolean; context: any }>) => {
	const { i18n } = useTranslation();
	const [isDarkMode, setIsDarkMode] = React.useState<boolean>(() => {
		const item = localStorage.getItem("isDarkMode");
		return item ? JSON.parse(item) : false;
	});
	const { isIframe } = IsIframe();

	const isLightTheme = forceLightMode || !isDarkMode;
	const themeType = isLightTheme ? lightTheme : darkTheme;
	themeType.direction = i18n.dir();
	const queryParams = new URLSearchParams(window ? window.location.search : {});

	const theme = React.useMemo(() => {
		let primaryColor;
		if (context.iframeId) {
			primaryColor = queryParams.get("color");
			if (
				isIframe() &&
				context?.iframeConfig &&
				context.iframeConfig.customStyle &&
				context.iframeConfig.customStyle.primaryColor
			) {
				primaryColor = context.iframeConfig.customStyle.primaryColor;
			}
		}
		return createTheme(
			themeType,
			primaryColor
				? {
						palette: {
							...(primaryColor
								? {
										primary: {
											dark: primaryColor,
											main: primaryColor,
										},
										cards: {
											main: primaryColor,
										},
								  }
								: {}),
						},
				  }
				: {}
		);
	}, [context?.iframeConfig?.customStyle]);

	document.body.classList.toggle("dark", !isLightTheme);
	document.body.classList.toggle("light", isLightTheme);

	const handleThemeChange = () => {
		localStorage.setItem("isDarkMode", JSON.stringify(!isDarkMode));
		setIsDarkMode(!isDarkMode);
	};
	const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

	return (
		<BriaThemeContext.Provider value={{ isDarkMode, handleThemeChange }}>
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<CssBaseline />
					{children}
				</StylesProvider>
			</ThemeProvider>
		</BriaThemeContext.Provider>
	);
};

export { BriaThemeProvider };
