import { Box, Theme, makeStyles } from "@material-ui/core";
import Plus from "../../assets/svgs/plus.svg";
import { useTranslation } from "react-i18next";

const DropDownButton = ({ lastOptionHandler, options }: any) => {
	const { t } = useTranslation("translation", { keyPrefix: "CampainInfo" });
	const classes = useStyles();
	const createBrandOption = {
		id: "createBrandOption",
		title: t("createBrand"),
	};
	return (
		<Box
			onClick={() => lastOptionHandler()}
			key={createBrandOption.id}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginBottom: options.length > 0 ? "16px" : "24px",
				marginTop: options.length > 0 ? "0px" : "24px",
			}}
		>
			<img className={classes.plusIcon} src={Plus} />
			<span className={classes.lastOptionButtonText}>{createBrandOption.title}</span>
		</Box>
	);
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	plusIcon: {
		marginRight: "5px",
	},
	lastOptionButtonText: {
		color: theme.palette.primary.dark,
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "16px",
	},
}));
export default DropDownButton;
