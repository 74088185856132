import { Dialog, IconButton, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useEffect, useState, RefObject, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";

import MinWhiteIcon from "../../../assets/icons/MinWhiteIcon";
import MaxWhiteIcon from "../../../assets/icons/MaxWhiteIcon";
import Parser from "html-react-parser";
import { resize } from "./Crop";
import { getImageMeta } from "../utilities";
import { proportions } from "../../../Constants/ImageProportions";

interface StyleProps {
	fullscreen?: boolean;
	imageRef: RefObject<HTMLImageElement>;
	cropperWidth: number;
	cropperHeight: number;
	croppingImage: string;
}

type CropImageToolProps = {
	minZoom: number;
	maxZoom: number;
	croppedArea: any;
	cropZoom: number;
	setCropZoom: (value: number) => void;
	cropName: string;
	imageUrl: string;
	fullscreen?: boolean;
	imageRef: RefObject<HTMLImageElement>;
	imageWrapperRef: RefObject<HTMLDivElement | undefined>;
	onCropCompleteCallback: (croppedAreaPixels: any, croppedAreaPercentage: any) => void;
	rotation: number;
	setRotation: (value: number) => void;
	croppedAreaPixels: any;
	previewImage: any;
	createCroppedImageFunction: (isPreview: boolean) => void;
	setCropImageRef: (value: any) => void;
	naturalSize: any;
	cropWidth: number;
	cropHeight: number;
	setCropWidth: (value: number) => void;
	setCropHeight: (value: number) => void;
	setNaturalSize: (value: any) => void;
	expandBackground?: boolean;
	activeCropSizeBtn?: any;
};

function CropImageTool({
	minZoom,
	maxZoom,
	imageUrl: passedImageUrl,
	previewImage,
	fullscreen,
	croppedArea,
	cropZoom,
	setCropZoom,
	cropName,
	imageRef,
	onCropCompleteCallback,
	rotation,
	setRotation,
	createCroppedImageFunction,
	setCropImageRef,
	cropHeight,
	setCropHeight,
	cropWidth,
	setCropWidth,
	naturalSize,
	setNaturalSize,
	imageWrapperRef,
	expandBackground,
	activeCropSizeBtn,
}: CropImageToolProps) {
	// Adapt zoom based on relaed to the original image
	const history = useHistory();
	const [cropperWidth, setCropperWidth] = useState<any>();
	const [cropperHeight, setCropperHeight] = useState<any>();
	const [croppingImage, setCroppingImage] = useState<any>();
	const [zoomPersantage, setZoomPersantage] = useState<number>(100);
	const classes = useStyles({
		fullscreen: fullscreen,
		imageRef: imageRef,
		cropperWidth: cropperWidth,
		cropperHeight: cropperHeight,
		croppingImage: croppingImage,
	});

	const [imageUrl, setImageUrl] = useState<string>();
	const divRef = useRef<any>(null);
	const cropPreviewImageRef = useRef<HTMLImageElement>(null);

	const [crop, setCrop] = useState({ x: 0, y: 0 });

	const area = { width: cropWidth, height: cropHeight };
	const [imageOnloadRatio, setImageOnloadRatio] = useState({
		widthRatio: 0,
		heightRatio: 0,
	});
	const [imageOnloadSize, setImageOnloadSize] = useState({
		width: 0,
		height: 0,
	});

	const [cropSize, setCropSize] = useState<{
		width: number;
		height: number;
	}>({ width: 0, height: 0 });

	const [showPreviewCrop, setShowPrviewCrop] = useState<boolean>(false);

	const onMediaLoad = (mediaSize: any) => {
		setNaturalSize({
			width: mediaSize.naturalWidth,
			height: mediaSize.naturalHeight,
		});
		setCropHeight(mediaSize.width);
		setCropWidth(mediaSize.height);
		setCropZoom(1);
		setRotation(0);
	};

	const onCropComplete = useCallback((croppedAreaNew, croppedAreaPixelss) => {
		onCropCompleteCallback(croppedAreaPixelss, croppedAreaNew);
	}, []);

	useEffect(() => {
		if (cropPreviewImageRef?.current && previewImage) {
			getImageMeta(previewImage).then((img: any) => {
				const imageHeight = img.height ?? 0;
				const imageWidth = img.width ?? 0;
				const containerHeight = cropPreviewImageRef?.current?.height ?? 0;
				const containerWidth = cropPreviewImageRef?.current?.width ?? 0;
				if (imageWidth <= containerWidth && imageHeight <= containerHeight) setZoomPersantage(100);
				else {
					setZoomPersantage(
						Math.trunc(Math.min(containerHeight / imageHeight, containerWidth / imageWidth) * 100)
					);
				}
			});
		}
	}, [
		previewImage,
		cropPreviewImageRef.current?.height,
		cropPreviewImageRef.current?.width,
		cropPreviewImageRef.current?.naturalHeight,
		cropPreviewImageRef.current?.naturalWidth,
	]);

	useEffect(() => {
		if (
			croppedArea &&
			croppedArea.width > 0 &&
			croppedArea.height > 0 &&
			naturalSize.width > 0 &&
			naturalSize.height > 0 &&
			imageWrapperRef.current &&
			imageWrapperRef.current.clientWidth > 0 &&
			imageWrapperRef.current.clientHeight > 0
		) {
			setCrop({ x: 0, y: 0 });

			let cropAreaRatio = 1;
			if (
				croppedArea.width > imageWrapperRef.current.clientWidth ||
				croppedArea.height > imageWrapperRef.current.clientHeight
			) {
				cropAreaRatio = Math.min(
					imageWrapperRef.current.clientWidth / croppedArea.width,
					imageWrapperRef.current.clientHeight / croppedArea.height
				);
			}

			const scaledCropWidth = croppedArea.width * cropAreaRatio;
			const scaledCropHeight = croppedArea.height * cropAreaRatio;
			setCropWidth(scaledCropWidth);
			setCropHeight(scaledCropHeight);

			let imageRatio = 1;
			if (
				naturalSize.width > imageWrapperRef.current.clientWidth ||
				naturalSize.height > imageWrapperRef.current.clientHeight
			) {
				imageRatio = Math.min(
					imageWrapperRef.current.clientWidth / naturalSize.width,
					imageWrapperRef.current.clientHeight / naturalSize.height
				);
			}
			const scaledImageWidth = naturalSize.width * imageRatio;
			const scaledImageHeight = naturalSize.height * imageRatio;
			let zoomRatio = expandBackground
				? Math.min(scaledCropHeight / scaledImageHeight, scaledCropWidth / scaledImageWidth)
				: Math.max(scaledCropHeight / scaledImageHeight, scaledCropWidth / scaledImageWidth);
			setCropZoom(zoomRatio);
			setCrop({ x: 0, y: 0 });
		}
	}, [croppedArea, naturalSize, imageWrapperRef.current]);

	useEffect(() => {
		if (passedImageUrl && passedImageUrl.length > 0) {
			setImageUrl(passedImageUrl);
		}
	}, [passedImageUrl]);

	useEffect(() => {
		if (cropWidth > 0 && cropHeight > 0) {
			setCropSize({ width: cropWidth, height: cropHeight });
		}
	}, [cropWidth, cropHeight]);

	useEffect(() => {
		if (divRef.current) {
			const childElement = divRef.current.querySelector(".reactEasyCrop_CropArea") as HTMLDivElement;
			if (childElement) {
				const { offsetWidth, offsetHeight } = childElement;
				if (offsetWidth >= 10 && offsetHeight >= 10) {
					// to solve wrong dimesions on first load
					setCropperWidth(offsetWidth);
					setCropperHeight(offsetHeight);
					const fetchCroppedImage = async () => {
						const transparentJpgSize = 1000;
						let ratio = 1;
						if (offsetWidth > transparentJpgSize || offsetHeight > transparentJpgSize) {
							ratio = Math.min(transparentJpgSize / offsetWidth, transparentJpgSize / offsetHeight);
						}
						const croppedAreaPixels = {
							x: 0,
							y: 0,
							width: offsetWidth * ratio,
							height: offsetHeight * ratio,
						};
						const croppedImage = await resize(
							"/transparent.jpg",
							croppedAreaPixels,
							0,
							offsetWidth,
							offsetHeight
						);
						return croppedImage;
					};
					fetchCroppedImage().then((result) => {
						setCroppingImage(result);
					});
				}
			}
		}
	}, [
		(divRef?.current?.querySelector(".reactEasyCrop_CropArea") as HTMLDivElement)?.offsetWidth,
		(divRef?.current?.querySelector(".reactEasyCrop_CropArea") as HTMLDivElement)?.offsetHeight,
	]);

	return (
		<>
			<div ref={divRef} style={{ width: "100%", height: "100%" }}>
				<Cropper
					restrictPosition={false}
					image={imageUrl}
					crop={crop}
					zoom={cropZoom}
					onZoomChange={setCropZoom}
					minZoom={minZoom}
					maxZoom={maxZoom}
					zoomWithScroll={true}
					onCropChange={setCrop}
					setImageRef={setCropImageRef}
					cropSize={cropSize}
					rotation={rotation}
					onRotationChange={setRotation}
					onCropComplete={onCropComplete}
					onMediaLoaded={onMediaLoad}
					classes={{ containerClassName: classes?.cropperContainer }}
					style={{
						mediaStyle: {
							// ...styleFitContainer(),
							// width: "auto",
							// position: "relative",
							// display: "inline-block",
						},
						cropAreaStyle: {
							// color: "rgba(217, 217, 217, 0.8)",
						},
					}}
				/>
			</div>
			{!showPreviewCrop && (
				<Box className={classes.onImageIconsContainer} display="flex" flexDirection="column">
					<>
						<IconButton
							onClick={() => {
								createCroppedImageFunction(true);
								setShowPrviewCrop(true);
							}}
							className={classes.iconButton}
							disabled={showPreviewCrop}
						>
							<MaxWhiteIcon className={classes.icon} width="31" height="31" />
						</IconButton>
						{/* <IconButton
							onClick={() => {
								setRotation((rotation + 90) % 360);
							}}
							className={classes.iconButton}
							disabled={showPreviewCrop}
						>
							<RotateWhiteIcon
								className={classes.icon}
								width="28"
								height="28"
							/>
						</IconButton> */}
					</>
				</Box>
			)}
			<Dialog
				onClose={() => setShowPrviewCrop(false)}
				open={showPreviewCrop}
				maxWidth={false}
				scroll="paper"
				className={classes.dialogRoot}
			>
				<Box
					display="flex"
					flexDirection="column"
					style={{
						height: "100%",
						alignItems: "center",
						justifyContent: "space-evenly",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						style={{
							width: "auto",
							height: "auto",
							maxWidth: "100%",
						}}
					>
						<img
							ref={cropPreviewImageRef}
							src={previewImage}
							style={{
								maxHeight: "90vh",
								backgroundImage: `url(/transparent.jpg)`,
								backgroundSize: "contain",
							}}
						/>
						<Box
							className={classes.onImageIconsContainer}
							style={{ zIndex: 1000 }}
							display="flex"
							flexDirection="column"
						>
							<>
								<>
									<IconButton
										onClick={() => {
											setShowPrviewCrop(false);
										}}
										className={classes.iconButton}
									>
										<MinWhiteIcon className={classes.icon} width="28" height="28" />
									</IconButton>
								</>
							</>
						</Box>
					</Box>
					<Box
						style={{
							position: "absolute",
							bottom: zoomPersantage == 100 ? -50 : 0,
							zIndex: 999,
							width: "1000px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							style={{
								width: "100%",
								height: 40,
								textAlign: "center",
							}}
						>
							<Typography
								style={{
									color: " #FFFFFF",

									opacity: 0.8,
									fontSize: "1.8rem",
									fontWeight: 500,
								}}
							>
								{cropName?.length > 1 && (
									<>
										<span>{Parser(cropName)} </span>
										<span
											style={{
												margin: "24px",
												color: "rgba(255, 255, 255, 0.5)",
											}}
										>
											|
										</span>
									</>
								)}
								&#32;&#32;
								{croppedArea?.width
									? croppedArea.width == -1
										? naturalSize.width
										: croppedArea.width
									: cropWidth}
								&#32;&#32;
								{" x "}
								&#32;&#32;
								{croppedArea?.height
									? croppedArea.height == -1
										? naturalSize.height
										: croppedArea.height
									: cropHeight}
								{zoomPersantage > 0 && zoomPersantage !== 100 && ` (${zoomPersantage}%)`}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</>
	); //
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	cropperContainer: (props) => ({
		position: "relative",
		overflow: "hidden",
		maxWidth: "100%",
		height: "100%",
		textAlign: "center",
		width: "100%",
		display: "inline-block",
		backgroundImage: `url(${props.croppingImage})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "Center",
		backgroundSize: "auto",
	}),

	dialogRoot: {
		"& .MuiPaper-root": {
			boxShadow: "none",
			background: "unset",
			overflow: "unset",
			maxHeight: "100%",
			maxWidth: "100%",
		},
		"&  .MuiBackdrop-root": { backgroundColor: "rgba(0, 0, 0, 0.68)" },
	},
	onImageIconsContainer: (props) => ({
		height: 26,
		width: 26,
		position: "absolute",

		background: "transparent",
		zIndex: 999,
		flexDirection: "row-reverse",
		left: "unset",
		right: "23px",
		top: "23px",
	}),

	iconButton: {
		boxShadow: "none",
		width: 28,
		height: 28,
		marginLeft: "8px",
		color: "transparent",
	},
	icon: {
		fontSize: 16,
		width: 36,
		height: 36,
		"& .MuiSvgIcon-root ": {
			width: 36,
			height: 36,
		},
		//boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
	},
}));

export default CropImageTool;
