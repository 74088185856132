import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { Skeleton } from "@material-ui/lab";

interface ISkeketonWrapper {
	loading: boolean;
	children: JSX.Element;
	length: number;
	skHeight: number;
}

const SkeletonArray = ({
	length,
	skHeight,
}: {
	length: number;
	skHeight: number;
}) => {
	const { w_100 } = useStyles();
	return (
		<>
			{new Array(length).fill(null).map(() => (
				<Skeleton animation="wave" className={w_100} height={skHeight} />
			))}
		</>
	);
};

export default function SkeketonWrapper({
	length,
	children,
	loading,
	skHeight,
}: ISkeketonWrapper) {
	return (
		<>
			{loading ? (
				<SkeletonArray length={length} skHeight={skHeight} />
			) : (
				children
			)}
		</>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		w_100: {
			width: "100%",
		},
	})
);
