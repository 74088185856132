import { createStyles, makeStyles, Menu, MenuItem, Theme, withStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import React from "react";
import DropdownArrow from "../../assets/svgs/dropdown-arrow";

const CustomizedButton = withStyles({
	root: {
		borderRadius: 4,
	},
	label: {
		textTransform: "capitalize",
	},
	disabled: {
		background: "rgba(18, 18, 50, 0.5)",
		color: "#FFFFFF",
		opacity: 0.3,
	},
})(Button);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btnStyle: {
			width: 175,
			height: 40,
			borderRadius: 8,
			color: theme.palette.primary.dark,
			fontSize: 16,
			fontWeight: 600,
			textTransform: "none",
			"&:hover": {
				background: "#E7E7E7",
				boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.03)",
			},
			"&:focus": {
				background: "rgba(83, 0, 201, 0.05);",
				boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02)",
			},
		},
		selected: {
			background: "rgba(83, 0, 201, 0.05);",
			boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02)",
		},
		selectedMenuItem: {
			backgroundColor: "#F1EDF7 !important",
		},
		menuItemRoot: {
			padding: "18px 12px",
			fontSize: 14,
			color: "#5B5B5B",
			lineHeight: "12px",
			fontWeight: 500,
		},
		arrowStyle: {
			marginLeft: 14,
		},
	})
);

interface IProps {
	text: string;
	menu: any;
	handleCloseMenu: any;
	btnClass?: any;
	hideArrow?: boolean;
	disabled?: boolean;
}

const BriaDropdownButton: React.FC<IProps & ButtonProps> = ({
	text,
	menu,
	handleCloseMenu,
	btnClass,
	hideArrow,
	disabled,
	...rest
}) => {
	const { styles } = btnClass;
	const classes = useStyles();
	// const mergedClassName = clsx(classes[buttonType], {
	// 	[classes.noUpperCase]: !upperCase,
	// 	[classes.upperCase]: upperCase,
	// });
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);

	const handleClose = (e: any) => {
		setAnchorEl(null);
		handleCloseMenu(e);
	};
	return (
		<div>
			<Button
				// id="basic-button"
				aria-controls={"basic-menu"}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				className={clsx(
					classes.btnStyle,
					{
						[classes.selected]: open,
					},
					btnClass
				)}
			>
				{text}
				{!hideArrow && <DropdownArrow className={classes.arrowStyle} />}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
				keepMounted
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				getContentAnchorEl={null}
				PaperProps={{
					style: {
						borderRadius: 8,
						width: 175,
						boxShadow: "0px 2px 16px rgba(36, 0, 88, 0.08)",
					},
				}}
			>
				{menu.map((item: any) => {
					return (
						<MenuItem
							disabled={disabled}
							classes={{
								root: classes.menuItemRoot,
								selected: classes.selectedMenuItem,
							}}
							value={item}
							onClick={(event) => {
								console.log(event, event.currentTarget.value);
								handleClose(event.currentTarget.getAttribute("value"));
							}}
						>
							{item}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default BriaDropdownButton;
