import { Box, InputBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import BriaButton, { BUTTONTYPES } from "../../BriaButton";
import AddIcon from "@material-ui/icons/Add";
import CreateCampaignDialog from "./CreateCampaignDialog";
import CircularLoadingTooltip from "../../LoadingTooltip";
import { useTranslation } from "react-i18next";
import { DISABLE_ENTER_LANGUAGES } from "../../../../Constants";
import i18n from "i18next";

type Props = {
	productImage: string;
	visualHash: string;
};

const CampaignTab = ({ productImage, visualHash }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [name, setName] = useState("");
	const [isEmptyName, setIsEmptyName] = useState(true);
	const [openCampaignDialog, setOpenCampaignDialog] = useState(false);
	const isLoading = !productImage || !visualHash;
	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		value ? setIsEmptyName(false) : setIsEmptyName(true);
		setName(value);
	};

	const handleEnterKey = (event: any) => {
		if (!DISABLE_ENTER_LANGUAGES.includes(i18n.language) && event.key === "Enter") {
			createCampaign();
		}
	};

	const createCampaign = () => {
		setOpenCampaignDialog(true);
	};

	return (
		<>
			<Box className={classes.form}>
				<Box style={{ display: "flex", gap: "2vh", alignItems: "center" }}>
					<Typography className={classes.subHeader}>{t("campaignName")}</Typography>
					{isLoading && <CircularLoadingTooltip key="" tab="" />}
				</Box>
				<InputBase
					id="inputId"
					placeholder={t("cocaColaAds")}
					className={classes.inputName}
					onChange={handleNameChange}
					value={name}
					onKeyUp={handleEnterKey}
					autoFocus={isEmptyName}
				/>
				<BriaButton
					className={classes.inputButton}
					size="large"
					buttonType={BUTTONTYPES.PRIMARYPURPLE}
					startIcon={<AddIcon />}
					disabled={isEmptyName || isLoading}
					onClick={createCampaign}
				>
					{t("createCampaign")}
				</BriaButton>
			</Box>
			{openCampaignDialog && (
				<CreateCampaignDialog
					open={openCampaignDialog}
					campaignName={name}
					productImage={productImage}
					visualHash={visualHash}
					handleClose={() => setOpenCampaignDialog(false)}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	form: { display: "grid", width: "95%", marginTop: "-20px" },
	subHeader: { fontSize: "14px" },
	inputName: {
		height: "4vh",
		borderRadius: "1vh",
		border: "1px solid #B3B3B9",
		color: "#5B5B5B",
		padding: "19px 0 19px 16px",
		letterSpacing: "0.02em",
		"& input": {
			fontSize: 16,
			textOverflow: "ellipsis",
			"&::placeholder": {
				color: "#5B5B5B",
				fontSize: 16,
			},
		},
		marginTop: "1vh",
		marginBottom: "1.8vh",
	},
	inputButton: {
		width: "53%",
		display: "flex",
		justifySelf: "end",
		borderRadius: "1vh",
	},
	loading: {
		position: "absolute",
		justifySelf: "center",
		alignSelf: "center",
	},
}));
export default CampaignTab;
