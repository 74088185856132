import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useContext } from "react";
import Context from "../../../Context/Context";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Google from "../../../assets/icons/Google";
import { GoogleAuthProvider } from "firebase/auth";
import { Form, Formik, FormikErrors } from "formik";
import BriaInput from "../../../Components/UI/BriaInput";
import ErrorIcon from "../../../assets/icons/ErrorIcon";
import BriaCheckbox from "../../../Components/UI/BriaCheckbox";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoginService } from "../../../hooks/useLoginService";
import { ReactComponent as Logo } from "../../../assets/bria_logo.svg";
import Container from "@material-ui/core/Container";
import { getCustomAuthProviderButton } from "../../../Helpers/AuthHelpers";
import clsx from "clsx";
import Github from "../../../assets/icons/Github";
import firebase from "firebase/compat";
import GithubAuthProvider = firebase.auth.GithubAuthProvider;

type LoginProps = {};
interface FormValues {
	email: string;
	password: string;
}

export default function LoginForm({}: LoginProps) {
	const classes = useStyles();
	const context = useContext(Context);
	const location = useLocation<any>();
	const { t } = useTranslation();
	let { loginWithEmail, errorMessage } = useLoginService();

	const initialValues = {
		email: "",
		password: "",
	};
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleSubmit = async (values: any) => {
		loginWithEmail(values.email, values.password);
	};

	const setAuthDialogStep = (value: string) => {
		context.setAuthDialogStep(value);
	};

	return (
		<Container component="main" maxWidth={false}>
			<Box className={classes.credentials_content}>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					width="100%"
					px={1.25}
					className={classes.content}
				>
					<Typography className={classes.typo1}>{t("welcomeBack")}</Typography>
					{location?.state?.passwordReset && <Alert severity="success">Password has been reset</Alert>}

					<Box className={classes.signInWithContainer}>
						<Button
							variant="outlined"
							startIcon={<Google className={classes.icon} />}
							onClick={async () =>
								isLoading
									? null
									: context.registerViaAuthProvider(new GoogleAuthProvider(), true).then(() => {
											context.setAuthDialogState(false);
									  })
							}
							className={clsx(classes.button, classes.autoSignInButton)}
						>
							{t("signInWithGoogle")}
						</Button>
						{!context.customAuthProvider ? (
							<Button
								variant="outlined"
								startIcon={<Github className={classes.icon} />}
								onClick={async () =>
									isLoading
										? null
										: context.registerViaAuthProvider(new GithubAuthProvider(), true).then(() => {
												context.setAuthDialogState(false);
										  })
								}
								className={clsx(classes.button, classes.autoSignInButton)}
							>
								{t("signInWithGithub")}
							</Button>
						) : (
							getCustomAuthProviderButton(
								context,
								isLoading,
								clsx(classes.button),
								t("signInWith") + context.customAuthProvider.name
							)
						)}
					</Box>
					<Typography className={classes.typo}>{t("or")}</Typography>

					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validate={(values) => {
							const errors: FormikErrors<FormValues> = {};
							if (!values.email) {
								errors.email = t("emailIsRequired");
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = t("invalidEmail");
							}

							if (!values.password) {
								errors.password = t("passwordIsRequired");
							}
							if (Object.keys(errors).length !== 0) {
								errorMessage = "";
							}

							return errors;
						}}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, handleChange, handleBlur, isValid }) => (
							<Form className={classes.form} noValidate>
								<Box className={classes.formElements}>
									<BriaInput
										id="email"
										label={t("email")}
										onChange={handleChange}
										error={(Boolean(errors.email) && touched.email) as boolean}
										onBlur={handleBlur}
										helperText={touched.email ? errors.email : ""}
										className={classes.textField}
										buttonSource="popup"
									/>
									<BriaInput
										id="password"
										label="Password"
										type="password"
										autoComplete="current-password"
										onChange={handleChange}
										onBlur={handleBlur}
										error={(Boolean(errors.password) && touched.password) as boolean}
										helperText={touched.password ? errors.password : ""}
										className={classes.textField}
										buttonSource="popup"
									/>

									{errorMessage && (
										<Box width="100%" className={classes.errorWrapper}>
											<Typography className={classes.error} color="secondary">
												<ErrorIcon className={classes.errorIcon} />
												{t(`${errorMessage}`.replace(/\s/g, "").replace(":", ""))}
											</Typography>
										</Box>
									)}
									<Button
										type="submit"
										variant="contained"
										color="secondary"
										className={clsx(classes.button, classes.ctaButton)}
									>
										{t("signIn")}
									</Button>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										className={classes.forgetPassword}
										height={17}
									>
										<Link
											onClick={() => {
												setAuthDialogStep("ForgotPassword");
											}}
											to={{
												pathname: "",
												search: window.location.search,
											}}
											className={classes.link}
										>
											Forgot your password?
										</Link>
									</Box>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>

				<Typography className={classes.typo2}>
					{t("isThisYourFirstTime?")}
					<Link
						onClick={() => {
							setAuthDialogStep("Register");
						}}
						to={{
							pathname: "",
							search: window.location.search,
						}}
						className={classes.signup}
					>
						{t("signUp")}
					</Link>
				</Typography>
			</Box>
		</Container>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			background: "white",
		},
		signInWithContainer: {
			display: "flex",
			columnGap: 10,
			width: "100%",
		},
		typo1: {
			fontSize: 32,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(6),
		},
		icon: {
			fontSize: 20,
		},
		button: {
			textTransform: "capitalize",
			borderRadius: 8,
			width: "100%",
			maxWidth: 440,
			height: 56,
		},
		formElements: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			rowGap: theme.spacing(3),
		},
		ctaButton: {
			marginTop: theme.spacing(0),
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: theme.palette.primary.dark,
			"&:hover, &:active, &:focus, &:visited": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
		typo: {
			position: "relative",
			width: "100%",
			textAlign: "center",
			fontSize: 16,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
			opacity: 0.5,
			margin: theme.spacing(3, 0),
			"&:before": {
				left: 0,
				width: "45%",
				bottom: "calc(50% - 1px)",
				height: 1,
				content: "''",
				display: "inline-block",
				position: "absolute",
				backgroundColor: "#B3B3B980",
			},
			"&:after": {
				right: 0,
				width: "45%",
				bottom: "calc(50% - 1px)",
				height: 1,
				content: "''",
				display: "inline-block",
				position: "absolute",
				backgroundColor: "#B3B3B980",
			},
		},
		form: {
			width: "100%",
		},
		textField: {
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				minWidth: "unset",
			},
		},
		errorWrapper: {
			textAlign: "left",
			position: "relative",
		},
		error: {
			fontSize: 12,
			fontWeight: theme.typography.fontWeightBold,
			maxWidth: 440,
			marginBottom: theme.spacing(1),
			position: "absolute",
			width: "100%",
			bottom: -6,
		},
		errorIcon: {
			fontSize: 14,
			marginRight: 4,
			verticalAlign: "middle",
		},
		forgetPassword: {
			width: 440,
			fontSize: 16,
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
		},
		checkbox: {
			[theme.breakpoints.down("xs")]: {
				marginLeft: 0,
			},
			"& .MuiCheckbox-root": {
				padding: "9px 9px 9px 0",
			},
			"& .MuiIconButton-label>span": {
				margin: "9px 9px 9px 0",
			},
		},
		link: {
			color: theme.palette.primary.dark,
			textDecoration: "none",
			[theme.breakpoints.down("xs")]: {
				marginRight: 16,
			},
			"&:hover": {
				textDecoration: "underline",
			},
		},
		typo2: {
			fontSize: 16,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
			background: "white",
			width: "100%",
			textAlign: "center",
			padding: theme.spacing(1, 0),
		},
		signup: {
			color: theme.palette.primary.dark,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},
		logo: {
			width: 141.09,
			height: 90.97,
		},
		credentials_content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-evenly",
			// width: 600,
			// minWidth: 600,
			maxWidth: 600,
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			background: "white",
			padding: "20px 60px",
			boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				padding: 0,
			},
		},
	})
);
