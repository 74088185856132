import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function CheckIcon(props: SvgIconProps) {
	return (
		<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.20459 5.26667L4.87126 9L12.2046 1"
				stroke={props.color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default CheckIcon;
