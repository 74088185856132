import { useState, useEffect } from "react";

/**
 *  const promiseFunc = () => new Promise((resolve,reject) => resolove({ count : 1 }))
 *
 *  const [
 *  getData: fucntion, // initiate the request by calling this func.
 *  data: any,    // the resolved promise data => { count : 1 }
 *  loading: boolean,  // promise resolving loading
 *  error: Error,  // error object in case promise rejected
 * ] = usePromise(promiseFunction)
 *
 */

const DEFAULT_ERROR = "Internal Server Error";

const sleep = (ms = 10) =>
	new Promise((resolve) =>
		setTimeout(() => {
			resolve(null);
		}, ms)
	);

export const usePromise = (promiseFunction: any, baseConfig?: any) => {
	const [hookState, setHookState] = useState({
		response: baseConfig?.defaultRes,
		loading: false,
		error: "",
	});

	const updateHookState = (newState = {}) => {
		setHookState({ ...hookState, ...newState });
	};

	const updateResponse = (res: any) => updateHookState({ response: res });

	function executePromise(...params: any) {
		updateHookState({ loading: true });
		return new Promise((resolve, reject) => {
			try {
				return (promiseFunction as () => Promise<any>)(
					...(params as [])
				)
					.then(async (value: any) => {
						updateHookState({ response: value, loading: false });
						resolve(value);
					})
					.catch((err) => {
						const errorMessage = reject(err);
						updateHookState({
							error: errorMessage,
							loading: false,
						});
					});
			} catch (error) {
				updateHookState({ error, loading: false });
				reject(error);
			}
		});
	}


	useEffect(()=>{
		if(baseConfig?.initReq) executePromise()
	},[])

	const { response, loading, error } = hookState;
	return [executePromise, response, loading, error, updateResponse];
};
