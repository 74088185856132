import { useContext } from "react";
import Context from "../Context/Context";

import { SystemViewTypes } from "./../Models/SystemView";

export const IsAdminOrTesting = () => {
	const context = useContext(Context);

	const isUserAdminOrTesting = () => {
		if (
			(context.user?.isAdmin() &&
				context.systemView === SystemViewTypes.Admin) ||
			(context.user?.isAdmin() &&
				context.systemView === SystemViewTypes.Testing)
		) {
			return true;
		} else return false;
	};

	return { isUserAdminOrTesting };
};
