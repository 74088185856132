class LinksConst {
	public static readonly CONTACT_US_FORM_LINK: string = "https://bria.ai/contact-us";
	public static readonly BRIA_API: string = "https://bria-ai-api-docs.redoc.ly/";
	public static readonly BRIA_WEBSITE: string = "https://bria.ai/";
	public static readonly PROD_LINK: string = "https://labs.bria.ai";
	public static readonly INT_LINK: string = "https://int.bria.ai";
	public static readonly PROD_HOSTNAME: string = "labs.bria.ai";
	public static readonly PROD_IFRAME_HOSTNAME: string = "prod.embedded.bria.ai";
	public static readonly INT_HOSTNAME: string = "int.bria.ai";
	public static readonly INT_IFRAME_HOSTNAME: string = "int.embedded.bria.ai";
	public static readonly JOIN_SLACK: string = "https://join.slack.com";
	public static readonly GAIA_HOME: string = "https://bria.ai/gaia/";
}

export default LinksConst;
