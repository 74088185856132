import { Button, makeStyles, Menu, MenuItem, Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";

import { ReactComponent as ArrowDown } from "../../assets/svgs/arrow-dropdown.svg";

import BriaButton, { BUTTONTYPES } from "./BriaButton";
import ThumbnailSlider from "./ThumbnailSlider";
interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		marginRight: 20,
	},
	menuItemRoot: {
		padding: "18px 12px",
		fontSize: 14,
		color: "#5B5B5B",
		lineHeight: "12px",
	},
	menuItemSelected: {
		backgroundColor: "rgba(83, 0, 201, 0.05) !important",
	},
	btnStyle: {
		width: 404,
		height: 40,
		fontWeight: 400,
		border: "1px solid #B3B3B9",
		borderRadius: 8,
		display: "flex",
		justifyContent: "space-between",
		padding: "9px 12px 9px",
		color: "#5B5B5B",
		fontSize: 14,
		textTransform: "capitalize",
		"&:hover": {
			border: "1px solid #1A0638",
		},
		"&:focus": {
			border: `3px solid ${theme.palette.primary.dark}`,
		},
	},
	title: {
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 12,
	},
	generateBtnStyle: {
		width: 85,
		height: 34,
		marginTop: 16,
		fontSize: 14,
		float: "right",
	},
	colorsWrapper: {
		display: "flex",
		justifyContent: "space-between",
		padding: "14px 20px",
	},
	colorIcon: {
		width: 18,
		height: 18,
		borderRadius: "100%",
		border: "0 solid #495057",
		cursor: "pointer",
	},
	sliderContainer: {
		marginTop: 28,
	},
}));

interface IProps {
	applySketchImageStyle: () => Promise<void>;
}

const ImageStyle: React.FC<IProps> = ({ applySketchImageStyle }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const [selectedOption, setSelectedOption] = useState("");

	const classes = useStyles();
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [sliderValue, setSliderValue] = useState(-10);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const strengthStyles = [
		{ borderWidth: 0.5 },
		{ borderWidth: 1 },
		{ borderWidth: 1.5 },
		{ borderWidth: 2 },
		{ background: "#8C8C8C" },
		{ background: "#495057" },
	];
	const generateImages = async () => {
		if (selectedOption === "Sketch") {
			await applySketchImageStyle();
		}
	};
	return (
		<Box className={classes.root}>
			<Box className={classes.selectContainer}>
				<Typography className={classes.title}>Select image style</Typography>
				<Button className={classes.btnStyle} onClick={handleClick}>
					{selectedOption ? selectedOption : "Select image style"}
					<ArrowDown />
				</Button>
				<Menu
					// labelId="demo-simple-select-label"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					// onChange={handleChange}
					elevation={0}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					PaperProps={{
						style: {
							width: 404,
							borderRadius: 8,
							boxShadow: "0px 8px 16px rgba(26, 6, 56, 0.1)",
						},
					}}
				>
					{/* <MenuItem
						classes={{
							root: classes.menuItemRoot,
							selected: classes.menuItemSelected,
						}}
						// selected={paltform === activeCropPreset}
						onClick={() => {
							setSelectedOption("Oil painting");
							handleClose();
						}}
						value={0}
					>
						Oil painting
					</MenuItem> */}
					<MenuItem
						classes={{
							root: classes.menuItemRoot,
							selected: classes.menuItemSelected,
						}}
						// selected={paltform === activeCropPreset}
						onClick={() => {
							setSelectedOption("Sketch");
							handleClose();
						}}
						value={1}
					>
						Sketch
					</MenuItem>
				</Menu>
			</Box>
			{selectedOption === "Oil painting" && (
				<Box className={classes.sliderContainer}>
					<ThumbnailSlider
						id={"painting"}
						maxValue={6}
						minValue={0}
						value={sliderValue}
						sliderDefaultValue={-10}
						title="Strength"
					/>
					<Box className={classes.colorsWrapper}>
						{strengthStyles.map((style, index) => (
							<Box
								className={classes.colorIcon}
								style={style}
								onClick={() => {
									setSliderValue(index + 1);
								}}
							/>
						))}
					</Box>
				</Box>
			)}
			<BriaButton
				disabled={!selectedOption}
				onClick={() => generateImages()}
				className={classes.generateBtnStyle}
				buttonType={BUTTONTYPES.PRIMARYMEDIUM}
			>
				Generate
			</BriaButton>
		</Box>
	);
};

export default ImageStyle;
