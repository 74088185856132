import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionCreditsTypes } from "../Constants/SubscriptionConstants";
import Context from "../Context/Context";

function UserRemainingCreditsMessage(props: {
	creditType: SubscriptionCreditsTypes;
}) {
	const classes = useStyles();
	const context = useContext(Context);

	function getCredits() {
		if (context.iframeConfig || !context.subscriptionConfig) return null;
		switch (props.creditType) {
			case SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION:
				return {
					remaining:
						context.user?.subscription
							.people_customizations_credits,
					total: context.subscriptionConfig?.peopleCustomizations,
				};
			case SubscriptionCreditsTypes.VIDEO:
				return {
					remaining: context.user?.subscription.videos_credits,
					total: context.subscriptionConfig?.videos,
				};
			default:
				return null;
		}
	}
	const credits = getCredits();
	return (
		<>
			{credits &&
				credits.remaining !== undefined &&
				credits.remaining !== -1 &&
				credits.total !== undefined &&
				credits.total !== -1 && (
					<Box>
						<Typography
							className={classes.userRemainingCreditMessage}
						>
							Hello! You have <br></br> used{" "}
							<strong>{credits.total - credits.remaining}</strong>{" "}
							out of <strong>{credits.total}</strong> credits
						</Typography>
					</Box>
				)}
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		userRemainingCreditMessage: {
			margin: "auto",
			width: "fit-content",
			fontSize: 12,
		},
	};
});

export default UserRemainingCreditsMessage;
