import { Box, Theme } from "@material-ui/core";
import ImgViewer, { GridImgProps, mainImgInfo } from "../BrandPage/ImgViewer/ImgViewer";
import TitleTexts from "../TitleTexts/TitleTexts";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { BrandContext } from "../BrandPage/BrandPage";
import { SELECTED_PREDEFINED_MOOD_INFO, SELECTED_PREDEFINED_MOOD_URL } from "../ColorPallete/ColorPallete";
import Context from "../../../../Context/Context";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import { handleMoodApplyCall } from "../BrandPage/ImgViewer/BrandImg/BrandImg";
import { useMultiPromise } from "../../../../hooks/useMultiPromises";
import clsx from "clsx";
import { GENERAL_ORG_ID } from "../../../../Constants";

export default function Results() {
	const { brandState, generalState, updateGeneralState, updateBrandState } = useContext(BrandContext);
	const { moodSliders } = brandState as any;
	const { moodSlidersSuggestion1, moodSlidersSuggestion2 } = generalState as any;
	const selectedImg: GridImgProps = mainImgInfo;
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const instance = BriaAPI.getInstance(selectedImg.vhash, context.iframeConfig);
	const [applyMoodToImgs] = useMultiPromise();
	const [loading, setLoading] = useState(false);
	const [imageSrc, setImageSrc] = useState((generalState as any)?.[SELECTED_PREDEFINED_MOOD_URL]);
	const [imageSuggestion1Src, setImageSuggestion1Src] = useState(mainImgInfo.src);
	const [imageSuggestion2Src, setImageSuggestion2Src] = useState(mainImgInfo.src);
	const [seed, setSeed] = useState(1);
	const [selectedImage, setSelectedImage] = useState((brandState as any)?.selectedResultImage ?? "center");
	const classes = useStyles({ selectedImage });

	useEffect(() => {
		setLoading(true);
		// generate selected mood img and 2 suggested moods img -25% and +25%
		const moodImgsPromiseArr = [moodSliders, moodSlidersSuggestion1, moodSlidersSuggestion2].map((mood) =>
			handleMoodApplyCall(context, instance, mood, organizationUid)
		);

		applyMoodToImgs(moodImgsPromiseArr)
			.then((imgs: any[]) => {
				updateGeneralState("", "", {
					[SELECTED_PREDEFINED_MOOD_URL]: imgs[0],
					[SELECTED_PREDEFINED_MOOD_INFO]: moodSliders,
				});
				setImageSrc(imgs[0] ? imgs[0] : mainImgInfo.src);
				setImageSuggestion1Src(imgs[1] ? imgs[1] : mainImgInfo.src);
				setImageSuggestion2Src(imgs[2] ? imgs[2] : mainImgInfo.src);
			})
			.finally(() => setLoading(false));
	}, []);

	const reRenderImageContainer = () => {
		setSeed(Math.random());
	};

	useEffect(() => {
		updateBrandState(selectedImage, "selectedResultImage");
		reRenderImageContainer();
	}, [selectedImage]);

	return (
		<Box className={classes.resultsRoot}>
			<TitleTexts passedClassName={classes.brandName} subText={(brandState as any)?.name} />
			<TitleTexts
				passedClassName={classes.titleTexts}
				text="Whoomp there it is!"
				subText="AI has generated this customized brand mood according to your inputs"
			/>
			<Box key={seed} display="flex" marginTop="4.8vh" className={classes.imagesContainer}>
				<Box
					width={selectedImage === "left" ? "23vw" : "14.54vw"}
					className={clsx({
						[classes.imageBox]: true,
						[classes.selectedImage]: selectedImage === "left",
					})}
				>
					<ImgViewer
						imageSrc={imageSuggestion1Src}
						hideImgTools={selectedImage !== "left"}
						enableCompareMode={selectedImage === "left"}
						parentLoading={loading}
						isBrandPage={true}
						onClick={() => setSelectedImage("left")}
					/>
				</Box>
				<Box
					width={selectedImage === "center" ? "23vw" : "14.54vw"}
					className={clsx({
						[classes.imageBox]: true,
						[classes.selectedImage]: selectedImage === "center",
					})}
				>
					<ImgViewer
						imageSrc={imageSrc}
						parentLoading={loading}
						hideImgTools={selectedImage !== "center"}
						enableCompareMode={selectedImage === "center"}
						isBrandPage={true}
						onClick={() => setSelectedImage("center")}
					/>
				</Box>
				<Box
					width={selectedImage === "right" ? "23vw" : "14.54vw"}
					className={clsx({
						[classes.imageBox]: true,
						[classes.selectedImage]: selectedImage === "right",
					})}
				>
					<ImgViewer
						imageSrc={imageSuggestion2Src}
						hideImgTools={selectedImage !== "right"}
						enableCompareMode={selectedImage === "right"}
						parentLoading={loading}
						isBrandPage={true}
						onClick={() => setSelectedImage("right")}
					/>
				</Box>
			</Box>
		</Box>
	);
}

interface StyleProps {
	selectedImage: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	brandName: {
		color: "#A4A4A4",
		padding: "5vh 0 4vh",
		"& p": {
			fontWeight: 400,
			fontSize: "24px",
		},
	},
	gridToggleIcon: {
		bottom: "-30px !important",
	},
	resultsRoot: {
		maxWidth: "calc(100% - 12.5%)",
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
	},
	titleTexts: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	imagesContainer: {
		justifyContent: "center",
		"& > *": {
			position: "relative",
			"&:after": {
				content: "''",
				display: "block",
				background:
					"radial-gradient(50% 50% at 50.07% 50%, rgb(162 162 162 / 61%) -20%, rgba(245, 245, 245, 0) 100%)",
				height: "25px",
				width: "66%",
				position: "absolute",
				left: "50%",
				transform: "translate(-50%)",
				bottom: "-16px",
				zIndex: "-1",
			},
		},
	},
	imageBox: {
		margin: "auto 0",
	},
	selectedImage: {
		"& img": {
			filter: "drop-shadow(rgba(92, 90, 90, 0.4) 0px 8px 12px)",
			border: "3px solid #fff",
		},
	},
}));
