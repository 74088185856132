import React from "react";

function SceneIcon({ color }: any) {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 5.5H12.01M1 12.5L5 8.50001C5.45606 8.06117 5.97339 7.83014 6.5 7.83014C7.02661 7.83014 7.54394 8.06117 8 8.50001L13 13.5M11 11.5L12 10.5C12.4561 10.0612 12.9734 9.83014 13.5 9.83014C14.0266 9.83014 14.5439 10.0612 15 10.5L17 12.5M4 1.5H14C15.6569 1.5 17 2.84315 17 4.5V14.5C17 16.1569 15.6569 17.5 14 17.5H4C2.34315 17.5 1 16.1569 1 14.5V4.5C1 2.84315 2.34315 1.5 4 1.5Z"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default SceneIcon;
