import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { ReactComponent as Logo } from "../../../assets/bria_logo.svg";
import { Container } from "@material-ui/core";

interface IProps {
	email: string;
	resendEmail: (values: any) => void;
}

const ResetPasswordConfirmationForm: React.FC<IProps> = ({ email, resendEmail }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const resendEmailToCustomer = async () => {
		resendEmail({ email: email });
	};

	return (
		<Container component="main" maxWidth={false}>
			<Box className={classes.credentials_content}>
				<Box position="relative">
					<Logo className={classes.logo} />
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center" width="100%">
					<Typography className={classes.typo1}>{t("emailSent")}</Typography>
				</Box>
				<Box textAlign="center" padding="10px">
					<Typography className={classes.typo2}>
						{t("weSentAPasswordResetLinkToV1")}{" "}
						<span className={classes.boldFont}>{t("boldEmail", { email: email })}</span>{" "}
						{t("weSentAPasswordResetLinkToV1.1")}
					</Typography>
				</Box>

				<EmailIcon className={classes.icon} />

				<Box textAlign="center" padding="10px" onClick={resendEmailToCustomer}>
					<Typography className={classes.typo3}>{t("resendEmail")}</Typography>
				</Box>
			</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: 130,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		// marginBottom: theme.spacing(7),
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	typo3: {
		fontSize: 16,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.primary.dark,
		cursor: "pointer",
		"&:hover": {
			opacity: 0.5,
		},
		"&:active": {
			color: "#37057D",
		},
	},
	boldFont: {
		fontWeight: theme.typography.fontWeightBold,
	},
	logo: {
		width: 141.09,
		height: 90.97,
	},
	credentials_content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-evenly",
		// width: 600,
		// minWidth: 600,
		maxWidth: 600,
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		background: "white",
		padding: "20px 60px",
		boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			padding: 0,
		},
	},
}));

export default ResetPasswordConfirmationForm;
