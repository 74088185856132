import { makeStyles } from "@material-ui/styles";
import React from "react";

export default function TextArea(props: any) {
	const classes = useStyles();
	return (
		<>
			<textarea
				rows={3}
				className={classes.textAreaStyle}
				{...props}
			/>
		</>
	);
}

const useStyles = makeStyles(() => ({
	textAreaStyle: {
		border: "1px solid ",
		color: "#A4A4A4",
		outline: "none",
		width: "100%",
		borderRadius: 5,
		marginTop: "1rem",
		padding: "1rem",
		fontSize: "1.2rem",
		resize: "none",
	},
}));
