import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function RerenderAt({
	dep,
	children,
}: {
	dep: any;
	children: JSX.Element;
}) {
	const [show, setShow] = useState(true);
	const [isMounted, setIsMounted] = useState(true);

	const toggleRender = () => {
		setShow(false);
		setTimeout(() => {
			setShow(true);
		}, 100);
	};

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		if (!isMounted) return;
		toggleRender();
	}, [dep]);

	return <> {show && children}</>;
}
