import { useEffect, useRef, useState } from "react";
import { getImageMeta, getImagePixelsData, hexToRgb } from "../../Components/Tools/utilities";
import { useSegmentation } from "./useSegmentation";
import { useTheme } from "@material-ui/core";

export const useSegmentationCanvas = (
	mainObjectData: any,
	lockMasks: any,
	config: any,
	imageUrl: string,
	objectsTabCanvasRef: any,
	parentDimenstions: { width: number; height: number } | undefined,
	brushOverlayCanvasRef: any,
	setShowObjectsFollowUpTooltip: (value: boolean) => void,
	setObjectsTooltipPosition: (value: { x: number; y: number } | undefined) => void,
	objectsPanopticImage: ImageData | undefined,
	objectsMasks: HTMLImageElement[],
	onSegmentClick?: (e: any, mask: HTMLImageElement | undefined) => void,
	onMouseClick?: (
		e: any,
		mouseLocation: { x: number; y: number } | undefined,
		width: number | undefined,
		height: number | undefined
	) => void,
	imageElement?: HTMLImageElement
) => {
	const context = useRef<CanvasRenderingContext2D | null>(null);
	const theme = useTheme();

	const maskColors = hexToRgb(theme.palette.primary.main);

	const getCanvasContext = async (newImageUrl: string = imageUrl) => {
		if (!objectsTabCanvasRef.current) {
			return;
		}
		context.current = objectsTabCanvasRef.current.getContext("2d");

		if (newImageUrl) {
			try {
				const image = await getImageMeta(newImageUrl);
				if (context.current && parentDimenstions) {
					setCanvasDimensions(objectsTabCanvasRef.current, image, parentDimenstions);
					drawCheckeredBackground(parentDimenstions);
					drawImageOnCanvas(image);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		}
	};
	const setCanvasDimensions = (canvas: any, image: any, parentDimensions: any) => {
		if (image.width > parentDimensions.width || image.height > parentDimensions.height) {
			const scaleX = parentDimensions.width / image.width;
			const scaleY = parentDimensions.height / image.height;
			const scale = Math.min(scaleX, scaleY);

			canvas.width = Math.round(image.width * scale);
			canvas.height = Math.round(image.height * scale);
		} else {
			canvas.width = image.width;
			canvas.height = image.height;
		}
	};

	const drawCheckeredBackground = (parentDimensions: any) => {
		if (!context.current) {
			return;
		}
		const squareSize = 8.2;
		const numSquaresX = parentDimensions.width / squareSize;
		const numSquaresY = parentDimensions.height / squareSize;

		for (let outer = 0; outer < numSquaresX; outer++) {
			for (let inner = 0; inner < numSquaresY; inner++) {
				const xPixel = outer * squareSize;
				const yPixel = inner * squareSize;

				if ((outer + inner) % 2 === 0) {
					context.current.fillStyle = "#fff";
				} else {
					context.current.fillStyle = "#cccccc";
				}

				context.current.fillRect(xPixel, yPixel, squareSize, squareSize);
			}
		}
	};

	const drawImageOnCanvas = (image: any) => {
		if (!context.current) {
			return;
		}
		context.current.drawImage(
			image,
			0,
			0,
			image.width,
			image.height,
			0,
			0,
			objectsTabCanvasRef.current.width,
			objectsTabCanvasRef.current.height
		);
	};
	const reDrawImage = async (newImageUrl: string) => {
		await getCanvasContext(newImageUrl);
	};

	const initializeCanvas = async () => {
		getCanvasContext();
	};

	useEffect(() => {
		initializeCanvas();
	}, []);

	const { mask } = useSegmentation(
		context,
		objectsTabCanvasRef,
		setShowObjectsFollowUpTooltip,
		setObjectsTooltipPosition,
		objectsPanopticImage,
		objectsMasks,
		onSegmentClick,
		onMouseClick,
		imageElement?.width,
		imageElement?.height,
		imageUrl
	);

	useEffect(() => {
		if (imageUrl) {
			reDrawImage(imageUrl);
		}
	}, [imageUrl]);

	useEffect(() => {
		if (imageElement && context.current) {
			if (mask) {
				const imageData = getImagePixelsData(imageElement, mask.width, mask.height);
				const maskData = getImagePixelsData(mask, mask.width, mask.height);
				if (imageData.imagePixelsData && maskData.imagePixelsData) {
					for (let i = 0, len = imageData.imagePixelsData.data.length; i < len; i = i + 4) {
						if (maskData.imagePixelsData.data[i] === 255) {
							imageData.imagePixelsData.data[i] = maskColors[0];
							imageData.imagePixelsData.data[i + 1] = maskColors[1];
							imageData.imagePixelsData.data[i + 2] = maskColors[2];
							imageData.imagePixelsData.data[i + 3] = 100;
						}
					}
					if (imageData.ctx) {
						imageData.ctx.putImageData(imageData.imagePixelsData, 0, 0);
						getImageMeta(imageData.ctx.canvas.toDataURL()).then((newImageElement) => {
							context.current?.drawImage(
								newImageElement,
								0,
								0,
								newImageElement.width,
								newImageElement.height,
								0,
								0,
								objectsTabCanvasRef.current.width,
								objectsTabCanvasRef.current.height
							);
						});
					}
				}
			} else {
				context.current?.drawImage(
					imageElement,
					0,
					0,
					imageElement.width,
					imageElement.height,
					0,
					0,
					objectsTabCanvasRef.current.width,
					objectsTabCanvasRef.current.height
				);
			}
		}
	}, [mask]);

	return {
		actions: undefined,
		reDrawImage,
		initializeCanvas,
	};
};
