import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { getValueFromNormalizedRange, normalizeSliderValue } from "../../Helpers/math";

import ErrorIcon from "@material-ui/icons/Error";
import CustomizedTooltip from "./Tooltip";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: (small) => (small ? 0 : "0 8px"),
		width: "100%",
		"&.disabled": {
			"& .MuiSlider-mark, & .MuiSlider-track, .MuiSlider-thumb": {
				display: "none",
			},
			// opacity: "0.3",
		},
	},
	sliderContainer: {
		width: "100%",
		padding: "2px 0",
		height: 20,
	},
	iconsWrapper: {
		textAlign: "right",
		display: "flex",
		justifyContent: "flex-end",
	},
	icon: {
		marginRight: theme.spacing(0.5),
		cursor: "pointer",
		stroke: theme.palette.cards.main,
		fontSize: 14,
	},
	disabledIcon: {
		opacity: 0.3,
	},
	iconColor: {
		color: theme.palette.cards.main,
	},
	title: {
		color: theme.palette.cardText.main,
		fontSize: 14,
		fontWeight: 600,
		lineHeight: "17px",
		"&.disabled": {
			color: `${theme.palette.cardText.main}4d`,
		},
	},
	typo: {
		color: "#5B5B5B",
		fontSize: 12,
		fontWeight: 400,
		lineHeight: "15px",
	},
}));

const customBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const ThumbnailCustomSlider = withStyles((theme) => ({
	root: {
		padding: "10px 0",
		color: (props: any) => (props.value === props.defaultValue ? "#bfbfbf" : theme.palette.cards.main),
	},
	thumb: {
		height: 18,
		width: 18,
		backgroundColor: "#fff",
		transform: (props: any) => `translateX( ${props.value > 0 ? "-" : ""}50%)`,
		marginTop: -9,
		marginLeft: -9,
		"&:hover": {
			boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			"@media (hover: none)": {
				boxShadow: customBoxShadow,
			},
		},
		boxShadow: customBoxShadow,
		zIndex: 2,
	},

	mark: (props: any) => {
		const marksLength = props.marks.length;
		const rootWidth = props.width;

		return {
			backgroundColor: "#bfbfbf",
			[`&[data-index='${marksLength - 1}']`]: props?.maxNameRiskArea
				? {
						backgroundColor: "#bfbfbf",
						height: 12,
						width: 1,
						marginTop: -5,
						"&:before": {
							content: '""',
							display: "block",
							background: "linear-gradient(270deg, #D80067 8%, rgba(216, 0, 103, 0) 102%);",
							width: rootWidth / marksLength,
							height: 2,
							marginTop: 5,
							position: "absolute",
							zIndex: 1,
							borderTopRightRadius: 1,
							borderBottomRightRadius: 1,
						},
				  }
				: {},
			[`&[data-index='1']`]: props?.minNameRiskArea
				? {
						backgroundColor: "#bfbfbf",
						height: 12,
						width: 1,
						marginTop: -5,
						"&:before": {
							content: '""',
							display: "block",
							background: "linear-gradient(90deg, #D80067 8%, rgba(216, 0, 103, 0) 102%);",
							width: rootWidth / marksLength,
							marginLeft: (rootWidth / marksLength) * -1,
							height: 2,
							marginTop: 5,
							position: "absolute",
							zIndex: 1,
						},
				  }
				: {},
		};
	},

	active: {},
	disabled: {
		"&.MuiSlider-thumb": {
			height: 18,
			width: 18,
			backgroundColor: "#fff",
			marginTop: -9,
			marginLeft: -9,
			"&:focus, &:hover, &$active": {
				boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
				"@media (hover: none)": {
					boxShadow: customBoxShadow,
				},
			},
			boxShadow: customBoxShadow,
		},
	},
	track: {
		height: 2,
		color: theme.palette.cards.main,
		backgroundImage: ({ trackGrdient }: any) => trackGrdient || "",
		width: ({ trackGrdient }: any) => (trackGrdient ? "100% !important" : ""),
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: "#bfbfbf",
		backgroundImage: ({ trackGrdient }: any) => trackGrdient || "",
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
}))((props: IProps | any) => <Slider {...props} />);

interface IProps extends SliderProps {
	id: string;
	title?: string;
	leftSubtitle?: string;
	rightSubtitle?: string;
	middleSubtitle?: string;
	minValue: number;
	maxValue: number;
	reversed?: number | null;
	value: number;
	stepSize?: number | null;
	handleValueChange?: (value: number) => void;
	handleDisabledChange?: (disabled: boolean) => void;
	disabled?: boolean;
	small?: boolean;
	error?: boolean;
	warrning?: boolean;
	reversedValues?: boolean;
	score?: number | string;
	sliderDefaultValue: number;
	NormalizationRangeMin?: number;
	NormalizationRangeMax?: number;
	minNameRiskArea?: boolean;
	maxNameRiskArea?: boolean;
	renderTopRight?: () => JSX.Element;
	disabledTip?: boolean;
	noMarks?: boolean;
}

const ThumbnailSlider: React.FC<IProps & Record<string, any>> = ({
	id,
	title,
	minValue,
	maxValue,
	value,
	stepSize = 2,
	handleValueChange,
	disabled,
	leftSubtitle,
	rightSubtitle,
	middleSubtitle,
	small,
	reversed = 1,
	warrning,
	error,
	score,
	sliderDefaultValue,
	NormalizationRangeMin = -10,
	NormalizationRangeMax = 10,
	minNameRiskArea = false,
	maxNameRiskArea = false,
	reversedValues,
	renderTopRight,
	disabledTip,
	noMarks,
	...rest
}) => {
	const { t } = useTranslation();
	const classes = useStyles(small);
	const reverse = reversed ?? 1;
	const min = Math.min(minValue * reverse, maxValue * reverse);
	const max = Math.max(minValue * reverse, maxValue * reverse);
	const [sliderValue, setSliderValue] = useState<number>(
		normalizeSliderValue(min, max, NormalizationRangeMin, NormalizationRangeMax, value * reverse)
	);
	const [sliderWidth, setSliderWidth] = useState(0);
	const sliderContainerRef = useRef<any>(null);

	useEffect(() => {
		setSliderWidth(sliderContainerRef?.current?.offsetWidth || 0);
	}, [sliderContainerRef]);

	const handleChange = (e: any, value: number | number[]) => {
		if (disabled) return;
		const newValue = getValueFromNormalizedRange(
			min,
			max,
			NormalizationRangeMin,
			NormalizationRangeMax,
			value as number
		);
		if (handleValueChange) {
			handleValueChange(newValue * reverse);
		}
	};

	const handleChangeDebouced = useCallback(
		debounce((nextValue: number) => handleChange(null, nextValue), 1000),
		[] // will be created only once initially
	);

	const handleSubtract = () => {
		if (disabled) return;
		let newValue = sliderValue - (stepSize ?? 1);
		if (newValue >= NormalizationRangeMin) {
			setSliderValue(newValue);
			handleChangeDebouced(newValue);
		}
	};
	const handleAdd = () => {
		if (disabled) return;
		let newValue = sliderValue + (stepSize ?? 1);
		if (newValue <= NormalizationRangeMax) {
			setSliderValue(newValue);
			handleChangeDebouced(newValue);
		}
	};

	useEffect(() => {
		let newValue = normalizeSliderValue(min, max, NormalizationRangeMin, NormalizationRangeMax, value * reverse);
		newValue = reversedValues ? newValue * -1 : newValue;
		if (newValue !== sliderValue) {
			setSliderValue(newValue);
		}
	}, [value]);

	let items = noMarks ? [] : Array((NormalizationRangeMax - NormalizationRangeMin) / (stepSize ?? 1)).fill(0);
	items.forEach((x, index) => {
		items[index] = {
			value: index * (stepSize ?? 1) + NormalizationRangeMin,
		};
	});
	const theme = useTheme();
	const defaultValueNormalized = normalizeSliderValue(
		min,
		max,
		NormalizationRangeMin,
		NormalizationRangeMax,
		sliderDefaultValue * reverse
	);

	const [openDisabledTip, setOpenDisabledTip] = useState(false);

	return (
		<Box className={clsx(classes.root, disabled && "disabled")}>
			<Grid container>
				<Grid container item alignItems="flex-end">
					<Grid item xs={6}>
						{title && (
							<Box display="flex" style={{ marginBottom: 18 }}>
								<Typography
									align="left"
									className={clsx(classes.title, disabled && "disabled")}
									variant={small ? "caption" : "body1"}
									style={{ textTransform: "capitalize" }}
								>
									{title}
									{(disabledTip || warrning || error) && (
										<CustomizedTooltip
											arrow
											multiLine={true}
											title={
												<>
													{disabledTip ? (
														<div>{t("theSystemOnlyEnablesHighQuality")}</div>
													) : (
														<div>{t("theSystemDetectedLowerQuality")} </div>
													)}
												</>
											}
											placement="right"
										>
											<ErrorIcon
												style={{
													fontSize: 16,
													margin: "2px 0 2px 10px",
													color:
														disabledTip || error ? theme.palette.primary.dark : "#FFC107",
												}}
											/>
										</CustomizedTooltip>
									)}
								</Typography>
							</Box>
						)}
					</Grid>
					{/* <Grid item xs={6}>
						{renderTopRight ? (
							<Box className={classes.iconsWrapper}>
								{renderTopRight()}
							</Box>
						) : (
							<Box className={classes.iconsWrapper}>
								<MinusIcon
									className={clsx(
										classes.icon,
										!disabled && classes.iconColor,
										disabled && classes.disabledIcon
									)}
									onClick={handleSubtract}
								/>
								<CirclePlusIcon
									className={clsx(
										classes.icon,
										!disabled && classes.iconColor,
										disabled && classes.disabledIcon
									)}
									onClick={handleAdd}
								/>
							</Box>
						)}
					</Grid> */}
				</Grid>
				<Grid item className={classes.sliderContainer} ref={sliderContainerRef}>
					<ThumbnailCustomSlider
						id={title}
						value={sliderValue}
						defaultValue={defaultValueNormalized}
						onChange={(e: any, value: number | number[]) => {
							setSliderValue(value as number);
						}}
						onChangeCommitted={handleChange}
						width={sliderWidth}
						min={NormalizationRangeMin}
						max={NormalizationRangeMax}
						disabled={disabled}
						marks={items}
						maxNameRiskArea={maxNameRiskArea}
						minNameRiskArea={minNameRiskArea}
						step={stepSize}
						{...rest}
					/>
				</Grid>
				<Grid style={{ marginTop: 10 }} item container justify="space-between">
					<Grid item>
						{leftSubtitle && (
							<Box fontWeight="fontWeightLight">
								<Typography variant="caption" className={classes.typo}>
									{reversed === 1 ? leftSubtitle : rightSubtitle}
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid item>
						{middleSubtitle && (
							<Box fontWeight="fontWeightLight">
								<Typography variant="caption" className={classes.typo}>
									{middleSubtitle}
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid item>
						{rightSubtitle && (
							<Box fontWeight="fontWeightLight">
								<Typography variant="caption" className={classes.typo}>
									{reversed === 1 ? rightSubtitle : leftSubtitle}
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ThumbnailSlider;
