export const normalizeSliderValue = (
	a0: number,
	a1: number,
	b0: number,
	b1: number,
	a: number
): number => {
	return b0 + (b1 - b0) * ((a - a0) / (a1 - a0));
};

export const normalizeSliderValueToBaseTen = (
	a0: number,
	a1: number,
	a: number
): number => {
	return normalizeSliderValue(a0, a1, -10, 10, a);
};

export const getValueFromNormalizedRange = (
	a0: number,
	a1: number,
	b0: number,
	b1: number,
	b: number
): number => {
	return a0 + ((b - b0) * (a1 - a0)) / (b1 - b0);
};

export const getValueFromNormalizedRangeBaseTen = (
	a0: number,
	a1: number,
	b: number
): number => {
	return getValueFromNormalizedRange(a0, a1, -10, 10, b);
};

export const caluclateThubmnailValue = (
	currentValue: number,
	thumbnailsCount: number,
	index: number
): number => {
	if (thumbnailsCount === 2) {
		let values: number[] = [];
		if (-10 <= currentValue && currentValue <= -5) {
			values = [1, 6];
		}
		if (
			(-4 <= currentValue && currentValue <= 0) ||
			(1 <= currentValue && currentValue <= 5)
		) {
			values = [-6, 6];
		}
		if (6 <= currentValue && currentValue <= 10) {
			values = [-1, -6];
		}

		return values[index];
	} else {
		if (
			(-10 <= currentValue && currentValue <= -6) ||
			(6 <= currentValue && currentValue <= 10)
		) {
			return 0;
		}
		if (-5 <= currentValue && currentValue <= 0) {
			return 6;
		}
		if (1 <= currentValue && currentValue <= 5) {
			return -6;
		}
	}
	return 0;
};
