import { useCallback, useEffect, useRef } from "react";

export const useBrush = (
	mainObjectData: any,
	lockMasks: boolean,
	context: any,
	brushConfig: any,
	objectsTabCanvasRef: any,
	brushOverlayCanvasRef: any
) => {
	const pathRef = useRef<Path2D | null>(null);
	const isDrawingRef = useRef(false);
	const previousCoordinatesRef = useRef([0, 0]);
	const overlayContextRef = useRef<CanvasRenderingContext2D | null>(null);

	const updateCoordinates = useCallback((e) => {
		previousCoordinatesRef.current = [e.offsetX, e.offsetY];
	}, []);
	const drawLine = useCallback(
		(e) => {
			if (!overlayContextRef.current || !pathRef.current || !isDrawingRef.current) {
				return;
			}
			let yValue = e.offsetY;
			let xValue = e.offsetX;
			if (mainObjectData) {
				const widthRatio = mainObjectData.width / objectsTabCanvasRef.current.clientWidth;
				const heightRatio = mainObjectData.height / objectsTabCanvasRef.current.clientHeight;
				const mousePosX = Math.round(e.offsetX * widthRatio);
				const mousePosY = Math.round(e.offsetY * heightRatio);
				const x = mousePosX;
				const y = mousePosY;
				const r = brushConfig.lineWidth > 25 ? brushConfig.lineWidth - 20 : brushConfig.lineWidth;
				if (lockMasks) {
					for (let i = x - r; i <= x + r; i++) {
						for (let j = y - r; j <= y + r; j++) {
							let distance = Math.sqrt((i - x) ** 2 + (j - y) ** 2);
							if (distance <= r) {
								const pixelIndex = (j * mainObjectData.width + i) * 4;
								const rValue = mainObjectData.data[pixelIndex];
								const gValue = mainObjectData.data[pixelIndex + 1];
								const bValue = mainObjectData.data[pixelIndex + 2];
								if (rValue && gValue && bValue && rValue !== 0 && gValue !== 0 && bValue !== 0) {
									// console.log(
									// 	"Pixel coordinate inside the circle: ({i}, {j})",
									// 	rValue,
									// 	gValue,
									// 	bValue
									// );
									objectsTabCanvasRef.current.removeEventListener("mousemove", drawLine);
									return;
								}
							}
						}
					}
				}
			}

			updateCoordinates(e);
			pathRef.current.lineTo(xValue, yValue);
			overlayContextRef.current.stroke(pathRef.current);
		},
		[updateCoordinates, mainObjectData, brushConfig, lockMasks]
	);
	const drawEnd = useCallback(() => {
		isDrawingRef.current = false;
		pathRef.current = null;
		objectsTabCanvasRef.current.removeEventListener("mousemove", drawLine);
		objectsTabCanvasRef.current.removeEventListener("mouseup", drawEnd);
	}, [drawLine, mainObjectData, lockMasks, brushConfig, objectsTabCanvasRef]);

	const drawStart = useCallback(
		(e) => {
			isDrawingRef.current = true;
			updateCoordinates(e);
			pathRef.current = new Path2D();
			pathRef.current.moveTo(previousCoordinatesRef.current[0], previousCoordinatesRef.current[1]);
			objectsTabCanvasRef.current.addEventListener("mousemove", drawLine);
			objectsTabCanvasRef.current.addEventListener("mouseup", drawEnd);
			if (overlayContextRef.current) {
				overlayContextRef.current.strokeStyle = context.current?.strokeStyle;
				overlayContextRef.current.lineWidth = context.current.lineWidth;
				overlayContextRef.current.lineCap = context.current?.lineCap;
				overlayContextRef.current.lineJoin = context.current.lineJoin;
				overlayContextRef.current.globalAlpha = context.current.globalAlpha;
				overlayContextRef.current.globalCompositeOperation = context.current.globalCompositeOperation;
			}
			return () => {
				if (objectsTabCanvasRef.current) {
					objectsTabCanvasRef.current.removeEventListener("mousemove", drawLine);
					objectsTabCanvasRef.current.removeEventListener("mouseup", drawEnd);
				}
			};
		},
		[drawEnd, drawLine, mainObjectData, brushConfig, lockMasks, overlayContextRef.current, context.current]
	);

	const drawCursor = useCallback(
		(e) => {
			let cursor = document.querySelector("#customBrushCursor") as HTMLElement;
			if (cursor) {
				cursor.style.display = "block";
				cursor.style.transform = `translate3d(${e.offsetX - brushConfig.lineWidth / 2}px, ${
					e.offsetY - brushConfig.lineWidth / 2
				}px, 0)`;
				objectsTabCanvasRef.current.addEventListener("mouseout", () => {
					cursor.style.display = "none";
				});
			}
		},
		[brushConfig, objectsTabCanvasRef, brushConfig, mainObjectData, lockMasks]
	);

	const handleMouseEnter = useCallback(() => {
		objectsTabCanvasRef.current.addEventListener("mousemove", drawCursor);
	}, [drawCursor, objectsTabCanvasRef, mainObjectData, brushConfig, lockMasks]);

	useEffect(() => {
		if (objectsTabCanvasRef.current) {
			objectsTabCanvasRef.current.addEventListener("mousedown", drawStart);
			objectsTabCanvasRef.current.addEventListener("mouseenter", handleMouseEnter);
		}

		return () => {
			if (objectsTabCanvasRef.current) {
				objectsTabCanvasRef.current.removeEventListener("mousedown", drawStart);
				objectsTabCanvasRef.current.removeEventListener("mouseenter", handleMouseEnter);
			}
		};
	}, [objectsTabCanvasRef, mainObjectData, brushConfig, lockMasks, drawStart, handleMouseEnter]);

	useEffect(() => {
		if (objectsTabCanvasRef.current) {
			const overlayCanvas = document.createElement("canvas");
			overlayCanvas.width = objectsTabCanvasRef.current.width;
			overlayCanvas.height = objectsTabCanvasRef.current.height;
			overlayCanvas.style.position = "absolute";
			overlayCanvas.style.top = "0";
			overlayCanvas.style.left = "0";
			overlayCanvas.style.pointerEvents = "none";
			overlayCanvas.style.width = objectsTabCanvasRef.current.style.width;
			overlayCanvas.style.height = objectsTabCanvasRef.current.style.height;
			overlayCanvas.style.opacity = context?.current?.brushColorOpacity;
			lockMasks && (overlayCanvas.style.backgroundColor = "#1A06384D");
			objectsTabCanvasRef.current.parentElement.appendChild(overlayCanvas);
			brushOverlayCanvasRef.current = overlayCanvas;
			overlayContextRef.current = overlayCanvas.getContext("2d");
		}

		return () => {
			if (brushOverlayCanvasRef.current && brushOverlayCanvasRef.current.parentElement) {
				brushOverlayCanvasRef.current.parentElement.removeChild(brushOverlayCanvasRef.current);
			}
		};
	}, [objectsTabCanvasRef, lockMasks]);

	return null;
};
