import { Box, Typography } from "@material-ui/core";
import { parseXmpFileContent_parseXmpFileContent_mood } from "../../../../../GraphQL/types/parseXmpFileContent";
import { apiMoodSchema, mapObjectBySchema } from "../../../Mood/data/sliders";
import { invertObj } from "../../../../../Helpers/mapper";
import { parseXmpFileContent } from "../../../../../GraphQL/mutations";
import client from "../../../../../GraphQL/client";
import {
	SELECTED_PREDEFINED_MOOD,
	XMP_FILE,
	XMP_FILE_INFO,
} from "../../ColorPallete/ColorPallete";
import { useContext, useRef, useState } from "react";
import { BrandContext } from "../../BrandPage/BrandPage";
import { brandSteps } from "../../steps";

export default function XMPUpload() {
	const [lcoalFile, setLocalFile] = useState<any>(null);
	const inputRef = useRef<HTMLInputElement | null>();
	const { updateBrandState, updateGeneralState, navigate } =
		useContext(BrandContext);

	const parseXmpContent = async (
		res: parseXmpFileContent_parseXmpFileContent_mood | undefined | null,
		fileInfo: any
	) => {
		const { moodParameters } = res || {};
		const mappedVersion = mapObjectBySchema(
			moodParameters,
			invertObj(apiMoodSchema)
		);

		updateBrandState(mappedVersion, "moodSliders");

		const xmpFileInfo = {
			id: XMP_FILE,
			moodId: XMP_FILE,
			name: fileInfo?.name,
			moodParameters: mappedVersion,
			// imageRes: xmpImageRes,
		};
		updateGeneralState(XMP_FILE, fileInfo?.name, {
			[XMP_FILE]: fileInfo?.name, // to store xmp info for color pallet step
			[XMP_FILE_INFO]: xmpFileInfo, // to store xmp info for color pallet step
			[SELECTED_PREDEFINED_MOOD]: XMP_FILE, // to default selected predefined mood to xmp file
		});
	};

	const onXmpFileSelect = () => {
		const file: any = inputRef.current?.files?.[0];
		let reader = new FileReader();
		setLocalFile(file);
		reader.readAsText(file);
		reader.onload = async function () {
			const res = await parseXmpFileContent(reader.result as any, client);
			parseXmpContent(res, file);
			navigate(brandSteps().length - 2);
		};
		reader.onerror = function (error) {
			console.error(error);
		};
	};

	return (
		<Box>
			<Typography
				style={{
					fontSize: 14,
					color: "#A4A4A4",
					fontWeight: 400,
					textAlign: "center",
					marginTop: "2vh",
					marginBottom: "-2vh",
				}}
			>
				You can skip this if you have an
				<br /> xmp file
				<span
					onClick={() => inputRef.current?.click()}
					style={{
						fontWeight: "bold",
						textDecoration: "underline",
						marginLeft: 4,
						cursor: "pointer",
					}}
				>
					upload
				</span>
			</Typography>
			<input
				style={{ display: "none" }}
				type="file"
				ref={(el) => (inputRef.current = el)}
				onChange={onXmpFileSelect}
			/>
			{/*{(lcoalFile || savedFile) && (*/}
			{/*	<Box mt="2rem">*/}
			{/*		<InlineAlert*/}
			{/*			msg="Your file was successfully uploaded"*/}
			{/*			type="info"*/}
			{/*		/>*/}
			{/*	</Box>*/}
			{/*)}*/}
		</Box>
	);
}
