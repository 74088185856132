import { Box, LinearProgress, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Icon from "../../../Common/Icon/Icon";
import CustomizedTooltip from "../../../UI/Tooltip";

function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

interface IFileUpload {
	isLogoTab?: boolean;
	renderFile?: (file: any) => JSX.Element;
	dragText: string;
	onDrop?: (fileInfo: any, file?: File) => void;
	file?: any;
	fileType?: string;
	keepPlaceholder?: boolean;
	hideFileOnDrop?: boolean;
	loading?: boolean;
}

export default function FileUpload({
	renderFile,
	dragText,
	isLogoTab,
	onDrop,
	file: passedFile,
	fileType = "PNG, JPG or WEBP",
	keepPlaceholder,
	hideFileOnDrop,
	loading,
}: IFileUpload) {
	const [file, setFile] = useState<any>(passedFile);
	const classes = useStyles({ isLogoTab });

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			if (!acceptedFiles.length) return;
			const [firstFile] = acceptedFiles;
			const { name, type } = firstFile;
			const withUrl = {
				...firstFile,
				preview: URL.createObjectURL(firstFile),
				base64: await getBase64(firstFile),
				name,
				type,
			};
			onDrop?.(withUrl, firstFile);
			!hideFileOnDrop && setFile(withUrl);
		},
	});

	useEffect(() => {
		if (!passedFile) return;
		setFile(passedFile);
	}, [passedFile]);
	return (
		<Box
			{...getRootProps()}
			className={clsx(classes.dropzone, file && "dropZoneFull")}
		>
			{loading && (
				<Box width="100%" position="absolute" top="0">
					<LinearProgress />
				</Box>
			)}
			<input {...getInputProps()} />
			<>
				{file && renderFile && (
					<Box
						style={{
							display: "flex",
							height: "100%",
							position: "relative",
							justifyContent: "center",
						}}
					>
						<CustomizedTooltip title={<>Replace</>} placement="top">
							<Box className={classes.editIcon}>
								<Icon name="edit-icon" />
							</Box>
						</CustomizedTooltip>
						{renderFile(file)}
					</Box>
				)}
				{(!file || keepPlaceholder) && (
					<>
						<Box>
							<Typography
								className={clsx(classes.dropzoneText, "bold")}
							>
								{dragText}
							</Typography>
							<Box className={classes.orUploadTextWrapper}>
								<Typography className={classes.dropzoneText}>
									Drag and drop your logo or
								</Typography>
								<Typography
									className={clsx(
										classes.dropzoneText,
										"upload"
									)}
								>
									upload
								</Typography>
								<Typography className={classes.dropzoneText}>
									from your computer
								</Typography>
							</Box>
						</Box>
						<Box className={classes.bottomText}>
							{!!fileType && (
								<Typography
									className={clsx(
										classes.dropzoneText,
										"small"
									)}
								>
									Supported File types <br />
									{fileType}
								</Typography>
							)}
						</Box>
					</>
				)}
			</>
		</Box>
	);
}
interface StyleProps {
	isLogoTab: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
	dropzone: {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		marginTop: "2.5rem",
		outlineOffset: "14px",
		flexDirection: "column",
		gap: "1rem",
		aspectRatio: "3/1",
		height: "24vh",
		"&:not(.dropZoneFull)": {
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DDDDDDFF' stroke-width='3' stroke-dasharray='4%2c 11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
		},
	},
	orUploadTextWrapper: {
		display: "flex",
		gap: 3,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "0.5rem",
	},
	dropzoneText: {
		fontSize: "12px",
		color: "#A4A4A4",
		textAlign: "center",
		"&.small": {
			fontSize: 10,
		},
		"&.bold": {
			fontWeight: 600,
			marginBottom: "1.3rem",
		},
		"&.upload": {
			color: "#D80067",
		},
	},
	bold: {
		fontWeight: "bold",
	},
	logoImg: {
		width: "95%",
		height: "95%",
		objectFit: "cover",
	},
	bottomText: (props) => ({
		position: "relative",
		bottom: props.isLogoTab ? "-23%" : "-16%",
	}),
	editIcon: {
		position: "absolute",
		top: "4%",
		right: "4%",
	},
}));
