import { Box, ClickAwayListener, Input, Theme, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";
import CircularLoadingTooltip from "../../UI/LoadingTooltip";
import Icon from "../Icon/Icon";
import CheckIcon from "../../../assets/icons/CheckIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Context from "../../../Context/Context";
import { IsIframe } from "../../../Helpers/iframe";

export interface IOption {
	title: string;
	id: string;
	renderOption?: (option: IOption) => JSX.Element | string;
	textAtSelected?: string; // how it's gonna look like when it's selected
	onClick?: () => void;
	style?: React.CSSProperties;
	disabled?: boolean;
	brandDropDown?: boolean;
}

interface StyleProps {
	expanded: boolean;
	backgroundColor: string;
	borderRadius: string;
	border: string;
	color: string;
	height: string;
	iconRight: string;
	borderStyle: string;
	paddingInline: string;
	iconOpacity: string;
	type: string;
	fontFamily?: string;
	width?: string;
	expandDirection: "top" | "bottom";
	disabled?: boolean;
	CampainDropdownElemStyle?: boolean;
	maxHeight?: string;
}

interface IDropDown {
	unifyDisplayTextFontWeight?: boolean;
	expandDirection?: "top" | "bottom";
	maxDisplayedTextLength?: number;
	options: IOption[];
	extraOptions?: IOption[];
	selectedOption?: IOption;
	bottomOption?: IOption;
	onChange: (option: IOption) => void;
	placeholder: string;
	defaultSelected: string;
	onDefaultClick?: (option: IOption) => void;
	addDefaultButton?: boolean;
	applyMaxDisplayedTextOnPlaceholder?: boolean;
	backgroundColor?: string;
	borderRadius?: string;
	border?: string;
	color?: string;
	height?: string;
	iconRight?: string;
	borderStyle?: string;
	paddingInline?: string;
	iconOpacity?: string;
	hideDropdown?: boolean;
	isSearchable?: boolean;
	type?: string;
	fontFamily?: string;
	width?: string;
	loading?: boolean;
	isGallery?: boolean;
	disabled?: boolean;
	fontWeight?: any;
	isFullWidth?: boolean;
	CampainDropdownElemStyle?: boolean;
	additionalElement?: any;
	fontSize?: any;
	optionsScroll?: boolean;
	maxHeight?: string;
}

export default function DropDown({
	unifyDisplayTextFontWeight = false,
	expandDirection = "bottom",
	hideDropdown = false,
	maxDisplayedTextLength,
	applyMaxDisplayedTextOnPlaceholder,
	options,
	onChange,
	selectedOption: passedSelectedOption,
	placeholder,
	defaultSelected,
	extraOptions = [],
	bottomOption,
	onDefaultClick,
	addDefaultButton = false,
	isSearchable = false,
	backgroundColor = "",
	borderRadius = "4px",
	border = "1px solid rgb(164, 164, 164)",
	color = "black",
	height = "auto",
	iconRight = "5%",
	borderStyle = "solid",
	paddingInline = "1rem",
	iconOpacity = "1.0",
	type = "",
	width = "",
	fontFamily,
	loading = false,
	isGallery,
	disabled = false,
	fontWeight = 500,
	isFullWidth = false,
	CampainDropdownElemStyle = false,
	optionsScroll = false,
	fontSize = "",
	additionalElement,
	maxHeight = "",
}: IDropDown) {
	const { isIframe } = IsIframe();

	const context = useContext(Context);
	const [expanded, setExpanded] = useState(false);
	const placeholderOption = {
		title: placeholder,
		id: "",
	};
	const [selectedOption, setSelectedOption] = useState<IOption>(placeholderOption);
	const [defaultHoveredIndex, setDefaultHoveredIndex] = useState<number | null>(null);

	const classes = useStyles({
		expandDirection,
		expanded,
		backgroundColor,
		borderRadius,
		border,
		color,
		height,
		iconRight,
		borderStyle,
		paddingInline,
		iconOpacity,
		type,
		fontFamily,
		width,
		disabled,
		CampainDropdownElemStyle,
		maxHeight,
	});

	const maxOptionLength = 0;
	const generatedTextForMaxWordLength = Array(
		(applyMaxDisplayedTextOnPlaceholder && maxDisplayedTextLength ? maxDisplayedTextLength : maxOptionLength) + 3
	) // since bottom list is position absolute and it's width is inherited from the displayed text, you should expand it as the max option length
		.fill("a")
		.join("");

	const onClick = (option: IOption) => {
		if (!option.disabled) {
			if (option && option?.brandDropDown) {
				context.setSelectedDropDownValue(option);
			}

			setSelectedOption(option);
			onChange(option);
		}
	};

	const handleClose = () => setExpanded(false);

	const toggleExpand = () => setExpanded(!expanded);
	const [searchValue, setSearchValue] = useState("");
	const searchRef = useRef();
	const theme = useTheme();

	useEffect(() => {
		setSearchValue("");
	}, [expanded]);

	const getOptions = () => {
		if (!searchValue) {
			return [...extraOptions, ...options, bottomOption];
		}
		return [...extraOptions, ...options, bottomOption].filter(
			(
				option = {
					id: "",
					title: "",
				}
			) => option.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
		);
	};
	const displayedText =
		"textAtSelected" in selectedOption
			? selectedOption?.textAtSelected || placeholder
			: selectedOption?.title || placeholder;

	const isSelectedOptin = (option: IOption) => option.id === selectedOption.id;

	useEffect(() => {
		if (!passedSelectedOption) return;
		setSelectedOption(passedSelectedOption);
	}, [passedSelectedOption?.id]);

	const filterEmptyOptions = (option: IOption | undefined) => !!option;
	const getDisplayedText = () => {
		return (
			<Box className={classes.displayedTextStyle}>
				{unifyDisplayTextFontWeight ? (
					<Typography
						style={{
							fontWeight: fontWeight,
							fontSize: fontSize ? fontSize : "min(1.4vw,16px)",
							fontFamily: fontFamily,
						}}
					>
						{displayedText}
					</Typography>
				) : (
					<>
						<Typography
							style={{ fontWeight: fontWeight, fontSize: fontSize ? fontSize : "min(1.4vw,16px)" }}
						>
							{displayedText.substring(0, displayedText.indexOf(":"))}
						</Typography>
						<Typography style={{ fontSize: fontSize ? fontSize : "min(1.4vw,16px)" }}>
							{displayedText.substring(displayedText.indexOf(":"), displayedText.length)}
						</Typography>
					</>
				)}
			</Box>
		);
	};
	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box
				className={clsx({ [classes.hide]: hideDropdown }, classes.dropdownWrapper, "dropdownWrapper")}
				onClick={toggleExpand}
			>
				<Box className={classes.displayedText}>
					{maxDisplayedTextLength
						? displayedText.length > maxDisplayedTextLength
							? displayedText.substring(0, maxDisplayedTextLength) + "..."
							: getDisplayedText()
						: getDisplayedText()}
				</Box>
				<Box
					className={clsx(classes.icon, {
						[classes.rotate]: expanded,
					})}
				>
					{loading ? (
						<Box style={{ marginRight: "7px" }}>
							<CircularLoadingTooltip key="" tab="" />
						</Box>
					) : isFullWidth ? (
						<KeyboardArrowDownIcon style={{ color: "#1A0638" }} />
					) : (
						<Icon color="#1A0638" name="arrow-down" />
					)}
				</Box>
				<Box className={classes.hiddenMockText}>{generatedTextForMaxWordLength}</Box>
				{expanded && (
					<Box
						sx={{ boxShadow: 2 }}
						className={`${classes.dropdownBody} ${isFullWidth ? classes.fullWidth : ""} ${
							optionsScroll ? classes.optionsScroll : ""
						}`}
					>
						{isSearchable && (
							<Box className={classes.searchBox}>
								<Input
									className={classes.searchBoxInput}
									onChange={(e) => {
										setSearchValue(e.target.value);
									}}
									value={searchValue}
									ref={searchRef}
									autoFocus
								/>
							</Box>
						)}
						{getOptions()
							.filter(filterEmptyOptions)
							.map(
								(
									option = {
										id: "",
										title: "",
									},
									index
								) => (
									<Box
										onClick={() => (option?.onClick ? option?.onClick() : onClick(option))}
										className={clsx(classes.dropdownElem, {
											[classes.selectedOption]: isSelectedOptin(option),
											[classes.disabledOption]: option.disabled,
											[classes.seprationBorder]:
												option.title !== "Art" &&
												option.title !== "Photography" &&
												isGallery &&
												index == 2,
											noHover: index === defaultHoveredIndex,
											[classes.CampainDropdownElemStyle]: CampainDropdownElemStyle,
										})}
										style={{ fontSize: "min(1.4vw, 14px)" }}
										{...option}
									>
										<Box className={classes.relative}>
											<Box
												style={{ fontWeight: CampainDropdownElemStyle && fontWeight }}
												className={classes.titleWrapper}
											>
												{isSelectedOptin(option) && (
													<Box
														className={clsx(classes.checkIcon, {
															[classes.disabled]: option.disabled,
														})}
													>
														{/* <Icon name="purple-check-icon" /> */}
														<CheckIcon color={theme.palette.primary.dark} />
													</Box>
												)}

												{option.renderOption ? (
													option.renderOption(option)
												) : (
													<span
														className={clsx({
															[classes.itemWithDefault]: addDefaultButton && option.id,
															[classes.disabled]: option.disabled,
														})}
													>
														{option.title}
													</span>
												)}
											</Box>
											<Box
												onClick={(event) => {
													event.stopPropagation();
													onDefaultClick?.(option);
												}}
												onMouseEnter={() => setDefaultHoveredIndex(index)}
												onMouseLeave={() => setDefaultHoveredIndex(null)}
												className={clsx(
													classes.defaultTextStyle,
													{
														[classes.fullOpacity]: defaultSelected === option.id,
													},
													{
														[classes.hide]: !addDefaultButton,
													}
												)}
											>
												{!option.id ? "" : "Default"}
											</Box>
										</Box>
									</Box>
								)
							)}
						{((context?.user?.organizations?.length ?? 0) > 0 ||
							(isIframe() && context?.iframeConfig?.organization)) &&
							additionalElement}
					</Box>
				)}
			</Box>
		</ClickAwayListener>
	);
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	hide: {
		display: "none !important",
	},
	dropdownWrapper: (props) => ({
		height: props.height,
		border:
			props.expanded && props.CampainDropdownElemStyle ? "2px solid " + theme.palette.primary.dark : props.border,
		borderStyle: props.borderStyle,
		borderRadius: props.borderRadius,
		borderTopRightRadius: props.type === "search" ? 0 : props.borderRadius,
		borderBottomRightRadius: props.type === "search" ? 0 : props.borderRadius,
		display: "flex",
		justifyContent: "space-between",
		paddingInline: props.paddingInline,
		alignItems: "center",
		cursor: "pointer",
		position: "relative",
		backgroundColor: props.backgroundColor,
		"&::selection": {
			backgroundColor: "transparent",
		},
		width: props.width ? props.width : "fit-content",
		minWidth: props.width ? props.width : "unset",
		pointerEvents: props.disabled ? "none" : "unset",
		opacity: props.disabled ? 0.38 : 1,
		"&:active, &:focus": {
			border: props.CampainDropdownElemStyle ? "2px solid " + theme.palette.primary.dark : "",
		},
	}),
	relative: {
		position: "relative",
	},
	displayedTextStyle: (props) => ({
		display: "flex",
		color: props.color,
	}),
	defaultTextStyle: {
		position: "absolute",
		right: "0",
		top: "50%",
		transform: "translateY(-50%)",
		opacity: 0.4,
		color: "#8800FF",
	},
	fullOpacity: {
		opacity: "1 !important",
	},
	hiddenMockText: {
		opacity: 0,
		display: "none",
	},
	displayedText: (props) => ({
		// position: "absolute",
		left: "1rem",
		top: "50%",
		// transform: "translateY(-50%)",
		zIndex: 2,
		whiteSpace: "pre",
		color: props.color,
		textTransform: !props.CampainDropdownElemStyle ? "capitalize" : "none",
	}),
	dropdownElem: {
		backgroundColor: "#fff",
		padding: "0.5rem 1.4rem",
		color: " rgba(26, 6, 56, 0.5)",
		textTransform: "capitalize",

		"&:hover:not(.noHover)": {
			backgroundColor: "#ddd",
			transition: "all 0.1s ease-in-out",
			color: theme.palette.primary.dark,
		},
		"&::selection": {
			backgroundColor: "transparent",
		},
		whiteSpace: "normal",
		"& $defaultTextStyle": {
			opacity: 0,
		},
		"&:hover": {
			"& $defaultTextStyle": {
				opacity: "0.2",
			},
		},
	},
	CampainDropdownElemStyle: {
		color: "#1A0638",
		textTransform: "capitalize",
		padding: "14px 24px",

		"&:hover:not(.noHover)": {
			backgroundColor: "#5300C90D",
			color: "#1A0638",
		},
	},
	dropdownBody: (props) => ({
		position: "absolute",
		top: props.expandDirection === "top" ? "auto" : "120%",
		bottom: props.expandDirection === "top" ? "110%" : "auto",
		left: 0,
		padding: "8px 0",
		boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.08)",
		border: "1px solid rgba(231, 231, 231, 1)",
		backgroundColor: "#FFFFFF",
		borderRadius: 8,
		zIndex: 1000,
		boxShadow: "20px 20px 20px 0px rgba(37, 3, 85, 0.1)",
	}),
	selectedOption: {
		backgroundColor: `${theme.palette.primary.dark}0D`,
		zIndex: 10,
		color: "#1A0638",
		"& span": {
			fontWeight: "bold",
			opacity: 1,
		},
	},
	seprationBorder: {
		borderTop: "1px solid #B3B3B9",
	},
	icon: (props) => ({
		// position: "absolute",
		right: props.iconRight,
		top: "50%",
		// transform: "translateY(-50%)",
		marginLeft: "0.42vw",
		color: "#1A0638",
		display: "flex",
		justifyContent: "end",
		opacity: props.iconOpacity,
	}),
	rotate: {
		transform: "rotate(180deg)",
		justifyContent: "start !important",
	},
	checkIcon: {
		position: "absolute",
		// left: "5%",
		// top: "50%",
		transform: "translateX(-18px)",
	},
	titleWrapper: {
		display: "flex",
		// justifyContent: "center",
		alignItems: "center",
	},
	itemWithDefault: {
		width: "77%",
	},
	searchBox: {
		backgroundColor: "rgb(0 0 0 / 5%)",
	},
	searchBoxInput: {
		width: " 100%",
		padding: "0.5rem 1.5rem",
		boxSizing: "border-box",
		borderRadius: "5px",
	},
	disabled: {
		opacity: 0.3,
	},
	disabledOption: {
		cursor: "default",
		pointerEvents: "none",
		background: "rgba(26, 6, 56, 0.05)",
	},
	fullWidth: {
		width: "100%",
	},
	optionsScroll: (props) => ({
		maxHeight: props.maxHeight ? props.maxHeight : "100px",
		overflowY: "auto",
	}),
	plusIcon: {
		marginRight: "5px",
	},
	lastOptionButtonText: {
		color: theme.palette.primary.dark,
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "16px",
	},
}));
