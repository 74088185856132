import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import RouterConstants from "../Constants/RouterConstants";
import {
	getAuth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	confirmPasswordReset,
	sendEmailVerification,
	checkActionCode,
	applyActionCode,
} from "firebase/auth";
import Context from "../Context/Context";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";

export const useLoginService = () => {
	const [errorMessage, setErrorMessage] = React.useState<boolean | string>(false);
	const history = useHistory();
	const { user, systemView } = useContext(Context);
	const context = useContext(Context);

	const loginWithEmail = async (email: string, password: string, onSuccessCallback?: () => void) => {
		signInWithEmailAndPassword(getAuth(), email, password)
			.then(async () => {
				context.setAuthDialogState(false);
				if (onSuccessCallback) {
					onSuccessCallback();
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	};

	const resetPassword = async (email: string) => {
		return sendPasswordResetEmail(getAuth(), email)
			.then(() => {
				setErrorMessage(false);
			})
			.catch((error) => {
				setErrorMessage(error.message);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			});
	};

	const confirmPasswordResetFunction = (code: string, newPassword: string) => {
		return confirmPasswordReset(getAuth(), code, newPassword)
			.then((res) => res)
			.catch((error) => {
				setErrorMessage(error.code);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			});
	};

	const sendEmailVerificationFunction = () => {
		sendEmailVerification(getAuth().currentUser!)
			// 	{
			// 	handleCodeInApp: true,
			// 	url: "http://localhost:3000/gallery",
			// }
			.then(() => {})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	};

	const verifyUser = (code: string) => {
		return checkActionCode(getAuth(), code)
			.then((res) => {
				return applyActionCode(getAuth(), code);
			})
			.then(() => {
				history.push(RouterConstants.HOME.path);
			});
	};

	return {
		loginWithEmail,
		resetPassword,
		confirmPasswordResetFunction,
		sendEmailVerificationFunction,
		verifyUser,
		errorMessage,
	};
};
