import React, { useContext, useState } from "react";
import { BrandContext, ISliderState } from "../BrandPage/BrandPage";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import TitleTexts from "../TitleTexts/TitleTexts";
import MoodSlider from "./MoodSlider/MoodSlider";
import { MOOD_SLIDER_DEFAULT_VALUE } from "./moodList";
import { mainImgInfo } from "../BrandPage/ImgViewer/ImgViewer";
import { allowedColors } from "../../Mood/data/colors";
import { moodSlidersValueRange } from "../../Mood/data/sliders";
import { useMultiPromise } from "../../../../hooks/useMultiPromises";

const moodParametersValueRange: {
	[key: string]: { minValue: number; maxValue: number };
} = moodSlidersValueRange as any;

const minMax = (value: number, range: { minValue: number; maxValue: number }) =>
	value ? (range ? Math.min(Math.max(value, range.minValue), range.maxValue) : value) : 0;

const incrementMood = (moodSliders: any, percentage: number) => {
	return Object.entries(moodSliders).reduce(
		(acc: any, [key, value]: [string, any]) => ({
			...acc,
			[key]: minMax(value * percentage, moodParametersValueRange?.[key]),
		}),
		{}
	);
};

const avgSelectedMoods = (selectedMoods: any) => {
	// calculate average of all selected filters
	const parametersAcc: { [key: string]: any[] } = {};
	Object.values(selectedMoods).forEach((mood) => {
		Object.entries((mood as any).moodParameters).forEach(([name, value]) => {
			parametersAcc[name] = parametersAcc[name] || [];
			parametersAcc[name].push(value);
		});
	});
	const parametersAvg: { [key: string]: any } = {};
	Object.entries(parametersAcc).forEach(
		([key, value]) => (parametersAvg[key] = value.reduce((acc, cur) => acc + cur, 0) / value.length)
	);

	return {
		color_mixer: allowedColors.map((color) => ({
			color: color.toLowerCase(),
			hue: minMax(parametersAvg[color.toLowerCase()] || 0, moodParametersValueRange["hue"]),
		})),
		color: {
			vibrance: minMax(parametersAvg.vibrance, moodParametersValueRange["vibrance"]),
			saturation: minMax(parametersAvg.saturation, moodParametersValueRange["saturation"]),
		},
		light: {
			brightness: minMax(parametersAvg.brightness, moodParametersValueRange["brightness"]),
			contrast: minMax(parametersAvg.contrast, moodParametersValueRange["contrast"]),
		},
	};
};

interface IMood {
	value: number;
	name: string;
	image: any;
	moodParameters: ISliderState;
}

export default function SingleMood({ mood }: { mood: IMood[] }) {
	const { updateBrandState, brandState, generalState, updateGeneralState } = useContext(BrandContext);
	const classes = useStyles();
	const moodKey = `${mood[0].name}-${mood[1].name}`;
	const defaultMoodImg = mainImgInfo.src;
	const [applyMoodToImgs] = useMultiPromise();
	const [value, setValue] = useState((generalState as any)?.selectedMoods?.[moodKey]?.value || 0);
	const [moodsImages, setMoodsImages] = useState<string[]>();
	const [loading, setLoading] = useState(false);

	const flatMoodsParameters = mood.map((obj) => {
		let flat = {};
		if (obj.moodParameters.light) flat = { ...flat, ...obj.moodParameters.light };
		if (obj.moodParameters.color) flat = { ...flat, ...obj.moodParameters.color };
		if (obj.moodParameters.color_mixer) {
			obj.moodParameters.color_mixer?.map((color) => {
				flat = { ...flat, [color.color]: color.hue };
			});
		}
		return flat;
	});

	const addMood = (originalMoodsObj: any, selectedMood: any, selectedMoodValue: number, incPercentage: number) => ({
		...(originalMoodsObj || {}),
		[moodKey]: {
			value: selectedMoodValue,
			moodParameters: incrementMood(selectedMood, incPercentage),
		},
	});

	const onChange = (e: any, v: number) => {
		setValue(v);

		// generate average selected moods and store selected moods map plus the average
		const selectedMood = v > MOOD_SLIDER_DEFAULT_VALUE ? flatMoodsParameters[1] : flatMoodsParameters[0];
		const selectedMoods = addMood((generalState as any)?.selectedMoods, selectedMood, v, Math.abs(v) / 100);
		updateBrandState(avgSelectedMoods(selectedMoods), "moodSliders");

		// generate 2 suggestions for results page and keep them in general state
		const selectedMoodsSuggestion1 = addMood(
			(generalState as any)?.selectedMoodsSuggestion1,
			selectedMood,
			v,
			Math.min(Math.max(value - 25, 0), 100)
		);
		const selectedMoodsSuggestion2 = addMood(
			(generalState as any)?.selectedMoodsSuggestion2,
			selectedMood,
			v,
			Math.min(Math.max(value + 25, 0), 100)
		);

		updateGeneralState("", "", {
			selectedMoods,
			selectedMoodsSuggestion1,
			moodSlidersSuggestion1: avgSelectedMoods(selectedMoodsSuggestion1),
			selectedMoodsSuggestion2,
			moodSlidersSuggestion2: avgSelectedMoods(selectedMoodsSuggestion2),
			originalMoodSliders: selectedMood,
		});
	};

	return (
		<Box className={classes.moodRoot}>
			<TitleTexts passedClassName={classes.brandName} subText={(brandState as any)?.name} />
			<TitleTexts passedClassName={classes.titleTexts} text="Is your brand more..." />
			<Box className={classes.moodSliderWrapper}>
				<MoodSlider
					key={"springAutumn"}
					moods={mood}
					onChange={onChange}
					value={value}
					defaultMoodImg={defaultMoodImg}
					moodsImages={moodsImages}
					loading={loading}
				/>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	brandName: {
		color: "#A4A4A4",
		padding: "5vh 0 5vh",
		"& p": {
			fontWeight: 400,
			fontSize: "24px",
		},
	},
	titleTexts: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
	},
	moodRoot: {
		maxWidth: "calc(100% - 12.5%)",
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
	},
	moodSliderWrapper: {},
}));
