/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardEvent, useContext, useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import WandIcon from "../../../../assets/icons/WandIcon";
import DropDown, { IOption } from "../../../Common/DropDown/DropDown";
import BriaButton, { BUTTONTYPES } from "../../BriaButton";
import ColorInput from "../../../Tools/Brand/ColorPallete/ColorInput/ColorInput";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Campaign, CREATE_CAMPAIGN_QUERY, defaultCampaign, headersPrompts } from "./utils";
import { GET_MEDIA_LAYOUTS, GET_USER_BRANDS } from "../../../../GraphQL/queries";
import { getUserBrands, getUserBrandsVariables } from "../../../../GraphQL/types/getUserBrands";
import { getMediaLayouts, getMediaLayoutsVariables } from "../../../../GraphQL/types/getMediaLayouts";
import CampaignAssets from "./CampaignAssets";
import CloseIcon from "@material-ui/icons/Close";
import useErrorPopup from "../../../../hooks/useErrorPopup";
import BriaConfirmationDialog from "../../../BriaConfirmationDialog";
import CampaignPreview from "./CampaignPreview";
import ReplayIcon from "@material-ui/icons/Replay";
import CampaignCheckboxes from "./CampaignCheckboxes";
import CampaignBackground from "./CampaignBackground";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import Context from "../../../../Context/Context";
import { getOrganizationGalleryId } from "../../../Tools/utilities";
import { getRandomText } from "../../../../Constants/RandomTextGenerator";
import { DISABLE_ENTER_LANGUAGES } from "../../../../Constants";
import { IsIframe } from "../../../../Helpers/iframe";
import { useTranslation } from "react-i18next";
import { translateText } from "../../../../GraphQL/mutations";
import i18n from "i18next";
import { getAuth } from "firebase/auth";

type Props = {
	open: boolean;
	campaignName?: string;
	productImage?: string;
	visualHash?: string;
	handleClose: () => void;
};

const CreateCampaignDialog = ({
	open,
	campaignName = "Generated Campaign",
	productImage = "",
	visualHash = "",
	handleClose,
}: Props) => {
	const { t } = useTranslation();
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	const [brandOptions, setBrandOptions] = useState<IOption[]>([
		{ id: "loading_brands", title: "Loading Brands...", disabled: true },
	]);
	const mediaOptions: IOption[] = [
		{ id: "1", title: "Google Display Ads" },
		{ id: "2", title: "Facebook Ads" },
		{ id: "3", title: "Linkedin Ads" },
		{ id: "4", title: "Amazon Product Images" },
	];
	const [selectedBrand, setSelectedBrand] = useState<any>();
	const [selectedMedia, setSelectedMedia] = useState<any>();

	const [loadBrandsQuery, { loading: loadingBrands, error: errorBrands, data: userBrands }] =
		useLazyQuery<getUserBrands, getUserBrandsVariables>(GET_USER_BRANDS);
	const [loadMediaLayouts, { loading: loadingTemplate, error: errorTemplate, data: mediaLayouts }] = useLazyQuery<
		getMediaLayouts,
		getMediaLayoutsVariables
	>(GET_MEDIA_LAYOUTS, { variables: { media: selectedMedia?.title, brandId: selectedBrand?.id } });
	const [generateCampaign, { loading: loadingResults, error: errorGenerating, data: generatedCampaigns }] =
		useMutation<{ createCampaign: { images: string[]; seed: number; psds: string[] } }, { campaign: Campaign }>(
			CREATE_CAMPAIGN_QUERY
		);
	const [campaign, setCampaign] = useState<Campaign>({ ...defaultCampaign, productVisualHash: visualHash });
	const [lastCampaignForm, setLastCampaignForm] = useState<Campaign>(campaign);
	const [colorsCheckbox, setColorsCheckbox] = useState(false);
	const [brandCheckbox, setBrandCheckbox] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [showWarningDialog, setShowWarningDialog] = useState(false);
	const [incompleteFormError, setIncompleteFormError] = useState(false);
	const errorPopup = useErrorPopup();
	const classes = useStyles();
	const selectedOrganization = context.selectedOrganization;

	const loadBrandsFromDb = () => {
		let orgId = undefined;
		if (isIframe() && context.iframeConfig?.organization) {
			orgId = context.iframeConfig.organization;
		} else if (selectedOrganization) {
			orgId = selectedOrganization.uid;
		}
		if (context.user?.isAdmin()) {
			loadBrandsQuery({
				variables: {
					includeGalleries: ["*"],
					includeUserGallery: false,
				},
			});
		} else {
			if (!getAuth().currentUser?.isAnonymous && orgId) {
				getOrganizationGalleryId(context, orgId).then((galleryId: number | null | undefined) => {
					loadBrandsQuery({
						variables: {
							includeGalleries: galleryId ? ["8774", `${galleryId}`] : ["8774"],
							includeUserGallery: false,
						},
					});
				});
			} else {
				loadBrandsQuery({
					variables: {
						includeGalleries: ["8774"],
						includeUserGallery: false,
					},
				});
			}
		}
	};

	useEffect(() => {
		loadBrandsFromDb();
	}, []);

	useEffect(() => {
		if (errorBrands) {
			errorPopup.showErrorPopup(t("failedGettingBrandsTryAgain"));
		}
	}, [errorBrands]);

	useEffect(() => {
		if (userBrands?.getUserBrands) {
			const mappedOptions = userBrands.getUserBrands.map((brand) => ({ ...brand, title: brand.name }));
			setBrandOptions(mappedOptions);
		}
	}, [userBrands]);

	useEffect(() => {
		if (selectedBrand) {
			handleCampaignChange("header1", { ...campaign.header1, ...selectedBrand.header1 });
			handleCampaignChange("header2", { ...campaign.header2, ...selectedBrand.header2 });
			if (selectedBrand.logo) {
				handleCampaignChange("logo", selectedBrand.logo);
			}
			if (selectedBrand.colorPallet) {
				handleCampaignChange("colors", [...selectedBrand.colorPallet, ""]);
				setColorsCheckbox(true);
			} else {
				setColorsCheckbox(false);
			}

			setBrandCheckbox(selectedBrand?.mood !== null);
		}
	}, [selectedBrand]);

	useEffect(() => {
		if (mediaLayouts?.getMediaLayouts) {
			handleCampaignChange(
				"layouts",
				mediaLayouts.getMediaLayouts.layouts.map((layout) => layout!.id)
			);
		}
	}, [mediaLayouts]);

	useEffect(() => {
		if (errorTemplate) {
			errorPopup.showErrorPopup(t("failedFetchingMediaTryAgain"));
		}
	}, [errorTemplate]);

	useEffect(() => {
		if (selectedMedia) {
			loadMediaLayouts();
		}
	}, [selectedMedia, loadMediaLayouts]);

	const handleCampaignChange = <K extends keyof Campaign>(key: K, value: Campaign[K]) => {
		setCampaign((prevCampaign) => ({ ...prevCampaign, [key]: value }));
	};

	const handleColorChange = (color: string, index: number, remove?: boolean) => {
		const updatedColors = campaign.colors ? [...campaign.colors] : [];
		remove ? updatedColors.splice(index, 1) : (updatedColors[index] = color);
		const showAddIcon: boolean = Boolean(updatedColors[updatedColors.length - 1] && updatedColors.length < 5);
		if (showAddIcon) updatedColors.push("");
		handleCampaignChange("colors", updatedColors);
	};

	const handleEnterKey = async (event: KeyboardEvent<HTMLInputElement>) => {
		if (!DISABLE_ENTER_LANGUAGES.includes(i18n.language) && event.key === "Enter" && isFormCompleted) {
			await handleGenerate();
		}
	};

	const handleGenerate = async (requestPsd: boolean = false) => {
		try {
			if (isFormCompleted) {
				if (!selectedBrand) {
					selectRandomBrand();
				}
				setIncompleteFormError(false);
				const colorsWithoutAddIcon =
					campaign.colors.length < 5 ? campaign.colors.slice(0, -1) : campaign.colors;
				const campaignForm: Campaign = {
					...(requestPsd ? lastCampaignForm : campaign),
					sid: BriaAPI.getInstance(visualHash, context.iframeConfig).getLastApiCallSid() || undefined,
					seed:
						requestPsd && generatedCampaigns?.createCampaign?.seed
							? generatedCampaigns?.createCampaign?.seed
							: undefined,
					colors: colorsWithoutAddIcon,
					requestPsd,
				};

				let translatedCampaignForm = campaignForm;
				if (
					translatedCampaignForm.bgDescription.text &&
					isIframe() &&
					context.iframeConfig?.enableTranslation
				) {
					translatedCampaignForm.bgDescription.text =
						(await translateText(i18n.language, "en", translatedCampaignForm.bgDescription.text)) ??
						translatedCampaignForm.bgDescription.text;
				}

				await generateCampaign({
					variables: {
						campaign: translatedCampaignForm,
					},
				});
				setLastCampaignForm(campaignForm);
			} else {
				errorPopup.showErrorPopup(t("someRequiredFieldsAreIncomplete"));
				setIncompleteFormError(true);
			}
		} catch (err) {
			errorPopup.showErrorPopup(errorGenerating?.message ?? t("failedGeneratingCampaign"));
		}
	};

	const selectRandomBrand = () => {
		if (userBrands && userBrands.getUserBrands) {
			const randomBrand: any = userBrands.getUserBrands[0];
			handleCampaignChange("header1", { ...campaign.header1, ...randomBrand.header1 });
			handleCampaignChange("header2", { ...campaign.header2, ...randomBrand.header2 });
			if (randomBrand.logo) {
				handleCampaignChange("logo", randomBrand.logo);
			}
			if (randomBrand.colorPallet) {
				handleCampaignChange("colors", [...randomBrand.colorPallet, ""]);
				setColorsCheckbox(true);
			} else {
				setColorsCheckbox(false);
			}

			setBrandCheckbox(randomBrand?.mood !== null);
		}
	};

	const isFormCompleted = !!(
		campaign.productVisualHash !== "" &&
		campaign.logo &&
		(typeof campaign.logo === "string" ? campaign.logo !== "" : campaign.logo.filename !== "") &&
		campaign.layouts.length > 0 &&
		(campaign.bgDescription?.text || campaign.keepOriginalBg) &&
		!isUploading &&
		!loadingTemplate
	);

	const onClose = () => {
		setShowWarningDialog(true);
	};

	const getRandomHeader1 = () => {
		let prompt = getRandomText(headersPrompts);
		while (prompt === campaign.header2.text) {
			prompt = getRandomText(headersPrompts);
		}
		handleCampaignChange("header1", { ...campaign.header1, text: prompt });
	};

	const getRandomHeader2 = () => {
		let prompt = getRandomText(headersPrompts);
		while (prompt === campaign.header1.text) {
			prompt = getRandomText(headersPrompts);
		}
		handleCampaignChange("header2", { ...campaign.header2, text: prompt });
	};

	return (
		<Dialog
			classes={{ paper: classes.dialog }}
			open={open}
			onClose={onClose}
			maxWidth="xl"
			fullWidth
			onKeyDownCapture={(e) => {
				if (isViewerOpen) {
					e.stopPropagation();
				}
			}}
		>
			<DialogTitle className={classes.header}>
				<WandIcon /> {t("createCampaign")}
				<IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Box>
					<Box className={classes.form} onKeyUp={handleEnterKey}>
						<form>
							<FormControl className={classes.formControl}>
								<Box>
									<Box className={classes.labelWrapper}>
										<Typography className={classes.inputLable}>{t("brand")}</Typography>
										{errorBrands && (
											<IconButton onClick={() => loadBrandsFromDb()}>
												<ReplayIcon style={{ width: "15px", height: "15px" }} />
											</IconButton>
										)}
									</Box>
									<DropDown
										options={brandOptions}
										defaultSelected=""
										onChange={setSelectedBrand}
										selectedOption={selectedBrand}
										placeholder={t("customBrand")}
										color="#5B5B5B"
										borderRadius="8px"
										width="100%"
										height="4.5vh"
										loading={loadingBrands}
									></DropDown>
								</Box>
								<Box>
									<Typography className={classes.inputLable}>{t("campaignMedia")}</Typography>
									<DropDown
										options={mediaOptions}
										defaultSelected=""
										onChange={setSelectedMedia}
										selectedOption={selectedMedia}
										placeholder={t("selectCampaign")}
										color="#5B5B5B"
										border={
											incompleteFormError && campaign.layouts.length === 0 && !loadingTemplate
												? "1px solid #DC3545"
												: "1px solid rgb(164, 164, 164)"
										}
										borderRadius="8px"
										width="100%"
										height="4.5vh"
										loading={loadingTemplate}
									></DropDown>
								</Box>
								<Box>
									<Typography className={classes.inputLable}>{t("campaignAssets")}</Typography>
									<CampaignAssets
										{...{
											productImage,
											selectedBrand,
											isUploading,
											incompleteFormError,
											handleCampaignChange,
											setIsUploading,
										}}
									/>
								</Box>
								<Box>
									<Typography className={classes.inputLable}>{t("campaignColors")}</Typography>
									<Box className={classes.colorOptions}>
										{campaign.colors?.map((color: string, index: number) => (
											<Box key={index}>
												{color && index < 2 && (
													<Typography className={classes.colorHeader}>
														{index === 0 && <>Primary</>}
														{index === 1 && <>Secondary</>}
													</Typography>
												)}
												<ColorInput
													circleWidth="33px"
													color={color}
													onColorChange={(color: string, remove?: boolean) =>
														handleColorChange(color, index, remove)
													}
												/>
											</Box>
										))}
									</Box>
								</Box>
								<CampaignCheckboxes
									{...{
										colorsCheckbox,
										brandCheckbox,
										selectedBrand,
										setColorsCheckbox,
										setBrandCheckbox,
									}}
								/>
								<Box>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Typography className={classes.inputLable}>
											{t("campaignMainMessage")}
										</Typography>
									</Box>
									<TextField
										placeholder={t("productAmazing")}
										variant="outlined"
										fullWidth
										onChange={(e) =>
											handleCampaignChange("header1", {
												...campaign.header1,
												text: e.target.value,
											})
										}
										value={campaign.header1.text}
										className={classes.textField}
									></TextField>
								</Box>
								<Box>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Typography className={classes.inputLable}>
											{t("campaignSecondaryMessage")}
										</Typography>
									</Box>
									<TextField
										placeholder={t("getFor50Now")}
										variant="outlined"
										fullWidth
										onChange={(e) =>
											handleCampaignChange("header2", {
												...campaign.header2,
												text: e.target.value,
											})
										}
										value={campaign.header2.text}
										className={classes.textField}
									></TextField>
								</Box>
								<CampaignBackground {...{ campaign, incompleteFormError, handleCampaignChange }} />
							</FormControl>
						</form>
					</Box>
					<Box className={classes.formButtons}>
						<BriaButton buttonType={BUTTONTYPES.TEXTBUTTON} style={{ color: "black" }} onClick={onClose}>
							{t("cancel")}
						</BriaButton>
						<BriaButton
							disabled={loadingResults}
							buttonType={BUTTONTYPES.PRIMARYPURPLE}
							onClick={() => handleGenerate()}
							startIcon={<WandIcon stroke="white" />}
						>
							{t("generate")}
						</BriaButton>
					</Box>
				</Box>
				<CampaignPreview
					{...{
						visualHash: campaign.productVisualHash,
						lastCampaignForm,
						images: generatedCampaigns?.createCampaign.images || [],
						seed: generatedCampaigns?.createCampaign.seed || NaN,
						psds: generatedCampaigns?.createCampaign.psds || [],
						campaignName,
						loadingResults,
						isViewerOpen,
						isFormCompleted,
						errorGenerating,
						handleGenerate,
						setIsViewerOpen,
					}}
				/>
				<BriaConfirmationDialog
					title={t("workWillNotBeSaved")}
					description={<>{t("areYouSureWorkWillNotBeSaved")}</>}
					height="220px"
					width="600px"
					buttonExtraStyle={{ width: 340, bottom: 30 }}
					buttonText={t("yes")}
					buttonType={BUTTONTYPES.PRIMARYPURPLE}
					showCancelButton={true}
					cancelButtonText={t("cancel")}
					cancelButtonType={BUTTONTYPES.SECONDARYPURPLE}
					onButtonClick={handleClose}
					onCloseModal={() => {
						setShowWarningDialog(false);
					}}
					shouldOpen={showWarningDialog}
				/>
			</DialogContent>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	dialog: {
		height: "120vh",
		width: "160vh",
		borderRadius: "10px !important",
		containerType: "inline-size",
	},
	header: {
		"& h2": {
			display: "inline-flex",
			alignItems: "center",
			gap: "1.8vh",
			fontWeight: 600,
			fontSize: "min(2.7vh, 2vw, 23px)",
			letterSpacing: "0.02em",
			color: "black",
			"& svg": {
				marginTop: "0.5vh",
			},
		},
	},
	dialogContent: {
		display: "flex",
		gap: "4vh",
	},
	form: {
		display: "grid",
		justifyItems: "end",
		height: "75vh",
		overflow: "auto",
		visibility: "hidden",
		"&:hover": {
			visibility: "visible",
		},
	},
	formControl: {
		height: "102%",
		width: "47.5vh",
		gap: "1.5vh",
		paddingRight: "10px",
		visibility: "visible",
		"& > div .dropdownWrapper > div": {
			width: "100%",
		},
	},
	formButtons: {
		display: "flex",
		justifyContent: "end",
		marginTop: "1vh",
		alignItems: "end",
		gap: "2vh",
		"& button": {
			fontSize: "min(1.6vh, 1.4vw, 16px)",
			fontWeight: 600,
			borderRadius: "1vh",
		},
	},
	labelWrapper: {
		display: "inline-flex",
		gap: "4px",
		"& button": {
			marginBottom: "1vh",
			padding: 0,
			"&:hover": {
				background: "none",
			},
		},
	},
	inputLable: {
		marginLeft: "4px",
		marginBottom: "8px",
		fontWeight: 600,
		fontSize: "min(1.3vw, 13px)",
		color: "black",
	},
	colorOptions: {
		display: "flex",
		gap: "2.2vh",
		marginLeft: "0.25vh",
		"& > div": {
			alignSelf: "end",
			justifyContent: "end",
		},
	},
	colorHeader: {
		marginBottom: "1vh",
		fontWeight: 400,
		fontSize: "min(1.1vw, 12px)",
		color: "#6D6D6D",
	},

	textField: {
		"& .MuiOutlinedInput-root": {
			height: "4.5vh",
			fontSize: "min(1.2vw, 12px)",
		},
	},
	surpriseBtn: {
		marginRight: "6px",
		marginBottom: "-2px",
		fontSize: "min(1.2vw, 12px)",
		"&:hover": {
			background: "unset",
			opacity: "0.75",
		},
	},
}));

export default CreateCampaignDialog;
