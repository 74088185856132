import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Context from "../../Context/Context";
import { capitalizeFirstLetter } from "../../Helpers/string";
import CustomizedTooltip from "../UI/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import { useTranslation } from "react-i18next";

export const CustomCheckbox = withStyles((theme) => ({
	root: {
		"& .MuiSvgIcon-root": {
			fill: theme.palette.primary.dark,
		},
		"&$checked": {
			"& .MuiIconButton-label": {
				position: "relative",
				zIndex: 0,
			},
		},
	},
	checked: {},
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: 0,
		"&:hover": {
			fontWeight: "bolder",
			background: "#E7E7E7",
			borderRadius: "8px",
		},
	},
	textStyle: {
		color: "#5B5B5B",
		fontSize: 14,
		fontWeight: (onHover) => (onHover ? "bolder" : 500),
	},
	checkboxStyle: {
		"&.MuiSvgIcon-root": {
			fill: `${theme.palette.primary.dark} !important`,
			color: `${theme.palette.primary.dark} !important`,
		},
	},
	slidersContainer: {
		maxHeight: "100%",
	},
	thumbnailsContainer: {
		overflow: "auto",
		height: "100%",
		paddingLeft: 0,
		paddingRight: 0,
	},
	bothContainer: {
		maxHeight: "50%",
	},
	bottomThumbnailsView: {
		height: "100%",
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
	},
	hoverObject: {
		display: "flex",
	},
	normalObject: {
		fontWeight: "normal",
	},
}));

interface IProps {
	onObjectHover: (id: string) => void;
	clearObjectHover: () => void;
	objectOnHover: string;
	setShouldResetCheckboxes: Dispatch<SetStateAction<boolean>>;
	shouldResetCheckboxes: boolean;
	objectId: string;
	label: string;
	onRemoveObject: (objectId: string, checked: boolean) => Promise<boolean>;
	oracle?: { remove?: boolean };
	loading: boolean;
}

export default function ObjectRemover({
	onObjectHover,
	clearObjectHover,
	objectOnHover,
	setShouldResetCheckboxes,
	shouldResetCheckboxes,
	label,
	objectId,
	onRemoveObject,
	loading,
	oracle,
}: IProps) {
	const { t } = useTranslation();
	const classes = useStyles(objectId === objectOnHover);
	const context = useContext(Context);
	const { objectsToRemoveArray } = context;

	const [checked, setChecked] = useState<boolean>(objectsToRemoveArray.includes(objectId) ? false : true);

	useEffect(() => {
		if (shouldResetCheckboxes) {
			setChecked(true);
		}
	}, [shouldResetCheckboxes]);

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setShouldResetCheckboxes(false);
		const newChecked: boolean = event.target.checked;
		const res = await onRemoveObject(objectId, newChecked);
		res && setChecked(newChecked);
	};

	return (
		<FormControlLabel
			id={objectId.substring(objectId.length - 7)}
			classes={{
				label: classes.hoverObject,
				root: classes.root,
			}}
			control={
				<CustomCheckbox
					// className={classes.checkboxStyle}

					name="Object Remover"
					checked={checked}
					onChange={handleChange}
					disabled={loading}
				/>
			}
			onMouseEnter={() => {
				onObjectHover(objectId);
			}}
			onMouseLeave={() => {
				clearObjectHover();
			}}
			label={
				<>
					<Typography className={classes.textStyle}>
						{label === "chair,table" ? "Furniture" : capitalizeFirstLetter(label)}
					</Typography>

					{oracle && !oracle?.remove && (
						<CustomizedTooltip
							arrow
							multiLine={true}
							title={<>{<div>{t("theSystemDetectedLowerQuality")}</div>}</>}
							placement="right"
						>
							<ErrorIcon
								style={{
									fontSize: 16,
									marginLeft: "5px",
									color: "#FFC107",
									marginBottom: "2.5px",
								}}
							/>
						</CustomizedTooltip>
					)}
				</>
			}
		/>
	);
}
