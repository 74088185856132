import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function WandIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" width="2px" height="2px" {...props}>
			<path
				d="M13 4L16 7M4 19L19 4L16 1L1 16L4 19ZM7 1C7 1.53043 7.21071 2.03914 7.58579 2.41421C7.96086 2.78929 8.46957 3 9 3C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5C7 4.46957 6.78929 3.96086 6.41421 3.58579C6.03914 3.21071 5.53043 3 5 3C5.53043 3 6.03914 2.78929 6.41421 2.41421C6.78929 2.03914 7 1.53043 7 1ZM17 11C17 11.5304 17.2107 12.0391 17.5858 12.4142C17.9609 12.7893 18.4696 13 19 13C18.4696 13 17.9609 13.2107 17.5858 13.5858C17.2107 13.9609 17 14.4696 17 15C17 14.4696 16.7893 13.9609 16.4142 13.5858C16.0391 13.2107 15.5304 13 15 13C15.5304 13 16.0391 12.7893 16.4142 12.4142C16.7893 12.0391 17 11.5304 17 11Z"
				stroke={props.stroke || "black"}
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default WandIcon;
