import { SubscriptionCreditsTypes } from "./SubscriptionConstants";

class RouterConstants {
	public static readonly HOME = {
		path: "/",
		name: "home",
		exact: true,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly GALLERY = {
		path: "/gallery/",
		name: "gallery",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly campaignGeneration = {
		path: "/campaign_generation/",
		name: "campaign_generation",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly GALLERY_VH = {
		path: "/gallery/:visual_hash",
		name: "playground",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly INSTANCES = {
		path: "/instances/",
		name: "instances",
		exact: true,
		strict: false,
	};
	public static readonly DATASETS = {
		path: "/datasets/",
		name: "dataset",
		exact: true,
		strict: false,
	};
	public static readonly DATASETS_ID = {
		path: "/datasets/:mongoId",
		name: "dataset",
		exact: true,
		strict: false,
	};
	public static readonly RESEARCH = {
		path: "/research/",
		name: "research",
		exact: true,
		strict: false,
	};
	public static readonly RESEARCH_ID = {
		path: "/research/:mongoId",
		name: "research",
		exact: true,
		strict: false,
	};
	public static readonly USERS = {
		path: "/users/",
		name: "users",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly LOGIN = {
		path: "/login/",
		name: "login",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly SIGNUP = {
		path: "/sign-up/",
		name: "sign-up",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly SIGNUPVIEWERS = {
		path: "/sign-up-page/",
		name: "sign-up",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly FORGOTPASSWORD = {
		path: "/forgot-password/",
		name: "forgot-password",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly RESETPASSWORD = {
		path: "/reset-password/",
		name: "reset-password",
		exact: false,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly PENDING = {
		path: "/pending/",
		name: "pending",
		exact: false,
		strict: false,
	};
	public static readonly VERIFY = {
		path: "/verify-user/",
		name: "verify-user",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly VERIFYING = {
		path: "/verifying/",
		name: "verifying",
		exact: false,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly AUTH = {
		path: "/auth",
		name: "auth",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly SETTINGS = {
		path: "/settings/",
		name: "settings",
		exact: false,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly VDR = {
		path: "/vdr/",
		name: "vdr",
		exact: false,
		strict: false,
	};
	public static readonly VDR_ID = {
		path: "/vdr/:mongoId",
		name: "vdr",
		exact: true,
		strict: false,
	};
	public static readonly BRIAPI = {
		path: "/briapi/",
		name: "briapi",
		exact: false,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly ORGANIZATIONS = {
		path: "/organizations/",
		name: "organizations",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly TERMS_OF_USE = {
		path: "/terms-of-service/",
		name: "terms-of-service",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly API_TERMS_OF_USE = {
		path: "/api-terms-of-service/",
		name: "api-terms-of-service",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly PRIVACY_POLICY = {
		path: "/privacy-policy/",
		name: "privacy-policy",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly REFUND_POLICY = {
		path: "/refund-policy/",
		name: "refund-policy",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly EARLY_BIRD = {
		path: "/early-bird/",
		name: "early-bird",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly LANDING_PAGE = {
		path: "/create-with-bria/",
		name: "landing-page",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly API_LANDING = {
		path: "/api/",
		name: "api-landing-page",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly SMART_SEARCH_LANDING_PAGE = {
		path: "/search-with-bria/",
		name: "search-with-bria",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly REMOVE_BG_LANDING_PAGE = {
		path: "/remove-background-with-bria/",
		name: "remove-background-with-bria",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly ADD_LOGO_LANDING_PAGE = {
		path: "/add-logo-with-bria/",
		name: "add-logo-with-bria",
		exact: false,
		strict: false,
		forceLightTheme: true,
		disableLoader: true,
	};
	public static readonly PAGE_NOT_FOUND = {
		path: "/page-not-found/",
		name: "page-not-found",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly MOBILE_PAGE = {
		path: "/mobile/",
		name: "mobile",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly USER_INFO = {
		path: "/user-info/",
		name: "user-info",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly API_BILLING_DASHBOARD = {
		path: "/api-billing-dashboard/",
		name: "api-billing-dashboard",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly IFRAME = {
		path: "/iframe/",
		name: "iframe",
		exact: true,
		strict: false,
		forceLightTheme: false,
	};
	public static readonly EARLY_ACCESS = {
		path: "/early-access/",
		name: "Early Access",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly FAIR_DIFFUSION = {
		path: "/fair-diffusion/",
		name: "Fair Diffusion",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly BRAND_PAGE = {
		path: "/create-brand/",
		name: "Create Brand",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly WEB_PLANS = {
		path: "/pricing-app/",
		name: "Web Plans",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly API_PLANS = {
		path: "/pricing/",
		name: "API Plans",
		exact: true,
		strict: false,
		forceLightTheme: true,
		// disableLoader: true,
	};
	public static readonly MARKETING_PLANS = {
		path: "/pricing-platform/",
		name: "API Plans For Marketing",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly ASSETS_PAGE = {
		path: "/assets/",
		name: "My Assets",
		exact: true,
		strict: false,
		subscriptionCreditType: SubscriptionCreditsTypes.MY_ASSETS,
		forceLightTheme: true,
		allowMultipleUpload: true,
	};
	public static readonly ACCOUNT_MANAGEMENT = {
		path: "/account-management/",
		name: "My account",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly ORGANIZATION_MANAGEMENT = {
		path: "/organization-management/",
		name: "My organization",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly GAIA_PRELOGIN = {
		path: "/text-to-image-get-started",
		name: "gaia-prelogin",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly DEKA_PORTAL = {
		path: "/deka-portal/",
		name: "Deka Portal",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly ACCESS_MODELS = {
		path: "/deka-portal/get-models",
		name: "Access Models",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly ATTRIBUTION_SIMULATOR = {
		path: "/deka-portal/attribution-simulator",
		name: "Attribution Simulator",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
	public static readonly GALLERY_DEMO = {
		path: "/text-to-image-playground",
		name: "gaia playground",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};

	public static readonly SAMSUNG_DEMO = {
		path: "/samsung-playground",
		name: "samsung playground",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};

	public static readonly SAMSUNG_SKETCH_DEMO = {
		path: "/samsung-sketch-playground",
		name: "samsung sketch playground",
		exact: true,
		strict: false,
		forceLightTheme: true,
	};
}

export default RouterConstants;
