import { Box, Checkbox, Dialog, IconButton, Theme, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomCloseIcon from "../assets/icons/CloseIcon";
import UploadIcon from "../assets/icons/Uploadcon";
import { ReactComponent as ErrorIcon } from "../assets/svgs/info-icon.svg";
import Context from "../Context/Context";
import useUploadFile from "../hooks/useUploadFile";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";
import ShowAt from "./Common/Wrappers/ShowAt";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import { IsIframe } from "../Helpers/iframe";
import { captureException } from "@sentry/react";

interface StyleProps {}

export default function UploadFile() {
	const context = useContext(Context);
	const history = useHistory();
	const classes = useStyles({});
	const { t } = useTranslation();
	const [popUpHovered, setPopUpHovered] = useState(false);
	const {
		showUploadProgressPopup,
		setShowUploadProgressPopup,
		handleCancelRequests,
		getRootProps,
		getInputProps,
		isDragActive,
		setAssetsChecked,
		assetsChecked,
		files,
		setOrganizationChecked,
		progressInfos,
		displayUploadForm,
		failedUploadedFiles,
		setDisplayUploadForm,
		onUploadImage,
		uploadingDone,
		uploading,
		organizationChecked,
		succesfullyUploadedImages,
		failedToUploadImages,
		closeUploadFormAndStatusPopup,
		handleCloseUploadForm,
		allowMultipleFiles,
	} = useUploadFile();
	const { isIframe } = IsIframe();
	useEffect(() => {
		window.addEventListener("beforeunload", controlReload);
		return () => {
			window.removeEventListener("beforeunload", controlReload);
		};
	}, [showUploadProgressPopup, uploadingDone]);
	const controlReload = (e: any) => {
		if (showUploadProgressPopup && !uploadingDone) {
			e.preventDefault();
			e.returnValue = "";
		}
	};

	const uploadProgressPopUp = () => (
		<Box height={"100%"} width={"100%"}>
			<Box
				height={64}
				className={classes.popUpTopContainer}
				onClick={() => context.setUploadDialogExpandFlag(!context.uploadDialogExpandFlag)}
			>
				<Box>
					<Typography align="left" variant="h6" className={classes.popUpTopText}>
						{uploading && succesfullyUploadedImages === 0
							? `${t("uploading")} ${files.length} ${files.length === 1 ? t("visual") : t("visuals")}`
							: `${succesfullyUploadedImages} upload${
									succesfullyUploadedImages === 1 ? "" : "s"
							  } complete`}
					</Typography>
					<Typography align="left" variant="subtitle2" className={classes.failedText}>
						{failedToUploadImages > 0 &&
							`${failedToUploadImages} visual${failedToUploadImages === 1 ? "" : "s"} failed to upload`}
					</Typography>
				</Box>

				<Box alignItems={"right"} className={classes.iconsContainer}>
					{context.uploadDialogExpandFlag ? (
						<KeyboardArrowDown
							className={classes.dropDownIcon}
							fontSize="medium"
							onClick={() => context.setUploadDialogExpandFlag(false)}
						/>
					) : (
						<KeyboardArrowUp
							className={classes.dropDownIcon}
							fontSize="medium"
							onClick={() => context.setUploadDialogExpandFlag(true)}
						/>
					)}

					<CloseIcon
						onClick={() => {
							closeUploadFormAndStatusPopup();
						}}
						fontSize="small"
						className={classes.closeIconStyle}
					/>
				</Box>
			</Box>
			<Collapse in={context.uploadDialogExpandFlag} className={classes.filesContainer}>
				<Box className={classes.timeContainer} style={{ display: uploadingDone ? "none" : "" }}>
					<Typography>{t("uploading")}...</Typography>
					<BriaButton
						className={classes.cancelButton}
						buttonType={BUTTONTYPES.TEXTBUTTON}
						onClick={handleCancelRequests}
					>
						{t("cancel")}
					</BriaButton>
				</Box>
				<Box className={classes.filesList}>
					{files.map((file, index) => {
						return (
							<Box key={index} className={classes.fileDetailsContainer}>
								<Box display={"flex"} alignItems={"center"}>
									<img className={classes.progressPreviewImageContainer} src={file.preview} />
									<Typography className={classes.fileNameText}>
										{file.name.length > 20
											? file.name.substring(0, 20) + "..."
											: file.name.substring(0, file.name.lastIndexOf("."))}
									</Typography>
								</Box>
								<Box
									style={{
										marginTop: 8,
									}}
								>
									{progressInfos[index]?.percentage !== 0 ? (
										failedUploadedFiles.includes(progressInfos[index]?.fileName) ? (
											<ErrorIcon className={classes.errorIcon} />
										) : (
											progressInfos[index]?.percentage === 100 && (
												<Checkbox
													color="primary"
													checked={true}
													className={classes.checkbox}
													size="small"
												/>
											)
										)
									) : (
										<CircularProgress
											value={70} // progressInfos[index]?.percentage
											variant="indeterminate"
											color="primary"
											size={"1rem"}
										/>
									)}
								</Box>
							</Box>
						);
					})}
				</Box>
			</Collapse>
		</Box>
	);

	function requestArgs({ file }: any) {
		//synchronous function returning axios config arguments
		const formData = new FormData();
		console.log(file);
		formData.append("file", file);
		return {
			url: "http://localhost:3000/upload",
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		};
	}

	const dropZone = () => {
		return (
			<Box
				onMouseOver={() => setPopUpHovered(true)}
				onMouseOut={() => setPopUpHovered(false)}
				className={classes.uploadContainer}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{isDragActive ? (
					<Box className={classes.activeDropZone}>
						<Typography style={{ margin: "auto" }}>{t("dropImageHere")}</Typography>
					</Box>
				) : files.length === 0 ? (
					<>
						<Box className={classes.dropZone}>
							<UploadIcon className={classes.uploadIcon} />
							<Box mt={"16px"}>
								<Typography align="center">
									<Box>
										<Box className={classes.title}>
											{t("dragAndDrop")} {allowMultipleFiles ? t("filesHere") : t("fileHere")}
											<br></br>
											{t("orBrowseTo")}
											<span
												className={classes.uploadText}
												style={{
													textDecoration: popUpHovered ? "underline" : "",
												}}
											>
												{t("upload")}
											</span>
										</Box>
									</Box>
								</Typography>
							</Box>
						</Box>
						<Box mt={"40px"}>
							<Typography className={classes.supportedFilesText}>{t("supportedFiles")}</Typography>
						</Box>
					</>
				) : null}
			</Box>
		);
	};

	const previewSingleImage = () => {
		return (
			<>
				<Typography className={clsx(classes.title, classes.center)}>{t("uploadYourImage")}</Typography>
				{uploadTargetSelector(true)}
				<Box display="flex" justifyContent="center" className={classes.previewImageContainer}>
					<img
						src={files[0].preview}
						className={classes.previewSingleImage}
						width="100%"
						alt="image-to-upload"
					/>
				</Box>
				{ctaButtons(true)}
			</>
		);
	};

	const previewMultipleImages = () => {
		return (
			<>
				<Typography className={clsx(classes.title, classes.strong)}>
					{files.length} {t("visualsWereSelectedToUpload")}
				</Typography>
				{uploadTargetSelector(false)}
				<Box display="flex" justifyContent="flex-start" className={classes.previewImageContainer}>
					{files.slice(0, 4).map((f) => (
						<img
							src={f.preview}
							className={classes.previewMultipleImage}
							width="100%"
							alt="image-to-upload"
						/>
					))}
					{files.length > 4 && (
						<Box
							style={{
								borderRadius: 4,
								width: 95,
								height: 95,
								backgroundColor: "rgba(151, 151, 151, 0.2)",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "rgba(73, 80, 87, 1)",
								fontSize: 24,
							}}
						>
							+{files.length - 4}
						</Box>
					)}
				</Box>
				{ctaButtons(false)}
			</>
		);
	};

	const uploadTargetSelector = (center: boolean) => {
		return isIframe() ? (
			<Box className={classes.uploadTargetDesc}></Box>
		) : (
			<>
				<Typography className={clsx({ [classes.center]: center }, classes.uploadTargetDesc)}>
					{context.user?.isAdmin() || !!context.user?.organizations[0]?.org_uid ? (
						<>
							Choose <span className={classes.strong}>'My Assets'</span> to keep your image private or{" "}
							<br></br>
							<span className={classes.strong}>'My Organization'</span> to share with your team.
						</>
					) : (
						<>
							Images will be available in <span className={classes.strong}>'My Assets'</span> page
						</>
					)}
				</Typography>
				<Box className={clsx({ [classes.center]: center }, classes.uploadTargetCheckboxesContainer)}>
					<span className={classes.checkboxContainer}>
						<Checkbox
							color={"primary"}
							style={{ padding: "0 9px 0 0" }}
							checked={assetsChecked}
							onChange={(e) => setAssetsChecked(e.target.checked)}
						/>
						{t("myAssets")}
					</span>
					<ShowAt
						admin
						testing
						viewer
						external
						condition={context.user?.isAdmin() || !!context.user?.organizations[0]?.org_uid}
					>
						<span className={classes.checkboxContainer} style={{ margin: 0 }}>
							<Checkbox
								color={"primary"}
								style={{ padding: "0 9px 0 0" }}
								checked={organizationChecked}
								onChange={(e) => setOrganizationChecked(e.target.checked)}
							/>
							{t("myOrganization")}
						</span>
					</ShowAt>
				</Box>
			</>
		);
	};

	const ctaButtons = (center: boolean) => {
		return (
			<Box
				className={clsx({
					[classes.center]: center,
					[classes.right]: !center,
				})}
			>
				<BriaButton
					buttonType={BUTTONTYPES.PRIMARY}
					className={clsx(classes.ctaButton, classes.ctaExtention)}
					disabled={context.iframeConfig?.uid === "preview" || (!assetsChecked && !organizationChecked)}
					onClick={(e) => {
						try {
							e.stopPropagation();
							e.preventDefault();
							context.setUploadDialogExpandFlag(true);
							onUploadImage();
							setShowUploadProgressPopup(true);
							setDisplayUploadForm(false, history);

							Analytics.logEvent(ANALYTICS_EVENTS.IMAGE_UPLOAD_START);
						} catch (err) {
							captureException(err);
						}
					}}
				>
					{t("upload")}
				</BriaButton>
				<BriaButton
					buttonType={BUTTONTYPES.SECONDARY}
					className={clsx(classes.ctaButton, classes.ctaCancelButton)}
					onClick={handleCloseUploadForm}
				>
					{t("cancel")}
				</BriaButton>
			</Box>
		);
	};

	return showUploadProgressPopup ? (
		<Box className={classes.popupConainter}>{uploadProgressPopUp()}</Box>
	) : (
		<Dialog
			onClose={handleCloseUploadForm}
			open={displayUploadForm}
			maxWidth={false}
			classes={{
				paper: classes.dialog,
			}}
		>
			<Box className={classes.closeBtnContainer}>
				<IconButton
					style={{
						padding: 0,
						display: isDragActive ? "none" : "inherit",
					}}
					color="inherit"
					aria-label="close"
					onClick={handleCloseUploadForm}
				>
					<CustomCloseIcon style={{ fontSize: 12 }} />
				</IconButton>
			</Box>
			<Box className={classes.mainContainer}>
				<Box
					style={{
						margin: "30px 0",
						padding: "0 30px",
						width: "100%",
					}}
				>
					{files.length === 0
						? dropZone()
						: files.length > 1
						? previewMultipleImages()
						: previewSingleImage()}
				</Box>
			</Box>
		</Dialog>
	);
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
	createStyles({
		dialog: {
			width: 620,
			minHeight: 407,
			borderRadius: "10px !important",
		},
		ctaButton: {
			width: 150,
			height: 40,
			marginRight: 12,
		},
		ctaExtenstion: {
			marginRight: 10,
		},
		ctaCancelButton: {},
		strong: {
			fontWeight: "bold",
		},
		uploadTargetDesc: {
			margin: "20px 0 20px 0",
		},
		uploadTargetCheckboxesContainer: {
			marginBottom: 30,
		},
		center: {
			textAlign: "center",
		},
		mainContainer: {
			margin: "auto",
			width: "100%",
			height: "100%",
			justifyContent: "center",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		uploadContainer: {
			justifyContent: "center",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			height: "100%",
			width: "100%",
			margin: "42px 0px 10px 0px",
		},
		root: {
			width: "100%",
			height: "100%",
		},
		dropZone: {
			border: "1px dashed #495057",
			borderRadius: 4,
			backgroundColor: "rgba(218, 218, 218, 0.1)",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			paddingTop: 59,
			paddingBottom: 59,
			width: 480,
		},
		activeDropZone: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: 240,
			width: 480,
		},
		right: {
			textAlign: "right",
		},
		title: {
			color: "#495057",
			fontWeight: "bold",
			fontSize: 24,
		},
		uploadIcon: {
			fontSize: 48,
		},
		uploadText: {
			cursor: "pointer",
			color: (props) => theme.palette.primary.dark,
		},
		supportedFilesText: {
			fontSize: 14,
			color: "rgba(73, 80, 87, 0.8)",
		},
		closeBtnContainer: {
			position: "absolute",
			top: 16,
			right: 16,
		},
		previewSingleImage: {
			maxWidth: "360px",
			maxHeight: "220px",
			objectFit: "contain",
		},
		previewMultipleImage: {
			maxWidth: "95px",
			maxHeight: "95px",
			marginRight: 15,
			objectFit: "cover",
			borderRadius: 4,
		},
		previewImageContainer: {
			marginBottom: 48,
		},
		checkboxContainer: {
			marginRight: 30,
			display: "inline-flex",
			justifyContent: "center",
			alignItems: "center",
		},
		popupConainter: {
			position: "fixed",
			bottom: 0,
			right: 0,
			width: 400,
			// height: 444,
			zIndex: 25,
			borderRadius: "4px 0 0 0",
		},
		popUpTopContainer: {
			backgroundColor: (props) => theme.palette.primary.dark,
			borderRadius: "4px 0 0 0",
			padding: "22px 28px 22px 28px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		popUpTopText: {
			color: theme.palette.common.white,
			lineHeight: "20px",
		},
		failedText: {
			color: theme.palette.common.white,
			lineHeight: "14px",
			marginTop: 4,
		},
		closeIconStyle: {
			color: theme.palette.common.white,
		},
		dropText: {
			fontWeight: 700,
			fontSize: 24,
		},
		dropDownIcon: {
			color: theme.palette.common.white,
			marginRight: 8,
			cursor: "pointer",
		},
		iconsContainer: {
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
		},
		filesContainer: {
			backgroundColor: theme.palette.common.white,
		},
		timeContainer: {
			height: 40,
			backgroundColor: "#E9ECEF",
			opacity: 0.5,
			display: "flex",
			justifyContent: "space-between",
			padding: "12px 28px 13px 28px",
		},
		cancelButton: {
			textDecoration: "underline",
		},
		filesList: {
			padding: "20px 28px 22px 28px",
			overflowY: "scroll",
			maxHeight: 340,
		},
		fileDetailsContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			borderBottom: "1px solid #E9ECEF",
			height: 58,
		},
		progressPreviewImageContainer: {
			width: 28,
			height: 28,
			borderRadius: 2,
			marginRight: 12,
		},
		fileNameText: {
			color: theme.palette.text.primary,
			fontSize: 14,
			fontWeight: 400,
			lineHeight: "14px",
		},
		checkbox: {
			borderRadius: 10,
			cursor: "auto",
		},
		errorIcon: {
			marginRight: 8,
		},
		autoCompleteStyle: {
			marginLeft: 20,
			width: 150,
		},
	})
);
