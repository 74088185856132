import { RefObject, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import { DrawerProps } from "@material-ui/core/Drawer";
import { BriaObjectType, SelectedPointInterface, ToolsViewerMode } from "./utilities";
import { Divider } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ThumbnailImage, ToolConfigTabsTypes, ToolsSlider, ToolsThumbnails } from "../../Models/ToolsConfig";
import ThumbnailCardNew from "../UI/ThumbnailCardNew";
import clsx from "clsx";
import { ImageSemantic } from "../../types/graphql-global-types";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import User from "../../Models/User";
import CircularProgress from "@material-ui/core/CircularProgress";
import MarketingMessaging from "../Common/marketing-messaging";
import ThumbnailList from "./ThumbnailList";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import { clothColors } from "../../Constants/StaticLists";
import Scene from "../../sdk/resources/element";
import RetryingImage from "../UI/RetryingImage";

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		width: "100%",
		display: "flex",
		paddingRight: 20,
		flexDirection: "column",
		[`&.slider-list-${ToolConfigTabsTypes.Expression}`]: {
			"& .personalizer:first-child": {
				paddingTop: 0,
			},
		},
		[`&.slider-list-mood`]: {
			height: "100%",
		},
	},
	slidersContainer: {
		maxHeight: "100%",
	},
	thumbnailsContainer: (props) => ({
		height: "100%",
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(3),
	}),
	bothContainer: {
		maxHeight: "50%",
	},
	bottomThumbnailsView: {
		height: "100%",
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
	},
	hairIconsWrapper: {
		display: "flex",
		justifyContent: "space-between",
		padding: "14px 20px",
	},
	hairIcon: {
		width: 15,
		height: 15,
		borderRadius: 3,
		cursor: "pointer",
		"&.disabled": {
			opacity: 0.5,
			cursor: "inherit",
		},
	},
	glassIcon: {
		width: 18,
		height: 18,
		borderRadius: "100%",
		border: "0 solid #495057",
		cursor: "pointer",
	},
	sliderHint: {
		fontSize: 12,
		color: "#495057",
		cursor: "pointer",
	},
	styleActions: {
		margin: theme.spacing(0, 6.25),
	},
	icon: {
		fontSize: 18,
		paddingRight: 4,
	},
}));

interface StyleProps {}

interface IProps {
	selectedBackgroundReplaceSeed?: number;
	selectedPoint: SelectedPointInterface | any | null;
	feature: string;
	visualHash: string;
	disabled?: boolean;
	oracle?: any;
	thumbnails?: ToolsThumbnails[];
	thumbnailImages?: ThumbnailImage[];
	drawerAnchor?: DrawerProps["anchor"];
	sliders: ToolsSlider[] | null;
	personalizerKey: string;
	isSuggestion?: boolean;
	isReplaceBackground?: boolean;
	// children?: React.ReactChild;
	personalizerValues: { [key: string]: number };
	onChangePersonalizer: (sliders: ToolsSlider[], key?: string, apiAction?: ApiActions) => void;
	onReplaceBgThumbnailClick?: (thumbnailImage: ThumbnailImage) => void;
	setReplaceBgThumbsLoading?: (value: boolean) => void;
	onAddSemanticCombination: (
		sematics: ToolsSlider[] | ImageSemantic[],
		controller: any,
		key?: string
	) => Promise<string>;
	objectType: any;
	systemView: string;
	currentStyle?: ToolsViewerMode;
	user: User | null;
	isLoadingExpression: boolean;
	imageRef?: RefObject<HTMLImageElement>;
	handleResetActions?: () => void;
	originalImage?: string;
	thumbnailWidth?: number;
	thumbnailHeight?: number;
	thumbnailsPerCol?: number;
	thumbnailsCount?: number;
	faceDevInfo?: Scene[];
	retryingImageAbortController?: AbortController;
	onErrorLoad?: boolean;
	isEngineOverloaded?: boolean;
	onLoadMoreClick?: () => Promise<void>;
}

export default function Personalizer({
	selectedBackgroundReplaceSeed,
	selectedPoint,
	onLoadMoreClick,
	visualHash,
	setReplaceBgThumbsLoading,
	isEngineOverloaded,
	faceDevInfo,
	disabled,
	oracle,
	personalizerValues,
	thumbnails = [],
	thumbnailImages = [],
	drawerAnchor = "right",
	sliders,
	personalizerKey,
	isSuggestion,
	isReplaceBackground,
	onChangePersonalizer,
	onAddSemanticCombination,
	objectType,
	currentStyle,
	user,
	systemView,
	isLoadingExpression,
	onReplaceBgThumbnailClick,
	imageRef,
	handleResetActions,
	originalImage,
	thumbnailWidth = 0,
	thumbnailHeight = 0,
	thumbnailsPerCol = 2,
	thumbnailsCount = 11,
	retryingImageAbortController,
	onErrorLoad = false,
	feature,
}: IProps) {
	const suggestionsMargin = 13;
	const { t } = useTranslation();
	const [selectedThumbnail, setSelectedThumbnail] = useState("");
	const [slidersOriginalValues, setSlidersOriginalValues] = useState<{
		[key: string]: any;
	}>({});
	const [validSliders, setValidSliders] = useState<ToolsSlider[]>([]);

	useEffect(() => {
		setValidSliders(
			sliders
				? sliders.filter(
						(el) =>
							!el.hideSlider && (!el.validateFromDevInfo || faceDevInfo?.some((d) => d.type === el.id))
				  )
				: []
		);
	}, [sliders]);

	useEffect(() => {
		const initialValues: Record<string, number> = {};

		validSliders.forEach((el) => {
			if (el.id === "glasses" || el.id === "haircolor" || el.id === ToolConfigTabsTypes.UPPER_CLOTHES) {
				initialValues[el.id] = personalizerValues[el.id];
			}
		});

		setSlidersOriginalValues(initialValues);
	}, [personalizerValues, validSliders]);

	const classes = useStyles({});

	const mapSliders = (value: number, el: ToolsSlider) => {
		if (el.id === "glasses" || el.id === "haircolor" || el.id === ToolConfigTabsTypes.UPPER_CLOTHES) {
			const hairColors = ["#000000", "#644539", "#D56751", "#FBF5DE", "#BAB9B8"];
			const glassesStyles = [
				{ borderWidth: 0.5 },
				{ borderWidth: 1 },
				{ borderWidth: 1.5 },
				{ borderWidth: 2 },
				{ borderWidth: 2.5 },
				{ background: "#495057" },
			];
			return (
				<Box width="100%" py={2} key={el.id}>
					<ThumbnailSlider
						key={el.id}
						id={el.id}
						disabled={disabled || el.disabled}
						disabledTip={el.disabled}
						defaultValue={el.defaultInitialValue}
						maxValue={el.maxValue}
						minValue={el.minValue}
						value={value}
						title={el.displayName}
						reversed={el.reversed}
						stepSize={el.id === "haircolor" || el.id === ToolConfigTabsTypes.UPPER_CLOTHES ? 2 : 4}
						NormalizationRangeMax={el.maxValue * 4}
						NormalizationRangeMin={el.minValue * 4}
						sliderDefaultValue={el.defaultInitialValue}
						warrning={el.warning}
						error={el.error}
						minNameRiskArea={el.minNameRiskArea}
						maxNameRiskArea={el.maxNameRiskArea}
						handleValueChange={(value) => {
							onChangePersonalizer(
								[{ ...el, value }],
								undefined,
								el.id === ToolConfigTabsTypes.UPPER_CLOTHES ? ApiActions.UPPER_CLOTHES : undefined
							);
						}}
						renderTopRight={
							el.id === "glasses" || el.id === ToolConfigTabsTypes.UPPER_CLOTHES
								? () => (
										<span
											className={classes.sliderHint}
											onClick={() => {
												if (slidersOriginalValues[el.id]) {
													onChangePersonalizer([
														{
															...el,
															value: slidersOriginalValues[el.id],
														},
													]);
												}
											}}
										>
											Back to original
										</span>
								  )
								: undefined
						}
					/>

					{el.id === "haircolor" && (
						<Box className={classes.hairIconsWrapper}>
							{hairColors.map((color, index) => (
								<Box
									className={clsx(classes.hairIcon, {
										disabled: disabled || el.disabled,
									})}
									style={{ background: color }}
									onClick={() => {
										if (!disabled && !el.disabled) {
											onChangePersonalizer([{ ...el, value: index }]);
										}
									}}
								/>
							))}
						</Box>
					)}

					{el.id === "glasses" && (
						<Box className={classes.hairIconsWrapper}>
							{glassesStyles.map((glass, index) => (
								<Box
									className={clsx(classes.glassIcon, {
										disabled: disabled || el.disabled,
									})}
									style={glass}
									onClick={() => {
										if (!disabled && !el.disabled) {
											onChangePersonalizer([{ ...el, value: index + 1 }]);
										}
									}}
								/>
							))}
						</Box>
					)}
					{el.id === ToolConfigTabsTypes.UPPER_CLOTHES && (
						<Box className={classes.hairIconsWrapper}>
							{clothColors.personlizerUpperClothHex.map((color, index) => (
								<Box
									className={clsx(classes.hairIcon, {
										disabled: disabled || el.disabled,
									})}
									style={{ background: color }}
									onClick={() => {
										if (!disabled && !el.disabled) {
											onChangePersonalizer(
												[{ ...el, value: index }],
												undefined,
												ApiActions.UPPER_CLOTHES
											);
										}
									}}
								/>
							))}
						</Box>
					)}
				</Box>
			);
		}
		return (
			<Box
				width="100%"
				pb={3.625}
				// pr={2.5}
				key={el.id}
				className={"personalizer"}
			>
				<ThumbnailSlider
					key={el.id}
					id={el.id}
					disabled={disabled || el.disabled}
					disabledTip={el.disabled}
					defaultValue={el.defaultInitialValue}
					maxValue={el.maxValue}
					minValue={el.minValue}
					leftSubtitle={el.minName}
					rightSubtitle={el.maxName}
					value={value}
					title={el.displayName}
					reversed={el.reversed}
					warrning={el.warning}
					error={el.error}
					minNameRiskArea={el.minNameRiskArea}
					maxNameRiskArea={el.maxNameRiskArea}
					sliderDefaultValue={el.defaultInitialValue}
					handleValueChange={(value) => {
						onChangePersonalizer([{ ...el, value }]);
					}}
				/>
			</Box>
		);
	};

	const personalizerSliderList = (
		<Box flex={1} overflow="auto">
			{(validSliders && validSliders.length > 0) || isLoadingExpression ? (
				<>
					{validSliders.map((el) => mapSliders(personalizerValues[el.id], el))}
					{isLoadingExpression && (
						<Box height="20vh" display="flex" justifyContent="center" alignItems="center">
							<CircularProgress />
						</Box>
					)}
				</>
			) : (
				<>
					<MarketingMessaging personalizerKey={personalizerKey} />
				</>
			)}
		</Box>
	);

	const showSliders =
		(currentStyle === ToolsViewerMode.sliders && personalizerKey !== ToolConfigTabsTypes.Suggestions) ||
		personalizerKey === ToolConfigTabsTypes.Expression ||
		(objectType !== BriaObjectType.human && objectType !== BriaObjectType.brand);
	return (
		<>
			<Box
				className={clsx(classes.root, `slider-list-${personalizerKey}`)}
				display={showSliders ? "unset" : "none !important"}
				maxHeight={showSliders ? "inherit" : 0}
				data-testid="slider-list"
			>
				{personalizerSliderList}
			</Box>
			{currentStyle === ToolsViewerMode.both && <Divider />}

			{(currentStyle === ToolsViewerMode.thumbnails || personalizerKey === ToolConfigTabsTypes.Suggestions) && (
				<Box className={classes.root} data-testid="thumbnails-list">
					<Box
						flex={1}
						className={clsx({
							[classes.thumbnailsContainer]: drawerAnchor !== "bottom",
							[classes.bottomThumbnailsView]: drawerAnchor === "bottom",
						})}
					>
						{isReplaceBackground ? (
							<Box display="flex" flexWrap="wrap" justifyContent="center" style={{ marginTop: "4px" }}>
								{thumbnailImages
									.slice(
										0,
										thumbnailImages.length < thumbnailsCount
											? thumbnailImages.length
											: thumbnailsCount
									)
									.map((el, index) => {
										return (
											<RetryingImage
												retry={index > 0}
												passedImageErrorOnLoad={index > 0 && isEngineOverloaded}
												loadedCallback={(success: boolean) => {
													index > 0 &&
														setReplaceBgThumbsLoading &&
														setReplaceBgThumbsLoading(false);
												}}
												maxAttempts={90}
												feature={feature}
												abortController={retryingImageAbortController}
												isSelected={
													(index === 0 && !selectedBackgroundReplaceSeed) ||
													el.seed === selectedBackgroundReplaceSeed
												}
												boxStyle={true}
												width={`${thumbnailWidth}px`}
												height={`${thumbnailHeight}px`}
												imageKey={el.key}
												url={el.imageUrl}
												onClick={() => {
													if (!disabled && onReplaceBgThumbnailClick) {
														onReplaceBgThumbnailClick(el);
													}
												}}
											></RetryingImage>
										);
									})}
								{thumbnailImages.length > 0 && thumbnailImages.length < thumbnailsCount && (
									<Box width="100%" display="flex" justifyContent="center">
										<BriaButton
											buttonType={BUTTONTYPES.TEXTSMALL}
											style={{
												width: 200,
												marginTop: 24,
											}}
											onClick={async () => {
												if (onLoadMoreClick) {
													await onLoadMoreClick();
												}
											}}
										>
											{t("loadMore")}
										</BriaButton>
									</Box>
								)}
							</Box>
						) : (sliders ?? [])?.length > 0 || isSuggestion ? (
							<Box display="flex" flexWrap="wrap">
								<ThumbnailList
									selectedPoint={selectedPoint}
									visualHash={visualHash}
									mapSliders={mapSliders}
									thumbnails={thumbnails ?? []}
									ProperThumbnailCard={ThumbnailCardNew}
									isSuggestion={!!isSuggestion}
									onAddSemanticCombination={onAddSemanticCombination}
									onChangePersonalizer={onChangePersonalizer}
									originalImage={originalImage || ""}
									personalizerValues={personalizerValues}
									selectedThumbnail={selectedThumbnail}
									setSelectedThumbnail={setSelectedThumbnail}
									thumbnailsHeight={thumbnailHeight}
									thumbnailsWidth={thumbnailWidth}
									thumbnailsCount={thumbnailsCount}
									disabled={disabled}
									handleResetActions={handleResetActions}
									onErrorLoad={onErrorLoad}
								/>
							</Box>
						) : (
							<MarketingMessaging personalizerKey={personalizerKey} />
						)}
					</Box>
				</Box>
			)}
		</>
	);
}
