import { Box, Button, Grid, InputBase, Link, TextField, Theme, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import ColorInput from "../Components/Tools/Brand/ColorPallete/ColorInput/ColorInput";
import CustomSingleSelect, { IGroup } from "../Components/Common/DropDown/ٍSingleSelectGroupedDropDown";
import WandIcon from "../assets/icons/WandIcon";
import BriaButton, { BUTTONTYPES } from "../Components/UI/BriaButton";
import closeImage from "../assets/svgs/x-brand.svg";
import KeepOrReplaceBackGroundDialog from "../Components/KeepOrReplaceBackGroundDialog";
import BriaAPI from "../sdk/resources/briaAPI";
import CampaignPreview from "../Components/Campaign/CampaignPreview";
import { useTranslation } from "react-i18next";
import Context from "../Context/Context";
import { Camp, CampaignAsset, defaultCampaign } from "../Components/UI/CreateCampaign/utils";
import uploadIcon from "../assets/icons/Upload";
import useCampaigngeneration from "../hooks/useCampaigngeneration";
import { generateCamp, translateText } from "../GraphQL/mutations";
import i18n from "i18next";
import { IsIframe } from "../Helpers/iframe";

import CampaignColorDialog from "../Components/CampaignColorDialog";

interface IProps {
	handleBackButton: (event: any) => void;
	passedImage: any;
}

const CampainGeneration = ({ handleBackButton, passedImage }: IProps) => {
	const { isIframe } = IsIframe();
	const classes = useStyles();
	const [campaign, setCampaign] = useState<Camp>({ ...defaultCampaign });
	const [isTooltipHovered, setIsTooltipHovered] = useState(false);
	const logoInputRef = useRef<HTMLInputElement | null>(null);
	const ctaInputRef = useRef<HTMLInputElement | null>(null);
	const mainAssetRef = useRef<HTMLInputElement | null>(null);
	const [renewKeyOnDelete, setRenewKeyOnDelete] = useState(0);
	const [mainAssetFile, setMainAssetFile] = useState("");
	const [shouldOpenKeepReplaceBackground, setShouldOpenKeepReplaceBackground] = useState(false);
	const [showGenerate, setShowGenerate] = useState(true);
	const [showReplaceGenerationComponent, setShowReplaceGenerationComponent] = useState(false);
	const [imageWithoutBackground, setImageWithoutBackground] = useState("");
	const [imageWithBackground, setImageWithBackground] = useState("");
	const [isImageSelected, setIsImageSelected] = useState(false);
	const [selectedFontPrimaryName, setSelectedFontPrimaryName] = useState("");
	const [selectedFontSecondaryName, setSelectedFontSecondaryName] = useState("");
	const [selectedFontPrimary, setSelectedFontPrimary] = useState<any>();
	const [selectedFontSecondary, setSelectedFontSecondary] = useState<any>();
	const [campaignAssets, setCampaignAssets] = useState<CampaignAsset>({
		mainAsset: "",
		logo: "",
		cta: "",
	});
	const [hasValidImage, setHasValidImage] = useState(true);
	const [isValidBgColor, setIsValidBgColor] = useState(true);
	const [isValidBgPrompt, setIsValidBgPrompt] = useState(true);
	const [generatedCampaigns, setGeneratedCampaigns] = useState<any>([]);
	const [generatedCampaignErrorMessage, setGeneratedCampaignErrorMessage] = useState<any>(null);
	const [imagesReady, setImagesReady] = useState(false);
	const [closeUsingIcon, setCloseUsingIcon] = useState(true);
	const [passedImageFromGallery, setPassedImageFromGallery] = useState(passedImage);
	const [campaignImagesGenerated, setCampaignImagesGenerated] = useState(true);
	const handleCampaignChange = <K extends keyof Camp>(key: K, value: Camp[K]) => {
		setCampaign((prevCampaign) => ({ ...prevCampaign, [key]: value }));
	};
	const [openColorDialog, setOpenColorDialog] = useState(false);
	const [primaryMessageColorOpened, setPrimaryMessageColorOpened] = useState(false);
	const [isMatchBrandsColors, setIsMatchBrandsColors] = useState(true);

	useEffect(() => {
		if (isImageSelected && !shouldOpenKeepReplaceBackground && !showReplaceGenerationComponent && !closeUsingIcon) {
			setMainAssetFile(imageWithBackground);
		} else if (
			isImageSelected &&
			!shouldOpenKeepReplaceBackground &&
			showReplaceGenerationComponent &&
			!closeUsingIcon
		) {
			setMainAssetFile(imageWithoutBackground);
		}
	}, [shouldOpenKeepReplaceBackground]);

	function deleteAsset(fileKey: any) {
		setRenewKeyOnDelete(renewKeyOnDelete + 1);
		setCampaignAssets((prevAssets) => ({
			...prevAssets,
			[fileKey]: "",
		}));

		handleCampaignChange(fileKey, { filename: "", base_64_string: "" });
	}

	const handleColorChange = (color: string, index: number, remove?: boolean) => {
		const updatedColors = [...campaign.colors];
		if (remove) {
			updatedColors.splice(index, 1);
		} else {
			updatedColors[index] = color;
		}

		const showAddIcon: boolean = Boolean(updatedColors[updatedColors.length - 1] && updatedColors.length < 5);
		if (showAddIcon) updatedColors.push("");

		handleCampaignChange("colors", updatedColors);
	};

	const context = useContext(Context);
	const fontOptions = context.orgFonts;
	const { t } = useTranslation("translation", { keyPrefix: "CampainGeneration" });

	const handlePrimaryFontChnage = (event: any) => {
		const selectedOption = context.orgFonts
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option.id === event.target.value);

		handleCampaignChange("header1", { ...campaign.header1, font: selectedOption.src });
		setSelectedFontPrimary(selectedOption.id);
		setSelectedFontPrimaryName(selectedOption.value);
	};

	const handleSecondaryFontChnage = (event: any) => {
		const selectedOption = context.orgFonts
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option.id === event.target.value);

		handleCampaignChange("header2", { ...campaign.header2, font: selectedOption.src });
		setSelectedFontSecondary(selectedOption.id);
		setSelectedFontSecondaryName(selectedOption.value);
	};

	const filterColors = (colors: string[]) => {
		const filtertedColors = colors
			?.map((color) => color.toUpperCase())
			?.filter((color) => color && /^#[0-9A-F]{6}$/i.test(color));
		return filtertedColors;
	};

	const handlePrimaryColorChange = (color: string, remove?: boolean) => {
		const filtertedColors = filterColors(campaign.colors);
		if (color && filtertedColors?.length > 0) {
			if (filtertedColors.includes(color?.toUpperCase())) {
				setIsMatchBrandsColors(true);
			} else {
				setIsMatchBrandsColors(false);
			}
		} else {
			setIsMatchBrandsColors(true);
		}
		var showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			handleCampaignChange("header1", { ...campaign.header1, color: color });
		}

		if (showAddIcon) handleCampaignChange("header1", { ...campaign.header1, color: "" });
	};

	const handleSolidColorChange = (color: string, remove?: boolean) => {
		var showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			handleCampaignChange("bgColor", color);
		}

		if (showAddIcon) handleCampaignChange("bgColor", "");
	};

	const handleSecondaryColorChange = (color: string, remove?: boolean) => {
		const filtertedColors = filterColors(campaign.colors);
		if (color && filtertedColors.length > 0) {
			if (filtertedColors.includes(color?.toUpperCase())) {
				setIsMatchBrandsColors(true);
			} else {
				setIsMatchBrandsColors(false);
			}
		} else {
			setIsMatchBrandsColors(true);
		}
		var showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			handleCampaignChange("header2", { ...campaign.header2, color: color });
		}

		if (showAddIcon) handleCampaignChange("header2", { ...campaign.header2, color: "" });
	};

	const GenerateCampaign = async () => {
		let hasValidationErrors = false;
		if (!campaign?.productVisualHash) {
			setHasValidImage(false);
			hasValidationErrors = true;
		}
		if (showReplaceGenerationComponent && showGenerate && !campaign.bgPrompt) {
			setIsValidBgPrompt(false);
			hasValidationErrors = true;
		}
		if (showReplaceGenerationComponent && !showGenerate && !campaign.bgColor) {
			setIsValidBgColor(false);
			hasValidationErrors = true;
		}

		if (hasValidationErrors) {
			return;
		}

		try {
			let translatedCampaignForm = campaign;
			if (translatedCampaignForm.bgPrompt) {
				translatedCampaignForm.bgPrompt =
					(await translateText(i18n.language, "en", translatedCampaignForm.bgPrompt)) ??
					translatedCampaignForm.bgPrompt;
			}
			setCampaignImagesGenerated(false);
			const generateCampRes = await generateCamp(translatedCampaignForm);
			if (generateCampRes && generateCampRes?.length > 0) {
				const updatedRes = generateCampRes.map((item: any, index: any) => {
					return {
						...item,
						loaded: false,
						name: item.placementName,
						src: item.pngFile,
					};
				});
				const lastCampaign = {
					...campaign,
					seed: generateCampRes[0]?.expansionSeed,
					bgSeed: generateCampRes[0]?.bgSeed,
					templateId: generateCampRes[0]?.templateId,
				};
				setCampaign(lastCampaign);
				setGeneratedCampaigns(updatedRes);
			} else {
				setGeneratedCampaigns([]);
			}
			setGeneratedCampaignErrorMessage(null);
			setCampaignImagesGenerated(true);
		} catch (e) {
			setGeneratedCampaigns([]);
			console.log(e);
			setGeneratedCampaignErrorMessage(e);
			setCampaignImagesGenerated(true);
		}
	};
	const { getBrandDetails, handleAssetUpload } = useCampaigngeneration({
		setCampaignAssets,
		handleCampaignChange,
		setImagesReady,
		setShouldOpenKeepReplaceBackground,
		setImageWithoutBackground,
		setImageWithBackground,
		campaign,
		setSelectedFontPrimaryName,
		setSelectedFontSecondaryName,
	});

	useEffect(() => {
		getBrandDetails();
		handleCampaignChange("channelIds", context.selectedChannelValues);
		handleCampaignChange("templateId", context.selectedTemplate);
		// const orgPlacements = context.orgPlacements;
		// const selectedOrgPlacements = context.selectedPlacementsValues;
		// const result = selectedOrgPlacements.map((id) => {
		// 	const option = orgPlacements[0]?.options.find((opt: any) => opt.id === id);
		// 	return {
		// 		id,
		// 		name: ` ${option.placemantName}`,
		// 		src: "",
		// 	};
		// });
		setGeneratedCampaigns([]);
	}, []);

	useEffect(() => {
		if (showReplaceGenerationComponent) {
			if (showGenerate) {
				handleCampaignChange("backgroundType", "generated");
			} else {
				handleCampaignChange("backgroundType", "solid");
			}
		}
	}, [showGenerate]);

	useEffect(() => {
		const fetchData = async () => {
			const url = passedImage?.imageUrl;
			const vhash = passedImage?.vhash;
			if (url && vhash) {
				context.setCurrentCampainVhash(vhash);
				handleCampaignChange("productVisualHash", vhash);
				setCampaignAssets((prevAssets) => ({
					...prevAssets,
					mainAsset: url,
				}));

				const sid = BriaAPI.getInstance(vhash, context.iframeConfig).getLastApiCallSid() || undefined;
				handleCampaignChange("sid", sid);

				setImagesReady(false);
				setShouldOpenKeepReplaceBackground(true);
				try {
					const client = BriaAPI.getInstance(vhash, context.iframeConfig);
					const { data } = await client._removeImageBG(sid || "", "");
					if (data?.image_res) {
						setImageWithoutBackground(data.image_res);
					}

					setImageWithBackground(url);
					setImagesReady(true);
				} catch (error) {
					console.error("Error fetching or processing image:", error);
					setImagesReady(true);
				}
			}
		};

		fetchData(); // Call the asynchronous function
	}, [passedImageFromGallery]);

	return (
		<>
			<Grid className={classes.mainGrid}>
				<Grid item>
					<Box className={classes.campaignGenerationContainer}>
						<Box
							style={{
								maxHeight:
									isIframe() && context.iframeConfig?.embeddedIframe
										? "calc(100vh - 160px)"
										: "calc(100vh - 236px)",
							}}
							className={classes.campaignInfoContainer}
						>
							<Typography className={classes.mainAssetALabel}>{t("mainAsset")}</Typography>
							<input
								key={renewKeyOnDelete}
								ref={mainAssetRef}
								onChange={(e) => handleAssetUpload("mainAsset", e)}
								accept="image/*"
								type="file"
								className={classes.displayNone}
							/>

							<Button
								disableRipple
								className={`${classes.mainAssetButton} ${classes.container} ${classes.containerHovered}`}
								onClick={(event) => {
									if (mainAssetFile) {
										event?.stopPropagation();
									} else {
										!isTooltipHovered && mainAssetRef.current?.click();
									}
								}}
							>
								<Box
									className={`${classes.mainAsset} ${!hasValidImage && classes.errorBorder}`}
									style={{ border: mainAssetFile ? "none" : "1.5px dashed #B3B3B9" }}
								>
									<Box className={classes.mainAssetContents}>
										{mainAssetFile ? (
											<>
												<Box className={classes.container}>
													<img className={classes.mainAssetImg} src={mainAssetFile} alt="" />
													<button
														className={classes.replaceButton}
														onClick={() => mainAssetRef.current?.click()}
													>
														{t("replaceImage")}
													</button>
												</Box>
											</>
										) : (
											<>
												<span className={classes.mainAssetLogo}> {uploadIcon(48, 49)}</span>

												<Typography className={classes.mainAssetDescriptions}>
													{t("mainAssetProduct")}
												</Typography>
												<Typography className={classes.mainAssetDescriptions}>
													{t("presenterOrBackground")}
												</Typography>
												<Typography className={classes.mainAssetSupportedFilesDescription}>
													{t("supportedFiles")}
												</Typography>
											</>
										)}
									</Box>
								</Box>
							</Button>
							{/* Background Generation Component */}
							{showReplaceGenerationComponent && (
								<Box>
									<Typography className={classes.BackgrundLable}>{t("backGround")}</Typography>
									<Box className={classes.replaceBackgroundButtons}>
										<Link
											onClick={() => {
												setShowGenerate(true);
											}}
											className={`${classes.underlineButton} ${
												classes.backgroundGenerateButton
											} ${showGenerate && classes.selectedButton}`}
										>
											<Typography className={`${classes.backGroundLable}`}>
												{t("generate")}{" "}
											</Typography>
										</Link>

										<Link
											onClick={() => {
												setShowGenerate(false);
											}}
											className={`${classes.underlineButton}  ${
												classes.backgroundGenerateSolidColorButton
											} ${!showGenerate && classes.selectedButton}`}
										>
											<Typography className={`${classes.solidColorLable}`}>
												{t("solidColor")}
											</Typography>
										</Link>
									</Box>
									{showGenerate && (
										<Box>
											<TextField
												autoFocus
												value={campaign.bgPrompt}
												fullWidth
												onChange={(e) => handleCampaignChange("bgPrompt", e.target.value)}
												placeholder={t("describeTheBackground")}
												multiline
												className={`${classes.textArea} ${
													!isValidBgPrompt ? classes.textAreaWithError : ""
												}`}
												rows={3}
												maxRows={4}
												InputProps={{
													style: { height: "100%" },
													disableUnderline: true,
												}}
												InputLabelProps={{
													classes: {
														root: classes.descrieBackgroundLabel,
													},
												}}
											/>
										</Box>
									)}
									{!showGenerate && (
										<Box className={classes.backGroundComponentsContainer}>
											<InputBase
												id="inputId"
												placeholder={t("messageText")}
												className={`${classes.textField} ${
													!isValidBgColor && classes.errorBorder
												}`}
												onChange={(e) => handleCampaignChange("bgColor", e.target.value)}
												value={campaign.bgColor}
											/>
											<ColorInput
												circleWidth="40px"
												color={campaign.bgColor}
												onColorChange={(color: string, remove?: boolean) =>
													handleSolidColorChange(color, remove)
												}
												circledashed={true}
											/>
										</Box>
									)}
								</Box>
							)}
							<Box>
								<Typography className={classes.campaignAssetLabel}>{t("campaignAssets")}</Typography>
								<Box className={classes.campaignAssetsBoxes}>
									<input
										key={renewKeyOnDelete}
										ref={logoInputRef}
										onChange={(e) => handleAssetUpload("logo", e)}
										accept="image/*"
										type="file"
										className={classes.displayNone}
									/>
									<Button
										className={classes.imguploadButton}
										onClick={() => !isTooltipHovered && logoInputRef.current?.click()}
									>
										<Box
											className={`${classes.campaignAssetBox} ${
												campaignAssets.logo && classes.checkers
											}`}
										>
											{campaignAssets.logo ? (
												<>
													<Tooltip
														title={"delete"}
														className={classes.tooltipStyle}
														placement="top"
														onClick={() => {
															deleteAsset("logo");
															setIsTooltipHovered(false);
														}}
														onOpen={() => setIsTooltipHovered(true)}
														onClose={() => setIsTooltipHovered(false)}
													>
														<img src={closeImage} />
													</Tooltip>
													<img src={campaignAssets.logo} className={classes.imgLogo} alt="" />
												</>
											) : (
												<>
													<span className={classes.assetsIcons}> {uploadIcon(30, 30)}</span>
													<Typography className={classes.CTALabel}>{t("logo")}</Typography>
												</>
											)}
										</Box>
									</Button>
									<input
										key={renewKeyOnDelete}
										ref={ctaInputRef}
										onChange={(e) => handleAssetUpload("cta", e)}
										accept="image/*"
										type="file"
										className={classes.displayNone}
									/>
									<Button
										className={classes.imguploadButton}
										onClick={() => !isTooltipHovered && ctaInputRef.current?.click()}
									>
										<Box className={classes.campaignAssetBox}>
											{campaignAssets.cta ? (
												<>
													<Tooltip
														title={"delete"}
														className={classes.tooltipStyle}
														placement="top"
														onClick={() => {
															deleteAsset("cta");
															setIsTooltipHovered(false);
														}}
														onOpen={() => setIsTooltipHovered(true)}
														onClose={() => setIsTooltipHovered(false)}
													>
														<img src={closeImage} />
													</Tooltip>
													<img src={campaignAssets.cta} className={classes.imgLogo} alt="" />
												</>
											) : (
												<>
													<span className={classes.assetsIcons}> {uploadIcon(30, 30)}</span>
													<Typography className={classes.CTALabel}>{t("cta")}</Typography>
												</>
											)}
										</Box>
									</Button>
								</Box>
								<Box>
									<Typography className={classes.brandColorLabel}>{t("brandColors")}</Typography>
								</Box>
							</Box>
							<Box className={classes.colorsLables}>
								{campaign.colors.map((color: string, index: number) => (
									<Box>
										{index < 2 && (
											<Typography className={classes.colorHeader}>
												{index === 0 && <>{t("Primary")}</>}
												{index === 1 && <>{t("Secondary")}</>}
											</Typography>
										)}
									</Box>
								))}
							</Box>

							<Box className={classes.colorOptions}>
								{campaign.colors.map((color: string, index: number) => (
									<Box key={index}>
										<ColorInput
											circleWidth="40px"
											color={color}
											onColorChange={(color: string, remove?: boolean) =>
												handleColorChange(color, index, remove)
											}
											circledashed={true}
										/>
									</Box>
								))}
							</Box>
							<Box>
								<Box className={classes.primarylables}>
									<Typography className={classes.inputsLabel}>{t("primaryMessage")}</Typography>
									<Typography className={classes.inputsLabelOptional}>{t("optional")}</Typography>
								</Box>
								<InputBase
									id="inputId"
									placeholder={t("messageText")}
									className={classes.textField}
									onChange={(e) =>
										handleCampaignChange("header1", { ...campaign.header1, text: e.target.value })
									}
									value={campaign.header1.text}
								/>
								<Box className={classes.fontColorSection}>
									<CustomSingleSelect
										selectedValue={selectedFontPrimary}
										handleChange={handlePrimaryFontChnage}
										groups={(fontOptions as IGroup[]) || []}
										className="custom-single-select"
										selectRootClass={classes.rootFirstSelect}
										placeholder={t("Font")}
										selectStyle={classes.selectStyle}
										menuMaxHeight="30vh"
										selectedValueName={selectedFontPrimaryName}
										fontOptions={true}
									/>
									<Box onClick={() => setPrimaryMessageColorOpened(true)}>
										<ColorInput
											circleWidth="40px"
											color={campaign.header1.color}
											onColorChange={(color: string, remove?: boolean) =>
												handlePrimaryColorChange(color, remove)
											}
											circledashed={true}
											disbaleDefaultPicker={true}
											setOpenColorDialog={setOpenColorDialog}
										/>
									</Box>
								</Box>
							</Box>
							{/* Secondary */}
							<Box>
								<Box className={classes.primarylables}>
									<Typography className={classes.inputsLabel}>{t("secondaryMessage")}</Typography>
									<Typography className={classes.inputsLabelOptional}>{t("optional")}</Typography>
								</Box>
								<InputBase
									id="inputId"
									placeholder={t("messageText")}
									className={classes.textField}
									onChange={(e) =>
										handleCampaignChange("header2", { ...campaign.header2, text: e.target.value })
									}
									value={campaign.header2.text}
								/>
								<Box className={classes.fontColorSection}>
									<CustomSingleSelect
										selectedValue={selectedFontSecondary}
										handleChange={handleSecondaryFontChnage}
										groups={(fontOptions as IGroup[]) || []}
										className="custom-single-select"
										selectRootClass={classes.rootFirstSelect}
										placeholder={t("Font")}
										selectStyle={classes.selectStyle}
										menuMaxHeight="30vh"
										selectedValueName={selectedFontSecondaryName}
										fontOptions={true}
									/>
									<Box onClick={() => setPrimaryMessageColorOpened(false)}>
										<ColorInput
											circleWidth="40px"
											color={campaign.header2.color}
											onColorChange={(color: string, remove?: boolean) =>
												handleSecondaryColorChange(color, remove)
											}
											circledashed={true}
											disbaleDefaultPicker={true}
											setOpenColorDialog={setOpenColorDialog}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box className={classes.backGenerateContainer}>
							<Box className={classes.buttonsBox}>
								<Box className={classes.InnerButtonContainer}>
									<Link
										onClick={handleBackButton}
										className={`${classes.underlineButton} ${classes.backButton}`}
									>
										{t("back")}
									</Link>
									<BriaButton
										disabled={!campaignImagesGenerated}
										style={{ height: "40px !important" }}
										buttonType={BUTTONTYPES.PRIMARYPURPLE}
										onClick={GenerateCampaign}
										startIcon={<WandIcon stroke="white" />}
									>
										{t("generate")}
									</BriaButton>
								</Box>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid style={{ flexGrow: 1 }}>
					<CampaignPreview
						images={generatedCampaigns}
						generatedCampaignErrorMessage={generatedCampaignErrorMessage}
						campaignImagesGenerated={campaignImagesGenerated}
					/>
				</Grid>
			</Grid>

			<KeepOrReplaceBackGroundDialog
				shouldOpen={shouldOpenKeepReplaceBackground}
				onClose={() => {
					setShouldOpenKeepReplaceBackground(false);
					setCloseUsingIcon(true);
				}}
				mainAssetFile={imageWithBackground}
				mainAssetWithoutBacground={imageWithoutBackground}
				handleKeepBackGround={() => {
					setIsImageSelected(true);
					setShowReplaceGenerationComponent(false);
					setShouldOpenKeepReplaceBackground(false);
					handleCampaignChange("backgroundType", "original");
					setCloseUsingIcon(false);
				}}
				handleReplaceBackGround={() => {
					setIsImageSelected(true);
					setShowReplaceGenerationComponent(true);
					setShouldOpenKeepReplaceBackground(false);
					handleCampaignChange("backgroundType", "generated");
					setCloseUsingIcon(false);
				}}
				imagesReady={imagesReady}
			/>
			<Box>
				<CampaignColorDialog
					shouldOpen={openColorDialog}
					onClose={() => setOpenColorDialog(false)}
					colors={campaign.colors}
					color={primaryMessageColorOpened ? campaign.header1.color : campaign.header2.color}
					setColor={primaryMessageColorOpened ? handlePrimaryColorChange : handleSecondaryColorChange}
					isMatchBrandsColors={isMatchBrandsColors}
					setIsMatchBrandsColors={setIsMatchBrandsColors}
				/>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		campaignGenerationContainer: {
			boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.08)",
			borderRadius: "8px",
			border: "1px solid  #E7E7E7",
			background: "#FFF",
			width: "416px",
			diplay: "flex",
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		mainCampaignContainer: {
			display: "flex",
			width: "416px",
		},
		campaignInfoContainer: {
			overflowY: "auto",
			padding: "24px",
		},
		mainAsset: {
			border: "1.5px dashed #B3B3B9",
			borderRadius: "8px",
			backgroundColor: "#FAFAFA",
			height: "100%",
			width: " 100%",
			"&:hover": {
				"& $mainAssetLogo path": {
					stroke: "#5B5B5B",
				},
				backgroundColor: "#E7E7E7",
			},
		},
		mainAssetContents: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "360px",
		},
		mainAssetImg: {
			height: " 200px",
			maxWidth: "360px",
			borderRadius: "8px",
		},
		mainAssetLogo: {
			paddingTop: "52px",
			paddingBottom: "7px",
		},
		mainAssetDescriptions: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "19.04px",
		},
		mainAssetSupportedFilesDescription: {
			color: "#1A0638",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "16.32px",
			paddingBottom: "28px",
			paddingTop: "10px",
		},
		mainAssetALabel: {
			marginBottom: "12px",
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		mainAssetButton: {
			width: "360px",
			height: "200px",
			padding: "0px",
			textTransform: "none",
			"& span": {
				height: "100%",
			},
		},
		campaignAssetLabel: {
			marginTop: "20px",
			marginBottom: "12px",
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		campaignAssetBox: {
			borderRadius: "8px",
			border: "1.5px dashed  #B3B3B9",
			backgroundColor: " #FAFAFA",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			height: "80px",
			justifyContent: "center",
			"&:hover": {
				backgroundColor: "#E7E7E7",
				"& $assetsIcons path": {
					stroke: "#5B5B5B",
				},
			},
		},
		assetsIcons: {
			paddingTop: "17px",
		},
		CTALabel: {
			paddingBottom: "12px",
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 500,
			lineHeight: "normal",
			paddingTop: "5px",
			textTransform: "none",
		},
		campaignAssetsBoxes: {
			display: "flex",
			gap: "14px",
		},
		brandColorLabel: {
			marginTop: "20px",
			marginBottom: "9px",
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		colorsLables: {
			display: "flex",
			gap: "8px",
		},
		colorHeader: {
			marginBottom: "12px",
			fontWeight: 400,
			fontSize: "12px",
			color: "#000",
			lineHeight: "normal",
		},
		colorOptions: {
			display: "flex",
			gap: "12px",
			"& > div": {
				alignSelf: "end",
				justifyContent: "end",
			},
		},
		textField: {
			width: "360px",
			borderRadius: "8px",
			height: "40px",
			border: "1px solid #B3B3B9",
			color: "#5B5B5B",
			padding: "13px 8px",
			"& input": {
				fontSize: 14,
				textOverflow: "ellipsis",
				"&::placeholder": {
					color: "#5B5B5B",
					fontSize: 14,
					fontWeight: 400,
				},
			},
		},
		primarylables: {
			display: "flex",
			justifyContent: "space-between",
			marginTop: "20px",
			marginBottom: "12px",
		},
		inputsLabelOptional: {
			color: "#5B5B5B",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "normal",
		},
		inputsLabel: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "normal",
		},
		rootFirstSelect: {
			padding: "10px 12px",
		},
		selectStyle: {
			width: "264px",
			borderRadius: "10px !important",
			backgroundColor: "white",
			"&:focus": {
				backgroundColor: "white",
			},
			overflowY: "auto",
		},
		fontColorSection: {
			display: "flex",
			gap: "12px",
			marginTop: "12px",
		},
		backGenerateContainer: {
			width: "360px",
			height: "88px",
			borderRadius: "0px 0px 8px 8px",
			backgroundColor: "#FFF",
			display: "flex",
			marginLeft: "24px",
			justifyContent: "end",
			flexGrow: 1,
		},
		buttonsBox: {
			display: "flex",
			marginTop: "24px",
			alignItems: "end",
			marginBottom: "24px",
			width: "100%",
		},
		backButton: {
			marginRight: "8px",
			lineHeight: "16px !important",
			padding: "12px 16px 12px 16px !important",
			height: "40px !important",
		},
		underlineButton: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			cursor: "pointer",
			fontWeight: 600,
			fontFamily: "Montserrat",
			color: "#1A0638",
			fontSize: "16px !important",
			letterSpacing: "0.02em",
			height: "34px",
			marginTop: 1,
			borderRadius: 8,
			textDecoration: "none",
			"&:hover": {
				backgroundColor: "#E5E5E5",
				textDecoration: "none",
			},
			"@media (max-width:1280px)": {
				fontSize: "1.1vw",
			},
		},
		imguploadButton: {
			padding: "0px",
			display: "block",
			width: "130px",
			height: "80px",
		},
		checkers: {
			backgroundImage: `url(/transarent-logo.jpg)`,
			backgroundSize: "cover",
		},
		tooltipStyle: {
			position: "absolute",
			right: "6px",
			top: "6px",
			fontSize: "14px",
			borderRadius: "4px",
			color: "initial",
		},
		imgLogo: {
			backgroundSize: "cover",
			maxHeight: "4vh",
			maxWidth: "10vh",
		},
		replaceBackgroundButtons: {
			display: "flex",
			justifyContent: "center",
			width: "100%",
		},
		backGroundLable: {
			color: "#1A0638",
			fontSize: "14px !important",
			fontWeight: 600,
			lineHeight: "16px",
			letterSpacing: "0.28px",
			padding: "9px",
		},
		solidColorLable: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			lineHeight: "16px",
			letterSpacing: "0.28px",
			padding: "9px",
		},
		backgroundGenerateButton: {
			border: "1px solid #B3B3B9",
			borderRadius: "8px 0px 0px 8px",
			width: "50%",
			"&:hover": {
				color: "#5300C9",
				border: "2px solid#5300C9",
				borderRadius: "8px 0px 0px 8px",
				backgroundColor: "rgba(83, 0, 201, 0.05)",
				boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06) inset",
			},
		},

		selectedButton: {
			color: "#5300C9",
			border: "2px solid#5300C9",
			borderRadius: "8px 0px 0px 8px",
			backgroundColor: "rgba(83, 0, 201, 0.05)",
			boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06) inset",
		},
		backgroundGenerateSolidColorButton: {
			border: "1px solid #B3B3B9",
			borderRadius: "0px 8px 8px 0px",
			width: "50%",
			"&:hover": {
				color: "#5300C9",
				border: "2px solid#5300C9",
				borderRadius: "0px 8px 8px 0px",
				backgroundColor: "rgba(83, 0, 201, 0.05)",
				boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06) inset",
			},
		},
		BackgrundLable: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 600,
			marginTop: "14px",
			marginBottom: "12px",
		},
		descrieBackgroundLabel: {
			color: "#5B5B5B",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "21px",
		},
		backGroundComponentsContainer: {
			display: "flex",
			marginTop: "14px",
			position: "relative",
			alignItems: "center",
			gap: "12px",
		},
		textArea: {
			"& .MuiInput-underline, ": {
				marginTop: "14px",
				"&::placeholder": {
					opacity: 1,
					color: "#5B5B5B",
					fontSize: "14px",
					fontWeight: 400,
					lineHeight: "21px",
				},
			},

			"& .MuiInputBase-root": {
				borderRadius: 8,
				padding: "9px 7px 19px 17px !important",
				boxShadow: "0 0 0 1px rgba(91, 91, 91, 0.5)",
				"&:hover": {
					boxShadow: "0 0 0 1px #1A0638",
				},
				"&.Mui-focused": {
					boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
				},
			},
			"& .MuiInput-underline:before ": {
				border: "none !important",
			},

			"& .MuiInputBase-input": {
				color: "#5B5B5B !important",
			},
			marginTop: "8px",
		},

		textAreaWithError: {
			"& .MuiInputBase-root": {
				border: "1px solid red",
				borderRadius: 8,
				padding: "9px 7px 19px 17px !important",
				boxShadow: "none !important",
			},
			"&.Mui-focused": {
				boxShadow: `none !important`,
			},
		},
		colorInput: {
			position: "absolute",
			left: 0,
			width: "100%",
			height: "100%",
			zIndex: 2,
			opacity: 0,
			cursor: "pointer",
		},
		IconButton: {
			paddingRight: "0px",
			"&:hover, &:focus, &:active": {
				// backgroundColor: "unset",
				backgroundColor: "transparent",
			},
		},
		displayNone: {
			display: "none",
		},
		container: {
			width: "100%",
			position: "relative",
			display: "inline-block",
		},
		replaceButton: {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "#5300C9",
			color: "#FFF",
			padding: "14px 16px",
			border: "none",
			cursor: "pointer",
			opacity: 0,
			borderRadius: "8px",
			boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.12)",
			transition: "opacity 0.3s ease",
			fontSize: "16px",
			fontWeight: 600,
			lineHeight: "16px",
		},
		containerHovered: {
			"&:hover $replaceButton": {
				opacity: 1,
			},
		},
		mainGrid: {
			height: "100%",
			display: "flex",
			flexDirection: "row",
			gap: "12px",
			"@media (max-width:1200px)": {
				justifyContent: "space-between",
			},
		},
		sliderWrapper: {
			flexGrow: 1,
			marginLeft: "16px",
			justifyContent: "center",
			gap: "40px",
		},
		buttonsContainer: {
			marginTop: "8px",
			display: "flex",
			flexDirection: "column",
			borderRadius: "8px",
			border: "1px solid #E7E7E7",
			padding: "13px 13px 21px 13px",
			alignItems: "baseline",
			backgroundColor: "white",
			gap: "13px",
			boxShadow:
				"0px 0px 0px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 8px 5px 0px rgba(0, 0, 0, 0.04), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 23px 6px 0px rgba(0, 0, 0, 0.00)",
		},
		buttonText: {},
		downloadButtonsGrid: {
			textAlign: "end",
			display: "flex",
			alignItems: "start",
			height: " 100%",
			width: "280px",
			justifyContent: "end",
		},
		errorBorder: {
			border: "1.5px solid red !important",
		},
		InnerButtonContainer: {
			borderTop: " 1px solid #E7E7E7",
			boxShadow: " 0px -4px 0px 0px rgba(0, 0, 0, 0.02)",
			display: "flex",
			paddingTop: "24px",
			width: "100%",
			justifyContent: "end",
		},
	})
);
export default CampainGeneration;
