import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import FileUpload from "../FileUpload/FileUpload";
import TitleTexts from "../TitleTexts/TitleTexts";
import { BrandContext } from "../BrandPage/BrandPage";
import { uploadLogoToS3 } from "../../../../GraphQL/mutations";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import useErrorPopup from "../../../../hooks/useErrorPopup";
import { useTranslation } from "react-i18next";

export default function LogoInput() {
	const classes = useStyles();
	const { updateBrandState, defaultStepValue, brandState, updateGeneralState } = useContext(BrandContext);
	const { t } = useTranslation();
	const useErrorPopupVar: any = useErrorPopup();
	const [logo, setLogo] = useState<any>(defaultStepValue);
	const [uploading, setUploading] = useState<boolean>(false);

	const onDrop = (file: any) => {
		handleChange(file);
	};
	const handleChange = async (file: any = "") => {
		setUploading(true);
		const logoResp = await uploadLogoToS3(file?.name, file?.base64);

		if (logoResp && logoResp.ok && logoResp.url) {
			try {
				const colorPaletteResponse = await BriaAPI.extractColorPalette(logoResp.url);

				setUploading(false);
				setLogo(logoResp.url);
				updateBrandState(logoResp.url);
				updateGeneralState("logoColorPallet", colorPaletteResponse.data?.palette?.slice(0, 5));
			} catch (e) {
				useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			}
		}
	};

	return (
		<Box className={classes.logoInputRoot}>
			<TitleTexts passedClassName={classes.brandName} subText={(brandState as any)?.name} />
			<TitleTexts
				passedClassName={classes.titleTexts}
				text="Do you have a logo?"
				subText={`You will be able to place your logo on images later on.\n AI could also extract and use brand colors from your logo`}
			/>

			<FileUpload
				file={logo?.length && logo}
				loading={uploading}
				hideFileOnDrop={true}
				onDrop={onDrop}
				dragText="Drop it like it’s hot!"
				renderFile={(file: any) => {
					return (
						<>
							<img src={file} alt="file" className={classes.logoImg} />
						</>
					);
				}}
			/>
			{/* <InlineAlert msg="Oops. You forgot to fill this in" type="info" /> */}
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	brandName: {
		color: "#A4A4A4",
		padding: "3vh 0 3vh",
		"& p": {
			fontWeight: 400,
			fontSize: "24px",
		},
	},
	titleTexts: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	logoInputRoot: {
		maxWidth: 800,
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
	},
	dropzone: {
		padding: "3rem 1rem",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "1rem",
		outlineOffset: -14,
		flexDirection: "column",
	},
	dropzoneText: {
		fontSize: "14px",
		color: "#000",
		opacity: "0.5",
	},
	logoImg: {
		maxWidth: "60%",
		maxHeight: "100%",
		objectFit: "contain",
	},
}));
