import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtmlPuri from "draftjs-to-html";
import { clearEditorContent } from 'draftjs-utils'
import html2canvas from "html2canvas";
import { uploadImageTosS3 } from "../../GraphQL/mutations";

interface IProps {
    setTextOnImage: (textHtml: string) => void;
    setSelectedImageUrl: (imageUrl: string) => void;
    setIsImageLoading: (set: boolean) => void;
}

export const TextEditor = (props: IProps) => {
    let [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    let [isTextMode, setIsTextMode] = useState(false);

    function onChangeState(state) {
        setEditorState(state);
        var purifiedHtml = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
        props.setTextOnImage(purifiedHtml);
    }

    function onRemoveText() {
        const state = clearEditorContent(editorState);
        setEditorState(state);
        var purifiedHtml = draftToHtmlPuri(convertToRaw(state.getCurrentContent()));
        props.setTextOnImage(purifiedHtml);
        setIsTextMode(false);
    }

    async function saveImage() {
        await props.setIsImageLoading(true);
        var canvas = await html2canvas(document.getElementById('root_image_box'), {
            useCORS: true,
        })
        var img = canvas.toDataURL();
        var imgUrl: string = await uploadImageTosS3(img);
        props.setSelectedImageUrl(imgUrl);
        onRemoveText();
        await props.setIsImageLoading(false);
    }

    const enabled_style = isTextMode && { border: "1px solid black", padding: '2px', minHeight: '400px' } || {}
    const disabled_style = !isTextMode && { "color": "gray" } || {}
    return (
        <>
            <button type="button" disabled={isTextMode} onClick={() => { setIsTextMode(true) }}>
                Add Text
            </button>
            <button type="button" disabled={!isTextMode} onClick={async () => { await saveImage() }}>
                Save Text
            </button>
            <button type="button" disabled={!isTextMode} onClick={() => { onRemoveText() }}>
                Remove Text
            </button>

            <div aria-disabled={!isTextMode}>
                <div style={{ ...enabled_style, ...disabled_style }}>
                    <Editor
                        readOnly={!isTextMode}
                        editorState={editorState}
                        onEditorStateChange={onChangeState}
                    />
                </div>
            </div>
        </>

    );
};