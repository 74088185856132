import { useEffect, useRef } from "react";
import { useBrush } from "./useBrush";
import { useCanvasActions } from "./useCanvasActions";
import { DEFAULT_BRUSH } from "../../Constants/defaultBrush";
import { getImageMeta } from "../../Components/Tools/utilities";

export const usePaintCanvas = (
	mainObjectData: any,
	lockMasks: boolean,
	brushConfig: any,
	imageUrl: string,
	objectsTabCanvasRef: any,
	parentDimenstions: { width: number; height: number } | undefined,
	brushOverlayCanvasRef: any
) => {
	const context = useRef<CanvasRenderingContext2D | null>(null);
	const actions = useCanvasActions(objectsTabCanvasRef, context, brushOverlayCanvasRef);

	const clearbrushOverlayCanvasRef = () => {
		if (brushOverlayCanvasRef.current && brushOverlayCanvasRef.current.getContext) {
			const context = brushOverlayCanvasRef.current.getContext("2d");
			if (context) {
				context.clearRect(0, 0, brushOverlayCanvasRef.current.width, brushOverlayCanvasRef.current.height);
			}
		}
	};
	const getCanvasContext = async (newImageUrl: string = imageUrl) => {
		if (!objectsTabCanvasRef.current) {
			return;
		}
		context.current = objectsTabCanvasRef.current.getContext("2d");

		if (newImageUrl) {
			try {
				const image = await getImageMeta(newImageUrl);
				if (context.current && parentDimenstions) {
					setCanvasDimensions(image, parentDimenstions);
					drawCheckeredBackground(parentDimenstions);
					drawImageOnCanvas(image);
					configureCanvasBrush();
				}
			} catch (error) {
				console.error("Error:", error);
			}
		}
	};
	const setCanvasDimensions = (image: any, parentDimensions: any) => {
		if (image.width > parentDimensions.width || image.height > parentDimensions.height) {
			const scaleX = parentDimensions.width / image.width;
			const scaleY = parentDimensions.height / image.height;
			const scale = Math.min(scaleX, scaleY);

			objectsTabCanvasRef.current.width = Math.round(image.width * scale);
			objectsTabCanvasRef.current.height = Math.round(image.height * scale);
		} else {
			objectsTabCanvasRef.current.width = image.width;
			objectsTabCanvasRef.current.height = image.height;
		}
	};

	const drawCheckeredBackground = (parentDimensions: any) => {
		if (!context.current) {
			return;
		}
		const squareSize = 8.2;
		const numSquaresX = parentDimensions.width / squareSize;
		const numSquaresY = parentDimensions.height / squareSize;

		for (let outer = 0; outer < numSquaresX; outer++) {
			for (let inner = 0; inner < numSquaresY; inner++) {
				const xPixel = outer * squareSize;
				const yPixel = inner * squareSize;

				if ((outer + inner) % 2 === 0) {
					context.current.fillStyle = "#fff";
				} else {
					context.current.fillStyle = "#cccccc";
				}

				context.current.fillRect(xPixel, yPixel, squareSize, squareSize);
			}
		}
	};

	const drawImageOnCanvas = (image: any) => {
		if (!context.current) {
			return;
		}
		context.current.drawImage(
			image,
			0,
			0,
			image.width,
			image.height,
			0,
			0,
			objectsTabCanvasRef.current.width,
			objectsTabCanvasRef.current.height
		);
	};
	const reDrawImage = async (newImageUrl: string) => {
		clearbrushOverlayCanvasRef();
		await getCanvasContext(newImageUrl);
	};

	const configureCanvasBrush = () => {
		if (!context.current) {
			return;
		}
		Object.assign(context.current, DEFAULT_BRUSH, brushConfig);
	};

	const initializeCanvas = async () => {
		clearbrushOverlayCanvasRef();
		getCanvasContext();
		configureCanvasBrush();
	};

	useEffect(() => {
		initializeCanvas();
	}, []);
	useEffect(() => {
		configureCanvasBrush();
	}, [brushConfig]);

	useBrush(mainObjectData, lockMasks, context, brushConfig, objectsTabCanvasRef, brushOverlayCanvasRef);

	return {
		actions,
		reDrawImage,
		initializeCanvas,
	};
};
