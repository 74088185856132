import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import SquareIcon from "../../assets/icons/SquareIcon";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CustomizedTooltip from "./Tooltip";

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(1.5),
	},
	icons: {
		display: "flex",
		width: 50,
		height: 17,
		cursor: "pointer",
		stroke: "#A4A4A4",
	},
	seperator: {
		width: 1.5,
		background: "#A4A4A4",
		margin: theme.spacing(0, 0.5),
	},
	activeButton: {
		stroke: "#D80067",
	},
	active: {
		background: "#D80067",
	},
	text: {
		fontSize: 10,
		marginTop: 2,
	},
}));

interface IProps {
	selectedValue: number;
	onChange: (value: number) => void;
	tooltipPosition?: string;
	hideLabel?: boolean;
}

const CompareButton: React.FC<IProps> = ({
	selectedValue,
	onChange,
	tooltipPosition = "top",
	hideLabel = false,
}) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const toggleStyle = () => {
		onChange(selectedValue === 1 ? 2 : 1);
	};

	return (
		<CustomizedTooltip arrow title="Compare" placement={tooltipPosition}>
			<Box
				className={classes.root}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<Box className={classes.icons} onClick={toggleStyle}>
					<SquareIcon
						className={clsx({
							[classes.activeButton]: selectedValue === 1,
						})}
					>
						{" "}
					</SquareIcon>
					<span
						className={clsx({
							[classes.seperator]: true,
							[classes.active]: selectedValue === 1,
						})}
					>
						{" "}
					</span>
					<SquareIcon
						className={clsx({
							[classes.activeButton]: selectedValue === 1,
						})}
					>
						{" "}
					</SquareIcon>
				</Box>
				{!hideLabel && <Typography className={classes.text}>{t("compare")}</Typography>}
			</Box>
		</CustomizedTooltip>
	);
};

export default CompareButton;
