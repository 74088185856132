import i18n from "i18next";

export const SearchRandomTexts = Array.from(Array(26).keys()).map((i) => `searchRandomTexts${i + 1}`);
export const Bria2SearchRandomTexts = Array.from(Array(10).keys()).map((i) => `bria2SearchRandomTexts${i + 1}`);
export const BackgroundReplacmentTexts = Array.from(Array(41).keys()).map((i) => `replaceBgPrompts${i + 1}`);
export const PresentersStylePrompts = Array.from(Array(9).keys()).map((i) => `presentersStylePrompts${i + 1}`);

export const getRandomText = (textList: string[]) => {
	let randomNumber = Math.floor(Math.random() * textList.length);
	return i18n.t(textList[randomNumber]);
};
