import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const AccordionExpandIcon = () => {
	return (
		<Box
			sx={{
				"& .collapsIconWrapper": {
					display: "block",
					background: "linear-gradient(145.62deg, #5300C9 14.25%, #200349 78.6%)",
					color: "white",
				},
				"& .expandIconWrapper": {
					display: "none",
				},
				".Mui-expanded & .collapsIconWrapper": {
					display: "none",
				},
				".Mui-expanded & .expandIconWrapper": {
					display: "block",
					background: "linear-gradient(145.62deg, #5300C9 14.25%, #200349 78.6%)",
					color: "white",
				},
			}}
		>
			<AddIcon className="collapsIconWrapper" />
			<RemoveIcon className="expandIconWrapper" />
		</Box>
	);
};

export default AccordionExpandIcon;
