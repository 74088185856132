import React, { createContext, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import Stepper from "../../Common/Stepper/Stepper";
import { brandSteps } from "./steps";
import Sidebar from "../../Sidebar";
import CustomPrompt from "../../Common/CustomPrompt/CustomPrompt";
import NavigationBtns from "./Navigator/NavigationBtns/NavigationBtns";
import CurrentStepIndicator, {
	filterHidden,
} from "./Navigator/CurrentStepIndicator/CurrentStepIndicator";
import { useThemeContext } from "../../../Context/BriaThemeProvider";
import { useContext } from "react";
import { BrandContext } from "./BrandPage/BrandPage";

export default function Brand() {
	const classes = useStyles();
	const [hasChange, setHasChange] = useState(false);

	const { next, prev, currentIndex, brandState, changeId } =
		useContext(BrandContext);
	const hideNavIndicator = changeId === -1;

	const { isDarkMode, handleThemeChange } = useThemeContext();
	const steps = brandSteps();

	return (
		<>
			<CustomPrompt
				message={
					"You haven’t finished setting up your brand style. Changes will not be saved. Are you sure you want to leave?"
				}
				when={false}
			/>
			<Box className={classes.wrapper}>
				<Sidebar
					hideTop
					border="none"
					px="20px"
					isDark={!isDarkMode}
					dotColor="#D80067"
				/>
				<Box className={classes.flex_1}>
					<Box className={classes.horizantlyCenter}>
						<Stepper currentStepIndex={currentIndex}>
							{steps.map(({ Render, RenderWithProps }) => (
								<Stepper.Step>
									{RenderWithProps ? Render : <Render />}
								</Stepper.Step>
							))}
						</Stepper>
						<Box className={classes.btnsWrapper}>
							<NavigationBtns
								next={next}
								prev={prev}
								currentIndex={currentIndex}
								atLast={
									currentIndex === brandSteps().length - 1
								}
							/>
						</Box>
					</Box>
					<Box className={classes.navigationWrapper}>
						{!hideNavIndicator && (
							<CurrentStepIndicator
								next={next}
								prev={prev}
								currentIndex={currentIndex}
								atLast={
									currentIndex === brandSteps().length - 1
								}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: "flex",
		position: "relative",
	},
	horizantlyCenter: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		padding: "0 2.5rem 7%",
		flexDirection: "column",
	},
	flex_1: {
		flex: 1,
		position: "relative",
	},
	navigationWrapper: {
		marginTop: "2rem",
		display: "flex",
		gap: 1,
		position: "absolute",
		bottom: "7%",
		left: "50%",
		transform: "translateX(-50%)",
		flexDirection: "column",
		"& :nth-child(7), & :nth-child(8), & :nth-child(9), & :nth-child(10), & :nth-child(11)":
			{
				display: "none",
			},
	},
	btnsWrapper: {
		display: "flex",
		margin: "3rem 0 0",
		width: "100%",
	},
}));
