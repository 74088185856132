import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const MinWhiteIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_20545_95560)">
				<rect x="4" width="28" height="28" rx="14" fill="#F1F1F1" />
			</g>
			<path
				d="M15.8672 19.3228L15.8672 16.0044M15.8672 16.0044L12.1339 16.0044M15.8672 16.0044L11.6005 19.7969"
				stroke="#495057"
			/>
			<path
				d="M23.8651 12.2187L20.1318 12.2187M20.1318 12.2187L20.1318 8.90038M20.1318 12.2187L24.3984 8.42632"
				stroke="#495057"
			/>
			<defs>
				<filter
					id="filter0_d_20545_95560"
					x="0"
					y="0"
					width="36"
					height="36"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_20545_95560"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_20545_95560"
						result="shape"
					/>
				</filter>
			</defs>
		</SvgIcon>
	);
};

export default MinWhiteIcon;
