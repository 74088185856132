/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, TextField, Theme, Typography } from "@material-ui/core";
import LoadingAnimation from "../Common/LoadingAnimation/LoadingAnimation";
import FullScreenViewer from "../UI/BrandTab/FullScreenViewer";
import { makeStyles } from "@material-ui/styles";
import { useContext, useEffect, useState } from "react";
import campaignDefault from "../../assets/campaignDefault.png";
import { useTranslation } from "react-i18next";

interface StyleProps {
	hasResults: boolean;
}

type Props = {
	images: string[];
	isViewerOpen: boolean;
	errorGenerating: any;
	setIsViewerOpen: (isViewerOpen: boolean) => void;
	errorInAllImages?: boolean;
	isAtleastOneImageReady: boolean;
	loadedImages: [];
	campaignImagesGenerated: boolean;
};

const CampaignGalleryPreview = ({
	images,
	isViewerOpen,
	errorGenerating,
	setIsViewerOpen,
	errorInAllImages,
	isAtleastOneImageReady,
	loadedImages,
	campaignImagesGenerated,
}: Props) => {
	const { t } = useTranslation();
	const [currentImage, setCurrentImage] = useState(0);
	const [lastImages, setLastImages] = useState(images);

	const classes = useStyles({ hasResults: images.length > 0 });
	useEffect(() => {
		if (images.length > 0) {
			setLastImages(images);
		}
	}, [images]);

	const handleImageClick = (index: number) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	};

	return (
		<Box className={classes.previewWrapper}>
			<Box className={classes.preview}>
				<Box className={classes.gallery}>
					<LoadingAnimation
						color="#ededed"
						progressBarTime={25}
						loading={
							(!isAtleastOneImageReady && images.length > 0 && !(errorInAllImages || errorGenerating)) ||
							!campaignImagesGenerated
						}
						showLoadingStrings={true}
						loadingStringsTimeout={5000}
					/>
					{lastImages.length > 0 && isAtleastOneImageReady && campaignImagesGenerated
						? lastImages.map(
								(image: any, i) =>
									image?.loaded && (
										<img
											style={{
												maxHeight: loadedImages?.length > 1 ? "50%" : "80%",
												maxWidth: loadedImages?.length > 1 ? "50%" : "80%",
											}}
											onContextMenu={(e) => e.preventDefault()}
											key={i}
											src={image.src}
											className={classes.campaignImage}
											alt=""
											onClick={() => handleImageClick(i)}
										/>
									)
						  )
						: lastImages.length === 0 &&
						  !isAtleastOneImageReady &&
						  !errorGenerating &&
						  !errorInAllImages &&
						  campaignImagesGenerated && (
								<Box className={classes.campaignDefault}>
									<img
										onContextMenu={(e) => e.preventDefault()}
										src={campaignDefault}
										alt="campaign-default"
									/>
									<Box>
										<Typography>{t("instantlyGenerate")}</Typography>
										<Typography color="primary">{t("multipleCampaignAssets")}</Typography>
									</Box>
								</Box>
						  )}

					{errorInAllImages || (errorGenerating && campaignImagesGenerated) ? (
						<Box className={classes.errorState}>{t("somethingWentWrong")}</Box>
					) : (
						""
					)}
				</Box>
			</Box>
			<FullScreenViewer
				open={!!(isViewerOpen && lastImages)}
				src={lastImages.map((image: any) => image.src)}
				currentIndex={currentImage}
				onClose={() => setIsViewerOpen(false)}
			/>
		</Box>
	);
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	previewWrapper: (props) => ({
		"& .MuiButton-iconSizeMedium > *:first-child": {
			display: "flex",
			fontSize: "unset",
		},

		height: "93%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: props.hasResults ? "#FAFAFA" : "",
		borderRadius: "16px",
	}),
	preview: {
		position: "relative",
		display: "grid",
		justifyItems: "center",
		gap: "2vh",
		"& header": {
			fontWeight: 600,
			color: "black",
			fontSize: "min(2.7vh, 2vw, 23px)",
		},
	},
	gallery: {
		display: "flex",
		justifyContent: "center",
		gap: "2vh",
		width: "100%",
		flexFlow: "wrap",
		overflow: "auto",
		alignItems: "center",
		maxHeight: "74vh",
	},
	campaignImage: {
		maxWidth: "80%",
		maxHeight: "80%",
		borderRadius: "8px",
		transition: "transform 0.3s, cursor 0.3s, box-shadow 0.3s",
		"&:hover": {
			transform: "scale(1.02)",
			cursor: "pointer",
			boxShadow: "0 0 11px rgba(33,33,33,.2)",
		},
	},
	campaignDefault: (props) => ({
		position: "absolute",
		textAlign: "center",
		"& img": {
			width: "min(75vh, 50vw)",
		},
		"& > div": {
			position: "absolute",
			bottom: "15vh",
			left: "17vh",
			top: "60vh",
		},
		"& p": {
			fontFamily: "Montserrat",
			fontSize: "min(2vw, 40px)",
			fontWeight: "500",
			lineHeight: "56px",
			letterSpacing: "-0.5px",
			textAlign: "center",
			textWrap: "nowrap",
		},
	}),

	errorState: {
		position: "absolute",
		textAlign: "center",
		width: "min(50vh, 50vw)",
	},
}));
export default CampaignGalleryPreview;
