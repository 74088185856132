import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as HomeSvg } from "../assets/home-icon.svg";
import { ReactComponent as SearchSvg } from "../assets/search-white.svg";
import { ReactComponent as AssetsSvg } from "../assets/assets-icon.svg";
import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import RouterConstants from "../Constants/RouterConstants";
import ShowAt from "./Common/Wrappers/ShowAt";
import Icon from "./Common/Icon/Icon";
import { SubscriptionCreditsTypes } from "../Constants/SubscriptionConstants";
import Context from "../Context/Context";
import { IsGuestUser } from "../Helpers/isGuest";
import { IsIframe } from "../Helpers/iframe";

interface ISidebar {
	isDark?: boolean;
	px?: string;
	border?: string;
	hideTop?: boolean;
	dotColor?: string;
	style?: any;
}

const useStyles = makeStyles(() => ({
	sidebarRoot: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		position: "relative",
		paddingTop: 18,
		"& > img": {
			height: 20,
		},
		borderTop: "none",
		border: ({ border = "" }: ISidebar) => border,
		paddingInline: ({ px }: any) => px || "35px",
	},
	itemWrapper: {
		position: "relative",
	},
	bottomDot: {
		width: 5,
		height: 5,
		background: ({ dotColor }: ISidebar) => dotColor || "#fff",
		borderRadius: "50%",
		position: "absolute",
		bottom: 7,
		left: "50%",
		transform: "translateX(-50%)",
	},
	item: {
		color: "#fff",
		textAlign: "center",
		fontSize: 10,
		letterSpacing: 0.5,
		margin: "25px 0px",
		cursor: "pointer",
		position: "relative",
		opacity: 0.9,
		filter: (props: any) => props.isDark && "brightness(0)",
		// "&.active:after": {
		// 	content: "''",
		// 	width: 5,
		// 	height: 5,
		// 	background: "#fff",
		// 	borderRadius: "50%",
		// 	position: "absolute",
		// 	bottom: -11,
		// 	left: "50%",
		// 	transform: "translateX(-50%)",
		// },
	},
	itemLabel: {
		fontSize: 10,
		fontWeight: 400,
		marginTop: 5,
	},
	hideTop: {
		position: "absolute",
		width: "100%",
		top: -10,
		left: 0,
		minHeight: 10,
		zIndex: 1200,
		backgroundColor: "#fff",
	},
}));

const Sidebar = ({ isDark, px, border, hideTop, style, ...rest }: ISidebar) => {
	const classes = useStyles({ isDark, px, border, ...rest });
	const history = useHistory();
	const { pathname } = useLocation();
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const context = useContext(Context);
	const { isGuestUser } = IsGuestUser();
	const { isIframe } = IsIframe();

	const links = [
		{
			title: "Home",
			path: RouterConstants.HOME.path,
			showAtProps: {
				showAlways: true,
			},
			icon: <HomeSvg />,
		},
		{
			title: "My Assets",
			path: RouterConstants.ASSETS_PAGE.path,
			showAtProps: {
				showAlways: true,
			},
			icon: <AssetsSvg />,
			subscriptionCreditType: SubscriptionCreditsTypes.MY_ASSETS,
		},
		{
			title: "Search",
			path: RouterConstants.GALLERY.path,
			showAtProps: {
				showAlways: true,
			},
			icon: <SearchSvg />,
		},
		{
			title: "Brand",
			path: RouterConstants.BRAND_PAGE.path,
			showAtProps: {
				testing: true,
				admin: true,
			},
			icon: <Icon name="brand-icon" style={{ filter: "brightness(100)" }} />, //TODO: fix the svg color issue when using it in img tag
		},
	];

	useEffect(() => {
		if (!isGuestUser()) {
			switch (context.authDialogStep) {
				case "Login":
				case "RegisterInfo":
					if (context.authDialogSource === "Create Brand") {
						context.setAuthDialogSource("");
						context.setAuthDialogState(false);
						history.push(RouterConstants.BRAND_PAGE.path);
					}
			}
		}
	}, [context.user, context.user?.updated]);

	const handleItemClick = async (link: any, index: number) => {
		// if (link.subscriptionCreditType) {
		// 	const hasCredits = await checkIfUserHasCredits(history, link.subscriptionCreditType, isIframe(), context);
		// 	if (!hasCredits) return;
		// }
		setActiveItemIndex(index);
		if (link.title === "Brand") {
			if (!isGuestUser()) {
				history.push(link.path);
			} else {
				context.setAuthDialogSource("Create Brand");
				context.setAuthDialogState(true);
			}
		} else {
			history.push(link.path);
		}
	};

	const isCurrentSelected = (link: any) => pathname === link.path;
	return (
		<Box className={classes.sidebarRoot} style={{ ...style }}>
			{hideTop && <Box className={classes.hideTop} />}
			{links.map((link, index) => (
				<ShowAt {...link.showAtProps} key={index}>
					<Box className={classes.itemWrapper}>
						<Box
							key={link.path}
							className={clsx(classes.item, isCurrentSelected(link) && "active")}
							onClick={() => {
								handleItemClick(link, index);
							}}
						>
							{link.icon}
							<Typography className={classes.itemLabel}>{link.title}</Typography>
						</Box>
						{isCurrentSelected(link) && <Box className={classes.bottomDot} />}
					</Box>
				</ShowAt>
			))}
		</Box>
	);
};

export default Sidebar;
