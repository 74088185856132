import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function MagicalRemovel(props: SvgIconProps) {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.7143 5L15.2143 7.5M5.21429 17.5L17.7143 5L15.2143 2.5L2.71429 15L5.21429 17.5ZM7.71429 2.5C7.71429 2.94203 7.88989 3.36595 8.20245 3.67851C8.51501 3.99107 8.93893 4.16667 9.38096 4.16667C8.93893 4.16667 8.51501 4.34226 8.20245 4.65482C7.88989 4.96738 7.71429 5.39131 7.71429 5.83333C7.71429 5.39131 7.5387 4.96738 7.22614 4.65482C6.91358 4.34226 6.48966 4.16667 6.04763 4.16667C6.48966 4.16667 6.91358 3.99107 7.22614 3.67851C7.5387 3.36595 7.71429 2.94203 7.71429 2.5ZM16.0476 10.8333C16.0476 11.2754 16.2232 11.6993 16.5358 12.0118C16.8483 12.3244 17.2723 12.5 17.7143 12.5C17.2723 12.5 16.8483 12.6756 16.5358 12.9882C16.2232 13.3007 16.0476 13.7246 16.0476 14.1667C16.0476 13.7246 15.872 13.3007 15.5595 12.9882C15.2469 12.6756 14.823 12.5 14.381 12.5C14.823 12.5 15.2469 12.3244 15.5595 12.0118C15.872 11.6993 16.0476 11.2754 16.0476 10.8333Z"
				stroke={props.color ? props.color : "#5300C9"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default MagicalRemovel;
