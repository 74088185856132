import { MutationFunctionOptions } from "@apollo/client";
import { useContext, useState } from "react";
import { GENERAL_ORG_ID } from "../../Constants";
import { IframeApplyChangePostMessageTypes, IframePostMessageTypes } from "../../Constants/IframeConstants";
import Context from "../../Context/Context";
import { sendPostMessage } from "../../Helpers/iframe";
import BriaAPI from "../../sdk/resources/briaAPI";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import Scene from "../../sdk/resources/element";
import { ImageSemantic, PipelineSetting, Rect } from "../../types/graphql-global-types";
import { SelectedPointInterface } from "./utilities";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";

export interface ExtremeExpressionVariables {
	ethnicity: string;
	ethnicityValue?: number | null;
	inputImage?: string | null;
	objectType?: string | null;
	pipelineSettings?: (PipelineSetting | null)[] | null;
	rects?: (Rect | null)[] | null;
	semantics?: (ImageSemantic | null)[] | null;
	uid: string;
	visualHash: string;
	emotion: string;
	sliderScale: number;
}

export const useExtremeExpression = (imageInfo: Scene[] | null) => {
	const { shouldClearWatermark } = ShouldClearWatermark();
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [selectedExpressionValue, setSelectedExpressionValue] = useState<number>(0);
	const [selectedExpressionName, setSelectedExpressionName] = useState<string>("");
	const [validExpressions, setValidExpressions] = useState<string[]>([]);

	const [createExtremeExpressionMutationLoading, setCreateExtremeExpressionMutationLoading] =
		useState<boolean>(false);

	const getValidExpressions = (selectedPoint: SelectedPointInterface | any | null) => {
		let validExp: string[] = [];
		if (imageInfo && selectedPoint) {
			const selectedPointInfo = imageInfo.find((item) => item.id === selectedPoint.id);
			if (selectedPointInfo && selectedPointInfo.actions && selectedPointInfo.actions.expression) {
				validExp = selectedPointInfo.actions.expression;
			}
		}
		setValidExpressions(validExp);
	};

	const createExtremeExpressionMutation = async (
		faceId: string,
		options: MutationFunctionOptions<ExtremeExpressionVariables>
	) => {
		if (!options.variables) {
			throw new Error("variables not provided");
		}

		setCreateExtremeExpressionMutationLoading(true);
		const bria = BriaAPI.getInstance(options.variables.visualHash, context.iframeConfig);

		const actions = bria.mapPipelineInputToAPI(options.variables as ExtremeExpressionVariables);
		const apiPipelineSettings = bria.mapPipelineSettingsToApi(options.variables.pipelineSettings);
		try {
			const res = await bria.callApi(
				ApiActions.FACE_MANIPULATIONS,
				{
					changes: [
						{
							id: faceId,
							actions,
							change_skin: true,
						},
					],
					image_url: options.variables.inputImage,
					apiPipelineSettings,
				},
				shouldClearWatermark(),
				organizationUid
			);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.Expression,
					action: actions.expression,
				},
				options.variables.visualHash,
				context.iframeConfig
			);
			setCreateExtremeExpressionMutationLoading(false);
			return {
				data: {
					addExtremeExpression: {
						newImage: {
							url: res.data.image_res,
							__typename: "NewImage",
						},
						layersUrl: res.data.layers_url,
						__typename: "AddExtremExpression",
					},
				},
			};
		} catch (e) {
			console.log(e);
			setCreateExtremeExpressionMutationLoading(false);
			throw e;
		}
	};

	const setExtremeExpression = (emotion: string, value: number) => {
		setSelectedExpressionName(emotion);
		setSelectedExpressionValue(value);
	};

	const resetExtremeExpression = () => {
		setExtremeExpression("", 0);
	};

	return {
		getValidExpressions,
		createExtremeExpressionMutation,
		setExtremeExpression,
		resetExtremeExpression,
		selectedExpressionValue,
		selectedExpressionName,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError: undefined,
		validExpressions,
		isLoadingExpression: false,
	};
};
