import { useContext } from "react";
import Context from "../Context/Context";
import { IsGuestUser } from "./isGuest";

export const ShouldClearWatermark = () => {
	const context = useContext(Context);
	const { isGuestUser } = IsGuestUser();

	const shouldClearWatermark = () => {
		return true;
	};

	return { shouldClearWatermark };
};
