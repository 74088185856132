import React from "react";
import SettingsSliders from "./SettingsSliders/SettingsSliders";
import ColorMixer from "../ColorMixer/ColorMixer";
import ColorSettings from "./ColorSettings/ColorSettings";
import { createContext } from "react";
import { useState } from "react";
import { defaultSelectedColor } from "../data/sliders";

export const SliderSettingsContext = createContext({
	localSelectedColor: "",
	setLocalSelectedColor: (color: string) => {},
});

export default function Sliders() {
	const [localSelectedColor, setLocalSelectedColor] =
		useState(defaultSelectedColor);

	const contextValue = {
		localSelectedColor,
		setLocalSelectedColor
	};
	return (
		<SliderSettingsContext.Provider value={contextValue}>
			<SettingsSliders />
			{/* <ColorMixer /> */}
			<ColorSettings />
		</SliderSettingsContext.Provider>
	);
}
