import { Box, createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import ToolHeader from "../Components/Tools/ToolHeader";
import React, { useContext, useEffect, useState } from "react";
import DotBackground from "../assets/svgs/dot_bg.svg";
import CampainGeneration from "./CampainGeneration";
import CampainInfo from "./CampainInfo";
import Context from "../Context/Context";
import client from "../GraphQL/client";
import { getOrganizationFonts, getOrganizationFontsVariables } from "../GraphQL/types/getOrganizationFonts";
import {
	GET_ORGANIZATION_BRAND_PRESETS,
	GET_ORGANIZATION_CHANNELS,
	GET_ORGANIZATION_CHANNELS_BY_TEMPLATE_ID,
	GET_ORGANIZATION_CHANNELS_BY_TEMPLATES_IDS,
	GET_ORGANIZATION_FONTS,
	GET_ORGANIZATION_PLACEMENTS_BY_CHANNEL_IDS,
	GET_ORGANIZATION_TEMPLATES,
	GET_ORGANIZATION_TEMPLATES_BY_CHANNEL_IDS,
} from "../GraphQL/queries";
import { getOrganizationChannels, getOrganizationChannelsVariables } from "../GraphQL/types/getOrganizationChannels";
import {
	getOrganizationBrandPresets,
	getOrganizationBrandPresetsVariables,
} from "../GraphQL/types/getOrganizationBrandPresets";
import {
	getOrganizationPlacementsByChannelIds,
	getOrganizationPlacementsByChannelIdsVariables,
} from "../GraphQL/types/getOrganizationPlacementsByChannelIds";
import { getOrganizationTemplates, getOrganizationTemplatesVariables } from "../GraphQL/types/getOrganizationTemplates";
import { IsIframe, sendPostMessage } from "../Helpers/iframe";
import { IframePostMessageTypes } from "../Constants/IframeConstants";
import { useHistory, useLocation } from "react-router-dom";
import RouterConstants from "../Constants/RouterConstants";
import {
	getOrganizationTemplatesByChannelIds,
	getOrganizationTemplatesByChannelIdsVariables,
} from "../GraphQL/types/getOrganizationTemplatesByChannelIds";
import {
	getOrganizationChannelsByTemplateId,
	getOrganizationChannelsByTemplateIdVariables,
} from "../GraphQL/types/getOrganizationChannelsByTemplateId";
import {
	getOrganizationChannelsByTemplatesIds,
	getOrganizationChannelsByTemplatesIdsVariables,
} from "../GraphQL/types/getOrganizationChannelsByTemplatesIds";
import { BRIA_ORG_ID } from "../Constants";

const Campain = () => {
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	const location = useLocation();
	const queryParams = isIframe() ? context.iframeConfig?.passedQueryParams : new URLSearchParams(location.search);

	const history = useHistory();
	const [showNewContent, setShowNewContent] = useState(false);
	const classes = useStyles();
	const passedImage = (location?.state as any) || queryParams;
	const [passedImageFromGallery, setPassedImageFromGallery] = useState(passedImage);

	let orgId: string;
	if (isIframe()) {
		orgId = context.iframeConfig?.organization ?? BRIA_ORG_ID;
	} else {
		orgId = context.selectedOrganization?.uid ?? BRIA_ORG_ID;
	}

	function prioritizePrivate(objArray: any, privateGroupName: string, publicGroupName: string) {
		let new_arr = [];
		if (objArray?.length == 0) {
			return objArray;
		}
		const publicIndex = objArray?.findIndex((group: any) => group.name === publicGroupName);
		const privateIndex = objArray?.findIndex((group: any) => group.name === privateGroupName);
		if (publicIndex !== -1 && privateIndex !== -1 && publicIndex < privateIndex) {
			new_arr.push(objArray[privateIndex]);
			new_arr.push(objArray[publicIndex]);
			return new_arr;
		} else {
			return objArray;
		}
	}

	const formatFontsByGropus = (fonts: any) => {
		const groupedFonts = fonts?.reduce((acc: any, font: any) => {
			const orgId: any = font?.orgId;
			if (!acc[orgId]) {
				acc[orgId] = [];
			}
			acc[orgId].push({
				id: font.id,
				label: font.name,
				value: font.name,
				src: font.src,
			});
			return acc;
		}, {});
		const orgFonts =
			groupedFonts &&
			Object.entries(groupedFonts).map(([orgId, options]) => ({
				name: orgId === BRIA_ORG_ID ? "publicFonts" : "privateFonts",
				options,
			}));
		return prioritizePrivate(orgFonts, "privateFonts", "publicFonts");
	};

	const formatChannelsAndTemplatesByGropus = (Data: any) => {
		const groupedData = Data?.reduce((acc: any, dat: any) => {
			const orgId: any = dat?.orgId;
			if (!acc[orgId]) {
				acc[orgId] = [];
			}
			acc[orgId].push({
				id: dat.id,
				label: dat.name,
				value: dat.name,
			});
			return acc;
		}, {});
		const orgData =
			groupedData &&
			Object.entries(groupedData).map(([orgId, options]) => ({
				name: orgId === BRIA_ORG_ID ? "Public" : "Private",
				options,
			}));

		return prioritizePrivate(orgData, "Private", "Public");
	};

	const getOrgFonts = async () => {
		const userRes = await client.query<getOrganizationFonts, getOrganizationFontsVariables>({
			query: GET_ORGANIZATION_FONTS,
			variables: {
				orgId: orgId,
			},
		});
		const orgFonts = formatFontsByGropus(userRes?.data?.getOrganizationFonts);

		return orgFonts;
	};

	const getOrgChannels = async () => {
		const userRes = await client.query<getOrganizationChannels, getOrganizationChannelsVariables>({
			query: GET_ORGANIZATION_CHANNELS,
			variables: {
				orgId: orgId,
			},
		});
		const orgChannels = formatChannelsAndTemplatesByGropus(userRes?.data?.getOrganizationChannels);
		return orgChannels;
	};

	const getTemplates = async () => {
		const userRes = await client.query<getOrganizationTemplates, getOrganizationTemplatesVariables>({
			query: GET_ORGANIZATION_TEMPLATES,
			variables: {
				orgId: orgId,
			},
		});

		const orgTemplates = formatChannelsAndTemplatesByGropus(userRes?.data?.getOrganizationTemplates);
		return orgTemplates;
	};
	// const getOrgPlacements = async () => {
	// 	const orgPlacement = await client.query<getOrganizationPlacements, getOrganizationPlacementsVariables>({
	// 		query: GET_ORGANIZATION_PLACEMENTS,
	// 		variables: {
	// 			orgId:
	// 				(context?.user?.organizations?.length > 0 && context?.user?.organizations[0]?.org_uid) ||
	// 				BRIA_ORG_ID,
	// 		},
	// 	});

	// 	var result: any = [];

	// 	context?.orgChannels?.forEach((channel_group: any) => {
	// 		let channel_group_name = channel_group.name;
	// 		let channel_options = channel_group.options;

	// 		let placements_for_channel = orgPlacement?.data?.getOrganizationPlacements?.filter((placement: any) =>
	// 			channel_options.some((option: any) => placement.channelId === option.id)
	// 		);

	// 		if (placements_for_channel && placements_for_channel?.length > 0) {
	// 			result.push({
	// 				name: channel_group_name,
	// 				options: placements_for_channel?.map((placement: any) => {
	// 					let relatedOption = channel_options.find((option: any) => placement.channelId === option.id);
	// 					return {
	// 						channelName: relatedOption.value,
	// 						placemantName: placement.name,
	// 						placementsize: `${placement.width}X${placement.height}`,
	// 						id: placement.id,
	// 						name: `${placement.name}`,
	// 						label: ` ${placement.name}`,
	// 					};
	// 				}),
	// 			});
	// 		}
	// 	});
	// 	const filteredOrgGroupedPlacement = result.filter((group: any) => group.options.length > 0);
	// 	return filteredOrgGroupedPlacement;
	// };

	const getOrgBrands = async () => {
		const responseData = await client.query<getOrganizationBrandPresets, getOrganizationBrandPresetsVariables>({
			query: GET_ORGANIZATION_BRAND_PRESETS,
			variables: {
				orgId: orgId || "",
			},
		});
		if (responseData && responseData.data && responseData.data.getOrganizationBrandPresets) {
			const updatedBrandOptions = responseData.data.getOrganizationBrandPresets.map((brand: any) => ({
				...brand,
				id: brand.id,
				title: brand.name,
				brandDropDown: true,
			}));
			return updatedBrandOptions;
		}
		return [];
	};

	const updatePlacementValuesBasedOnChannlesSelection = async () => {
		const channels_selected_values = context.selectedChannelValues;
		const intArray = channels_selected_values.map(Number);
		const orgPlacement = await client.query<
			getOrganizationPlacementsByChannelIds,
			getOrganizationPlacementsByChannelIdsVariables
		>({
			query: GET_ORGANIZATION_PLACEMENTS_BY_CHANNEL_IDS,
			variables: {
				channelIds: intArray,
			},
		});
		let result: any = [];

		context?.orgChannels?.forEach((channel_group: any) => {
			let channel_group_name = channel_group.name;
			let channel_options = channel_group.options;

			let placements_for_channel = orgPlacement?.data?.getOrganizationPlacementsByChannelIds?.filter(
				(placement: any) => channel_options.some((option: any) => placement.channelId === option.id)
			);

			if (placements_for_channel && placements_for_channel?.length > 0) {
				result.push({
					name: channel_group_name,
					options: placements_for_channel?.map((placement: any) => {
						let relatedOption = channel_options.find((option: any) => placement.channelId === option.id);
						return {
							channelName: relatedOption.value,
							placemantName: placement.name,
							placementWidth: placement.width,
							placementHeight: placement.height,
							id: placement.id,
							name: `${placement.name}`,
							label: `${placement.name}`,
						};
					}),
				});
			}
		});
		const filteredOrgGroupedPlacement = result.filter((group: any) => group.options.length > 0);
		return filteredOrgGroupedPlacement;
	};

	const fetchTemplatesBasedOnChannelsSelections = async () => {
		const channels_selected_values = context.selectedChannelValues;
		const intArray = channels_selected_values.map(Number);
		const templates = await client.query<
			getOrganizationTemplatesByChannelIds,
			getOrganizationTemplatesByChannelIdsVariables
		>({
			query: GET_ORGANIZATION_TEMPLATES_BY_CHANNEL_IDS,
			variables: {
				channelIds: intArray,
				orgId: orgId,
			},
		});
		const orgTemplates = formatChannelsAndTemplatesByGropus(templates?.data?.getOrganizationTemplatesByChannelIds);

		return orgTemplates;
	};

	const fetchChannelsBasedOnTemplateSelection = async () => {
		const channels = await client.query<
			getOrganizationChannelsByTemplateId,
			getOrganizationChannelsByTemplateIdVariables
		>({
			query: GET_ORGANIZATION_CHANNELS_BY_TEMPLATE_ID,
			variables: {
				templateId: Number(context.selectedTemplate),
				orgId: orgId,
			},
		});
		const orgChannels = formatChannelsAndTemplatesByGropus(channels?.data?.getOrganizationChannelsByTemplateId);
		return orgChannels;
	};

	useEffect(() => {
		const fetchChannelsBasedOnTemplate = async () => {
			context.setLoadingChannels(true);
			let orgChannels: any;
			if (!context.selectedTemplate) {
				const idValues = context.orgTemplates?.flatMap((item) => item.options.map((option) => option.id));
				getOrgChannelsByTemplateIds(idValues).then((orgChannels) => {
					context.setOrgChannels(orgChannels);
					const updatedSelectedChannels = context.selectedChannelValues.filter((selectedId) => {
						return orgChannels.some((orgChannel: any) => {
							return orgChannel.options.some((option: any) => option.id === selectedId);
						});
					});
					context.setSelectedChannelValues(updatedSelectedChannels);
				});
			} else {
				orgChannels = await fetchChannelsBasedOnTemplateSelection();
				context.setOrgChannels(orgChannels);
				const updatedSelectedChannels = context.selectedChannelValues.filter((selectedId) => {
					return orgChannels.some((orgChannel: any) => {
						return orgChannel.options.some((option: any) => option.id === selectedId);
					});
				});
				context.setSelectedChannelValues(updatedSelectedChannels);
			}

			context.setLoadingChannels(false);
		};
		fetchChannelsBasedOnTemplate();
	}, [context.selectedTemplate]);

	useEffect(() => {
		const fetchTemplatesBasedOnChannels = async () => {
			context.setLoadingTemplates(true);
			let orgTemplates;
			if (context.selectedChannelValues.length == 0) {
				orgTemplates = await getTemplates();
			} else {
				orgTemplates = await fetchTemplatesBasedOnChannelsSelections();
			}
			context.updateOrgTemplates(orgTemplates);
			context.setLoadingTemplates(false);
		};
		fetchTemplatesBasedOnChannels();
	}, [context.selectedChannelValues]);

	useEffect(() => {
		const fetchBrandsData = async () => {
			context.setloadingBrands(true);
			const updatedBrandOptions: any = await getOrgBrands();
			context.setOrgBrands(updatedBrandOptions);
			context.setloadingBrands(false);
		};

		const fetchFontsData = async () => {
			const fontOptions = await getOrgFonts();
			context.updateOrgFonts(fontOptions);
		};

		const fetchTemplates = () => {
			context.setLoadingTemplates(true);
			context.setLoadingChannels(true);
			getTemplates()
				.then((dynamicTemplates) => {
					context.updateOrgTemplates(dynamicTemplates);
					context.setLoadingTemplates(false);
					const idValues = dynamicTemplates?.flatMap((item) => item.options.map((option) => option.id));

					getOrgChannelsByTemplateIds(idValues)
						.then((orgChannels) => {
							context.setOrgChannels(orgChannels);
							context.setLoadingChannels(false);
						})
						.catch((error) => {
							console.error("Error fetching organization channels:", error);
							context.setLoadingChannels(false);
						});
				})
				.catch((error) => {
					console.error("Error fetching templates:", error);
					context.setLoadingTemplates(false);
				});
		};
		fetchBrandsData();
		fetchFontsData();
		fetchTemplates();
		sendPostMessage(IframePostMessageTypes.InitialLoadComplete, {}, "", context.iframeConfig);
	}, []);

	const getOrgChannelsByTemplateIds = async (idValues: number[]) => {
		const userRes = await client.query<
			getOrganizationChannelsByTemplatesIds,
			getOrganizationChannelsByTemplatesIdsVariables
		>({
			query: GET_ORGANIZATION_CHANNELS_BY_TEMPLATES_IDS,
			variables: {
				templatesIds: idValues,
			},
		});
		const orgChannels = formatChannelsAndTemplatesByGropus(userRes?.data?.getOrganizationChannelsByTemplatesIds);
		return orgChannels;
	};

	// useEffect(() => {
	// 	const fetchPlacementsData = async () => {
	// 		const orgPlacements = await getOrgPlacements();
	// 		context.setOrgPlacements(orgPlacements);
	// 		context.setLoadingPlacements(false);
	// 	};
	// 	fetchPlacementsData();
	// }, [context.orgChannels]);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		context.setLoadingPlacements(true);
	// 		let updatedPlacementValues;
	// 		if (context.selectedChannelValues.length == 0) {
	// 			updatedPlacementValues = await getOrgPlacements();
	// 		} else {
	// 			updatedPlacementValues = await updatePlacementValuesBasedOnChannlesSelection();
	// 		}
	// 		context.setOrgPlacements(updatedPlacementValues);

	// 		context.setLoadingPlacements(false);
	// 	};

	// 	fetchData();
	// }, [context.selectedChannelValues]);
	useEffect(() => {
		context.setCurrentCampainVhash(passedImage?.vhash);

		context.setSelectedChannelValues([]);
		context.setSelectedDropDownValue({});
		context.setSelectedTemplate("");
		context.setCampaignName("");
		if (passedImage?.imageUrl || passedImage?.vhash) {
			history.replace(RouterConstants.campaignGeneration.path);
		}
	}, []);

	return (
		<>
			<Box className={classes.fullWidth}>
				{(!isIframe() || (isIframe() && !context.iframeConfig?.embeddedIframe)) && (
					<ToolHeader
						isCampaign={true}
						imageName={context.currentCampainVhash ? context.currentCampainVhash : "8679a552aa79cc96"}
					/>
				)}
				<Box className={classes.containerWrapper}>
					{!showNewContent && (
						<CampainInfo
							handleGetStartedButton={() => {
								setShowNewContent(true);
							}}
						/>
					)}
					{showNewContent && (
						<CampainGeneration
							handleBackButton={() => {
								setShowNewContent(false);
							}}
							passedImage={passedImageFromGallery}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fullWidth: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
		},
		containerWrapper: {
			backgroundImage: `url(${DotBackground})`,
			padding: "32px",
			height: "100%",
			overflowY: "hidden",
			"@media (max-width:850px)": {
				overflowX: "scroll",
			},
		},
	})
);

export default Campain;
