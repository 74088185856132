import { useTheme } from "@material-ui/core";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DropdownArrow = (props: SvgIconProps) => {
	const theme = useTheme();
	return (
		<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M1 1L7 7L13 1"
				stroke={theme.palette.primary.dark}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DropdownArrow;
