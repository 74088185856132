import { Box, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CustomCheckbox } from "../../../Tools/ObjectRemover";
import { useTranslation } from "react-i18next";

type Props = {
	colorsCheckbox: boolean;
	brandCheckbox: boolean;
	selectedBrand: any;
	setColorsCheckbox: (isChecked: boolean) => void;
	setBrandCheckbox: (isChecked: boolean) => void;
};

const CampaignCheckboxes = ({
	colorsCheckbox,
	brandCheckbox,
	selectedBrand,
	setColorsCheckbox,
	setBrandCheckbox,
}: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const handleColorsCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setColorsCheckbox(event.target.checked);
	};

	const handleBrandCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBrandCheckbox(event.target.checked);
	};
	return (
		<Box className={classes.checkbox}>
			<Box className={classes.checkbox}>
				<FormControlLabel
					control={<CustomCheckbox checked={colorsCheckbox} onChange={handleColorsCheckbox} />}
					label={<Typography className={classes.checkboxLable}>{t("applyCampaignColors")}</Typography>}
				/>
			</Box>
			<Box className={classes.checkbox}>
				<FormControlLabel
					control={<CustomCheckbox checked={brandCheckbox} onChange={handleBrandCheckbox} />}
					label={<Typography className={classes.checkboxLable}>{t("applyBrandSentiment")}</Typography>}
					disabled={!selectedBrand || selectedBrand.mood === null}
				/>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	checkbox: {
		display: "flex",
		alignItems: "center",
		"& svg": {
			width: "19px",
			height: "19px",
		},
	},
	checkboxLable: {
		fontFamily: "Montserrat",
		fontSize: "min(1.3vw, 14px)",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
	},
}));
export default CampaignCheckboxes;
