import React, { useEffect } from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const FullscreenIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 16 14" {...props}>
			<path
				fill="none"
				d="M11.0012 0.600098H15.2014M15.2014 0.600098V4.33343M15.2014 0.600098L10.4012 4.86676M0.800781 9.66676V13.4001M0.800781 13.4001H5.00096M0.800781 13.4001L5.60099 9.13343"
				stroke={props.stroke ? props.stroke : "white"}
			/>
		</SvgIcon>
	);
};

export default FullscreenIcon;
