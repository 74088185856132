import { Dispatch, SetStateAction } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";

interface IProps {
	openVideoReadyPopup: boolean;
	setOpenVideoReadyPopup: Dispatch<SetStateAction<boolean>>;
	onView: () => void;
}

const VideoReadyPopup = ({
	openVideoReadyPopup,
	setOpenVideoReadyPopup,
	onView,
}: IProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleCloseVideoReadyPopup = () => {
		setOpenVideoReadyPopup(false);
	};

	return (
		<Dialog
			onClose={handleCloseVideoReadyPopup}
			aria-labelledby="simple-dialog-title"
			open={openVideoReadyPopup}
			// open={true}
			maxWidth={false}
			scroll="paper"
			classes={{
				scrollPaper: classes.topScrollPaper,
				// paperScrollBody: classes.topPaperScrollBody,
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				className={classes.popup}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography className={classes.text}>
						{t("yourVideoIsReady")}
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						style={{ alignSelf: "flex-end" }}
						onClick={handleCloseVideoReadyPopup}
					>
						<CloseIcon style={{ fontSize: 32 }} />
					</IconButton>
				</Box>

				<Typography className={classes.bodyText}>
					{t("pressToViewYourVideo")}
				</Typography>

				<Box display="flex" justifyContent="space-between" width="100%">
					<BriaButton
						buttonType={BUTTONTYPES.SECONDARY}
						onClick={handleCloseVideoReadyPopup}
					>
						{t("cancel")}
					</BriaButton>

					<BriaButton
						buttonType={BUTTONTYPES.SECONDARY}
						className={classes.button}
						onClick={() => {
							onView();
							handleCloseVideoReadyPopup();
						}}
					>
						{t("view")}
					</BriaButton>
				</Box>
			</Box>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popup: {
			margin: theme.spacing(3),
			width: 428,
			height: 156,
		},
		text: {
			fontSize: 24,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.text.primary,
		},
		button: {
			width: 99,
			height: 40,
		},
		bodyText: {
			fontSize: 14,
			fontWeight: 300,
			color: "#2C2738",
			height: 60,
		},
		topScrollPaper: {
			alignItems: "flex-start",
		},
		// topPaperScrollBody: {
		// 	verticalAlign: "top",
		// },
	})
);

export default VideoReadyPopup;
