import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import React from "react";
import { ApiSubscriptionTypes } from "../../Constants/SubscriptionConstants";

interface IProps {
	plan: ApiSubscriptionTypes;
}

const ConsumptionFeeInfoTable = ({ plan }: IProps) => {
	const data = {
		[ApiSubscriptionTypes.none]: [],
		[ApiSubscriptionTypes.starter]: [],
		[ApiSubscriptionTypes.ultimate]: [],
		[ApiSubscriptionTypes.pro]: [
			{ id: 1, column1: "Up to 1,000", column2: "$0" },
			{ id: 2, column1: "1,001 - 10,000", column2: "$0.06" },
			{ id: 3, column1: "10,001 - 100,000", column2: "$0.04" },
			{ id: 4, column1: "100,001 - 500,000", column2: "$0.02" },
			{ id: 5, column1: "More than 500,000", column2: "Contact Us" },
		],
		[ApiSubscriptionTypes.basic]: [
			{ id: 1, column1: "Up to 100", column2: "$0" },
			{ id: 2, column1: "101 - 1,000", column2: "$0.08" },
			{ id: 3, column1: "1,001 - 10,000", column2: "$0.06" },
			{ id: 4, column1: "10,001 - 500,000", column2: "$0.04" },
			{ id: 5, column1: "More than 500,000", column2: "Contact Us" },
		],
		[ApiSubscriptionTypes.ultimate]: [
			{ id: 1, column1: "Up to 100", column2: "$0" },
			{ id: 2, column1: "101 - 1,000", column2: "$0.04" },
			{ id: 3, column1: "1,001 - 10,000", column2: "$0.02" },
			{ id: 4, column1: "10,001 - 500,000", column2: "$0.01" },
			{ id: 5, column1: "More than 500,000", column2: "$0.005" },
		],
	};

	const { t } = useTranslation("translation", { keyPrefix: "pricingPage" });
	const classes = useStyles();
	return data[plan].length > 0 ? (
		<Table>
			<TableHead className={classes.infoTableHead}>
				<TableRow>
					<TableCell className={classes.tableFirstHeader}> {t("monthlyAPICalls")}</TableCell>
					<TableCell className={classes.tableSecondHeader}>{t("pricePerCall")}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data[plan].map((row: any) => (
					<TableRow key={row.id}>
						<TableCell className={`${classes.tablerowsContentStyle} ${classes.firstColumnRows}`}>
							{row.column1}
						</TableCell>
						<TableCell className={classes.tablerowsContentStyle}>{row.column2}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	) : (
		<></>
	);
};
const useStyles = makeStyles((theme) => ({
	infoTableHead: {
		borderBottom: "2px solid #E7E7E7",
	},
	tableFirstHeader: {
		whiteSpace: "nowrap",
		paddingLeft: "0px",
		color: "#5B5B5B",
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "normal",
	},
	tableSecondHeader: {
		whiteSpace: "nowrap",
		color: "#5B5B5B",
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "normal",
	},
	tablerowsContentStyle: {
		color: "#1A0638",
		fontWeight: 500,
		fontSize: "16px",
		lineHeight: "normal",
		whiteSpace: "nowrap",
	},
	firstColumnRows: {
		paddingLeft: "0px",
	},
}));

export default ConsumptionFeeInfoTable;
