import React from "react";

function PresenterIcon({ color }: any) {
	return (
		<svg
			width="14"
			height="21"
			viewBox="0 0 14 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 19.5V17.5C1 16.4391 1.42143 15.4217 2.17157 14.6716C2.92172 13.9214 3.93913 13.5 5 13.5H9C10.0609 13.5 11.0783 13.9214 11.8284 14.6716C12.5786 15.4217 13 16.4391 13 17.5V19.5M11 5.5C11 7.70914 9.20914 9.5 7 9.5C4.79086 9.5 3 7.70914 3 5.5C3 3.29086 4.79086 1.5 7 1.5C9.20914 1.5 11 3.29086 11 5.5Z"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default PresenterIcon;
