import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import { Box, Theme, Typography } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import { useContext, useState } from "react";
import { uploadImageViaApi } from "./Tools/utilities";
import { uuid4 } from "@sentry/utils";
import RouterConstants from "../Constants/RouterConstants";
import { useHistory } from "react-router-dom";
import { MODIFIED_IMAGES_ORG_ID } from "../Constants";
import { useTranslation } from "react-i18next";
import { prepareImageForExport } from "../GraphQL/queries";
import { resize } from "./Tools/CropImgeTool/Crop";
import Context from "../Context/Context";

interface IProps {
	open: boolean;
	onCloseModal: () => void;
	image: any;
	selectedImageUrl: any;
	removeWatermark: boolean;
	isCropApplied: boolean;
	imageVH: any;
	title?: string;
	desciption?: string;
	note?: string;
	onContinuePressed?: () => void;
	croppedAreaPixels: any;
	croppedArea: any;
	rotation: any;
}

export default function SaveCropPopUp(props: IProps) {
	const { t } = useTranslation();
	const {
		open,
		onCloseModal,
		image,
		selectedImageUrl,
		removeWatermark,
		isCropApplied,
		croppedAreaPixels,
		croppedArea,
		rotation,
		imageVH,
		onContinuePressed,
		title = t("saveCropPopupTitle"),
		desciption = t("saveCropPopupDesc"),
		note = t("saveCropPopupNote"),
	} = props;
	const classes = useStyles({});
	const [errorPopUp, setErrorPopUp] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const pathname = RouterConstants.GALLERY.path;
	const history = useHistory();

	const context = useContext(Context);

	const saveNewHash = async () => {
		setLoading(true);
		let imageWithoutWatermark;
		if (removeWatermark && context.iframeConfig?.organization) {
			imageWithoutWatermark = await prepareImageForExport(selectedImageUrl, context.iframeConfig.organization);
			if (isCropApplied) {
				imageWithoutWatermark = await resize(
					imageWithoutWatermark,
					croppedAreaPixels,
					rotation,
					croppedArea.width,
					croppedArea.height
				);
			}
		} else {
			imageWithoutWatermark = image;
		}
		const fileData = await fetch(imageWithoutWatermark);
		const fileBlob = await fileData.blob();
		const file = new File([fileBlob], `${uuid4()}.png`, {
			type: "image/png",
		});
		const resp = await uploadImageViaApi(file, MODIFIED_IMAGES_ORG_ID, false);
		const res = resp.data as any;
		if (res && res.visualId && res.status) {
			if (imageVH === res.visualId) {
				setErrorPopUp(true);
			} else {
				history.push(`${pathname}${res.visualId}`);
				onContinuePressed && onContinuePressed();
				onCloseModal();
			}
		}
		setLoading(false);
	};
	return (
		<>
			<Dialog
				classes={{
					paper: classes.paper,
				}}
				onClose={() => {
					onCloseModal();
				}}
				open={open}
				maxWidth={false}
			>
				{!errorPopUp && (
					<Box>
						<Typography className={classes.title}>{title}</Typography>
						<Typography className={classes.description}>{desciption}</Typography>
						<Typography className={classes.note}>{note}</Typography>
						<Box className={classes.btnContainer}>
							<BriaButton
								disabled={loading}
								style={{ color: "#5300C9" }}
								className={classes.btnStyle}
								buttonType={BUTTONTYPES.TEXTSMALL}
								onClick={() => onCloseModal()}
							>
								{t("cancel")}
							</BriaButton>
							<BriaButton
								disabled={loading}
								onClick={saveNewHash}
								className={classes.btnStyle}
								buttonType={BUTTONTYPES.PRIMARYMEDIUM}
							>
								{t("saveNew")}
							</BriaButton>
						</Box>
					</Box>
				)}
				{errorPopUp && (
					<Box>
						<Typography className={classes.title}>Image failed to save</Typography>
						<Typography className={classes.description}>
							An error occurred while saving the image. Make more noticeable changes to the image and try
							again.
						</Typography>
						<BriaButton
							onClick={() => {
								onCloseModal();
								setErrorPopUp(false);
							}}
							className={classes.errorButtonStyle}
							buttonType={BUTTONTYPES.PRIMARYMEDIUM}
						>
							{t("gotIt")}
						</BriaButton>
					</Box>
				)}
			</Dialog>
		</>
	);
}

interface StyleProps {
	height?: string;
	width?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	paper: {
		width: 640,
		minHeight: 253,
		padding: 32,
		borderRadius: "8px !important",
		paddingBottom: 20,
	},
	title: {
		fontSize: 24,
		fontWeight: 600,
		marginBottom: 20,
		lineHeight: "29px",
		color: "#1A0638",
	},
	description: {
		fontSize: 16,
		fontWeight: 400,
		color: "#5B5B5B",
		marginBottom: 12,
	},
	errorButtonStyle: {
		width: 223,
		height: 40,
		float: "right",
		marginTop: 16,
	},
	note: {
		color: "#5B5B5B",
		fontWeight: 600,
		fontSize: 16,
	},
	btnContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 28,
	},
	btnStyle: {
		width: 120,
		height: 40,
		fontSize: 16,
		marginLeft: 10,
	},
}));
