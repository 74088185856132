import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import RouterConstants from "../../../../../Constants/RouterConstants";
import Context from "../../../../../Context/Context";
import { saveBrandStyle } from "../../../../../GraphQL/mutations";
import { saveBrandStyle_saveBrandStyle } from "../../../../../GraphQL/types/saveBrandStyle";
import Analytics, { ANALYTICS_EVENTS } from "../../../../../Models/Analytics";
import InlineAlert from "../../../../Common/InlineAlert/InlineAlert";
import BriaButton, { BUTTONTYPES } from "../../../../UI/BriaButton";
import { lowerCaseProp } from "../../../Mood/data/colors";
import { BrandContext } from "../../BrandPage/BrandPage";
import { CURRENT_MOOD_IS_DEFAULTED } from "../MarkAsDefault/MarkAsDefault";
import { IsIframe } from "../../../../../Helpers/iframe";

export default function SubmitReview() {
	const classes = useStyles();
	const context = useContext(Context);
	const history = useHistory();
	const { isIframe } = IsIframe();

	const { user } = context;
	const { next, brandState, generalState, setChangeId } = useContext(BrandContext);
	const [error, setError] = useState<string>();
	const _generalState = generalState as any;

	const currentMoodIsDefaulted = _generalState?.[CURRENT_MOOD_IS_DEFAULTED];

	const processColorMixer = (colorMixer: any = {}) =>
		colorMixer.map((colorObj: any) => lowerCaseProp(colorObj, "color"));

	const [selectedImage, setSelectedImage] = useState((brandState as any)?.selectedResultImage ?? "center");

	function getSelectedMoodSliders() {
		return selectedImage === "left"
			? (generalState as any)?.moodSlidersSuggestion1
			: selectedImage === "right"
			? (generalState as any)?.moodSlidersSuggestion2
			: (brandState as any)?.moodSliders;
	}

	const onBrandSubmit = async () => {
		await Analytics.logEvent(ANALYTICS_EVENTS.CREATE_BRAND);
		// const hasCredits = await checkIfUserHasCredits(
		// 	history,
		// 	SubscriptionCreditsTypes.CREATE_BRAND_STYLE,
		// 	isIframe(),
		// 	context
		// );
		// if (!hasCredits) return;

		const brandStateContect = brandState as any;
		let brandParameters = getSelectedMoodSliders();
		// rename color_mixer to colorMixer and process to lower case
		if (brandParameters && brandParameters.color_mixer) {
			brandParameters.colorMixer = processColorMixer(brandParameters.color_mixer);
			delete brandParameters.color_mixer;
		}

		const saveBrandResp: saveBrandStyle_saveBrandStyle | null = await saveBrandStyle(
			brandStateContect.name,
			brandStateContect.logo,
			brandStateContect.colorPallet,
			{
				parameters: brandParameters,
			},
			brandStateContect.gardian
		);
		if (saveBrandResp && saveBrandResp.ok) {
			if (currentMoodIsDefaulted && saveBrandResp.brandId) {
				user?.updateBrand({
					selected_brand: saveBrandResp.brandId ?? "",
				});
			}
			setChangeId(-1); // -1 as a mark that mood is saved
			history.push(RouterConstants.GALLERY.path);
		} else {
			setError("Couldn't save your brand style, please try again later.");
		}
	};

	return (
		<>
			<BriaButton style={{ flex: 1 }} onClick={onBrandSubmit} buttonType={BUTTONTYPES.PRIMARY}>
				Save and see in Gallery
			</BriaButton>
			{!!error && (
				<Box className={classes.errorInlineWrapper}>
					<Box className={classes.errorInnerWrapper}>
						<InlineAlert type="error" msg={error} />
					</Box>
				</Box>
			)}
		</>
	);
}

const useStyles = makeStyles(() => ({
	errorInlineWrapper: {
		position: "absolute",
		left: 0,
		bottom: "15%",
		width: "100%",
	},
	errorInnerWrapper: {
		padding: "0 40px",
	},
}));
