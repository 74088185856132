import { useContext } from "react";
import Context from "../Context/Context";

import { SystemViewTypes } from "./../Models/SystemView";

export const useSystemViewAt = (applyAtViewTypes: string[] = []) => {
	const { systemView } = useContext(Context);

	const applyAtView = (cb = () => {}) => {
		if (!applyAtViewTypes.includes(systemView)) return;
		cb();
	};

	const dontApplyAt = (cb = () => {}) => {
		if (applyAtViewTypes.includes(systemView)) return;
		cb();
	};
	return [applyAtView, dontApplyAt];
};
