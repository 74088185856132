import React, {
	Dispatch,
	ReactElement,
	RefObject,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Personalizer from "./Personalizer";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Localizer from "./Diversifier";
import { DrawerProps } from "@material-ui/core/Drawer";
import {
	BriaObjectType,
	SelectedPointInterface,
	ToolsBackGroundBlurMode,
	ToolsBackGroundMode,
	ToolsViewerMode,
} from "./utilities";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { ApolloError } from "@apollo/client";
import Context from "../../Context/Context";
import { ROLES } from "../../Routes";
import BriaBadge from "../UI/BriaBadge";
import {
	ThumbnailImage,
	ToolConfigTabsTypes,
	ToolsSlider,
	ToolsStructure,
	ToolsSuggestions,
	ToolsThumbnails,
	UncropValues,
} from "../../Models/ToolsConfig";
import CameraMovementTab from "./CameraMovementTab";
import { SystemViewTypes } from "../../Models/SystemView";
import VideoReadyPopup from "./VideoReadyPopup";
import ObjectRemover from "./ObjectRemover";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ThumbnailSliderSwitch from "../UI/ThumbnailSliderSwitch";
import MarketingMessaging from "../Common/marketing-messaging";
import Scene, { ThumbnailData } from "../../sdk/resources/element";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import { CameraVideoActions } from "../../Pages/Tool/useImageEditor";
import CameraMovementModal from "./CameraMovementModal";
import clsx from "clsx";
import ErrorIcon from "@material-ui/icons/Error";
import CustomizedTooltip from "../UI/Tooltip";
import UserRemainingCreditsMessage from "../UserRemainingCreditsMessage";
import { SubscriptionCreditsTypes } from "../../Constants/SubscriptionConstants";
import { TextEditor } from "./TextEditor";
import SceneBackground from "../UI/SceneBackground";
import CropImage from "../CropImage";
import PresentersStyle from "../UI/PresentersStyle";
import ObjectsTab from "./ObjectsTab";
import Link from "@material-ui/core/Link";
import { IsIframe } from "../../Helpers/iframe";
import CampaignTab from "../UI/BrandTab/CreateCampaign/CampaignTab";
import ExpandBackground from "../UI/ExpandBackground";
import { useSegmentationCanvas } from "../../hooks/painter/useSegmentationCanvas";
import { usePaintCanvas } from "../../hooks/painter/usePaintCanvas";
import ImageStyle from "../UI/ImageStyle";
import SaveCropPopUp from "../SaveCropPopUp";

interface IProps {
	imageTransparencyPercentage: number;
	applySketchImageStyle: () => Promise<void>;
	imageElement?: HTMLImageElement;
	objectsPanopticImage: ImageData | undefined;
	objectsMasks: HTMLImageElement[];
	setObjectsTooltipPosition: (value: { x: number; y: number } | undefined) => void;
	setShowObjectsFollowUpTooltip: (value: boolean) => void;
	originalImageDimensions: { width: number; height: number };
	setReplaceBgThumbsLoading: (value: boolean) => void;
	setUncropThumbsLoading: (value: boolean) => void;
	presentersStyleLoading: boolean;
	naturalSize: { width: number; height: number };
	backgroundOracle: any;
	visualHash: string;
	faceDevInfo?: Scene[];
	isImageLoading: boolean;
	replaceBgThumbsLoading: boolean;
	uncropThumbsLoading: boolean;
	processing: boolean;
	replaceBgLoading: boolean;
	uncropLoading: boolean;
	backgroundString: ToolsBackGroundMode;
	onObjectHover: (id: string) => void;
	clearObjectHover: () => void;
	objectOnHover: string;
	toggleBackground: (fireRequest?: boolean) => void;
	toggleBackgroundBlur: (fireRequest?: boolean) => void;
	backgroundBlur: ToolsBackGroundBlurMode;
	disableRemoveBGSwitch: boolean;
	objectsList: any[];
	shouldResetCheckboxes: boolean;
	setShouldResetCheckboxes: Dispatch<SetStateAction<boolean>>;
	accessoriesToRemove: any[];
	disabledTabs: { [key: string]: boolean };
	pointOfInterests: any[];
	disabled: boolean;
	imageUrl: string;
	cropImageUrl: string;
	imageRef: RefObject<HTMLImageElement>;
	selectedPoint: SelectedPointInterface | null;
	selectedEthnicity: string;
	selectedEthnicityValue: number;
	removeObjectMutationLoading: boolean;
	onRemoveObject: (objectId: string) => Promise<boolean>;
	onShowObject: (objectId: string) => Promise<boolean>;
	onChangeEthnicity: (ethnicty: string, value: number) => void;
	personalizerValues: { [key: string]: number };
	onChangePersonalizer: (field: string, value: number, actionType?: ApiActions) => Promise<void> | void;
	onAddSemanticCombination: (thumbnail: ThumbnailData, original: boolean, controller: any) => Promise<string>;
	isUncropApplied: boolean;
	minZoom: number;
	maxZoom: number;
	toDisplayThumbnails: { url: string }[] | null;
	onThumbnailClick: (value: any) => void;
	resetUncrop: () => void;
	resetCrop: () => void;
	drawerAnchor: DrawerProps["anchor"];
	fullscreen?: boolean;
	selectedVideo: string | null | undefined;
	setSelectedVideo: React.Dispatch<React.SetStateAction<string | null | undefined>>;
	handleResetActions: (isFashionMenTabSelected: boolean) => void;
	configLoading?: boolean;
	createExtremeExpression: (emotion: string, value: number) => Promise<void> | undefined;
	createExtremeExpressionMutationLoading: boolean;
	createExtremeExpressionMutationError: ApolloError | undefined;
	selectedExpressionValue: number;
	selectedExpressionName: string;
	resetExtremeExpression: () => void;
	isLoadingExpression: boolean;
	children?: ReactElement;
	toolConfigurations: ToolsStructure[];
	currentSliders: ToolsSlider[];
	toolsConfigThumbnails: ToolsThumbnails[];
	toolsConfigSuggestions: ToolsSuggestions[];
	resetLastExtremeExpression: () => void;
	fetchCameraVideo: (actions: CameraVideoActions) => void;
	setIsImageMode: React.Dispatch<SetStateAction<boolean>>;
	setIsCameraMovementTabSelected: React.Dispatch<SetStateAction<boolean>>;
	setTextOnImage: React.Dispatch<SetStateAction<string>>;
	setIsTextEditorTabSelected: React.Dispatch<SetStateAction<boolean>>;
	setIsLogoTabSelected: React.Dispatch<SetStateAction<boolean>>;
	setIsObjectsTabSelected: React.Dispatch<SetStateAction<boolean>>;
	isObjectsTabSelected: boolean;
	objectsTabCanvasRef: any;
	resetManualBrushTab: boolean | null;
	setResetManualBrushTab: React.Dispatch<SetStateAction<boolean | null>>;
	setIsObjectsAutomaticTabSelected: React.Dispatch<SetStateAction<boolean>>;
	isObjectsManualBrushTabSelected: boolean;
	setIsObjectsManualBrushTabSelected: React.Dispatch<SetStateAction<boolean>>;
	brushConfig: any;
	setBrushConfig: any;
	isCameraMovementTabSelected: boolean;
	setIsFashionMenSelected: (isSelected: boolean, wasSelected: boolean) => void;
	setIsImageLoading: React.Dispatch<SetStateAction<boolean>>;
	openVideoReadyPopup: boolean;
	setOpenVideoReadyPopup: React.Dispatch<SetStateAction<boolean>>;
	setSelectedImageUrl: (imageUrl: string) => void;
	doDownloadVideo: (url?: string) => void;
	imageVH: string;
	openCameraMovementModal: boolean;
	setOpenCameraMovementModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleCameraModalContinue: () => void;
	resetCameraMotionVideo: () => void;
	cameraMotionVideo: string;
	preCameraMovementActionsConfig: { [s: string]: any } | null;
	setPreCameraMovementActionsConfig: React.Dispatch<React.SetStateAction<{ [s: string]: any } | null>>;
	cameraVideoStatus?: "Progress" | "Ready" | null;
	expressionsSliders?: any;
	setIsReplaceBackgroundTabSelected: (value: boolean) => void;
	isReplaceBackgroundTabSelected: boolean;
	onReplaceBgThumbnailClick: (thumbnailImage: ThumbnailImage) => void;
	onUncropThumbnailClick: (thumbnailImage: ThumbnailImage) => Promise<void>;
	onImageReplaceBackground: (
		fast: boolean,
		bg_prompt?: string,
		prefix?: string,
		num_results?: number,
		append?: boolean
	) => Promise<void>;
	onUncrop: (
		canvas_size: [number, number],
		original_image_size: [number, number],
		original_image_location: [number, number],
		num_results: number,
		append?: boolean,
		prompt?: string,
		expandImageTab?: boolean
	) => Promise<void>;
	originalImage: string;
	uncropValues: UncropValues | undefined;
	replaceBgText: { fast: boolean; text: string; force?: boolean; prefix?: string };
	uncropThumbnails: ThumbnailImage[];
	expandUncropThumbnails: ThumbnailImage[];
	replaceBgThumbnails: ThumbnailImage[];
	OnloadError: boolean;
	cropImageFunction: (w: number, h: number, isCustom: boolean) => void;
	setCropTabSeleced: (value: boolean) => void;
	cropTabSeleced: boolean;
	setCropName: (value: string) => void;
	activeCropPreset: string;
	setActiveCropPreset: (value: string) => void;
	activeCropSizebtn: number;
	setActiveCropSizeBtn: (value: number) => void;
	setDraftZoom: (value: number) => void;
	draftZoom: number;
	setCroppedAreaPixels: (value: any) => void;
	resetPointsOfInterests: () => void;
	setRotation: (value: number) => void;
	setCroppedAreaPercentage: (value: any) => void;
	setDraftCroppedAreaPercentage: (value: any) => void;
	setDraftCroppedAreaPixels: (value: any) => void;
	draftCroppedAreaPercentage: any;
	draftCroppedAreaPixels: any;
	draftRotation: number;
	previewImage: any;
	croppedArea: any;
	rotation: any;
	selectedTab: number;
	ethicalError: boolean;
	setEthicalError: (value: boolean) => void;
	setSelectedTab: (value: number) => void;
	croppedAreaPixels: any;
	croppedImage: any;
	draftCropName: string;
	setDraftCropName: (value: string) => void;
	setDraftCroppedArea: (value: any) => void;
	disableObjectsTab: boolean;
	createCroppedImage: (
		isPreview: boolean,
		passedCroppedAreaPixels?: any,
		passedRotation?: any,
		cropFlag?: any
	) => Promise<void>;
	onDoPresentersStyle: (bg_prompt?: string) => Promise<void>;
	onPresentersStyleThumbnailClick: (thumbnailImage: ThumbnailImage) => Promise<void>;
	presentersStyleThumbnails: ThumbnailImage[];
	presentersStyleText: { text: string; force?: boolean };
	setGeneralLoading: (loading: boolean) => void;
	expandBackgroundTabSelected: boolean;
	setExpandBackgroundTabSelected: (value: boolean) => void;
	setCropImageUrl: (value: any) => void;
	onRemoveObjectByCoordinates: (positivePoint: any, imageSize: any) => Promise<boolean>;
	onRestoreAllObjects: any;
	lockMasks: boolean;
	setLockMasks: (value: boolean) => void;
	mainObjectData: any;
	setMainObjectData: (value: any) => void;
	showCropSavePopUp: boolean;
	setShowCropSavePopUp: (value: boolean) => void;
	setSelectedPoint: (x: any) => void;
	setShowCropSaveFirstTime: (value: boolean) => void;
}

interface IMapper {
	[key: string]: React.PropsWithChildren<React.ReactNode>;
}

export interface IObject {
	name: string;
	rect: {
		h: number;
		w: number;
		x: number;
		y: number;
	};
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		editStyleModalBody: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: "500px",
			height: "700px",
			position: "absolute",
			padding: theme.spacing(4),
			backgroundColor: "white",
		},
		styleModalHeader: {
			fontSize: 24,
			fontWeight: "bold",
		},
		automaticTitle: {
			color: "#1A0638",
			fontSize: 14,
			marginBottom: 24,
			fontWeight: 600,
		},
		labelAndIconContainer: {
			display: "flex",
			marginBottom: 20,
		},
		styleModalSubHeader: {
			fontSize: 14,
			paddingTop: 30,
			fontWeight: "bold",
			color: "#D3D4D8",
		},
		styleModalBodyText: {
			fontSize: 14,
		},
		styleModalBodyLink: {
			fontSize: 14,
			color: "#D80067",
		},
		styleModalFooterLink: {
			fontSize: 14,
			color: "black",
			cursor: "pointer",
		},
		scaleContainer: {
			position: "absolute",
			bottom: 23,
			zIndex: 10,
			left: -9,
		},
		scaleThisFeature: {
			fontSize: 14,
			fontWeight: 400,
			color: "#000000",
			lineHeight: "17px",
		},
		styleModalApplyLink: {
			fontSize: 14,
			color: "white",
			margin: "auto",
			cursor: "pointer",
		},
		selectedCropTab: {
			backgroundColor: "rgba(83, 0, 201, 0.05);",
			boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02)",
			border: `2px solid ${theme.palette.primary.dark} !important`,
		},
		cropContainer: {
			border: "2px solid rgba(179, 179, 185, 1)",
			width: 202,
			height: 34,
			justifyContent: "center",
			alignItems: "center",
			display: "flex",
			borderRadius: 8,
			fontSize: 16,
			fontWeight: 600,
			letterSpacing: "0.02em",
			cursor: "pointer",
			"&:hover": {
				background: "#E7E7E7",
			},
			"&:focus": {
				background: "rgba(83, 0, 201, 0.05);",
			},
			// "&:focus-visible": {
			// 	background: "#37057D",
			// 	color: "#FFFFFF",
			// },
			"&:disabled": {
				color: theme.palette.primary.dark,
				opacity: 0.25,
			},
		},
		styleModalApplyContainer: {
			width: 117,
			height: 30,
			backgroundColor: "#D80067",
			borderRadius: 20,
			textAlign: "center",
			marginLeft: 18,
			display: "flex",
			alignItems: "center",
		},
		styleModalControl: {
			paddingTop: 20,
		},
		styleModalFooter: {
			paddingTop: 40,
		},
		titleContainer: {
			display: "flex",
		},
		tabsContainer: {
			background: "transparent",
		},
		generalContainer: {
			backgroundColor: theme.palette.common.white,
			marginLeft: 20,
			border: "1px solid #E7E7E7",
			borderRadius: 8,
			boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08)",
			width: 446,
			marginBottom: 40,
		},
		tabsScroller: {
			overflowX: "visible",
			paddingInline: 8,
		},
		flexContainer: {
			justifyContent: "center",
		},
		subTabContainer: {
			paddingLeft: 23,
			fontSize: 12,
		},
		editLink: {
			color: "#D80067",
			fontSize: 12,
			cursor: "pointer",
		},
		editLinkContainer: {
			paddingLeft: 23,
		},
		tabRoot: {},
		cropTabContainer: {
			width: "unset !important",
			marginRight: "0px !important",
		},
		tabContainer: {
			color: "black",
			overflow: "visible",
			alignSelf: "start",
			fontSize: 18,
			fontWeight: theme.typography.fontWeightBold,
			// width: "100%",
			minWidth: "100px",
			textTransform: "capitalize",
			padding: "20px 0px 0px 0px",
			marginRight: 6,
			marginBottom: -4,
			opacity: 1,
			textAlign: "left",
			"& .MuiTab-wrapper": {
				// alignItems: "flex-start",
			},
		},
		disableTab: {
			opacity: 0.3,
			pointerEvents: "none",
		},
		disableTabLabel: {
			cursor: "default",

			"& p": {
				opacity: 0.3,
			},
		},
		disabled: {
			// opacity: 0.4,
			opacity: "1 !important",
			color: "rgba(164, 164, 164, 0.4)",
		},
		selecteTab: {
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.primary.dark,
		},
		hidden: {
			opacity: 0,
		},
		sideRoot: {
			// marginTop: theme.spacing(1),
			// padding: theme.spacing(1),
			display: "flex",
			height: "100%",
			marginTop: 20,
		},
		sideContent: {
			height: "100%",
			display: "flex",
			// flexDirection: "column",
			// borderRadius: theme.shape.borderRadius,
		},
		title: {
			fontSize: 14,
			color: theme.palette.textColor.light,
			fontWeight: theme.typography.fontWeightMedium,
		},
		link: {
			fontSize: theme.typography.pxToRem(16),
			fontWeight: theme.typography.fontWeightMedium,
			cursor: "pointer",
			textDecoration: "underline",

			"&:hover": {
				fontWeight: theme.typography.fontWeightBold,
			},
		},
	};
});

const TabDisabledTip = () => {
	return (
		<CustomizedTooltip
			title={
				<>
					<b className="title">Selection</b>
					<div>
						The system only enables selection of objects that can be customized at production-level quality
					</div>
				</>
			}
			placement="right"
			multiLine={true}
		>
			<ErrorIcon
				style={{
					fontSize: 16,
					marginTop: -10,
					// position: "absolute",
					// top: 7,
					// right: 0,
					color: "#D80067",
				}}
			/>
		</CustomizedTooltip>
	);
};

function TabPanel(props: any) {
	const { children, hidden, style } = props;

	return hidden ? null : (
		<Typography role="tabpanel" component={"div"} hidden={hidden} style={{ ...style }}>
			<Box>{children}</Box>
		</Typography>
	);
}

export const ToolsActionContext = React.createContext({
	cachedThumbnails: [],
	setCachedThumbnails: (thumbnailsType: string, thumbnails: any[]) => {},
});

const ToolsAction: React.FC<IProps> = ({
	setDraftCroppedAreaPercentage,
	setDraftCroppedAreaPixels,
	imageTransparencyPercentage,
	applySketchImageStyle,
	setObjectsTooltipPosition,
	setShowObjectsFollowUpTooltip,
	fullscreen,
	setDraftZoom,
	naturalSize,
	draftZoom,
	originalImageDimensions,
	setReplaceBgThumbsLoading,
	setUncropThumbsLoading,
	presentersStyleLoading,
	backgroundOracle,
	replaceBgLoading,
	uncropLoading,
	isUncropApplied,
	minZoom,
	maxZoom,
	setIsReplaceBackgroundTabSelected,
	isReplaceBackgroundTabSelected,
	onReplaceBgThumbnailClick,
	onUncropThumbnailClick,
	onImageReplaceBackground,
	onUncrop,
	onObjectHover,
	clearObjectHover,
	objectOnHover,
	originalImage,
	visualHash,
	faceDevInfo,
	setTextOnImage,
	createCroppedImage,
	onDoPresentersStyle,
	onPresentersStyleThumbnailClick,
	presentersStyleThumbnails,
	presentersStyleText,
	replaceBgThumbsLoading,
	uncropThumbsLoading,
	accessoriesToRemove,
	setShouldResetCheckboxes,
	shouldResetCheckboxes,
	backgroundString,
	toggleBackground,
	toggleBackgroundBlur,
	backgroundBlur,
	disableRemoveBGSwitch,
	objectsList,
	disabled,
	imageUrl,
	setShowCropSaveFirstTime,
	cropImageUrl,
	imageRef,
	selectedEthnicity,
	selectedEthnicityValue,
	removeObjectMutationLoading,
	personalizerValues,
	drawerAnchor,
	activeCropPreset,
	setActiveCropPreset,
	activeCropSizebtn,
	setActiveCropSizeBtn,
	selectedPoint,
	onRemoveObject,
	onShowObject,
	onChangeEthnicity,
	onThumbnailClick,
	onAddSemanticCombination,
	onChangePersonalizer,
	configLoading,
	children,
	createExtremeExpression,
	pointOfInterests,
	isLoadingExpression,
	toolConfigurations,
	currentSliders,
	toolsConfigThumbnails,
	toolsConfigSuggestions,
	fetchCameraVideo,
	setIsCameraMovementTabSelected,
	setIsTextEditorTabSelected,
	setIsLogoTabSelected,
	setIsObjectsTabSelected,
	isObjectsTabSelected,
	isObjectsManualBrushTabSelected,
	resetManualBrushTab,
	setResetManualBrushTab,
	setIsObjectsAutomaticTabSelected,
	setIsObjectsManualBrushTabSelected,
	brushConfig,
	setBrushConfig,
	objectsTabCanvasRef,
	isCameraMovementTabSelected,
	setIsFashionMenSelected,
	isImageLoading,
	setIsImageLoading,
	openVideoReadyPopup,
	setOpenVideoReadyPopup,
	processing,
	setSelectedImageUrl,
	doDownloadVideo,
	imageVH,
	openCameraMovementModal,
	setOpenCameraMovementModal,
	handleCameraModalContinue,
	resetCameraMotionVideo,
	cameraMotionVideo,
	preCameraMovementActionsConfig,
	setPreCameraMovementActionsConfig,
	cameraVideoStatus,
	disabledTabs,
	expressionsSliders,
	replaceBgText,
	uncropValues,
	replaceBgThumbnails,
	uncropThumbnails,
	expandUncropThumbnails,
	OnloadError,
	cropImageFunction,
	setCropTabSeleced,
	cropTabSeleced,
	setCropName,
	setCroppedAreaPixels,
	setCroppedAreaPercentage,
	setRotation,
	resetPointsOfInterests,
	draftCroppedAreaPercentage,
	draftCroppedAreaPixels,
	draftRotation,
	selectedTab,
	ethicalError,
	setEthicalError,
	setSelectedTab,
	croppedAreaPixels,
	croppedArea,
	rotation,
	resetUncrop,
	resetCrop,
	croppedImage,
	draftCropName,
	setDraftCropName,
	setDraftCroppedArea,
	disableObjectsTab,
	setGeneralLoading,
	expandBackgroundTabSelected,
	setExpandBackgroundTabSelected,
	setCropImageUrl,
	objectsPanopticImage,
	imageElement,
	objectsMasks,
	onRemoveObjectByCoordinates,
	onRestoreAllObjects,
	lockMasks,
	setLockMasks,
	mainObjectData,
	setMainObjectData,
	showCropSavePopUp,
	setShowCropSavePopUp,
	setSelectedPoint,
}) => {
	const context = useContext(Context);
	const { isIframe } = IsIframe();

	const [cachedThumbnails, setCachedThumbnails] = React.useState<any>({});
	const [newTabNumber, setNewTabNumber] = React.useState<number | undefined>(undefined);

	const [openEditStyleModal, setOpenEditStyleModal] = React.useState<boolean>(false);
	const classes = useStyles();
	const { t } = useTranslation();
	const [tabHoverd, setTabHoverd] = useState<number>();
	// const objectsToRemoveRef = React.useRef([])
	const theme = useTheme();
	const onStyleChange = (style: ToolsViewerMode) => {
		context.user?.updateThumbnailsSettings(style);
	};

	useEffect(() => {
		const canClearTheCachedThumbs = selectedPoint?.objectType === BriaObjectType.human;

		if (!canClearTheCachedThumbs) return;
		setCachedThumbnails({});
	}, [visualHash]);
	const selectedTabDefault = 0;
	const scrollContainer = document.getElementById("containerDiv");
	const API_DOCS_MAPPER: any = {
		Background: "https://bria-ai-api-docs.redoc.ly/#tag/backgroundinfo",
		Expressions: "https://bria-ai-api-docs.redoc.ly/#tag/personinfo",
		Appearance: "https://bria-ai-api-docs.redoc.ly/#tag/personinfo",
		Models: "https://bria-ai-api-docs.redoc.ly/#tag/personinfo",
		Style: "https://bria-ai-api-docs.redoc.ly/#tag/recast_model",
		Automatic: "https://bria-ai-api-docs.redoc.ly/#tag/objectsinfo",
		"Social Media": undefined,
		"Media Sizes": undefined,
		Advertisements: undefined,
		Custom: undefined,
		Mood: undefined,
		"Fine-tuning": undefined,
		main: "https://bria-ai-api-docs.redoc.ly",
	};
	// const [editorState, setEditorState] = useState(() =>
	// 	EditorState.createEmpty()
	// );

	const [imageContainerDimensions, setImageContainerDimensions] =
		useState<{
			width: number;
			height: number;
		}>();

	useEffect(() => {
		if (imageRef.current && imageRef.current.clientWidth > 0 && imageRef.current.clientHeight > 0) {
			setImageContainerDimensions({
				width: imageRef.current.clientWidth,
				height: imageRef.current.clientHeight,
			});
		}
	}, [imageRef.current, imageRef.current?.clientWidth, imageRef.current?.clientHeight]);

	function usePrevious(value: any) {
		const ref = React.useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const updateCachedThumbnails = (key: string, newThumbs: any[] = []) => {
		setCachedThumbnails({
			...cachedThumbnails,
			[key]: newThumbs,
		});
	};
	const ToolsActionContextValue: any = {
		cachedThumbnails,
		setCachedThumbnails: updateCachedThumbnails,
	};

	const suggestionThumbnails = useMemo(() => {
		return toolsConfigSuggestions.map((el, index) => {
			return {
				key: `suggestions-${index + 1}`,
				title: `Option ${index + 1}`,
				level1_id: "personalizer",
				level2_id: "suggestions",
				sliders: el.thumbnails,
				validation: {},
			};
		});
	}, [toolsConfigSuggestions]);

	const diversityThubmbnails = useMemo(() => {
		return toolsConfigThumbnails.filter((el) => el.level2_id === ToolConfigTabsTypes.Diversity);
	}, [toolsConfigThumbnails]);

	const diversitySliders = useMemo(() => {
		return currentSliders.filter((el) => el.level2Id === ToolConfigTabsTypes.Diversity);
	}, [currentSliders]);
	const [isEngineOverloaded, setIsEngineOverloaded] = useState<boolean>(false);
	const verticalThumbConfig = [130, 200, 3, 3];
	const horizontalThumbConfig = [150, 130, 2, 3];
	const [thumbnailConfig, setThumbnailsConfig] = useState<number[]>(
		originalImageDimensions.height > originalImageDimensions.width ? verticalThumbConfig : horizontalThumbConfig
	);

	const applyCrop = async () => {
		setCropName(draftCropName);
		setDraftCroppedArea(croppedArea);
		setCroppedAreaPixels(draftCroppedAreaPixels);
		setCroppedAreaPercentage(draftCroppedAreaPercentage);
		setRotation(draftRotation);
		setDraftZoom(draftZoom);
		resetPointsOfInterests();
		setShowCropSaveFirstTime(true);
		await createCroppedImage(false, draftCroppedAreaPixels, draftRotation, true);
	};
	const callUncropApi = async (num_results: number, append?: boolean, prompt?: string) => {
		setUncropThumbsLoading(!!!append);
		try {
			const ratio = Math.min(
				croppedArea.width / croppedAreaPixels.width,
				croppedArea.height / croppedAreaPixels.height
			);
			await onUncrop(
				[croppedArea.width, croppedArea.height],
				[Math.round(naturalSize.width * ratio), Math.round(naturalSize.height * ratio)],
				[Math.round(croppedAreaPixels.x * ratio * -1), Math.round(croppedAreaPixels.y * ratio * -1)],
				num_results,
				append,
				prompt,
				ExpandImageIndex !== undefined && ExpandImageIndex === selectedTab + 1
			);
		} catch (err: any) {
			if (!append && err.response && err.response.status === 512) {
				setIsEngineOverloaded(true);
			}
		}
	};
	const getCropImageComponent = (platformArray: number) => {
		return (
			<CropImage
				imageTransparencyPercentage={imageTransparencyPercentage}
				naturalSize={naturalSize}
				setCropZoom={setDraftZoom}
				thumbnailConfig={thumbnailConfig}
				setIsEngineOverloaded={setIsEngineOverloaded}
				isEngineOverloaded={isEngineOverloaded}
				callUncropApi={callUncropApi}
				applyCrop={applyCrop}
				resetCrop={resetCrop}
				setGeneralLoading={setGeneralLoading}
				createCroppedImage={createCroppedImage}
				isUncropApplied={isUncropApplied}
				minZoom={minZoom}
				maxZoom={maxZoom}
				setDraftCroppedArea={setDraftCroppedArea}
				draftCropName={draftCropName}
				setDraftCropName={setDraftCropName}
				croppedImage={croppedImage}
				croppedArea={croppedArea}
				originalImageDimensions={originalImageDimensions}
				draftCroppedAreaPercentage={draftCroppedAreaPercentage}
				draftCroppedAreaPixels={draftCroppedAreaPixels}
				draftRotation={draftRotation}
				imageVH={imageVH}
				resetPointsOfInterests={resetPointsOfInterests}
				setCroppedAreaPixels={setCroppedAreaPixels}
				setCroppedAreaPercentage={setCroppedAreaPercentage}
				setRotation={setRotation}
				resetUncrop={resetUncrop}
				croppedAreaPixels={croppedAreaPixels}
				cropImageFunction={cropImageFunction}
				imageRef={imageRef}
				setCropTabSeleced={setCropTabSeleced}
				cropZoom={draftZoom}
				setCropName={setCropName}
				platformArray={platformArray}
				activeCropPreset={activeCropPreset}
				setActiveCropPreset={setActiveCropPreset}
				activeCropSizebtn={activeCropSizebtn}
				setActiveCropSizeBtn={setActiveCropSizeBtn}
				uncropThumbsLoading={uncropThumbsLoading}
				setUncropThumbsLoading={setUncropThumbsLoading}
				processing={processing}
				uncropLoading={uncropLoading}
				selectedPoint={selectedPoint}
				visual_hash={visualHash}
				onUncropThumbnailClick={onUncropThumbnailClick}
				uncropValues={uncropValues}
				onUncrop={onUncrop}
				uncropThumbnails={uncropThumbnails}
				originalImage={originalImage}
				OnloadError={OnloadError}
			/>
		);
	};

	// responsible of thumbnails and sliders
	const ToolsActionMapper: IMapper = {
		[ToolConfigTabsTypes.Diversity]: (
			<Box height="100%">
				<Localizer
					selectedPoint={selectedPoint}
					visualHash={visualHash}
					oracle={selectedPoint?.oracle}
					disabled={disabled}
					ethnicityValue={selectedEthnicityValue}
					selectedEthnicity={selectedEthnicity}
					onChangeEthnicity={(ethnicity, value) => {
						onChangeEthnicity(ethnicity, value);
					}}
					onAddSemanticCombination={async (ethnicity, ethnicityValue, controller) => {
						return await onAddSemanticCombination(
							{
								ethnicity: ethnicity,
								ethnicityValue: ethnicityValue,
								semantics: [],
								thumbnailIndex: 0,
							},
							false,
							controller
						);
					}}
					drawerAnchor={drawerAnchor}
					thumbnails={diversityThubmbnails}
					sliders={diversitySliders}
				/>
			</Box>
		),
		[ToolConfigTabsTypes.Suggestions]: (
			<Personalizer
				selectedPoint={selectedPoint}
				visualHash={visualHash}
				oracle={selectedPoint?.oracle}
				key="suggestion"
				feature="suggestion"
				personalizerKey="suggestion"
				personalizerValues={personalizerValues}
				disabled={disabled}
				isSuggestion={true}
				currentStyle={context.preferences?.style}
				user={context.user}
				systemView={context.systemView}
				onChangePersonalizer={(sliders) => {
					const semanticsList = sliders.filter((el) => {
						const slider: ToolsSlider = el as ToolsSlider;
						return slider.level1Id === "personalizer";
					});
					const mutation: any = {
						semanticsList,
					};
					const ethinicty = sliders.filter((el) => {
						const slider: ToolsSlider = el as ToolsSlider;
						return slider.level1Id === "localizer";
					});
					if (ethinicty.length === 1) {
						mutation["ethnicity"] = ethinicty[0].id;
						mutation["ethnicityValue"] = ethinicty[0].value;
					}
					onThumbnailClick(mutation);
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					if (semantics.length > 0) {
						const semanticsList = semantics.filter((el) => {
							const slider: ToolsSlider = el as ToolsSlider;
							return slider.level1Id === ToolConfigTabsTypes.Personalizer;
						});
						const ethinicty = semantics.filter((el) => {
							const slider: ToolsSlider = el as ToolsSlider;
							return slider.level1Id === ToolConfigTabsTypes.Localizer;
						});
						return await onAddSemanticCombination(
							{
								ethnicity: ethinicty[0]?.name ?? "original",
								ethnicityValue: +ethinicty[0]?.value ?? 0,
								semantics: semanticsList,
								thumbnailIndex: 0,
							},
							true,
							controller
						);
					}
					return "";
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={suggestionThumbnails}
				sliders={null}
				objectType={selectedPoint?.objectType}
				isLoadingExpression={false}
				thumbnailsCount={suggestionThumbnails.length}
			/>
		),
		[ToolConfigTabsTypes.Style]: (
			<PresentersStyle
				presentersStyleText={presentersStyleText}
				presentersStyleLoading={presentersStyleLoading}
				processing={processing}
				selectedPoint={selectedPoint}
				visual_hash={visualHash}
				imageRef={imageRef}
				presentersStyleThumbnails={presentersStyleThumbnails}
				onPresentersStyleThumbnailClick={onPresentersStyleThumbnailClick}
				onDoPresentersStyle={onDoPresentersStyle}
				originalImage={originalImage}
				OnloadError={OnloadError}
			/>
		),
		[ToolConfigTabsTypes.ImageStyle]: <ImageStyle applySketchImageStyle={applySketchImageStyle} />,
		[ToolConfigTabsTypes.createCampaign]: <CampaignTab {...{ productImage: originalImage, visualHash }} />,
		[ToolConfigTabsTypes.background]: (
			<SceneBackground
				replaceBgThumbsLoading={replaceBgThumbsLoading}
				originalImageDimensions={originalImageDimensions}
				setReplaceBgThumbsLoading={setReplaceBgThumbsLoading}
				processing={processing}
				backgroundOracle={backgroundOracle}
				replaceBgLoading={replaceBgLoading}
				backgroundString={backgroundString}
				toggleBackground={toggleBackground}
				disabled={disableRemoveBGSwitch}
				selectedPoint={selectedPoint}
				visual_hash={visualHash}
				imageRef={imageRef}
				onReplaceBgThumbnailClick={onReplaceBgThumbnailClick}
				replaceBgText={replaceBgText}
				onImageReplaceBackground={onImageReplaceBackground}
				replaceBgThumbnails={replaceBgThumbnails}
				originalImage={originalImage}
				toggleBackgroundBlur={toggleBackgroundBlur}
				backgroundBlur={backgroundBlur}
				OnloadError={OnloadError}
				ethicalError={ethicalError}
				setEthicalError={setEthicalError}
			></SceneBackground>
		),
		[ToolConfigTabsTypes.ExpandBackground]: (
			<ExpandBackground
				setDraftCroppedAreaPercentage={setDraftCroppedAreaPercentage}
				setDraftCroppedAreaPixels={setDraftCroppedAreaPixels}
				imageTransparencyPercentage={imageTransparencyPercentage}
				naturalSize={naturalSize}
				setCropImageUrl={setCropImageUrl}
				cropImageUrl={cropImageUrl}
				croppedArea={croppedArea}
				draftCroppedAreaPixels={draftCroppedAreaPixels}
				draftCroppedAreaPercentage={draftCroppedAreaPercentage}
				setCropName={setCropName}
				setRotation={setRotation}
				draftZoom={draftZoom}
				setDraftCroppedArea={setDraftCroppedArea}
				draftCropName={draftCropName}
				setDraftCropName={setDraftCropName}
				resetPointsOfInterests={resetPointsOfInterests}
				setCroppedAreaPixels={setCroppedAreaPixels}
				setCroppedAreaPercentage={setCroppedAreaPercentage}
				activeCropPreset={activeCropPreset}
				setActiveCropSizeBtn={setActiveCropSizeBtn}
				activeCropSizebtn={activeCropSizebtn}
				onUncropThumbnailClick={onUncropThumbnailClick}
				resetUncrop={resetUncrop}
				croppedImage={croppedImage}
				resetCrop={resetCrop}
				cropImageFunction={cropImageFunction}
				setCropTabSeleced={setCropTabSeleced}
				cropZoom={draftZoom}
				setIsEngineOverloaded={setIsEngineOverloaded}
				setCropZoom={setDraftZoom}
				setActiveCropPreset={setActiveCropPreset}
				setUncropThumbsLoading={setUncropThumbsLoading}
				uncropThumbsLoading={uncropThumbsLoading}
				callUncropApi={callUncropApi}
				applyCrop={applyCrop}
				thumbnailConfig={thumbnailConfig}
				uncropThumbnails={expandUncropThumbnails}
				isUncropApplied={isUncropApplied}
				originalImageDimensions={originalImageDimensions}
				visual_hash={visualHash}
			/>
		),

		[ToolConfigTabsTypes.MediaSizesCrop]: getCropImageComponent(0),
		[ToolConfigTabsTypes.CustomCrop]: getCropImageComponent(1),
		[ToolConfigTabsTypes.Accessories]: (
			<Box display="flex" flexDirection="column" height="100%" width="100%" overflow="auto">
				{context.user?.isViewer() ||
				(context.user?.isAdmin() && context.systemView === SystemViewTypes.Viewer) ? (
					<div></div>
				) : (
					accessoriesToRemove.map((acessory: Scene) => {
						return (
							<ObjectRemover
								loading={removeObjectMutationLoading}
								onObjectHover={onObjectHover}
								clearObjectHover={clearObjectHover}
								objectOnHover={objectOnHover}
								setShouldResetCheckboxes={setShouldResetCheckboxes}
								shouldResetCheckboxes={shouldResetCheckboxes}
								label={acessory.type ?? ""}
								objectId={acessory.id}
								onRemoveObject={async (objectId: string, checked: boolean) => {
									if (checked) {
										return await onShowObject(objectId);
									} else {
										return await onRemoveObject(objectId);
									}
								}}
							/>
						);
					})
				)}
			</Box>
		),
		[ToolConfigTabsTypes.AutoObjIdentification]: (
			<Box display="flex" flexDirection="column" height="100%" width="100%" overflow="auto">
				{imageUrl && imageContainerDimensions && (
					<ObjectsTab
						setMainObjectData={setMainObjectData}
						mainObjectData={mainObjectData}
						lockMasks={lockMasks}
						setLockMasks={setLockMasks}
						setObjectsTooltipPosition={setObjectsTooltipPosition}
						setShowObjectsFollowUpTooltip={setShowObjectsFollowUpTooltip}
						onRemoveObjectByCoordinates={onRemoveObjectByCoordinates}
						canvasHook={useSegmentationCanvas}
						imageElement={imageElement}
						objectsPanopticImage={objectsPanopticImage}
						objectsMasks={objectsMasks}
						fullscreen={fullscreen}
						isImageLoading={isImageLoading}
						setIsImageLoading={setIsImageLoading}
						parentDimenstions={imageContainerDimensions}
						selectedTab="automatic"
						pointOfInterests={pointOfInterests}
						disableObjectsTab={disableObjectsTab}
						visualHash={visualHash}
						brushConfig={brushConfig}
						setBrushConfig={setBrushConfig}
						resetManualBrushTab={resetManualBrushTab}
						setResetManualBrushTab={setResetManualBrushTab}
						setIsObjectsManualBrushTabSelected={setIsObjectsManualBrushTabSelected}
						setIsObjectsAutomaticTabSelected={setIsObjectsAutomaticTabSelected}
						objectsTabCanvasRef={objectsTabCanvasRef}
						imageUrl={cropImageUrl}
						setSelectedImageUrl={setSelectedImageUrl}
						objectsList={objectsList}
						loading={removeObjectMutationLoading}
						onObjectHover={onObjectHover}
						clearObjectHover={clearObjectHover}
						objectOnHover={objectOnHover}
						setShouldResetCheckboxes={setShouldResetCheckboxes}
						shouldResetCheckboxes={shouldResetCheckboxes}
						onRemoveObject={onRemoveObject}
						onShowObject={onShowObject}
						onRestoreAllObjects={onRestoreAllObjects}
					/>
				)}
			</Box>
		),
		[ToolConfigTabsTypes.ManualObjBrush]: (
			<Box display="flex" flexDirection="column" height="100%" width="100%" overflow="auto">
				{imageUrl && imageContainerDimensions && (
					<ObjectsTab
						setMainObjectData={setMainObjectData}
						mainObjectData={mainObjectData}
						lockMasks={lockMasks}
						setLockMasks={setLockMasks}
						setObjectsTooltipPosition={setObjectsTooltipPosition}
						setShowObjectsFollowUpTooltip={setShowObjectsFollowUpTooltip}
						onRemoveObjectByCoordinates={onRemoveObjectByCoordinates}
						canvasHook={usePaintCanvas}
						imageElement={imageElement}
						objectsPanopticImage={objectsPanopticImage}
						objectsMasks={objectsMasks}
						fullscreen={fullscreen}
						isImageLoading={isImageLoading}
						setIsImageLoading={setIsImageLoading}
						parentDimenstions={imageContainerDimensions}
						selectedTab="manual-brush"
						pointOfInterests={pointOfInterests}
						disableObjectsTab={disableObjectsTab}
						visualHash={visualHash}
						brushConfig={brushConfig}
						setBrushConfig={setBrushConfig}
						resetManualBrushTab={resetManualBrushTab}
						setResetManualBrushTab={setResetManualBrushTab}
						setIsObjectsManualBrushTabSelected={setIsObjectsManualBrushTabSelected}
						setIsObjectsAutomaticTabSelected={setIsObjectsAutomaticTabSelected}
						objectsTabCanvasRef={objectsTabCanvasRef}
						imageUrl={cropImageUrl}
						setSelectedImageUrl={setSelectedImageUrl}
						objectsList={objectsList}
						loading={removeObjectMutationLoading}
						onObjectHover={onObjectHover}
						clearObjectHover={clearObjectHover}
						objectOnHover={objectOnHover}
						setShouldResetCheckboxes={setShouldResetCheckboxes}
						shouldResetCheckboxes={shouldResetCheckboxes}
						onRemoveObject={onRemoveObject}
						onShowObject={onShowObject}
						onRestoreAllObjects={onRestoreAllObjects}
					/>
				)}
			</Box>
		),
		[ToolConfigTabsTypes.Expression]: (
			<Personalizer
				selectedPoint={selectedPoint}
				visualHash={visualHash}
				key={ToolConfigTabsTypes.Expression}
				feature={ToolConfigTabsTypes.Expression}
				personalizerKey={ToolConfigTabsTypes.Expression}
				personalizerValues={personalizerValues}
				disabled={disabled}
				oracle={selectedPoint?.oracle}
				currentStyle={context.preferences?.style}
				user={context.user}
				systemView={context.systemView}
				onChangePersonalizer={(sliders) => {
					if (sliders.length === 1 && sliders[0].level1Id === ToolConfigTabsTypes.Personalizer)
						onChangePersonalizer(sliders[0].id, sliders[0].value);
					else if (sliders.length === 1 && sliders[0].level1Id === ToolConfigTabsTypes.Emotions) {
						createExtremeExpression(sliders[0].id, sliders[0].value);
					}
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					return await onAddSemanticCombination(
						{
							ethnicity: selectedEthnicity,
							ethnicityValue: selectedEthnicityValue,
							semantics: semantics,
							thumbnailIndex: 0,
						},
						false,
						controller
					);
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={toolsConfigThumbnails.filter((el) => el.level2_id === ToolConfigTabsTypes.Expression)}
				sliders={expressionsSliders}
				isLoadingExpression={isLoadingExpression}
				objectType={selectedPoint?.objectType}
				thumbnailsCount={
					toolsConfigThumbnails.filter((el) => el.level2_id === ToolConfigTabsTypes.Expression).length
				}
			/>
		),
		[ToolConfigTabsTypes.CameraMovement]: (
			<CameraMovementTab
				disabled={disabled}
				fetchCameraVideo={fetchCameraVideo}
				imageUrl={imageUrl}
				imageRef={imageRef}
				preCameraMovementActionsConfig={preCameraMovementActionsConfig}
				setPreCameraMovementActionsConfig={setPreCameraMovementActionsConfig}
				cameraVideoStatus={cameraVideoStatus}
			/>
		),
		[ToolConfigTabsTypes.FashionMen]:
			context.user?.role === ROLES.ADMIN && context.systemView === SystemViewTypes.Testing ? (
				<Personalizer
					selectedPoint={selectedPoint}
					oracle={selectedPoint?.oracle}
					visualHash={visualHash}
					key="fashion"
					feature="fashion"
					personalizerKey="fashion"
					personalizerValues={personalizerValues}
					disabled={disabled}
					isSuggestion={false}
					currentStyle={context.preferences?.style}
					user={context.user}
					systemView={context.systemView}
					onChangePersonalizer={(sliders) => {
						if (sliders.length === 1)
							onChangePersonalizer(sliders[0].id, sliders[0].value, ApiActions.FASHION_MEN);
					}}
					onAddSemanticCombination={async (semantics, controller) => {
						return "";
					}}
					drawerAnchor={drawerAnchor}
					sliders={currentSliders.filter((el) => el.level2Id === "fashion_men")}
					objectType={BriaObjectType.human}
					isLoadingExpression={false}
				/>
			) : (
				<MarketingMessaging personalizerKey={ToolConfigTabsTypes.FashionMen} />
			),
		text_editor: (
			<TextEditor
				setTextOnImage={setTextOnImage}
				setSelectedImageUrl={setSelectedImageUrl}
				setIsImageLoading={setIsImageLoading}
			/>
		),
	};

	// For Appearance
	const personalizerMapper = (key: string): ReactElement => {
		const thumbs = toolsConfigThumbnails.filter((el) => el.level2_id === key);
		return (
			<Personalizer
				selectedPoint={selectedPoint}
				visualHash={visualHash}
				faceDevInfo={faceDevInfo}
				key={key}
				feature={key}
				personalizerKey={key}
				oracle={selectedPoint?.oracle}
				personalizerValues={personalizerValues}
				disabled={disabled}
				currentStyle={context.preferences?.style}
				user={context.user}
				systemView={context.systemView}
				onChangePersonalizer={(sliders, key, apiAction) => {
					if (sliders.length === 1) onChangePersonalizer(sliders[0].id, sliders[0].value, apiAction);
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					return await onAddSemanticCombination(
						{
							ethnicity: selectedEthnicity,
							ethnicityValue: selectedEthnicityValue,
							semantics: semantics,
							thumbnailIndex: 0,
						},
						false,
						controller
					);
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={thumbs}
				sliders={currentSliders.filter((el) => el.level2Id === key)}
				objectType={selectedPoint?.objectType}
				isLoadingExpression={false}
				thumbnailsCount={thumbs.length}
			/>
		);
	};

	const panels = toolConfigurations.map((el, index) => {
		return (
			<TabPanel
				key={`tab-panel-${el.level2Id}`}
				value={selectedTab}
				index={index}
				hidden={selectedTab !== index}
				top={40}
				style={
					el.level2Id === ToolConfigTabsTypes.Expression || el.level2Id === ToolConfigTabsTypes.Appearance
						? {
								height: "calc(100% - 10px)",
								// overflowY: "auto",
								// marginTop: 16,
						  }
						: {}
				}
			>
				<Box
					style={{
						marginTop: cropTabSeleced || isObjectsTabSelected ? 0 : 30,
					}}
				>
					{(el.level2Id === ToolConfigTabsTypes.Appearance ||
						el.level2Id === ToolConfigTabsTypes.Diversity) && (
						<Grid item>
							<ThumbnailSliderSwitch
								defaultStyle={
									el.level2Id === ToolConfigTabsTypes.Appearance
										? ToolsViewerMode.sliders
										: ToolsViewerMode.thumbnails
									// context.preferences?.style ?? ToolsViewerMode.thumbnails
								}
								onStyleChange={onStyleChange}
								disabled={false}
							/>
						</Grid>
					)}
					{!ToolsActionMapper[el.level2Id] ? personalizerMapper(el.level2Id) : ToolsActionMapper[el.level2Id]}
				</Box>
			</TabPanel>
		);
	});

	const diversityTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.Diversity;
	})?.level2Location;

	const expressionTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.Expression;
	})?.level2Location;

	const MediaSizesCropIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.MediaSizesCrop;
	})?.level2Location;
	const ExpandImageIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.ExpandBackground;
	})?.level2Location;
	const CustomCropIndex = toolConfigurations.find((e1) => {
		return e1.level2Id == ToolConfigTabsTypes.CustomCrop;
	})?.level2Location;

	const cameraMovementTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.CameraMovement;
	})?.level2Location;

	const replaceBackgroundTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.background;
	})?.level2Location;

	const fashionMenTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.FashionMen;
	})?.level2Location;

	const textEditorTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.TextEditor;
	})?.level2Location;

	const logoTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.Logo;
	})?.level2Location;

	const manualBruchTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.ManualObjBrush;
	})?.level2Location;
	const automaticTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.AutoObjIdentification;
	})?.level2Location;

	if (textEditorTabIndex !== undefined && textEditorTabIndex === selectedTab + 1) {
		setIsTextEditorTabSelected(true);
	} else {
		setIsTextEditorTabSelected(false);
	}

	if (logoTabIndex !== undefined && logoTabIndex === selectedTab + 1) {
		setIsLogoTabSelected(true);
	} else {
		setIsLogoTabSelected(false);
	}
	if (
		(manualBruchTabIndex !== undefined && manualBruchTabIndex === selectedTab + 1) ||
		(automaticTabIndex !== undefined && automaticTabIndex === selectedTab + 1)
	) {
		setIsObjectsTabSelected(true);
	} else {
		setIsObjectsTabSelected(false);
	}

	if (cameraMovementTabIndex !== undefined && cameraMovementTabIndex === selectedTab + 1) {
		setIsCameraMovementTabSelected(true);
	} else {
		setIsCameraMovementTabSelected(false);
	}
	// crop tab
	if (
		(MediaSizesCropIndex !== undefined && MediaSizesCropIndex === selectedTab + 1) ||
		(CustomCropIndex !== undefined && CustomCropIndex === selectedTab + 1)
	)
		setCropTabSeleced(true);
	else setCropTabSeleced(false);
	if (ExpandImageIndex !== undefined && ExpandImageIndex === selectedTab + 1) setExpandBackgroundTabSelected(true);
	else setExpandBackgroundTabSelected(false);

	if (replaceBackgroundTabIndex !== undefined && replaceBackgroundTabIndex === selectedTab + 1) {
		setIsReplaceBackgroundTabSelected(true);
	} else {
		setIsReplaceBackgroundTabSelected(false);
	}

	const handleChange = (event: any, newValue: SetStateAction<number>) => {
		const selectedTabId = toolConfigurations.find((el) => {
			return el.level2Location - 1 === newValue;
		})?.level2Id;
		setLockMasks(false);
		if (selectedTabId && disabledTabs[selectedTabId]) {
			return;
		}

		const oldValue: SetStateAction<number> = selectedTab;
		setNewTabNumber(newValue as number);

		if (diversityTabIndex !== undefined && newValue === diversityTabIndex - 1) {
			// context.user?.updateThumbnailsSettings(ToolsViewerMode.thumbnails);
		}

		if (
			isCameraMovementTabSelected &&
			cameraMotionVideo &&
			cameraMovementTabIndex !== undefined &&
			newValue !== cameraMovementTabIndex - 1
		) {
			setOpenCameraMovementModal(true);
			return;
		}

		if (cameraMovementTabIndex !== undefined && newValue === cameraMovementTabIndex - 1) {
			setIsCameraMovementTabSelected(true);
		} else {
			setIsCameraMovementTabSelected(false);
		}

		if (textEditorTabIndex !== undefined && newValue === textEditorTabIndex - 1) {
			setIsTextEditorTabSelected(true);
		} else {
			setIsTextEditorTabSelected(false);
		}

		if (logoTabIndex !== undefined && newValue === logoTabIndex - 1) {
			setIsLogoTabSelected(true);
		} else {
			setIsLogoTabSelected(false);
		}
		if (
			(manualBruchTabIndex !== undefined && newValue === manualBruchTabIndex - 1) ||
			(automaticTabIndex !== undefined && newValue === automaticTabIndex - 1)
		) {
			setIsObjectsTabSelected(true);
		} else {
			setIsObjectsTabSelected(false);
		}

		const isFashionSelected = fashionMenTabIndex !== undefined && newValue == fashionMenTabIndex - 1;
		const wasFashionSelected = fashionMenTabIndex !== undefined && oldValue == fashionMenTabIndex - 1;
		setIsFashionMenSelected(isFashionSelected, wasFashionSelected);
		setSelectedTab(newValue);
		setNewTabNumber(undefined);
	};

	const onCameraModalContinue = () => {
		if (newTabNumber !== undefined) {
			setSelectedTab(newTabNumber);
			setOpenCameraMovementModal(false);
			setNewTabNumber(undefined);
			resetCameraMotionVideo();
		} else {
			handleCameraModalContinue();
		}
	};

	useEffect(() => {
		setSelectedTab(selectedTabDefault);
		scrollContainer?.scrollTo(0, 0);
	}, [selectedPoint]);

	if (configLoading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
				<CircularProgress />
			</Box>
		);
	}

	const disableSliderSwitch =
		toolConfigurations.find((el) => {
			return el.level2Id === ToolConfigTabsTypes.Diversity;
		})?.level2Location === selectedTab;

	const childrenWithProps = React.Children.map(children, (child) => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				disableToggle: disableSliderSwitch,
			});
		}
		return child;
	});

	return (
		<ToolsActionContext.Provider value={ToolsActionContextValue}>
			{(!isIframe() || (isIframe() && context.iframeConfig && !!!context.iframeConfig.whiteLabel)) && (
				<Box className={classes.scaleContainer}>
					<Typography className={classes.scaleThisFeature} variant="h5" align="center">
						Scale this feature
						<br />
						via{" "}
						<Link
							onClick={(e: React.MouseEvent<any>) => {
								const tab: string = toolConfigurations[selectedTab]?.level2DisplayName;
								if (API_DOCS_MAPPER[tab]) {
									window.open(`${API_DOCS_MAPPER[tab]}`, "_blank");
								} else {
									window.open(`${API_DOCS_MAPPER["main"]}`, "_blank");
								}
							}}
							className={classes.link}
						>
							API
						</Link>
					</Typography>
				</Box>
			)}
			<Box className={classes.sideRoot}>
				<Box className={classes.sideContent}>
					{childrenWithProps}
					{/* <Divider /> */}
					{!selectedPoint ? (
						<Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
							<CircularProgress />
						</Box>
					) : (
						<Box
							display="flex"
							flex={1}
							minHeight={0}
							className={classes.generalContainer}
							flexDirection={"column"}
						>
							<Box style={{ marginBottom: 10 }}>
								{
									<Tabs
										value={selectedTab}
										onChange={handleChange}
										variant="scrollable"
										orientation="horizontal"
										scrollButtons="auto"
										classes={{
											indicator: classes.tabsContainer,
											scroller: classes.tabsScroller,
											flexContainer:
												toolConfigurations.find(
													(el) => el.input1 === "Crop" || el.input1 === "objects"
												) && classes.flexContainer,
										}}
										style={{
											overflow: "visible",
											minHeight: "90%",
											borderBottom: "1px solid rgba(26, 6, 56, 0.1)",
										}}
									>
										{toolConfigurations.map((el, index) => {
											const newTabDesgin = el.input1 === "Crop" || el.input1 === "objects";
											const title = (
												<Box className={classes.titleContainer}>
													{el?.icon && (
														<div
															style={{
																marginRight: 10.5,
																display: "flex",
															}}
														>
															{React.cloneElement(el.icon, {
																color:
																	selectedTab === index
																		? theme.palette.primary.dark
																		: "#1A0638",
															})}{" "}
														</div>
													)}
													<Typography className={classes.title} id={el.level2DisplayName}>
														{el.level2DisplayName}
													</Typography>
												</Box>
											);

											const label =
												el.soon &&
												(!context.user?.isAdmin() ||
													context.systemView !== SystemViewTypes.Admin) ? (
													<BriaBadge
														badgeContent={
															context.user?.isViewer() ||
															(context.user?.isAdmin() &&
																context.systemView === SystemViewTypes.Viewer)
																? "Premium"
																: "Soon"
														}
														color="secondary"
													>
														{title}
													</BriaBadge>
												) : el.new ? (
													<BriaBadge badgeContent={"New"} color="secondary">
														{title}
													</BriaBadge>
												) : (
													title
												);

											return (
												<Tab
													key={el.level2Id + index}
													disableRipple
													disabled={
														el.soon &&
														(!context.user?.isAdmin() ||
															context.systemView !== SystemViewTypes.Admin)
													}
													onMouseOver={() => setTabHoverd(index)}
													onMouseOut={() => setTabHoverd(undefined)}
													label={
														<>
															<Box
																className={clsx({
																	[classes.labelAndIconContainer]: true,
																	[classes.cropContainer]: newTabDesgin,
																	[classes.selectedCropTab]:
																		newTabDesgin && selectedTab === index,
																})}
																style={{
																	borderRadius:
																		newTabDesgin &&
																		index === toolConfigurations.length - 1
																			? "0px 8px 8px 0px"
																			: "8px 0px 0px 8px",
																	borderLeft:
																		newTabDesgin &&
																		index === toolConfigurations.length - 1
																			? "none"
																			: "",
																	borderRight:
																		newTabDesgin && index === 0 ? "none" : "",
																}}
															>
																{label}{" "}
																{disabledTabs[el.level2Id] && <TabDisabledTip />}
															</Box>

															{!newTabDesgin && (
																<Box
																	style={{
																		// borderBottomWidth: 4,
																		// borderBottomStyle:
																		// 	"solid",
																		height: 4,
																		backgroundColor:
																			tabHoverd === index && selectedTab !== index
																				? "#00000033"
																				: selectedTab === index
																				? theme.palette.primary.dark
																				: "transparent",
																		borderRadius: 10,
																		width: "100%",
																	}}
																/>
															)}
														</>
													}
													className={clsx({
														[classes.tabContainer]: true,
														[classes.disableTabLabel]: disabledTabs[el.level2Id],
														[classes.cropTabContainer]: newTabDesgin,
													})}
													classes={{
														root: classes.tabRoot,
														selected: classes.selecteTab,
														disabled: classes.disabled,
													}}
													onClick={(event) =>
														disabledTabs[el.level2Id] ? event.preventDefault() : null
													}
												/>
											);
										})}
									</Tabs>
								}
								{(selectedPoint.objectType === BriaObjectType.human || isCameraMovementTabSelected) && (
									<UserRemainingCreditsMessage
										creditType={
											selectedPoint.objectType === BriaObjectType.human
												? SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION
												: SubscriptionCreditsTypes.VIDEO
										}
									></UserRemainingCreditsMessage>
								)}
							</Box>
							<Box>
								<Divider orientation="vertical" />
							</Box>
							<Box
								id="containerDiv"
								height={
									isCameraMovementTabSelected || isReplaceBackgroundTabSelected
										? "100%"
										: "calc(100% - 60px)"
								}
								style={{
									flex: 1,
									minWidth: 0,
									paddingLeft: 20,
									overflowY: "auto",
									overflowX: "hidden",
								}}
							>
								{panels}
							</Box>
						</Box>
					)}
				</Box>
				<SaveCropPopUp
					imageVH={imageVH}
					image={selectedPoint?.objectType === BriaObjectType.imageStyle ? imageUrl : cropImageUrl}
					selectedImageUrl={imageUrl}
					croppedAreaPixels={croppedAreaPixels}
					croppedArea={croppedArea}
					rotation={rotation}
					isCropApplied={!!cropImageUrl}
					removeWatermark={!!context.iframeConfig?.watermarkPath}
					open={showCropSavePopUp}
					onContinuePressed={() => {
						setShowCropSaveFirstTime(false);
						setSelectedPoint(context.selectedTab);
					}}
					onCloseModal={() => setShowCropSavePopUp(false)}
					desciption={
						selectedPoint?.objectType === BriaObjectType.imageStyle
							? "After changing the image style, the image will be saved as a new image, and undoing previous changes will not be possible."
							: undefined
					}
					note={
						selectedPoint?.objectType === BriaObjectType.imageStyle
							? "We recommend changing image style the final step in your editing process."
							: undefined
					}
				/>
				<VideoReadyPopup
					openVideoReadyPopup={openVideoReadyPopup}
					setOpenVideoReadyPopup={setOpenVideoReadyPopup}
					onView={() => {
						setSelectedTab(cameraMovementTabIndex ? cameraMovementTabIndex - 1 : 0);
					}}
				/>
				<CameraMovementModal
					openCameraMovementModal={openCameraMovementModal}
					setOpenCameraMovementModal={setOpenCameraMovementModal}
					doDownloadVideo={doDownloadVideo}
					imageVH={imageVH}
					onContinue={onCameraModalContinue}
				/>
			</Box>
		</ToolsActionContext.Provider>
	);
};

export default ToolsAction;
